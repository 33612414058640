import styled from 'styled-components';

export const ShowGroupWrapper = styled.div`
  padding-left: 205px;
  min-height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  @media (max-width: 1024px) {
    padding-left: 66px;
  }
`;
