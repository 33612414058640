import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import hasProp from 'helpers/hasProp';
import AssignRadio from './AssignRadio';
import AssignResult from './AssignResult';

export default function AssignModal(props) {
  const { t } = useTranslation(['create-assign-question']);
  const { onConfirm } = props;
  const {
    assignedClass,
    assignedGroup,
    assignedStudent,
    assignedPrivateGroup,
    assignedAdminGroup,
    assignedLevel
  } = useSelector(state => state.worksheet);

  const hasAssignees =
    assignedClass.length > 0 ||
    assignedGroup.length > 0 ||
    assignedStudent.length > 0 ||
    assignedPrivateGroup.length > 0 ||
    (hasProp(assignedAdminGroup, 'ID') && assignedLevel !== '');

  return (
    <div className="d-flex assign-modal">
      <Col md={9} className="assign-modal-form assign-private">
        <AssignRadio />
      </Col>
      <Col md={3} className="assign-modal-info assign-class">
        <AssignResult
          assignedClass={assignedClass}
          assignedGroup={assignedGroup}
          assignedStudent={assignedStudent}
          assignedPrivateGroup={assignedPrivateGroup}
          assignedAdminGroup={assignedAdminGroup}
          assignedLevel={assignedLevel}
          hasAssignees={hasAssignees}
        />
        <button
          className={`btn ${
            hasAssignees ? 'btn-primary' : 'btn-secondary'
          } assign-modal-btn`}
          type="button"
          disabled={!hasAssignees}
          onClick={onConfirm}
        >
          {t(`create-assign-question:assignModal.confirm`, 'Confirm')}
        </button>
        {/* if it's clickable, change btn-secondary class into btn-primary */}
      </Col>
    </div>
  );
}
