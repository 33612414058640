import styled from 'styled-components';

export const ActionsMenu = styled.div`
  position: relative;
  display: inline-block;
  &:hover .actions-dropdown-content {
    display: block;
  }
  .more-options {
    font-size: 30px;
    position: relative;
    bottom: 0.5rem;
    border: none;
    background-color: transparent;
    &:hover {
      color: #ff5b00;
    }
  }
`;

export const MenuContent = styled.div`
  display: none;
  position: absolute;
  left: -2.5rem;
  background: #fff;
  min-width: 104.4px;
  padding: 0.5rem 1rem 1rem 1rem;
  margin-right: 1rem;
  box-shadow: 0 3px 6px rgba(45, 45, 45, 0.37);
  z-index: 1;
  .actions-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 600;
    color: #727272;
    cursor: pointer;
    &:hover {
      color: #ff5b00;
    }
  }
`;
