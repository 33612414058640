import React from 'react';
import { SelectNumberWrapper } from './SelectNumberQuestions.styles';

export default function SelectNumberQuestions({
  type,
  count,
  changeCount,
  color,
  index,
  changeControl,
  label
}) {
  return (
    <SelectNumberWrapper color={color}>
      <div className="diff-type">{label}</div>
      <div className="diff-controls">
        <div
          className="dec-count"
          onClick={() => changeControl('dec', type, index)}
        >
          -
        </div>
        <input
          type="text"
          value={count}
          onChange={e => changeCount(e.target.value, type, index)}
        />
        <div
          className="inc-count"
          onClick={() => changeControl('inc', type, index)}
        >
          +
        </div>
      </div>
    </SelectNumberWrapper>
  );
}
