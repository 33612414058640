import React, { useCallback, useEffect, useState } from 'react';
import DropdownMenu from 'components/Shared/DropdownMenu';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import iconToga from 'assets/img/icon-toga.svg';
import GameSettingStudentList from 'components/ManageClass/GameSettingStudentList';
import {
  fetchGameSettingClassRequest,
  fetchGameSettingStudentRequest,
  resetError,
  saveGameSettingRequest
} from 'stores/gameSetting/actions';
import AlertModal from 'components/Alert/AlertModal';
import isNilOrEmpty from 'helpers/isNilOrEmpty';
import GameSettingContainer from './GameSetting.styles';

const GameSetting = () => {
  const { t } = useTranslation(['manage-class', 'common']);
  const dispatch = useDispatch();
  const {
    classes,
    students,
    error,
    isLoading,
    saveSettingResult
  } = useSelector(state => state.gameSetting);

  const [selectedClass, setSelectedClass] = useState(null);
  const [allowedStudentIDs, setAllowedStudentIDs] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const onModalClose = () => {
    setAlertMessage('');
    setIsShowModal(false);
    dispatch(resetError());
  };

  const handleSelectedClass = classObject => {
    setSelectedClass(classObject);
  };

  useEffect(() => {
    dispatch(fetchGameSettingClassRequest());
  }, [dispatch]);

  useEffect(() => {
    setSelectedClass(classes[0]);
  }, [classes]);

  useEffect(() => {
    if (selectedClass) {
      dispatch(fetchGameSettingStudentRequest(selectedClass.Id));
    }
  }, [dispatch, selectedClass]);

  useEffect(() => {
    if (students) {
      const allowed = students
        .filter(student => student.Access === true)
        .map(student => student.UserId);
      setAllowedStudentIDs(allowed);
    }
  }, [students]);

  useEffect(() => {
    if (!isNilOrEmpty(error)) {
      setIsShowModal(true);
      setAlertMessage(error);
    }
  }, [error]);

  useEffect(() => {
    if (saveSettingResult) {
      setIsShowModal(true);
      setAlertMessage(
        t(
          `manage-class:gameSettings.modal.desc`,
          'Game Setting Change Successfully!'
        )
      );
    }
  }, [saveSettingResult, t]);

  const handleOnSave = useCallback(() => {
    const disallowed = students
      .map(student => student.UserId)
      .filter(id => !allowedStudentIDs.includes(id));
    const params = {
      Groupid: selectedClass.Id,
      AllowedUsers: allowedStudentIDs,
      DeniedUsers: disallowed
    };

    dispatch(saveGameSettingRequest(params));
  }, [dispatch, allowedStudentIDs, students, selectedClass]);

  return (
    <>
      <AlertModal
        title={t(`manage-class:gameSettings.modal.info`, 'Info')}
        isShow={isShowModal}
        handleClose={onModalClose}
        buttons={
          <button
            type="button"
            className="btn btn-primary"
            onClick={onModalClose}
          >
            {t('common:close', 'Close')}
          </button>
        }
      >
        <p>{alertMessage}</p>
      </AlertModal>
      <GameSettingContainer>
        <div className="d-flex justify-content-between align-items-center mx-auto px-3 py-4 border-bottom">
          <Link to="/" className="navigation-back">
            &lt; {t(`common:back`, 'Back')}
          </Link>

          <div className="d-flex align-items-center">
            <img
              className="mr-2"
              src={iconToga}
              alt="class-icon"
              width="35px"
              height="35px"
            />
            <span className="mr-2">
              {t(`manage-class:studModal.class`, 'Class')}
            </span>
            <div className="mr-2">
              <DropdownMenu
                selectedValue={selectedClass && selectedClass.Name}
                values={classes}
                valueKey="Name"
                setValue={handleSelectedClass}
                width="200px"
              />
            </div>
            <span>
              {t(
                `manage-class:gameSettings.desc`,
                'Math Games Setting for Individual Students'
              )}
            </span>
          </div>

          <button
            type="button"
            className="btn btn-primary btn-next-qn question-cart-content__right-btn"
            onClick={handleOnSave}
          >
            {t(`manage-class:editGroup.save`, 'Save')}
          </button>
        </div>
        <div className="px-5">
          <GameSettingStudentList
            students={students}
            allowedStudentIDs={allowedStudentIDs}
            setAllowedStudentIDs={setAllowedStudentIDs}
            isLoading={isLoading}
          />
        </div>
      </GameSettingContainer>
    </>
  );
};
export default React.memo(GameSetting);
