import React, { useState, useCallback } from 'react';
import StudentTable from 'components/Report/StudentTableReport';
import NoReport from 'components/Report/NoReport';
import cloneDeep from 'lodash.clonedeep';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClassProficiencyReportRequest } from 'stores/report/actions';

import useDeepCompareEffect from 'use-deep-compare-effect';
import Spinner from 'components/Shared/Spinner';
import { isEmpty, isNil } from 'ramda';
import { LoadingContainer } from './ReportPage.styles';

const ProficiencyReportByStudent = ({ visible }) => {
  const dispatch = useDispatch();
  // Redux States
  const {
    classProficiencyReport,
    selectedClass,
    reportLevel,
    selectedSyllabus,
    isLoading: reportsLoading
  } = useSelector(state => state.reports);
  const [sortDetail, setSortDetail] = useState(null);
  const isLeveless = isEmpty(selectedSyllabus.Levels);

  const sortedData = useCallback(() => {
    if (sortDetail) {
      const { type, sortType } = sortDetail;
      const hasData = classProficiencyReport.some(
        datum => datum.CompetencyDetails[type] > 0
      );
      if (hasData) {
        const result = cloneDeep(classProficiencyReport).sort((a, b) => {
          const c =
            a.CompetencyDetails[type] - b.CompetencyDetails[type] > 0 ? 1 : -1;
          return sortType === 'asc' ? c : c * -1;
        });
        return result;
      }
      return classProficiencyReport;
    }
    return classProficiencyReport;
  }, [classProficiencyReport, sortDetail]);

  useDeepCompareEffect(() => {
    if (selectedClass && selectedSyllabus && !isNil(reportLevel)) {
      dispatch(
        fetchClassProficiencyReportRequest({
          classID: selectedClass.ID,
          reportLevel: isLeveless ? -1 : reportLevel.ID,
          syllabusID: selectedSyllabus.CurriculumID
        })
      );
    }
  }, [dispatch, reportLevel, selectedClass, selectedSyllabus]);
  const handleSortClick = ({ type, sortType }) => {
    setSortDetail(prev => {
      if (prev) {
        return prev.type === type && prev.sortType === sortType
          ? null
          : { type, sortType };
      }
      return { type, sortType };
    });
  };
  const renderResult = () => {
    if (reportsLoading) {
      return (
        <LoadingContainer>
          <Spinner styles={{ marginTop: '0' }} />
        </LoadingContainer>
      );
    }
    if (classProficiencyReport?.length) {
      return (
        <StudentTable
          type="proficiency"
          data={sortedData()}
          isLoading={reportsLoading}
          sortClick={handleSortClick}
          sortDetail={sortDetail}
        />
      );
    }
    return <NoReport />;
  };
  return visible ? renderResult() : null;
};

export default ProficiencyReportByStudent;
