import React, { createRef, useState, useEffect } from 'react';
import { isNil, sort } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import isNilOrEmpty from 'helpers/isNilOrEmpty';
import filterQuestions from 'helpers/filterQuestions';
import parseKeys from 'helpers/parseKeys';
import { selectQuestion } from 'stores/questions/actions';
import { selectVariation } from 'stores/variations/actions';
import ItemWrap from 'components/Content/ItemWrap';
import QuestionCardView from 'components/Content/QuestionCardView';
import QuestionModalView from 'components/Content/QuestionModalView';
import SkillItem from 'components/Content/SkillItem';
import QuestionLoader from 'components/Loader/QuestionLoader';

export default function QuestionsView() {
  const slider = React.createRef();
  const dispatch = useDispatch();

  // Redux state section
  const { questions, selectedQuestion } = useSelector(state => state.questions);
  const { variations, selectedVariation, isTitleLoading } = useSelector(
    state => state.variations
  );
  const { filters } = useSelector(state => state.filters);
  const { skills: unsortedSkills, selectedSkill } = useSelector(
    state => state.skills
  );
  const skills = sort((a, b) => a.Display - b.Display, unsortedSkills);

  // State section
  const [show, setShow] = useState(false);
  const [showSolution, setShowSolution] = useState(false);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [initialSlide, setInitialSlide] = useState(0);

  const handleShowSolution = () => setShowSolution(true);
  const handleHideSolution = () => setShowSolution(false);
  const getSkillTitle = skill => <SkillItem skill={skill} />;
  const handleClose = () => {
    handleHideSolution();
    dispatch(selectVariation({}));
    setShow(false);
  };

  const handleShow = async question => {
    const variation = variations.find(v => v.ID === question.VariationId);
    if (!isNil(variation)) {
      await dispatch(selectVariation(variation));
    }
    await dispatch(selectQuestion(question));
    setShow(true);
  };

  const handleSlideTo = index => slider.current.slickGoTo(index);
  const onSlideChange = index =>
    dispatch(selectQuestion(filteredQuestions[index]));

  const skillRefs = skills.reduce((acc, value) => {
    acc[value.ID] = createRef();
    return acc;
  }, {});

  const scrollToSkill = id => {
    const element = skillRefs[id].current;
    // Header element height
    const offset = -53;
    const scrollContainer = document.getElementsByClassName(
      'simplebar-content-wrapper'
    )[1];

    if (element && scrollContainer) {
      const elementRect = element.getBoundingClientRect();
      const containerRect = scrollContainer.getBoundingClientRect();
      const targetScrollTop =
        elementRect.top -
        containerRect.top +
        scrollContainer.scrollTop +
        offset;
      scrollContainer.scrollTo({
        top: targetScrollTop,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    if (!isTitleLoading) scrollToSkill(selectedSkill.ID);
  }, [isTitleLoading]); // eslint-disable-line

  useEffect(() => {
    const newFilteredQuestion = filterQuestions(
      !isNilOrEmpty(questions) ? questions : selectedVariation?.Questions ?? [],
      filters
    ).map(question =>
      // parse the question data to make it similar from question detail
      parseKeys(question, [
        { old: 'QuestionID', new: 'Id' },
        { old: 'VariationID', new: 'VariationId' }
      ])
    );
    setFilteredQuestions(newFilteredQuestion);
    const questionIndex = Math.max(
      0,
      newFilteredQuestion.findIndex(
        q => q.Id === selectedQuestion[selectedVariation?.ID]?.Id
      )
    );
    setInitialSlide(questionIndex);
  }, [selectedQuestion, selectedVariation, questions]); // eslint-disable-line

  return (
    <>
      {!isNilOrEmpty(selectedVariation) && (
        <Modal show={show} onHide={handleClose} className="modal-qs">
          <Slider
            afterChange={onSlideChange}
            fade
            infinite={false}
            initialSlide={initialSlide}
            ref={slider}
            slidesToScroll={1}
            slidesToShow={1}
            speed={0}
          >
            {filteredQuestions.map(question => {
              return (
                <QuestionModalView
                  handleSlideTo={handleSlideTo}
                  key={String(question.Id)}
                  skillTitle={getSkillTitle(
                    skills.find(
                      skill => skill.SIOID === selectedVariation.SIOID
                    )
                  )}
                  question={question}
                  questions={filteredQuestions}
                  onCloseModal={handleClose}
                  showSolution={showSolution}
                />
              );
            })}
          </Slider>
        </Modal>
      )}
      {skills.map(skill => {
        const filterArr = variations.filter(
          variation => variation.SIOID === skill.SIOID
        );
        return (
          <ItemWrap
            id={String(skill.Display)}
            key={String(skill.Display)}
            ref={skillRefs[skill.ID]}
            skill={skill}
            sio={filterArr}
          >
            <h3>{getSkillTitle(skill)}</h3>
            <div className="box-white-wrap">
              <div className="row">
                {filterArr.length > 0 ? (
                  filterArr.map(variation => {
                    // for variation with emtpy questions, just return null
                    if (isNilOrEmpty(variation.Questions[0])) {
                      return null;
                    }
                    const variationQuestions = variation.Questions.map(
                      question =>
                        // parse the question data to make it similar from question detail
                        parseKeys(question, [
                          { old: 'QuestionID', new: 'Id' },
                          { old: 'VariationID', new: 'VariationId' }
                        ])
                    );
                    const question =
                      selectedQuestion[variation.ID] ??
                      variationQuestions[0].Question;
                    if (isNilOrEmpty(question)) {
                      return null;
                    }
                    return (
                      <QuestionCardView
                        key={String(variation.ID)}
                        question={question}
                        questions={variationQuestions}
                        onShowModal={() => handleShow(question)}
                        onShowSolution={handleShowSolution}
                      />
                    );
                  })
                ) : (
                  <QuestionLoader />
                )}
              </div>
            </div>
          </ItemWrap>
        );
      })}
    </>
  );
}
