import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import avatarPlaceholder from 'assets/img/avatar.png';
import SearchIcon from 'assets/img/icon-manage-class-lup.svg';
import MatchedResult from 'components/ManageClass/MatchedResult';
import {
  CreateGroupStudentsWrapper,
  GroupStudentsContainer
} from './CreateGroupStudents.styles';

const CreateGroupStudents = ({
  classSelected,
  classIdSelected,
  getStudents,
  selectedList,
  changeStudents,
  changeSelectedList,
  Students
}) => {
  const { t } = useTranslation(['manage-class-group']);
  const [searchTerm, setSearchTerm] = useState('');
  const [showStudents, setShowStudents] = useState(getStudents);
  const [selectedStudents, setSelectedStudents] = useState(Students);

  useEffect(() => {
    setShowStudents(getStudents);
  }, [getStudents]);

  useEffect(() => {
    setSelectedStudents(Students);
  }, [Students]);

  const searchStudent = val => {
    setSearchTerm(val);
    const searchedStudents = getStudents.filter(
      student =>
        student.DisplayName.toLowerCase().indexOf(val.toLowerCase()) >= 0
    );
    setShowStudents(searchedStudents);
  };

  const isStudentSelected = UserId => {
    return selectedStudents.some(obj => obj.userId === UserId);
  };

  const selectStudent = (userId, userName) => {
    const tempStudents = [...selectedStudents];
    const stuIndex = tempStudents.findIndex(obj => obj.userId === userId);
    if (stuIndex === -1) tempStudents.push({ userId });
    else tempStudents.splice(stuIndex, 1);
    setSelectedStudents(tempStudents);
    changeStudents(tempStudents); // save in parent component
    changeList(userId, userName); // save for showing list in CreateGroupList
  };

  const changeList = (userId, userName) => {
    const tempList = { ...selectedList };
    if (tempList[classIdSelected]) {
      const tempListIndex = tempList[classIdSelected].findIndex(
        obj => obj.userId === userId
      );
      if (tempListIndex === -1)
        tempList[classIdSelected].push({
          userId,
          userName,
          classname: classSelected
        });
      else tempList[classIdSelected].splice(tempListIndex, 1);
    } else {
      tempList[classIdSelected] = [];
      tempList[classIdSelected].push({
        userId,
        userName,
        classname: classSelected
      });
    }
    changeSelectedList(tempList);
  };

  return (
    <CreateGroupStudentsWrapper searchIcon={SearchIcon}>
      <div className="crt-grp-classname">
        {classSelected ||
          t(`manage-class-group:createGroup.className`, 'Class name')}
      </div>
      <Form className="search-form col-sm-12">
        <input
          type="search"
          id="search-student"
          className="form-control"
          placeholder={t('manage-class-group:createGroup.search', 'Search')}
          value={searchTerm}
          onChange={e => searchStudent(e.target.value)}
          autoComplete="off"
        />
      </Form>
      <GroupStudentsContainer>
        {showStudents.length === 0 ? (
          <div className="no-student">
            {t(
              `manage-class-group:createGroup.noStudent`,
              'No students in this class.'
            )}
          </div>
        ) : (
          showStudents.map(student => (
            <div
              key={student.UserId}
              className={
                isStudentSelected(student.UserId)
                  ? 'group-students-lists active-student'
                  : 'group-students-lists'
              }
            >
              <div className="check-container">
                <input
                  type="checkbox"
                  checked={isStudentSelected(student.UserId)}
                  value={student.UserId}
                  onChange={() =>
                    selectStudent(student.UserId, student.DisplayName)
                  }
                />
                <span className="group-checkmark" />
              </div>

              <div className="grp-img-container">
                <img
                  src={student.ProfileImage}
                  alt="student-img"
                  onError={e => (e.target.src = avatarPlaceholder)}
                />
              </div>

              <div className="grp-img-name">
                <MatchedResult
                  result={student.DisplayName}
                  searchTerm={searchTerm}
                />
              </div>
            </div>
          ))
        )}
      </GroupStudentsContainer>
    </CreateGroupStudentsWrapper>
  );
};

export default CreateGroupStudents;
