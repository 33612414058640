import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import {
  fetchProfileDataRequest,
  updateProfileRequest,
  emptyUpdateProfile
} from 'stores/profileSettings/actions';
import ShowPswd from 'assets/img/icon-show-pswd.svg';
import HidePswd from 'assets/img/icon-hide-pswd.svg';
import ProfileImage from './ProfileImage';
import { ProfileFormContainer } from './ProfileForm.style';
import { isEmpty } from 'ramda';

import ProfileSuccessModal from './ProfileSuccessModal';
import PopupModal from 'components/Shared/PopupModal';

export default function ProfileForm() {
  const { t } = useTranslation(['profile-settings']);
  const dispatch = useDispatch();
  const { isLoading } = useSelector(state => state.profileSettings);
  const { profileData } = useSelector(state => state.profileSettings);
  const { updateData } = useSelector(state => state.profileSettings);
  const { error } = useSelector(state => state.profileSettings);

  const [name, setName] = useState('');
  const [nameInClass, setNameInClass] = useState('');
  const [email, setEmail] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [profileAvatarId, setProfileAvatarId] = useState('');
  const [changePwd, setChangePwd] = useState(false);
  const [passwords, setPasswords] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [showPswd, setShowPswd] = useState(false);
  const [showConfirmPswd, setShowConfirmPswd] = useState(false);
  const [showErr, setShowErr] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    if (!profileData.error) {
      setName(profileData.FullName);
      setNameInClass(profileData.NameInClass);
      setEmail(profileData.TeacherEmail);
      setProfileImage(profileData.ProfileImage);
      setProfileAvatarId(profileData.ProfileAvatarId);
    }
  }, [profileData]);

  useEffect(() => {
    if (!isEmpty(updateData)) {
      if (updateData) setChangePwd(false);
      dispatch(fetchProfileDataRequest());
      dispatch(emptyUpdateProfile());
      setDisableButton(true);
      showModal();
    }
    // eslint-disable-next-line
  }, [updateData]);

  useEffect(() => {
    if (error) {
      setShowErr(error);
    }
  }, [error]);

  const changePassword = (val, type) => {
    setDisableButton(false);
    const tempPswd = { ...passwords };
    tempPswd[type] = val;
    setPasswords(tempPswd);
  };

  const createReqObj = () => {
    const obj = {
      UserId: profileData.UserId,
      FullName: name,
      NameInClass: nameInClass,
      TeacherEmail: email,
      ProfileAvatarId: profileAvatarId,
      CurrentPassword: changePwd ? passwords.oldPassword : null,
      NewPassword: changePwd ? passwords.newPassword : null,
      ConfirmPassword: changePwd ? passwords.confirmPassword : null
    };
    return obj;
  };

  const handleSubmit = e => {
    e.preventDefault();
    setShowErr('');
    if (changePwd) {
      if (passwords.newPassword !== passwords.confirmPassword) {
        setShowErr(
          t(
            `profile-settings:main.pswdErr`,
            'New and confirm password should be same.'
          )
        );
        return;
      }
    }
    const obj = createReqObj();
    dispatch(updateProfileRequest(obj, changePwd));
    cancelChangePswd();
  };

  const setAvatarId = val => {
    setProfileAvatarId(val);
  };

  const cancelChangePswd = () => {
    setShowErr('');
    setChangePwd(false);
    setPasswords({
      ...passwords,
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    });
  };

  const showModal = () => setShowSuccessModal(true);

  return (
    <>
      <ProfileImage
        profileImage={profileImage}
        profileAvatarId={profileAvatarId}
        setProfileAvatarId={setAvatarId}
        removeDisableButton={() => setDisableButton(false)}
      />
      <ProfileFormContainer>
        <form onSubmit={handleSubmit}>
          <div className="edit-profile-field">
            <span className="field-title">
              {t(`profile-settings:main.name`, 'Name')}
            </span>
            <input
              type="text"
              value={name}
              className="profile-form-inp"
              onChange={e => {
                setName(e.target.value);
                setDisableButton(false);
              }}
              required
            />
          </div>
          <div className="edit-profile-field">
            <span className="field-title">
              {t(`profile-settings:main.nameClass`, 'Name in class')}
            </span>
            <input
              type="text"
              value={nameInClass}
              className="profile-form-inp"
              onChange={e => {
                setNameInClass(e.target.value);
                setDisableButton(false);
              }}
              required
            />
          </div>
          <div className="edit-profile-field">
            <span className="field-title">
              {t(`profile-settings:main.email`, 'Email')}
            </span>
            <input
              type="text"
              value={email}
              className="profile-form-inp"
              onChange={e => {
                setEmail(e.target.value);
                setDisableButton(false);
              }}
              required
            />
          </div>

          {!changePwd ? (
            <div className="edit-profile-field">
              <span className="field-title">
                {t(`profile-settings:main.password`, 'Password')}
              </span>
              <div className="password-button">
                <input
                  type="text"
                  value="●●●●●●●●●"
                  className="change-pswd-inp"
                />
                <span
                  onClick={() => setChangePwd(true)}
                  className="change-pswd"
                >
                  {t(`profile-settings:main.changePswd`, 'Change Password')}
                </span>
              </div>
            </div>
          ) : (
            <>
              <div onClick={cancelChangePswd} className="cancel-pswd-container">
                <hr className="pswd-border-line" />
                <span className="cancel-pswd">
                  {t(
                    `profile-settings:main.cancelChangePswd`,
                    'Cancel Change Password'
                  )}
                </span>
              </div>
              <div className="edit-profile-field">
                <span className="field-title">
                  {t(`profile-settings:main.oldPswd`, 'Old Password')}
                </span>
                <input
                  type="password"
                  value={passwords.oldPassword}
                  className="profile-form-inp"
                  onChange={e => changePassword(e.target.value, 'oldPassword')}
                  autoComplete={false}
                  required
                />
              </div>
              <div className="edit-profile-field">
                <span className="field-title">
                  {t(`profile-settings:main.newPswd`, 'New Password')}
                </span>
                <div className="password-button">
                  <input
                    type={showPswd ? 'text' : 'password'}
                    value={passwords.newPassword}
                    className="change-pswd-inp"
                    onChange={e =>
                      changePassword(e.target.value, 'newPassword')
                    }
                    autoComplete="off"
                    required
                  />
                  <img
                    src={showPswd ? HidePswd : ShowPswd}
                    alt="show-hide-icon"
                    className="show-hide"
                    onClick={() => setShowPswd(!showPswd)}
                  />
                </div>
              </div>
              <div className="edit-profile-field">
                <span className="field-title">
                  {t(
                    `profile-settings:main.confirmPswd`,
                    'Confirm New Password'
                  )}
                </span>
                <div className="password-button">
                  <input
                    type={showConfirmPswd ? 'text' : 'password'}
                    value={passwords.confirmPassword}
                    className="change-pswd-inp"
                    onChange={e =>
                      changePassword(e.target.value, 'confirmPassword')
                    }
                    autoComplete="off"
                    required
                  />
                  <img
                    src={showConfirmPswd ? HidePswd : ShowPswd}
                    alt="show-hide-icon"
                    className="show-hide"
                    onClick={() => setShowConfirmPswd(!showConfirmPswd)}
                  />
                </div>
              </div>
            </>
          )}
          {showErr && <div className="profile-err">{showErr}</div>}
          <button
            disabled={disableButton}
            type="submit"
            className="btn btn-lg btn-primary save-profile"
          >
            {t(`profile-settings:main.saveChanges`, 'Save Changes')}
          </button>
          {isLoading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="ml-2"
            />
          )}
        </form>
        <PopupModal
          show={showSuccessModal}
          hide={() => setShowSuccessModal(false)}
        >
          <ProfileSuccessModal hide={() => setShowSuccessModal(false)} />
        </PopupModal>
      </ProfileFormContainer>
    </>
  );
}
