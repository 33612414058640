import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

import ProgressBar from 'components/Report/ProgressBar';
import { Header, SkillInfo } from './SkillTopbar.styles';

const Topbar = ({
  type,
  item,
  progressBar,
  skillList,
  back,
  setSortDetail
}) => {
  const { t } = useTranslation(['proficiency-report', 'high-score-report']);
  const history = useHistory();
  const { topicID } = useParams();

  return (
    <Header>
      <div className="back-button" role="button" onClick={back}>
        {t(`proficiency-report:studentReport.back`, '< Back')}
      </div>
      <SkillInfo>
        <p className="skill-label">{item.TopicName}</p>
        <Dropdown className="dropdown-container">
          <Dropdown.Toggle variant="light" className="dropdown-custom skills">
            {t(`proficiency-report:studentReportSide.skill`, 'Skill')}{' '}
            {item.Display} {item.SkillName}
          </Dropdown.Toggle>
          <Dropdown.Menu flip={false} className="menu-custom">
            {skillList.map(skill => (
              <Dropdown.Item
                key={skill.SkillId}
                className="item-custom"
                onClick={() => {
                  if (type === 'proficiency') {
                    setSortDetail(null);
                  }
                  history.push(
                    `/report/${type}-report/skill/${topicID}/${skill.SkillId}`
                  );
                }}
              >
                {t(`proficiency-report:studentReportSide.skill`, 'Skill')}{' '}
                {skill.Display} {skill.SkillName}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <div className="right">
          <span className="right-progress-bar">
            <ProgressBar height="14px" visualParts={progressBar} />
          </span>
          <p className="progress-label">
            {type === 'proficiency' && (
              <>
                <span>{item.CompetencyDetails.Mastered}</span>{' '}
                {`/${item.TotalNoOfStudents} ${
                  item.TotalNoOfStudents >= 2
                    ? t(`proficiency-report:classReport.students`, 'students')
                    : t(`proficiency-report:classReport.student`, 'student')
                } ${t(`proficiency-report:classReport.mastery`, 'mastery')}`}
              </>
            )}
            {type === 'high-score' && (
              <>
                <span>{item.MasteryLevels.A}</span>{' '}
                {`/${item.TotalNoOfUsers} ${
                  item.TotalNoOfUsers >= 2
                    ? t(`proficiency-report:classReport.students`, 'students')
                    : t(`proficiency-report:classReport.student`, 'student')
                } ${t(`high-score-report:legend.AStar`, 'A Star')}`}
              </>
            )}
          </p>
        </div>
      </SkillInfo>
    </Header>
  );
};

export default Topbar;
