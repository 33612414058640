import { call, put, select } from 'redux-saga/effects';
import { isNil, isEmpty } from 'ramda';
import {
  fetchLevelsRequest,
  fetchLevelsSuccess,
  fetchLevelsFailed,
  selectLevel,
  setVideosRequest,
  setVideosFailed,
  setVideosSuccess
} from 'stores/levels/actions';
import { setUserData } from 'stores/login/actions';

import LevelService from 'services/levels';
import { setUserDefaultLevel } from 'services/user';
import getUserCurriculums from 'sagas/curriculums';

export function* setDefaultLevel({ selectedLevel }) {
  const { selectedCurriculum } = yield select(state => state.curriculums);
  // eslint-disable-next-line no-nested-ternary
  const checkLevelId = () => {
    if (isEmpty(selectedCurriculum?.Levels)) {
      return -1;
    } else if (
      !isEmpty(selectedCurriculum?.Levels) &&
      selectedCurriculum.Levels.find(item => item.ID === selectedLevel.ID)
    ) {
      return selectedLevel.ID;
    }
    return selectedCurriculum.Levels[0].ID;
  };
  const levelId = checkLevelId();

  const setUser = yield call(setUserDefaultLevel, levelId);
  if (!isNil(setUser)) {
    if (!isNil(setUser.error)) {
      // nothing
    } else {
      yield put(setUserData(setUser));
      yield put(setVideosRequest(selectedLevel));
    }
  }
}

export function* getVideos({ selectedLevel }) {
  // let curriculumId = '';
  // if (isNil(curriculum)) {
  //   const { selectedCurriculum } = yield select(state => state.curriculums);
  //   curriculumId = selectedCurriculum.CurriculumID;
  // } else {
  //   curriculumId = curriculum;
  // }
  const { selectedCurriculum } = yield select(state => state.curriculums);
  const curriculumId = selectedCurriculum.CurriculumID;
  // eslint-disable-next-line no-nested-ternary
  const checkLevelId = () => {
    if (isEmpty(selectedCurriculum?.Levels)) {
      return -1;
    } else if (
      !isEmpty(selectedCurriculum?.Levels) &&
      selectedCurriculum.Levels.find(item => item.ID === selectedLevel.ID)
    ) {
      return selectedLevel.ID;
    }
    return selectedCurriculum.Levels[0].ID;
  };
  const levelId = checkLevelId();
  const data = yield call(
    LevelService.getLevelVideos,
    curriculumId,
    // selectedCurriculum.CurriculumID,
    levelId
  );
  if (!isNil(data)) {
    if (!isNil(data.error)) {
      console.log('Handle err', data);
      yield put(setVideosFailed(data));
    } else {
      yield put(setVideosSuccess(data));
    }
  }
}

/**
 * The startup saga is the place to define behavior to execute when the application starts.
 */
export default function* levels({ user }) {
  yield put(fetchLevelsRequest());
  const data = yield call(LevelService.fetchLevelsApi);
  if (!isNil(data)) {
    if (!isNil(data.error)) {
      // if the API return error message
      yield put(fetchLevelsFailed(data.error));
    } else {
      yield put(fetchLevelsSuccess(data));
      yield call(getUserCurriculums);
      if (isNil(user.DefaultLevel) || user.DefaultLevel < 1) {
        // yield call([history, history.push], '/');
      } else {
        const level = data.find(lvl => lvl.ID === user.DefaultLevel) ?? {};
        yield put(selectLevel(level));
        // only when current location is homescreen, redirect to browse-content
        // if (['/'].includes(history.location.pathname)) {
        //   yield call([history, history.push], '/dashboard');
        // }
      }
    }
  }
}
