import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setStep } from 'stores/utils/step/actions';
import { resetSelectedWorksheet } from 'stores/worksheet/actions';
import StepperWizard from 'components/Shared/StepperWizard';
import CreateHomeworkWorksheet from 'pages/CreateAndAssign/CreateHomework/CreateHomeworkWorksheet';
import AssignSavedWorksheet from 'pages/CreateAndAssign/CreateHomework/AssignSavedWorksheet';
import isNilOrEmpty from 'helpers/isNilOrEmpty';
import { isEmpty } from 'ramda';
import WorksheetPreviewNew from '../QuestionCart/WorksheetPreviewNew';
import { CreateHomeworkContainer } from './CreateHomework.styles';

const CreateHomework = () => {
  const { t } = useTranslation(['create-assign-question']);
  const dispatch = useDispatch();
  const history = useHistory();
  const { activeStep } = useSelector(state => state.step);
  const { title, presentationType } = useSelector(state => state.worksheet);
  const { createWorksheetItems } = useSelector(state => state.topicalWorksheet);

  useEffect(() => {
    return () => {
      dispatch(resetSelectedWorksheet());
    };
  }, [dispatch]);

  useEffect(() => {
    isEmpty(createWorksheetItems) &&
      history.push('/create-and-assign/topical-worksheet');
    // eslint-disable-next-line
  }, [isEmpty(createWorksheetItems)]);

  const onSelect = step => {
    let valid = false;
    switch (step) {
      case 2:
        valid =
          !!title.trim() &&
          createWorksheetItems.length > 0 &&
          createWorksheetItems.length <= 50;
        break;
      case 3:
        valid =
          !!title.trim() &&
          createWorksheetItems.length > 0 &&
          !isNilOrEmpty(presentationType);
        break;
      default:
        valid = true;
        break;
    }
    if (valid) {
      dispatch(setStep(step));
    }
  };

  return (
    <CreateHomeworkContainer activeStep={activeStep}>
      <StepperWizard onSelect={onSelect}>
        <CreateHomeworkWorksheet
          step="1"
          title={t(`create-assign-question:stepper.editWo`, 'Edit Worksheet')}
        />
        <WorksheetPreviewNew
          step="2"
          title={t(`create-assign-question:stepper.preview`, 'Preview')}
        />
        <AssignSavedWorksheet
          step="3"
          title={t(`create-assign-question:stepper.assign`, 'Assign')}
        />
      </StepperWizard>
    </CreateHomeworkContainer>
  );
};

export default CreateHomework;
