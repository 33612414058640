import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const MonthlyReportChartStyle = styled.div`
  .printChart {
    background-color: #000;
  }
  background-color: #ffffff;
  padding: 30px 100px;
  display: block;
  text-align: center;
  margin: 80px 150px 50px 150px;
  @page {
    margin: 20mm 20mm 20mm 0mm;
  }
  @media (max-width: 1400px) {
    margin: 80px 80px 50px 80px;
    padding: 30px 80px;
  }
  @media (max-width: 900px) {
    margin: 80px 50px 50px 50px;
    padding: 30px 50px;
  }
  .koobitsTitle {
    font-size: 36px;
    color: #4a4a4a;
    align-self: center;
  }
  .monthlyText {
    font-size: 26px;
    color: #e26717;
    align-self: center;
  }
  .reportContainer {
    height: 300px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 30px;
    display: -webkit-box;
    -webkit-box-orient: block-axis;
    -webkit-box-lines: multiple;
    @media (max-width: 1450px) {
      height: 400px;
    }
    @media (max-width: 1300px) {
      height: auto;
    }
  }
  .renderReport {
    display: flex;
    margin: 10px 0px;
    width: 500px;
    @media (max-width: 1650px) {
      width: 400px;
    }
    @media (max-width: 1450px) {
      width: 300px;
    }
    @media (max-width: 1300px) {
      width: 500px;
    }
    @media (max-width: 1200px) {
      width: 400px;
    }
    @media (max-width: 900px) {
      width: 350px;
    }
  }
  .reportName {
    font-size: 22px;
    margin-left: 10px;
  }
  .number {
    min-width: 23px;
    max-width: 23px;
    height: 23px;
    background-color: #424242;
    color: white;
    border: 1px solid #424242;
    border-radius: 1000px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
  }
  .renderLevel {
    display: grid;
    grid-template-columns: repeat(3, 80px);
    grid-column-gap: 30px;
    grid-row-gap: 20px;
  }
  .levelButton {
    border: none;
    background-color: transparent;
  }
  .levelText {
    color: #337ab7;
    font-size: 14px;
  }
  .reportNameChart {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .levelChartName {
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: #333;
  }
  .chart {
    margin: 30px 0px;
  }
  .chartReportContainer {
    text-align: center;
  }
`;
