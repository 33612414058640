import { call, put } from 'redux-saga/effects';
import { isNil } from 'ramda';
import moment from 'moment';
import ManageClassService from 'services/manage-class';
import {
  fetchClassesSuccess,
  fetchClassesFailed,
  fetchStudentsClassSuccess,
  fetchStudentsFailed,
  searchStudentSuccess,
  searchStudentFailed,
  fetchStudentDetailsSuccess,
  fetchStudentDetailsFailed,
  editClassSuccess,
  editClassFailed,
  changeClassSuccess,
  changeClassFailed,
  resetPwSuccess,
  resetPwFailed,
  fetchIconsSuccess,
  fetchIconsFailed,
  resetStudents,
  resetSearchResults,
  setStudentPages,
  setSearchPages
} from 'stores/manage-class/actions';

// Saga for fetching classes data
export function* fetchClasses({ params }) {
  const queryParams = {
    year: params.year
  };
  const data = yield call(ManageClassService.fetchClasses, queryParams);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchClassesSuccess(data));
    }
  } else {
    yield put(fetchClassesFailed(data.error));
    // TODO: set classes state to empty?
  }
}

// Saga for fetching classes data for current year
export function* fetchClassesForCurrentYear() {
  const queryParams = {
    year: moment().year()
  };
  const data = yield call(ManageClassService.fetchClasses, queryParams);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchClassesSuccess(data));
    }
  } else {
    yield put(fetchClassesFailed(data.error));
    // TODO: set classes state to empty?
  }
}

// Saga for fetching students data
export function* fetchStudents({ params }) {
  const queryParams = {
    classID: params.classID,
    pageNum: params.pageNum
  };
  const data = yield call(ManageClassService.fetchStudents, queryParams);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchStudentsClassSuccess(data));
      if (data.TotalCount > 20) {
        yield put(setStudentPages(Math.ceil(data.TotalCount / 20)));
      } else {
        yield put(setStudentPages(1));
      }
    }
  } else {
    yield put(fetchStudentsFailed(data.error));
    yield put(resetStudents());
  }
}

// Saga for searching student by name or id
export function* searchStudents({ params }) {
  const queryParams = {
    pageNum: params.pageNum,
    SchoolId: params.schoolID,
    SrchName: params.searchName,
    studentId: params.searchID
  };
  const data = yield call(ManageClassService.searchStudents, queryParams);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(searchStudentSuccess(data));
      if (data.TotalCount > 20) {
        yield put(setSearchPages(Math.ceil(data.TotalCount / 20)));
      } else {
        yield put(setSearchPages(1));
      }
    }
  } else {
    yield put(searchStudentFailed(data.error));
    yield put(resetSearchResults());
  }
}

// Saga for fetching student details by student ID
export function* fetchStudentDetails({ params }) {
  const queryParams = {
    studentID: params.studentID
  };
  const data = yield call(ManageClassService.fetchStudentDetails, queryParams);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchStudentDetailsSuccess(data));
    }
  } else {
    yield put(fetchStudentDetailsFailed(data.error));
  }
}

// Saga for posting edit class request
export function* editClass({ params }) {
  const queryParams = {
    classID: params.classID,
    className: params.className,
    iconID: params.iconID
  };
  const result = yield call(ManageClassService.editClass, queryParams);
  if (!isNil(result)) {
    if (isNil(result.error)) {
      yield put(editClassSuccess());
    } else {
      yield put(editClassFailed(result.error));
    }
  } else {
    yield put(editClassFailed(result.error));
  }
}

// Saga for posting change class request
export function* changeClass({ params }) {
  const queryParams = {
    StudentIDs: params.StudentIDs,
    classID: params.classID
  };
  const result = yield call(ManageClassService.changeClass, queryParams);
  if (!isNil(result)) {
    if (isNil(result.error)) {
      yield put(changeClassSuccess());
    } else {
      yield put(changeClassFailed(result.error));
    }
  } else {
    yield put(changeClassFailed(result.error));
  }
}

// Saga for posting reset password request
export function* resetPassword({ params }) {
  const queryParams = {
    studentID: params.studentID
  };
  const result = yield call(ManageClassService.resetPassword, queryParams);
  if (!isNil(result)) {
    if (isNil(result.error)) {
      yield put(resetPwSuccess());
    } else {
      yield put(resetPwFailed(result.error));
    }
  } else {
    yield put(resetPwFailed(result.error));
  }
}

// Saga for fetching class icons by level
export function* fetchIcons({ params }) {
  const queryParams = {
    level: params.level
  };
  const data = yield call(ManageClassService.fetchIcons, queryParams);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(
        fetchIconsSuccess({
          level: params.level,
          icons: data
        })
      );
    }
  } else {
    yield put(fetchIconsFailed(data.error));
  }
}
