import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header/Header';
import ProfileForm from './ProfileForm/ProfileForm';
import {
  ProfileSettingHeader,
  ProfileFormWrapper
} from './ProfileSettings.style';

export default function ManageClass() {
  const { t } = useTranslation(['profile-settings']);
  return (
    <>
      <Header />
      <ProfileSettingHeader>
        {t(`profile-settings:main.profileSettings`, 'Profile and Settings')}
      </ProfileSettingHeader>
      <ProfileFormWrapper>
        <ProfileForm />
      </ProfileFormWrapper>
    </>
  );
}
