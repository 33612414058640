import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import PopupModal from 'components/Shared/PopupModal';
import TrialAccountModalBody from 'components/TrialAccount/TrialAccountModalBody';
import VideoOverlayContainer from './VideoOverlay.styles';
import 'react-modal-video/scss/modal-video.scss';
import PlayVideoIcon from 'assets/img/icon-play-video.svg';

const VideoOverlay = ({ type, videoId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const showVideo = () => {
    setIsOpen(true);
  };

  const [showTrialAccount, setShowTrialAccount] = useState(false);
  const hideTrialAccount = () => {
    setShowTrialAccount(false);
  };

  return (
    <>
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        youtube={{
          autoplay: 1,
          mute: 1,
          rel: 0
        }}
        videoId={videoId}
        showVideo={showVideo}
        onClose={() => setIsOpen(false)}
      />
      <PopupModal show={showTrialAccount} hide={hideTrialAccount}>
        <TrialAccountModalBody hide={hideTrialAccount} type="trial-video" />
      </PopupModal>
      <VideoOverlayContainer>
        <img
          src={`https://img.youtube.com/vi/${videoId}/mqdefault.jpg`}
          width="225px"
          className="video-thumbnail"
          alt="video-thumbnail"
        />
        {type === 'play-video' && (
          <div className="play-video" onClick={() => setIsOpen(true)}>
            <img src={PlayVideoIcon} className="play-icon" alt="play" />
          </div>
        )}
        {type === 'disabled-video' && (
          <div
            className="disabled-video"
            onClick={() => setShowTrialAccount(true)}
          />
        )}
      </VideoOverlayContainer>
    </>
  );
};

export default VideoOverlay;
