import React from 'react';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'ramda';
import hasProp from 'helpers/hasProp';

export default function AssignResult(props) {
  const { t } = useTranslation(['create-assign-question']);
  const {
    assignedClass,
    assignedGroup,
    assignedStudent,
    assignedPrivateGroup,
    assignedAdminGroup,
    assignedLevel,
    hasAssignees
  } = props;

  const groupedStudents = groupBy(
    student => student.ClassName,
    assignedStudent
  );

  return (
    <>
      <h3>{t(`create-assign-question:assign.assignTo`, 'Assign to')}</h3>
      {hasAssignees ? (
        <>
          {assignedClass.length > 0 && (
            <div className="class-list">
              <h5>
                {t(`create-assign-question:assign.assignTo`, 'Assign to')}
              </h5>
              {assignedClass.map(item => (
                <p key={item.ID}>{item.Name}</p>
              ))}
            </div>
          )}
          {assignedGroup.length > 0 && (
            <div className="group-list">
              <h5>{t(`create-assign-question:assignModal.group`, 'Group')}</h5>
              {assignedGroup.map(item => (
                <p key={item.ID}>{item.Name}</p>
              ))}
            </div>
          )}
          {assignedPrivateGroup.length > 0 && (
            <div className="group-list">
              <h5>
                {t(
                  `create-assign-question:assignModal.pvtGroup`,
                  'Private Group'
                )}
              </h5>
              {assignedPrivateGroup.map(item => (
                <p key={item.ID}>{item.Name}</p>
              ))}
            </div>
          )}
          {hasProp(assignedAdminGroup, 'ID') && (
            <div className="group-list">
              <h5>
                {t(
                  `create-assign-question:assignModal.adminGroup`,
                  'Admin Group'
                )}
              </h5>
              <p>{assignedAdminGroup.Name}</p>
            </div>
          )}
          {assignedLevel !== '' && (
            <div className="group-list">
              <h5>{t(`create-assign-question:assignModal.level`, 'Level')}</h5>
              <p>{`Primary ${assignedLevel}`}</p>
            </div>
          )}
          {assignedStudent.length > 0 && (
            <div className="group-list">
              <h5>
                {t(
                  `create-assign-question:assignModal.indvStud`,
                  'Individual Students'
                )}
              </h5>
              {Object.entries(groupedStudents).map(
                ([studentClass, classOfStudents]) => {
                  return (
                    <React.Fragment key={studentClass}>
                      <p className="text-muted mt-2">{studentClass}</p>
                      {classOfStudents.map(singleStudent => (
                        <div key={singleStudent.UserId}>
                          <p>{singleStudent.FullName}</p>
                        </div>
                      ))}
                    </React.Fragment>
                  );
                }
              )}
            </div>
          )}
        </>
      ) : (
        <p>
          {t(
            `create-assign-question:assignModal.noSelection`,
            'No Classes, Groups or students chosen'
          )}
        </p>
      )}
    </>
  );
}
