import styled from 'styled-components';

export const SelectNumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .diff-type {
    color: ${props => props.color};
    /* text-transform: capitalize; */
  }
  .diff-controls {
    display: flex;
    background: #fff;
    justify-content: center;
    margin-top: 10px;
    .dec-count,
    .inc-count {
      border: 1px solid #c5c5c5;
      color: #727272;
      cursor: pointer;
      padding: 5px 10px;
    }
    .dec-count {
      border-style: solid none solid solid;
      border-radius: 3px 0 0 3px;
    }
    .inc-count {
      border-style: solid solid solid none;
      border-radius: 0 3px 3px 0;
    }
    input {
      border: 1px solid #c5c5c5;
      border: none solid none solid;
      width: 25%;
      text-align: center;
      font-size: 15px;
      color: #222222;
    }
  }
`;
