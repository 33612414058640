import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function SidebarNavLink(props) {
  const { children, path: url } = props;
  const { pathname } = useLocation();
  const subPagePath = url.split('/').slice(1)[1];
  const isActivePath = path =>
    path === pathname || pathname.includes(subPagePath);
  return (
    <Link
      className={`nav-link${isActivePath(url) ? ' nav-link-active' : ''}`}
      to={url}
    >
      {children}
    </Link>
  );
}
