import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import topicRevisionIcon from 'assets/img/icon-topic-revision.svg';
import questionCartIcon from 'assets/img/icon-question-cart.svg';
import topicalWorksheetIcon from 'assets/img/icon-topical-worksheet.svg';
import factualFluencyIcon from 'assets/img/icon-factual-fluency-white.png';
import { resetWorksheetSearchPage } from 'stores/worksheet/actions';
import { featureToggle } from 'constants/index';
import SidebarNavLink from './SidebarNavLink';

export default function SidebarCreateAssign() {
  const { t } = useTranslation(['create-assign-question']);
  const dispatch = useDispatch();
  const { isAdmin } = useSelector(state => state.login);

  return (
    <div className="sidebar-create-assign">
      <ul className="sidebar-create-assign__ul list-group">
        <li className="list-group-item">
          {/* @todo dynamically set active class from redux state */}
          <SidebarNavLink path="/create-and-assign">
            <img
              src={questionCartIcon}
              alt="icon-list"
              className="sidebar-icon icon-question-cart"
            />
            <span>
              {t(
                `create-assign-question:sidebar.questionCart`,
                'Question Cart'
              )}
            </span>
          </SidebarNavLink>
        </li>
        <li
          className="list-group-item"
          onClick={() => dispatch(resetWorksheetSearchPage())}
        >
          <SidebarNavLink path="/create-and-assign/saved-worksheet">
            <i className="icon-saved-worksheet" />
            <span>
              {t(`create-assign-question:sidebar.savedWo`, 'Saved worksheet')}
            </span>
          </SidebarNavLink>
        </li>
        <li className="list-group-item">
          <SidebarNavLink path="/create-and-assign/topical-worksheet">
            <img
              src={topicalWorksheetIcon}
              alt="icon-list"
              className="sidebar-icon icon-question-cart"
            />
            <span>
              {t(
                `create-assign-question:sidebar.topicalWo`,
                'Topical worksheet'
              )}
            </span>
          </SidebarNavLink>
        </li>
        <li className="list-group-item">
          <SidebarNavLink path="/create-and-assign/factual-fluency">
            <img
              src={factualFluencyIcon}
              alt="icon-list"
              className="sidebar-icon icon-question-cart"
            />
            <span>
              {t(
                `create-assign-question:sidebar.factualFluency`,
                'Factual Fluency'
              )}
            </span>
          </SidebarNavLink>
        </li>
        {featureToggle.topicRevision && (
          <li className="list-group-item">
            <SidebarNavLink path="/create-and-assign/topic-revision">
              <img
                src={topicRevisionIcon}
                alt="icon-list"
                className="sidebar-icon icon-topic-revision"
              />
              <span>Topic Revision</span>
            </SidebarNavLink>
          </li>
        )}
        {isAdmin && (
          <li className="list-group-item">
            <SidebarNavLink path="/create-and-assign/import-questions">
              <img
                src={topicRevisionIcon}
                alt="icon-list"
                className="sidebar-icon icon-import-question"
              />
              <span>
                {t(
                  `create-assign-question:sidebar.importQnList`,
                  'Import Question List'
                )}
              </span>
            </SidebarNavLink>
          </li>
        )}
      </ul>
    </div>
  );
}
