import React from 'react';
import ContentLoader from 'react-content-loader';

export default function SkillsLoader() {
  const randomWidth = Math.floor(Math.random() * (340 - 80 + 1)) + 80;
  return (
    <li className="bg-white" style={{ height: 60, paddingTop: 18 }}>
      <ContentLoader>
        <rect x="8" y={1} rx="4" ry="4" width={randomWidth} height="7" />
      </ContentLoader>
    </li>
  );
}
