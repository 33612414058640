import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchPrivateGroupsRequest } from 'stores/privateGroup/actions';
import { GroupsListPage } from './GroupsList.styles';

/**
 * @todo when the UI for empty cart questions done, use cartQuestions from reducer or other resources
 * @note client still not confirm whether cartQuestions is the data we will be use
 */
export default function GroupList() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['manage-class-private-group']);

  const [groupName, setGroupName] = useState('');
  const { privateGroups } = useSelector(state => state.privateGroup);

  const handleChangeGroupName = group => {
    setGroupName(group);
  };

  useEffect(() => {
    dispatch(fetchPrivateGroupsRequest(groupName));
  }, [dispatch, groupName]);

  return (
    <GroupsListPage>
      <h5>
        {t('manage-class-private-group:all.title', 'Search Private Groups')}
      </h5>
      <Link
        type="button"
        className="btn btn-primary btn-private-create"
        to="/manage-class/add"
        style={{
          marginRight: '1rem'
        }}
      >
        {t('manage-class-private-group:all.createBtn', 'Create New')}
      </Link>
      <Form.Group className="col-md-12">
        <input
          type="text"
          className="form-control"
          placeholder={t(
            'manage-class-private-group:all.placeholder',
            'Search by group name'
          )}
          value={groupName}
          onChange={event => handleChangeGroupName(event.target.value)}
        />
      </Form.Group>

      <ul className="list-group" style={{ padding: '0 1rem' }}>
        {privateGroups.map(group => (
          <li key={group.id} className="list-group-item">
            <span className="list-group-initial">
              {group.Name.substr(0, 1)}
            </span>{' '}
            {group.Name}
            {/* @todo pending API for edit and delete */}
            {/* <button type="button" className="btn btn-private">
              Edit
            </button>
            <button type="button" className="btn btn-private">
              Delete
            </button> */}
          </li>
        ))}
      </ul>
    </GroupsListPage>
  );
}
