import apiClient from 'services/api';

const handleResponseSuccess = result => {
  if (result.data?.IsSuccessful === false) {
    return {
      error: result.data?.Message?.Message ?? 'API request failed' // eslint-disable-line
    };
  }
  return result.data?.Result ?? [];
};

const fetchGameSettingClass = () => {
  return apiClient
    .get(`GameAccess/GetAllTeacherGroups`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch class failed' // eslint-disable-line
      };
    });
};

const fetchGameSettingStudentByGroup = GroupID => {
  return apiClient
    .get(`GameAccess/GetStudentsList/${GroupID}`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch class failed' // eslint-disable-line
      };
    });
};

const saveGameSetting = params => {
  const options = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return apiClient
    .post(`GameAccess/SaveGameSettingForStudents`, params, options)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Save game setting failed' // eslint-disable-line
      };
    });
};

export default {
  fetchGameSettingClass,
  fetchGameSettingStudentByGroup,
  saveGameSetting
};
