import styled from 'styled-components';

export const Header = styled.div`
  margin-top: 2rem;
  padding-right: 250px;
  padding-left: 205px;
  @media (max-width: 1024px) {
    padding-left: 66px;
  }
  @media (max-width: 640px) {
    padding-left: 0;
  }
`;
