import {
  FETCH_PROFILE_DATA_REQUEST,
  FETCH_PROFILE_DATA_SUCCESS,
  FETCH_PROFILE_DATA_FAILED,
  FETCH_AVATARS_REQUEST,
  FETCH_AVATARS_SUCCESS,
  FETCH_AVATARS_FAILED,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILED,
  EMPTY_UPDATE_PROFILE
} from './actionTypes';

export const fetchProfileDataRequest = () => {
  return {
    type: FETCH_PROFILE_DATA_REQUEST
  };
};

export const fetchProfileDataSuccess = data => {
  return {
    type: FETCH_PROFILE_DATA_SUCCESS,
    data
  };
};

export const fetchProfileDataFailed = error => {
  return {
    type: FETCH_PROFILE_DATA_FAILED,
    error
  };
};

export const fetchAvatarsRequest = () => {
  return {
    type: FETCH_AVATARS_REQUEST
  };
};

export const fetchAvatarsSuccess = data => {
  return {
    type: FETCH_AVATARS_SUCCESS,
    data
  };
};

export const fetchAvatarsFailed = error => {
  return {
    type: FETCH_AVATARS_FAILED,
    error
  };
};

export const updateProfileRequest = (params, updateProfile) => {
  return {
    type: UPDATE_PROFILE_REQUEST,
    params,
    updateProfile
  };
};

export const updateProfileSuccess = data => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    data
  };
};

export const updateProfileFailed = error => {
  return {
    type: UPDATE_PROFILE_FAILED,
    error
  };
};

export const emptyUpdateProfile = () => {
  return {
    type: EMPTY_UPDATE_PROFILE
  };
};
