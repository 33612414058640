import {
  SET_SELECTED_QUESTIONS,
  REMOVE_SELECTED_SAVED_WORKSHEET_QUESTION,
  SET_EDIT_SAVED_WORKSHEET_QUESTIONS,
  SET_WORKSHEET_TITLE,
  SET_INSTRUCTION,
  SET_ASSIGMENT_TYPE,
  SET_PRESENTATION_TYPE,
  REMOVE_SELECTED_QUESTION,
  ASSIGN_WORKSHEET,
  SAVE_WORKSHEET,
  DELETE_WORKSHEET,
  DELETE_WORKSHEET_SUCCESS,
  DELETE_WORKSHEET_FAILED,
  DELETE_WORKSHEET_RESET,
  SELECTING_WORKSHEET,
  SELECTED_WORKSHEET,
  SELECTED_WORKSHEET_FAILED,
  RESET_SELECTED_WORKSHEET,
  FETCH_WORKSHEETS_SUCCESS,
  FETCH_WORKSHEETS_REQUEST,
  FETCH_WORKSHEETS_FAILED,
  ASSIGN_CLASS,
  ASSIGN_GROUP,
  ASSIGN_STUDENT,
  ASSIGN_PRIVATE_GROUP,
  ASSIGN_ADMIN_GROUP,
  ASSIGN_LEVEL,
  RESET_ASSIGNMENT,
  RESET_WORKSHEET,
  FETCH_ALL_WORKSHEETS_REQUEST,
  FETCH_ALL_WORKSHEETS_SUCCESS,
  FETCH_ALL_WORKSHEETS_FAILED,
  SCHEDULE_WORKSHEET,
  SET_SETTING_TIME_TYPE,
  SET_SETTING_TIME_LIMIT,
  GET_WORKSHEET_REQUEST,
  RESET_WORKSHEET_SEARCH_PAGE
} from './actionTypes';

export const assignWorksheet = (data, isPublish) => {
  return { type: ASSIGN_WORKSHEET, data, isPublish };
};

export const saveWorksheet = worksheet => {
  return { type: SAVE_WORKSHEET, worksheet };
};

export const setSelectedQuestions = selectedQuestions => {
  return { type: SET_SELECTED_QUESTIONS, selectedQuestions };
};

export const setEditSavedWorksheetQuestions = questions => {
  return {
    type: SET_EDIT_SAVED_WORKSHEET_QUESTIONS,
    questions
  };
};

export const setWorksheetTitle = title => {
  return { type: SET_WORKSHEET_TITLE, title };
};

export const setIntruction = intruction => {
  return { type: SET_INSTRUCTION, intruction };
};

export const removeSelectedQuestion = Id => {
  return { type: REMOVE_SELECTED_QUESTION, Id };
};

export const removeSelectedSavedWorksheetQuestion = Id => {
  return { type: REMOVE_SELECTED_SAVED_WORKSHEET_QUESTION, Id };
};
export const fetchWorksheetsRequest = (page, limit) => {
  return { type: FETCH_WORKSHEETS_REQUEST, page, limit };
};

export const fetchWorksheetsSuccess = worksheets => {
  return { type: FETCH_WORKSHEETS_SUCCESS, worksheets };
};

export const fetchWorksheetsFailed = error => {
  return { type: FETCH_WORKSHEETS_FAILED, error };
};

// detail worksheet
export const selectWorksheet = (
  selectedWorksheet,
  withCart,
  savedWorksheet,
  assignmentType
) => {
  return {
    type: SELECTING_WORKSHEET,
    selectedWorksheet,
    withCart,
    savedWorksheet,
    assignmentType
  };
};

// detail worksheet
export const getWorksheet = worksheetId => {
  return {
    type: GET_WORKSHEET_REQUEST,
    worksheetId
  };
};

export const selectWorksheetSuccess = selectedWorksheet => {
  return { type: SELECTED_WORKSHEET, selectedWorksheet };
};

export const selectWorksheetFailed = error => {
  return { type: SELECTED_WORKSHEET_FAILED, error };
};

export const resetSelectedWorksheet = () => {
  return { type: RESET_SELECTED_WORKSHEET };
};

export const assignClass = classes => {
  return { type: ASSIGN_CLASS, classes };
};

export const assignGroup = groups => {
  return { type: ASSIGN_GROUP, groups };
};

export const assignStudent = students => {
  return { type: ASSIGN_STUDENT, students };
};

export const assignPrivateGroup = privateGroups => {
  return { type: ASSIGN_PRIVATE_GROUP, privateGroups };
};

export const assignAdminGroup = adminGroups => {
  return { type: ASSIGN_ADMIN_GROUP, adminGroups };
};

export const assignLevel = level => {
  return { type: ASSIGN_LEVEL, level };
};

export const resetAssignment = assignmentType => {
  return {
    type: RESET_ASSIGNMENT,
    assignmentType
  };
};

export const resetWorksheet = () => {
  return { type: RESET_WORKSHEET };
};

export const fetchAllWorksheetsRequest = () => {
  return { type: FETCH_ALL_WORKSHEETS_REQUEST };
};

export const fetchAllWorksheetsSuccess = allWorksheets => {
  return { type: FETCH_ALL_WORKSHEETS_SUCCESS, allWorksheets };
};

export const fetchAllWorksheetsFailed = error => {
  return { type: FETCH_ALL_WORKSHEETS_FAILED, error };
};

export const deleteWorksheet = worksheetId => {
  return { type: DELETE_WORKSHEET, worksheetId };
};

export const deleteWorksheetSuccess = () => {
  return { type: DELETE_WORKSHEET_SUCCESS };
};

export const deleteWorksheetFailed = () => {
  return { type: DELETE_WORKSHEET_FAILED };
};

export const deleteWorksheetReset = () => {
  return { type: DELETE_WORKSHEET_RESET };
};

export const scheduleWorksheet = data => {
  return { type: SCHEDULE_WORKSHEET, data };
};

export const setAssigmentType = assignmentType => {
  return { type: SET_ASSIGMENT_TYPE, assignmentType };
};

export const setPresentationType = presentationType => {
  return { type: SET_PRESENTATION_TYPE, presentationType };
};

export const setSettingTimeType = timeSettingType => {
  return { type: SET_SETTING_TIME_TYPE, timeSettingType };
};

export const setSettingTimeValue = timeLimit => {
  return { type: SET_SETTING_TIME_LIMIT, timeLimit };
};
export const resetWorksheetSearchPage = () => {
  return { type: RESET_WORKSHEET_SEARCH_PAGE };
};
