import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import IconIvm from 'assets/img/icon-ivm.svg';
import IconQuestion from 'assets/img/icon-questions.svg';
import IconPlay from 'assets/img/icon-play.svg';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import { useHistory } from 'react-router-dom';
import { sort } from 'ramda';
import { selectTopic } from 'stores/topics/actions';
import openIVM from 'helpers/openIVM';

export default function ContentType() {
  const { t } = useTranslation(['browse-content']);
  const dispatch = useDispatch();
  const { isTrial } = useSelector(state => state.login);
  const { topics } = useSelector(state => state.topics);
  const sortedTopics = sort((a, b) => a.Display - b.Display, topics);
  const history = useHistory();

  const contentTypes = [
    {
      name: t(`browse-content:main.questions`, 'Questions'),
      url: null,
      icon: IconQuestion
    },
    {
      name: t(`browse-content:main.videoTutorials`, 'Video Tutorials'),
      url: null,
      icon: IconPlay
    },
    {
      name: 'IVM',
      url: null,
      icon: IconIvm
    }
  ];
  const [contentType, setContentType] = useState(contentTypes[0].name);

  const [isOpen, setIsOpen] = useState(false);
  const showVideo = () => {
    setIsOpen(true);
  };

  return (
    <>
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        youtube={{
          autoplay: 1,
          mute: 1
        }}
        videoId="_Hu_UCRzX5g"
        showVideo={showVideo}
        onClose={() => setIsOpen(false)}
      />
      <div className="row align-items-center">
        <div className="col-sm-auto">
          <div className="pt-2 py-sm-0 subbar-label content-type-label">
            {t(`browse-content:main.contentType`, 'Content Type')}
          </div>
        </div>
        <div className="col-sm">
          <ul className="nav">
            {contentTypes.map((item, index) => (
              <li
                key={String(index)}
                className={`nav-item ${item.name === contentType && 'active'}`}
              >
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e => {
                    if (item.name === 'IVM' && isTrial) {
                      setContentType(item.name);
                      showVideo();
                    } else if (item.name === 'IVM' && !isTrial) {
                      e.preventDefault();
                      openIVM();
                    } else if (
                      item.name ===
                      t(`browse-content:main.videoTutorials`, 'Video Tutorials')
                    ) {
                      e.preventDefault();
                      setContentType(item.name);
                      history.push('/browse-content/problemsums-trial');
                    } else if (item.url === null && item.name !== contentType) {
                      e.preventDefault();
                      setContentType(item.name);
                      history.push('/browse-content/question-list');
                      dispatch(selectTopic(sortedTopics[0]));
                    }
                  }}
                  className="nav-link"
                >
                  <img
                    src={item.icon}
                    className={
                      item.name ===
                      t(`browse-content:main.videoTutorials`, 'Video Tutorials')
                        ? 'mr-2 play-rounded'
                        : 'mr-2'
                    }
                    alt={item.name}
                  />
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
