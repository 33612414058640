import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { ProfileModal } from './ProfileImage.style';

const ProfileImageModal = props => {
  const { t } = useTranslation(['profile-settings']);
  const { show, handleClose, avatarId, saveImage, resultImages } = props;
  const [selectedId, setSelectedId] = useState(avatarId);

  const setImage = () => {
    const altImg = resultImages[selectedId];
    saveImage(altImg, selectedId);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <ProfileModal>
        <h5>
          {t(`profile-settings:profilePic.changePic`, 'Change Profile Picture')}
        </h5>
        <div className="img-modal-body">
          {Object.keys(resultImages).map(item => (
            <div
              onClick={() => setSelectedId(item)}
              className={`imgs-container ${selectedId === item &&
                'img-active'}`}
              key={item}
            >
              <img
                className="all-imgs"
                src={resultImages[item]}
                alt="default-pic"
              />
            </div>
          ))}
        </div>
        <div className="img-modal-footer">
          <button
            type="button"
            className="btn btn-outline-light"
            onClick={handleClose}
          >
            {t(`profile-settings:profilePic.cancel`, 'Cancel')}
          </button>
          <button type="button" className="btn btn-primary" onClick={setImage}>
            {t(`profile-settings:profilePic.confirm`, 'Confirm')}
          </button>
        </div>
      </ProfileModal>
    </Modal>
  );
};

export default ProfileImageModal;
