export const FETCH_TOPIC_SKILL_REQUEST = 'FETCH_TOPIC_SKILL_REQUEST';
export const FETCH_TOPIC_SKILL_SUCCESS = 'FETCH_TOPIC_SKILL_SUCCESS';
export const FETCH_TOPIC_SKILL_FAILED = 'FETCH_TOPIC_SKILL_FAILED';
export const NO_TOPIC_SKILL = 'NO_TOPIC_SKILL';
export const CREATE_FACTUAL_FLUENCY_RESET = 'CREATE_FACTUAL_FLUENCY_RESET';
export const CREATE_FACTUAL_FLUENCY_REQUEST = 'CREATE_FACTUAL_FLUENCY_REQUEST';
export const CREATE_FACTUAL_FLUENCY_SUCCESS = 'CREATE_FACTUAL_FLUENCY_SUCCESS';
export const CREATE_FACTUAL_FLUENCY_FAILED = 'CREATE_FACTUAL_FLUENCY_FAILED';
export const CREATE_FACTUAL_FLUENCY_ERROR = 'CREATE_FACTUAL_FLUENCY_ERROR';
export const SET_CREATE_FACTUAL_FLUENCY_QUESTIONS =
  'SET_CREATE_FACTUAL_FLUENCY_QUESTIONS';
export const REMOVE_CREATE_FACTUAL_FLUENCY_QUESTION =
  'REMOVE_CREATE_FACTUAL_FLUENCY_QUESTION';
export const FETCH_FACTUAL_FLUENCY_LEADERBOARD_REQUEST =
  'FETCH_FACTUAL_FLUENCY_LEADERBOARD_REQUEST';
export const FETCH_FACTUAL_FLUENCY_LEADERBOARD_SUCCESS =
  'FETCH_FACTUAL_FLUENCY_LEADERBOARD_SUCCESS';
export const FETCH_FACTUAL_FLUENCY_LEADERBOARD_FAILED =
  'FETCH_FACTUAL_FLUENCY_LEADERBOARD_FAILED';
export const FACTUAL_FLUENCY_LEADERBOARD_RESET =
  'FACTUAL_FLUENCY_LEADERBOARD_RESET';
