import styled from 'styled-components';

export const QuestionNumber = styled.p`
  position: absolute;
  left: 23px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0px;
  color: ${props => (props.active ? '#ff5b00' : 'rgba(52, 52, 52, 0.8)')};
`;

export const QuestionBox = styled.div`
  border: 1px solid rgba(112, 112, 112, 0.14);
`;
