import {
  FETCH_PROFILE_DATA_REQUEST,
  FETCH_PROFILE_DATA_SUCCESS,
  FETCH_PROFILE_DATA_FAILED,
  FETCH_AVATARS_REQUEST,
  FETCH_AVATARS_SUCCESS,
  FETCH_AVATARS_FAILED,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILED,
  EMPTY_UPDATE_PROFILE
} from './actionTypes';

const initialState = {
  isLoading: false,
  error: null,
  profileData: {},
  avatars: {},
  updateData: {}
};

export default function profileSettings(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROFILE_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        profileData: {}
      };
    case FETCH_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        profileData: action.data
      };
    case FETCH_PROFILE_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case FETCH_AVATARS_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case FETCH_AVATARS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        avatars: action.data
      };
    case FETCH_AVATARS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateData: action.data,
        error: null
      };
    case UPDATE_PROFILE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case EMPTY_UPDATE_PROFILE:
      return {
        ...state,
        updateData: {}
      };
    default:
      return state;
  }
}
