import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { ReactComponent as Logo } from 'assets/img/logo.svg';
import DefaultLogo from 'assets/img/logo.svg';
import TeacherLogo from 'assets/img/logoTeacher.svg';
// import TutorLogo from 'assets/img/logoTutor.svg';
import AdminLogo from 'assets/img/logoAdmin.svg';
import NavbarCollapse from './NavbarCollapse';
import { HeaderTrial } from './Header.styles';

const Header = () => {
  const { isTrial, isSchoolAdmin, isTeacher } = useSelector(
    state => state.login
  );
  const history = useHistory();
  const headerVisibility =
    history.location.pathname === '/login' ? 'd-none' : '';

  let logo = DefaultLogo;
  if (isSchoolAdmin) logo = AdminLogo;
  else if (isTeacher) logo = TeacherLogo;
  return (
    <nav className={`navbar navbar-expand fixed-top ${headerVisibility}`}>
      <Link className="navbar-brand" to="/dashboard">
        {isTrial ? (
          <>
            <img src={DefaultLogo} alt="trial-logo" />
            <HeaderTrial>School Trial</HeaderTrial>
          </>
        ) : (
          <img
            src={logo}
            alt="Logo"
            onError={e => (e.target.src = DefaultLogo)}
          />
        )}
      </Link>
      <NavbarCollapse />
    </nav>
  );
};

export default Header;
