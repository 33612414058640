// Cart questions
export const ADD_QUESTION_TO_CART = 'ADD_QUESTION_TO_CART';
export const ADD_QUESTIONS_TO_CART = 'ADD_QUESTIONS_TO_CART';
export const DELETE_QUESTION_FROM_CART = 'DELETE_QUESTION_FROM_CART';
export const FETCH_CART_QUESTIONS_FAILED = 'FETCH_CART_QUESTIONS_FAILED';
export const FETCH_CART_QUESTIONS_REQUEST = 'FETCH_CART_QUESTIONS_REQUEST';
export const FETCH_CART_QUESTIONS_SUCCESS = 'FETCH_CART_QUESTIONS_SUCCESS';
export const RESET_CART_QUESTIONS = 'RESET_CART_QUESTIONS';

// questions lists
export const FETCH_QUESTIONS_FAILED = 'FETCH_QUESTIONS_FAILED';
export const FETCH_QUESTIONS_REQUEST = 'FETCH_QUESTIONS_REQUEST';
export const FETCH_QUESTIONS_SUCCESS = 'FETCH_QUESTIONS_SUCCESS';

// selecting question, usefull when load question detail as active question for variation
export const RESET_SELECTED_QUESTION = 'RESET_SELECTED_QUESTION';
export const SELECTED_QUESTION = 'SELECTED_QUESTION';
export const SELECTING_QUESTION = 'SELECTING_QUESTION';

// selecting questions result search
export const RESET_SELECTED_QUESTIONS = 'RESET_SELECTED_QUESTION';
export const SELECTING_QUESTIONS = 'SELECTING_QUESTIONS';

export const SEARCHING_QUESTIONS = 'SEARCHING_QUESTIONS';
export const SEARCH_QUESTIONS_SUCCESS = 'SEARCH_QUESTIONS_SUCCESS';
export const SEARCH_QUESTIONS_FAILED = 'SEARCH_QUESTIONS_FAILED';
export const RESET_SEARCH_QUESTIONS = 'RESET_SEARCH_QUESTIONS';
