import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClassRequest } from 'stores/utils/lookup/actions';
import { Dropdown } from 'react-bootstrap';
import moment from 'moment';
import PrintIcon from 'assets/img/print-icon.svg';
import { PDFDownloadLink } from '@react-pdf/renderer';
import LoginReportPDF from 'components/Report/LoginReportPDF';
import LoginReportTable from 'components/Report/LoginReportTable';
import generateLevels from 'helpers/reportLevels';
import Spinner from 'components/Shared/Spinner';
import {
  fetchLoginReportRequest,
  fetchWeekItemsRequest,
  setSelectedClass,
  setClassLevel
} from 'stores/report/actions';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { LoginReportContainer, SeparationBar } from './LoginReport.styles';

const LoginReport = () => {
  const { t } = useTranslation(['login-report']);
  const dispatch = useDispatch();
  // Redux States
  const {
    loginReport,
    weekItems,
    selectedClass,
    selectedClassLevel,
    isLoading
  } = useSelector(state => state.reports);
  const { classes } = useSelector(state => state.lookup);
  const reportLevels = generateLevels();
  const [weekId, setWeekId] = useState(0);

  // Filter out only classes of type 1
  const filteredClasses =
    classes.length > 0
      ? classes.filter(classObject => classObject.Type === 1)
      : [];

  useEffect(() => {
    dispatch(fetchClassRequest(selectedClassLevel));
  }, [dispatch, selectedClassLevel]);

  useEffect(() => {
    dispatch(fetchWeekItemsRequest({ numberOfPreviousWeek: 3 }));
  }, [dispatch]);

  useDeepCompareEffect(() => {
    if (selectedClass) {
      dispatch(
        fetchLoginReportRequest({
          classID: selectedClass && selectedClass.ID,
          weekID: weekId
        })
      );
    }
  }, [dispatch, selectedClass, weekId]);

  return (
    <LoginReportContainer>
      <div className="top-bar">
        <div className="title">
          {t(`login-report:header.loginReport`, 'Login Report')}
        </div>
        <div className="top-bar-content">
          <div className="dropdown-section">
            <p className="dropdown-label">
              {t(`login-report:header.class`, 'Class')}
            </p>
            <Dropdown>
              <Dropdown.Toggle
                variant="light"
                className="dropdown-custom level"
              >
                {`P${selectedClassLevel}`}
              </Dropdown.Toggle>
              <Dropdown.Menu flip={false}>
                {reportLevels.map(level => (
                  <Dropdown.Item
                    key={level}
                    onClick={() => {
                      dispatch(setClassLevel(level));
                    }}
                  >{`P${level}`}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
              <Dropdown.Toggle
                variant="light"
                className="dropdown-custom class"
              >
                {selectedClass && selectedClass.Name ? selectedClass.Name : '-'}
              </Dropdown.Toggle>
              <Dropdown.Menu flip={false}>
                {filteredClasses.map(item => (
                  <Dropdown.Item
                    key={item.ID}
                    onClick={event => {
                      event.preventDefault();
                      dispatch(setSelectedClass(item));
                    }}
                  >
                    {item.Name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="weekly-date-section">
            <button
              className="nav-btn"
              type="button"
              disabled={weekId === 2}
              onClick={() => {
                setWeekId(weekId + 1);
              }}
            >{`<`}</button>
            <p className="week">{`${t(`login-report:header.week`, 'Week')} ${
              weekItems
                ? moment(
                    weekItems[weekId].Display.split(' - ')[1],
                    'DD MMM YYYY'
                  ).isoWeek()
                : ''
            }`}</p>
            <p className="date">
              {isLoading
                ? 'Loading...'
                : weekItems && weekItems[weekId].Display}
            </p>
            <button
              className="nav-btn"
              type="button"
              disabled={weekId === 0}
              onClick={() => {
                setWeekId(weekId - 1);
              }}
            >{`>`}</button>
          </div>
          {!isLoading && (
            <PDFDownloadLink
              document={
                <LoginReportPDF
                  data={loginReport}
                  weekId={weekId}
                  selectedLevel={selectedClassLevel}
                  selectedClass={selectedClass && selectedClass.Name}
                  date={weekItems && weekItems[weekId].Display}
                />
              }
              fileName={`KooBits Login Report - ${weekItems &&
                weekItems[weekId].Display} - ${selectedClass &&
                selectedClass.Name}.pdf`}
            >
              <div className="action-button">
                <button>
                  <span>
                    <img src={PrintIcon} alt="print-icon" />
                  </span>
                  {t(`login-report:header.print`, 'Print')}
                </button>
              </div>
            </PDFDownloadLink>
          )}
        </div>
      </div>
      <SeparationBar />
      {isLoading ? (
        <div style={{ marginTop: '200px' }}>
          <Spinner />
        </div>
      ) : (
        weekItems && (
          <LoginReportTable
            data={loginReport}
            weekId={weekId}
            weekItems={weekItems}
          />
        )
      )}
    </LoginReportContainer>
  );
};

export default LoginReport;
