/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useDispatch } from 'react-redux';
import { setShowTrialPopup } from 'stores/login/actions';
import { Link, withRouter } from 'react-router-dom';

const NavItem = ({ isActive, to, children, externalLink }) => {
  const dispatch = useDispatch();
  const classes = ['nav-item', 'text-icon'];
  if (isActive) classes.push('active');

  return (
    <li
      className={classes.join(' ')}
      onClick={() => {
        dispatch(setShowTrialPopup(true));
      }}
      style={{ width: '17%' }}
    >
      {externalLink && (
        <a
          className="nav-link main-menu"
          href={to}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      )}
      {!externalLink && (
        <Link className="nav-link main-menu" to={to}>
          {children}
        </Link>
      )}
    </li>
  );
};

export default withRouter(({ location, match, ...props }) => {
  let isActive = location.pathname.slice(0, props.to.length) === props.to;
  if (props.to === '/') {
    isActive = false;
  }
  return <NavItem {...props} isActive={isActive} />; /* eslint-disable-line */
});
