import styled from 'styled-components';

export const ProfileSettingHeader = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
  padding: 25px;
  text-align: center;
  color: #202020;
  font-size: 16px;
  font-weight: bold;
`;

export const ProfileFormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
`;
