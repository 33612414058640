import React from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from 'components/Report/ProgressBar';
import GradeAIcon from 'assets/img/icon-report-high-score-a.svg';
import GradeBIcon from 'assets/img/icon-report-high-score-b.svg';
import GradeCIcon from 'assets/img/icon-report-high-score-c.svg';
import GradeDIcon from 'assets/img/icon-report-high-score-d.svg';
import IncompleteIcon from 'assets/img/icon-report-high-score-incomplete.svg';
import {
  proficiencyColors,
  ProficiencyStatus,
  MasteryLevel
} from 'constants/index';
import calculatePercentageString from 'helpers/calculatePercentageString';
import { SkillSection, ProficiencyBadge } from './StudentReport.styles';

// Helper for parsing api to input format for ProgressBar
const sortSkills = skills => {
  const progressBarInput = [
    {
      percentage: '',
      color: proficiencyColors.mastery.color,
      borderColor: proficiencyColors.mastery.borderColor
    },
    {
      percentage: '',
      color: proficiencyColors.competent.color,
      borderColor: proficiencyColors.competent.borderColor
    },
    {
      percentage: '',
      color: proficiencyColors.beginning.color,
      borderColor: proficiencyColors.beginning.borderColor
    },
    {
      percentage: '',
      color: proficiencyColors.incomplete.color,
      borderColor: proficiencyColors.incomplete.borderColor
    }
  ];
  const proficiencyIndex = {
    incomplete: 3,
    beginning: 2,
    competent: 1,
    mastery: 0
  };
  const sortedSkills = {
    incomplete: 0,
    beginning: 0,
    competent: 0,
    mastery: 0
  };
  let total = 0;
  // Sort skills by proficiency status
  for (const skill of skills) {
    const proficiency = ProficiencyStatus[skill.ProficiencyStatus];
    sortedSkills[proficiency] += 1;
    total += 1;
  }
  for (const proficiency of Object.keys(sortedSkills)) {
    if (sortedSkills[proficiency] > 0) {
      // Calculate percentage string
      const percentageString = calculatePercentageString(
        sortedSkills[proficiency],
        total
      );
      progressBarInput[
        proficiencyIndex[proficiency]
      ].percentage = percentageString;
    } else {
      progressBarInput[proficiencyIndex[proficiency]].percentage = '0%';
    }
  }
  return progressBarInput.filter(
    proficiency => proficiency.percentage !== '0%'
  );
};

const StudentReport = ({
  setShowSkillDetails,
  type,
  selectedSkill,
  setSelectedSkill,
  setSelectedSkillTopic,
  setSelectedSkillNumber,
  data
}) => {
  const { t } = useTranslation(['proficiency-report']);
  return (
    <>
      {data &&
        data.map((topic, index) => (
          <SkillSection key={topic.TopicId} index={index} type={type}>
            <p className="skill-title">
              <strong>{topic.TopicName}</strong>
            </p>
            {type === 'proficiency' && (
              <div className="progressbar">
                <ProgressBar
                  height="11px"
                  visualParts={sortSkills(topic.Skills)}
                />
                <p>
                  <strong>{topic.SkillMastered}</strong>
                  {` / ${topic.TotalNoOfSkill} ${t(
                    'proficiency-report:studentReport.skillsMastered',
                    'Skills mastered'
                  )} ( ${t(
                    'proficiency-report:studentReport.classAvg',
                    'Class Avg'
                  )} `}
                  <strong>{topic.ClassAverage}</strong>
                  {` / ${topic.TotalNoOfSkill} )`}
                </p>
              </div>
            )}
            <div className="skill-badges">
              {type === 'proficiency' &&
                topic.Skills.map((skill, index) => (
                  <div
                    className="badge"
                    key={`${topic.TopicId}-${skill.SkillId}`}
                    style={{
                      background:
                        selectedSkill &&
                        selectedSkill.SkillId === skill.SkillId &&
                        'rgba(174, 128, 180, 0.24)'
                    }}
                    onClick={() => {
                      setShowSkillDetails(true);
                      setSelectedSkill(skill);
                      setSelectedSkillTopic(topic.TopicName);
                      setSelectedSkillNumber(index + 1);
                    }}
                  >
                    {ProficiencyStatus[skill.ProficiencyStatus] ===
                      'mastery' && (
                      <ProficiencyBadge proficiency="mastery">
                        <span>
                          {t(
                            'proficiency-report:studentReportSide.skill',
                            'Skill'
                          )}
                        </span>
                        <span>{index + 1}</span>
                      </ProficiencyBadge>
                    )}
                    {ProficiencyStatus[skill.ProficiencyStatus] ===
                      'competent' && (
                      <ProficiencyBadge proficiency="competent">
                        <span>
                          {t(
                            'proficiency-report:studentReportSide.skill',
                            'Skill'
                          )}
                        </span>
                        <span>{index + 1}</span>
                      </ProficiencyBadge>
                    )}
                    {ProficiencyStatus[skill.ProficiencyStatus] ===
                      'beginning' && (
                      <ProficiencyBadge proficiency="beginning">
                        <span>
                          {t(
                            'proficiency-report:studentReportSide.skill',
                            'Skill'
                          )}
                        </span>
                        <span>{index + 1}</span>
                      </ProficiencyBadge>
                    )}
                    {ProficiencyStatus[skill.ProficiencyStatus] ===
                      'incomplete' && (
                      <ProficiencyBadge proficiency="incomplete">
                        <span>
                          {t(
                            'proficiency-report:studentReportSide.skill',
                            'Skill'
                          )}
                        </span>
                        <span>{index + 1}</span>
                      </ProficiencyBadge>
                    )}
                    <p className="skill-percentage">
                      <strong>{`${Math.round(
                        skill.Proficiency * 100
                      )}%`}</strong>
                    </p>
                  </div>
                ))}
              {type === 'highscore' &&
                topic.Skills.map((skill, index) => (
                  <div
                    className="badge"
                    key={`${topic.TopicId}-${skill.SkillId}`}
                    style={{
                      background:
                        selectedSkill &&
                        selectedSkill.SkillId === skill.SkillId &&
                        'rgba(174, 128, 180, 0.24)'
                    }}
                    onClick={() => {
                      setShowSkillDetails(true);
                      setSelectedSkill(skill);
                      setSelectedSkillTopic(topic.TopicName);
                      setSelectedSkillNumber(index + 1);
                    }}
                  >
                    {MasteryLevel[skill.MasteryLevel] === 'A' && (
                      <img
                        src={GradeAIcon}
                        alt="Grade A"
                        className="grade-icon"
                      />
                    )}
                    {MasteryLevel[skill.MasteryLevel] === 'B' && (
                      <img
                        src={GradeBIcon}
                        alt="Grade B"
                        className="grade-icon"
                      />
                    )}
                    {MasteryLevel[skill.MasteryLevel] === 'C' && (
                      <img
                        src={GradeCIcon}
                        alt="Grade C"
                        className="grade-icon"
                      />
                    )}
                    {MasteryLevel[skill.MasteryLevel] === 'D' && (
                      <img
                        src={GradeDIcon}
                        alt="Grade D"
                        className="grade-icon"
                      />
                    )}
                    {MasteryLevel[skill.MasteryLevel] === 'incomplete' && (
                      <img
                        src={IncompleteIcon}
                        alt="Incomplete"
                        className="grade-icon"
                      />
                    )}
                    <p className="skill-number">
                      <strong>
                        {t(
                          'proficiency-report:studentReportSide.skill',
                          'Skill'
                        )}{' '}
                        {index + 1}
                      </strong>
                    </p>
                  </div>
                ))}
            </div>
          </SkillSection>
        ))}
    </>
  );
};

export default StudentReport;
