import {
  FETCH_GAME_SETTING_CLASS_REQUEST,
  FETCH_GAME_SETTING_CLASS_SUCCESS,
  FETCH_GAME_SETTING_CLASS_FAILED,
  FETCH_GAME_SETTING_STUDENT_REQUEST,
  FETCH_GAME_SETTING_STUDENT_SUCCESS,
  FETCH_GAME_SETTING_STUDENT_FAILED,
  SAVE_GAME_SETTING_REQUEST,
  SAVE_GAME_SETTING_SUCCESS,
  SAVE_GAME_SETTING_FAILED,
  RESET_ERROR
} from './actionTypes';

export const fetchGameSettingClassRequest = () => {
  return {
    type: FETCH_GAME_SETTING_CLASS_REQUEST
  };
};

export const fetchGameSettingClassSuccess = classes => {
  return { type: FETCH_GAME_SETTING_CLASS_SUCCESS, classes };
};

export const fetchGameSettingClassFailed = error => {
  return {
    type: FETCH_GAME_SETTING_CLASS_FAILED,
    error
  };
};

export const fetchGameSettingStudentRequest = GroupID => {
  return {
    type: FETCH_GAME_SETTING_STUDENT_REQUEST,
    GroupID
  };
};

export const fetchGameSettingStudentSuccess = students => {
  return { type: FETCH_GAME_SETTING_STUDENT_SUCCESS, students };
};

export const fetchGameSettingStudentFailed = error => {
  return {
    type: FETCH_GAME_SETTING_STUDENT_FAILED,
    error
  };
};

export const saveGameSettingRequest = params => {
  return {
    type: SAVE_GAME_SETTING_REQUEST,
    params
  };
};

export const saveGameSettingSuccess = () => {
  return { type: SAVE_GAME_SETTING_SUCCESS };
};

export const saveGameSettingFailed = error => {
  return {
    type: SAVE_GAME_SETTING_FAILED,
    error
  };
};

export const resetError = () => {
  return {
    type: RESET_ERROR
  };
};
