import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { FactualFluencyPNG } from 'assets/img/dashboardv2';
import { fetchAssignmentsRequest } from 'stores/assignments/actions';
import { homeworkTypes } from 'constants/index';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import { isEmpty } from 'ramda';

import EmptyAssignment from '../EmptyAssignment/EmptyAssignment';
import {
  StyledCard,
  StyledContainer,
  StyledLink
} from './AssignmentTrackingCard.styles';

const getIcon = homeworkType => {
  if (homeworkType === 13) {
    return <img src={FactualFluencyPNG} width={30} alt="iconFactualFluency" />;
  }
  if (homeworkTypes[homeworkType]) {
    return homeworkTypes[homeworkType].logo;
  }
  return 'H';
};

const AssignmentTrackingCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { assignments, isLoading } = useSelector(state => state.assignments);

  const filteredData = assignments.length > 0 ? assignments.slice(0, 4) : [];

  useEffect(() => {
    dispatch(
      fetchAssignmentsRequest({
        date: moment().year(),
        pageSize: 4,
        pageIndex: 0
      })
    );
  }, [dispatch]);

  return (
    <div style={{ marginTop: '1rem' }}>
      {isLoading && 'Loading...'}
      {!isLoading && isEmpty(filteredData) && <EmptyAssignment />}
      {!isLoading && !isEmpty(filteredData) && (
        <StyledContainer>
          {filteredData.map(assignment => (
            <StyledCard key={assignment.HomeWorkId}>
              <div className="track-row">
                <span
                  className="hw-type-icon"
                  style={{
                    background: homeworkTypes[assignment.HomeWorkType]
                      ? homeworkTypes[assignment.HomeWorkType].bgColor
                      : '#49C8AA'
                  }}
                >
                  {getIcon(assignment.HomeWorkType)}
                </span>
                <div className="hw-link">{assignment.HomeWorkTitle}</div>
              </div>
              <div className="track-row">
                <Button
                  type="button"
                  variant="link"
                  size="open-button"
                  className="open-track-btn"
                  onClick={() => {
                    history.push(
                      // eslint-disable-next-line no-nested-ternary
                      assignment.TrackingGroup.length > 0 &&
                        (assignment.TrackingIndividual === null ||
                          assignment.TrackingIndividual.TotalAssignee > 0)
                        ? assignment.TrackingGroup.length === 1
                          ? `/track-assignment/homework/${assignment.HomeWorkId}/${assignment.TrackingGroup[0].ClassID}`
                          : `/track-assignment/homework/${assignment.HomeWorkId}`
                        : `/track-assignment/homework/${assignment.HomeWorkId}/all`
                    );
                  }}
                >
                  {t('dashboard:assignmentTracking.trackBtn', 'Track')}
                </Button>
              </div>
            </StyledCard>
          ))}
          <StyledLink>
            <Link to="/track-assignment" className="more-link">
              {t(
                'dashboard:assignmentTracking.moreAssignments',
                'More Assignments'
              )}
            </Link>
          </StyledLink>
        </StyledContainer>
      )}
    </div>
  );
};

export default AssignmentTrackingCard;
