import {
  FETCH_ASSIGNMENTS_REQUEST,
  FETCH_ASSIGNMENTS_SUCCESS,
  FETCH_ASSIGNMENTS_FAILED,
  SET_YEARS,
  SET_NUMBER_OF_PAGES,
  FETCH_TEACHERS_REQUEST,
  FETCH_TEACHERS_SUCCESS,
  FETCH_TEACHERS_FAILED,
  FETCH_TEACHER_ASSIGNMENTS_REQUEST,
  FETCH_TEACHER_ASSIGNMENTS_SUCCESS,
  FETCH_TEACHER_ASSIGNMENTS_FAILED,
  FETCH_MULTI_CLASS_REPORT_REQUEST,
  FETCH_MULTI_CLASS_REPORT_SUCCESS,
  FETCH_MULTI_CLASS_REPORT_FAILED,
  FETCH_SINGLE_CLASS_REPORT_REQUEST,
  FETCH_SINGLE_CLASS_REPORT_SUCCESS,
  FETCH_SINGLE_CLASS_REPORT_FAILED,
  FETCH_CSV_CLASS_REPORT_REQUEST,
  FETCH_CSV_CLASS_REPORT_SUCCESS,
  FETCH_CSV_CLASS_REPORT_FAILED,
  DELETE_ASSIGNED_HW_REQUEST,
  DELETE_ASSIGNED_HW_SUCCESS,
  DELETE_ASSIGNED_HW_FAILED,
  SET_LATEST_YEAR,
  SET_ASSIGNMENT_SUMMARY_HISTORY,
  SET_SELECTED_TEACHER,
  CLEAR_ASSIGNMENTS,
  CLEAR_SINGLE_CLASS_REPORT,
  CLEAR_MULTI_CLASS_REPORT,
  RESET_ERROR,
  RESET_DELETE_HW_RESULT,
  MARK_ANSWER_RIGHT_REQUEST,
  MARK_ANSWER_RIGHT_SUCCESS,
  MARK_ANSWER_RIGHT_FAILED,
  CHANGE_TITLE_DATE_RESET,
  CHANGE_TITLE_DATE_REQUEST,
  CHANGE_TITLE_DATE_SUCCESS,
  CHANGE_TITLE_DATE_FAILED
} from './actionTypes';

export const fetchAssignmentsRequest = params => {
  return { type: FETCH_ASSIGNMENTS_REQUEST, params };
};

export const fetchAssignmentsSuccess = assignments => {
  return { type: FETCH_ASSIGNMENTS_SUCCESS, assignments };
};

export const fetchAssignmentsFailed = error => {
  return {
    type: FETCH_ASSIGNMENTS_FAILED,
    error
  };
};

export const fetchTeacherAssignmentsRequest = params => {
  return {
    type: FETCH_TEACHER_ASSIGNMENTS_REQUEST,
    params
  };
};

export const fetchTeacherAssignmentsSuccess = assignments => {
  return {
    type: FETCH_TEACHER_ASSIGNMENTS_SUCCESS,
    assignments
  };
};

export const fetchTeacherAssignmentsFailed = error => {
  return {
    type: FETCH_TEACHER_ASSIGNMENTS_FAILED,
    error
  };
};

export const fetchMultiClassReportRequest = params => {
  return {
    type: FETCH_MULTI_CLASS_REPORT_REQUEST,
    params
  };
};

export const fetchMultiClassReportSuccess = report => {
  return {
    type: FETCH_MULTI_CLASS_REPORT_SUCCESS,
    report
  };
};

export const fetchMultiClassReportFailed = error => {
  return {
    type: FETCH_MULTI_CLASS_REPORT_FAILED,
    error
  };
};

export const fetchSingleClassReportRequest = params => {
  return {
    type: FETCH_SINGLE_CLASS_REPORT_REQUEST,
    params
  };
};

export const fetchSingleClassReportSuccess = report => {
  return {
    type: FETCH_SINGLE_CLASS_REPORT_SUCCESS,
    report
  };
};

export const fetchSingleClassReportFailed = error => {
  return {
    type: FETCH_SINGLE_CLASS_REPORT_FAILED,
    error
  };
};

export const fetchCSVClassReportRequest = params => {
  return {
    type: FETCH_CSV_CLASS_REPORT_REQUEST,
    params
  };
};

export const fetchCSVClassReportSuccess = (data, report) => {
  return {
    type: FETCH_CSV_CLASS_REPORT_SUCCESS,
    data,
    report
  };
};

export const fetchCSVClassReportFailed = error => {
  return {
    type: FETCH_CSV_CLASS_REPORT_FAILED,
    error
  };
};

export const fetchTeachersRequest = params => {
  return { type: FETCH_TEACHERS_REQUEST, params };
};

export const fetchTeachersSuccess = teachers => {
  return { type: FETCH_TEACHERS_SUCCESS, teachers };
};

export const fetchTeachersFailed = error => {
  return {
    type: FETCH_TEACHERS_FAILED,
    error
  };
};

export const deleteAssignedHwRequest = params => {
  return {
    type: DELETE_ASSIGNED_HW_REQUEST,
    params
  };
};

export const deleteAssignedHwSuccess = () => {
  return {
    type: DELETE_ASSIGNED_HW_SUCCESS
  };
};

export const deleteAssignedHwFailed = error => {
  return {
    type: DELETE_ASSIGNED_HW_FAILED,
    error
  };
};

export const setYears = years => {
  return {
    type: SET_YEARS,
    years
  };
};

export const setNumOfPages = number => {
  return {
    type: SET_NUMBER_OF_PAGES,
    number
  };
};

export const setLatestYear = year => {
  return {
    type: SET_LATEST_YEAR,
    year
  };
};

export const setAssignmentSummaryHistory = page => {
  return {
    type: SET_ASSIGNMENT_SUMMARY_HISTORY,
    page
  };
};

export const setSelectedTeacher = teacherID => {
  return {
    type: SET_SELECTED_TEACHER,
    teacherID
  };
};

export const clearAssignments = () => {
  return {
    type: CLEAR_ASSIGNMENTS
  };
};

export const clearSingleClassReport = () => {
  return {
    type: CLEAR_SINGLE_CLASS_REPORT
  };
};

export const clearMultiClassReport = () => {
  return {
    type: CLEAR_MULTI_CLASS_REPORT
  };
};

export const resetError = () => {
  return {
    type: RESET_ERROR
  };
};

export const resetDeleteHwResult = () => {
  return {
    type: RESET_DELETE_HW_RESULT
  };
};

export const markAnswerRightRequest = params => {
  return {
    type: MARK_ANSWER_RIGHT_REQUEST,
    params
  };
};

export const markAnswerRightSuccess = () => {
  return {
    type: MARK_ANSWER_RIGHT_SUCCESS
  };
};

export const markAnswerRightFailed = error => {
  return {
    type: MARK_ANSWER_RIGHT_FAILED,
    error
  };
};

export const changeTitleDateReset = () => {
  return { type: CHANGE_TITLE_DATE_RESET };
};

export const changeTitleDateRequest = params => {
  return {
    type: CHANGE_TITLE_DATE_REQUEST,
    params
  };
};

export const changeTitleDateSuccess = data => {
  return {
    type: CHANGE_TITLE_DATE_SUCCESS,
    data
  };
};

export const changeTitleDateFailed = error => {
  return {
    type: CHANGE_TITLE_DATE_FAILED,
    error
  };
};
