import {
  FETCH_LEVELS_REQUEST,
  FETCH_LEVELS_SUCCESS,
  FETCH_LEVELS_FAILED,
  FETCH_VIDEOS_REQUEST,
  FETCH_VIDEOS_SUCCESS,
  FETCH_VIDEOS_FAILED,
  SELECTED_LEVEL
} from './actionTypes';

const initialState = {
  levels: [],
  selectedLevel: {},
  videos: [],
  isLoading: false,
  error: ''
};

export default function levels(state = initialState, action) {
  switch (action.type) {
    case FETCH_LEVELS_REQUEST:
      return { ...state, isLoading: true };
    case FETCH_LEVELS_SUCCESS:
      return {
        ...initialState,
        levels: action.levels,
        selectedLevel: state.selectedLevel
      };
    case FETCH_LEVELS_FAILED:
      return { ...state, isLoading: false, error: action.error };
    case FETCH_VIDEOS_REQUEST:
      return { ...state, videos: [], isLoading: true };
    case FETCH_VIDEOS_SUCCESS:
      return {
        ...state,
        videos: action.data,
        isLoading: false
      };
    case FETCH_VIDEOS_FAILED:
      return { ...state, isLoading: false, error: action.error, videos: [] };
    case SELECTED_LEVEL:
      return { ...state, selectedLevel: action.selectedLevel };
    default:
      return state;
  }
}
