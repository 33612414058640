// select
export const SET_SELECTED_QUESTIONS = 'SET_SELECTED_QUESTIONS';
export const SET_WORKSHEET_TITLE = 'SET_WORKSHEET_TITLE';
export const SET_INSTRUCTION = 'SET_INSTRUCTION';
export const SET_ASSIGMENT_TYPE = 'SET_ASSIGMENT_TYPE';
export const SET_PRESENTATION_TYPE = 'SET_PRESENTATION_TYPE';
export const SET_SETTING_TIME_TYPE = 'SET_SETTING_TIME_TYPE';
export const SET_SETTING_TIME_LIMIT = 'SET_SETTING_TIME_LIMIT';

export const REMOVE_SELECTED_QUESTION = 'REMOVE_SELECTED_QUESTION';

export const ASSIGN_WORKSHEET = 'ASSIGN_WORKSHEET';
export const SAVE_WORKSHEET = 'SAVE_WORKSHEET';
export const DELETE_WORKSHEET = 'DELETE_WORKSHEET';
export const DELETE_WORKSHEET_SUCCESS = 'DELETE_WORKSHEET_SUCCESS';
export const DELETE_WORKSHEET_FAILED = 'DELETE_WORKSHEET_FAILED';
export const DELETE_WORKSHEET_RESET = 'DELETE_WORKSHEET_RESET';

export const SCHEDULE_WORKSHEET = 'SCHEDULE_WORKSHEET';

export const FETCH_ALL_WORKSHEETS_REQUEST = 'FETCH_ALL_WORKSHEETS_REQUEST';
export const FETCH_ALL_WORKSHEETS_SUCCESS = 'FETCH_ALL_WORKSHEETS_SUCCESS';
export const FETCH_ALL_WORKSHEETS_FAILED = 'FETCH_ALL_WORKSHEETS_FAILED';

export const FETCH_WORKSHEETS_REQUEST = 'FETCH_WORKSHEETS_REQUEST';
export const FETCH_WORKSHEETS_SUCCESS = 'FETCH_WORKSHEETS_SUCCESS';
export const FETCH_WORKSHEETS_FAILED = 'FETCH_WORKSHEETS_FAILED';
export const RESET_WORKSHEET = 'RESET_WORKSHEET';

export const GET_WORKSHEET_REQUEST = 'GET_WORKSHEET_REQUEST';
export const SELECTING_WORKSHEET = 'SELECTING_WORKSHEET';
export const SELECTED_WORKSHEET = 'SELECTED_WORKSHEET';
export const SELECTED_WORKSHEET_FAILED = 'SELECTED_WORKSHEET_FAILED';
export const RESET_SELECTED_WORKSHEET = 'RESET_SELECTED_WORKSHEET';

export const ASSIGN_CLASS = 'ASSIGN_CLASS';
export const ASSIGN_GROUP = 'ASSIGN_GROUP';
export const ASSIGN_STUDENT = 'ASSIGN_STUDENT';
export const ASSIGN_PRIVATE_GROUP = 'ASSIGN_PRIVATE_GROUP';
export const ASSIGN_ADMIN_GROUP = 'ASSIGN_ADMIN_GROUP';
export const ASSIGN_LEVEL = 'ASSIGN_LEVEL';
export const RESET_ASSIGNMENT = 'RESET_ASSIGNMENT';

export const SET_EDIT_SAVED_WORKSHEET_QUESTIONS =
  'SET_EDIT_SAVED_WORKSHEET_QUESTIONS';
export const REMOVE_SELECTED_SAVED_WORKSHEET_QUESTION =
  'REMOVE_SELECTED_SAVED_WORKSHEET_QUESTION';
export const RESET_WORKSHEET_SEARCH_PAGE = 'RESET_WORKSHEET_SEARCH_PAGE';
