import React from 'react';
import successIcon from 'assets/img/icon-manage-class-success-check.svg';
import ProfileSuccessModalContnainer from './ProfileSuccessModal.styles';

const ProfileSuccessModal = ({ hide }) => {
  return (
    <ProfileSuccessModalContnainer>
      <button className="close-btn" type="button" onClick={hide}>
        X
      </button>
      <span className="success-title">
        <img
          src={successIcon}
          alt="Success icon"
          style={{ marginRight: '0.5rem' }}
        />
        Success!
      </span>
      <span style={{ margin: '2rem 0' }}>
        Your changes have been saved succesfully.
      </span>
      <button className="success-btn" type="button" onClick={hide}>
        OK
      </button>
    </ProfileSuccessModalContnainer>
  );
};

export default ProfileSuccessModal;
