import styled, { css } from 'styled-components';
import { proficiencyColors } from 'constants/index';

export const StudentTable = styled.div`
  margin: 0 30px;
  position: relative;
  top: 10.6rem;
  /* @media (max-width: 1365px) {
    top: 11rem;
  } */
  @media (max-width: 864px) {
    font-size: 12px;
  }
  td {
    vertical-align: middle;
    text-align: center;
  }
  .custom-td {
    width: 130px;
  }
  .table-bordered {
    tr {
      :nth-child(odd) {
        background-color: #ffffff;
      }
      :nth-child(even) {
        background-color: #f8f8f8;
      }
      position: relative;
      :hover:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        box-shadow: 0px 0px 0px 2px #ff5b00 inset;
        top: 0;
        left: 0;
        pointer-events: none;
      }
    }
  }

  .table thead th {
    border-top: none;
    border-bottom: none;
    padding: 0;
  }

  .student-name {
    vertical-align: middle;
    text-align: left;
    cursor: pointer;
    img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .progressbar {
    text-align: left;
    cursor: pointer;
  }

  .mastery {
    background-color: rgba(255, 245, 194, 0.67);
  }
  .competent {
    background-color: rgba(242, 255, 235, 0.34);
  }
  .developing {
    background-color: rgba(235, 252, 255, 0.31);
  }
  .beginning {
    background-color: rgba(255, 235, 235, 0.37);
  }
  .incomplete {
    background-color: rgba(255, 255, 255);
  }
  .gradeA {
    width: 300px;
  }
  .ratings-category {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    justify-content: center;
    .grade {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    img {
      width: 28px;
      height: 28px;
    }
    .sub-text {
      color: #292929;
      font-size: 11px;
      margin-left: 3px;
      font-weight: normal;
    }
    .ratings-text {
      white-space: nowrap;
      font-size: 10px;
      transform: scale(0.9);
    }
    strong {
      color: #545454;
    }
  }
`;

export const GradeACell = styled.div`
  display: flex;
  img {
    margin-right: 1rem;
  }
  strong {
    font-size: 1.125rem;
    color: #363636;
  }
  p {
    font-size: 0.6875rem;
    color: #828282;
  }
  .progress-part {
    flex-basis: 80%;
  }
`;
export const SortButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e9e9e9;
  border-bottom: none;
  margin-top: 0.5rem;
`;
export const SortButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 50%;
  padding: 8px 0;
  background: ${({ active }) => (active ? '#9A9A9A' : 'white')};
  svg {
    path {
      fill: ${({ active }) => (active ? 'white' : '#DBDBDB')};
    }
  }
  ${({ type }) =>
    type === 'asc' &&
    css`
      border-left: 1px solid #e9e9e9;
      svg {
        transform: rotate(180deg);
      }
    `}
`;
export const ProficiencySkills = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon {
    width: 7px;
    height: 7px;
    margin-right: 4px;
    margin-top: 3px;
    background: ${props => proficiencyColors[props.proficiency].color};
    border-radius: 50%;
    border: solid 1px
      ${props => proficiencyColors[props.proficiency].borderColor};
  }
  img {
    margin-bottom: 5px;
  }
  .skill-title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span {
    font-weight: 500;
    text-align: center;
  }
  .prof-desc {
    font-size: 12px;
    transform: scale(0.8);
    white-space: nowrap;
    color: #727272;
  }
`;
