import React from 'react';
import IconWarning from 'assets/img/icon-warning.png';

const Forbidden = () => {
  return (
    <div className="forbidden-page">
      <img src={IconWarning} alt="Warning" className="forbidden-page__icon" />
      <h1 className="forbidden-page__title">Access Denied</h1>
      <p className="forbidden-page__desc">
        Please contact your administrator for access.
      </p>
    </div>
  );
};

export default Forbidden;
