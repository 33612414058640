import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPrivateGroupsRequest } from 'stores/privateGroup/actions';
import PrivateGroupCheckbox from './CheckboxPrivateGroup';

export default function AssignClass() {
  const { t } = useTranslation(['create-assign-question']);
  const dispatch = useDispatch();

  const [groupName, setGroupName] = useState('');
  const { privateGroups } = useSelector(state => state.privateGroup);

  const handleChangeGroupName = group => {
    setGroupName(group);
  };

  useEffect(() => {
    dispatch(fetchPrivateGroupsRequest(groupName));
  }, [dispatch, groupName]);

  return (
    <div>
      <p>
        {t(
          `create-assign-question:assignModal.choosePrivateGroup`,
          'Please choose private groups you want to assign'
        )}
      </p>
      <Form.Group className="col-md-12">
        <input
          type="text"
          className="form-control"
          placeholder={t(
            `create-assign-question:assignModal.searchBy`,
            'Search by group name'
          )}
          value={groupName}
          onChange={event => handleChangeGroupName(event.target.value)}
        />
      </Form.Group>
      <PrivateGroupCheckbox groups={privateGroups} />
    </div>
  );
}
