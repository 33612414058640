import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import history from 'helpers/history';
import startup from 'stores/startup/actions';
import BrowseContent from 'pages/BrowseContent/BrowseContent';
import ChooseLevel from 'pages/ChooseLevel';
import CreateAndAssign from 'pages/CreateAndAssign/CreateAndAssign';
import TrackAssignment from 'pages/TrackAssignment/TrackAssignment';
import ManageClass from 'pages/ManageClass/ManageClass';
import ProfileSetting from 'pages/ProfileSetting/ProfileSetting';
import LoginNew from 'pages/LoginNew';
import PrintAssignment from 'pages/TrackAssignment/PrintAssignment';
import AuthenticatedRoute from 'components/Utils/AuthenticatedRoute';
import Report from 'pages/Report/Report';
import Dashboard from 'pages/DashboardV2';
import Leaderboard from 'pages/CreateAndAssign/FactualFluency/Leaderboard';
import FactualFluencyGame from 'pages/CreateAndAssign/FactualFluency/FactualFluencyGame';
import WorksheetPrint from 'pages/CreateAndAssign/Print/WorksheetPrint';

export default function Routes() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startup());
  }, [dispatch]);
  return (
    <Router history={history}>
      <Switch>
        <Redirect
          exact
          from="/teacherreport/monthlyreport"
          to={{
            pathname: '/report/teacherreport/monthlyreport',
            search: window.location.search
          }}
        />
        <AuthenticatedRoute exact path="/" component={Dashboard} />
        <AuthenticatedRoute
          exact
          path="/choose-level"
          component={ChooseLevel}
        />
        <AuthenticatedRoute path="/browse-content" component={BrowseContent} />
        <AuthenticatedRoute path="/dashboard" component={Dashboard} />
        <AuthenticatedRoute
          path="/create-and-assign"
          component={CreateAndAssign}
        />
        <AuthenticatedRoute
          path="/track-assignment"
          component={TrackAssignment}
        />
        <AuthenticatedRoute path="/report" component={Report} />
        <AuthenticatedRoute path="/manage-class" component={ManageClass} />
        <AuthenticatedRoute
          path="/profile-setting"
          component={ProfileSetting}
        />
        <AuthenticatedRoute
          exact
          path="/print/:homeworkID"
          component={props => <PrintAssignment {...props} />}
        />
        <AuthenticatedRoute
          exact
          path="/worksheet/print/:homeworkID"
          component={props => <WorksheetPrint {...props} />}
        />
        <AuthenticatedRoute
          path="/factual-fluency"
          component={props => <FactualFluencyGame {...props} />}
        />
        <AuthenticatedRoute
          exact
          path="/leaderboard/:homeworkID"
          component={props => <Leaderboard {...props} />}
        />
        <Route exact path="/login" component={LoginNew} />
        <Route
          exact
          path="/members-login"
          render={() => {
            window.location.href = process.env.REACT_APP_MEMBERS_LOGIN_URL;
          }}
        />
      </Switch>
    </Router>
  );
}
