import styled from 'styled-components';

export const AssignContent = styled.div`
  margin-top: 55px;
  padding-left: 205px;
  padding-right: 283px;
  @media (max-width: 1024px) {
    padding-left: 56px;
  }
  @media screen and (max-width: 1199px) {
    padding-right: 220px;
  }
  @media screen and (max-width: 991.98px) {
    padding-right: 200px;
  }
  @media screen and (max-width: 575.98px) {
    padding-right: 180px;
  }

  .question-header {
    margin-right: 40px;
  }

  .media {
    p {
      font-size: 20px;
      line-height: 1.1rem;
    }
  }
`;

export const FatualFluencyInfo = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid #dddddd;
  margin: 0 -15px;
  padding: 10px 15px 0 15px;
  font-size: 12px;
  div {
    margin-right: 20px;

    span {
      color: #ff5b00;
      font-weight: bold;
    }
  }
`;
