import styled from 'styled-components';

export const AssignmentSummaryPage = styled.div`
  padding-top: 1rem;
  .summary-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    font-family: 'Muli';
    margin-bottom: 1rem;

    .title {
      font-size: 20px;
      color: #545454;
      margin-right: ${props => (props.isOthers === true ? '10%' : '25%')};
    }
    .year-selector {
      display: flex;
      .dropdown-container {
        margin-right: ${props => (props.isOthers === true ? '1rem' : '0')};
      }
    }
  }
`;
