import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';

export const StyledDropdown = styled(Dropdown)`
  #dropdown-custom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: solid 1px #707070;
    min-width: 230px;
    width: ${props => (props.width ? props.width : '230px')};
  }

  .dropdown-item-custom {
    width: ${props => (props.width ? props.width : '230px')};
  }
`;
