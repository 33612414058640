import styled from 'styled-components';

export const StudentsListContainer = styled.div`
  position: relative;
  padding: 0 250px 0 205px;
  @media (max-width: 1024px) {
    padding: 0 250px 0 66px;
  }
  @media (max-width: 640px) {
    padding: 0 250px 0 0;
  }
  &.on-loading {
    background-color: rgba(114, 114, 114, 0.15);
  }

  .spinner-border {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -16px;
    margin-left: -16px;
  }
`;
