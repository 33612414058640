import {
  FETCH_CLASS_REQUEST,
  FETCH_CLASS_SUCCESS,
  FETCH_CLASS_FAILED,
  FETCH_STUDENTS_REQUEST,
  FETCH_STUDENTS_SEARCH_REQUEST,
  FETCH_STUDENTS_SUCCESS,
  FETCH_STUDENTS_FAILED,
  FETCH_MY_GROUP_REQUEST,
  FETCH_MY_GROUP_SUCCESS,
  FETCH_MY_GROUP_FAILED
} from './actionTypes';

const initialState = {
  isLoading: false,
  classes: [],
  students: [],
  groups: [],
  error: ''
};

export default function lookup(state = initialState, action) {
  switch (action.type) {
    case FETCH_CLASS_REQUEST:
      return {
        ...state,
        error: ''
      };
    case FETCH_STUDENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: ''
      };
    case FETCH_STUDENTS_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: ''
      };
    case FETCH_MY_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: ''
      };
    case FETCH_CLASS_FAILED:
    case FETCH_STUDENTS_FAILED:
    case FETCH_MY_GROUP_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case FETCH_CLASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        classes: action.classes
      };
    case FETCH_STUDENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        students: action.students
      };
    case FETCH_MY_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        groups: action.groups
      };
    default:
      return state;
  }
}
