import styled, { css } from 'styled-components';

export const TopBarContainer = styled.div`
  background: white;
  position: fixed;
  right: 0;
  left: 195px;
  z-index: 100;
  @media (max-width: 1024px) {
    left: 56px;
  }
  @media (max-width: 639px) {
    left: 0px;
  }
  box-shadow: 0px 3px 6px rgba(129, 129, 129, 0.16);
  .title-container {
    display: flex;
    justify-content: center;
  }
  .title {
    margin-top: 20px;
    font-size: 20px;
  }
  .subtitle {
    font-size: 12px;
    margin-left: 10px;
    margin-top: 30px;
    span {
      color: rgba(255, 91, 0);
    }
  }
  .dropdown-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 5px 40px 12px;
    font-size: 14px;
    @media (max-width: 1038px) {
      font-size: 13px;
    }
    @media (max-width: 882px) {
      font-size: 12px;
    }
    @media (max-width: 744px) {
      font-size: 10px;
    }
  }
  .dropdown-container-group {
    display: flex;
    align-items: flex-end;
  }
  .class-group-dropdown {
    display: flex;
    .dropdown-menu {
      max-height: 70vh;
      overflow-y: scroll;
    }
    .level {
      width: 75px;
      margin-bottom: 0;
    }
    .class-group {
      width: 190px;
      margin-bottom: 0;
      @media (max-width: 882px) {
        width: 160px;
      }
      @media (max-width: 744px) {
        width: 140px;
      }
    }
  }
  .dropdown-label {
    margin-top: 5px;
    margin-bottom: 0;
    @media (max-width: 1038px) {
      margin-top: 8px;
    }
  }
  .report-level-dropdown {
    display: flex;
    margin-left: 20px;
    .level {
      width: 100px;
      margin-bottom: 0;
    }
    .syllabus {
      width: 194px;
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      position: relative;
      &::after {
        position: absolute;
        top: 50%;
        right: 6px;
        transform: translateY(-50%);
      }
    }
    @media (max-width: 744px) {
      margin-left: 10px;
    }
  }
  .dropdown-item:active {
    color: #212529;
    background-color: transparent;
  }

  .dropdown-custom {
    width: 160px;
    margin-left: 10px;
    border: 1px solid rgba(112, 112, 112, 0.48);
    background-color: #ffffff;
    font-size: 14px;
    text-align: left;
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 882px) {
      font-size: 12px;
      width: 120px;
    }
    @media (max-width: 744px) {
      font-size: 10px;
      width: 110px;
    }
  }
`;

export const PrintButton = styled.div`
  margin-left: 50px;
  margin-bottom: 15px;
  @media (max-width: 864px) {
    margin-left: 0px;
  }
  button {
    border: 1px solid rgba(196, 196, 196);
    border-radius: 3px;
    font-size: 14px;
    padding: 8px;
    background: White;
    @media (max-width: 864px) {
      font-size: 10px;
      padding: 5px;
    }
    @media (max-width: 744px) {
      font-size: 8px;
      padding: 4px;
    }
    img {
      margin-right: 8px;
      @media (max-width: 744px) {
        height: 15px;
        width: 15px;
        margin-right: 5px;
      }
    }
    span {
      margin-left: 3px;
      img {
        @media (max-width: 744px) {
          height: 6px;
          width: 6px;
        }
        @media (max-width: 718px) {
          height: 4px;
          width: 4px;
        }
      }
    }
  }
`;

export const SyllabusDropdown = styled.div`
  display: flex;
  font-size: 14px;
  position: fixed;
  top: 180px;
  right: 0px;
  left: 195px;
  padding: 20px 40px 5px 40px;
  z-index: 99;
  @media (max-width: 1024px) {
    left: 56px;
  }
  @media (max-width: 639px) {
    left: 0px;
  }
  @media (max-width: 1038px) {
    font-size: 13px;
  }
  @media (max-width: 882px) {
    font-size: 12px;
  }
  .stickyOff {
    display: flex;
  }
  .stickyOn {
    display: flex;
    position: fixed;
    background-color: #f1f1f1;
    z-index: 99;
    width: 100%;
    top: 180px;
    right: 0px;
    left: 195px;
    padding: 20px 40px 5px 40px;
  }
  .dropdown-label {
    margin-top: 5px;
    @media (max-width: 1038px) {
      margin-top: 8px;
    }
  }
  .syllabus-dropdown {
    .syllabus {
      width: 300px;
      @media (max-width: 882px) {
        width: 255px;
      }
      @media (max-width: 744px) {
        width: 215px;
      }
    }
    display: flex;
  }
  .dropdown-item:active {
    color: #212529;
    background-color: transparent;
  }
  .dropdown-toggle::after {
    position: relative;
    left: 10px;
    @media (max-width: 744px) {
      left: 3px;
      width: 6px;
      vertical-align: 1px;
    }
  }
  .sort-dropdown {
    display: flex;
    margin-left: 20px;
    flex: 1;
  }
  .dropdown-custom {
    width: 170px;
    margin-left: 10px;
    border: 1px solid rgba(112, 112, 112, 0.48);
    background-color: #ffffff;
    font-size: 14px;
    text-align: left;
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 882px) {
      font-size: 12px;
      width: 155px;
    }
    @media (max-width: 744px) {
      font-size: 10px;
      width: 115px;
    }
  }
  .rating-info {
    font-family: 'Muli', 'sans-serif';
  }
  .ratings-category {
    font-weight: 900;
    font-size: 14px;
    margin: 0 10px;
    img {
      width: 26px;
      margin-right: 2px;
    }
    .sub-text {
      color: #afacac;
      font-size: 11px;
    }
  }
`;
export const DownloadCSV = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const DownloadCSVModalBody = styled.div`
  background: white;
  min-width: 480px;
  position: relative;
  .title {
    color: #ff5b00;
    font-size: 18px;
    padding-top: 30px;
    padding-bottom: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 12px;
    }
  }
  .modal-content {
    color: #060606;
    padding: 0 16px 20px;
  }
  .action {
    display: flex;
    justify-content: center;
    padding: 18px 0 30px;
    .btn-cancel {
      border-color: #d5d5d5;
      color: #ff5b00;
      margin-right: 58px;
    }
    .btn-download {
      &:disabled {
        background-color: #d5d5d5;
        border-color: #d5d5d5;
        display: flex;
        align-items: center;
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }
  .close {
    position: absolute;
    top: 16px;
    right: 28px;
    cursor: pointer;
  }
`;

export const DownloadCSVSuccessBody = styled.div`
  background: white;
  min-width: 480px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 1rem;
  .success-title {
    font-size: 1.125rem;
    color: #000000;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .success-description {
    margin: 2rem 0;
    color: #000000;
  }
  .btn-success-ok {
    width: 127px;
    color: #fffdfc;
    background: #5ed46a;
    margin-bottom: 1rem;
    ${({ fail }) =>
      fail &&
      css`
        background: #ef5c57;
      `}
  }
  .title {
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    color: #ff5b00;
    font-size: 1.25rem;
    .action-icon {
      margin-right: 1rem;
    }
  }
`;
