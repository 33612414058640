import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { Link, useHistory } from 'react-router-dom';
import { Button, ProgressBar } from 'react-bootstrap';
import PopoverMenu from 'components/TrackAssignment/PopoverMenu';
import checkIcon from 'assets/img/icon-check-orange.svg';
import printIcon from 'assets/img/icon-track-assignment-print-active.svg';
import { featureToggle, homeworkTypes } from 'constants/index';
import { AssignmentDate, StyledCard } from './AssignmentCard.styles';
import iconFactualFluency from 'assets/img/icon-factual-fluency.png';

const getIcon = homeworkType => {
  if (homeworkType === 13) {
    return <img src={iconFactualFluency} alt="iconFactualFluency" />;
  }
  if (homeworkTypes[homeworkType]) {
    return homeworkTypes[homeworkType].logo;
  }

  return 'H';
};

export default function AssignmentCard({
  homeworkID,
  homeworkName,
  homeworkType,
  homeworkStatus,
  visibleDate,
  homeworkQnCount,
  assignedDate,
  startDate,
  deadline,
  classes,
  individuals,
  isOthers,
  openDeleteModal,
  setSelectedHomeworkID,
  assignment,
  openChangeTitleModal
}) {
  const { t } = useTranslation(['track-assignment-assigned-me']);
  const history = useHistory();

  return (
    <>
      <AssignmentDate>
        <span>{`${t(
          'track-assignment-assigned-me:main.assignDate',
          'Assign Date: '
        )}${moment
          .tz(assignedDate, 'Asia/singapore')
          .format('DD-MMM-YYYY')}`}</span>
        {homeworkStatus === 4 && (
          <span className="edit-status">
            *
            {t(
              `track-assignment-assigned-me:main.editProgress`,
              'Editing in progress'
            )}
          </span>
        )}
      </AssignmentDate>
      <StyledCard>
        <div className="details">
          <div className="title">
            <span
              className="hw-type-icon"
              style={{
                background: homeworkTypes[homeworkType]
                  ? homeworkTypes[homeworkType].bgColor
                  : '#49C8AA'
              }}
            >
              {getIcon(homeworkType)}
            </span>
            <Link
              to={
                classes.length > 0 &&
                (individuals === null || individuals.TotalAssignee > 0)
                  ? classes.length === 1
                    ? `/track-assignment/homework/${homeworkID}/${classes[0].ClassID}`
                    : `/track-assignment/homework/${homeworkID}`
                  : `/track-assignment/homework/${homeworkID}/all`
              }
              className="hw-link"
            >
              {homeworkName}
            </Link>
          </div>
          <div className="qn-count">
            <span
              style={{
                fontSize: '0.75rem',
                color: '#727272',
                textAlign: 'center'
              }}
            >
              {t(`track-assignment-assigned-me:main.totalQn`, 'Total Qn')}
            </span>
            <span style={{ fontSize: '1rem', color: '#1E1E1E' }}>
              {homeworkQnCount}
            </span>
          </div>
          <div className="dates">
            <span className="dates-title">
              {t(
                `track-assignment-assigned-me:main.startEndDate`,
                'Start/End date (dd/mm/yy)'
              )}
            </span>
            <span
              className="start-end-dates"
              onClick={() => openChangeTitleModal(assignment)}
            >
              {moment.tz(startDate, 'Asia/singapore').format('DD/MM/YY')} -{' '}
              {moment.tz(deadline, 'Asia/singapore').format('DD/MM/YY')}
            </span>
            {homeworkStatus === 6 && (
              <span className="visible-date">
                {t(`track-assignment-assigned-me:main.visible`, 'Visible on ')}
                {moment.tz(visibleDate, 'Asia/singapore').format('DD/MM/YY')}
              </span>
            )}
          </div>
          <div className="actions">
            {featureToggle.assignAgainButton &&
              (isOthers ? (
                <Button
                  variant="outline-primary"
                  size="print-button"
                  onClick={() => {}}
                >
                  <img src={printIcon} alt="Print" className="action-icon" />
                </Button>
              ) : (
                <Button
                  variant="outline-primary"
                  size="assign-button"
                  onClick={() => {}}
                >
                  {t(
                    `track-assignment-assigned-me:main.assignAgain`,
                    'Assign again'
                  )}
                </Button>
              ))}
            <Button
              variant="primary"
              size="open-button"
              onClick={() => {
                history.push(
                  classes.length > 0 &&
                    (individuals === null || individuals.TotalAssignee > 0)
                    ? classes.length === 1
                      ? `/track-assignment/homework/${homeworkID}/${classes[0].ClassID}`
                      : `/track-assignment/homework/${homeworkID}`
                    : `/track-assignment/homework/${homeworkID}/all`
                );
              }}
            >
              {t(`track-assignment-assigned-me:main.open`, 'Open')}
            </Button>
            {!isOthers && (
              <PopoverMenu
                homeworkID={homeworkID}
                openDeleteModal={openDeleteModal}
                setSelectedHomeworkID={setSelectedHomeworkID}
              />
            )}
          </div>
        </div>
        {individuals !== null && (
          <div
            className="class-submission"
            onClick={() => {
              history.push(`/track-assignment/homework/${homeworkID}/all`);
            }}
          >
            <span className="submission-class-student">
              <Link
                to={`/track-assignment/homework/${homeworkID}/all`}
                className="hw-link"
              >
                {`${individuals.TotalAssignee} Student${
                  individuals.TotalAssignee > 1 ? 's' : ''
                }`}
              </Link>
            </span>
            <span className="submissions">
              <span style={{ width: '180px' }}>{`${t(
                'track-assignment-assigned-me:main.submission',
                'Submission '
              )}  ${individuals.TotalSubmission}/${
                individuals.TotalAssignee
              }`}</span>
              <ProgressBar
                variant="progress-bar-submission"
                now={
                  (individuals.TotalSubmission / individuals.TotalAssignee) *
                  100
                }
                style={{ margin: '0 1rem', width: '130px' }}
              />
              {individuals.TotalAssignee > 0 &&
                individuals.TotalSubmission === individuals.TotalAssignee && (
                  <img
                    src={checkIcon}
                    alt="All submitted icon"
                    width="14.75px"
                    height="10.73px"
                  />
                )}
            </span>
          </div>
        )}
        {classes !== null &&
          classes.map(group => (
            <div
              className="class-submission"
              key={group.ClassID}
              onClick={() => {
                history.push(
                  `/track-assignment/homework/${homeworkID}/${group.ClassID}`
                );
              }}
            >
              <span className="submission-class-student">
                <Link
                  to={`/track-assignment/homework/${homeworkID}/${group.ClassID}`}
                  className="hw-link"
                >
                  {group.ClassName}
                </Link>
              </span>
              <span className="submissions">
                <span style={{ width: '180px' }}>{`${t(
                  'track-assignment-assigned-me:main.submission',
                  'Submission '
                )} ${group.TotalSubmission}/${group.TotalAssignee}`}</span>
                <ProgressBar
                  variant="progress-bar-submission"
                  now={(group.TotalSubmission / group.TotalAssignee) * 100}
                  style={{ margin: '0 1rem', width: '130px' }}
                />
                {group.TotalAssignee > 0 &&
                  group.TotalSubmission === group.TotalAssignee && (
                    <img
                      src={checkIcon}
                      alt="All submitted icon"
                      width="14.75px"
                      height="10.73px"
                    />
                  )}
              </span>
            </div>
          ))}
      </StyledCard>
    </>
  );
}
