import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './AssignIndividual.styles.scss';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchClassRequest,
  fetchStudentsRequest
} from 'stores/utils/lookup/actions';
import { filter, find, propEq, findIndex } from 'ramda';
import {
  assignStudent,
  resetAssignment
} from 'stores/worksheet/assign/actions';
import Spinner from 'components/Shared/Spinner';

export default function AssignIndividual() {
  const { t } = useTranslation(['create-assign-question']);
  const dispatch = useDispatch();

  // Get data from redux store
  const { levels, selectedLevel: globalSelectedLevel } = useSelector(
    state => state.levels
  );
  const { classes, students, isLoading, error } = useSelector(
    state => state.lookup
  );
  const { assignedStudent } = useSelector(state => state.assign);

  // Level state management
  const [selectedLevel, setSelectedLevel] = useState(globalSelectedLevel.ID);
  const onChangeLevel = event => {
    setSelectedLevel(event.target.value);
  };
  // Level on change effect
  useEffect(() => {
    dispatch(fetchClassRequest(selectedLevel));
  }, [dispatch, selectedLevel]);

  // Class state management
  const [selectedClass, setSelectedClass] = useState(
    classes.length > 0 ? classes[0].ID : 1
  );
  const onChangeClass = event => {
    setSelectedClass(event.target.value);
  };
  // Class on change effect
  useEffect(() => {
    dispatch(fetchStudentsRequest(selectedClass));
  }, [dispatch, selectedClass]);
  // When classes change, change the selected class
  useEffect(() => {
    setSelectedClass(classes.length > 0 ? classes[0].ID : 1);
  }, [classes]);

  // Available students state management
  const availableStudents = filter(
    student =>
      findIndex(propEq('UserId', parseInt(student.UserId, 10)))(
        assignedStudent
      ) === -1,
    students
  );
  const [
    selectedAvailableStudentIds,
    setSelectedAvailableStudentIds
  ] = useState([]);
  const handleSelectAvailableStudents = event => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(parseInt(options[i].value, 10));
      }
    }
    setSelectedAvailableStudentIds(value);
  };
  const onAdd = () => {
    const updatedData = [...assignedStudent];
    selectedAvailableStudentIds.forEach(id => {
      const newData = find(propEq('UserId', id))(students);
      updatedData.push(newData);
    });

    dispatch(assignStudent(updatedData));
    dispatch(resetAssignment('admin'));
    setSelectedAvailableStudentIds([]);
  };

  // Selected assigned students state management
  const [selectedAssignedStudentIds, setSelectedAssignedStudentIds] = useState(
    []
  );
  const handleSelectAssignedStudents = event => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(parseInt(options[i].value, 10));
      }
    }
    setSelectedAssignedStudentIds(value);
  };
  const onRemove = () => {
    let updatedData = [...assignedStudent];

    updatedData = updatedData.filter(
      student => selectedAssignedStudentIds.indexOf(student.UserId) === -1
    );
    dispatch(assignStudent(updatedData));
    setSelectedAssignedStudentIds([]);
  };

  return (
    <div>
      <p>
        {t(
          `create-assign-question:assignModal.chooseStud`,
          'Please choose students you want to assign'
        )}
      </p>
      <Form.Group controlId="options-level">
        <Form.Control
          as="select"
          name="level"
          onChange={onChangeLevel}
          value={selectedLevel}
        >
          {levels.map(level => (
            <option key={level.ID} value={level.ID}>
              {level.Name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="options-class-group">
        <Form.Control
          as="select"
          name="level"
          onChange={onChangeClass}
          value={selectedClass}
        >
          {classes.map(classItem => (
            <option key={classItem.ID} value={classItem.ID}>
              {classItem.Name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <div className="assign-form">
        <div style={{ position: 'absolute', left: '160px', top: '150px' }}>
          {isLoading && <Spinner small />}
        </div>
        <Form.Control
          as="select"
          multiple
          className="assign-modal-textarea"
          onChange={handleSelectAvailableStudents}
          value={selectedAvailableStudentIds}
        >
          {isLoading ? (
            ''
          ) : availableStudents.length === 0 || error !== '' ? (
            <option disabled style={{ fontSize: '14px' }}>
              {t(
                `create-assign-question:assignModal.noStud`,
                'No student in the selected class'
              )}
            </option>
          ) : (
            availableStudents.map(student => (
              <option key={student.UserId} value={student.UserId}>
                {student.FullName}
              </option>
            ))
          )}
        </Form.Control>
        <div className="assign-form-button">
          <button
            type="button"
            onClick={onAdd}
            disabled={selectedAvailableStudentIds.length <= 0}
            className="btn btn-primary"
          >
            {t(`create-assign-question:assignModal.add`, 'Add')}
          </button>
          <button
            type="button"
            onClick={onRemove}
            disabled={assignedStudent.length <= 0}
            className="btn btn-info"
          >
            {t(`create-assign-question:assignModal.remove`, 'Remove')}
          </button>
        </div>
        <Form.Control
          as="select"
          multiple
          className="assign-modal-textarea--selected"
          onChange={handleSelectAssignedStudents}
          value={selectedAssignedStudentIds}
        >
          {assignedStudent.map(student => (
            <option key={student.UserId} value={student.UserId}>
              {student.FullName} {student.ClassName}
            </option>
          ))}
        </Form.Control>
      </div>
    </div>
  );
}
