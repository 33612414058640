import apiClient from 'services/api';

const handleResponseSuccess = result => {
  if (result.data?.IsSuccessful === false) {
    return {
      error: result.data?.Message?.Message ?? 'Fetch worksheet questions failed' // eslint-disable-line
    };
  }
  return result.data?.Result ?? [];
};

/**
 * Assign a worksheet to classes / groups / individuals
 */
const assignWorksheet = (params, isPublish) => {
  const query = {};
  if (isPublish) {
    query.publish = isPublish;
  }
  return apiClient
    .post('Worksheet/Assign', params, { params: query })
    .then(result => {
      if (result.data?.IsSuccessful === false) {
        return {
          error: result.data?.Message?.Message ?? 'Assign worksheet failed' // eslint-disable-line
        };
      }
      return result.data?.Result ?? [];
    })
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Assign Worksheet failed' // eslint-disable-line
      };
    });
};

/**
 * Add a question into Cart, I put the all data into the params
 * is because they are only 3 data, it is clearer then only single params object

 */
const saveWorksheet = (params, isEdit) => {
  const query = {};
  if (isEdit) {
    query.isEdit = isEdit;
  }
  return apiClient
    .post(`Worksheet/Save`, params, { params: query })
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Save Worksheet failed' // eslint-disable-line
      };
    });
};

/**
 * Fetch all worksheet
 */
const getMyWorksheets = () => {
  return apiClient
    .get(`Worksheet/GetMyWorksheets`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch worksheet failed' // eslint-disable-line
      };
    });
};

/**
 * Fetch pagination worksheet
 */
const getSavedWorksheets = (page, limit) => {
  const params = {
    pageindex: page || 0,
    pagesize: limit || 5
  };
  return apiClient
    .get(`Worksheet/GetSavedWorksheets/${params.pagesize}/${params.pageindex}`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch worksheet failed' // eslint-disable-line
      };
    });
};

/**
 * Fetch worksheet by ID
 */
const getMyWorksheetById = ID => {
  return apiClient
    .get(`Worksheet/GetMyWorksheetById/${ID}`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch worksheet failed' // eslint-disable-line
      };
    });
};

const deleteWorksheet = ID => {
  return apiClient
    .delete(`Worksheet/DeleteHomework/${ID}`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'delete worksheet failed' // eslint-disable-line
      };
    });
};

/**
 * Schedule a worksheet to classes / groups / individuals
 */
const scheduledWorksheet = params => {
  return apiClient
    .post('Worksheet/ScheduleWorksheet', params)
    .then(result => {
      if (result.data?.IsSuccessful === false) {
        return {
          error: result.data?.Message?.Message ?? 'Scheduled worksheet failed' // eslint-disable-line
        };
      }
      return result.data?.Result ?? [];
    })
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Scheduled Worksheet failed' // eslint-disable-line
      };
    });
};

export default {
  assignWorksheet,
  saveWorksheet,
  getMyWorksheetById,
  getMyWorksheets,
  getSavedWorksheets,
  deleteWorksheet,
  scheduledWorksheet
};
