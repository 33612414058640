import React from 'react';
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import questionParser from 'helpers/questionParser';
import convertTagsToHTML from 'helpers/text/convertTagsToHTML';
import {
  MediaBody,
  McqOptionsList,
  TableModal
} from './QuestionContent.styles';
// import IconPlay2 from 'assets/img/Icon Video.svg';

export default function QuestionContent({
  question,
  onShowModal,
  onShowSolution,
  isFactualFluencyView
}) {
  // jsx renders
  const { t } = useTranslation(['browse-content']);
  const { title, imageUri, mcqOptions } = questionParser(question);
  const renderOptions = mcqOptions.map(option =>
    HTMLReactParser(convertTagsToHTML(option, false), {
      replace: domNode => {
        if (domNode.name === 'img') {
          domNode.attribs.style = 'width: 68%';
          return domNode;
        }
        return domNode;
      }
    })
  );
  const renderQuestion = HTMLReactParser(convertTagsToHTML(title, false), {
    replace: domNode => {
      if (domNode.name === 'helptext') {
        return (
          <small id="questionHelp" className="form-text text-muted">
            {domNode.children[0]?.data ?? ''}
          </small>
        );
      }
      if (domNode.name === 'table') {
        domNode.attribs.cellspacing = '0';
        domNode.attribs.cellpadding = '0';
        domNode.attribs.style = 'align-content: center;';
        return domNode;
      }
      if (domNode.name === 'td') {
        if (
          domNode.attribs.hasOwnProperty('style') &&
          !domNode.attribs.style.includes('border')
        ) {
          domNode.attribs.style = 'width: 10%';
        }
        return domNode;
      }
      if (domNode.name === 'input') {
        return (
          <input
            type="text"
            style={{
              maxWidth: '60px'
            }}
          />
        );
      }
      return domNode;
    }
  });
  const renderQuestionCondition = () => {
    if (isFactualFluencyView) {
      if (Array.isArray(renderQuestion)) {
        return renderQuestion[0];
      }
      return renderQuestion;
    }
    return renderQuestion;
  };
  return (
    <div className="media">
      <MediaBody>
        <TableModal>{renderQuestionCondition()}</TableModal>
        {mcqOptions.length > 0 && (
          <McqOptionsList>
            {renderOptions.map(option => (
              <li key={option}>{option}</li>
            ))}
          </McqOptionsList>
        )}

        {!isFactualFluencyView && (
          <>
            <br />
            <br />
            <a
              href="#noredirect"
              onClick={e => {
                e.preventDefault();
                onShowSolution && onShowSolution();
                onShowModal && onShowModal();
              }}
            >
              {t(`browse-content:questionCard.seeSol`, 'See Solution')}
            </a>
          </>
        )}
      </MediaBody>
      {!isEmpty(imageUri) && (
        <div className="media-right">
          <img onClickCapture={onShowModal} src={imageUri} alt="question" />
        </div>
      )}
    </div>
  );
}
