import React from 'react';
import ContentLoader from 'react-content-loader';

export default function TopicsLoader() {
  return (
    <ContentLoader height={340}>
      {[20, 80, 140, 200, 260].map((y, index) => {
        const randomWidth = Math.floor(Math.random() * (340 - 80 + 1)) + 80;
        return (
          <rect
            key={String(index)}
            x="30"
            y={y}
            rx="9"
            ry="9"
            width={randomWidth}
            height="20"
          />
        );
      })}
    </ContentLoader>
  );
}
