import styled from 'styled-components';

export const QuestionPaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0.5rem 1rem 0;
  .question-pagination-page {
    margin: 0.2rem auto;
    color: #f9625c;
    font-size: 12px;
  }
  .btn-container {
    display: flex;
    justify-content: center;
    gap: 6px;
  }
  .next-button,
  .prev-button {
    padding: 12px;
    position: relative;
    border-radius: 50%;
    background: #dfdfdf;
    border: none;
    && {
      &:active {
        background: #dfdfdf;
      }
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%);
      color: #727171;
    }
  }
`;
