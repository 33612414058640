import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from 'components/Shared/Spinner';
import { Button } from 'react-bootstrap';
import downloadIcon from 'assets/img/icon-track-assignment-download.svg';
import { fetchCSVClassReportRequest } from 'stores/assignments/actions';
// import CsvDownload from 'react-csv-downloader';
import { CSVLink } from 'react-csv';

import successIcon from 'assets/img/icon-manage-class-success-check.svg';
import { DownloadCSVModalContainer } from './DownloadCSVModalBody.styles';

const DownloadCSVModalBody = ({ hide, homeworkID }) => {
  const { t } = useTranslation(['track-assignment-assigned-me']);
  const dispatch = useDispatch();
  const {
    parsedCSVClassReport,
    CSVClassReport,
    isCSVClassReportLoading,
    error
  } = useSelector(state => state.assignments);

  // selected class state
  const [selectedClassID, setSelectedClassID] = useState([]);
  const [downloadSucces, setDownloadSuccess] = useState(false);

  // get list of class name
  const classList = CSVClassReport[0] && CSVClassReport[0].TrackingGroup;
  const individualList = CSVClassReport[0] &&
    CSVClassReport[0].TrackingIndividual && { ClassName: 'Assigned Students' };

  const downloadOptionList = () => {
    if (CSVClassReport[0] && CSVClassReport[0].TrackingIndividual !== null) {
      return classList && classList.concat(individualList);
    }
    if (CSVClassReport[0] && CSVClassReport[0].TrackingGroup === null) {
      return individualList;
    }
    return classList;
  };

  const combineList = downloadOptionList();

  // filtered csv data based on selected classID
  const filteredData =
    parsedCSVClassReport &&
    parsedCSVClassReport.filter(item => {
      return selectedClassID.indexOf(item.classID) >= 0;
    });

  // CSV Columns
  const columns = [
    {
      key: 'class',
      label: `${t('track-assignment-assigned-me:csvColumn.class', 'CLASS')}`
    },
    {
      key: 'studentName',
      label: `${t(
        'track-assignment-assigned-me:csvColumn.studentName',
        'STUDENT NAME'
      )}`
    },
    {
      key: 'homeworkTitle',
      label: `${t(
        'track-assignment-assigned-me:csvColumn.hwName',
        'HOMEWORK NAME'
      )}`
    },
    {
      key: 'homeworkID',
      label: `${t(
        'track-assignment-assigned-me:csvColumn.hwId',
        'HOMEWORK ID'
      )}`
    },
    {
      key: 'score',
      label: `${t('track-assignment-assigned-me:csvColumn.score', 'SCORE')}`
    },
    {
      key: 'maxScore',
      label: `${t(
        'track-assignment-assigned-me:csvColumn.maxScore',
        'MAX ATTAINABLE'
      )}`
    },
    {
      key: 'submissionDate',
      label: `${t(
        'track-assignment-assigned-me:csvColumn.subDate',
        'SUBMISSION DATE'
      )}`
    }
  ];

  useEffect(() => {
    dispatch(
      fetchCSVClassReportRequest({
        homeworkID
      })
    );
  }, [dispatch, homeworkID]);
  return (
    <DownloadCSVModalContainer>
      {downloadSucces === true ? (
        <>
          <span className="success-title">
            <img
              src={successIcon}
              alt="Success icon"
              style={{ marginRight: '0.5rem' }}
            />
            {t(
              'track-assignment-assigned-me:downloadModal.success',
              'Success!'
            )}
          </span>
          <span style={{ margin: '2rem 0', color: '#000000' }}>
            {t(
              'track-assignment-assigned-me:downloadModal.downloadDes',
              'Assignment downloaded successfully!'
            )}
          </span>
          <Button
            variant="success-ok"
            onClick={() => {
              hide();
            }}
          >
            {t('track-assignment-assigned-me:editAssign.ok', 'OK')}
          </Button>
        </>
      ) : (
        <>
          <div className="title">
            <img src={downloadIcon} alt="Download" className="action-icon" />
            <span>
              {t(
                'track-assignment-assigned-me:downloadModal.xls',
                'Download XLS'
              )}
            </span>
          </div>
          <div className="content-section">
            {isCSVClassReportLoading ? (
              <Spinner />
            ) : (
              <>
                <p>
                  {t(
                    'track-assignment-assigned-me:downloadModal.select',
                    'Select class to download'
                  )}
                </p>
                {combineList &&
                  combineList.map((item, index) => (
                    <div className="class-list" key={index}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={item.ClassID}
                        value={item.ClassID}
                        onChange={e => {
                          if (e.target.checked) {
                            setSelectedClassID([
                              ...selectedClassID,
                              item.ClassID
                            ]);
                          } else {
                            setSelectedClassID(
                              selectedClassID.filter(
                                sth => sth !== item.ClassID
                              )
                            );
                          }
                        }}
                      />
                      <span className="custom-checkbox" />
                      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                      <label
                        className="form-check-label"
                        htmlFor={item.ClassID}
                      >
                        {item.ClassName}
                      </label>
                    </div>
                  ))}
              </>
            )}
            {!isCSVClassReportLoading && error !== null && <p>{error}</p>}
          </div>
          <div className="btn-section">
            <Button className="cancel-btn" onClick={() => hide()}>
              {t('track-assignment-assigned-me:editAssign.cancel', 'Cancel')}
            </Button>
            <CSVLink
              text="Download"
              filename={
                CSVClassReport[0] && `${CSVClassReport[0].HomeWorkTitle}.csv`
              }
              headers={columns}
              data={filteredData}
            >
              <Button
                className="download-btn"
                disabled={filteredData.length < 1}
                onClick={() => setDownloadSuccess(true)}
              >
                {t(
                  'track-assignment-assigned-me:downloadModal.download',
                  'Download'
                )}
              </Button>
            </CSVLink>
          </div>
        </>
      )}
    </DownloadCSVModalContainer>
  );
};

export default DownloadCSVModalBody;
