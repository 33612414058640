import apiClient from 'services/api';

const handleResponseSuccess = result => {
  if (result.data?.IsSuccessful === false) {
    return {
      error: result.data?.Message?.Message ?? 'API request failed' // eslint-disable-line
    };
  }
  return result.data?.Result ?? [];
};

const fetchTopicSkill = LevelID => {
  return apiClient
    .get(`FactualFluency/Topics/${LevelID}`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch topics failed' // eslint-disable-line
      };
    });
};

const createFactualFluency = obj => {
  return apiClient
    .post(`FactualFluency/Questions`, obj)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Get Questions failed' // eslint-disable-line
      };
    });
};

const fetchLeaderboard = homeworkID => {
  return apiClient
    .get(`FactualFluency/Leaderboard/${homeworkID}`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Get Leaderboard failed' // eslint-disable-line
      };
    });
};

export default {
  fetchTopicSkill,
  createFactualFluency,
  fetchLeaderboard
};
