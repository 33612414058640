import React from 'react';
import ContentType from 'components/Content/ContentType';

// eslint-disable-next-line
const Subbar = React.forwardRef((_, ref) => {
  return (
    <div ref={ref} className="subbar">
      <div className="subbar-inner">
        <ContentType />
      </div>
    </div>
  );
});

export default Subbar;
