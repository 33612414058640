import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMyGroupsRequest } from 'stores/utils/lookup/actions';
import { assignGroup, resetAssignment } from 'stores/worksheet/assign/actions';

import { findIndex, find, propEq } from 'ramda';

export default function AssignClass() {
  const { t } = useTranslation(['create-assign-question']);
  const dispatch = useDispatch();
  const { groups } = useSelector(state => state.lookup);
  const { assignedGroup } = useSelector(state => state.assign);

  useEffect(() => {
    dispatch(fetchMyGroupsRequest());
  }, [dispatch]);

  const checkChecked = id => {
    return findIndex(propEq('ID', parseInt(id, 10)))(assignedGroup) !== -1;
  };

  const onChange = event => {
    const { value } = event.target;
    // Use new variable to avoid mutating state
    let updatedData;

    if (!checkChecked(value)) {
      const newData = find(propEq('ID', parseInt(value, 10)))(groups);

      updatedData = [...assignedGroup, newData];
    } else {
      const index = findIndex(propEq('ID', parseInt(value, 10)))(assignedGroup);
      updatedData = [
        ...assignedGroup.slice(0, index),
        ...assignedGroup.slice(index + 1)
      ];
    }

    dispatch(assignGroup(updatedData));
    dispatch(resetAssignment('admin'));
  };

  return (
    <div>
      <p>
        {t(
          `create-assign-question:assignModal.chooseGroup`,
          'Please choose groups you want to assign'
        )}
      </p>
      {groups.length !== 0 ? (
        <>
          {groups.map(item => {
            return (
              <Form.Group
                key={`group-${item.ID}`}
                className="row"
                controlId={`group-${item.ID}`}
              >
                <Form.Check
                  className="checkbox-class"
                  checked={checkChecked(item.ID)}
                  onChange={onChange}
                  type="checkbox"
                  label={item.Name}
                  value={item.ID}
                />
              </Form.Group>
            );
          })}
        </>
      ) : (
        <p>
          {t(`create-assign-question:assignModal.noGroup`, 'No group found')}
        </p>
      )}
    </div>
  );
}
