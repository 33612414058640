import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import history from 'helpers/history';
import { useDispatch } from 'react-redux';
import { getStudentByClassReset } from 'stores/createGroup/actions';
import { CreateGroupHeaderStyle } from './CreateGroup.styles';

const CreateGroup = ({
  Name,
  changeNameHandler,
  callCreateGroup,
  showErr,
  buttonName
}) => {
  const { t } = useTranslation(['manage-class-group']);
  const dispatch = useDispatch();
  const goBack = () => {
    history.goBack();
    dispatch(getStudentByClassReset());
  };

  return (
    <CreateGroupHeaderStyle>
      <div className="back-list">
        <span onClick={goBack}>
          &lt; {t(`manage-class-group:createGroup.backList`, 'Back to list')}
        </span>
      </div>
      <div className="grp-name-container">
        <form onSubmit={callCreateGroup}>
          <span className="field-title">
            {t(`manage-class-group:table.grpName`, 'Group Name')}
          </span>
          <input
            type="text"
            value={Name}
            className="profile-form-inp"
            onChange={e => changeNameHandler(e.target.value)}
            placeholder={t(
              `manage-class-group:createGroup.enterName`,
              'Enter the group name here'
            )}
            required
          />
          <button
            type="submit"
            className="btn btn-lg btn-primary create-grp-btn"
          >
            {buttonName}
          </button>
          <div className="show-err">{showErr}</div>
        </form>
      </div>
    </CreateGroupHeaderStyle>
  );
};

export default memo(CreateGroup);
