import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'ramda';
import moment from 'moment';
import { useDebounce } from 'use-debounce';
import {
  searchStudentRequest,
  resetPwResultAction,
  resetError
} from 'stores/manage-class/actions';
import EmptySearchResults from 'assets/img/empty-search-results.svg';
import SchoolPlanIcon from 'assets/img/icon-manage-class-student-list-plan-school.svg';
import StandardPlanIcon from 'assets/img/icon-manage-class-student-list-plan-standard.svg';
import PremiumPlanIcon from 'assets/img/icon-manage-class-student-list-plan-premium.svg';
import avatarPlaceholder from 'assets/img/avatar.png';
import sortStudentsByClass from 'helpers/parseStudentSearchResults';
import Spinner from 'components/Shared/Spinner';
import PopupModal from './PopupModal';
import ResetPwModalBody from './ResetPwModalBody';
import StudentDetailsModalBody from './StudentDetailsModalBody';
import Pagination from './PaginationSearch';
import MatchedResult from './MatchedResult';
import { SearchResultContainer } from './SearchResults.styles';

export default function SearchResults({ searchTerm, searchType }) {
  const { t } = useTranslation(['manage-class']);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 2000);
  const getPlanIcon = planID => {
    switch (planID) {
      case 0:
        return SchoolPlanIcon;
      case 2:
        return StandardPlanIcon;
      case 3:
        return PremiumPlanIcon;
      case 5:
        return PremiumPlanIcon;
      case 6:
        return PremiumPlanIcon;
      default:
        return null;
    }
  };

  const [showStudentDetailsModal, setShowStudentDetailsModal] = useState(false);
  const [selectedStudentID, setSelectedStudentID] = useState(null);

  const hideStudentDetailsModal = () => {
    setShowStudentDetailsModal(false);
  };

  const dispatch = useDispatch();
  const { searchResults, isLoading, classes, resetPwResult } = useSelector(
    state => state.manageClasses
  );
  const [currentPage, setCurrentPage] = useState(1);
  const sortedSearch = searchResults.hasOwnProperty('List')
    ? sortStudentsByClass(searchResults.List)
    : [];
  const [showResetPwModal, setShowResetPwModal] = useState(false);

  useEffect(() => {
    if (searchType === t(`manage-class:search.name`)) {
      dispatch(
        searchStudentRequest({
          pageNum: currentPage,
          searchName: debouncedSearchTerm.trim(),
          searchID: null,
          schoolID: classes.ID
        })
      );
    } else {
      dispatch(
        searchStudentRequest({
          pageNum: currentPage,
          searchName: null,
          searchID: debouncedSearchTerm.trim(),
          schoolID: classes.ID
        })
      );
    }
    // eslint-disable-next-line
  }, [currentPage, dispatch, debouncedSearchTerm, searchType, classes.ID]);

  const hideResetPwModal = () => {
    if (resetPwResult === true) {
      if (searchType === t(`manage-class:search.name`)) {
        dispatch(
          searchStudentRequest({
            pageNum: currentPage,
            searchName: debouncedSearchTerm.trim(),
            searchID: null,
            schoolID: classes.ID
          })
        );
      } else {
        dispatch(
          searchStudentRequest({
            pageNum: currentPage,
            searchName: null,
            searchID: debouncedSearchTerm.trim(),
            schoolID: classes.ID
          })
        );
      }
    }
    dispatch(resetError());
    dispatch(resetPwResultAction());
    setShowResetPwModal(false);
  };

  const openResetPwModal = () => {
    dispatch(resetError());
    setShowResetPwModal(true);
  };

  const passwordFormat = (pass, username) => {
    if (isNil(pass)) {
      return 'N.A';
    }
    if (pass === username) {
      return t(`manage-class:studList.sameAsId`, 'Same as the login ID');
    }
    return pass;
  };

  return (
    <SearchResultContainer>
      <PopupModal
        show={showStudentDetailsModal}
        hide={hideStudentDetailsModal}
        size="lg"
      >
        <StudentDetailsModalBody
          hide={hideStudentDetailsModal}
          studentID={selectedStudentID}
        />
      </PopupModal>
      <PopupModal show={showResetPwModal} hide={hideResetPwModal} size="sm">
        <ResetPwModalBody
          hide={hideResetPwModal}
          studentID={selectedStudentID}
        />
      </PopupModal>
      {isLoading && <Spinner top="200px" />}
      {!isLoading &&
        (sortedSearch.length > 0 ? (
          <div className="empty-result">
            <div className="search-result-container">
              <div className="search-result-labels">
                <div className="login_id">
                  {t(`manage-class:searchResult.loginId`, 'Login ID')}
                </div>
                <div className="pswd">
                  {t(`manage-class:searchResult.password`, 'Password')}
                </div>
                <div className="activation_date">
                  {t(
                    `manage-class:searchResult.activeDate`,
                    'Activation Date '
                  )}
                  {t(`manage-class:searchResult.dateFormat`, '(dd/mm/yyyy)')}
                </div>
                <div className="status">
                  {t(
                    `manage-class:searchResult.status`,
                    'Parent App Linking Status'
                  )}
                </div>
              </div>
              {sortedSearch.map(classObject => (
                <div key="id">
                  <div className="class-name">
                    {classObject.students[0].ClassName}
                  </div>
                  <table className="table">
                    <tbody className="table-bordered">
                      {classObject.students.map(student => (
                        <tr className="table-content" key={student.UserId}>
                          <td className="name">
                            <img
                              onError={e => {
                                e.target.src = avatarPlaceholder;
                              }}
                              src={student.ProfileImage}
                              alt="profile"
                            />
                            <span>
                              {getPlanIcon(student.PlanId) !== null && (
                                <img
                                  src={getPlanIcon(student.PlanId)}
                                  alt="plan"
                                />
                              )}
                            </span>
                            <p>
                              {searchType === t(`manage-class:search.name`) ? (
                                <MatchedResult
                                  result={student.DisplayName}
                                  searchTerm={debouncedSearchTerm}
                                />
                              ) : (
                                <>{student.DisplayName}</>
                              )}
                            </p>
                          </td>
                          <td className="login">
                            {searchType !== t(`manage-class:search.name`) ? (
                              <MatchedResult
                                result={student.UserName}
                                searchTerm={debouncedSearchTerm}
                              />
                            ) : (
                              <>{student.UserName}</>
                            )}
                          </td>
                          <td className="password">
                            {passwordFormat(student.password, student.UserName)}
                            {student.password !== student.UserName && (
                              <button
                                type="button"
                                className="rest-pswd"
                                onClick={() => {
                                  setSelectedStudentID(student.UserId);
                                  openResetPwModal();
                                }}
                              >
                                {t(`manage-class:studList.reset`, 'Reset')}
                              </button>
                            )}
                          </td>
                          <td className="active-date">
                            {student.ActivationDate === null
                              ? 'N.A'
                              : moment(student.ActivationDate).format(
                                  'DD/MM/YYYY'
                                )}
                          </td>
                          <td className="link">
                            {student.ParentAppLinkingStatus
                              ? t(`manage-class:searchResult.linked`, 'Linked')
                              : '-'}
                          </td>
                          <td className="details">
                            <button
                              className="btn btn-outline-primary btn-details"
                              type="button"
                              onClick={() => {
                                setSelectedStudentID(student.UserId);
                                setShowStudentDetailsModal(true);
                              }}
                            >
                              {t(
                                `manage-class:searchResult.details`,
                                'Details'
                              )}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
              <Pagination currentPage={currentPage} setPage={setCurrentPage} />
            </div>
          </div>
        ) : (
          <div className="empty-result">
            <img src={EmptySearchResults} alt="No results found" />
          </div>
        ))}
    </SearchResultContainer>
  );
}
