import {
  FETCH_SKILLS_REQUEST,
  FETCH_SKILLS_SUCCESS,
  FETCH_SKILLS_FAILED,
  SELECTED_SKILL,
  RESET_SELECTED_SKILL
} from './actionTypes';

const initialState = {
  skills: [],
  selectedSkill: {},
  isLoading: false,
  error: ''
};

export default function skills(state = initialState, action) {
  switch (action.type) {
    case FETCH_SKILLS_REQUEST:
      return { ...state, isLoading: true };
    case FETCH_SKILLS_SUCCESS:
      return {
        ...initialState,
        skills: action.skills,
        selectedSkill: state.selectedSkill
      };
    case FETCH_SKILLS_FAILED:
      return { ...state, isLoading: false, error: action.error };
    case SELECTED_SKILL:
      return { ...state, selectedSkill: action.selectedSkill };
    case RESET_SELECTED_SKILL:
      return { ...state, selectedSkill: {} };
    default:
      return state;
  }
}
