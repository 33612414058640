import { call, put } from 'redux-saga/effects';
import { isNil } from 'ramda';
import createGroupService from 'services/createGroup';
import {
  fetchMyGroupSuccess,
  fetchMyGroupFailed,
  updateGroupSuccess,
  updateGroupFailed,
  createMyGroupSuccess,
  createMyGroupFailed,
  deleteGroupSuccess,
  deleteGroupFailed,
  getClassByLevelSuccess,
  getClassByLevelFailed,
  getStudentByClassSuccess,
  getStudentByClassFailed
} from 'stores/createGroup/actions';

/**
 * The saga for create group
 */
export function* createGroupData({ params }) {
  const data = yield call(createGroupService.createNewGroup, params);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(createMyGroupSuccess(data));
    } else {
      yield put(createMyGroupFailed(data.error));
    }
  }
}

/**
 * The saga for fetch group data
 */
export function* fetchMyGroup() {
  const data = yield call(createGroupService.fetchGroupData);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchMyGroupSuccess(data));
    } else {
      yield put(fetchMyGroupFailed(data.error));
    }
  }
}

/**
 * The saga for get group by id
 */
export function* updateGroup({ params }) {
  const data = yield call(createGroupService.updateGroup, params);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(updateGroupSuccess(data));
    } else {
      yield put(updateGroupFailed(data.error));
    }
  }
}

/**
 * The saga for delete group
 */
export function* deleteGroupById({ params }) {
  const data = yield call(createGroupService.deleteGroupById, params);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(deleteGroupSuccess(data));
    } else {
      yield put(deleteGroupFailed(data.error));
    }
  }
}

/**
 * The saga for getting classes by level
 */
export function* getClassesByLevel() {
  const data = yield call(createGroupService.getClassByLevel);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(getClassByLevelSuccess(data));
    } else {
      yield put(getClassByLevelFailed(data.error));
    }
  }
}

/**
 * The saga for getting students by classes
 */
export function* getStudentsByClass({ params }) {
  const data = yield call(createGroupService.getStudentsByClass, params);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(getStudentByClassSuccess(data.List));
    } else {
      yield put(getStudentByClassFailed(data.error));
    }
  }
}
