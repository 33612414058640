import styled from 'styled-components';

export const CreateGroupListsWrapper = styled.div`
  width: 35%;
  border-right: 1px solid #c4c4c4;
  height: calc(100vh - 160px);
  overflow: auto;
  background-color: #fbfbfb;
  .no-student-container {
    margin: 60px 20px;
  }
  .no-studnet-selected {
    font-size: 16px;
    color: #545454;
    margin-bottom: 3px;
  }
  .group-list-title {
    margin: 20px;
    font-weight: 700;
    font-size: 17px;
    color: #1e1e1e;
  }
  .group-list-container {
    margin: 20px;
  }
  .list-class-title {
    color: #989898;
    margin-bottom: 20px;
  }
  .list-class-students {
    margin: 10px 0;
    color: #ff5b00;
    display: flex;
    align-items: center;
  }
  .delete-student {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin-left: 10px;
    background-color: #c4c4c4;
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 11px;
  }
`;
