import { call, put } from 'redux-saga/effects';
import { isNil } from 'ramda';
import moment from 'moment';
import AssignmentService from 'services/assignments';
import {
  fetchAssignmentsSuccess,
  fetchAssignmentsFailed,
  fetchTeacherAssignmentsSuccess,
  fetchTeacherAssignmentsFailed,
  fetchTeachersSuccess,
  fetchTeachersFailed,
  fetchMultiClassReportSuccess,
  fetchMultiClassReportFailed,
  fetchSingleClassReportSuccess,
  fetchSingleClassReportFailed,
  fetchCSVClassReportSuccess,
  fetchCSVClassReportFailed,
  setLatestYear,
  setNumOfPages,
  clearAssignments,
  deleteAssignedHwSuccess,
  deleteAssignedHwFailed,
  markAnswerRightSuccess,
  markAnswerRightFailed,
  changeTitleDateSuccess,
  changeTitleDateFailed
} from 'stores/assignments/actions';
import { parseCSVReport } from 'helpers/singleClassReport';

// Saga for fetching assignments summary (Assigned by me)
// Params for API endpoint
// date: 2020
// pageSize: 20 (Max number of results to return, optional can be null)
// pageIndex: 1 (Returns error if no more results on this pageIndex, optional can be null)
// *pageSize & pageIndex params must be passed together
// This function fetches the latest 21 assignments assigned by logged in user
export function* fetchAssignments({ params }) {
  const queryParams = {
    date: params.date,
    pageSize: params.pageSize,
    pageIndex: params.pageIndex
  };
  const data = yield call(AssignmentService.fetchAssignments, queryParams);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchAssignmentsSuccess(data));
      // Set available years if date = null (first load)
      yield put(setLatestYear(moment(data[0].AssignedDate).year()));
      // Set number of pages available
      if (params.date !== null) {
        yield put(setNumOfPages(data[0].TotalPage));
      } else {
        const year = moment(data[0].AssignedDate)
          .year()
          .toString();
        // Check if results list has 20 results from the latest year
        const dataFromLatestYear = data.filter(
          assignment =>
            moment(assignment.AssignedDate)
              .year()
              .toString() === year
        );
        if (dataFromLatestYear.length >= 20) {
          // Call API endpoint with latest year to check num of pages
          const assignmentsForYear = yield call(
            AssignmentService.fetchAssignments,
            {
              date: `${moment(data[0].AssignedDate)
                .year()
                .toString()}`,
              pageSize: 20,
              pageIndex: 1
            }
          );
          if (!isNil(assignmentsForYear) && isNil(assignmentsForYear.error)) {
            yield put(setNumOfPages(assignmentsForYear[0].TotalPage));
          }
        } else {
          yield put(fetchAssignmentsSuccess(dataFromLatestYear));
          yield put(setNumOfPages(1));
        }
      }
    } else {
      yield put(fetchAssignmentsFailed(data.error));
      yield put(clearAssignments());
    }
  }
}

// Saga for fetching assignment summary (Assigned by others)
export function* fetchTeacherAssignments({ params }) {
  const queryParams = {
    teacherID: params.teacherID,
    pageSize: params.pageSize,
    pageIndex: params.pageIndex,
    year: params.year
  };
  const data = yield call(
    AssignmentService.fetchTeacherAssignments,
    queryParams
  );
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchTeacherAssignmentsSuccess(data));
      yield put(setNumOfPages(data[0]?.TotalPage || 1));
    } else {
      yield put(fetchTeacherAssignmentsFailed(data.error));
      yield put(clearAssignments());
    }
  }
}

// Saga for fetching list of teachers (Assigned by others page)
export function* fetchTeachers() {
  const data = yield call(AssignmentService.fetchTeachers);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchTeachersSuccess(data));
    } else {
      yield put(fetchTeachersFailed(data.error));
    }
  }
}

// Saga for fetching multi-class report
export function* fetchMultiClassReport({ params }) {
  const queryParams = {
    homeworkID: params.homeworkID
  };
  const data = yield call(AssignmentService.fetchMultiClassReport, queryParams);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchMultiClassReportSuccess(data));
    } else {
      yield put(fetchMultiClassReportFailed(data.error));
    }
  }
}

// Saga for fetching single-class report
export function* fetchSingleClassReport({ params }) {
  const queryParams = {
    homeworkID: params.homeworkID
  };
  const data = yield call(
    AssignmentService.fetchSingleClassReport,
    queryParams
  );
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchSingleClassReportSuccess(data));
    } else {
      yield put(fetchSingleClassReportFailed(data.error));
    }
  }
}

// Saga for fetching csv class report
export function* fetchCSVClassReport({ params }) {
  const queryParams = {
    homeworkID: params.homeworkID
  };
  const data = yield call(
    AssignmentService.fetchSingleClassReport,
    queryParams
  );
  if (!isNil(data)) {
    if (isNil(data.error)) {
      // Parse api response
      const report = parseCSVReport(data);
      yield put(fetchCSVClassReportSuccess(data, report));
    } else {
      yield put(fetchCSVClassReportFailed(data.error));
    }
  }
}

// Saga for deleting assigned hw
export function* deleteAssignedHw({ params }) {
  const queryParams = {
    homeworkID: params.homeworkID
  };
  const data = yield call(AssignmentService.deleteAssignedHw, queryParams);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(deleteAssignedHwSuccess());
    } else {
      yield put(deleteAssignedHwFailed(data.error));
    }
  }
}

// Saga for mark answer right
export function* markAnswerRight({ params }) {
  const queryParams = {
    submissionId: params.submissionId
  };
  const data = yield call(AssignmentService.markAnswerRight, queryParams);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(markAnswerRightSuccess());
    } else {
      yield put(markAnswerRightFailed(data.error));
    }
  }
}

export function* changeTitleDate({ params }) {
  const data = yield call(AssignmentService.changeTitleDate, params);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(changeTitleDateSuccess(data));
    } else {
      yield put(changeTitleDateFailed(data.error));
    }
  }
}
