import {
  FETCH_SKILLS_REQUEST,
  FETCH_SKILLS_SUCCESS,
  FETCH_SKILLS_FAILED,
  SELECTED_SKILL,
  RESET_SELECTED_SKILL
} from './actionTypes';

export const fetchSkillsRequest = () => {
  return { type: FETCH_SKILLS_REQUEST };
};

export const fetchSkillsSuccess = skills => {
  return { type: FETCH_SKILLS_SUCCESS, skills };
};

export const fetchSkillsFailed = error => {
  return { type: FETCH_SKILLS_FAILED, error };
};

export const selectSkill = selectedSkill => {
  return { type: SELECTED_SKILL, selectedSkill };
};

export const resetSelectedSkill = () => {
  return { type: RESET_SELECTED_SKILL };
};
