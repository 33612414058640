import styled from 'styled-components';

export const CurriculumCardContainer = styled.div`
  .card-content {
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 173px;
    border: 1px solid #cccccc;
    padding: 10px;
    justify-content: center;
    margin: 10px;
    p {
      text-align: center;
      font-size: 16px;
      color: #000000;
      font-weight: bold;
    }
    :hover {
      border: 2px solid #9ddfdd;
      cursor: pointer;
    }
    img {
      align-self: center;
    }
  }
  .selected {
    background-color: #ebf9ff;
    border: 1px solid #9ddfdd;
  }
`;
