import apiClient from 'services/api';
import fileDownload from 'js-file-download';
const handleResponseSuccess = result => {
  if (result.data?.IsSuccessful === false) {
    return {
      error: result.data?.Message?.Message ?? 'API request failed' // eslint-disable-line
    };
  }
  return result.data?.Result ?? [];
};

const handleResponseSuccessWithoutResult = result => {
  return result.data ?? [];
};
const handleDownloadResponse = async (result, fileName) => {
  return fileDownload(result.data, fileName);
};
// Fetch proficiency report for class
const fetchClassProficiencyReport = params => {
  const { classID, reportLevel, syllabusID } = params;
  return apiClient
    .get(
      `Report/GetAllUserProficiencyReport/Student/${classID}/${reportLevel}/${syllabusID}`
    )
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error:
          err?.response?.data?.message ??
          'Fetch class proficiency report failed' // eslint-disable-line
      };
    });
};

// Fetch highscore report for class
const fetchClassHighScoreReport = params => {
  const { classID, reportLevel, curriculumID } = params;
  return apiClient
    .get(
      `Report/GetAllUserMasteryLevelReport/Student/${classID}/${reportLevel}/${curriculumID}`
    )
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error:
          err?.response?.data?.message ?? 'Fetch class high score report failed' // eslint-disable-line
      };
    });
};
// Fetch proficiency report for skill
const fetchProficiencySkillReport = params => {
  const { classID, reportLevel, curriculumID, topicID } = params;
  return apiClient
    .get(
      `Report/GetAllUserProficiencyReport/Skill/${classID}/${reportLevel}/${curriculumID}/${topicID}`
    )
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error:
          err?.response?.data?.message ??
          'Fetch student proficiency skill report failed' // eslint-disable-line
      };
    });
};
// Fetch highscore report for skill
const fetchHighScoreSkillReport = params => {
  const { classID, reportLevel, curriculumID, topicID } = params;
  return apiClient
    .get(
      `Report/GetAllUserMasteryLevelReport/Skill/${classID}/${reportLevel}/${curriculumID}/${topicID}`
    )
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error:
          err?.response?.data?.message ??
          'Fetch student proficiency skill report failed' // eslint-disable-line
      };
    });
};
// Fetch proficiency/highscore report for student
const fetchStudentProficiencyReport = params => {
  const { studentID, classID, reportLevel, syllabusID } = params;
  return apiClient
    .get(
      `Report/GetUserProficiencyAndMasteryReport/${studentID}/${classID}/${reportLevel}/${syllabusID}`
    )
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error:
          err?.response?.data?.message ??
          'Fetch student proficiency/high score report failed' // eslint-disable-line
      };
    });
};

// Fetch sample qn info by qn ID
const fetchSampleQn = params => {
  const { qnID } = params;
  const options = {
    params: {
      includeAnswer: false,
      includeSolution: true
    }
  };
  return apiClient
    .get(`Question/GetById/${qnID}`, options)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch sample question failed' // eslint-disable-line
      };
    });
};

// Fetch Login Report
const fetchLoginReport = params => {
  const { classID, weekID } = params;
  return apiClient
    .get(`Report/GetLoginReport/${classID}/${weekID}`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch login report failed' // eslint-disable-line
      };
    });
};

// Fetch Week Items
const fetchWeekItems = params => {
  const { numberOfPreviousWeek } = params;
  return apiClient
    .get(`Report/GetWeekItems/${numberOfPreviousWeek}`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch week items failed' // eslint-disable-line
      };
    });
};

// Fetch Teacher Report Month
const fetchTeacherMonthReport = params => {
  const { year } = params;
  return apiClient
    .get(`TeacherReport/GetReportMonths/${year}`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error:
          err?.response?.data?.message ?? 'Fetch teacher report month failed' // eslint-disable-line
      };
    });
};

// Fetch Teacher Report Specific Month
const fetchTeacherSpecificMonthReport = params => {
  const { year, month } = params;
  return apiClient
    .get(`TeacherReport/GetMonthlyReport?year=${year}&month=${month}`)
    .then(handleResponseSuccessWithoutResult)
    .catch(err => {
      return {
        error:
          err?.response?.data?.message ??
          'Fetch teacher report specific month failed' // eslint-disable-line
      };
    });
};

// Fetch skill proficiency detail report for student
const fetchSkillProficiencyDetailReport = params => {
  const { classID, reportLevel, curriculumID, topicID, skillID } = params;
  return apiClient
    .get(
      `Report/GetAllUserProficiencyReport/Skill/${classID}/${reportLevel}/${curriculumID}/${topicID}/${skillID}`
    )
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error:
          err?.response?.data?.message ??
          'Fetch skill proficiency detail report failed' // eslint-disable-line
      };
    });
};

// Fetch skill highscore detail report for student
const fetchSkillHighScoreDetailReport = params => {
  const { classID, reportLevel, curriculumID, topicID, skillID } = params;
  return apiClient
    .get(
      `Report/GetAllUserMasteryLevelReport/Skill/${classID}/${reportLevel}/${curriculumID}/${topicID}/${skillID}`
    )
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error:
          err?.response?.data?.message ??
          'Fetch skill high score detail report failed' // eslint-disable-line
      };
    });
};
// Fetch download high score report
const fetchDownloadHighScoreReport = params => {
  const { classID, reportLevel, curriculumID, fileName } = params;
  return apiClient
    .get(
      `Report/DownloadAllUserMasteryLevelReport/Skill/${classID}/${reportLevel}/${curriculumID}`,
      { responseType: 'blob' }
    )
    .then(res => handleDownloadResponse(res, fileName))
    .catch(err => {
      console.log(err);
      throw new Error('Download high score report failed');
    });
};
// Fetch download proficiency report
const fetchDownloadProficiencyReport = params => {
  const { classID, reportLevel, curriculumID, fileName } = params;
  return apiClient
    .get(
      `Report/DownloadAllUserProficiencyReport/Skill/${classID}/${reportLevel}/${curriculumID}`,
      { responseType: 'blob' }
    )
    .then(res => handleDownloadResponse(res, fileName))
    .catch(err => {
      console.log(err);
      throw new Error('Download proficiency report failed');
    });
};
export default {
  fetchClassProficiencyReport,
  fetchClassHighScoreReport,
  fetchStudentProficiencyReport,
  fetchSampleQn,
  fetchLoginReport,
  fetchWeekItems,
  fetchTeacherMonthReport,
  fetchTeacherSpecificMonthReport,
  fetchProficiencySkillReport,
  fetchHighScoreSkillReport,
  fetchSkillProficiencyDetailReport,
  fetchSkillHighScoreDetailReport,
  fetchDownloadHighScoreReport,
  fetchDownloadProficiencyReport
};
