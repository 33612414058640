import React, { useState } from 'react';
import { isEmpty } from 'ramda';
import { useSelector } from 'react-redux';
import SelectTopic from 'components/SelectTopic';
import Content from 'components/Content/Content';
import Overlay from 'components/Content/Overlay';
import Sidebar from 'components/Sidebar/Sidebar';
import SidebarLevels from 'components/Sidebar/SidebarLevels';
import SidebarTopics from 'components/Sidebar/SidebarTopics';
import './BrowseContent.styles.scss';
// import { Redirect } from 'react-router-dom';
import Header from 'components/Header/Header';

export default function BrowseContent() {
  // const { user } = useSelector(state => state.login);
  const { selectedTopic, showSideBar } = useSelector(state => state.topics);
  const { selectedCurriculum } = useSelector(state => state.curriculums);
  const currLevels = selectedCurriculum && selectedCurriculum.Levels;
  const [expanded, setExpanded] = useState(false);
  const toggleNavigation = () => setExpanded(!expanded);

  // if (
  //   (isNil(user.DefaultLevel) || user.DefaultLevel < 1) &&
  //   !isEmpty(currLevels)
  // ) {
  //   return <Redirect to="/choose-level" />;
  // }

  return (
    <>
      <Header />
      <main>
        <Sidebar expanded={expanded} toggleNavigation={toggleNavigation}>
          {!isEmpty(currLevels) && <SidebarLevels />}
          <SidebarTopics />
        </Sidebar>
        <div className="content content--browse-content">
          {isEmpty(selectedTopic) && !showSideBar ? (
            <>
              <Overlay isVisible={expanded} toggleVisible={toggleNavigation} />
              <SelectTopic textClass={expanded ? 'text-light' : ''} />
            </>
          ) : (
            <>
              <Content />
              <Overlay isVisible={expanded} toggleVisible={toggleNavigation} />
            </>
          )}
        </div>
      </main>
    </>
  );
}
