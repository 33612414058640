import React from 'react';
import { Modal } from 'react-bootstrap';
import { StyledModal } from './PopupModal.styles';

export default function PopupModal({ children, show, hide, size }) {
  return (
    <StyledModal
      dialogClassName={size === 'sm' ? 'modal-custom' : 'modal-custom-large'}
      show={show}
      keyboard={false}
      onHide={() => {
        hide();
      }}
    >
      <Modal.Header closeButton className="modal-header-custom" />
      <Modal.Body>{children}</Modal.Body>
    </StyledModal>
  );
}
