import * as actionTypes from './actionTypes';

export const fetchClassProficiencyReportRequest = params => {
  return {
    type: actionTypes.FETCH_CLASS_PROFICIENCY_REPORT_REQUEST,
    params
  };
};

export const fetchClassProficiencyReportSuccess = report => {
  return {
    type: actionTypes.FETCH_CLASS_PROFICIENCY_REPORT_SUCCESS,
    report
  };
};

export const fetchClassProficiencyReportFailed = error => {
  return {
    type: actionTypes.FETCH_CLASS_PROFICIENCY_REPORT_FAILED,
    error
  };
};

export const fetchClassHighScoreReportRequest = params => {
  return {
    type: actionTypes.FETCH_CLASS_HIGHSCORE_REPORT_REQUEST,
    params
  };
};

export const fetchClassHighScoreReportSuccess = report => {
  return {
    type: actionTypes.FETCH_CLASS_HIGHSCORE_REPORT_SUCCESS,
    report
  };
};

export const fetchClassHighScoreReportFailed = error => {
  return {
    type: actionTypes.FETCH_CLASS_HIGHSCORE_REPORT_FAILED,
    error
  };
};

export const fetchStudentProficiencyReportRequest = params => {
  return {
    type: actionTypes.FETCH_STUDENT_PROFICIENCY_REPORT_REQUEST,
    params
  };
};

export const fetchStudentProficiencyReportSuccess = report => {
  return {
    type: actionTypes.FETCH_STUDENT_PROFICIENCY_REPORT_SUCCESS,
    report
  };
};

export const fetchStudentProficiencyReportFailed = error => {
  return {
    type: actionTypes.FETCH_STUDENT_PROFICIENCY_REPORT_FAILED,
    error
  };
};

export const fetchSampleQnRequest = params => {
  return {
    type: actionTypes.FETCH_SAMPLE_QN_REQUEST,
    params
  };
};

export const fetchSampleQnSuccess = question => {
  return {
    type: actionTypes.FETCH_SAMPLE_QN_SUCCESS,
    question
  };
};

export const fetchSampleQnFailed = error => {
  return {
    type: actionTypes.FETCH_SAMPLE_QN_FAILED,
    error
  };
};

export const setSelectedClass = classObject => {
  return {
    type: actionTypes.SET_SELECTED_CLASS,
    classObject
  };
};

export const setSortOrder = sortOrder => {
  return {
    type: actionTypes.SET_SORT_ORDER,
    sortOrder
  };
};

export const setReportLevel = reportLevel => {
  return {
    type: actionTypes.SET_REPORT_LEVEL,
    reportLevel
  };
};

export const setSyllabus = syllabus => {
  return {
    type: actionTypes.SET_SYLLABUS,
    syllabus
  };
};

export const setClassLevel = level => {
  return {
    type: actionTypes.SET_CLASS_LEVEL,
    level
  };
};

export const fetchLoginReportRequest = params => {
  return {
    type: actionTypes.FETCH_LOGIN_REPORT_REQUEST,
    params
  };
};

export const fetchLoginReportSuccess = report => {
  return {
    type: actionTypes.FETCH_LOGIN_REPORT_SUCCESS,
    report
  };
};

export const fetchLoginReportFailed = error => {
  return {
    type: actionTypes.FETCH_LOGIN_REPORT_FAILED,
    error
  };
};

export const fetchWeekItemsRequest = params => {
  return {
    type: actionTypes.FETCH_WEEK_ITEMS_REQUEST,
    params
  };
};

export const fetchWeekItemsSuccess = weeks => {
  return {
    type: actionTypes.FETCH_WEEK_ITEMS_SUCCESS,
    weeks
  };
};

export const fetchWeekItemsFailed = error => {
  return {
    type: actionTypes.FETCH_WEEK_ITEMS_FAILED,
    error
  };
};

export const toggleReport = val => {
  return {
    type: actionTypes.TOGGLE_REPORTS,
    val
  };
};

export const fetchTeacherReportMonthRequest = params => {
  return {
    type: actionTypes.FETCH_TEACHER_REPORT_MONTH_REQUEST,
    params
  };
};

export const fetchTeacherReportMonthSuccess = report => {
  return {
    type: actionTypes.FETCH_TEACHER_REPORT_MONTH_SUCCESS,
    report
  };
};

export const fetchTeacherReportMonthFailed = error => {
  return {
    type: actionTypes.FETCH_TEACHER_REPORT_MONTH_FAILED,
    error
  };
};

export const fetchTeacherReportSpecificMonthRequest = params => {
  return {
    type: actionTypes.FETCH_TEACHER_REPORT_SPECIFIC_MONTH_REQUEST,
    params
  };
};

export const fetchTeacherReportSpecificMonthSuccess = report => {
  return {
    type: actionTypes.FETCH_TEACHER_REPORT_SPECIFIC_MONTH_SUCCESS,
    report
  };
};

export const fetchTeacherReportSpecificMonthFailed = error => {
  return {
    type: actionTypes.FETCH_TEACHER_REPORT_SPECIFIC_MONTH_FAILED,
    error
  };
};

export const fetchSyllabusRequest = params => {
  return {
    type: actionTypes.FETCH_REPORT_SYLLABUS_REQUEST,
    params
  };
};

export const fetchSyllabusSuccess = data => {
  return {
    type: actionTypes.FETCH_REPORT_SYLLABUS_SUCCESS,
    data
  };
};

export const fetchSyllabusFailed = error => {
  return {
    type: actionTypes.FETCH_REPORT_SYLLABUS_FAILED,
    error
  };
};

export const fetchProficiencySkillReportRequest = params => {
  return {
    type: actionTypes.FETCH_REPORT_PROFICIENCY_SKILL_REQUEST,
    params
  };
};
export const fetchProficiencySkillReportSuccess = data => {
  return {
    type: actionTypes.FETCH_REPORT_PROFICIENCY_SKILL_SUCCESS,
    data
  };
};
export const fetchProficiencySkillReportFailed = error => {
  return {
    type: actionTypes.FETCH_REPORT_PROFICIENCY_SKILL_FAILED,
    error
  };
};
export const cleanProficiencySkillReport = () => {
  return {
    type: actionTypes.CLEAN_REPORT_PROFICIENCY_SKILL
  };
};

export const fetchHighScoreSkillReportRequest = params => {
  return {
    type: actionTypes.FETCH_REPORT_HIGHSCORE_SKILL_REQUEST,
    params
  };
};
export const fetchHighScoreSkillReportSuccess = data => {
  return {
    type: actionTypes.FETCH_REPORT_HIGHSCORE_SKILL_SUCCESS,
    data
  };
};
export const fetchHighScoreSkillReportFailed = error => {
  return {
    type: actionTypes.FETCH_REPORT_HIGHSCORE_SKILL_FAILED,
    error
  };
};

export const fetchSkillProficiencyDetailReportRequest = params => {
  return {
    type: actionTypes.FETCH_SKILL_PROFICIENCY_DETAIL_REPORT_REQUEST,
    params
  };
};

export const fetchSkillProficiencyDetailReportSuccess = report => {
  return {
    type: actionTypes.FETCH_SKILL_PROFICIENCY_DETAIL_REPORT_SUCCESS,
    report
  };
};

export const fetchSkillProficiencyDetailReportFailed = error => {
  return {
    type: actionTypes.FETCH_SKILL_PROFICIENCY_DETAIL_REPORT_FAILED,
    error
  };
};

export const fetchSkillHighScoreDetailReportRequest = params => {
  return {
    type: actionTypes.FETCH_SKILL_HIGHSCORE_DETAIL_REPORT_REQUEST,
    params
  };
};

export const fetchSkillHighScoreDetailReportSuccess = report => {
  return {
    type: actionTypes.FETCH_SKILL_HIGHSCORE_DETAIL_REPORT_SUCCESS,
    report
  };
};

export const fetchSkillHighScoreDetailReportFailed = error => {
  return {
    type: actionTypes.FETCH_SKILL_HIGHSCORE_DETAIL_REPORT_FAILED,
    error
  };
};
export const cleanHighScoreSkillReport = () => {
  return {
    type: actionTypes.CLEAN_REPORT_HIGHSCORE_SKILL
  };
};
