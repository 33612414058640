import styled from 'styled-components';

export const AssignmentDate = styled.div`
  font-size: 0.75rem;
  color: rgba(114, 114, 114, 0.65);
  padding: 0 0.5rem 0.5rem 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .edit-status {
    font-size: 0.875rem;
    color: #ff0000;
  }
`;

export const StyledCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 1rem;
  border: solid 1px #dfdfdf;
  color: #1e1e1e;
  .hw-link {
    color: #1e1e1e;
    &:hover {
      color: #ff7121;
    }
  }

  .bg-progress-bar-submission {
    background-color: #ff5b00;
  }

  .progress {
    border-radius: 30px;
  }
  .class-submission {
    display: flex;
    flex-direction: row;
    padding: 0.8rem 1rem;
    border-top: solid 1px #dfdfdf;
    background: #f7f9f9;
    &:hover {
      cursor: pointer;
    }
    .submission-class-student {
      padding-left: 2.7rem;
      margin-right: 1rem;
      width: 240px;
    }
    .submissions {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .details {
    display: flex;
    flex-direction: row;

    .title {
      flex-basis: 50%;
      font-size: 17px;
      font-weight: 600;
      padding: 1rem 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      .hw-type-icon {
        width: 31px;
        height: 31px;
        border-radius: 50%;
        padding: 0.5rem;
        font-family: Helvetica, sans-serif;
        font-size: 19px;
        font-weight: 100;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 0.7rem;

        img {
          width: 31px;
          height: 31px;
        }
      }
    }
    .qn-count {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-basis: 8%;
      border-left: solid 1px #c8c8c8;
      border-right: solid 1px #c8c8c8;
    }
    .dates {
      flex-basis: 17%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .dates-title {
        font-size: 0.75rem;
        font-weight: 600;
        color: rgba(114, 114, 114, 0.55);
      }
      .start-end-dates {
        font-size: 0.8125rem;
        color: #1e1e1e;
      }
      .start-end-dates:hover {
        text-decoration-line: underline;
        cursor: pointer;
        color: #ff5b00;
      }
      .visible-date {
        font-size: 0.6875rem;
        color: #ff5b00;
      }
    }
    .actions {
      flex-basis: 25%;
      border-left: solid 1px #c8c8c8;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      padding: 0 1rem;
      .btn-assign-button {
        height: 31px;
        width: 134px;
        padding: 0;
        border: solid 1px #d5d5d5;
        &:hover {
          border-color: #ff5b00;
        }
      }
      .btn-print-button {
        width: 57px;
        height: 31px;
        padding: 0 0 0.5rem 0;
        border: solid 1px #d5d5d5;
        &:hover {
          border-color: #ff5b00;
        }
      }
      .btn-open-button {
        height: 31px;
        width: 84px;
        padding: 0;
      }
    }
  }
`;
