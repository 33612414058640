import React from 'react';
import { useTranslation } from 'react-i18next';
import finger from 'assets/img/Icon Select.svg';

export default function SelectTopic(props) {
  const { textClass } = props;
  const { t } = useTranslation(['browse-content']);
  return (
    <div className="middle">
      <div className="select-topic">
        <img src={finger} alt="select-topic" />
        <h1 className={textClass}>
          {t(`browse-content:main.select`, 'Select topic to start')}
        </h1>
      </div>
    </div>
  );
}
