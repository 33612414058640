import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import generateLevels from 'helpers/reportLevels';
import {
  fetchLoginReportRequest,
  fetchWeekItemsRequest,
  setSelectedClass,
  setClassLevel
} from 'stores/report/actions';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { fetchClassRequest } from 'stores/utils/lookup/actions';
import { Dropdown } from 'react-bootstrap';
import { BarChart, Bar, Cell, XAxis, YAxis } from 'recharts';
import moment from 'moment';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import aggregateLoginReportData from 'helpers/aggregateLoginReportData';
import { Container, ClassAttendance } from './LoginReportCard.styles';

const LoginReportCard = () => {
  const { t } = useTranslation(['dashboard']);
  const dispatch = useDispatch();
  const ref = useRef(null);
  // Redux States
  const {
    loginReport,
    weekItems,
    selectedClass,
    selectedClassLevel,
    isLoading,
    reportError
  } = useSelector(state => state.reports);
  const { classes } = useSelector(state => state.lookup);
  const currentDayOfWeek = moment().day();
  const reportLevels = generateLevels();
  const [weekId, setWeekId] = useState(0);
  const [selectedBarChartDay, setSelectedBarChartDay] = useState(
    currentDayOfWeek
  );
  const [barWidth, setBarWidth] = useState(0);
  // Filter out only classes of type 1
  const filteredClasses =
    classes.length > 0
      ? classes.filter(classObject => classObject.Type === 1)
      : [];

  let aggregatedData;
  if (loginReport) {
    aggregatedData = aggregateLoginReportData(loginReport);
  }

  // UseEffects
  useEffect(() => {
    dispatch(fetchClassRequest(selectedClassLevel));
  }, [dispatch, selectedClassLevel]);

  useEffect(() => {
    dispatch(fetchWeekItemsRequest({ numberOfPreviousWeek: 3 }));
  }, [dispatch]);

  useDeepCompareEffect(() => {
    if (selectedClass) {
      dispatch(
        fetchLoginReportRequest({
          classID: selectedClass && selectedClass.ID,
          weekID: weekId
        })
      );
    }
  }, [dispatch, selectedClass, weekId]);
  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        if (ref.current.offsetWidth > 525) {
          setBarWidth(525);
        } else {
          setBarWidth(ref.current.offsetWidth);
        }
      }
    };
    window.addEventListener('resize', handleResize);
    window.addEventListener(
      'DOMContentLoaded',
      setBarWidth(ref.current.offsetWidth)
    );
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Container ref={ref}>
      <div className="dropdown-container">
        <span className="dropdown-label">
          {t('dashboard:loginReport.classFrom', 'Class from')}
        </span>
        <Dropdown bsPrefix="level-dropdown">
          <Dropdown.Toggle variant="light" className="dropdown-custom level">
            {`P${selectedClassLevel}`}
          </Dropdown.Toggle>
          <Dropdown.Menu flip={false}>
            {reportLevels.map(level => (
              <Dropdown.Item
                key={level}
                onClick={() => {
                  dispatch(setClassLevel(level));
                }}
              >{`P${level}`}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown className="class">
          <Dropdown.Toggle variant="light" className="dropdown-custom">
            {selectedClass && selectedClass.Name ? selectedClass.Name : '-'}
          </Dropdown.Toggle>
          <Dropdown.Menu flip={false}>
            {filteredClasses.map(item => (
              <Dropdown.Item
                key={item.ID}
                onClick={event => {
                  event.preventDefault();
                  dispatch(setSelectedClass(item));
                }}
              >
                {item.Name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="dropdown-container">
        <span className="dropdown-label">
          {t('dashboard:loginReport.Time', 'Time')}
        </span>
        <Dropdown className="week">
          <Dropdown.Toggle variant="light" className="dropdown-custom">
            {`Week ${
              !isNil(weekItems)
                ? moment(
                    weekItems[weekId].Display.split(' - ')[1],
                    'DD MMM YYYY'
                  ).isoWeek()
                : ''
            }`}
          </Dropdown.Toggle>
          <Dropdown.Menu flip={false}>
            {!isNil(weekItems) &&
              weekItems.map(week => (
                <Dropdown.Item
                  key={week.Id}
                  onClick={event => {
                    event.preventDefault();
                    setWeekId(week.Id);
                  }}
                >
                  {`Week ${moment(
                    week.Display.split(' - ')[1],
                    'DD MMM YYYY'
                  ).isoWeek()}`}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <ClassAttendance>
        <svg width="6.04px" height="6.81px" className="indicator">
          <rect width="6.04px" height="6.81px" style={{ fill: '#46C2CB' }} />
        </svg>
        <span className="num-logged-in">
          {!isNil(aggregatedData)
            ? aggregatedData[selectedBarChartDay].loggedIn
            : ''}
        </span>
        <span className="class-size">
          {!isNil(aggregatedData) &&
            ` /${aggregatedData[selectedBarChartDay]?.total +
              aggregatedData[selectedBarChartDay]?.loggedIn}`}
        </span>
        {!isNil(weekItems) && (
          <span className="logged-in-date">
            {t('dashboard:loginReport.loggedInOn', 'Logged in on')}{' '}
            {moment(weekItems[weekId].Display.split(' - ')[1], 'DD MMM YYYY')
              .subtract(6 - selectedBarChartDay, 'days')
              .format('DD/MM/YYYY, dddd')}
          </span>
        )}
        {isLoading && <div>Loading...</div>}
        {!isLoading && reportError !== null && <p>{reportError}</p>}
        {!isLoading && reportError === null && !isNil(aggregatedData) && (
          <BarChart
            width={barWidth}
            height={180}
            data={aggregatedData}
            margin={{
              top: 20
            }}
            barSize={30}
          >
            <XAxis dataKey="name" />
            <YAxis tick={false} width={30} />
            <Bar dataKey="loggedIn" stackId="a" fill="#46C2CB">
              {aggregatedData.map((entry, index) => (
                <Cell
                  key={entry.name}
                  stroke={index === selectedBarChartDay ? '#FFE330' : '#DBDBDB'}
                  strokeWidth={index === selectedBarChartDay ? '4px' : 0}
                  onClick={() => {
                    setSelectedBarChartDay(index);
                  }}
                />
              ))}
            </Bar>
            <Bar dataKey="total" stackId="a" fill="#DBDBDB">
              {aggregatedData.map((entry, index) => (
                <Cell
                  key={entry.name}
                  stroke={index === selectedBarChartDay ? '#FFE330' : '#DBDBDB'}
                  strokeWidth={index === selectedBarChartDay ? '4px' : 0}
                  onClick={() => {
                    setSelectedBarChartDay(index);
                  }}
                />
              ))}
            </Bar>
            <Bar dataKey="future" stackId="a" fill="rgba(219, 219, 219, 0.21)">
              {aggregatedData.map((entry, index) => (
                <Cell
                  key={entry.name}
                  stroke={
                    index === selectedBarChartDay
                      ? '#FFE330'
                      : 'rgba(219, 219, 219, 0.21)'
                  }
                  strokeWidth={index === selectedBarChartDay ? '4px' : 0}
                />
              ))}
            </Bar>
          </BarChart>
        )}
      </ClassAttendance>
    </Container>
  );
};

export default LoginReportCard;
