import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'ramda';
import {
  fetchStudentsRequest,
  resetPwResultAction,
  resetError
} from 'stores/manage-class/actions';
import moment from 'moment';
import SchoolPlanIcon from 'assets/img/icon-manage-class-student-list-plan-school.svg';
import StandardPlanIcon from 'assets/img/icon-manage-class-student-list-plan-standard.svg';
import PremiumPlanIcon from 'assets/img/icon-manage-class-student-list-plan-premium.svg';
import LinkApp from 'assets/img/icon-manage-class-student-list-link.svg';
import avatarPlaceholder from 'assets/img/avatar.png';
import Spinner from 'components/Shared/Spinner';
import PopupModal from './PopupModal';
import StudentDetailsModalBody from './StudentDetailsModalBody';
import ResetPwModalBody from './ResetPwModalBody';
import { StudentTableContainer } from './StudentTable.styles';

// Helpers
const setCheckboxesToChecked = check => {
  const checkboxes = document.getElementsByClassName('individual');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < checkboxes.length; i++) {
    checkboxes[i].checked = check;
  }
};

const getPlanIcon = planID => {
  switch (planID) {
    case 0:
      return SchoolPlanIcon;
    case 2:
      return StandardPlanIcon;
    case 3:
      return PremiumPlanIcon;
    case 5:
      return PremiumPlanIcon;
    case 6:
      return PremiumPlanIcon;
    default:
      return null;
  }
};

export default function StudentTable({
  selectedStudentIDs,
  setSelectedStudentIDs,
  classID,
  currentPage,
  setPage
}) {
  const { t } = useTranslation(['manage-class']);
  const dispatch = useDispatch();
  const { students, resetPwResult, isLoading } = useSelector(
    state => state.manageClasses
  );
  // Modal States
  const [showStudentDetailsModal, setShowStudentDetailsModal] = useState(false);
  const [showResetPwModal, setShowResetPwModal] = useState(false);
  // Stores selected studentID for reset pw & student details modal
  const [selectedStudentID, setSelectedStudentID] = useState(null);
  const openResetPwModal = () => {
    dispatch(resetError());
    setShowResetPwModal(true);
  };
  // Event handlers to hide popup modals
  const hideStudentDetailsModal = () => {
    setShowStudentDetailsModal(false);
  };
  const hideResetPwModal = () => {
    if (resetPwResult === true) {
      dispatch(
        fetchStudentsRequest({
          classID,
          pageNum: currentPage
        })
      );
    }
    dispatch(resetError());
    dispatch(resetPwResultAction());
    setShowResetPwModal(false);
  };

  const studentIDsArray = students?.hasOwnProperty('List')
    ? students.List.map(student => student.UserId)
    : [];

  const passwordFormat = (pass, username) => {
    if (isNil(pass)) {
      return 'N.A';
    }
    if (pass === username) {
      return t(`manage-class:studList.sameAsId`, 'Same as the login ID');
    }
    return pass;
  };

  // Effect to fetch data via redux saga
  useEffect(() => {
    dispatch(
      fetchStudentsRequest({
        classID,
        pageNum: currentPage
      })
    );
    // eslint-disable-next-line
  }, [currentPage, dispatch]);

  useEffect(() => {
    setPage(1);
    dispatch(
      fetchStudentsRequest({
        classID,
        pageNum: 1
      })
    );
    // eslint-disable-next-line
  }, [classID]);
  return (
    <StudentTableContainer>
      {isLoading && <Spinner position="inherit" gridColumn="1/17" />}
      {!isLoading && students?.hasOwnProperty('List') === false && (
        <span
          style={{
            gridColumn: '1/17',
            textAlign: 'center',
            marginTop: '1rem',
            height: '70vh'
          }}
        >
          No students
        </span>
      )}
      {!isLoading && students?.hasOwnProperty('List') && (
        <>
          <PopupModal show={showResetPwModal} hide={hideResetPwModal} size="sm">
            <ResetPwModalBody
              hide={hideResetPwModal}
              studentID={selectedStudentID}
            />
          </PopupModal>
          <PopupModal
            show={showStudentDetailsModal}
            hide={hideStudentDetailsModal}
            size="lg"
          >
            <StudentDetailsModalBody
              hide={hideStudentDetailsModal}
              studentID={selectedStudentID}
            />
          </PopupModal>
          <table className="table">
            <thead className="table-borderless">
              <tr className="table-head">
                <div className="container-checkbox">
                  <input
                    type="checkbox"
                    className="student-check-box"
                    onChange={event => {
                      if (event.target.checked) {
                        setCheckboxesToChecked(true);
                        setSelectedStudentIDs(studentIDsArray);
                      } else {
                        setCheckboxesToChecked(false);
                        setSelectedStudentIDs([]);
                      }
                    }}
                  />
                  <span className="checkmark" />
                </div>
                <th scope="col">{t(`manage-class:studList.plan`, 'Plan')}</th>
                <th scope="col">
                  {t(`manage-class:studList.studName`, 'Student Name')}
                </th>
                <th scope="col">
                  {t(`manage-class:searchResult.loginId`, 'Login ID')}
                </th>
                <th scope="col">
                  {t(`manage-class:searchResult.password`, 'Password')}
                </th>
                <th scope="col">
                  {t(`manage-class:searchResult.activeDate`, 'Activation Date')}
                  <span>
                    {t(`manage-class:searchResult.dateFormat`, '(dd/mm/yyyy)')}
                  </span>
                </th>
                <th scope="col">
                  {t(
                    `manage-class:searchResult.status`,
                    'Parent App Linking Status'
                  )}
                </th>
                <th scope="col"> </th>
              </tr>
            </thead>
            <tbody className="table-bordered">
              {students?.hasOwnProperty('List') &&
                students.List.map(item => (
                  <tr className="table-row" key={item.UserId}>
                    <div className="container-checkbox">
                      <input
                        type="checkbox"
                        className="student-check-box individual"
                        name="check"
                        onChange={event => {
                          if (event.target.checked) {
                            setSelectedStudentIDs([
                              ...selectedStudentIDs,
                              item.UserId
                            ]);
                          } else {
                            setSelectedStudentIDs(
                              selectedStudentIDs.filter(
                                sth => sth !== item.UserId
                              )
                            );
                          }
                        }}
                      />
                      <span className="checkmark" />
                    </div>
                    <td
                      className="plan"
                      style={{
                        background:
                          selectedStudentIDs.includes(item.UserId) &&
                          'rgba(255, 196, 0, 0.33)',
                        borderTop:
                          selectedStudentIDs.includes(item.UserId) &&
                          'solid 1px rgba(255, 162, 0, 0.33)',
                        borderLeft:
                          selectedStudentIDs.includes(item.UserId) &&
                          'solid 1px rgba(255, 162, 0, 0.33)',
                        borderBottom:
                          selectedStudentIDs.includes(item.UserId) &&
                          'solid 1px rgba(255, 162, 0, 0.33)'
                      }}
                    >
                      {getPlanIcon(item.PlanId) !== null && (
                        <img src={getPlanIcon(item.PlanId)} alt="Plan Icon" />
                      )}
                      {item.PlanName}
                    </td>
                    <td
                      className="table-row--student"
                      style={{
                        background:
                          selectedStudentIDs.includes(item.UserId) &&
                          'rgba(255, 196, 0, 0.33)',
                        borderTop:
                          selectedStudentIDs.includes(item.UserId) &&
                          'solid 1px rgba(255, 162, 0, 0.33)',
                        borderBottom:
                          selectedStudentIDs.includes(item.UserId) &&
                          'solid 1px rgba(255, 162, 0, 0.33)'
                      }}
                    >
                      <img
                        onError={e => {
                          e.target.src = avatarPlaceholder;
                        }}
                        src={item.ProfileImage}
                        alt="profile"
                      />
                      {item.DisplayName}
                    </td>
                    <td
                      className="table-row--student-id"
                      style={{
                        background:
                          selectedStudentIDs.includes(item.UserId) &&
                          'rgba(255, 196, 0, 0.33)',
                        borderTop:
                          selectedStudentIDs.includes(item.UserId) &&
                          'solid 1px rgba(255, 162, 0, 0.33)',
                        borderBottom:
                          selectedStudentIDs.includes(item.UserId) &&
                          'solid 1px rgba(255, 162, 0, 0.33)'
                      }}
                    >
                      {item.UserName}
                    </td>
                    <td
                      className="table-row--password"
                      style={{
                        background:
                          selectedStudentIDs.includes(item.UserId) &&
                          'rgba(255, 196, 0, 0.33)',
                        borderTop:
                          selectedStudentIDs.includes(item.UserId) &&
                          'solid 1px rgba(255, 162, 0, 0.33)',
                        borderBottom:
                          selectedStudentIDs.includes(item.UserId) &&
                          'solid 1px rgba(255, 162, 0, 0.33)'
                      }}
                    >
                      <span>
                        {passwordFormat(item.password, item.UserName)}
                      </span>
                      {item.password !== item.UserName && (
                        <button
                          type="button"
                          onClick={() => {
                            setSelectedStudentID(item.UserId);
                            openResetPwModal();
                          }}
                        >
                          {t(`manage-class:studList.reset`, 'Reset')}
                        </button>
                      )}
                    </td>
                    <td
                      className="table-row--active-date"
                      style={{
                        background:
                          selectedStudentIDs.includes(item.UserId) &&
                          'rgba(255, 196, 0, 0.33)',
                        borderTop:
                          selectedStudentIDs.includes(item.UserId) &&
                          'solid 1px rgba(255, 162, 0, 0.33)',
                        borderBottom:
                          selectedStudentIDs.includes(item.UserId) &&
                          'solid 1px rgba(255, 162, 0, 0.33)'
                      }}
                    >
                      {item.ActivationDate === null
                        ? 'N.A'
                        : moment(item.ActivationDate).format('DD/MM/YYYY')}
                    </td>
                    <td
                      style={{
                        background:
                          selectedStudentIDs.includes(item.UserId) &&
                          'rgba(255, 196, 0, 0.33)',
                        borderTop:
                          selectedStudentIDs.includes(item.UserId) &&
                          'solid 1px rgba(255, 162, 0, 0.33)',
                        borderBottom:
                          selectedStudentIDs.includes(item.UserId) &&
                          'solid 1px rgba(255, 162, 0, 0.33)'
                      }}
                    >
                      {item.ParentAppLinkingStatus ? (
                        <img src={LinkApp} alt="Linked" />
                      ) : (
                        '-'
                      )}
                    </td>
                    <td
                      className="table-row--detail"
                      style={{
                        background:
                          selectedStudentIDs.includes(item.UserId) &&
                          'rgba(255, 196, 0, 0.33)',
                        borderTop:
                          selectedStudentIDs.includes(item.UserId) &&
                          'solid 1px rgba(255, 162, 0, 0.33)',
                        borderBottom:
                          selectedStudentIDs.includes(item.UserId) &&
                          'solid 1px rgba(255, 162, 0, 0.33)',
                        borderRight:
                          selectedStudentIDs.includes(item.UserId) &&
                          'solid 1px rgba(255, 162, 0, 0.33)'
                      }}
                    >
                      <button
                        className="btn btn-outline-primary btn-details"
                        type="button"
                        onClick={() => {
                          setSelectedStudentID(item.UserId);
                          setShowStudentDetailsModal(true);
                        }}
                      >
                        {t(`manage-class:searchResult.details`, 'Details')}
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      )}
    </StudentTableContainer>
  );
}
