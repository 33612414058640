import {
  SAVE_PRIVATE_GROUP,
  DELETE_PRIVATE_GROUP,
  FETCH_PRIVATE_GROUPS_FAILED,
  FETCH_PRIVATE_GROUPS_REQUEST,
  FETCH_PRIVATE_GROUPS_SUCCESS,
  FETCH_PRIVATE_GROUP_SUCCESS,
  FETCH_PRIVATE_GROUP_FAILED,
  FETCH_PRIVATE_GROUP_REQUEST,
  SELECT_STUDENTS
} from './actionTypes';

const initialState = {
  error: '',
  isLoading: false,
  privateGroups: [],
  selectedPrivateGroup: {},
  selectedStudents: []
};

export default function worksheet(state = initialState, action) {
  switch (action.type) {
    case SAVE_PRIVATE_GROUP:
    case DELETE_PRIVATE_GROUP:
    case FETCH_PRIVATE_GROUPS_REQUEST:
    case FETCH_PRIVATE_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_PRIVATE_GROUPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        privateGroups: action.privateGroups
      };
    case FETCH_PRIVATE_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedPrivateGroup: action.privateGroup
      };
    case FETCH_PRIVATE_GROUP_FAILED:
    case FETCH_PRIVATE_GROUPS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case SELECT_STUDENTS:
      return {
        ...state,
        isLoading: false,
        selectedStudents: action.students
      };
    default:
      return state;
  }
}
