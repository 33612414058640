import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import arrowImage from 'assets/img/arrow.svg';
import history from 'helpers/history';
import isNilOrEmpty from 'helpers/isNilOrEmpty';
import PopupModal from 'components/Shared/PopupModal';
import TrialAccountModalBody from 'components/TrialAccount/TrialAccountModalBody';
import { setShowTrialPopup } from 'stores/login/actions';
import { useTranslation } from 'react-i18next';
import navs from './navs.js';
import NavbarNav from './NavbarNav';
import NavItem from './NavItem';

const NavbarCollapse = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['navigation']);
  const { cartQuestions } = useSelector(state => state.questions);
  const { isAdmin, isTrial, isTrialExpired, showTrialPopup } = useSelector(
    state => state.login
  );
  const showArrow =
    history.location.pathname === '/create-and-assign' &&
    isNilOrEmpty(cartQuestions); // @todo remove "!" when the design ready

  // modal state
  const hideTrialAccount = () => {
    dispatch(setShowTrialPopup(false));
  };

  const trialAccount = isTrial && (
    <PopupModal show={showTrialPopup} hide={hideTrialAccount}>
      <TrialAccountModalBody
        hide={hideTrialAccount}
        type={isTrialExpired ? 'trial-expired' : 'trial-account'}
      />
    </PopupModal>
  );

  return (
    <>
      {trialAccount}
      <ul className="navbar-nav mx-lg-auto navbar-width">
        {navs.map((nav, index) => {
          if (nav.forAdmin && !isAdmin) {
            return false;
          }
          return (
            <NavItem
              key={String(index)}
              to={nav.to}
              externalLink={nav.externalLink}
            >
              <i className={nav.icon} />
              {nav.to === '/browse-content' && showArrow && (
                <div className="navigation-arrow">
                  <img alt="nav-arrow" src={arrowImage} />
                </div>
              )}

              <span>{t(`navigation:navbar.${nav.nameKey}`, nav.name)}</span>
            </NavItem>
          );
        })}
      </ul>
      <NavbarNav />
    </>
  );
};

export default NavbarCollapse;
