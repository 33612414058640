import styled from 'styled-components';

const GameSettingContainer = styled.div`
  background-color: white;
  width: calc(100vw - 280px);
  margin-left: 245px;
  margin-right: 35px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  @media (max-width: 1024px) {
    width: calc(100vw - 106px);
    margin-left: 81px;
    margin-right: 25px;
  }
  span {
    font-size: 16px;
  }

  .navigation-back {
    color: #727272;
    text-decoration: none;
    @media only screen and (max-width: 768px) {
      font-size: 10px;
    }
    &:hover {
      color: #ff5b00;
    }
  }
`;

export default GameSettingContainer;
