import apiClient from 'services/api';

export default (CurriculumID, LevelID) => {
  return apiClient
    .get(
      `Curriculum/GetSyllabusByCurriculumIDandLevelID/${CurriculumID}/${LevelID}`
    )
    .then(result => {
      return result.data?.Result ?? [];
    })
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch syllabus failed' // eslint-disable-line
      };
    });
};
