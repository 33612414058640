import styled from 'styled-components';

export const LoginReportTableStyle = styled.div`
  margin: 0 30px 30px;
  .empty-result {
    display: flex;
    justify-content: center;
    margin-top 100px;
  }
  .table {
    vertical-align: middle;
    text-align: center;
    border: 1px solid #e9e9e9;
    thead {
      position:sticky;
      top: 215px;
      @media (max-width: 882px) {
        top: 200px;
      }
      @media (max-width: 768px) {
        top: 215px;
      }
      @media (max-width: 767px) {
        top: 0;
      }
    }
    .date {
      width: 100px;
      text-transform: uppercase;
      color: #ff5b00;
      font-weight: normal;
      padding: 0.5rem;
    }
    .student,
    .total {
      color: #000000;
      font-weight: normal;
      padding: 0.5rem;

    }
    .student {
      text-align: left;
      padding-left: 30px;
      @media (max-width: 1024px) {
        width: 500px;
      }
    }
    .total {
      width: 100px;
    }
    thead {
      background-color: #fff2cf;
      font-size: 16px;
      th {
        vertical-align: middle;
      }
    }
    tbody {
      tr:nth-child(odd) {
        background-color: #ffffff;
      }
      tr:nth-child(even) {
        background-color: #f8f8f8;
      }
      td {
        vertical-align: middle;
        text-align: center;
      }
    }
    .student-name {
      display: flex;
      align-items: center;
      border-top: none;
      padding-left: 30px;
      text-align: left;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      span {
        font-weight: bold;
        font-size: 14px;
        margin-left: 15px;
        color: #363636;
      }
    }
    .total-number {
      color: #545454;
      font-weight: bold;
    }
  }
`;
