import React, { forwardRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Avatar from 'assets/img/avatar.png';
// import { ReactComponent as TickIcon } from 'assets/img/locale/icon-black-tick.svg';
import Cookies from 'js-cookie';
import { logout } from 'stores/login/actions';
import { featureToggle } from 'constants/index';
import { isNil } from 'ramda';
import getStudentToken from 'services/switchStudentAccount';
import PopupModal from 'components/Shared/PopupModal';
import LanguageModal from './LanguageModal';

const ProfileAvatar = ({ onClick }, ref) => {
  const { profileData } = useSelector(state => state.profileSettings);
  return (
    <li className="nav-item avatar">
      <Link
        to="/"
        className="nav-link"
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
      >
        <img
          src={
            profileData && profileData.ProfileImage
              ? profileData.ProfileImage
              : Avatar
          }
          alt="profile"
          onError={e => {
            e.target.src = Avatar;
          }}
        />
      </Link>
    </li>
  );
};

const NavbarNav = () => {
  const { t } = useTranslation(['navigation']);
  const history = useHistory();
  const dispatch = useDispatch();
  const { cartQuestions } = useSelector(state => state.questions);
  const handleLogout = () => dispatch(logout());
  const [showLangModal, setShowLangModal] = useState(false);
  const hideLangModal = () => {
    setShowLangModal(false);
  };

  const switchToStudentHandler = async () => {
    const res = await getStudentToken();
    // Check if api request succeeded
    if (!isNil(res.error)) {
      console.log(res.error);
      return;
    }
    Cookies.set('sp_access_token', res.AccessToken, {
      path: '/',
      domain: 'koobits.com'
    });
    Cookies.set('sp_refresh_token', res.RefreshToken, {
      path: '/',
      domain: 'koobits.com'
    });
    // add callback for flutter app switch account
    const hasFlutter = window.flutter_inappwebview;
    if (hasFlutter) hasFlutter.callHandler('switchAccountFunc');
    window.location.replace(process.env.REACT_APP_STUDENT_PORTAL_URL);
  };

  const toggleLanguage = () => {
    setShowLangModal(true);
  };

  return (
    <>
      <PopupModal show={showLangModal} hide={hideLangModal} width="177px">
        <LanguageModal setModal={setShowLangModal} />
      </PopupModal>
      <ul className="navbar-nav">
        {featureToggle.notificationBell && (
          <li className="nav-item px-0">
            <Link className="nav-link nav-notif px-2" to="/">
              <i className="icon-bell" />
              {/* Notification Count */}
              <span className="number">3</span>
            </Link>
          </li>
        )}
        <li className="nav-item px-0">
          <Link className="nav-link px-2" to="/create-and-assign">
            <i
              className={
                cartQuestions?.length > 0 ? 'icon-cart-filled' : 'icon-cart'
              }
            />
            {cartQuestions?.length > 0 && (
              <span className="number">{cartQuestions.length}</span>
            )}
          </Link>
        </li>
        <Dropdown className="avatar-dropdown">
          <Dropdown.Toggle
            as={forwardRef(ProfileAvatar)}
            id="dropdown-custom-components"
          />
          <Dropdown.Menu>
            <div className="dropdown-menu-arrow profile-arrow" />
            <Dropdown.Item eventKey="1" onClick={switchToStudentHandler}>
              <i className="icon-switch" />
              {t(
                `navigation:menu.switchToStudentAccount`,
                'Switch to student account'
              )}
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              eventKey="2"
              onClick={() => history.push('/profile-setting')}
            >
              <i className="icon-profile" />
              {t(`navigation:menu.profileSettings`, 'Profile and Settings')}
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="3" onClick={toggleLanguage}>
              <i className="icon-global" />
              {t(`navigation:menu.changeLanguage`, 'Change Language')}
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="4" onClick={handleLogout}>
              <i className="icon-logout" />
              {t(`navigation:menu.logOut`, 'Log out')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ul>
    </>
  );
};

export default NavbarNav;
