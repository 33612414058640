import React, { useState } from 'react';
import MenuSvgIcon from 'components/TrackAssignment/MenuSvgIcon';

export default function PopoverMenuItem({ icon, children }) {
  const [isActive, setIsActive] = useState(false);
  return (
    <div
      className="actions-item"
      onMouseEnter={() => {
        setIsActive(true);
      }}
      onMouseLeave={() => {
        setIsActive(false);
      }}
      style={{ marginTop: '1rem' }}
    >
      <MenuSvgIcon icon={icon} fill={isActive ? '#FF5B00' : '#747474'} />
      {children}
    </div>
  );
}
