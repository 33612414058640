import styled from 'styled-components';

export const ChangeClassModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #060606;
  font-size: 0.875rem;
  .success-title {
    font-size: 1.125rem;
    color: #4dae30;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .btn-success-ok {
    width: 127px;
    color: #fffdfc;
    background: #5ed46a;
    margin-bottom: 1rem;
  }
  .change-class-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
    span {
      margin-right: 1rem;
    }
    .dropdown-menu {
      min-width: 200px;
    }
  }
  .selection-action-buttons {
    display: flex;
    flex-direction: row;
    border-top: solid 1px rgba(112, 112, 112, 0.22);
    justify-content: space-evenly;
    padding-top: 1rem;
    width: 100%;
    .btn-outline-cancel {
      color: #ff5b00;
      border: solid 1px #d5d5d5;
      width: 127px;
      &:hover {
        border-color: #ff5b00;
        background: #fff7f3;
      }
    }
    .btn-ok {
      width: 127px;
      color: #fffdfc;
      background: #ff5b00;
    }
  }
  #dropdown-change-class {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: solid 1px #707070;
    width: 200px;
    font-size: 0.875rem;
  }
`;
