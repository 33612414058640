import {
  FETCH_CLASS_REQUEST,
  FETCH_CLASS_SUCCESS,
  FETCH_CLASS_FAILED,
  FETCH_STUDENTS_REQUEST,
  FETCH_STUDENTS_SEARCH_REQUEST,
  FETCH_STUDENTS_SUCCESS,
  FETCH_STUDENTS_FAILED,
  FETCH_MY_GROUP_FAILED,
  FETCH_MY_GROUP_REQUEST,
  FETCH_MY_GROUP_SUCCESS
} from './actionTypes';

export const fetchClassRequest = LevelId => {
  return { type: FETCH_CLASS_REQUEST, LevelId };
};

export const fetchClassSuccess = classes => {
  return { type: FETCH_CLASS_SUCCESS, classes };
};

export const fetchClassFailed = error => {
  return { type: FETCH_CLASS_FAILED, error };
};

export const fetchStudentsRequest = ClassId => {
  return { type: FETCH_STUDENTS_REQUEST, ClassId };
};

export const fetchStudentsSearchRequest = (keyword, page, limit) => {
  return { type: FETCH_STUDENTS_SEARCH_REQUEST, keyword, page, limit };
};

export const fetchStudentsSuccess = students => {
  return { type: FETCH_STUDENTS_SUCCESS, students };
};

export const fetchStudentsFailed = error => {
  return { type: FETCH_STUDENTS_FAILED, error };
};

export const fetchMyGroupsRequest = () => {
  return { type: FETCH_MY_GROUP_REQUEST };
};

export const fetchMyGroupsSuccess = groups => {
  return { type: FETCH_MY_GROUP_SUCCESS, groups };
};

export const fetchMyGroupsFailed = error => {
  return { type: FETCH_MY_GROUP_FAILED, error };
};
