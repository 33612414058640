import React from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  assignClass,
  assignGroup,
  resetAssignment
} from 'stores/worksheet/assign/actions';
import { findIndex, find, propEq } from 'ramda';
import { classType } from 'constants/index';

export default function ClassGroupCheckbox(props) {
  const { type } = props;
  const dispatch = useDispatch();
  const { assignedClass, assignedGroup } = useSelector(state => state.assign);

  const { classes } = useSelector(state => state.lookup);

  const items = classes.filter(classData => {
    return classData.Type === classType[type];
  });

  const assignedData = type === 'class' ? assignedClass : assignedGroup;
  const assignData = type === 'class' ? assignClass : assignGroup;

  const checkChecked = id => {
    return findIndex(propEq('ID', parseInt(id, 10)))(assignedData) !== -1;
  };

  const onChange = event => {
    const { value } = event.target;
    // Use new variable to avoid mutating state
    let updatedData;

    if (!checkChecked(value)) {
      const newData = find(propEq('ID', parseInt(value, 10)))(classes);

      updatedData = [...assignedData, newData];
    } else {
      const index = findIndex(propEq('ID', parseInt(value, 10)))(assignedData);
      updatedData = [
        ...assignedData.slice(0, index),
        ...assignedData.slice(index + 1)
      ];
    }

    dispatch(assignData(updatedData));
    dispatch(resetAssignment('admin'));
  };

  return items.length !== 0 ? (
    <>
      {items.map(item => {
        return (
          <Form.Group
            key={`${type}-${item.ID}`}
            className="row schedule-checkbox"
            controlId={`${type}-${item.ID}`}
          >
            <Form.Check
              className="checkbox-class"
              checked={checkChecked(item.ID)}
              onChange={onChange}
              type="checkbox"
              label={item.Name}
              value={item.ID}
            />
          </Form.Group>
        );
      })}
    </>
  ) : (
    <p>No classes found</p>
  );
}
