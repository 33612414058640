import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonHeader,
  RightNav,
  TopBarFixed
} from 'components/CreateAndAssign/FactualFluency/Preview/FactualFluencyHeader.style';
import { useSelector } from 'react-redux';
import questionParser from 'helpers/questionParser';
import HTMLReactParser from 'html-react-parser';
import convertTagsToHTML from 'helpers/text/convertTagsToHTML';
import isNilOrEmpty from 'helpers/isNilOrEmpty';
import {
  Container,
  NavTitle,
  QuestionWrap,
  ResultBody
} from './FactualFluencyResult.styles';

const renderQuestionAnswer = (question, number) => {
  const { solution } = questionParser(question);
  const renderQuestion = HTMLReactParser(convertTagsToHTML(solution, false), {
    replace: domNode => {
      if (domNode.name === 'p') {
        let questionText = '';
        let answerText = '';
        const text = domNode.children[0]?.data ?? '';
        if (text) {
          const split = text.split('=');
          questionText = !isNilOrEmpty(split[0]) ? split[0].trim() : '';
          answerText = !isNilOrEmpty(split[1]) ? split[1].trim() : '';
        }
        return (
          <QuestionWrap>
            <div className="question-number">{`Q${number}:`}</div>
            <div className="question-wrap">
              <span className="question-text">{questionText}</span>
            </div>
            <div className="question-wrap">
              <span className="question-text">=</span>
            </div>
            <div className="question-wrap">
              <span className="question-text answer">{answerText}</span>
            </div>
          </QuestionWrap>
        );
      }
      return domNode;
    }
  }).filter(el => el.type !== 'br');

  return renderQuestion;
};

const FactualFluencyResult = () => {
  const { createFactualFluencyItems } = useSelector(
    state => state.factualFluency
  );
  const { t } = useTranslation(['create-assign-factual-fluency']);

  const onClose = useCallback(() => {
    window.open('', '_self');
    window.close();
  }, []);
  return (
    <Container>
      <TopBarFixed>
        <NavTitle>
          {t('create-assign-factual-fluency:answers', 'Answers')}:{' '}
          <span>Q1 - Q{createFactualFluencyItems.length}</span>
        </NavTitle>
        <RightNav className="wrap-button-right">
          <ButtonHeader className="btn single-btn" onClick={onClose}>
            <i className="icon-koo-close2" />
          </ButtonHeader>
        </RightNav>
      </TopBarFixed>
      <ResultBody>
        {createFactualFluencyItems.map((question, key) => {
          return renderQuestionAnswer(question.CartQuestion, key + 1);
        })}
      </ResultBody>
    </Container>
  );
};

export default React.memo(FactualFluencyResult);
