import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import MonthlyReport from 'components/Report/MonthlyReport';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTeacherReportMonthRequest } from 'stores/report/actions';
import Spinner from 'components/Shared/Spinner';
import moment from 'moment';
import { MonthlyActivity } from './TeacherMonthlyReport.styles';

const years = () => {
  const dataYears = [];
  const dateStart = moment().subtract(1, 'y');
  const dateEnd = moment();
  while (dateEnd.diff(dateStart, 'years') >= 0) {
    dataYears.push(dateStart.format('YYYY'));
    dateStart.add(1, 'year');
  }
  return dataYears.reverse();
};

const TeacherMonthlyReport = () => {
  const { t } = useTranslation(['monthly-report']);
  const [yearSelected, setYearSelected] = useState(new Date().getFullYear());
  const dispatch = useDispatch();

  const { isLoading, teacherMonthReport } = useSelector(state => state.reports);

  useEffect(() => {
    dispatch(fetchTeacherReportMonthRequest(yearSelected));
  }, [dispatch, yearSelected]);

  return (
    <MonthlyActivity>
      <div className="top-bar">
        <p className="title">
          {t(`monthly-report:header.monthlyReport`, 'Monthly Report')}
        </p>
        <div className="dropdown-section">
          <p className="dropdown-label">
            {t(`monthly-report:header.year`, 'Year')}
          </p>
          <Dropdown>
            <Dropdown.Toggle variant="light" className="dropdown-custom">
              {yearSelected}
            </Dropdown.Toggle>
            <Dropdown.Menu flip={false}>
              {years().map(e => (
                <Dropdown.Item key={e} onClick={() => setYearSelected(e)}>
                  {e}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {isLoading && <Spinner />}
      <MonthlyReport data={teacherMonthReport} />
    </MonthlyActivity>
  );
};

export default TeacherMonthlyReport;
