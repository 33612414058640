import { sort } from 'ramda';
import {
  SET_START_END_DATE,
  SET_RELEASE_DATE,
  SET_KOKO_CREDIT,
  ASSIGN_CLASS,
  ASSIGN_GROUP,
  ASSIGN_STUDENT,
  ASSIGN_PRIVATE_GROUP,
  ASSIGN_ADMIN_GROUP,
  ASSIGN_LEVEL,
  RESET_ASSIGN,
  RESET_ASSIGNMENT
} from './actionTypes';

const initialState = {
  error: '',
  isLoading: false,
  startDate: '',
  endDate: '',
  releaseDate: '',
  kokoCredit: '',
  assignedClass: [],
  assignedGroup: [],
  assignedStudent: [],
  assignedPrivateGroup: [],
  assignedAdminGroup: {},
  assignedLevel: ''
};

export default function worksheet(state = initialState, action) {
  switch (action.type) {
    case SET_START_END_DATE:
      return {
        ...state,
        isLoading: false,
        startDate: action.startDate,
        endDate: action.endDate
      };
    case SET_RELEASE_DATE:
      return {
        ...state,
        isLoading: false,
        releaseDate: action.releaseDate
      };
    case SET_KOKO_CREDIT:
      return {
        ...state,
        isLoading: false,
        kokoCredit: action.kokoCredit
      };
    case ASSIGN_CLASS:
      return {
        ...state,
        isLoading: false,
        assignedClass: action.classes
      };
    case ASSIGN_GROUP:
      return {
        ...state,
        isLoading: false,
        assignedGroup: action.groups
      };
    case ASSIGN_STUDENT:
      return {
        ...state,
        isLoading: false,
        assignedStudent: sort((a, b) => a.ClassID - b.ClassID, action.students)
      };
    case ASSIGN_PRIVATE_GROUP:
      return {
        ...state,
        isLoading: false,
        assignedPrivateGroup: action.privateGroups
      };
    case ASSIGN_ADMIN_GROUP:
      return {
        ...state,
        isLoading: false,
        assignedAdminGroup: action.adminGroups
      };
    case ASSIGN_LEVEL:
      return {
        ...state,
        isLoading: false,
        assignedLevel: action.level
      };
    case RESET_ASSIGN:
      return initialState;
    case RESET_ASSIGNMENT:
      return action.assignmentType === 'admin'
        ? {
            ...state,
            isLoading: false,
            assignedAdminGroup: {},
            assignedLevel: ''
          }
        : {
            ...state,
            isLoading: false,
            assignedClass: [],
            assignedGroup: [],
            assignedStudent: [],
            assignedPrivateGroup: []
          };
    default:
      return state;
  }
}
