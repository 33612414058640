import React, { useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './DateTimePicker.styles.scss';

export default function DateTimePicker(props) {
  const { selectedDate, applyCallback, minDateVal } = props;
  const [minDate] = useState(minDateVal || new Date());

  const config = {
    sundayFirst: true
  };

  const value = selectedDate.format('DD MMM YYYY, h:mm A');

  return (
    <DateRangePicker
      showDropdowns
      singleDatePicker
      startDate={selectedDate}
      local={config}
      onApply={applyCallback}
      minDate={minDate}
      onHideCalendar={applyCallback}
      timePicker
      onEvent={applyCallback}
    >
      <a href="#date-time" onClick={event => event.preventDefault()}>
        {value}
      </a>
    </DateRangePicker>
  );
}
