import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchIconsRequest } from 'stores/manage-class/actions';
import {
  EditClassIconSelectorContainer,
  PaginationContainer
} from './EditClassIconSelector.styles';

export default function EditClassIconSelector({
  level,
  selectedIconID,
  setSelectedIconID
}) {
  const dispatch = useDispatch();
  const { icons, iconsLevel } = useSelector(state => state.manageClasses);
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(icons.length / 10);

  useEffect(() => {
    if (iconsLevel !== level) {
      dispatch(
        fetchIconsRequest({
          level
        })
      );
    }
  }, [dispatch, iconsLevel, level]);
  return (
    <EditClassIconSelectorContainer>
      <div className="edit-classicon-selector">
        {icons
          .slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10)
          .map(icon => (
            <div
              key={`icon-${icon.Id}`}
              className={`class-icon-container ${icon.Id === selectedIconID &&
                'selected'}`}
              onClick={() => {
                setSelectedIconID(icon.Id);
              }}
            >
              <span className="class-icon">
                <img
                  src={icon.IconUrl}
                  width="51.26px"
                  height="51.26px"
                  alt="Class Icon"
                />
              </span>
            </div>
          ))}
      </div>
      <PaginationContainer>
        {currentPage > 1 && (
          <button
            type="button"
            onClick={() => {
              setCurrentPage(currentPage - 1);
            }}
          >
            {'<'}
          </button>
        )}
        {currentPage < maxPage && (
          <button
            type="button"
            onClick={() => {
              setCurrentPage(currentPage + 1);
            }}
          >
            {'>'}
          </button>
        )}
      </PaginationContainer>
    </EditClassIconSelectorContainer>
  );
}
