import React from 'react';
import wrongAnsIcon from 'assets/img/icon-track-assignment-wrong-ans.svg';
import { QuestionResultContainer } from './QuestionResultIcon.styles';

export default function QuestionResultIcon({ result }) {
  return (
    <QuestionResultContainer>
      {result === 'no-attempt' ? (
        <div className="no-attempt" />
      ) : result ? (
        <div className="correct-answer" />
      ) : (
        <img src={wrongAnsIcon} alt="Wrong Answer" className="wrong-answer" />
      )}
    </QuestionResultContainer>
  );
}
