import {
  SAVE_PRIVATE_GROUP,
  DELETE_PRIVATE_GROUP,
  FETCH_PRIVATE_GROUPS_FAILED,
  FETCH_PRIVATE_GROUPS_REQUEST,
  FETCH_PRIVATE_GROUPS_SUCCESS,
  FETCH_PRIVATE_GROUP_SUCCESS,
  FETCH_PRIVATE_GROUP_FAILED,
  FETCH_PRIVATE_GROUP_REQUEST,
  SELECT_STUDENTS
} from './actionTypes';

export const savePrivateGroup = name => {
  return { type: SAVE_PRIVATE_GROUP, name };
};

export const detelePrivateGroup = privateGroupId => {
  return { type: DELETE_PRIVATE_GROUP, privateGroupId };
};

export const fetchPrivateGroupsRequest = groupName => {
  return { type: FETCH_PRIVATE_GROUPS_REQUEST, groupName };
};

export const fetchPrivateGroupsSuccess = privateGroups => {
  return { type: FETCH_PRIVATE_GROUPS_SUCCESS, privateGroups };
};

export const fetchPrivateGroupsFailed = error => {
  return { type: FETCH_PRIVATE_GROUPS_FAILED, error };
};

export const fetchPrivateGroupRequest = privateGroupId => {
  return { type: FETCH_PRIVATE_GROUP_REQUEST, privateGroupId };
};

export const fetchPrivateGroupSuccess = privateGroup => {
  return { type: FETCH_PRIVATE_GROUP_SUCCESS, privateGroup };
};

export const fetchPrivateGroupFailed = error => {
  return { type: FETCH_PRIVATE_GROUP_FAILED, error };
};

export const selectStudents = students => {
  return { type: SELECT_STUDENTS, students };
};
