import React, { createRef } from 'react';
import Subbar from 'components/Content/Subbar';
import ProblemsumsTrial from 'pages/BrowseContent/TrialAccount/ProblemSumsTrial';
import QuestionList from 'pages/BrowseContent/QuestionList';
import { Route } from 'react-router-dom';

const Content = () => {
  const subbarRef = createRef();

  return (
    <>
      <Subbar ref={subbarRef} />
      <Route
        exact
        path="/browse-content/problemsums-trial"
        component={ProblemsumsTrial}
      />
      <Route
        exact
        path="/browse-content/question-list"
        component={QuestionList}
      />
    </>
  );
};

export default Content;
