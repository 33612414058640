import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchClassesRequest,
  resetEditClassResult,
  resetError
} from 'stores/manage-class/actions';
import moment from 'moment';
import PopupModal from 'components/ManageClass/PopupModal';
import EditClassModalBody from 'components/ManageClass/EditClassModalBody';
import SearchResults from 'components/ManageClass/SearchResults';
import ClassCardCollection from 'components/ManageClass/ClassCardCollection';
import DropdownMenu from 'components/Shared/DropdownMenu';
import SearchIcon from 'assets/img/icon-manage-class-lup.svg';
import { DisplayClassPage } from './DisplayClass.styles';

// Helper
function* range(start, end, step) {
  while (start >= end) {
    yield start;
    start += step;
  }
}

const generateYears = () => {
  return Array.from(range(moment().year(), 2020, -1));
};

const LevelMenuOption = value => `P${value}`;

const DisplayClass = () => {
  const { t } = useTranslation(['manage-class']);
  const searchTypes = [
    t(`manage-class:search.id`, 'ID'),
    t(`manage-class:search.name`, 'Name')
  ];
  const dispatch = useDispatch();
  const { levels } = useSelector(state => state.levels);
  const { classes, editClassResult } = useSelector(
    state => state.manageClasses
  );
  // Modal State
  const [showEditClassModal, setEditClassModal] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  // Search State
  const [searchTerm, setSearchTerm] = useState('');
  // Filter State
  const [filterLevel, setFilterLevel] = useState('');
  const [filterYear, setFilterYear] = useState(moment().year());
  const [filterType, setFilterType] = useState(
    t(`manage-class:search.name`, 'Name')
  );
  // Years Dropdown Options
  const years = generateYears();
  // Event handlers for popup modal
  const openEditClassModal = () => {
    dispatch(resetError());
    setEditClassModal(true);
  };
  const hideEditClassModal = () => {
    if (editClassResult === true) {
      dispatch(
        fetchClassesRequest({
          year: filterYear
        })
      );
      dispatch(resetEditClassResult());
    }
    setEditClassModal(false);
  };
  useEffect(() => {
    // for translating language in state
    if (filterType === t(`manage-class:search.id`)) {
      setFilterType(t(`manage-class:search.id`));
    } else {
      setFilterType(t(`manage-class:search.name`));
    }
  }, [filterType, searchTypes, t]);
  // useEffect to fetch classes data via redux saga
  useEffect(() => {
    dispatch(
      fetchClassesRequest({
        year: filterYear
      })
    );
  }, [dispatch, filterYear]);

  const schoolInfo = classes
    ? {
        logo: classes.SchoolLogo,
        name: classes.Name
      }
    : null;

  return (
    <DisplayClassPage searchIcon={SearchIcon}>
      {classes && classes.length === 0 && (
        <div className="error">
          <h3>No classes found</h3>
        </div>
      )}
      {classes && (
        <>
          <PopupModal
            show={showEditClassModal}
            hide={hideEditClassModal}
            size="sm"
          >
            <EditClassModalBody
              hide={hideEditClassModal}
              selectedClass={selectedClass}
              selectedYear={classes.Year}
            />
          </PopupModal>
          <div className="top-bar">
            <div className="calendar-year">
              <p>{t(`manage-class:header.year`, 'Year: ')}</p>
              <DropdownMenu
                selectedValue={filterYear}
                valueKey={null}
                values={years}
                setValue={setFilterYear}
                width="160px"
              />
            </div>
            <div className="school">
              {classes.hasOwnProperty('ClassList') && (
                <img
                  onError={e => {
                    e.target.style.display = 'none';
                  }}
                  src={schoolInfo.logo}
                  alt="school-logo"
                />
              )}
              <p>{schoolInfo.name}</p>
            </div>
            <div className="level">
              <p>{t(`manage-class:header.level`, 'Level:')}</p>
              <DropdownMenu
                selectedValue={
                  filterLevel !== ''
                    ? `Primary ${filterLevel}`
                    : t(`manage-class:header.all`, 'All')
                }
                valueKey={null}
                values={levels.map(level => level.ID)}
                setValue={setFilterLevel}
                valueTemplate={LevelMenuOption}
                width="160px"
              >
                <Dropdown.Item
                  onClick={event => {
                    event.preventDefault();
                    setFilterLevel('');
                  }}
                >
                  {t(`manage-class:header.all`, 'All')}
                </Dropdown.Item>
              </DropdownMenu>
            </div>
          </div>
          <div className="search-bar">
            <div className="search-type-dropdown">
              <DropdownMenu
                selectedValue={filterType}
                valueKey={null}
                values={searchTypes}
                setValue={setFilterType}
                width="160px"
              />
            </div>
            <Form className="search col-sm-12">
              <input
                type="search"
                id="search"
                className="form-control"
                placeholder={t(`manage-class:search.search`, 'Search')}
                value={searchTerm}
                onChange={e => {
                  setSearchTerm(e.target.value);
                }}
              />
            </Form>
          </div>
          <div className="text-information">
            <p>
              {t(`manage-class:search.resetMsg`, 'You can click to ')}
              <span>
                {t(`manage-class:search.resetMsg1`, `reset student's password`)}
              </span>
              {t(`manage-class:search.resetMsg2`, `and edit other settings.`)}
            </p>
          </div>
          {searchTerm.length >= 3 ? (
            <SearchResults searchTerm={searchTerm} searchType={filterType} />
          ) : (
            <div className="class-card">
              <ClassCardCollection
                showModal={openEditClassModal}
                setSelectedClass={setSelectedClass}
                filterLevel={filterLevel}
              />
            </div>
          )}
        </>
      )}
    </DisplayClassPage>
  );
};
export default DisplayClass;
