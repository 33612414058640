import styled from 'styled-components';

export const MediaBody = styled.div`
  margin-right: 50px;
  p {
    line-height: 1.4285;
    font-size: 18px;
    color: #000;
    font-family: Arial, sans-serif;
    margin-bottom: -6px;
  }

  .question {
    margin-bottom: 10px;
  }
`;

export const McqOptionsList = styled.ul`
  list-style-type: circle;
  li {
    line-height: 1.4285;
    font-size: 18px;
    color: #000;
    font-family: Arial, sans-serif;
  }
`;

export const TableModal = styled.div`
  line-height: 1.4285;
  font-size: 18px;
  color: #000;
  font-family: Arial, sans-serif;
  table {
    width: 100%;
    margin-left: initial !important;
    margin: auto;
  }
  table tbody tr td {
    text-align: center;
  }
  table tbody tr td hr {
    margin-top: 0;
    margin-bottom: 3px;
    border-color: darkgray;
  }
  table tbody tr td input {
    max-width: 25px !important;
    height: 35px;
    text-align: center;
  }
  input {
    border-color: transparent;
    background-color: rgba(144, 144, 144, 0.2);
  }
  @media print {
    td {
      font-size: 18px;
      color: #000;
      font-family: Arial, sans-serif;
    }
  }
`;
