import styled from 'styled-components';

export const EditClassModalBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #060606;
  font-size: 0.875rem;
  .field-title {
    font-size: 0.875rem;
    color: rgba(26, 26, 26, 0.45);
    font-weight: 600;
    margin-right: 0.5rem;
    width: 100px;
    text-align: right;
  }
  .edit-class-field {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    input {
      width: 360px;
      height: 38px;
      padding: 0 0.5rem;
      border: solid 1px rgba(112, 112, 112, 0.36);
    }
  }
  .selected-class-icon {
    width: 82px;
    height: 82px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
  }
  .selection-action-buttons {
    display: flex;
    flex-direction: row;
    border-top: solid 1px rgba(112, 112, 112, 0.22);
    justify-content: space-evenly;
    padding-top: 1rem;
    width: 100%;
    .btn-outline-cancel {
      color: #ff5b00;
      border: solid 1px #d5d5d5;
      width: 127px;
      &:hover {
        border-color: #ff5b00;
        background: #fff7f3;
      }
    }
    .btn-ok {
      width: 127px;
      color: #fffdfc;
      background: #ff5b00;
    }
  }
`;
