import styled from 'styled-components';

export const EditSavedWorksheetContainer = styled.div`
  margin-top: 55px;
  padding-left: 195px;
  @media (max-width: 1024px) {
    padding-left: 56px;
    .question-cart-content {
      margin-left: 0;
      border-bottom: 1px solid rgba(#707070, 0.21);
    }
    .check-add-score {
      left: 100px;
      ~ .custom-checkbox {
        left: 100px;
        @media (max-width: 991px) {
          left: 120px;
        }
        @media (max-width: 639px) {
          left: 40px;
        }
      }
      ~ label {
        left: 130px;
        @media (max-width: 991px) {
          left: 150px;
        }
        @media (max-width: 639px) {
          left: 70px;
        }
      }
    }
    .box-white-wrap {
      .question-card.create-assign {
        padding-left: 10px;
      }
    }
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1065px;
  background-color: #fff;
  padding: 15px 1px 0 1px;
  margin: 0 auto 10px;
  font-family: 'Muli', sans-serif;
  border-radius: 0;
  box-shadow: none;
  color: rgba(52, 52, 52, 0.8);
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
