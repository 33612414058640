import { isEmpty, isNil } from 'ramda';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGIN_RESET,
  SET_SHOW_TRIAL_POPUP,
  SET_USER_DATA
} from './actionTypes';

const initialState = {
  user: {},
  isLoading: false,
  isAdmin: false,
  isSchoolAdmin: false,
  isTrial: false,
  isTrialExpired: false,
  showTrialPopup: true,
  error: '',
  redirectString: '',
  language: null,
  localePreferences: null
};

// Helper
const findTrialRoleID = roles => {
  const TrialRoleIndex = roles.findIndex(role => role.ID === 94);
  if (TrialRoleIndex !== -1) {
    return true;
  }
  return false;
};

const checkIfSchoolAdmin = roleID => {
  switch (roleID) {
    // Admin
    case 4:
      return true;
    // PS_Admin
    case 3:
      return true;
    // SuperAdmin
    case 5:
      return true;
    // SupportAdmin
    case 41:
      return true;
    case 96:
      return true;
    default:
      return false;
  }
};

const findSchoolAdminRoleID = roles => {
  const schoolAdminRoleIndex = roles.findIndex(role =>
    checkIfSchoolAdmin(role.ID)
  );
  if (schoolAdminRoleIndex !== -1) {
    return true;
  }
  return false;
};

const findTeacherRoleID = roles => {
  const schoolAdminRoleIndex = roles.findIndex(role => role.ID === 2);
  if (schoolAdminRoleIndex !== -1) {
    return true;
  }
  return false;
};

export default function login(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, error: '', isLoading: true };
    case LOGIN_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const parsePreferences = !isNil(action.userPreferences)
        ? JSON.parse(action.userPreferences)
        : [];
      // eslint-disable-next-line no-case-declarations
      const getLocalePref = !isEmpty(parsePreferences)
        ? parsePreferences.find(
            item => item.Name.toLowerCase().trim() === 'currentlocale'
          )
        : null;
      return {
        ...state,
        isLoading: false,
        user: action.user,
        isAdmin: action.isAdmin,
        isTrial: findTrialRoleID(action.user.Role),
        isSchoolAdmin: findSchoolAdminRoleID(action.user.Role),
        isTeacher: findTeacherRoleID(action.user.Role),
        isTrialExpired: action.isTrialExpired,
        redirectString: action.mySecret,
        language: !isNil(action.myLocale) ? JSON.parse(action.myLocale) : [],
        localePreferences: getLocalePref
      };
    case SET_USER_DATA:
      return { ...state, user: action.user };
    case LOGIN_FAILED:
      return { ...state, isLoading: false, error: action.error };
    case LOGIN_RESET:
      return initialState;
    case SET_SHOW_TRIAL_POPUP:
      return {
        ...state,
        showTrialPopup: action.show
      };
    default:
      return state;
  }
}
