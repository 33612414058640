import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  assignAdminGroup,
  assignLevel,
  resetAssignment
} from 'stores/worksheet/actions';
import './AssignAdmin.styles.scss';

const adminGroupsHash = {
  premium: 'All Premium users',
  retail: 'All Retail users',
  koobits: 'All KooBits users'
};

export default function AssignAdmin() {
  const { t } = useTranslation(['create-assign-question']);
  const dispatch = useDispatch();
  const { levels, selectedLevel: globalSelectedLevel } = useSelector(
    state => state.levels
  );
  const { assignedAdminGroup, assignedLevel } = useSelector(
    state => state.worksheet
  );

  const [selectedLevel, setSelectedLevel] = useState(
    assignedLevel !== '' ? assignedLevel : globalSelectedLevel.ID
  );
  const [selectedUserGroup, setSelectedUserGroup] = useState(
    assignedAdminGroup.ID
  );

  const onChangeLevel = event => {
    setSelectedLevel(event.target.value);
    dispatch(assignLevel(event.target.value));
    dispatch(resetAssignment('non-admin'));
  };

  const onChangeUserGroup = event => {
    if (event.target.checked) {
      setSelectedUserGroup(event.target.value);
      dispatch(
        assignAdminGroup({
          ID: event.target.value,
          Name: adminGroupsHash[event.target.value]
        })
      );
      dispatch(assignLevel(selectedLevel));
      dispatch(resetAssignment('non-admin'));
    } else {
      setSelectedUserGroup(null);
      dispatch(assignAdminGroup({}));
      dispatch(assignLevel(''));
      dispatch(resetAssignment('non-admin'));
    }
  };

  return (
    <div className="assign-admin">
      <p>
        {t(
          'create-assign-question:assignModal.chooseAdmin',
          'Please choose users and level you want to assign'
        )}
      </p>
      <div className="form-check">
        <input
          type="radio"
          id="type-premium"
          value="premium"
          checked={selectedUserGroup === 'premium'}
          onChange={onChangeUserGroup}
          className="form-check-radio custom-radio"
        />{' '}
        <Form.Label htmlFor="type-premium">
          {t(
            'create-assign-question:assignModal.allPremium',
            'All Premium users'
          )}
        </Form.Label>
      </div>

      {/* <Form.Group key="retail" className="row" controlId="select-users">
        <Form.Check
          className="checkbox-class"
          checked={selectedUserGroup === 'retail'}
          onChange={onChangeUserGroup}
          type="checkbox"
          label="All Retail users"
          value="retail"
        />
  </Form.Group> */}
      <div className="form-check">
        <input
          type="radio"
          id="koobits"
          value="koobits"
          checked={selectedUserGroup === 'koobits'}
          onChange={onChangeUserGroup}
          className="form-check-radio custom-radio"
        />{' '}
        <Form.Label htmlFor="type-premium">
          {t(
            'create-assign-question:assignModal.allKoobits',
            'All KooBits users'
          )}
        </Form.Label>
      </div>
      <Form.Group controlId="options-level">
        <Form.Control
          as="select"
          name="level"
          onChange={onChangeLevel}
          value={selectedLevel}
        >
          {levels.map(level => (
            <option key={level.ID} value={level.ID}>
              {level.Name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    </div>
  );
}
