import styled, { css } from 'styled-components';
export const StyledSwitchContainer = styled.div`
  position: absolute;
  left: 226px;
  top: 230px;
  background: #dddddd;
  color: #484848;
  padding: 3px 4px;
  border-radius: 4px;
  display: flex;
  z-index: 1;
  @media (max-width: 1024px) {
    left: 84px;
  }
`;
export const StyledSwitch = styled.div`
  min-width: 142px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px 0 0 4px;
  background: white;
  border: 1px solid #cbcbcb;
  border-right: none;
  :last-child {
    border-radius: 0 4px 4px 0;
  }
  @media (max-width: 1223px) {
    min-width: 100px;
  }
  ${({ active }) =>
    active &&
    css`
      background: #fff7f2;
      border: 1px solid #ff5b00;
      color: #ff5b00;
    `}
`;
