import React from 'react';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Cell } from 'recharts';

const colors = ['#55C92B', '#49C8AA', '#FF7121', '#FFC043'];

const CustomYAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={2}
        textAnchor="end"
        fill="#79b0ff"
        fontSize="0.8rem"
      >
        {payload.value}
      </text>
    </g>
  );
};

export default function SubmissionsBarChart({ data }) {
  const { t } = useTranslation(['track-assignment-multi-class']);
  return (
    <BarChart
      width={1000}
      height={400}
      data={data}
      margin={{ top: 5, right: 30, left: 20, bottom: 10 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        type="category"
        dataKey="ClassName"
        label={{
          value: t(
            `track-assignment-multi-class:barGraph.hWSubmission`,
            'Homework Submission Status'
          ),
          position: 'insideBottom',
          offset: -10
        }}
      />
      <YAxis type="number" allowDecimals={false} tick={<CustomYAxisTick />} />
      <Bar dataKey="TotalSubmission" maxBarSize={100}>
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colors[index % 4]} />
        ))}
      </Bar>
    </BarChart>
  );
}
