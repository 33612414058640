import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import manageClass from 'assets/img/icon-manage-class-class.svg';
import myGroup from 'assets/img/icon-manage-class-group.svg';
import gameSetting from 'assets/img/icon-game-setting.svg';
import { featureToggle } from 'constants/index';

export default function SidebarManageClass() {
  const { t } = useTranslation(['manage-class']);
  const { isAdmin } = useSelector(state => state.login);
  const { pathname } = useLocation();
  const isActivePath = path => path === pathname;
  return (
    <div className="sidebar-create-assign">
      <ul className="sidebar-create-assign__ul list-group">
        <li className="list-group-item">
          <Link
            className={`nav-link${
              isActivePath('/manage-class') ? ' nav-link-active' : ''
            }`}
            to="/manage-class"
          >
            <img src={manageClass} alt="icon-list" className="sidebar-icon" />
            <span>{t(`manage-class:sidebar.manageClass`, 'Manage Class')}</span>
          </Link>
        </li>
        <li className="list-group-item">
          <Link
            className={`nav-link${
              isActivePath('/manage-class/show') ? ' nav-link-active' : ''
            }`}
            to="/manage-class/show"
          >
            <img src={myGroup} alt="icon-list" className="sidebar-icon" />
            <span>{t(`manage-class:sidebar.myGroups`, 'My Groups')}</span>
          </Link>
        </li>
        {featureToggle.myGroups && (
          <li className="list-group-item">
            <Link
              className={`nav-link${
                isActivePath('/manage-class/groups') ? ' nav-link-active' : ''
              }`}
            >
              <img
                src={myGroup}
                alt="icon-list"
                className="sidebar-icon icon-question-cart"
              />
              <span>{t(`manage-class:sidebar.myGroups`, 'My Groups')}</span>
            </Link>
          </li>
        )}
        {isAdmin && (
          <>
            <li className="list-group-item">
              <Link
                className={`nav-link${
                  isActivePath('/manage-class/privategroups')
                    ? ' nav-link-active'
                    : ''
                }`}
                to="/manage-class/privategroups"
              >
                <img
                  src={myGroup}
                  alt="icon-list"
                  className="sidebar-icon icon-question-cart"
                />
                <span>
                  {t(`manage-class:sidebar.allPvtGroups`, 'All Private Groups')}
                </span>
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                className={`nav-link${
                  isActivePath('/manage-class/add') ? ' nav-link-active' : ''
                }`}
                to="/manage-class/add"
              >
                <img
                  src={myGroup}
                  alt="icon-list"
                  className="sidebar-icon icon-question-cart"
                />
                <span>
                  {t(`manage-class:sidebar.addPvtGroups`, 'Add Private Groups')}
                </span>
              </Link>
            </li>
          </>
        )}
        <li className="list-group-item">
          <Link
            className={`nav-link${
              isActivePath('/manage-class/game-setting')
                ? ' nav-link-active'
                : ''
            }`}
            to="/manage-class/game-setting"
          >
            <img src={gameSetting} alt="icon-list" className="sidebar-icon" />
            <span>
              {t(`manage-class:sidebar.mathGameSetting`, 'Math Game Setting')}
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
}
