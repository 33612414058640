import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { setStep } from 'stores/utils/step/actions';
import EmptyCartQuestion from 'components/CreateAndAssign/EmptyCartQuestion';
import WorksheetQuestions from 'pages/CreateAndAssign/QuestionCart/WorksheetQuestions';
import WorksheetEdit from 'pages/CreateAndAssign/QuestionCart/WorksheetEdit';
// import WorksheetPreview from 'pages/CreateAndAssign/QuestionCart/WorksheetPreview';
import StepperWizard from 'components/Shared/StepperWizard';
import WorksheetAssign from 'pages/CreateAndAssign/QuestionCart/WorksheetAssign';
import isNilOrEmpty from 'helpers/isNilOrEmpty';
import WorksheetPreviewNew from './QuestionCart/WorksheetPreviewNew';

/**
 * @todo when the UI for empty cart questions done, use cartQuestions from reducer or other resources
 * @note client still not confirm whether cartQuestions is the data we will be use
 */
export default function QuestionCart() {
  const { t } = useTranslation(['create-assign-question']);
  const dispatch = useDispatch();
  const { cartQuestions } = useSelector(state => state.questions);
  const { title, selectedQuestions, presentationType } = useSelector(
    state => state.worksheet
  );

  const onSelect = step => {
    let valid = false;
    switch (step) {
      case 2:
        valid = selectedQuestions.length > 0 && selectedQuestions.length <= 50;
        break;
      case 3:
        valid = title.trim().length > 0;
        break;
      case 4:
        valid =
          selectedQuestions.length > 0 &&
          title.trim().length > 0 &&
          !isNilOrEmpty(presentationType);
        break;
      default:
        valid = true;
        break;
    }
    if (valid) {
      dispatch(setStep(step));
    }
  };

  return (
    <>
      {isEmpty(cartQuestions) ? (
        <EmptyCartQuestion />
      ) : (
        <StepperWizard onSelect={onSelect}>
          <WorksheetQuestions
            step="1"
            title={t(
              `create-assign-question:stepper.questionCart`,
              'Question Cart'
            )}
          />
          <WorksheetEdit
            step="2"
            title={t(`create-assign-question:stepper.editWo`, 'Edit Worksheet')}
          />
          <WorksheetPreviewNew
            step="3"
            title={t(`create-assign-question:stepper.preview`, 'Preview')}
          />
          <WorksheetAssign
            step="4"
            title={t(`create-assign-question:stepper.assign`, 'Assign')}
          />
        </StepperWizard>
      )}
    </>
  );
}
