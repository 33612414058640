import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Clipboard from 'react-clipboard.js';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ButtonGroup, Button } from 'react-bootstrap';
import {
  fetchMultiClassReportRequest,
  clearMultiClassReport
} from 'stores/assignments/actions';
import SubmissionsBarChart from 'components/TrackAssignment/SubmissionsBarChart';
import HomeworkQuestionsModal from 'components/TrackAssignment/HomeworkQuestionsModal';
import printIcon from 'assets/img/icon-track-assignment-print-active.svg';
import downloadIcon from 'assets/img/icon-track-assignment-download.svg';
import leaderboardIcon from 'assets/img/icon-track-assignment-leaderboard.svg';
import openHWIcon from 'assets/img/icon-track-assignment-open-hw.svg';
import { featureToggle, homeworkLink } from 'constants/index';
import PopupModal from 'components/ManageClass/PopupModal';
import DownloadCSVModalBody from 'components/TrackAssignment/DownloadCSVModalBody';
import { MultiClassReportPage } from './MultiClassReport.styles';

const checkforSubmissions = classes => {
  for (const classObject of classes) {
    if (classObject.TotalSubmission > 0) {
      return true;
    }
  }
  return false;
};

export default function MultiClassReport({ match }) {
  const { t } = useTranslation(['track-assignment-multi-class']);
  const homeworkID = parseInt(match.params.homeworkID);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    multiClassReport,
    assignmentSummaryHistory,
    selectedTeacher
  } = useSelector(state => state.assignments);

  // Component States
  const [showQuestionsModal, setShowQuestionsModal] = useState(false);
  const [showDownloadCSVModal, setShowDownloadCSVModal] = useState(false);

  // Modal Event Handlers
  const hideQuestionModal = () => {
    setShowQuestionsModal(false);
  };

  const hideDownloadCSVModal = () => {
    setShowDownloadCSVModal(false);
  };

  useEffect(() => {
    dispatch(
      fetchMultiClassReportRequest({
        homeworkID
      })
    );
    return () => {
      dispatch(clearMultiClassReport());
    };
  }, [dispatch, homeworkID]);

  return (
    <>
      {multiClassReport.length > 0 && (
        <MultiClassReportPage>
          <PopupModal
            show={showDownloadCSVModal}
            hide={hideDownloadCSVModal}
            size="sm"
          >
            <DownloadCSVModalBody
              homeworkID={homeworkID}
              hide={hideDownloadCSVModal}
            />
          </PopupModal>
          <HomeworkQuestionsModal
            show={showQuestionsModal}
            hide={hideQuestionModal}
            homeworkID={homeworkID}
            hwTitle={multiClassReport[0].HomeWorkTitle}
            isSingleClassReportPage={false}
          />
          <div
            className="report-navigation"
            onClick={() => {
              if (assignmentSummaryHistory === 'others') {
                history.push(`/track-assignment/others/${selectedTeacher}`);
              } else {
                history.push('/track-assignment');
              }
            }}
          >
            {`< ${t(`track-assignment-multi-class:main.back`, `Back`)}`}
          </div>
          <div className="tab-container">
            <div className="report-tabs">
              <span className="active">
                {t(`track-assignment-multi-class:main.all`, `All`)}
              </span>
              {multiClassReport[0].TrackingIndividual !== null && (
                <span
                  className="class-tab"
                  onClick={() => {
                    history.push(
                      `/track-assignment/homework/${homeworkID}/all`
                    );
                  }}
                >
                  {t(
                    `track-assignment-multi-class:main.assignedStud`,
                    `Assigned Students`
                  )}
                </span>
              )}
              {multiClassReport[0].TrackingGroup.length > 0 &&
                multiClassReport[0].TrackingGroup.map(classGroup => (
                  <span
                    key={classGroup.ClassID}
                    className="class-tab"
                    onClick={() => {
                      history.push(
                        `/track-assignment/homework/${homeworkID}/${classGroup.ClassID}`
                      );
                    }}
                  >
                    {classGroup.ClassName}
                  </span>
                ))}
              <span className="placeholder" />
            </div>

            <span className="scroller-tab">
              <ButtonGroup aria-label="Basic example">
                <Button
                  variant="light"
                  onClick={() => {
                    const div = document.getElementsByClassName('report-tabs');
                    div[0].scrollLeft -= 100;
                  }}
                >
                  {'<'}
                </Button>
                <Button
                  variant="light"
                  onClick={() => {
                    const div = document.getElementsByClassName('report-tabs');
                    div[0].scrollLeft += 100;
                  }}
                >
                  {'>'}
                </Button>
              </ButtonGroup>
            </span>
          </div>
          <div className="report-homework-details">
            <div className="report-homework-details-info">
              <p>
                <strong>{multiClassReport[0].HomeWorkTitle}</strong>
              </p>
              <span style={{ marginRight: '1rem' }}>
                {t(
                  `track-assignment-multi-class:main.assignBy`,
                  'Assigned by: '
                )}
                {multiClassReport[0].TeacherName}
              </span>
              <span className="report-dates">{`${moment(
                multiClassReport[0].StartDate
              ).format('DD/MM/YYYY')} - ${moment(
                multiClassReport[0].Deadline
              ).format('DD/MM/YYYY')}`}</span>
            </div>

            <div className="report-actions">
              <span
                onClick={() => {
                  const win = window.open(
                    `${process.env.REACT_APP_DOMAIN}${
                      process.env.REACT_APP_SUBDOMAIN
                    }${
                      process.env.REACT_APP_SUBDOMAIN === '/' ? '' : '/'
                    }print/${homeworkID}`,
                    '_blank'
                  );
                  if (win != null) {
                    win.focus();
                  }
                }}
              >
                <img src={printIcon} alt="Print" className="action-icon" />
                {t(`track-assignment-multi-class:main.printHw`, 'Print HW')}
              </span>
              <span
                onClick={() => {
                  setShowDownloadCSVModal(true);
                }}
              >
                <img
                  src={downloadIcon}
                  alt="Download"
                  className="action-icon"
                />
                {t(`track-assignment-multi-class:main.download`, 'Download')}
              </span>
              {featureToggle.reportActionButtons &&
                multiClassReport[0].HomeWorkType === 13 && (
                  <>
                    <span>|</span>
                    <span
                      onClick={() => {
                        history.push(`/leaderboard/${homeworkID}`);
                      }}
                    >
                      <img
                        src={leaderboardIcon}
                        alt="Leaderboard"
                        className="action-icon"
                      />
                      {t(
                        `track-assignment-multi-class:main.leaderboard`,
                        'Leaderboard'
                      )}
                    </span>
                  </>
                )}
              <span
                onClick={() => {
                  setShowQuestionsModal(true);
                }}
              >
                <img src={openHWIcon} alt="Open HW" className="action-icon" />
                {t(`track-assignment-multi-class:main.openHw`, 'Open HW')}
              </span>
            </div>
          </div>
          <div className="report-submission-details">
            <p>{`${t(
              `track-assignment-multi-class:main.startDate`,
              'Start Date: '
            )}${moment(multiClassReport[0].StartDate).format(
              'DD-MMM-YYYY HH:mm'
            )}`}</p>
            <p>{`${t(
              `track-assignment-multi-class:main.deadline`,
              'Deadline '
            )}${moment(multiClassReport[0].Deadline).format(
              'DD-MMM-YYYY HH:mm'
            )} ${!multiClassReport[0].CloseAfterDeadline &&
              t(
                `track-assignment-multi-class:main.accessible`,
                '(Accessible after deadline)'
              )}`}</p>
            <div className="submission-details-url">
              <span>
                {t(`track-assignment-multi-class:main.url`, 'URL: ')}
                <span className="url-text">{`${homeworkLink}${multiClassReport[0].HomeWorkId}`}</span>
              </span>
              <Clipboard
                data-clipboard-text={`${homeworkLink}${multiClassReport[0].HomeWorkId}`}
                className="copy-button"
              >
                {t(`track-assignment-multi-class:main.copy`, 'Copy')}
              </Clipboard>
            </div>
          </div>
          <div className="report-submission-chart">
            {multiClassReport[0].TrackingGroup.length > 0 && (
              <SubmissionsBarChart data={multiClassReport[0].TrackingGroup} />
            )}
            {multiClassReport[0].TrackingGroup.length > 0 &&
              !checkforSubmissions(multiClassReport[0].TrackingGroup) && (
                <div className="chart-empty-text">
                  <h3>
                    {t(
                      `track-assignment-multi-class:barGraph.emptyMsg`,
                      'No Submissions yet'
                    )}
                  </h3>
                  <p>
                    {t(
                      `track-assignment-multi-class:barGraph.submissionMsg`,
                      `When your students submit the homework, then you'll find the data here`
                    )}
                  </p>
                </div>
              )}
          </div>
        </MultiClassReportPage>
      )}
    </>
  );
}
