import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Col } from 'react-bootstrap';
import WorksheetQuestionModal from 'components/CreateAndAssign/QuestionCart/WorksheetQuestionModal';
import WorksheetForm from 'components/CreateAndAssign/QuestionCart/WorksheetForm';
import { setWorksheetTitle } from 'stores/worksheet/actions';
import NavigationWizard from 'components/CreateAndAssign/NavigationWizard';
import { nextStep } from 'stores/utils/step/actions';
import WorksheetQuestion from 'components/CreateAndAssign/QuestionCart/WorksheetQuestion';
import Spinner from 'components/Shared/Spinner';
import ValidationMessage from 'components/CreateAndAssign/ValidationMessage';
import {
  EditSavedWorksheetContainer,
  HeaderContainer,
  HeaderContent
} from './EditSavedWorksheet.styles';

const EditSavedWorksheet = () => {
  const { t } = useTranslation(['create-assign-saved']);
  const dispatch = useDispatch();
  // REDUX STATES
  const { isLoading, editSavedWorksheetQuestions, title } = useSelector(
    state => state.worksheet
  );
  // COMPONENT STATES
  const [show, setShow] = useState(false);
  const [showSolution, setShowSolution] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [alertMsg, setAlertMsg] = useState(false);

  const toggleShowSolution = () => setShowSolution(!showSolution);

  const [questionPopup, setQuestionPopup] = useState({});
  const handleClose = () => {
    toggleShowSolution();
    setShow(false);
  };

  const handleShow = async question => {
    setQuestionPopup(question);
    setShow(true);
  };

  const handleNextStep = () => {
    if (title.trim() === '') {
      setAlertMsg(true);
    }
    if (title.trim().length > 0 && editSavedWorksheetQuestions.length > 0) {
      setAlertMsg(false);
      dispatch(nextStep());
    }
  };

  const onChangeTitle = value => {
    dispatch(setWorksheetTitle(value));
  };

  /*eslint-disable */
  useEffect(() => {
    if (!isLoading && editSavedWorksheetQuestions.length > 0) {
      setSelectedCard(editSavedWorksheetQuestions[0].ID);
    }
  }, [isLoading]);
  /* eslint-enable */

  return (
    <EditSavedWorksheetContainer>
      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          <Modal show={show} onHide={handleClose} className="modal-qs">
            <WorksheetQuestionModal
              key={String(questionPopup.Id)}
              selectedQuestion={questionPopup}
              onCloseModal={handleClose}
              showSolution={showSolution}
            />
          </Modal>
          <HeaderContainer>
            <HeaderContent>
              <Col xl={7} md={6}>
                <WorksheetForm
                  isError={title.trim() === ''}
                  value={title}
                  placeholder={t(
                    `create-assign-saved:main.addWoTitle`,
                    'Add Worksheet Title'
                  )}
                  onChange={onChangeTitle}
                  showBackBtn={false}
                  alertMsg={alertMsg}
                />
              </Col>
              <NavigationWizard
                onClick={handleNextStep}
                showtotalQuestions
                totalQuestions={editSavedWorksheetQuestions.length}
              />
            </HeaderContent>
            {editSavedWorksheetQuestions.length > 50 && <ValidationMessage />}
          </HeaderContainer>
          <div className="box-white-wrap question-cart-list">
            <div className="row">
              {editSavedWorksheetQuestions.map((question, key) => {
                return (
                  <WorksheetQuestion
                    isEditSavedWorksheet
                    isShowEditPanel
                    key={String(question.ID)}
                    selectedQuestion={question}
                    onShowModal={() => handleShow(question)}
                    onShowSolution={toggleShowSolution}
                    selectedCard={selectedCard}
                    onClick={() => setSelectedCard(question.ID)}
                    cardIndex={key}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}
    </EditSavedWorksheetContainer>
  );
};

export default EditSavedWorksheet;
