import styled from 'styled-components';

export const ProfileImageStyle = styled.div`
  position: relative;
  margin-bottom: 30px;
  .profile-img {
    width: auto;
    height: 90px;
    border-radius: 50%;
  }
  .camera-border {
    position: absolute;
    bottom: -4px;
    right: -4px;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 20px;
    }
  }
`;

export const ProfileModal = styled.div`
  .modal-content {
    max-width: 700px;
  }
  h5 {
    height: 60px;
    background: #ededed;
    text-align: center;
    color: #202020;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img-modal-body {
    height: 300px;
    overflow: auto;
    padding: 20px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    .imgs-container {
      transition: all 0.2s;
      margin: 10px 15px;
      border: 3px solid transparent;
      cursor: pointer;
      border: 3px solid transparent;
    }
    .img-active {
      border-color: #ff5b00;
      filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    }
    .all-imgs {
      width: 100px;
      height: 100px;
    }
  }
  .img-modal-footer {
    padding: 20px 30px;
    text-align: right;
    button {
      margin-left: 5px;
    }
    .btn-outline-light {
      color: #545454;
      border: 1px solid #c4c4c4;
      border-radius: 2px;
    }
  }
`;
