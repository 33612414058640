import styled from 'styled-components';

export const ProfileFormContainer = styled.div`
  text-align: center;
  .edit-profile-field {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    font-size: 16px;
    .field-title {
      width: 210px;
      text-align: right;
      margin-right: 10px;
      color: #202020;
    }
    .profile-form-inp {
      width: 360px;
      height: 38px;
      padding: 0 1.5rem;
      border: solid 1px rgba(112, 112, 112, 0.36);
      color: #202020;
      font-weight: 600;
    }
    .password-button {
      display: flex;
      width: 360px;
      height: 38px;
      padding: 0 0.3rem 0 1.5rem;
      border: solid 1px rgba(112, 112, 112, 0.36);
      color: #202020;
      font-weight: 600;
      background-color: #fff;
      align-items: center;
    }
    .change-pswd-inp {
      flex-grow: 2;
      background: transparent;
      border: none;
    }
    .change-pswd {
      color: #ff5b00;
      cursor: pointer;
    }
    .show-hide {
      cursor: pointer;
    }
  }
  .cancel-pswd-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    .pswd-border-line {
      width: 60%;
      margin: 8px;
      border-color: #c4c4c4;
    }
    .cancel-pswd {
      color: #ff5b00;
      cursor: pointer;
      font-weight: 600px;
    }
  }
  .profile-err {
    color: red;
    margin-bottom: 5px;
  }
  .save-profile {
    font-weight: 600;
    font-size: 16px;
    padding: 5px 20px;
    min-width: auto;
  }
  .save-profile:disabled {
    background-color: #ff5b00;
    border-color: #ff5b00;
    opacity: 0.4;
    cursor: not-allowed;
  }
`;
