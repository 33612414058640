import React from 'react';
import { useTranslation } from 'react-i18next';
import PopoverMenuItem from 'components/TrackAssignment/PopoverMenuItem';
import { ActionsMenu, MenuContent } from './GroupPopoverMenu.styles';

export default function GroupPopoverMenu({
  openDeleteModal,
  setIdNameForDelete,
  editGroup
}) {
  const { t } = useTranslation(['manage-class-group']);
  return (
    <ActionsMenu>
      <button type="button" className="more-options">
        ...
      </button>
      <MenuContent className="actions-dropdown-content">
        <PopoverMenuItem icon="edit">
          <span style={{ marginLeft: '1rem' }} onClick={editGroup}>
            {t(`manage-class-group:table.edit`, 'Edit')}
          </span>
        </PopoverMenuItem>
        <PopoverMenuItem icon="delete">
          <span
            style={{ marginLeft: '1rem' }}
            onClick={() => {
              setIdNameForDelete();
              openDeleteModal();
            }}
          >
            {t(`manage-class-group:table.delete`, 'Delete')}
          </span>
        </PopoverMenuItem>
        {/* <PopoverMenuItem icon="email">
          <span
            style={{ marginLeft: '1rem' }}
            onClick={console.log('email')}>
            Email
          </span>
        </PopoverMenuItem>
        <PopoverMenuItem icon="notification">
          <span
            style={{ marginLeft: '1rem' }}
            onClick={() => console.log('notification')}
          >
            Notification
          </span>
        </PopoverMenuItem> */}
      </MenuContent>
    </ActionsMenu>
  );
}
