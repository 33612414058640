import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Topbar,
  Container,
  TitleHeading,
  TitleMeta
} from './LeaderboardHeader.styles';
import iconFactualFluency from 'assets/img/icon-factual-fluency.png';
import BackIcon from 'assets/img/back-icon.svg';

import moment from 'moment';

const LeaderboardHeader = props => {
  const { homework } = props;
  const { t } = useTranslation(['leaderboard']);
  const history = useHistory();
  return (
    <Topbar>
      <div
        className="button-back"
        onClick={() => {
          history.goBack();
        }}
      >
        <img src={BackIcon} alt="Back" />
        {`${t(`track-assignment-multi-class:main.back`, `Back`)}`}
      </div>
      <Container>
        <TitleHeading>
          <img src={iconFactualFluency} alt="iconFactualFluency" />
          {homework.HomeWorkTitle}
        </TitleHeading>
        <TitleMeta>
          <span>Assigned by: {homework.TeacherName}</span>
          <span>
            {`${moment(homework.StartDate).format('DD/MM/YYYY')}-${moment(
              homework.Deadline
            ).format('DD/MM/YYYY')}`}
          </span>
        </TitleMeta>
      </Container>
    </Topbar>
  );
};

export default React.memo(LeaderboardHeader);
