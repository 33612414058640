import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sort, clone, isEmpty } from 'ramda';

import { HOTS_CURR_ID, AGE_GROUP } from 'constants/hotsCurr';

import {
  fetchSyllabusRequest,
  pushToCartRequest,
  createWorkSheetRequest,
  pushToCartReset
} from 'stores/topical-worksheet/actions';
import { selectLevel } from 'stores/levels/actions';
import { selectCurriculum } from 'stores/curriculums/actions';

import DropdownMenu from 'components/Shared/DropdownMenu';
import AlertModal from 'components/Alert/AlertModal';
import SelectNumberQuestions from 'components/CreateAndAssign/SelectNumberQuestions';

import topicalWorksheetWhiteIcon from 'assets/img/icon-topical-worksheet-white.svg';
import cartIcon from 'assets/img/icon-push-cart.svg';
import { resetSelectedWorksheet } from 'stores/worksheet/actions';
import { TopicalWorksheetContainer } from './TopicalWorksheet.styles';
import NoSyllabus from './NoSyllabus';

export default function TopicalWorksheet() {
  const { t } = useTranslation(['create-assig-topical', 'common']);
  const history = useHistory();
  const dispatch = useDispatch();
  const { curriculums, selectedCurriculum } = useSelector(
    state => state.curriculums
  );
  const { levels, selectedLevel } = useSelector(state => state.levels);

  const {
    syllabus,
    createWorksheet,
    pushCart,
    isLoading,
    pushCartLoading,
    createWorksheetLoading,
    error
  } = useSelector(state => state.topicalWorksheet);
  const sortedTopics = sort((a, b) => a.Display - b.Display, syllabus);

  const [isRun, setIsRun] = useState(false);
  const [levelId, setLevelId] = useState(selectedLevel?.ID);
  const [curriculumId, setCurriculumId] = useState(
    selectedCurriculum.CurriculumID
  );
  const [curriculumName, setCurriculumName] = useState(
    selectedCurriculum.curriculumName
  );
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowMsg, setIsShowMsg] = useState('');

  const levellessCurr = isEmpty(selectedCurriculum.Levels);

  const sortedArray = arrVal => {
    const val = arrVal.map(elem => {
      return {
        easy: 0,
        medium: 0,
        difficult: 0,
        total: 0
      };
    });
    return val;
  };
  const [counts, setCounts] = useState(sortedArray(sortedTopics));

  const isHOTS = selectedCurriculum?.CurriculumID === HOTS_CURR_ID;

  useEffect(() => {
    setCurriculum(curriculumId, curriculumName);
    return () => {
      dispatch(resetSelectedWorksheet());
    };
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (!isRun) {
      setIsRun(true);
      return;
    }
    const sortedTopics = sort((a, b) => a.Display - b.Display, syllabus);
    let tempCounts = clone(counts);
    tempCounts = sortedArray(sortedTopics);
    setCounts(tempCounts);
    // eslint-disable-next-line
  }, [syllabus]);

  useEffect(() => {
    if (!isRun) {
      setIsRun(true);
      return;
    }
    if (!isEmpty(createWorksheet)) {
      history.push('/create-and-assign/topical-worksheet/create');
    }
    // eslint-disable-next-line
  }, [createWorksheet]);

  useEffect(() => {
    if (!isRun) {
      setIsRun(true);
      return;
    }
    if (!isEmpty(pushCart)) {
      dispatch(pushToCartReset());
      history.push('/create-and-assign');
    }
    // eslint-disable-next-line
  }, [pushCart]);

  useEffect(() => {
    if (!isRun) {
      setIsRun(true);
      return;
    }
    if (!isEmpty(error)) {
      setIsShowModal(true);
      setIsShowMsg(error);
    }
    // eslint-disable-next-line
  }, [error]);

  const getSyllabus = (currId, level) => {
    dispatch(fetchSyllabusRequest(currId, level));
  };

  const setCurriculum = (id, name) => {
    const curriculum = curriculums.find(curr => curr.CurriculumID === id);
    setCurriculumId(id);
    setCurriculumName(name);
    const findLevel = curriculum.Levels.find(level => level.ID === levelId);
    if (!findLevel) {
      if (curriculum.Levels[0]) {
        setLevelId(curriculum.Levels[0].ID);
        dispatch(selectLevel(curriculum.Levels[0]));
      }
      getSyllabus(id, curriculum.Levels[0]?.ID ?? -1);
    } else {
      getSyllabus(id, levelId);
    }
    dispatch(selectCurriculum(curriculum));
  };

  const setLevel = id => {
    const level = levels.find(lvl => lvl.ID === id);
    setLevelId(id);
    dispatch(selectLevel(level));
    getSyllabus(curriculumId, id);
  };

  const save = (topicId, index, type) => {
    const { easy, medium, difficult } = counts[index];
    const obj = {
      curriculumId,
      topicId,
      levelId,
      easy,
      medium,
      difficult
    };
    if (type === 'push') {
      dispatch(pushToCartRequest(obj));
    } else {
      dispatch(createWorkSheetRequest(obj));
    }
  };

  const onChangeHandler = (val, difficultyLevel, index) => {
    if (/^\d*\.?\d*$/.test(val)) {
      const tempCounts = clone(counts);
      const objVal = tempCounts[index];
      if (val === '') val = 0;
      objVal[difficultyLevel] = Number(val);
      objVal.total = objVal.easy + objVal.medium + objVal.difficult;
      setCounts(tempCounts);
    }
  };

  const onControlsHandler = (type, difficultyLevel, index) => {
    const tempCounts = clone(counts);
    const objVal = tempCounts[index];
    if (type === 'inc') {
      objVal[difficultyLevel] = objVal[difficultyLevel] + 1;
    } else if (objVal[difficultyLevel] !== 0) {
      objVal[difficultyLevel] = objVal[difficultyLevel] - 1;
    }
    objVal.total = objVal.easy + objVal.medium + objVal.difficult;
    setCounts(tempCounts);
  };

  const onModalClose = () => {
    setIsShowMsg('');
    setIsShowModal(false);
  };

  return (
    <TopicalWorksheetContainer>
      <div className="topical-worksheet-header">
        <img src={topicalWorksheetWhiteIcon} alt="topical-icon" />
        <span>
          {t(`create-assig-topical:main.topicalWo`, 'Topical Worksheet')}
        </span>
      </div>
      {(isLoading || pushCartLoading || createWorksheetLoading) && (
        <Spinner className="topical-loader" animation="border" />
      )}
      <div className="topical-worksheet-dropdown">
        <div className="tw-levels">
          <span>{t(`create-assig-topical:main.curriculum`, 'Curriculum')}</span>
          <Dropdown>
            <Dropdown.Toggle variant="light" id="dropdown-custom">
              {curriculumName}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {curriculums.map(item => (
                <Dropdown.Item
                  key={`${item.CurriculumID}`}
                  onClick={() =>
                    setCurriculum(item.CurriculumID, item.curriculumName)
                  }
                  value={curriculumId}
                >
                  {item.curriculumName}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {!levellessCurr && (
          <div className="tw-levels">
            <span>{t(`create-assig-topical:main.level`, 'Level')}</span>
            <DropdownMenu
              selectedValue={
                isHOTS
                  ? `${t(`common:age`, 'Age')} ${AGE_GROUP[levelId]}`
                  : `Primary ${levelId}`
              }
              valueKey={null}
              values={selectedCurriculum?.Levels?.map(level => level.ID)}
              setValue={setLevel}
              valueTemplate={value =>
                isHOTS
                  ? `${t(`common:age`, 'Age')} ${AGE_GROUP[value]}`
                  : `P${value}`
              }
            />
          </div>
        )}
      </div>

      <div className="topical-worksheet-body">
        {sortedTopics.length === 0 ? (
          <NoSyllabus />
        ) : (
          sortedTopics.map((item, index) => (
            <div className="topical-worksheet-card" key={item.ID}>
              <div className="topical-name-category">
                <div className="syllabus-name">
                  <div className="syllabus-item-title">
                    {t(`create-assig-topical:main.topic`, 'Topic')}
                  </div>
                  <div className="syllabus-item-name">{item.Name}</div>
                </div>
              </div>
              <div className="topical-difficulty-category">
                <SelectNumberQuestions
                  type="easy"
                  count={counts[index] && counts[index].easy}
                  changeCount={onChangeHandler}
                  changeControl={onControlsHandler}
                  color="#48D292"
                  index={index}
                  label={t(`create-assig-topical:main.easy`, 'Easy')}
                />
                <SelectNumberQuestions
                  type="medium"
                  count={counts[index] && counts[index].medium}
                  changeCount={onChangeHandler}
                  changeControl={onControlsHandler}
                  color="#F09E54"
                  index={index}
                  label={t(`create-assig-topical:main.medium`, 'Medium')}
                />
                <SelectNumberQuestions
                  type="difficult"
                  count={counts[index] && counts[index].difficult}
                  changeCount={onChangeHandler}
                  changeControl={onControlsHandler}
                  color="#FF7D7D"
                  index={index}
                  label={t(`create-assig-topical:main.difficult`, 'Difficult')}
                />
              </div>
              <div className="topical-total-category">
                <div className="tw-category-total">
                  {t(`create-assig-topical:main.total`, 'Total: ')}
                  <span>{counts[index] && counts[index].total}</span>
                </div>
                <div className="tw-category-controls">
                  <button
                    className="tw-push-cart"
                    onClick={() => save(item.ID, index, 'push')}
                    disabled={counts[index] && counts[index].total === 0}
                  >
                    +<img src={cartIcon} alt="cart-icon" />
                  </button>
                  <button
                    className="tw-create"
                    onClick={() => save(item.ID, index, 'create')}
                    disabled={counts[index] && counts[index].total === 0}
                  >
                    {t(`create-assig-topical:main.create`, 'Create')}
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
        <AlertModal
          title={t(`create-assig-topical:delWo.error`, 'Error')}
          isShow={isShowModal}
          handleClose={onModalClose}
          buttons={
            <button
              type="button"
              className="btn btn-primary"
              onClick={onModalClose}
            >
              {t(`create-assig-topical:delWo.close`, 'Close')}
            </button>
          }
        >
          <p>{isShowMsg}</p>
        </AlertModal>
      </div>
    </TopicalWorksheetContainer>
  );
}
