import React from 'react';
import { assignmentTypes } from 'constants/index';
import { invertObj, toUpper } from 'ramda';
import './CircleType.styles.scss';
import iconFactualFluency from 'assets/img/icon-factual-fluency.png';
import { isFactualFluency } from 'helpers/factualFluency';

export default function CircleType(props) {
  const { typeId } = props;
  const assignmentTypeName = invertObj(assignmentTypes)[typeId];

  return (
    <span className={`worksheet-type worksheet-type--${assignmentTypeName}`}>
      {isFactualFluency(typeId) ? (
        <img src={iconFactualFluency} alt="iconFactualFluency" />
      ) : (
        toUpper(assignmentTypeName.substr(0, 1))
      )}
    </span>
  );
}
