import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';

import AlertModal from './AlertModal';

const ConfirmModal = props => {
  const { t } = useTranslation(['create-assign-question']);
  const { isShow, onConfirm, onClose, title, children, loading } = props;

  const buttons = (
    <>
      <button
        type="button"
        className="btn btn-outline-light"
        onClick={onClose}
        style={{ marginRight: '1rem' }}
      >
        {t(`create-assign-question:resetModal.no`, 'No')}
      </button>
      <button type="button" className="btn btn-primary" onClick={onConfirm}>
        {t(`create-assign-question:resetModal.yes`, 'Yes')}
        {loading === true && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="ml-2"
          />
        )}
      </button>
    </>
  );

  return (
    <AlertModal
      title={title}
      isShow={isShow}
      handleClose={onClose}
      buttons={buttons}
    >
      {children}
    </AlertModal>
  );
};

export default ConfirmModal;
