import { fetchSingleVariation } from 'stores/variations/actions';
import { useDispatch } from 'react-redux';
import React, { useEffect } from 'react';

const scrollMarginTop = 20;
// eslint-disable-next-line
const ItemWrap = React.forwardRef(({ children, id, skill, sio }, ref) => {
  const dispatch = useDispatch();
  useEffect(() => {
    let timer = null;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          clearTimeout(timer);
          if (!sio.length) {
            timer = setTimeout(() => {
              dispatch(fetchSingleVariation({ skill }));
            }, 500);
          }
        } else {
          clearTimeout(timer);
        }
      },
      { threshold: 0.5 }
    );
    const currentRef = ref?.current;
    if (currentRef) {
      observer.observe(currentRef);
    }
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
      clearTimeout(timer);
    };
  }, [dispatch, ref, sio, sio.length, skill]);
  return (
    <>
      {/* eslint-disable-next-line */}
      <div id={id} ref={ref} style={{ scrollMarginTop }}>
        {children}
      </div>
    </>
  );
});

export default ItemWrap;
