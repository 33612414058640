import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonStepPrev from 'components/Shared/ButtonStepPrev';

export default function WorksheetAssignTitle(props) {
  const { t } = useTranslation(['create-assign-question']);
  const { title, totalQuestions, icon, onPreview } = props;
  return (
    <div className="w-100 px-2">
      <ButtonStepPrev
        title={t(`create-assign-question:editWo.back`, '< Back')}
      />
      <div className="d-flex align-items-center justify-content-between px-3">
        <div className="d-flex align-items-center my-3">
          {icon && (
            <img
              src={icon}
              className="mr-2 align-self-start"
              width={35}
              height={35}
              alt="icon"
            />
          )}
          <div className="text-left assign-header-title">
            <h3>{title}</h3>
            <div className="d-flex flex-row assign-header-subtitle">
              <div>
                ( {t(`create-assign-question:main.totalQn`, 'Total Qn : ')}
                <span>{totalQuestions}</span> )
              </div>
              {/* <div>
                Total Score : <span>10</span> )
              </div> */}
            </div>
          </div>
        </div>
        {onPreview && (
          <button
            onClick={onPreview}
            className="btn btn-outline-primary align-self-start"
          >
            {t(`create-assign-question:stepper.preview`, 'Preview')}
          </button>
        )}
      </div>
    </div>
  );
}
