import {
  FETCH_CLASSES_REQUEST,
  FETCH_CLASSES_SUCCESS,
  FETCH_CLASSES_FAILED,
  FETCH_STUDENTS_DATA_REQUEST,
  FETCH_STUDENTS_CLASS_SUCCESS,
  FETCH_STUDENTS_FAILED,
  FETCH_STUDENT_DETAILS_REQUEST,
  FETCH_STUDENT_DETAILS_SUCCESS,
  FETCH_STUDENT_DETAILS_FAILED,
  EDIT_CLASS_REQUEST,
  EDIT_CLASS_SUCCESS,
  EDIT_CLASS_FAILED,
  CHANGE_CLASS_REQUEST,
  CHANGE_CLASS_SUCCESS,
  CHANGE_CLASS_FAILED,
  SEARCH_STUDENT_REQUEST,
  SEARCH_STUDENT_SUCCESS,
  SEARCH_STUDENT_FAILED,
  SET_STUDENT_PAGES,
  SET_SEARCH_PAGES,
  RESET_PW_REQUEST,
  RESET_PW_SUCCESS,
  RESET_PW_FAILED,
  FETCH_ICONS_REQUEST,
  FETCH_ICONS_SUCCESS,
  FETCH_ICONS_FAILED,
  RESET_STUDENTS,
  RESET_SEARCH_RESULTS,
  RESET_PW_RESULT,
  RESET_EDIT_CLASS_RESULT,
  RESET_CHANGE_CLASS_RESULT,
  RESET_ERROR
} from './actionTypes';

export const fetchClassesRequest = params => {
  return {
    type: FETCH_CLASSES_REQUEST,
    params
  };
};

export const fetchClassesSuccess = classes => {
  return { type: FETCH_CLASSES_SUCCESS, classes };
};

export const fetchClassesFailed = error => {
  return {
    type: FETCH_CLASSES_FAILED,
    error
  };
};

export const fetchStudentsRequest = params => {
  return {
    type: FETCH_STUDENTS_DATA_REQUEST,
    params
  };
};
export const fetchStudentsClassSuccess = students => {
  return { type: FETCH_STUDENTS_CLASS_SUCCESS, students };
};

export const fetchStudentsFailed = error => {
  return {
    type: FETCH_STUDENTS_FAILED,
    error
  };
};

export const fetchStudentDetailsRequest = params => {
  return {
    type: FETCH_STUDENT_DETAILS_REQUEST,
    params
  };
};
export const fetchStudentDetailsSuccess = details => {
  return { type: FETCH_STUDENT_DETAILS_SUCCESS, details };
};

export const fetchStudentDetailsFailed = error => {
  return {
    type: FETCH_STUDENT_DETAILS_FAILED,
    error
  };
};

export const editClassRequest = params => {
  return {
    type: EDIT_CLASS_REQUEST,
    params
  };
};

export const editClassSuccess = () => {
  return { type: EDIT_CLASS_SUCCESS };
};

export const editClassFailed = error => {
  return {
    type: EDIT_CLASS_FAILED,
    error
  };
};

export const changeClassRequest = params => {
  return {
    type: CHANGE_CLASS_REQUEST,
    params
  };
};

export const changeClassSuccess = () => {
  return { type: CHANGE_CLASS_SUCCESS };
};

export const changeClassFailed = error => {
  return {
    type: CHANGE_CLASS_FAILED,
    error
  };
};

export const searchStudentRequest = params => {
  return {
    type: SEARCH_STUDENT_REQUEST,
    params
  };
};

export const searchStudentSuccess = results => {
  return {
    type: SEARCH_STUDENT_SUCCESS,
    results
  };
};

export const searchStudentFailed = error => {
  return {
    type: SEARCH_STUDENT_FAILED,
    error
  };
};

export const resetPwRequest = params => {
  return {
    type: RESET_PW_REQUEST,
    params
  };
};

export const resetPwSuccess = () => {
  return { type: RESET_PW_SUCCESS };
};

export const resetPwFailed = error => {
  return {
    type: RESET_PW_FAILED,
    error
  };
};

export const fetchIconsRequest = params => {
  return {
    type: FETCH_ICONS_REQUEST,
    params
  };
};

export const fetchIconsSuccess = result => {
  return {
    type: FETCH_ICONS_SUCCESS,
    result
  };
};

export const fetchIconsFailed = error => {
  return {
    type: FETCH_ICONS_FAILED,
    error
  };
};

export const resetStudents = () => {
  return {
    type: RESET_STUDENTS
  };
};

export const resetSearchResults = () => {
  return {
    type: RESET_SEARCH_RESULTS
  };
};

export const setStudentPages = pageTotal => {
  return {
    type: SET_STUDENT_PAGES,
    pageTotal
  };
};

export const setSearchPages = pageTotal => {
  return {
    type: SET_SEARCH_PAGES,
    pageTotal
  };
};

export const resetPwResultAction = () => {
  return {
    type: RESET_PW_RESULT
  };
};

export const resetEditClassResult = () => {
  return {
    type: RESET_EDIT_CLASS_RESULT
  };
};

export const resetChangeClassResult = () => {
  return {
    type: RESET_CHANGE_CLASS_RESULT
  };
};

export const resetError = () => {
  return {
    type: RESET_ERROR
  };
};
