import history from 'helpers/history';

export default () => {
  // clear tokens from localStorage
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('current_locale');
  // logout by navigate to login page
  const loginRoute =
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_NETLIFY === 'true'
      ? '/login'
      : '/members-login';
  // add callback for flutter app logout
  const hasFlutter = window.flutter_inappwebview;
  if (hasFlutter) hasFlutter.callHandler('logOutFunc');
  history.push(loginRoute);
};
