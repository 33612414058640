import {
  FETCH_MY_GROUPS_REQUEST,
  FETCH_MY_GROUPS_SUCCESS,
  FETCH_MY_GROUPS_FAILED,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILED,
  DELETE_GROUP_RESET,
  CREATE_MY_GROUP_RESET,
  CREATE_MY_GROUP_REQUEST,
  CREATE_MY_GROUP_SUCCESS,
  CREATE_MY_GROUP_FAILED,
  UPDATE_GROUP_RESET,
  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAILED,
  GET_CLASS_BY_LEVEL_REQUEST,
  GET_CLASS_BY_LEVEL_SUCCESS,
  GET_CLASS_BY_LEVEL_FAILED,
  GET_STUDENT_BY_CLASS_RESET,
  GET_STUDENT_BY_CLASS_REQUEST,
  GET_STUDENT_BY_CLASS_SUCCESS,
  GET_STUDENT_BY_CLASS_FAILED
} from './actionTypes';

export const fetchMyGroupRequest = () => {
  return {
    type: FETCH_MY_GROUPS_REQUEST
  };
};

export const fetchMyGroupSuccess = data => {
  return {
    type: FETCH_MY_GROUPS_SUCCESS,
    data
  };
};

export const fetchMyGroupFailed = error => {
  return {
    type: FETCH_MY_GROUPS_FAILED,
    error
  };
};

export const deleteGroupRequest = params => {
  return {
    type: DELETE_GROUP_REQUEST,
    params
  };
};
export const deleteGroupSuccess = details => {
  return {
    type: DELETE_GROUP_SUCCESS,
    details
  };
};

export const deleteGroupFailed = error => {
  return {
    type: DELETE_GROUP_FAILED,
    error
  };
};

export const resetDeleteRequest = () => {
  return {
    type: DELETE_GROUP_RESET
  };
};

export const createMyGroupReset = () => {
  return {
    type: CREATE_MY_GROUP_RESET
  };
};

export const createMyGroupRequest = params => {
  return {
    type: CREATE_MY_GROUP_REQUEST,
    params
  };
};

export const createMyGroupSuccess = data => {
  return {
    type: CREATE_MY_GROUP_SUCCESS,
    data
  };
};

export const createMyGroupFailed = error => {
  return {
    type: CREATE_MY_GROUP_FAILED,
    error
  };
};

export const updateGroupReset = () => {
  return {
    type: UPDATE_GROUP_RESET
  };
};

export const updateGroupRequest = params => {
  return {
    type: UPDATE_GROUP_REQUEST,
    params
  };
};
export const updateGroupSuccess = data => {
  return {
    type: UPDATE_GROUP_SUCCESS,
    data
  };
};

export const updateGroupFailed = error => {
  return {
    type: UPDATE_GROUP_FAILED,
    error
  };
};

export const getClassByLevelRequest = params => {
  return {
    type: GET_CLASS_BY_LEVEL_REQUEST,
    params
  };
};

export const getClassByLevelSuccess = data => {
  return {
    type: GET_CLASS_BY_LEVEL_SUCCESS,
    data
  };
};

export const getClassByLevelFailed = error => {
  return {
    type: GET_CLASS_BY_LEVEL_FAILED,
    error
  };
};

export const getStudentByClassReset = () => {
  return {
    type: GET_STUDENT_BY_CLASS_RESET
  };
};

export const getStudentByClassRequest = params => {
  return {
    type: GET_STUDENT_BY_CLASS_REQUEST,
    params
  };
};

export const getStudentByClassSuccess = data => {
  return {
    type: GET_STUDENT_BY_CLASS_SUCCESS,
    data
  };
};

export const getStudentByClassFailed = error => {
  return {
    type: GET_STUDENT_BY_CLASS_FAILED,
    error
  };
};
