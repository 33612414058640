import React from 'react';

/**
 * @todo adding classNames and styles
 */
const HeaderBox = props => {
  const { children } = props;
  return (
    <div className="box-white d-flex justify-content-between align-items-center question-cart-content">
      {children}
    </div>
  );
};
export default HeaderBox;
