import styled from 'styled-components';

export const LoginPage = styled.div`
  height: calc(100vh - 53px);
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  @media (max-width: 480px) {
    overflow: hidden;
  }
`;
