import { getTimeType, renderTimes, secondsToTime } from 'helpers/timesParser';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import {
  Container,
  CenterMiddle,
  InfoGroup,
  InfoDesc,
  InfoLabel,
  InfoGroupBtn,
  CloseButton
} from './FactualFluencyReady.styles';

const FactualFluencyReady = ({ onMute }) => {
  const { t, i18n } = useTranslation(['create-assign-question']);
  const timeUnit = unit => {
    return `${t(`create-assign-question:timeSetting.${unit}`, unit)}`;
  };
  const { title, assignmentType, timeLimit } = useSelector(
    state => state.worksheet
  );

  const { createFactualFluencyItems } = useSelector(
    state => state.factualFluency
  );

  const { profileData } = useSelector(state => state.profileSettings);

  const timeType = getTimeType(assignmentType);

  const times = secondsToTime(timeLimit);

  const history = useHistory();
  const onClose = useCallback(() => {
    window.open('', '_self');
    window.close();
  }, []);

  useEffect(() => {
    const lang = localStorage.getItem('current_locale');
    i18n.changeLanguage(lang);
  }, [i18n]);

  const onClick = useCallback(() => {
    onMute();
    history.push('/factual-fluency/preview');
  }, [history, onMute]);
  return (
    <Container>
      <CloseButton type="button" className="btn btn-close-rt" onClick={onClose}>
        <i className="icon-koo-close2" />
      </CloseButton>
      <CenterMiddle>
        <div className="container">
          <h1 className="title-text text-center">{title}</h1>
          {/* <InfoGroup>
            <InfoLabel>Deadline</InfoLabel>
            <InfoDesc>Total 1 Min</InfoDesc>
          </InfoGroup> */}
          <InfoGroup>
            <InfoLabel>
              {t(`create-assign-question:readyFF.assignBy`, 'Assigned By')}
            </InfoLabel>
            <InfoDesc>{profileData.FullName}</InfoDesc>
          </InfoGroup>
          <InfoGroup>
            <InfoLabel>
              {t(`create-assign-question:readyFF.time`, 'Time')}
            </InfoLabel>
            <InfoDesc>
              {timeType.name}{' '}
              {timeType.value !== 6 && renderTimes(times, timeUnit)}
            </InfoDesc>
          </InfoGroup>
          <InfoGroup>
            <InfoLabel>
              {t(`create-assign-question:readyFF.noOfQn`, 'No. of Qns')}
            </InfoLabel>
            <InfoDesc>
              {createFactualFluencyItems.length}{' '}
              {t(`create-assign-question:readyFF.qn`, 'Qns')}
            </InfoDesc>
          </InfoGroup>
          <InfoGroupBtn>
            <button
              type="button"
              className="btn btn-primary btn-block btn-xlg"
              onClick={onClick}
            >
              {t(`create-assign-question:readyFF.ready`, 'Ready, Go!')}
            </button>
          </InfoGroupBtn>
        </div>
      </CenterMiddle>
    </Container>
  );
};

export default React.memo(FactualFluencyReady);
