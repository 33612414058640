import {
  FETCH_TOPICS_REQUEST,
  FETCH_TOPICS_SUCCESS,
  FETCH_TOPICS_FAILED,
  SELECTED_TOPIC,
  RESET_SELECTED_TOPIC,
  UNSELECT_TOPIC
} from './actionTypes';

export const fetchTopicsRequest = () => {
  return { type: FETCH_TOPICS_REQUEST };
};

export const fetchTopicsSuccess = topics => {
  return { type: FETCH_TOPICS_SUCCESS, topics };
};

export const fetchTopicsFailed = error => {
  return { type: FETCH_TOPICS_FAILED, error };
};

export const selectTopic = selectedTopic => {
  return { type: SELECTED_TOPIC, selectedTopic };
};

export const resetSelectedTopic = () => {
  return { type: RESET_SELECTED_TOPIC };
};

export const unSelectTopic = () => {
  return { type: UNSELECT_TOPIC };
};
