import styled from 'styled-components';

export const PrintAssignmentPage = styled.div`
  padding: 0 1rem;

  .top {
    display: flex;
    width: 100%;
    padding: 0 2rem;
    margin-bottom: 2rem;
    justify-content: flex-end;
  }

  .question-item-body,
  .media-body {
    border-right: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  @media all {
    .page-break {
      display: none;
    }
  }
  @media print {
    html,
    body {
      height: initial !important;
      overflow: visible !important;
      -webkit-print-color-adjust: exact;
    }
    .print-btn {
      display: none;
    }
  }

  @media print {
    .page-break {
      margin-bottom: 10mm;
      display: block;
      page-break-inside: auto;
    }
    .question-item-body {
      margin-top: 10mm;
      page-break-inside: avoid;
    }
  }
`;
