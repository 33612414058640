import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Linotte Regular';
  display: flex;
  flex-direction: column;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
`;

export const NoParticipant = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  background: #ffffff;
  width: 70%;
  height: 100%;
  padding: 20px 30px;
  text-align: center;
`;
