import React, { useState, useEffect } from 'react';
import TopBar from 'components/Report/TopBar';
import { useDispatch, useSelector } from 'react-redux';
import generateLevels from 'helpers/reportLevels';
import { fetchClassRequest } from 'stores/utils/lookup/actions';
import { fetchUserCurriculumsRequest } from 'stores/curriculums/actions';
import { isEmpty, isNil } from 'ramda';
import { setReportLevel } from 'stores/report/actions';
import {
  StyledSwitchContainer,
  StyledSwitch
} from './ProficiencyReport.styles';
import { ReportPage } from './ReportPage.styles';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import ProficiencyReportByStudent from './ProficiencyReportByStudent';
import ProficiencyReportBySkills from './ProficiencyReportBySkills';
const TYPES = [
  {
    label: 'By Students',
    value: 'student'
  },
  {
    label: 'By Skills',
    value: 'skill'
  }
];
const ProficiencyReport = ({ history }) => {
  const dispatch = useDispatch();

  const {
    selectedClass,
    sortOrder,
    reportLevel,
    selectedSyllabus,
    selectedClassLevel
  } = useSelector(state => state.reports);
  const { classes } = useSelector(state => state.lookup);
  const { curriculums, error } = useSelector(state => state.curriculums);
  const [type, setType] = useState(
    history.location.state?.type || TYPES[0].value
  );

  const isLeveless = isEmpty(selectedSyllabus?.Levels);

  const reportLevels = generateLevels();
  // Filter out only classes of type 1
  const filteredClasses =
    classes.length > 0
      ? classes.filter(classObject => classObject.Type === 1)
      : [];

  // UseEffect
  useEffect(() => {
    dispatch(fetchClassRequest(selectedClassLevel));
  }, [dispatch, selectedClassLevel]);
  useEffect(() => {
    if (error !== '') {
      dispatch(fetchUserCurriculumsRequest());
    }
  }, [dispatch, error]);
  useDeepCompareEffectNoCheck(() => {
    if (selectedSyllabus && isNil(reportLevel) && !isLeveless) {
      const firstLevel = selectedSyllabus.Levels[0];
      dispatch(setReportLevel(firstLevel));
    }
  }, [reportLevel, selectedSyllabus, isLeveless]);
  return (
    reportLevel && (
      <ReportPage>
        {reportLevel && (
          <TopBar
            type="proficiency"
            classes={filteredClasses}
            selectedClass={selectedClass}
            classLevels={reportLevels}
            reportLevels={!isLeveless ? selectedSyllabus.Levels : []}
            selectedLevel={reportLevel}
            curriculums={curriculums}
            selectedSyllabus={selectedSyllabus}
            sortOrder={sortOrder}
            selectedClassLevel={selectedClassLevel}
            detailType={type}
          />
        )}
        <StyledSwitchContainer>
          {TYPES.map(item => (
            <StyledSwitch
              key={item.value}
              active={item.value === type}
              onClick={() => setType(item.value)}
            >
              {item.label}
            </StyledSwitch>
          ))}
        </StyledSwitchContainer>
        <ProficiencyReportByStudent visible={type === TYPES[0].value} />
        <ProficiencyReportBySkills visible={type === TYPES[1].value} />
      </ReportPage>
    )
  );
};

export default ProficiencyReport;
