import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${({ num }) => (num === 0 ? 'hidden' : 'visible')};
  color: #484848;
  .correct-answer {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #75c649;
    margin-right: 4px;
  }
  img {
    width: 8px;
    height: 8px;
    margin-right: 4px;
  }
`;
