import { takeLatest, all } from 'redux-saga/effects';
import login, { logout, refreshToken } from 'sagas/login';
import startup from 'sagas/startup';
import APP_STARTUP from 'stores/startup/actionTypes';
import {
  LOGIN_REQUEST,
  LOGIN_RESET,
  LOGIN_SUCCESS,
  REFRESH_TOKEN_REQUEST
} from 'stores/login/actionTypes';
import {
  SELECTED_LEVEL,
  FETCH_VIDEOS_REQUEST
} from 'stores/levels/actionTypes';
import { SELECTED_TOPIC } from 'stores/topics/actionTypes';
import { SELECTED_SKILL } from 'stores/skills/actionTypes';
import {
  ADD_QUESTION_TO_CART,
  SELECTING_QUESTION,
  DELETE_QUESTION_FROM_CART,
  SEARCHING_QUESTIONS,
  ADD_QUESTIONS_TO_CART,
  RESET_CART_QUESTIONS
} from 'stores/questions/actionTypes';
import { refreshSkills } from 'sagas/skills';
import { callSelectedSkill, callGetVariations } from 'sagas/variations';
import { FETCH_SINGLE_VARIATION } from 'stores/variations/actionTypes';
import levels, { setDefaultLevel, getVideos } from 'sagas/levels';
import syllabus from 'sagas/syllabus';
import getQuestionDetail, {
  addQuestionToCart,
  fetchCartQuestions,
  removeQuestionFromCart,
  fetchSearchQuestions,
  addQuestionsToCart,
  resetCart
} from 'sagas/questions';
import {
  ASSIGN_WORKSHEET,
  SAVE_WORKSHEET,
  FETCH_WORKSHEETS_REQUEST,
  SELECTING_WORKSHEET,
  DELETE_WORKSHEET,
  SCHEDULE_WORKSHEET,
  GET_WORKSHEET_REQUEST
} from 'stores/worksheet/actionTypes';
import getWorksheetDetail, {
  assignWorksheet,
  saveWorksheet,
  fetchWorksheets,
  deleteWorksheet,
  scheduledWorksheet,
  getWorksheet
} from 'sagas/worksheet';

import {
  FETCH_CLASS_REQUEST,
  FETCH_STUDENTS_REQUEST,
  FETCH_STUDENTS_SEARCH_REQUEST,
  FETCH_MY_GROUP_REQUEST
} from 'stores/utils/lookup/actionTypes';
import {
  fetchClassByLevel,
  fetchStudentsByClass,
  fetchStudentsByKeyword,
  fetchMyGroups
} from 'sagas/lookup';
import {
  FETCH_ASSIGNMENTS_REQUEST,
  FETCH_TEACHERS_REQUEST,
  FETCH_TEACHER_ASSIGNMENTS_REQUEST,
  FETCH_MULTI_CLASS_REPORT_REQUEST,
  FETCH_SINGLE_CLASS_REPORT_REQUEST,
  DELETE_ASSIGNED_HW_REQUEST,
  MARK_ANSWER_RIGHT_REQUEST,
  FETCH_CSV_CLASS_REPORT_REQUEST,
  CHANGE_TITLE_DATE_REQUEST
} from 'stores/assignments/actionTypes';
import {
  fetchAssignments,
  fetchTeacherAssignments,
  fetchTeachers,
  fetchMultiClassReport,
  fetchSingleClassReport,
  deleteAssignedHw,
  markAnswerRight,
  fetchCSVClassReport,
  changeTitleDate
} from 'sagas/assignments';
import {
  FETCH_STUDENTS_DATA_REQUEST,
  FETCH_STUDENT_DETAILS_REQUEST,
  FETCH_CLASSES_REQUEST,
  SEARCH_STUDENT_REQUEST,
  CHANGE_CLASS_REQUEST,
  EDIT_CLASS_REQUEST,
  RESET_PW_REQUEST,
  FETCH_ICONS_REQUEST
} from 'stores/manage-class/actionTypes';
import {
  fetchStudents,
  fetchStudentDetails,
  fetchClasses,
  searchStudents,
  changeClass,
  editClass,
  resetPassword,
  fetchIcons
} from 'sagas/manage-class';
import {
  FETCH_CLASS_PROFICIENCY_REPORT_REQUEST,
  FETCH_CLASS_HIGHSCORE_REPORT_REQUEST,
  FETCH_STUDENT_PROFICIENCY_REPORT_REQUEST,
  FETCH_SAMPLE_QN_REQUEST,
  FETCH_LOGIN_REPORT_REQUEST,
  FETCH_WEEK_ITEMS_REQUEST,
  FETCH_TEACHER_REPORT_MONTH_REQUEST,
  FETCH_TEACHER_REPORT_SPECIFIC_MONTH_REQUEST,
  FETCH_REPORT_SYLLABUS_REQUEST,
  FETCH_REPORT_PROFICIENCY_SKILL_REQUEST,
  FETCH_REPORT_HIGHSCORE_SKILL_REQUEST,
  FETCH_SKILL_PROFICIENCY_DETAIL_REPORT_REQUEST,
  FETCH_SKILL_HIGHSCORE_DETAIL_REPORT_REQUEST
} from 'stores/report/actionTypes';
import {
  fetchClassProficiencyReport,
  fetchClassHighScoreReport,
  fetchStudentProficiencyReport,
  fetchSampleQn,
  fetchLoginReport,
  fetchWeekItems,
  fetchTeacherMonthReport,
  fetchTeacherSpecificMonthReport,
  fetchReportSyllabus,
  fetchProficiencySkillReport,
  fetchHighScoreSkillReport,
  fetchSkillProficiencyDetailReport,
  fetchSkillHighScoreDetailReport
} from 'sagas/reports';
import {
  SAVE_PRIVATE_GROUP,
  FETCH_PRIVATE_GROUPS_REQUEST
} from 'stores/privateGroup/actionTypes';
import { savePrivateGroup, fetchPrivateGroups } from 'sagas/privateGroup';
import { SELECTED_CURRICULUM } from 'stores/curriculums/actionTypes';
import {
  FETCH_PROFILE_DATA_REQUEST,
  FETCH_AVATARS_REQUEST,
  UPDATE_PROFILE_REQUEST
} from 'stores/profileSettings/actionTypes';
import {
  fetchProfileData,
  fetchAvatars,
  updateProfileData
} from 'sagas/profileSettings';
import {
  FETCH_MY_GROUPS_REQUEST,
  UPDATE_GROUP_REQUEST,
  DELETE_GROUP_REQUEST,
  CREATE_MY_GROUP_REQUEST,
  GET_CLASS_BY_LEVEL_REQUEST,
  GET_STUDENT_BY_CLASS_REQUEST
} from 'stores/createGroup/actionTypes';
import {
  fetchMyGroup,
  updateGroup,
  deleteGroupById,
  createGroupData,
  getClassesByLevel,
  getStudentsByClass
} from 'sagas/createGroup';
import {
  FETCH_SYLLABUS_REQUEST,
  PUSH_TO_CART_REQUEST,
  PUSH_TO_CART_SUCCESS,
  CREATE_WORKSHEET_REQUEST
} from 'stores/topical-worksheet/actionTypes';
import {
  fetchSyllabus,
  pushToCart,
  createWorkSheet
} from 'sagas/topical-worksheet';
import {
  CREATE_FACTUAL_FLUENCY_REQUEST,
  FETCH_FACTUAL_FLUENCY_LEADERBOARD_REQUEST,
  FETCH_TOPIC_SKILL_REQUEST
} from 'stores/factual-fluency/actionTypes';
import { SET_USER_PREFERENCES_REQUEST } from 'stores/userPreferences/actionTypes';
import {
  FETCH_GAME_SETTING_CLASS_REQUEST,
  FETCH_GAME_SETTING_STUDENT_REQUEST,
  SAVE_GAME_SETTING_REQUEST
} from 'stores/gameSetting/actionTypes';
import {
  createFactualFluency,
  fetchLeaderboard,
  fetchTopicSkill
} from './factual-fluency';
import setUserPreferences from './userPreferences';
import {
  fetchGameSettingClass,
  fetchGameSettingStudentByGroup,
  saveGameSetting
} from './gameAccessSetting';

export default function* root() {
  yield all([
    /**
     * @see https://redux-saga.js.org/docs/basics/UsingSagaHelpers.html
     */
    // Run the startup saga when the application starts
    takeLatest(ADD_QUESTION_TO_CART, addQuestionToCart),
    takeLatest(APP_STARTUP, startup),
    takeLatest(REFRESH_TOKEN_REQUEST, refreshToken),
    takeLatest(DELETE_QUESTION_FROM_CART, removeQuestionFromCart),
    takeLatest(SELECTED_SKILL, callSelectedSkill),
    takeLatest(FETCH_SINGLE_VARIATION, callGetVariations),
    // takeLatest(FETCH_SKILLS_SUCCESS, refreshVariations),
    takeLatest(LOGIN_REQUEST, login),
    takeLatest(LOGIN_RESET, logout),
    takeLatest(LOGIN_SUCCESS, fetchCartQuestions),
    takeLatest(LOGIN_SUCCESS, levels),
    // takeLatest(LOGIN_SUCCESS, fetchWorksheets),
    takeLatest(SELECTED_LEVEL, setDefaultLevel),
    takeLatest(FETCH_VIDEOS_REQUEST, getVideos),
    takeLatest(SELECTED_LEVEL, syllabus),
    takeLatest(SELECTED_TOPIC, refreshSkills),
    takeLatest(SELECTING_QUESTION, getQuestionDetail),
    takeLatest(SAVE_WORKSHEET, saveWorksheet),
    takeLatest(ASSIGN_WORKSHEET, assignWorksheet),
    takeLatest(FETCH_WORKSHEETS_REQUEST, fetchWorksheets),
    takeLatest(FETCH_CLASS_REQUEST, fetchClassByLevel),
    takeLatest(FETCH_STUDENTS_REQUEST, fetchStudentsByClass),
    takeLatest(FETCH_STUDENTS_SEARCH_REQUEST, fetchStudentsByKeyword),
    takeLatest(SAVE_PRIVATE_GROUP, savePrivateGroup),
    takeLatest(FETCH_PRIVATE_GROUPS_REQUEST, fetchPrivateGroups),
    takeLatest(SELECTED_CURRICULUM, syllabus),
    takeLatest(SEARCHING_QUESTIONS, fetchSearchQuestions),
    takeLatest(ADD_QUESTIONS_TO_CART, addQuestionsToCart),
    takeLatest(RESET_CART_QUESTIONS, resetCart),
    takeLatest(GET_WORKSHEET_REQUEST, getWorksheet),
    takeLatest(SELECTING_WORKSHEET, getWorksheetDetail),
    takeLatest(DELETE_WORKSHEET, deleteWorksheet),
    takeLatest(SCHEDULE_WORKSHEET, scheduledWorksheet),
    takeLatest(FETCH_ASSIGNMENTS_REQUEST, fetchAssignments),
    takeLatest(FETCH_TEACHER_ASSIGNMENTS_REQUEST, fetchTeacherAssignments),
    takeLatest(FETCH_TEACHERS_REQUEST, fetchTeachers),
    takeLatest(FETCH_MULTI_CLASS_REPORT_REQUEST, fetchMultiClassReport),
    takeLatest(FETCH_SINGLE_CLASS_REPORT_REQUEST, fetchSingleClassReport),
    takeLatest(DELETE_ASSIGNED_HW_REQUEST, deleteAssignedHw),
    takeLatest(MARK_ANSWER_RIGHT_REQUEST, markAnswerRight),
    takeLatest(FETCH_STUDENTS_DATA_REQUEST, fetchStudents),
    takeLatest(FETCH_STUDENT_DETAILS_REQUEST, fetchStudentDetails),
    takeLatest(FETCH_CLASSES_REQUEST, fetchClasses),
    takeLatest(SEARCH_STUDENT_REQUEST, searchStudents),
    takeLatest(CHANGE_CLASS_REQUEST, changeClass),
    takeLatest(EDIT_CLASS_REQUEST, editClass),
    takeLatest(RESET_PW_REQUEST, resetPassword),
    takeLatest(FETCH_ICONS_REQUEST, fetchIcons),
    takeLatest(
      FETCH_CLASS_PROFICIENCY_REPORT_REQUEST,
      fetchClassProficiencyReport
    ),
    takeLatest(FETCH_CLASS_HIGHSCORE_REPORT_REQUEST, fetchClassHighScoreReport),
    takeLatest(
      FETCH_STUDENT_PROFICIENCY_REPORT_REQUEST,
      fetchStudentProficiencyReport
    ),
    takeLatest(
      FETCH_REPORT_PROFICIENCY_SKILL_REQUEST,
      fetchProficiencySkillReport
    ),
    takeLatest(FETCH_REPORT_HIGHSCORE_SKILL_REQUEST, fetchHighScoreSkillReport),
    takeLatest(FETCH_REPORT_SYLLABUS_REQUEST, fetchReportSyllabus),
    takeLatest(FETCH_SAMPLE_QN_REQUEST, fetchSampleQn),
    takeLatest(FETCH_LOGIN_REPORT_REQUEST, fetchLoginReport),
    takeLatest(FETCH_WEEK_ITEMS_REQUEST, fetchWeekItems),
    takeLatest(FETCH_TEACHER_REPORT_MONTH_REQUEST, fetchTeacherMonthReport),
    takeLatest(
      FETCH_TEACHER_REPORT_SPECIFIC_MONTH_REQUEST,
      fetchTeacherSpecificMonthReport
    ),
    takeLatest(
      FETCH_SKILL_PROFICIENCY_DETAIL_REPORT_REQUEST,
      fetchSkillProficiencyDetailReport
    ),
    takeLatest(
      FETCH_SKILL_HIGHSCORE_DETAIL_REPORT_REQUEST,
      fetchSkillHighScoreDetailReport
    ),
    takeLatest(FETCH_PROFILE_DATA_REQUEST, fetchProfileData),
    takeLatest(FETCH_AVATARS_REQUEST, fetchAvatars),
    takeLatest(UPDATE_PROFILE_REQUEST, updateProfileData),
    takeLatest(FETCH_SAMPLE_QN_REQUEST, fetchSampleQn),
    takeLatest(FETCH_MY_GROUPS_REQUEST, fetchMyGroup),
    takeLatest(UPDATE_GROUP_REQUEST, updateGroup),
    takeLatest(DELETE_GROUP_REQUEST, deleteGroupById),
    takeLatest(CREATE_MY_GROUP_REQUEST, createGroupData),
    takeLatest(GET_CLASS_BY_LEVEL_REQUEST, getClassesByLevel),
    takeLatest(GET_STUDENT_BY_CLASS_REQUEST, getStudentsByClass),
    takeLatest(FETCH_SYLLABUS_REQUEST, fetchSyllabus),
    takeLatest(PUSH_TO_CART_REQUEST, pushToCart),
    takeLatest(PUSH_TO_CART_SUCCESS, fetchCartQuestions),
    takeLatest(CREATE_WORKSHEET_REQUEST, createWorkSheet),
    takeLatest(FETCH_MY_GROUP_REQUEST, fetchMyGroups),
    takeLatest(FETCH_CSV_CLASS_REPORT_REQUEST, fetchCSVClassReport),
    takeLatest(CHANGE_TITLE_DATE_REQUEST, changeTitleDate),
    // factual-fluency
    takeLatest(FETCH_TOPIC_SKILL_REQUEST, fetchTopicSkill),
    takeLatest(CREATE_FACTUAL_FLUENCY_REQUEST, createFactualFluency),
    takeLatest(FETCH_FACTUAL_FLUENCY_LEADERBOARD_REQUEST, fetchLeaderboard),
    takeLatest(SET_USER_PREFERENCES_REQUEST, setUserPreferences),
    // game-setting
    takeLatest(FETCH_GAME_SETTING_CLASS_REQUEST, fetchGameSettingClass),
    takeLatest(
      FETCH_GAME_SETTING_STUDENT_REQUEST,
      fetchGameSettingStudentByGroup
    ),
    takeLatest(SAVE_GAME_SETTING_REQUEST, saveGameSetting)
  ]);
}
