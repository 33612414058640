import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setPresentationType } from 'stores/worksheet/actions';
import HeaderBox from 'components/CreateAndAssign/HeaderBox';
import NavigationWizard from 'components/CreateAndAssign/NavigationWizard';
import ChooseWorkSheetType from 'components/CreateAndAssign/ChooseWorkSheetType';
import { Col } from 'react-bootstrap';
import { presentationTypes } from 'constants/index';
import { nextStep } from 'stores/utils/step/actions';
import isNilOrEmpty from 'helpers/isNilOrEmpty';
import ButtonStepPrev from 'components/Shared/ButtonStepPrev';

export default function WorksheetPreviewNew() {
  const { t } = useTranslation(['create-assign-question']);
  const dispatch = useDispatch();
  const { presentationType } = useSelector(state => state.worksheet);

  const presentationTypeID = isNilOrEmpty(presentationType)
    ? presentationTypes.standard
    : parseInt(presentationType, 10);

  useEffect(() => {
    dispatch(setPresentationType(presentationTypeID));
  }, [presentationTypeID, dispatch]);
  const handleOnNext = () => {
    dispatch(nextStep());
    dispatch(setPresentationType(presentationTypeID));
  };

  return (
    <div className="topical-preview">
      <HeaderBox>
        <Col style={{ display: 'flex' }} xl={7} md={6} sm={12}>
          <div style={{ width: '70px' }}>
            <ButtonStepPrev
              title={t(`create-assign-question:editWo.back`, '< Back')}
            />
          </div>
          <div className="align-items-center justify-content-center worksheet-preview-text">
            <h3>
              {t(
                `create-assign-question:preview.chooseWoType`,
                'Choose Worksheet Type'
              )}
            </h3>
            <p>
              {t(
                `create-assign-question:preview.chooseWo1`,
                'We provide 3 worksheet types: '
              )}
              <b>
                {t(
                  `create-assign-question:preview.chooseWo2`,
                  'Homework, Mock Test and Test/Assessment.'
                )}
              </b>{' '}
              <br />
              {t(
                `create-assign-question:preview.chooseWo3`,
                'You can choose one of them to present your worksheet.'
              )}
            </p>
          </div>
        </Col>
        <NavigationWizard onClick={handleOnNext} />
      </HeaderBox>
      <div className="box-white box-white-wrap question-cart-list worksheet-preview-content">
        <ChooseWorkSheetType />
      </div>
    </div>
  );
}
