// select
export const SET_START_END_DATE = '@ASSIGN/SET_START_END_DATE';
export const SET_RELEASE_DATE = '@ASSIGN/SET_RELEASE_DATE';
export const SET_KOKO_CREDIT = '@ASSIGN/SET_KOKO_CREDIT';

export const ASSIGN_CLASS = '@ASSIGN/ASSIGN_CLASS';
export const ASSIGN_GROUP = '@ASSIGN/ASSIGN_GROUP';
export const ASSIGN_STUDENT = '@ASSIGN/ASSIGN_STUDENT';
export const ASSIGN_PRIVATE_GROUP = '@ASSIGN/ASSIGN_PRIVATE_GROUP';
export const ASSIGN_ADMIN_GROUP = '@ASSIGN/ASSIGN_ADMIN_GROUP';
export const ASSIGN_LEVEL = '@ASSIGN/ASSIGN_LEVEL';
export const RESET_ASSIGN = '@ASSIGN/RESET_ASSIGN';
export const RESET_ASSIGNMENT = '@ASSIGN/RESET_ASSIGNMENT';
