import styled, { css } from 'styled-components';
import { proficiencyColors, masteryColors } from 'constants/index';

export const Content = styled.div`
  /* margin-top: 7rem; */
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem 5% 2.5rem 10%;
  border-bottom: ${props => !props.isLast && '1px solid #e9e9e9'};
  table {
    width: 100%;

    td {
      width: 50%;
    }
  }
  .incomplete-desc {
    margin-bottom: 0.4rem;
    color: #ff0303;
    font-size: 0.625rem;
    position: relative;
    left: 50%;
  }
`;

export const TableTitle = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 0.625rem;
  font-family: 'Nunito';
  p {
    margin-bottom: 0;
  }
  .student-count {
    font-size: 1.75rem;
    font-weight: 700;
    margin-right: 0.5rem;
    color: #363636;
    line-height: 1.2rem;
  }
  .student-detail {
    color: #363636;
  }
`;

export const TableContent = styled.table`
  margin-top: ${({ sortBtn }) => !sortBtn && '0.8rem'};
  tr:nth-child(odd) {
    background: ${({ level, report }) =>
      report === 'proficiency'
        ? proficiencyColors[level].tableOddColor
        : masteryColors[level].tableOddColor};
  }

  tr:nth-child(even) {
    background: ${({ level, report }) =>
      report === 'proficiency'
        ? proficiencyColors[level].tableEvenColor
        : masteryColors[level].tableEvenColor};
  }

  td {
    border: solid 1px
      ${({ level, report }) =>
        report === 'proficiency'
          ? proficiencyColors[level].tableBorderColor
          : masteryColors[level].tableBorderColor};
    padding: 0.5rem 0.8rem;
    font-weight: 700;
    font-family: 'Nunito';
    li {
      font-weight: 600;
      font-family: 'Muli';
    }
  }

  .percentage {
    font-weight: 700;
    color: ${({ level }) => level === 'incomplete' && '#CCCCCC'};
  }
`;

export const ProficiencySkills = styled.div`
  display: flex;
  align-items: baseline;
  margin-left: 15px;
  margin-right: 15px;
  .icon {
    width: 13px;
    height: 13px;
    background: ${props => proficiencyColors[props.proficiency].color};
    border-radius: 3px;
    border: solid 1px
      ${props => proficiencyColors[props.proficiency].borderColor};
  }
  .prof-name {
    font-family: 'Nunito';
    font-weight: 700;
    text-transform: capitalize;
    color: #363636;
    font-size: 0.625rem;
    margin-left: 0.5rem;
  }
  .prof-descricption {
    font-size: 0.5rem;
    display: flex;
    align-items: baseline;
    color: #727272;
    margin-left: 0.25rem;
    span {
      font-weight: 700;
      margin-left: 0.2rem;
    }
  }
  @media screen and (max-width: 895px) {
    margin-right: 0 !important;
  }
`;

export const MasterySkills = styled.div`
  display: flex;
  align-items: flex-end;
  img {
    margin: 0 0.5rem 0 1rem;
    width: 35px;
  }
  .skill-detail {
    color: #727272;
    span {
      font-weight: 700;
    }
  }
`;

export const SortButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e9e9e9;
  border-bottom: none;
  width: 80px;
  position: relative;
  left: 50%;
  margin-top: -0.8rem;

  @media (max-width: 600px) {
    margin-top: 0.5rem;
  }
`;
export const SortButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 50%;
  padding: 8px 0;
  background: ${({ active }) => (active ? '#9A9A9A' : 'white')};
  svg {
    path {
      fill: ${({ active }) => (active ? 'white' : '#DBDBDB')};
    }
  }
  ${({ type }) =>
    type === 'asc' &&
    css`
      border-left: 1px solid #e9e9e9;
      svg {
        transform: rotate(180deg);
      }
    `}
`;
