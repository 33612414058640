import styled from 'styled-components';

export const StudentDetailsModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #0a0a0a;
  font-size: 0.875rem;
  .student-details-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 1rem;
    border-bottom: solid 1px rgba(112, 112, 112, 0.39);
    .student-details-name {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      span {
        margin-left: 0.5rem;
        color: #363636;
      }
    }
    .student-details-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      margin-top: 0.5rem;
      @media (max-width: 975px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .student-details-field {
        display: flex;
        flex-direction: row;
        margin-right: 1rem;
        margin-top: 1rem;
      }
    }
  }
  .placeholder-title {
    font-size: 0.75rem;
    color: rgba(10, 10, 10, 0.39);
    margin-right: 1rem;
    text-align: right;
  }
  .field-group-1 {
    width: 150px;
  }
  .field-group-2 {
    width: 100px;
  }
  .group-1 {
    width: 50px;
    @media (max-width: 1400px) {
      text-align: left;
    }
    @media (max-width: 975px) {
      width: initial;
    }
  }
  .group-2 {
    width: 100px;
    @media (max-width: 975px) {
      text-align: left;
      width: initial;
    }
  }
  .group-3 {
    width: 100px;
    @media (max-width: 1400px) {
      text-align: left;
    }
    @media (max-width: 975px) {
      width: initial;
    }
  }
  .parent-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 1rem;
    border-bottom: solid 1px rgba(112, 112, 112, 0.39);
    .parent-details-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 1rem;
      flex-wrap: wrap;
      .parent-details-field {
        margin-right: 1rem;
        width: 30%;
        .parent-detail-email {
          color: #ff5b00;
          text-decoration: underline;
        }
      }
    }
  }
  .selection-action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 1rem;
    width: 100%;
    .btn-ok {
      width: 127px;
      color: #fffdfc;
      background: #ff5b00;
    }
  }
`;
