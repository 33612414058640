import styled from 'styled-components';
import { LevelHoverColors } from 'constants/index';

export const CardContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
  margin-bottom: 40px;
  margin-top: 10px;
  margin-right: 100px;
  border: solid 5px rgba(0, 0, 0, 0);
  :hover {
    border: ${props => `solid 5px ${LevelHoverColors[props.level]}`};
  }
  @media only screen and (max-width: 1024px) {
    margin-right: 90px;
  }
`;

export const CardTopSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
`;
