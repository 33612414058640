import styled from 'styled-components';

const DeleteModalBodyContnainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 550px;
  background: white;
  padding: 50px;
  align-items: center;
  .close-btn {
    position: absolute;
    right: 35px;
    top: 30px;
    border: none;
    font-size: 18px;
    background: none;
  }
  .title {
    color: #ff5b00;
    font-size: 18px;
    margin: 10px;
  }
  .text {
    color: #060606;
    margin: 10px 10px 30px 10px;
    font-size: 16px;
  }
  .btn-group {
    margin: 10px;
    button {
      width: 180px;
      padding: 8px;
      border-radius: 2px;
      font-size: 16px;
      margin: 0px 20px;
    }
    .no-btn {
      background: white;
      color: #ff5b00;
      border: 1px solid #d5d5d5;
      &:hover {
        background: #FFF7F3
        color: #f25008;
        border-color: #f25008;
      }
    }
    .yes-btn {
      background: #ff5b00;
      color: white;
      border: 1px solid #ff3d3d;
      &:hover {
        background: #f25008;
      }
    }
  }
  span {
    font-size: 16px;
  }
  .success-title {
    font-size: 18px;
  }
  .success-btn {
    width: 180px;
      padding: 8px;
      border-radius: 2px;
      font-size: 16px;
      margin: 0px 20px;
      background: #5ED46A;
      color: white;
      border: 1px solid #29C16A;
  }
`;

export default DeleteModalBodyContnainer;
