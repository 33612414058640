import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 100px;
  font-family: 'Linotte Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;

  .wrap-button-right {
    width: 135px;
    margin: 0;

    .single-btn {
      width: 100%;
    }
  }
`;

export const NavTitle = styled.div`
  font-size: 46px;
  font-weight: 700;
  padding-left: 135px;
  margin: 0 auto;

  span {
    font-size: 60px;
    text-align: center;
  }
`;

export const ResultBody = styled.div`
  font-weight: 700;
  display: table;
  margin: 0 auto;
`;
export const QuestionWrap = styled.div`
  margin-top: 20px;
  padding-right: 0px;
  display: table-row;

  .question-number {
    display: table-cell;
    vertical-align: middle;
    padding-right: 50px;
    font-size: 44px;
    color: #e06400;
  }

  .question-wrap {
    display: table-cell;
    vertical-align: middle;
    padding-right: 30px;

    .question-text {
      font-size: 54px;
      margin-right: 0;
      text-align: right;
      line-height: 1;
      color: #212529;

      &.answer {
        margin-left: 20px;
        color: #064da4;
      }
    }
  }
`;
