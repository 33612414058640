import React from 'react';
import { useDispatch } from 'react-redux';
import { prevStep } from 'stores/utils/step/actions';

export default function ButtonStepPrev(props) {
  const { title } = props;

  const dispatch = useDispatch();
  const handlePrevStep = () => {
    dispatch(prevStep());
  };
  return (
    <button
      type="button"
      onClick={handlePrevStep}
      className="btn btn-link question-cart-content__back"
    >
      {title}
    </button>
  );
}
