// select
export const SAVE_PRIVATE_GROUP = 'SAVE_PRIVATE_GROUP';
export const DELETE_PRIVATE_GROUP = 'DELETE_PRIVATE_GROUP';

export const FETCH_PRIVATE_GROUPS_FAILED = 'FETCH_PRIVATE_GROUPS_FAILED';
export const FETCH_PRIVATE_GROUPS_REQUEST = 'FETCH_PRIVATE_GROUPS_REQUEST';
export const FETCH_PRIVATE_GROUPS_SUCCESS = 'FETCH_PRIVATE_GROUPS_SUCCESS';

export const FETCH_PRIVATE_GROUP_SUCCESS = 'FETCH_PRIVATE_GROUP_SUCCESS';
export const FETCH_PRIVATE_GROUP_FAILED = 'FETCH_PRIVATE_GROUPS_FAILED';
export const FETCH_PRIVATE_GROUP_REQUEST = 'FETCH_PRIVATE_GROUPS_REQUEST';

export const SELECT_STUDENTS = 'SELECT_STUDENTS';
