import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import HeaderBox from 'components/CreateAndAssign/HeaderBox';
import WorksheetAssignTitle from 'components/CreateAndAssign/QuestionCart/WorksheetAssignTitle';
import NewBorder from 'components/Shared/NewBorder';
import QuestionHeader from 'components/Question/QuestionHeader';
import QuestionContent from 'components/Question/QuestionContent';
import AssignSidebar from 'components/CreateAndAssign/AssignSidebar';
import iconFactualFluency from 'assets/img/icon-factual-fluency.png';
import { getTimeType, renderTimes, secondsToTime } from 'helpers/timesParser';
import {
  AssignContent,
  FatualFluencyInfo
} from './AssignSavedFactualFluency.styles';

const AssignSavedFactualFluency = ({ method }) => {
  const { t } = useTranslation(['create-assign-question']);
  const { title, assignmentType, timeLimit } = useSelector(
    state => state.worksheet
  );

  const { createFactualFluencyItems } = useSelector(
    state => state.factualFluency
  );

  const onPreview = useCallback(() => {
    const win = window.open(`/factual-fluency/ready`, '_blank');
    if (win != null) {
      win.focus();
    }
  }, []);

  const timeType = getTimeType(assignmentType);

  const times = secondsToTime(timeLimit);

  const renderInfo = (time, times) => {
    const translation = name => {
      switch (name) {
        case 'Interval Time':
          return 'intervalTime';
        case 'Do not time me':
          return 'noTime';
        case 'Total Time':
          return 'totalTime';
        default:
          return '';
      }
    };
    const timeUnit = unit => {
      return `${t(`create-assign-question:timeSetting.${unit}`, unit)}`;
    };

    if (time.value === 6) {
      return (
        <div>
          {t(
            `create-assign-question:timeSetting.${translation(time.name)}`,
            time.name
          )}
        </div>
      );
    }

    return (
      <div>
        {t(
          `create-assign-question:timeSetting.${translation(time.name)}`,
          time.name
        )}
        : <span>{renderTimes(times, timeUnit)}</span>
      </div>
    );
  };

  return (
    <>
      <AssignContent>
        <HeaderBox>
          <WorksheetAssignTitle
            icon={iconFactualFluency}
            title={title}
            totalQuestions={createFactualFluencyItems.length}
            onPreview={onPreview}
          />
        </HeaderBox>
        <div className="box-white p-3 assign-content">
          <FatualFluencyInfo>
            {renderInfo(timeType, times)}
            <div>
              {t('create-assign-question:timeSetting.noOfQn', 'No.of Qn :')}
              <span>
                {createFactualFluencyItems.length}{' '}
                {t('create-assign-question:timeSetting.qn', 'Qn')}
              </span>
            </div>
          </FatualFluencyInfo>
          <NewBorder />

          {createFactualFluencyItems.map((question, key) => {
            const order = key + 1;
            return (
              <>
                <div
                  className="d-flex flex-row"
                  key={`question-factual-${order}`}
                >
                  <QuestionHeader order={order} score={2} />
                  <QuestionContent
                    isFactualFluencyView
                    question={question.CartQuestion}
                  />
                </div>
                <NewBorder />
              </>
            );
          })}
        </div>
      </AssignContent>
      <AssignSidebar isCreateFactualFluency />
    </>
  );
};

export default React.memo(AssignSavedFactualFluency);
