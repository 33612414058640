import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const StyledModal = styled(Modal)`
  .modal-custom {
    min-width: 30%;
    .modal-header-custom {
      border-bottom: none;
      z-index: 100;
    }
  }

  .modal-custom-large {
    min-width: 60%;
    .modal-header-custom {
      border-bottom: none;
    }
  }
`;
