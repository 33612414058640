import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import useDeepCompareEffect from 'use-deep-compare-effect';
import cloneDeep from 'lodash.clonedeep';

import { compareValues } from 'helpers/singleClassReport';
import { ProficiencyStatus, proficiencyColors } from 'constants/index';
import calculatePercentageString from 'helpers/calculatePercentageString';
import {
  fetchSkillProficiencyDetailReportRequest,
  fetchProficiencySkillReportRequest,
  setReportLevel
} from 'stores/report/actions';

import Spinner from 'components/Shared/Spinner';
import SkillTopbar from 'components/Report/SkillReportDetail/SkillTopbar';
import SkillDetailContent from 'components/Report/SkillReportDetail/SkillDetailContent';
import NoReport from 'components/Report/NoReport';

import SkillDetailPage from './SkillProficiencyReport.styles';

const SkillProficiencyReport = () => {
  const { t } = useTranslation(['proficiency-report']);
  const history = useHistory();
  const dispatch = useDispatch();
  const { topicID, skillID } = useParams();
  const {
    selectedClass,
    reportLevel,
    selectedSyllabus,
    skillDetailReport,
    proficiencySkill
  } = useSelector(state => state.reports);
  const [sortDetail, setSortDetail] = useState(null);
  const pageLoading =
    proficiencySkill.isLoading || skillDetailReport?.isLoading;
  useEffect(() => {
    dispatch(
      fetchSkillProficiencyDetailReportRequest({
        classID: selectedClass.ID,
        reportLevel: reportLevel?.ID,
        curriculumID: selectedSyllabus.CurriculumID,
        topicID,
        skillID
      })
    );
    dispatch(
      fetchProficiencySkillReportRequest({
        classID: selectedClass.ID,
        reportLevel: reportLevel?.ID,
        curriculumID: selectedSyllabus.CurriculumID,
        topicID
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, skillID, reportLevel]);
  useDeepCompareEffect(() => {
    if (selectedSyllabus && isNil(reportLevel) && selectedSyllabus.Levels) {
      const firstLevel = selectedSyllabus.Levels[0];
      dispatch(setReportLevel(firstLevel));
    }
  }, [reportLevel, selectedSyllabus]);
  const progressbarNumber = data => {
    const progressBarInput = [
      {
        percentage: '',
        color: proficiencyColors.mastery.color,
        borderColor: proficiencyColors.mastery.borderColor
      },
      {
        percentage: '',
        color: proficiencyColors.competent.color,
        borderColor: proficiencyColors.competent.borderColor
      },
      {
        percentage: '',
        color: proficiencyColors.beginning.color,
        borderColor: proficiencyColors.beginning.borderColor
      },
      {
        percentage: '',
        color: proficiencyColors.incomplete.color,
        borderColor: proficiencyColors.incomplete.borderColor
      }
    ];
    const proficiencyIndex = {
      Incomplete: 3,
      NeedImprove: 2,
      Passed: 1,
      Mastered: 0
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const proficiency of Object.keys(data?.CompetencyDetails)) {
      if (data?.CompetencyDetails[proficiency] > 0) {
        // Calculate percentage string
        const percentageString = calculatePercentageString(
          data?.CompetencyDetails[proficiency],
          data?.TotalNoOfStudents
        );
        progressBarInput[
          proficiencyIndex[proficiency]
        ].percentage = percentageString;
      } else {
        progressBarInput[proficiencyIndex[proficiency]].percentage = '0%';
      }
    }
    return progressBarInput.filter(
      proficiency => proficiency.percentage !== '0%'
    );
  };

  // note: proficiency from backend is always sorted desc
  const handleSortClick = ({ level, order }) => {
    setSortDetail(prev => {
      if (prev) {
        return prev.level === level && prev.order === order
          ? null
          : { level, order };
      }
      return { level, order };
    });
  };

  const parseProfSkill = [];

  for (let i = 0; i <= 4; i += 1) {
    if (i !== 2) {
      const findItem = skillDetailReport?.data?.findIndex(
        item => item.CompetencyLevel === i
      );
      if (findItem !== -1) {
        parseProfSkill.push(
          skillDetailReport?.data && skillDetailReport?.data[findItem]
        );
      } else {
        parseProfSkill.push({ Counts: 0, CompetencyLevel: i, Data: [] });
      }
    }
  }

  const sorted = useCallback(() => {
    const clonedData = cloneDeep(parseProfSkill);
    const result = [];
    if (!isNil(sortDetail)) {
      clonedData.forEach(item => {
        if (item.CompetencyLevel === sortDetail?.level) {
          item.Data.sort(compareValues('Proficiency', sortDetail?.order));
          result.push(item);
        } else {
          result.push(item);
        }
      });
      return result.sort(compareValues('CompetencyLevel', 'desc'));
    }
    return parseProfSkill.sort(compareValues('CompetencyLevel', 'desc'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDetail, skillDetailReport?.data]);

  const skillData = proficiencySkill?.data?.find(
    skill => skill.SkillId === parseInt(skillID, 10)
  );

  return (
    <SkillDetailPage>
      {pageLoading && <Spinner />}
      {!pageLoading && !isNil(skillDetailReport?.error) && (
        <NoReport
          message={t(
            `proficiency-report:noReports.skillDesc`,
            'Select another skill to show reports.'
          )}
        />
      )}
      {!pageLoading && skillData && (
        <SkillTopbar
          type="proficiency"
          item={skillData}
          skillList={proficiencySkill.data}
          progressBar={progressbarNumber(skillData)}
          back={() => history.push('/report', { type: 'skill' })}
          setSortDetail={setSortDetail}
        />
      )}

      <div className="content-detail">
        {!pageLoading &&
          skillDetailReport?.data &&
          sorted().map(data => (
            <SkillDetailContent
              key={data.CompetencyLevel}
              data={data}
              reportType="proficiency"
              isLast={ProficiencyStatus[data.CompetencyLevel] === 'incomplete'}
              descActive={
                sortDetail?.level === data.CompetencyLevel &&
                sortDetail?.order === 'desc'
              }
              descSortHandler={() => {
                handleSortClick({
                  level: data.CompetencyLevel,
                  order: 'desc'
                });
              }}
              ascActive={
                sortDetail?.level === data.CompetencyLevel &&
                sortDetail?.order === 'asc'
              }
              ascSortHandler={() => {
                handleSortClick({
                  level: data.CompetencyLevel,
                  order: 'asc'
                });
              }}
              isSorting
              level={ProficiencyStatus[data.CompetencyLevel]}
            />
          ))}
      </div>
    </SkillDetailPage>
  );
};

export default SkillProficiencyReport;
