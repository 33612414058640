import styled from 'styled-components';

const GameSettingStudentTable = styled.div`
  display: grid;
  margin-top: 60px;
  margin-right: 20px;
  width: 100%;
  @media only screen and (max-width: 800px) {
    margin-top: 60px;
  }
  .table {
    tbody {
      height: 200px;
      overflow-y: auto;
      width: 100%;
    }
    td {
      vertical-align: middle;
      @media only screen and (max-width: 768px) {
        padding: 0px;
      }
    }
    .table-head {
      th {
        color: rgba($color: #0a0a0a, $alpha: 0.42);
        font-size: 14px;
        font-weight: 100;
        vertical-align: middle;
        text-align: center;
        border-bottom: none;
        @media only screen and (max-width: 1260px) {
          font-size: 12px;
        }
        @media only screen and (max-width: 1132px) {
          font-size: 10px;
        }
        @media only screen and (max-width: 768px) {
          font-size: 6px;
        }
        @media only screen and (max-width: 587px) {
          font-size: 4px;
        }
        span {
          display: flex;
          justify-content: center;
        }
      }
    }
    .table-row {
      font-size: 14px;
      padding: 12px 5px;
      text-align: center;
      @media only screen and (max-width: 1132px) {
        font-size: 12px;
      }
      @media only screen and (max-width: 587px) {
        font-size: 4px;
      }
      .plan {
        width: 1%;
        font-size: 11px;
        text-align: center;
        @media only screen and (max-width: 800px) {
          font-size: 8px;
        }
        @media only screen and (max-width: 587px) {
          font-size: 4px;
        }
        img {
          height: 22px;
          width: 22px;
          @media only screen and (max-width: 800px) {
            height: 0px;
            width: 0px;
          }
        }
      }
      &--student {
        font-weight: 600;
        text-align: left;
        @media only screen and (max-width: 800px) {
          font-size: 8px;
        }
        @media only screen and (max-width: 587px) {
          font-size: 4px;
        }
        img {
          margin-right: 10px;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          @media only screen and (max-width: 800px) {
            height: 20px;
            width: 20px;
            margin-left: 5px;
            margin-right: 5px;
          }
          @media only screen and (max-width: 639px) {
            height: 0px;
            width: 0px;
            margin-left: 3px;
            margin-right: 0px;
          }
        }
      }
    }
    //the container
    .container-checkbox {
      position: relative;
      display: flex;
      align-items: center;
    }
    //hide the browser's default check-box
    .container-checkbox input {
      opacity: 0;
      cursor: pointer;
    }
    .container-checkbox label {
      margin-bottom: 0;
      margin-left: 35px;
      @media only screen and (max-width: 800px) {
        font-size: 8px;
      }
      @media only screen and (max-width: 587px) {
        font-size: 4px;
      }
    }
    //create a custom checkbox
    .container-checkbox .checkmark {
      position: absolute;
      height: 25px;
      width: 25px;
      border-radius: 4px;
      border: 1px solid #9e9e9e;
      background-color: white;
      @media only screen and (max-width: 1132px) {
        height: 17px;
        width: 17px;
      }
      @media only screen and (max-width: 768px) {
        height: 18px;
        width: 18px;
      }
      @media only screen and (max-width: 587px) {
        height: 10px;
        width: 10px;
      }
    }
    //on mouse hover
    .container-checkbox:hover input ~ .checkmark {
      background-color: #ffc400;
      border: none;
    }
    //when checkbox is checked
    .container-checkbox input:checked ~ .checkmark {
      background-color: #ef7836;
      border: none;
    }
    //create the checkmark/indicator (hidden when not checked)
    .container-checkbox .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }
    //show the checkmark when checked
    .container-checkbox input:checked ~ .checkmark:after {
      display: block;
    }
    //style the checkmark/indicator
    .container-checkbox .checkmark:after {
      left: 8px;
      top: 2px;
      width: 8px;
      height: 16px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      @media only screen and (max-width: 1132px) {
        width: 6px;
        height: 10px;
        border-width: 0 2px 2px 0;
        left: 6px;
      }
      @media only screen and (max-width: 768px) {
        width: 6px;
        height: 10px;
        border-width: 0 2px 2px 0;
        left: 6px;
      }
      @media only screen and (max-width: 587px) {
        width: 4px;
        height: 6px;
        border-width: 0 1.5px 1.5px 0;
        left: 3px;
        top: 1px;
      }
    }
  }
`;

export default GameSettingStudentTable;
