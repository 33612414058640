import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'ramda';
import { fetchTeachersRequest } from 'stores/assignments/actions';
import splash from 'assets/img/other-assignments-splash.svg';
import DropdownMenu from 'components/Shared/DropdownMenu';
import { OtherAssignmentsPage } from './OtherAssignments.styles';

export default function OtherAssignments() {
  const { t } = useTranslation(['track-assignment-assigned-others']);
  const dispatch = useDispatch();
  const history = useHistory();
  const { teachers } = useSelector(state => state.assignments);
  const [selectedTeacher, setSelectedTeacher] = useState(
    teachers.length > 0
      ? teachers[0]
      : {
          FullName: t(
            `track-assignment-assigned-others:main.selectTeacher`,
            'Select a teacher'
          )
        }
  );

  const handleShowButton = () => {
    history.push(`/track-assignment/others/${selectedTeacher.ID}`);
  };

  // Fetch teachers data
  useEffect(() => {
    dispatch(fetchTeachersRequest());
  }, [dispatch]);
  return (
    <OtherAssignmentsPage>
      <img src={splash} alt="Assigned by others" />
      <h1 className="text-center" style={{ marginBottom: '1rem' }}>
        <b>
          {t(
            `track-assignment-assigned-others:main.browseAssign`,
            'Browse assignments created by'
          )}
        </b>
      </h1>
      <DropdownMenu
        selectedValue={selectedTeacher.FullName}
        values={teachers}
        valueKey="FullName"
        setValue={setSelectedTeacher}
        width="max-content"
      />
      <Button
        variant="primary"
        size="show-button"
        onClick={handleShowButton}
        style={{ marginTop: '1rem' }}
        disabled={
          isEmpty(teachers) ||
          selectedTeacher.FullName ===
            t(
              `track-assignment-assigned-others:main.selectTeacher`,
              'Select a teacher'
            )
        }
      >
        {t(`track-assignment-assigned-others:main.show`, 'Show')}
      </Button>
    </OtherAssignmentsPage>
  );
}
