export const FETCH_GAME_SETTING_CLASS_REQUEST =
  'FETCH_GAME_SETTING_CLASS_REQUEST';
export const FETCH_GAME_SETTING_CLASS_SUCCESS =
  'FETCH_GAME_SETTING_CLASS_SUCCESS';
export const FETCH_GAME_SETTING_CLASS_FAILED =
  'FETCH_GAME_SETTING_CLASS_FAILED';
export const FETCH_GAME_SETTING_STUDENT_REQUEST =
  'FETCH_GAME_SETTING_STUDENT_REQUEST';
export const FETCH_GAME_SETTING_STUDENT_SUCCESS =
  'FETCH_GAME_SETTING_STUDENT_SUCCESS';
export const FETCH_GAME_SETTING_STUDENT_FAILED =
  'FETCH_GAME_SETTING_STUDENT_FAILED';
export const SAVE_GAME_SETTING_REQUEST = 'SAVE_GAME_SETTING_REQUEST';
export const SAVE_GAME_SETTING_SUCCESS = 'SAVE_GAME_SETTING_SUCCESS';
export const SAVE_GAME_SETTING_FAILED = 'SAVE_GAME_SETTING_FAILED';
export const RESET_ERROR = 'RESET_ERROR';
