import styled from 'styled-components';

export const WorksheetTypeStyled = styled.div`
  display: flex;
  flex-flow: column;
  .worksheet-type-info {
    margin: auto;
  }
  .worksheet-type-options {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 25px;
  }
  .worksheet-type-choice {
    display: flex;
    .worksheet-type-icon {
      height: 31px;
      width: 31px;
      border-radius: 50%;
      text-align: center;
      color: white;
      padding: 2px;
      font-family: Helvetica;
      font-size: 19px;
    }
    .form-check {
      padding: 8px;
      color: #000000;
    }
  }
  .types-label {
    @media screen and (max-width: 562px) {
      font-size: 12px;
    }
  }
  //hide the browser's default radio
  .form-check input {
    opacity: 0;
    cursor: pointer;
  }
  //create a custom radio
  .form-check .custom-radio {
    position: absolute;
    left: 3px;
    height: 18px;
    width: 18px;
    border-radius: 50px;
    border: 1px solid #9e9e9e;
    background-color: white;
    pointer-events: none;
  }
  //when radio is checked
  .form-check input:checked ~ .custom-radio {
    background-color: none;
    border: 1px solid #deab71;
  }
  //create the indicator (hidden when not checked)
  .form-check .custom-radio:after {
    content: '';
    position: absolute;
    display: none;
  }
  //show the checkmark when checked
  .form-check input:checked ~ .custom-radio:after {
    display: block;
  }
  //style the indicator
  .form-check .custom-radio:after {
    margin: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50px;
    background-color: #ffa640;
  }
`;
