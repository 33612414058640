import styled from 'styled-components';

export const CreateGroupLevelWrapper = styled.div`
  width: 30%;
  border-right: 1px solid #c4c4c4;
  height: calc(100vh - 160px);
  overflow: auto;
  background-color: #fbfbfb;
  .group-select-level {
    margin: 20px;
    button {
      background-color: #ffffff;
      width: 95% !important;
    }
  }
  .group-select-class {
    margin: 10px 0;
    div {
      font-weight: 600;
      font-size: 16px;
      padding: 10px 30px;
      cursor: pointer;
      position: relative;
    }
    .select-class-img {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }
`;
