import React from 'react';
import { SpinnerContainer } from './Spinner.styles';

const Spinner = ({ top, position, gridColumn, small, styles }) => (
  <SpinnerContainer
    top={top}
    position={position}
    gridColumn={gridColumn}
    small={small}
    style={styles}
  >
    Loading...
  </SpinnerContainer>
);

export default Spinner;
