import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isNil } from 'ramda';
import {
  fetchStudentProficiencyReportRequest,
  setReportLevel,
  setSyllabus
} from 'stores/report/actions';
import { fetchUserCurriculumsRequest } from 'stores/curriculums/actions';
import Spinner from 'components/Shared/Spinner';
import SkillDetails from 'components/Report/SkillDetails';
import StudentReport from 'components/Report/StudentReport';
import ToggleReport from 'components/Report/ToggleReport';
import avatarPlaceholder from 'assets/img/avatar.png';
import PrintIcon from 'assets/img/icon-report-print.svg';
import ArrowIcon from 'assets/img/icon-report-print-arrow.svg';
import GradeAIcon from 'assets/img/icon-report-high-score-a.svg';
import GradeBIcon from 'assets/img/icon-report-high-score-b.svg';
import GradeCIcon from 'assets/img/icon-report-high-score-c.svg';
import GradeDIcon from 'assets/img/icon-report-high-score-d.svg';
import { PrintButton } from 'components/Report/TopBar.styles';
import { featureToggle } from 'constants/index';
import {
  Header,
  Content,
  StudentReportPage
} from './StudentHighScoreReport.styles.js';

const StudentHighScoreReport = ({ match }) => {
  const { t } = useTranslation(['proficiency-report']);
  const studentID = parseInt(match.params.studentID, 10);
  const history = useHistory();
  const dispatch = useDispatch();
  // Redux States
  const {
    studentProficiencyReport,
    isLoading,
    selectedClass,
    reportError,
    reportLevel,
    selectedSyllabus
  } = useSelector(state => state.reports);
  const { curriculums, error } = useSelector(state => state.curriculums);
  // Component States
  const [showSkillDetails, setShowSkillDetails] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [selectedSkillTopic, setSelectedSkillTopic] = useState(null);
  const [selectedSkillNumber, setSelectedSkillNumber] = useState(null);

  // const reportLevels = generateLevels();
  const isLeveless = isEmpty(selectedSyllabus.Levels);
  const levels = !isEmpty(selectedSyllabus.Levels)
    ? selectedSyllabus.Levels
    : [];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getLevel = syll => {
    const isSelectedLeveless = isEmpty(syll.Levels);
    const checkLevelAvailable =
      syll && syll.Levels.find(item => item.ID === reportLevel);
    if (!isSelectedLeveless) {
      if (isNil(checkLevelAvailable)) {
        return syll.Levels[0];
        // eslint-disable-next-line no-else-return
      } else {
        return reportLevel;
      }
      // eslint-disable-next-line no-else-return
    } else {
      return { ID: -1 };
    }
  };

  // UseEffect
  useEffect(() => {
    if (error !== '') {
      dispatch(fetchUserCurriculumsRequest());
    }
  }, [dispatch, error]);
  useEffect(() => {
    dispatch(
      fetchStudentProficiencyReportRequest({
        studentID,
        classID: selectedClass.ID,
        reportLevel: isLeveless ? -1 : reportLevel.ID,
        syllabusID: selectedSyllabus.CurriculumID
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    reportLevel,
    selectedClass.ID,
    selectedSyllabus,
    selectedSyllabus.CurriculumID,
    studentID
  ]);
  return (
    <StudentReportPage>
      {isLoading && <Spinner />}
      {!isLoading && reportError && (
        <div className="error">
          <h3>{reportError}</h3>
        </div>
      )}
      {!isLoading && studentProficiencyReport && (
        <>
          <Header>
            <span
              className="back-button"
              onClick={() => {
                history.push('/report/class-report/high-score-report');
              }}
            >
              {t(`proficiency-report:studentReport.back`, '< Back')}
            </span>
            <span className="student-details">
              <img
                src={studentProficiencyReport.ProfilePictureURL}
                onError={e => {
                  e.target.src = avatarPlaceholder;
                }}
                alt="Student Profile"
                border="0"
                width="50px"
                height="50px"
                style={{
                  marginRight: '0.5rem',
                  borderRadius: '50%'
                }}
              />
              <strong>{studentProficiencyReport.Username}</strong>
            </span>
            <ToggleReport studentID={studentID} />
            {featureToggle.reportPrintButton && (
              <PrintButton>
                <button type="button">
                  <img src={PrintIcon} alt="print" />
                  {t(`proficiency-report:studentReport.print`, 'Print')}{' '}
                  <span>
                    <img src={ArrowIcon} alt="arrow" />
                  </span>
                </button>
              </PrintButton>
            )}
          </Header>
          <Content showSkillDetails={showSkillDetails}>
            <div className="section-container">
              <div className="legend-section">
                <span className="ratings-category">
                  <img src={GradeAIcon} alt="Grade A" />
                  9-10
                  <span className="sub-text">
                    {' '}
                    / 10
                    {t(
                      `proficiency-report:classReport.qnPerSkill`,
                      ' Qn per skill'
                    )}
                  </span>
                </span>
                <span className="ratings-category">
                  <img src={GradeBIcon} alt="Grade B" />
                  6-8
                  <span className="sub-text">
                    {' '}
                    / 10
                    {t(
                      `proficiency-report:classReport.qnPerSkill`,
                      ' Qn per skill'
                    )}
                  </span>
                </span>
                <span className="ratings-category">
                  <img src={GradeCIcon} alt="Grade C" />
                  4-5
                  <span className="sub-text">
                    {' '}
                    / 10
                    {t(
                      `proficiency-report:classReport.qnPerSkill`,
                      ' Qn per skill'
                    )}
                  </span>
                </span>
                <span className="ratings-category">
                  <img src={GradeDIcon} alt="Grade D" />
                  1-3
                  <span className="sub-text">
                    {' '}
                    / 10
                    {t(
                      `proficiency-report:classReport.qnPerSkill`,
                      ' Qn per skill'
                    )}
                  </span>
                </span>
              </div>
              <div className="dropdown-section">
                {!isLeveless && (
                  <div className="report-level-dropdown">
                    <span className="dropdown-label">
                      {t(
                        `proficiency-report:header.reportLevel`,
                        'Report Level from'
                      )}
                    </span>
                    <Dropdown flip={false}>
                      <Dropdown.Toggle
                        variant="light"
                        className="dropdown-custom"
                      >
                        {`P${reportLevel.ID}`}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {levels.map(level => (
                          <Dropdown.Item
                            key={level.ID}
                            onClick={() => {
                              dispatch(setReportLevel(level));
                              setShowSkillDetails(false);
                            }}
                          >
                            {`P${level.ID}`}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}

                <div className="syllabus-dropdown">
                  <span className="dropdown-label">
                    {t(`proficiency-report:header.syllabus`, 'Syllabus')}
                  </span>
                  <Dropdown flip={false}>
                    <Dropdown.Toggle
                      variant="light"
                      className="dropdown-select-syllabus"
                    >
                      <span>{selectedSyllabus.curriculumName}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {curriculums.map(syl => (
                        <Dropdown.Item
                          key={syl.ID}
                          onClick={() => {
                            dispatch(setSyllabus(syl));
                            dispatch(setReportLevel(getLevel(syl)));
                            setShowSkillDetails(false);
                          }}
                        >
                          {syl.curriculumName}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <StudentReport
              setShowSkillDetails={setShowSkillDetails}
              selectedSkill={selectedSkill}
              setSelectedSkill={setSelectedSkill}
              setSelectedSkillTopic={setSelectedSkillTopic}
              setSelectedSkillNumber={setSelectedSkillNumber}
              type="highscore"
              data={studentProficiencyReport.Topics}
            />
          </Content>
        </>
      )}
      {showSkillDetails && (
        <SkillDetails
          type="highscore"
          selectedSkill={selectedSkill}
          selectedSkillTopic={selectedSkillTopic}
          selectedSkillNumber={selectedSkillNumber}
        />
      )}
    </StudentReportPage>
  );
};

export default StudentHighScoreReport;
