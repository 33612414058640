// Helper to aggregate login report data to format for chart
const aggregateLoginReportData = data => {
  const classSize = data.length;
  const reducer = (accumulator, item) => {
    const result = accumulator.map((dayTotal, index) => {
      if (item?.LoginStatus[index] === 1) {
        return dayTotal + item?.LoginStatus[index];
      }
      return dayTotal;
    });
    return result;
  };
  const aggregatedLoginCountsTotal = data.reduce(reducer, Array(7).fill(0));
  const daysOfWeek = [
    {
      name: 'Sun',
      total: classSize
    },
    {
      name: 'Mon',
      total: classSize
    },
    {
      name: 'Tue',
      total: classSize
    },
    {
      name: 'Wed',
      total: classSize
    },
    {
      name: 'Thu',
      total: classSize
    },
    {
      name: 'Fri',
      total: classSize
    },
    {
      name: 'Sat',
      total: classSize
    }
  ];
  if (data.length <= 0) {
    return daysOfWeek.map(day => {
      return {
        ...day,
        loggedIn: 0,
        future: false
      };
    });
  }
  const aggregatedTotal = daysOfWeek.map((day, index) => {
    const isFuture = data[0].LoginStatus[index] === 2;
    if (isFuture) {
      return {
        ...day,
        total: 0,
        loggedIn: 0,
        future: day.total
      };
    }
    return {
      ...day,
      total: day.total - aggregatedLoginCountsTotal[index],
      loggedIn: aggregatedLoginCountsTotal[index],
      future: 0
    };
  });
  return aggregatedTotal;
};

export default aggregateLoginReportData;
