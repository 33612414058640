import apiClient from 'services/api';

const handleResponseSuccess = result => {
  if (result.data?.IsSuccessful === false) {
    return {
      error: result.data?.Message?.Message ?? 'Fetch data failed' // eslint-disable-line
    };
  }
  return result.data?.Result ?? [];
};

/**
 * Update profile
 */
const updateProfile = (params, updatePassword) => {
  return apiClient
    .put(`User/UpdateProfile?UpdatePassword=${updatePassword}`, params)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Update profile failed' // eslint-disable-line
      };
    });
};

/**
 * Fetch profile data
 */
const getProfileData = () => {
  return apiClient
    .get(`User/GetMyProfile`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch Profile Data failed' // eslint-disable-line
      };
    });
};

/**
 * Fetch avatars
 */
const getAvatars = () => {
  return apiClient
    .get(`User/GetAvatars`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch Avatars failed' // eslint-disable-line
      };
    });
};

export default {
  getProfileData,
  getAvatars,
  updateProfile
};
