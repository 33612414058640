import { isNil } from 'ramda';
import { put } from 'redux-saga/effects';
import { refreshTokenRequest } from 'stores/login/actions';
/**
 * The startup saga is the place to define behavior to execute when the application starts.
 */
export default function* startup() {
  // calling refresh token action when startup, to get lates tokens and user data
  const refreshToken = localStorage.getItem('refresh_token');
  if (!isNil(refreshToken)) {
    yield put(refreshTokenRequest());
  }
}
