import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function SpinnerOverlay({ isLoading }) {
  return (
    <div
      style={{
        backgroundColor: 'rgba(0,0,0,0.2)',
        opacity: isLoading ? 1 : 0,
        pointerEvents: 'none',
        transition: 'all 0.5s',
        WebkitTransition: 'all 0.5s'
      }}
      className="position-absolute d-flex justify-content-center align-items-center h-100 w-100"
    >
      <Spinner animation="border" variant="light" />
    </div>
  );
}
