import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Container, NoParticipant } from './Leaderboard.styles';
import LeaderboardHeader from 'components/Leaderboard/LeaderboardHeader';
import StudentList from 'components/Leaderboard/StudentList';
import Header from 'components/Header/Header';

import isNilOrEmpty from 'helpers/isNilOrEmpty';
import {
  fetchFactualFluencyLeaderboardRequest,
  factualFluencyLeaderboardReset
} from 'stores/factual-fluency/actions';
import {
  clearSingleClassReport,
  fetchSingleClassReportRequest
} from 'stores/assignments/actions';

const Leaderboard = ({ match }) => {
  const homeworkID = parseInt(match.params.homeworkID, 10);
  const { t } = useTranslation(['leaderboard']);
  const dispatch = useDispatch();

  const { singleClassReport } = useSelector(state => state.assignments);

  const { isLoading, leaderboard } = useSelector(state => state.factualFluency);

  useEffect(() => {
    dispatch(
      fetchSingleClassReportRequest({
        homeworkID
      })
    );
    dispatch(fetchFactualFluencyLeaderboardRequest(homeworkID));
    return () => {
      dispatch(factualFluencyLeaderboardReset());
      dispatch(clearSingleClassReport());
    };
  }, [dispatch, homeworkID]);

  return (
    <>
      <Header />

      <Container>
        <LeaderboardHeader
          homework={!isNilOrEmpty(singleClassReport[0]) && singleClassReport[0]}
        />
        <StudentList
          data={leaderboard}
          isTopFifty={true}
          isLoading={isLoading}
        />
        {!isLoading && leaderboard.length === 0 && (
          <NoParticipant>
            {t(
              'leaderboard:banner.noParticipant',
              'No participants at the moment.'
            )}
          </NoParticipant>
        )}
      </Container>
    </>
  );
};

export default Leaderboard;
