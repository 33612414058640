import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonStepNext from 'components/Shared/ButtonStepNext';

/**
 * @todo adding classNames and styles
 */
const NavigationWizard = props => {
  const { t } = useTranslation(['create-assign-question']);
  const { totalQuestions, onClick, showtotalQuestions } = props;

  return (
    <div className="d-flex align-items-center question-cart-content__right">
      {showtotalQuestions && (
        <p className="total-qn">
          {t(`create-assign-question:main.totalQn`, 'Total Qn : ')}
          <span className="total-qn-number">{totalQuestions}</span>
        </p>
      )}

      <ButtonStepNext
        title={t(`create-assign-question:main.next`, 'Next')}
        onClick={onClick}
        disabled={totalQuestions > 50}
      />
    </div>
  );
};
export default React.memo(NavigationWizard);
