import React, { useState } from 'react';
import TopicItem from './TopicItem';
import { TopicContainer } from './TopicList.style';

const TopicList = props => {
  const { topics, counts, setCounts } = props;
  const [allOpen, setAllOpen] = useState(false);
  return (
    <TopicContainer>
      {topics.map((Topic, index) => {
        return (
          <TopicItem
            key={String(Topic.Id)}
            topic={Topic}
            index={index}
            counts={counts}
            setCounts={setCounts}
            allOpen={allOpen}
            setAllOpen={setAllOpen}
          />
        );
      })}
    </TopicContainer>
  );
};
export default React.memo(TopicList);
