import styled from 'styled-components';

export const ButtonContainer = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  .curr-name {
    display: flex;
    flex-direction: column;
    width: 165px;
  }
  .label {
    color: #47270e;
    font-size: 8px;
    margin-right: 10px;
  }
  .name {
    font-size: 11px;
    color: #000000;
  }
`;

export const ChangeBtn = styled.div`
  border: 1px solid #d8a211;
  background: #ffc831;
  border-radius: 5px;
  color: #000000;
  font-family: 'Muli';
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  cursor: pointer;
`;
