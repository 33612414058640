import styled from 'styled-components';

export const SearchResultContainer = styled.div`
  .empty-result {
    display: flex;
    justify-content: center;
    margin-top: 250px;
  }
  .search-result-labels {
    display: grid;
    grid-template-columns: repeat(15, 1fr);
    .login_id {
      grid-column: 6/8;
      text-align: center;
    }
    .pswd {
      grid-column: 8/10;
      text-align: center;
    }
    .activation_date {
      grid-column: 10/12;
      text-align: center;
    }
    .status {
      grid-column: 12/14;
      text-align: center;
    }
  }
  .search-result-container {
    display: flex;
    flex-direction: column;
    margin: 0px 50px;
    width: 100%;
    .class-name {
      font-size: 14px;
      font-weight: 600;
      color: #1a1a1a;
      margin-bottom: 10px;
    }
    .table {
      background-color: white;
      .table-bordered {
        display: flex;
        flex-direction: column;
        border: none;
        .table-content {
          display: grid;
          grid-template-columns: repeat(15, 1fr);
          font-size: 14px;
          text-align: center;
          @media only screen and (max-width: 1024px) {
            font-size: 12px;
          }
          @media only screen and (max-width: 768px) {
            font-size: 10px;
          }
          .name {
            grid-column: 1/6;
            display: flex;
            overflow-wrap: break-word;
            text-align: left;
            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-right: 10px;
              @media only screen and (max-width: 768px) {
                width: 25px;
                height: 25px;
                margin-right: 5px;
              }
            }
            span {
              img {
                width: 22px;
                height: 22px;
                margin-left: -25px;
                margin-bottom: -40px;
                @media only screen and (max-width: 768px) {
                  width: 15px;
                  height: 15px;
                  margin-left: -15px;
                  margin-bottom: -30px;
                }
              }
            }
            p {
              margin-left: 3px;
            }
          }
          .login {
            grid-column: 6/8;
            overflow-wrap: break-word;
          }
          .password {
            grid-column: 8/10;
            overflow-wrap: break-word;
            .rest-pswd {
              background: none;
              border: none;
              color: #ff723d;
              font-size: 13px;
              text-decoration: underline;
              margin-left: 10px;
              @media only screen and (max-width: 768px) {
                font-size: 8px;
                margin-left: 3px;
              }
              @media only screen and (max-width: 587px) {
                font-size: 4px;
              }
            }
          }
          .active-date {
            grid-column: 10/12;
            overflow-wrap: break-word;
          }
          .link {
            grid-column: 12/14;
          }
          .details {
            grid-column: 14/16;
            button {
              @media only screen and (max-width: 768px) {
                padding: 5px;
              }
            }
            .btn-outline-primary:focus {
              background-color: transparent;
              color: #ff723d;
            }
          }
        }
      }
    }
  }
`;
