import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SidebarRight from 'components/Sidebar/SidebarRight';
import { Form } from 'react-bootstrap';
// eslint-disable-next-line no-unused-vars
import Select from 'react-select';
import optionsParser from 'helpers/optionsParser';
import { useSelector, useDispatch } from 'react-redux';
import { fetchStudentsSearchRequest } from 'stores/utils/lookup/actions';
import { groupBy, propEq, findIndex } from 'ramda';
import StudentList from 'components/ManageClass/StudentsList';
import { selectStudents, savePrivateGroup } from 'stores/privateGroup/actions';
import Border from 'components/Shared/Border';
import AlertModal from 'components/Alert/AlertModal';
import { Header } from './GroupAdd.styles';

/**
 * @todo when the UI for empty cart questions done, use cartQuestions from reducer or other resources
 * @note client still not confirm whether cartQuestions is the data we will be use
 */
export default function GroupAdd() {
  const { t } = useTranslation(['manage-class-private-group']);
  const dispatch = useDispatch();
  const { levels } = useSelector(state => state.levels);
  // eslint-disable-next-line no-unused-vars
  const levelOptions = optionsParser(levels, 'ID', 'Name');
  const { classes } = useSelector(state => state.lookup);
  const { selectedStudents } = useSelector(state => state.privateGroup);

  // const [selectedLevel, setSelectedLevel] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [title, setTitle] = useState('');

  // message alertt
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailed, setShowFailed] = useState(false);

  // eslint-disable-next-line no-unused-vars
  // const onChangeLevel = value => {
  //   if (!isNil(value)) {
  //     setSelectedLevel(value[0].value);
  //   }
  // };
  // Level on change effect
  // useEffect(() => {
  //   dispatch(fetchClassRequest(selectedLevel));
  // }, [dispatch, selectedLevel]);

  // eslint-disable-next-line no-unused-vars
  const classOptions = optionsParser(classes, 'ID', 'Name');

  const onChangeKeyword = event => {
    setKeyword(event.target.value);
  };
  const onChangeTitle = event => {
    setTitle(event.target.value);
  };

  const onClick = () => {
    setSearchTerm(keyword);
    dispatch(fetchStudentsSearchRequest(keyword));
  };

  const groupedStudents = groupBy(
    student => student.SchoolName,
    selectedStudents
  );

  const saveGroup = () => {
    if (title.trim() !== '') {
      dispatch(savePrivateGroup(title));
      dispatch(selectStudents([]));
      setTitle('');
      setKeyword('');
      setShowSuccess(true);
    } else {
      setShowFailed(true);
    }
  };

  const onRemove = userId => {
    const index = findIndex(propEq('UserId', parseInt(userId, 10)))(
      selectedStudents
    );
    const updatedData = [
      ...selectedStudents.slice(0, index),
      ...selectedStudents.slice(index + 1)
    ];

    dispatch(selectStudents(updatedData));
  };

  return (
    <>
      <AlertModal
        title={t(
          'manage-class-private-group:modal.titleFail',
          'Save Private Group Failed!'
        )}
        message={t(
          'manage-class-private-group:modal.messageFail',
          'Private Group name must be filled'
        )}
        isShow={showFailed}
        handleClose={() => setShowFailed(false)}
        buttons={
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setShowFailed(false)}
          >
            {t('manage-class-private-group:modal.ok', 'Ok')}
          </button>
        }
      />

      <AlertModal
        title={t(
          'manage-class-private-group:modal.titleSuccess',
          'Save Private Group Successfully!'
        )}
        message={t(
          'manage-class-private-group:modal.messageSuccess',
          'Private Group has been saved'
        )}
        isShow={showSuccess}
        handleClose={() => setShowSuccess(false)}
        buttons={
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setShowSuccess(false)}
          >
            {t('manage-class-private-group:modal.ok', 'Ok')}
          </button>
        }
      />

      <Header>
        <div className="form-row align-items-center">
          {/* @todo Multi select school */}
          {/* <Form.Group className="col-md-6">
            <Form.Control as="select" name="level">
              <option value="class">Class</option>
              <option value="group">Group</option>
            </Form.Control>
          </Form.Group> */}
          {/* @todo Pending API allow search by level */}
          {/* <Form.Group className="col-md-6">
            <Select
              placeholder="Select level(s)"
              options={levelOptions}
              onChange={onChangeLevel}
              isMulti
            />
          </Form.Group> */}
          {/* @todo Pending API allow search by class */}
          {/* <Form.Group className="col-md-6">
            <Select
              placeholder="Select class(es)"
              options={classOptions}
              onChange={() => {}}
              isMulti
            />
          </Form.Group> */}
          <Form.Group className="col-md-12">
            <input
              type="text"
              className="form-control"
              placeholder={t(
                'manage-class-private-group:add.placeholder',
                'Search by student username'
              )}
              value={keyword}
              onChange={onChangeKeyword}
            />
          </Form.Group>
          <Form.Group className="col-md-12">
            <button type="button" onClick={onClick} className="btn btn-primary">
              {t('manage-class-private-group:add.submitBtn', 'Submit')}
            </button>
          </Form.Group>
        </div>
      </Header>
      <StudentList keyword={searchTerm} />
      <SidebarRight className="sidebar-manage-class">
        <div className="form-group">
          <input
            type="text"
            placeholder={t(
              'manage-class-private-group:add.groupName',
              'Group Name'
            )}
            className="form-control"
            onChange={onChangeTitle}
            value={title}
          />
        </div>
        {selectStudents.length > 0 && (
          <div className="group-list students-list">
            <h5>
              {t(
                'manage-class-private-group:add.selectedStu',
                'Selected Students'
              )}
            </h5>
            {Object.entries(groupedStudents).map(
              ([studentSchool, schoolOfStudents]) => {
                return (
                  <React.Fragment key={studentSchool}>
                    <p className="text-muted">{studentSchool}</p>
                    {schoolOfStudents.map(singleStudent => (
                      <div key={singleStudent.UserId}>
                        <p>
                          {singleStudent.DisplayName}{' '}
                          <button
                            className="btn btn-link btn-selected-student"
                            type="button"
                            value={singleStudent.UserId}
                            onClick={() => onRemove(singleStudent.UserId)}
                          />
                        </p>
                      </div>
                    ))}
                    <Border />
                  </React.Fragment>
                );
              }
            )}
          </div>
        )}
        <button type="button" onClick={saveGroup} className="btn btn-primary">
          {t('manage-class-private-group:add.submitBtn', 'Submit')}
        </button>
      </SidebarRight>
    </>
  );
}
