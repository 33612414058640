import styled from 'styled-components';

export const CreateHomeworkContainer = styled.div`
  .topical-preview {
    padding-left: 195px;
  }
  .question-cart-content {
    margin-top: 60px !important;
  }
  .content__stepper-block {
    width: ${props =>
      props.activeStep === 3 ? 'calc(100vw - 395px)' : 'calc(100vw - 195px)'};
    margin-left: 195px;
  }

  @media (max-width: 1024px) {
    .content__stepper-block {
      width: 100vw;
      margin-left: 0;
    }
    .topical-preview {
      padding-left: 55px;
    }
  }

  @media (max-width: 640px) {
    .content__stepper-block {
      width: calc(100vw + 20px);
      left: -20px;
    }
    .topical-preview {
      padding-left: 20px;
    }
  }
`;
