import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import AssignClass from './AssignClass';
import AssignIndividual from './AssignIndividual';
import AssignPrivate from './AssignPrivate';
import AssignAdmin from './AssignAdmin';
import AssignGroup from './AssignGroup';

export default function AssignRadio() {
  const { t } = useTranslation(['create-assign-question']);
  const { isAdmin } = useSelector(state => state.login);
  const [typeAssign, setTypeAssign] = useState('class');

  const onChange = event => {
    setTypeAssign(event.target.value);
  };

  const renderSwitch = param => {
    switch (param) {
      case 'private':
        return <AssignPrivate />;
      case 'admin':
        return <AssignAdmin />;
      case 'class':
        return <AssignClass />;
      case 'individual':
        return <AssignIndividual />;
      case 'group':
        return <AssignGroup />;
      default:
        return false;
    }
  };

  return (
    <>
      <div className="assign-modal-header d-flex flex-row">
        {isAdmin && (
          <>
            <div className="form-check">
              <input
                type="radio"
                id="type-private"
                value="private"
                checked={typeAssign === 'private'}
                onChange={onChange}
                className="form-check-radio custom-radio"
              />
              <Form.Label htmlFor="type-private">
                {t(
                  `create-assign-question:assignModal.pvtGroup`,
                  'Private Group'
                )}
              </Form.Label>
            </div>
            <span>&</span>
          </>
        )}
        <div className="form-check">
          <input
            type="radio"
            id="type-class"
            value="class"
            checked={typeAssign === 'class'}
            onChange={onChange}
            className="form-check-radio custom-radio"
          />{' '}
          <Form.Label htmlFor="type-class">
            {t(`create-assign-question:assignModal.class`, 'Class')}
          </Form.Label>
        </div>
        <span>&</span>
        <div className="form-check">
          <input
            type="radio"
            id="type-group"
            value="group"
            checked={typeAssign === 'group'}
            onChange={onChange}
            className="form-check-radio custom-radio"
          />{' '}
          <Form.Label htmlFor="type-group">
            {t(`create-assign-question:assignModal.group`, 'Group')}
          </Form.Label>
        </div>
        <span>&</span>
        <div className="form-check">
          <input
            type="radio"
            id="type-individual"
            value="individual"
            checked={typeAssign === 'individual'}
            onChange={onChange}
            className="form-check-radio custom-radio"
          />{' '}
          <Form.Label htmlFor="type-individual">
            {t(
              `create-assign-question:assignModal.indvStud`,
              'Individual Student'
            )}
          </Form.Label>
        </div>
        {isAdmin && (
          <div className="form-check">
            <input
              type="radio"
              id="type-admin"
              value="admin"
              checked={typeAssign === 'admin'}
              onChange={onChange}
              className="form-check-radio custom-radio"
            />{' '}
            <Form.Label htmlFor="type-admin">
              {t(`create-assign-question:assignModal.forAdimn`, 'For Admin')}
            </Form.Label>
          </div>
        )}
      </div>
      <div className="assign-modal-body">{renderSwitch(typeAssign)}</div>
    </>
  );
}
