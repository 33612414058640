import moment from 'moment';
import {
  FETCH_ASSIGNMENTS_REQUEST,
  FETCH_ASSIGNMENTS_SUCCESS,
  FETCH_ASSIGNMENTS_FAILED,
  SET_YEARS,
  SET_NUMBER_OF_PAGES,
  FETCH_TEACHER_ASSIGNMENTS_REQUEST,
  FETCH_TEACHER_ASSIGNMENTS_SUCCESS,
  FETCH_TEACHER_ASSIGNMENTS_FAILED,
  FETCH_TEACHERS_REQUEST,
  FETCH_TEACHERS_SUCCESS,
  FETCH_TEACHERS_FAILED,
  FETCH_MULTI_CLASS_REPORT_REQUEST,
  FETCH_MULTI_CLASS_REPORT_SUCCESS,
  FETCH_MULTI_CLASS_REPORT_FAILED,
  FETCH_SINGLE_CLASS_REPORT_REQUEST,
  FETCH_SINGLE_CLASS_REPORT_SUCCESS,
  FETCH_SINGLE_CLASS_REPORT_FAILED,
  FETCH_CSV_CLASS_REPORT_REQUEST,
  FETCH_CSV_CLASS_REPORT_SUCCESS,
  FETCH_CSV_CLASS_REPORT_FAILED,
  DELETE_ASSIGNED_HW_REQUEST,
  DELETE_ASSIGNED_HW_SUCCESS,
  DELETE_ASSIGNED_HW_FAILED,
  SET_LATEST_YEAR,
  SET_ASSIGNMENT_SUMMARY_HISTORY,
  SET_SELECTED_TEACHER,
  CLEAR_ASSIGNMENTS,
  CLEAR_MULTI_CLASS_REPORT,
  CLEAR_SINGLE_CLASS_REPORT,
  RESET_DELETE_HW_RESULT,
  RESET_ERROR,
  CHANGE_TITLE_DATE_RESET,
  CHANGE_TITLE_DATE_REQUEST,
  CHANGE_TITLE_DATE_SUCCESS,
  CHANGE_TITLE_DATE_FAILED,
  MARK_ANSWER_RIGHT_REQUEST,
  MARK_ANSWER_RIGHT_SUCCESS,
  MARK_ANSWER_RIGHT_FAILED
} from './actionTypes';

const generateYears = () => {
  const yearsArray = [];
  let currentYear = moment().year();
  while (currentYear >= 2013) {
    yearsArray.push(currentYear);
    currentYear -= 1;
  }
  return yearsArray;
};

const initialState = {
  isLoading: false,
  isSingleClassReportLoading: false,
  isCSVClassReportLoading: false,
  deleteHwIsLoading: false,
  assignments: [],
  years: generateYears(),
  numOfPages: 1,
  teachers: [],
  multiClassReport: [],
  singleClassReport: [],
  CSVClassReport: [],
  parsedCSVClassReport: [],
  latestYearWithAssignments: null,
  assignmentSummaryHistory: null,
  selectedTeacher: null,
  deleteHwResult: false,
  changeTitleIsLoading: false,
  changeTitleDate: false,
  markAnswerRight: false,
  error: null
};

export default function assignments(state = initialState, action) {
  switch (action.type) {
    case FETCH_ASSIGNMENTS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        assignments: action.assignments
      };
    case FETCH_ASSIGNMENTS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case FETCH_TEACHER_ASSIGNMENTS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_TEACHER_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        assignments: action.assignments
      };
    case FETCH_TEACHER_ASSIGNMENTS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case FETCH_MULTI_CLASS_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_MULTI_CLASS_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        multiClassReport: action.report
      };
    case FETCH_MULTI_CLASS_REPORT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case FETCH_SINGLE_CLASS_REPORT_REQUEST:
      return {
        ...state,
        isSingleClassReportLoading: true,
        error: null
      };
    case FETCH_SINGLE_CLASS_REPORT_SUCCESS:
      return {
        ...state,
        isSingleClassReportLoading: false,
        singleClassReport: action.report
      };
    case FETCH_SINGLE_CLASS_REPORT_FAILED:
      return {
        ...state,
        isSingleClassReportLoading: false,
        error: action.error
      };
    case FETCH_CSV_CLASS_REPORT_REQUEST:
      return {
        ...state,
        isCSVClassReportLoading: true
      };
    case FETCH_CSV_CLASS_REPORT_SUCCESS:
      return {
        ...state,
        isCSVClassReportLoading: false,
        CSVClassReport: action.data,
        parsedCSVClassReport: action.report
      };
    case FETCH_CSV_CLASS_REPORT_FAILED:
      return {
        ...state,
        isCSVClassReportLoading: false,
        error: action.error
      };
    case SET_YEARS:
      return {
        ...state,
        years: action.years
      };
    case SET_LATEST_YEAR:
      return {
        ...state,
        latestYearWithAssignments: action.year
      };
    case SET_NUMBER_OF_PAGES:
      return {
        ...state,
        numOfPages: action.number
      };
    case SET_ASSIGNMENT_SUMMARY_HISTORY:
      return {
        ...state,
        assignmentSummaryHistory: action.page
      };
    case SET_SELECTED_TEACHER:
      return {
        ...state,
        selectedTeacher: action.teacherID
      };
    case FETCH_TEACHERS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_TEACHERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        teachers: action.teachers
      };
    case FETCH_TEACHERS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case DELETE_ASSIGNED_HW_REQUEST:
      return {
        ...state,
        deleteHwIsLoading: true,
        deleteHwResult: false,
        error: null
      };
    case DELETE_ASSIGNED_HW_SUCCESS:
      return {
        ...state,
        deleteHwIsLoading: false,
        deleteHwResult: true
      };
    case DELETE_ASSIGNED_HW_FAILED:
      return {
        ...state,
        deleteHwIsLoading: false,
        error: action.error
      };
    case CLEAR_ASSIGNMENTS:
      return {
        ...state,
        assignments: []
      };
    case CLEAR_SINGLE_CLASS_REPORT:
      return {
        ...state,
        singleClassReport: []
      };
    case CLEAR_MULTI_CLASS_REPORT:
      return {
        ...state,
        multiClassReport: []
      };
    case RESET_ERROR:
      return {
        ...state,
        error: null
      };
    case RESET_DELETE_HW_RESULT:
      return {
        ...state,
        deleteHwResult: false
      };
    case CHANGE_TITLE_DATE_RESET:
      return {
        ...state,
        changeTitleDate: false
      };
    case CHANGE_TITLE_DATE_REQUEST:
      return {
        ...state,
        changeTitleIsLoading: true,
        changeTitleDate: false,
        error: null
      };
    case CHANGE_TITLE_DATE_SUCCESS:
      return {
        ...state,
        changeTitleIsLoading: false,
        changeTitleDate: true
      };
    case CHANGE_TITLE_DATE_FAILED:
      return {
        ...state,
        changeTitleIsLoading: false,
        error: action.error
      };
    case MARK_ANSWER_RIGHT_REQUEST:
      return {
        ...state,
        isLoading: true,
        markAnswerRight: false,
        error: null
      };
    case MARK_ANSWER_RIGHT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        markAnswerRight: true
      };
    case MARK_ANSWER_RIGHT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    default:
      return state;
  }
}
