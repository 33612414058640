import {
  SET_STEP,
  START_STEP,
  PREV_STEP,
  NEXT_STEP,
  FINISH_STEP
} from './actionTypes';

export const setStep = step => {
  return { type: SET_STEP, step };
};

export const startStep = () => {
  return { type: START_STEP };
};

export const prevStep = () => {
  return { type: PREV_STEP };
};

export const nextStep = () => {
  return { type: NEXT_STEP };
};

export const finishStep = () => {
  return { type: FINISH_STEP };
};
