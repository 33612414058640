import React from 'react';
import { featureToggle } from 'constants/index';
import StudentReportSidebar from './StudentReport-Sidebar';
import SchoolReportSidebar from './SchoolReport-Sidebar';
import ClassReportSidebar from './ClassReport-Sidebar';

export default function SidebarReport() {
  return (
    <div className="sidebar-create-assign">
      <ul className="sidebar-create-assign__ul list-group">
        <ClassReportSidebar />
        {featureToggle.reportSidebarOptions && <StudentReportSidebar />}
        <SchoolReportSidebar />
      </ul>
    </div>
  );
}
