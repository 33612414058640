import { sort } from 'ramda';
import {
  SET_SELECTED_QUESTIONS,
  SET_EDIT_SAVED_WORKSHEET_QUESTIONS,
  REMOVE_SELECTED_SAVED_WORKSHEET_QUESTION,
  REMOVE_SELECTED_QUESTION,
  SET_WORKSHEET_TITLE,
  SET_INSTRUCTION,
  SET_ASSIGMENT_TYPE,
  SET_PRESENTATION_TYPE,
  FETCH_WORKSHEETS_SUCCESS,
  FETCH_WORKSHEETS_FAILED,
  FETCH_WORKSHEETS_REQUEST,
  SELECTING_WORKSHEET,
  SELECTED_WORKSHEET,
  SELECTED_WORKSHEET_FAILED,
  RESET_SELECTED_WORKSHEET,
  SAVE_WORKSHEET,
  ASSIGN_CLASS,
  ASSIGN_GROUP,
  ASSIGN_STUDENT,
  ASSIGN_PRIVATE_GROUP,
  ASSIGN_ADMIN_GROUP,
  ASSIGN_LEVEL,
  RESET_ASSIGNMENT,
  RESET_WORKSHEET,
  FETCH_ALL_WORKSHEETS_SUCCESS,
  FETCH_ALL_WORKSHEETS_FAILED,
  FETCH_ALL_WORKSHEETS_REQUEST,
  DELETE_WORKSHEET,
  DELETE_WORKSHEET_SUCCESS,
  DELETE_WORKSHEET_FAILED,
  DELETE_WORKSHEET_RESET,
  ASSIGN_WORKSHEET,
  SCHEDULE_WORKSHEET,
  SET_SETTING_TIME_TYPE,
  SET_SETTING_TIME_LIMIT,
  GET_WORKSHEET_REQUEST,
  RESET_WORKSHEET_SEARCH_PAGE
} from './actionTypes';

const initialState = {
  selectedQuestions: [],
  editSavedWorksheetQuestions: [],
  error: null,
  isLoading: false,
  allWorksheets: [],
  worksheets: [],
  selectedWorksheet: {},
  title: '',
  instructions: '',
  assignedClass: [],
  assignedGroup: [],
  assignedStudent: [],
  assignedPrivateGroup: [],
  assignedAdminGroup: {},
  assignedLevel: '',
  assignmentType: '',
  presentationType: '',
  deleteWorkSheet: false,
  timeLimit: 0,
  worksheetSearchPage: 1
};

export default function worksheet(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_QUESTIONS:
      return { ...state, selectedQuestions: action.selectedQuestions };
    case SET_EDIT_SAVED_WORKSHEET_QUESTIONS:
      return {
        ...state,
        editSavedWorksheetQuestions: action.questions
      };
    case REMOVE_SELECTED_QUESTION: {
      const updatedSelectedQuestions = state.selectedQuestions.filter(
        question => question.ID !== action.Id
      );
      return { ...state, selectedQuestions: updatedSelectedQuestions };
    }
    case REMOVE_SELECTED_SAVED_WORKSHEET_QUESTION: {
      const updatedSelectedQuestions = state.editSavedWorksheetQuestions.filter(
        question => question.ID !== action.Id
      );
      return {
        ...state,
        editSavedWorksheetQuestions: updatedSelectedQuestions
      };
    }
    case SET_WORKSHEET_TITLE:
      return { ...state, title: action.title };
    case SET_INSTRUCTION:
      return { ...state, instructions: action.instructions };
    case SET_ASSIGMENT_TYPE:
      return { ...state, assignmentType: action.assignmentType };
    case SET_PRESENTATION_TYPE:
      return { ...state, presentationType: action.presentationType };
    case SET_SETTING_TIME_TYPE:
      return { ...state, timeSettingType: action.timeSettingType };
    case SET_SETTING_TIME_LIMIT:
      return { ...state, timeLimit: action.timeLimit };
    case SAVE_WORKSHEET:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case FETCH_WORKSHEETS_REQUEST:
    case FETCH_ALL_WORKSHEETS_REQUEST:
    case SELECTING_WORKSHEET:
    case GET_WORKSHEET_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case DELETE_WORKSHEET:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case DELETE_WORKSHEET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        deleteWorkSheet: true
      };
    case DELETE_WORKSHEET_FAILED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case DELETE_WORKSHEET_RESET:
      return {
        ...state,
        isLoading: false,
        error: null,
        deleteWorkSheet: false
      };
    case ASSIGN_WORKSHEET:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case SCHEDULE_WORKSHEET:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case FETCH_WORKSHEETS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        worksheets: action.worksheets,
        worksheetSearchPage: action.worksheets.PageIndex
      };
    case FETCH_ALL_WORKSHEETS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allWorksheets: action.allWorksheets
      };
    case SELECTED_WORKSHEET:
      return {
        ...state,
        isLoading: false,
        selectedWorksheet: action.selectedWorksheet,
        title: action.selectedWorksheet.Title,
        assignmentType:
          action.selectedWorksheet.PresentationType.AssignmentTypeID,
        presentationType:
          action.selectedWorksheet.PresentationType.presentationTypeID,
        timeLimit: action.selectedWorksheet.PresentationType.TimeLimit
      };
    case RESET_SELECTED_WORKSHEET:
      return {
        ...state,
        editSavedWorksheetQuestions: [],
        error: null,
        isLoading: false,
        worksheets: [],
        selectedWorksheet: {},
        title: '',
        instructions: '',
        assignedClass: [],
        assignedGroup: [],
        assignedStudent: [],
        assignedPrivateGroup: [],
        assignedAdminGroup: {},
        assignedLevel: '',
        assignmentType: '',
        presentationType: '',
        timeLimit: 0
      };
    case FETCH_WORKSHEETS_FAILED:
    case FETCH_ALL_WORKSHEETS_FAILED:
    case SELECTED_WORKSHEET_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case ASSIGN_CLASS:
      return {
        ...state,
        isLoading: false,
        assignedClass: action.classes
      };
    case ASSIGN_GROUP:
      return {
        ...state,
        isLoading: false,
        assignedGroup: action.groups
      };
    case ASSIGN_STUDENT:
      return {
        ...state,
        isLoading: false,
        assignedStudent: sort((a, b) => a.ClassID - b.ClassID, action.students)
      };
    case ASSIGN_PRIVATE_GROUP:
      return {
        ...state,
        isLoading: false,
        assignedPrivateGroup: action.privateGroups
      };
    case ASSIGN_ADMIN_GROUP:
      return {
        ...state,
        isLoading: false,
        assignedAdminGroup: action.adminGroups
      };
    case ASSIGN_LEVEL:
      return {
        ...state,
        isLoading: false,
        assignedLevel: action.level
      };
    case RESET_ASSIGNMENT:
      return action.assignmentType === 'admin'
        ? {
            ...state,
            isLoading: false,
            assignedAdminGroup: {},
            assignedLevel: ''
          }
        : {
            ...state,
            isLoading: false,
            assignedClass: [],
            assignedGroup: [],
            assignedStudent: [],
            assignedPrivateGroup: []
          };
    case RESET_WORKSHEET_SEARCH_PAGE: {
      return {
        ...state,
        worksheetSearchPage: 1
      };
    }
    case RESET_WORKSHEET:
      return initialState;

    default:
      return state;
  }
}
