export const FETCH_PROFILE_DATA_REQUEST = 'FETCH_PROFILE_DATA_REQUEST';
export const FETCH_PROFILE_DATA_SUCCESS = 'FETCH_PROFILE_DATA_SUCCESS';
export const FETCH_PROFILE_DATA_FAILED = 'FETCH_PROFILE_DATA_FAILED';
export const FETCH_AVATARS_REQUEST = 'FETCH_AVATARS_REQUEST';
export const FETCH_AVATARS_SUCCESS = 'FETCH_AVATARS_SUCCESS';
export const FETCH_AVATARS_FAILED = 'FETCH_AVATARS_FAILED';
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED';
export const EMPTY_UPDATE_PROFILE = 'EMPTY_UPDATE_PROFILE';
