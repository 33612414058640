import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import AssignedByMeIcon from 'assets/img/icon-track-assignment-assigned-by-me.svg';
import AssignedByOthersIcon from 'assets/img/icon-track-assignment-assigned-by-others.svg';
import AssignedByMeActiveIcon from 'assets/img/icon-track-assignment-assigned-by-me-active.svg';
import AssignedByOthersActiveIcon from 'assets/img/icon-track-assignment-assigned-by-others-active.svg';

const SidebarTrackAssignment = ({ pathname }) => {
  const { t } = useTranslation(['track-assignment-assigned-me']);
  return (
    <div className="sidebar-create-assign">
      <ul className="sidebar-create-assign__ul list-group">
        <li className="list-group-item">
          <Link
            className={`nav-link ${pathname === '/track-assignment' &&
              'nav-link-active'}`}
            to="/track-assignment"
          >
            <img
              src={
                pathname === '/track-assignment'
                  ? AssignedByMeActiveIcon
                  : AssignedByMeIcon
              }
              alt="icon-list"
              className="track-assignment-sidebar-icon"
            />
            <span>
              {t(
                `track-assignment-assigned-me:sidebar.assignedMe`,
                'Assigned by me'
              )}
            </span>
          </Link>
        </li>
        <li className="list-group-item">
          <Link
            className={`nav-link ${pathname.startsWith(
              '/track-assignment/others'
            ) && 'nav-link-active'}`}
            to="/track-assignment/others"
          >
            <img
              src={
                pathname.startsWith('/track-assignment/others')
                  ? AssignedByOthersActiveIcon
                  : AssignedByOthersIcon
              }
              alt="icon-list"
              className="track-assignment-sidebar-icon"
            />
            <span>
              {t(
                `track-assignment-assigned-me:sidebar.assignedOthers`,
                'Assigned by others'
              )}
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default withRouter(({ location }) => {
  return <SidebarTrackAssignment pathname={location.pathname} />;
});
