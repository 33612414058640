import { call, put } from 'redux-saga/effects';
import { isNil } from 'ramda';
import {
  fetchSyllabusSuccess,
  fetchSyllabusFailed,
  noSyllabus,
  pushToCartSuccess,
  pushToCartFailed,
  createWorkSheetSuccess,
  createWorkSheetFailed,
  createWorkSheetError
} from 'stores/topical-worksheet/actions';
import TopicalService from 'services/topical-worksheet';

export function* fetchSyllabus({ CurriculumID, LevelID }) {
  const data = yield call(TopicalService.fetchSyllabus, CurriculumID, LevelID);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchSyllabusSuccess(data));
    } else {
      if (data.error === 'No Data Found') yield put(noSyllabus());
    }
  } else {
    yield put(fetchSyllabusFailed(data.error));
  }
}

export function* pushToCart({ obj }) {
  const data = yield call(TopicalService.pushToCart, obj);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(pushToCartSuccess(data));
    } else {
      if (data.error) {
        yield put(createWorkSheetError(data.error));
      }
    }
  } else {
    yield put(pushToCartFailed(data.error));
  }
}

export function* createWorkSheet({ obj }) {
  const data = yield call(TopicalService.createWorkSheet, obj);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(createWorkSheetSuccess(data));
    } else {
      if (data.error) {
        yield put(createWorkSheetError(data.error));
      }
    }
  } else {
    yield put(createWorkSheetFailed(data.error));
  }
}
