const shortenLevel = str => {
  if (str.startsWith('Primary')) {
    return str.replace('Primary ', 'P');
  }
  if (str.startsWith('Secondary')) {
    return str.replace('Secondary', 'Sec');
  }
  return str;
};

export default shortenLevel;
