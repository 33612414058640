import React, { useEffect, useRef } from 'react';
import { StyledModal } from './CustomModal.styles';

export default function CustomModal({ children, hide }) {
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // hide();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () =>
        document.removeEventListener('mousedown', handleClickOutside);
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <StyledModal>
      <div ref={wrapperRef} className="modal-content">
        {children}
      </div>
    </StyledModal>
  );
}
