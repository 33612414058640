import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import PrintIcon from 'assets/img/print-icon.svg';
import MonthlyReportChart from 'components/Report/MonthlyReportChart';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTeacherReportMonthRequest,
  fetchTeacherReportSpecificMonthRequest
} from 'stores/report/actions';
import Spinner from 'components/Shared/Spinner';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import isNilOrEmpty from 'helpers/isNilOrEmpty';
import { find, propEq } from 'ramda';
import { SpecificMonthlyReportStyle } from './SpecificMonthlyReport.styles';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

class MonthlyReportChartComponent extends React.PureComponent {
  render() {
    const { data, reportName } = this.props;

    return <MonthlyReportChart data={data} reportName={reportName} />;
  }
}

const SpecificMonthlyReport = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const year = query.get('year');
  const month = query.get('month');
  const { t } = useTranslation(['monthly-report', 'common', 'login-report']);
  const [name, setName] = useState(null);
  const [monthSelected, setMonthSelected] = useState(month);
  const {
    isLoading,
    teacherSpecificMonthReport,
    teacherMonthReport
  } = useSelector(state => state.reports);

  useEffect(() => {
    dispatch(fetchTeacherReportMonthRequest(year));
  }, [dispatch, year]);

  useEffect(() => {
    const selected =
      !isNilOrEmpty(teacherMonthReport) &&
      find(propEq('Month', parseInt(monthSelected, 10)))(teacherMonthReport);

    if (!selected.IsAvailable && selected.Year === year) {
      history.push('/report/school-report/monthly-activity');
    }

    dispatch(
      fetchTeacherReportSpecificMonthRequest({
        year,
        month: monthSelected
      })
    );
  }, [dispatch, year, monthSelected, teacherMonthReport, history]);

  useEffect(() => {
    const selectedName =
      !isNilOrEmpty(teacherMonthReport) &&
      teacherMonthReport.filter(e => e.Month === parseInt(monthSelected, 10));

    setName(selectedName[0]);
  }, [dispatch, teacherMonthReport, monthSelected]);

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current
  });

  const handleChangeMonth = useCallback(
    selectedMonth => () => {
      setMonthSelected(selectedMonth);
      const currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set('month', selectedMonth);
      history.push(
        `${window.location.pathname}?${currentUrlParams.toString()}`
      );
    },
    [history]
  );

  const MonthlyReportChartMemo = useMemo(
    () =>
      name && (
        <MonthlyReportChartComponent
          ref={printRef}
          data={teacherSpecificMonthReport}
          reportName={name}
        />
      ),
    [name, teacherSpecificMonthReport]
  );

  return (
    <SpecificMonthlyReportStyle>
      <div className="top-bar">
        <div className="backButtonWrapper">
          <button
            type="button"
            className="back-button"
            onClick={() => {
              history.push('/report/school-report/monthly-activity');
            }}
          >
            &lt; {t(`common:back`, 'Back')}
          </button>
        </div>
        <div className="headerRight">
          <div className="dropdown-section">
            <p className="dropdown-label">
              {t(`monthly-report:specificHeader.chooseMonth`, 'Choose Month')}
            </p>
            <Dropdown>
              <Dropdown.Toggle variant="light" className="dropdown-custom">
                {t(
                  `monthly-report:month.${name && name.ReportName.slice(0, 3)}`,
                  `${name && name.ReportName.slice(0, 3)}`
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu flip={false}>
                {!isNilOrEmpty(teacherMonthReport) &&
                  teacherMonthReport
                    .filter(e => e.IsAvailable)
                    .map(e => (
                      <Dropdown.Item
                        key={e.ReportName.slice(0, 3)}
                        onClick={handleChangeMonth(e.Month)}
                      >
                        {t(
                          `monthly-report:month.${e.ReportName.slice(0, 3)}`,
                          `${e.ReportName.slice(0, 3)}`
                        )}
                      </Dropdown.Item>
                    ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="action-button">
            <button type="button" onClick={handlePrint}>
              <img className="printIcon" src={PrintIcon} alt="print-icon" />
              {t(`login-report:header.print`, 'Print')}
            </button>
          </div>
        </div>
      </div>
      {isLoading && <Spinner />}
      {MonthlyReportChartMemo}
    </SpecificMonthlyReportStyle>
  );
};

export default SpecificMonthlyReport;
