import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { nextStep } from 'stores/utils/step/actions';

import { Modal, Col } from 'react-bootstrap';
import PopupModal from 'components/Shared/PopupModal';
import RemoveQuestionModal from 'components/CreateAndAssign/QuestionCart/RemoveQuestionModal';
import iconTrash from 'assets/img/icons8-trash.svg';
import WorksheetQuestion from 'components/CreateAndAssign/QuestionCart/WorksheetQuestion';
import WorksheetQuestionModal from 'components/CreateAndAssign/QuestionCart/WorksheetQuestionModal';
// import IconPlus from 'assets/img/icons-plus.svg';
import HeaderBox from 'components/CreateAndAssign/HeaderBox';
import NavigationWizard from 'components/CreateAndAssign/NavigationWizard';
import WorksheetForm from 'components/CreateAndAssign/QuestionCart/WorksheetForm';
import { setWorksheetTitle } from 'stores/worksheet/actions';
import { isEmpty } from 'ramda';

export default function WorksheetEdit() {
  const { t } = useTranslation(['create-assign-saved']);
  const dispatch = useDispatch();

  const { selectedQuestions, title } = useSelector(state => state.worksheet);

  const [show, setShow] = useState(false);
  const [showSolution, setShowSolution] = useState(false);
  const [selectedCard, setSelectedCard] = useState(
    !isEmpty(selectedQuestions) && selectedQuestions[0].ID
  );
  const [confirmation, setConfirmation] = useState(false);
  const [alertMsg, setAlertMsg] = useState(false);

  const toggleShowSolution = () => setShowSolution(!showSolution);

  const [questionPopup, setQuestionPopup] = useState({});
  const handleClose = () => {
    toggleShowSolution();
    setShow(false);
  };

  const handleShow = async question => {
    setQuestionPopup(question);
    setShow(true);
  };

  const handleNextStep = () => {
    if (title.trim() === '') {
      setAlertMsg(true);
    }
    if (title.trim()) {
      setAlertMsg(false);
      dispatch(nextStep());
    }
  };

  const onChangeTitle = value => {
    dispatch(setWorksheetTitle(value));
  };

  const showConfirmation = () => {
    setConfirmation(true);
  };

  const closeConfirmation = () => {
    setConfirmation(false);
  };

  return (
    <div className="edit-worksheet">
      <Modal show={show} onHide={handleClose} className="modal-qs">
        <WorksheetQuestionModal
          key={String(questionPopup.Id)}
          selectedQuestion={questionPopup}
          onCloseModal={handleClose}
          showSolution={showSolution}
        />
      </Modal>
      <PopupModal
        show={confirmation}
        hide={closeConfirmation}
        className="modal-qs"
      >
        <RemoveQuestionModal hide={closeConfirmation} type="remove-all" />
      </PopupModal>
      <HeaderBox>
        <Col xl={7} md={6}>
          <WorksheetForm
            isError={title.trim() === ''}
            value={title}
            placeholder={t(
              `create-assign-saved:main.addWoTitle`,
              'Add Worksheet Title'
            )}
            onChange={onChangeTitle}
            alertMsg={alertMsg}
          />
        </Col>
        <Col md="auto" style={{ display: 'flex' }}>
          <button
            type="button"
            className="btn remove-question-all-btn"
            onClick={showConfirmation}
            style={{ paddingRight: '0px' }}
          >
            <img
              alt="Trash"
              src={iconTrash}
              className="trash-icon-all"
              title="Remove All"
            />
          </button>
          <NavigationWizard
            onClick={handleNextStep}
            showtotalQuestions
            totalQuestions={selectedQuestions.length}
          />
        </Col>
      </HeaderBox>
      {/* <div className="justify-content-center align-items-center question-add-instruction">
        <button type="button" className="btn">
          <img src={IconPlus} alt="plus-icon" className="icon-plus" /> Add
          Instruction
        </button>
      </div>
      <input
        type="checkbox"
        id="add-score-weightage"
        className="check-add-score"
      /> */}
      {/* <span className="custom-checkbox" /> */}
      {/* eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
      {/* <label htmlFor="add-score-weightage">Add Score Weightage</label> */}
      <div className="box-white-wrap question-cart-list">
        <div className="row">
          {selectedQuestions.map((question, key) => {
            return (
              <WorksheetQuestion
                isShowEditPanel
                key={String(question.ID)}
                selectedQuestion={question}
                onShowModal={() => handleShow(question)}
                onShowSolution={toggleShowSolution}
                selectedCard={selectedCard}
                onClick={() => setSelectedCard(question.ID)}
                cardIndex={key}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
