import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import scheduleIcon from 'assets/img/icon-schedule-orange.svg';
import assignedIcon from 'assets/img/icon-assigned.svg';
import assignNow from 'assets/img/icon-assign-now.svg';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectWorksheet } from 'stores/worksheet/actions';
import history from 'helpers/history';
import { worksheetStatus, featureToggle } from 'constants/index';
import SavedWorksheetRowSubMenu from './SavedWorksheetRowSubMenu';
import CircleType from '../CircleType';
import './SavedWorksheetRow.styles.scss';
import { isFactualFluency } from 'helpers/factualFluency';

export default function SavedWorksheetRow(props) {
  const { t } = useTranslation(['create-assign-saved']);
  const dispatch = useDispatch();
  const { worksheet } = props;
  const ModifiedDate = moment(worksheet.ModifiedDate).format('DD/MM/YYYY');

  const handleSchedule = selectedWorksheet => {
    dispatch(selectWorksheet(selectedWorksheet, false, false, 1));
    history.push('/create-and-assign/saved-worksheet/schedule');
  };

  const handleAssign = selectedWorksheet => {
    dispatch(selectWorksheet(selectedWorksheet, false, false, 2));
    history.push('/create-and-assign/saved-worksheet/assign-now');
  };

  const handleEdit = async selectedWorksheet => {
    await dispatch(selectWorksheet(selectedWorksheet, false, true));

    if (isFactualFluency(selectedWorksheet.PresentationType.AssignmentTypeID)) {
      history.push('/create-and-assign/factual-fluency/edit');
    } else {
      history.push('/create-and-assign/saved-worksheet/edit');
    }
  };
  return (
    <div className="saved-worksheet__content-item">
      <div className="saved-worksheet__item-row saved-worksheet__item-row--bordered">
        <div className="saved-worksheet-type">
          <CircleType typeId={worksheet.PresentationType.AssignmentTypeID} />
        </div>
        <div className="saved-worksheet-title">
          <p>{worksheet.Title}</p>
        </div>
        <div className="saved-worksheet-date">
          <p>{ModifiedDate}</p>
        </div>
        {featureToggle.previewButton && (
          <div className="saved-worksheet-preview">
            <i className="icon-document" />
          </div>
        )}
        <div className="saved-worksheet-presentation">
          <div className="saved-worksheet-presentation__data-action">
            <button
              type="button"
              className="btn btn-outline-secondary btn-saved-schedule"
              onClick={() => handleSchedule(worksheet)}
              disabled={worksheetStatus.publish !== worksheet.WorksheetStatus}
            >
              <img
                src={scheduleIcon}
                alt="icon-list"
                className="saved-worksheet__icon--schedule"
              />
              {t(`create-assign-saved:main.schedule`, 'Schedule')}
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary btn-saved-schedule"
              onClick={() => handleAssign(worksheet)}
              disabled={worksheetStatus.publish !== worksheet.WorksheetStatus}
            >
              <img
                src={assignNow}
                alt="icon-list"
                className="saved-worksheet__icon--schedule"
              />
              {t(`create-assign-saved:main.assignNow`, 'Assign Now')}
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary btn-saved-assign"
              onClick={() => handleEdit(worksheet)}
            >
              {t(`create-assign-saved:main.edit`, 'Edit')}
            </button>
            <SavedWorksheetRowSubMenu worksheet={worksheet} />
          </div>
        </div>
      </div>
      {worksheet.IsAssigned && (
        <div className="saved-worksheet__item-row">
          <div className="saved-worksheet-assignments">
            <span className="saved-worksheet-assignments__link">
              <img
                src={assignedIcon}
                alt="icon-list"
                className="saved-worksheet__icon--assigned"
              />
              {t(`create-assign-saved:main.assigned`, 'Assigned')}
            </span>
            <Link
              to="/track-assignment"
              className="saved-worksheet-assignments__link--all"
            >
              {t(`create-assign-saved:main.seeAll`, 'see all assignments')}
            </Link>
          </div>
        </div>
      )}
      <div className="saved-worksheet__item-row">
        {worksheet.Files.length > 0 &&
          worksheet.Files.map(file => (
            <div key={file.ID} className="saved-worksheet-attachment">
              <a
                href="#noredirect"
                className="saved-worksheet-attachment__link"
              >
                <i className="icon-pdf" />
                {t(
                  `create-assign-saved:main.singSyll`,
                  'Singapore Maths Syllabus 2018....'
                )}
              </a>
            </div>
          ))}
      </div>
    </div>
  );
}
