import styled from 'styled-components';

export const Header = styled.div`
  background: white;
  position: fixed;
  width: calc(100vw - 195px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 0;
  box-shadow: 0 3px 6px rgba(129, 129, 129, 0.16);
  z-index: 10;
  .back-button {
    color: #545454;
    cursor: pointer;
    width: 100px;
    font-size: 0.94rem;
    position: absolute;
    margin-left: 1.5rem;
    &:hover {
      color: #ff5b00;
    }
  }

  @media (max-width: 1024px) {
    width: calc(100vw - 56px);
  }
  @media (max-width: 639px) {
    width: 100vw;
  }
`;

export const SkillInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  row-gap: 10px;
  padding-left: 10%;
  width: -webkit-fill-available;
  padding-right: 5%;

  .skill-label {
    flex-grow: 0.8;
    margin-bottom: 0;
    font-weight: 700;
    color: #363636;
    max-width: 450px;
  }

  .dropdown-container {
    flex-grow: 1;
    max-width: 415px;
    @media (max-width: 882px) {
      max-width: 250px;
    }
  }

  .dropdown-custom {
    width: 100%;
    margin-left: 10px;
    border: 1px solid rgba(112, 112, 112, 0.48);
    background-color: #ffffff;
    font-size: 14px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 882px) {
      font-size: 12px;
    }
    @media (max-width: 744px) {
      font-size: 10px;
    }
  }

  .skills {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    position: relative;
    max-width: 380px;
    &::after {
      position: absolute;
      top: 50%;
      right: 6px;
      transform: translateY(-50%);
    }
  }

  .menu-custom {
    width: -webkit-fill-available;
  }

  .item-custom {
    white-space: break-spaces;
    display: block;
    position: relative;
  }

  .right {
    flex-grow: 0.5;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .right-progress-bar {
      width: 118px;
      margin-right: 0.8rem;
    }
    .progress-label {
      color: #363636;
      font-size: 0.625rem;
      span {
        font-weight: bold;
      }
    }
  }
`;
