import styled from 'styled-components';

const SkillDetailPage = styled.div`
  background-color: #ffffff;
  padding-left: 195px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @media (max-width: 1024px) {
    padding-left: 56px;
  }
  @media (max-width: 639px) {
    padding-left: 0;
  }
  .error {
    width: 100%;
    text-align: center;
    margin-top: 2rem;
  }
  .content-detail {
    margin-top: 6rem;
  }
`;

export default SkillDetailPage;
