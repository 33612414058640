import React from 'react';
import { sort } from 'ramda';
import { useDispatch } from 'react-redux';
import { selectSkill } from 'stores/skills/actions';
import SkillItem from 'components/Content/SkillItem';
import SkillsLoader from 'components/Loader/SkillsLoader';

const SkillsList = ({ isLoading, skills }) => {
  const sortedSkills = sort((a, b) => a.Display - b.Display, skills);
  const dispatch = useDispatch();
  const sortByDisplay = (a, b) => a.Display - b.Display;
  return (
    <ul className="list-skills">
      {isLoading
        ? [...Array(8)].map((y, index) => <SkillsLoader key={String(index)} />)
        : sortedSkills.sort(sortByDisplay).map((skill, index) => (
            <li key={String(index)} className="a01|a02|a03">
              <a
                href="#noredirect"
                onClick={e => {
                  e.preventDefault();
                  dispatch(selectSkill(skill));
                }}
              >
                <SkillItem skill={skill} />
              </a>
            </li>
          ))}
    </ul>
  );
};

export default SkillsList;
