import React from 'react';
import moment from 'moment';

import LoginIcon from 'assets/img/icon-login-report-login-pdf.png';
import NotLoginIcon from '../../assets/img/icon-login-report-not-login-pdf.png';
import NotLoginYetIcon from 'assets/img/icon-login-report-login-not-active-pdf.png';

import Muli from 'assets/fonts/Muli.ttf';
import MuliB from 'assets/fonts/MuliB.ttf';

import {
  Page,
  Text,
  Document,
  StyleSheet,
  Image,
  View,
  Font
} from '@react-pdf/renderer';

const LoginReportPDF = ({
  data,
  weekId,
  selectedLevel,
  selectedClass,
  date
}) => {
  Font.register({
    family: 'Muli',
    fonts: [{ src: Muli }, { src: MuliB, fontWeight: 700 }]
  });

  const styles = StyleSheet.create({
    body: {
      paddingHorizontal: '35px',
      fontFamily: 'Muli'
    },
    title: {
      fontSize: 14,
      textAlign: 'center',
      color: '#FF5B00',
      fontWeight: 700,
      marginTop: '35px'
    },
    category: {
      fontSize: 10,
      color: '#727272'
    },
    content: {
      fontSize: 12,
      color: '#363636',
      fontWeight: 700
    },
    items: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: '20px',
      marginBottom: '20px'
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#333',
      borderRightWidth: 0,
      borderBottomWidth: 0
    },
    tableRow: { margin: 'auto', flexDirection: 'row' },
    tableColHead: {
      width: '25%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#333',
      borderLeftWidth: 0,
      borderTopWidth: 0,
      backgroundColor: '#FFF2CF'
    },
    tableCol: {
      width: '25%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#333',
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableColDaysHead: {
      width: '9.35%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#333',
      borderLeftWidth: 0,
      borderTopWidth: 0,
      backgroundColor: '#FFF2CF'
    },
    tableColDays: {
      width: '9.37%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#333',
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableCell: {
      margin: 'auto',
      marginTop: 5,
      marginBottom: 5,
      fontSize: 10,
      color: '#545454',
      fontWeight: 700
    },
    tableCellHead: {
      margin: 'auto',
      marginTop: 10,
      marginBottom: 5,
      fontSize: 10,
      color: '#545454'
    },
    tableCellDay: {
      margin: 'auto',
      marginTop: 5,
      fontSize: 10,
      color: '#FF5B00',
      textTransform: 'uppercase'
    },
    tableCellDate: {
      margin: 'auto',
      marginBottom: 5,
      fontSize: 10,
      color: '#FF5B00'
    },
    tableCellStu: {
      margin: 5,
      fontSize: 10,
      textAlign: 'left',
      color: '#363636',
      fontWeight: 700
    },
    pageNumber: {
      fontFamily: 'Muli',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      textAlign: 'center',
      borderColor: '#333',
      fontSize: 10
    }
  });

  const icon = day => {
    if (day === 1) {
      return <Image cache={false} src={LoginIcon}></Image>;
    }
    if (day === 0) {
      return <Image cache={false} src={NotLoginIcon}></Image>;
    }
    if (day === 2) {
      return <Image cache={false} src={NotLoginYetIcon}></Image>;
    }
  };

  // date
  let currentDate = new Date();
  let days = [];
  for (let i = 0; i <= 6; i++) {
    let first = currentDate.getDate() - currentDate.getDay() + i;
    let day = new Date(currentDate.setDate(first));
    days.push(day);
  }

  return (
    <Document>
      <Page size="A4" style={styles.body} wrap>
        <Text style={styles.title} fixed>
          Login Report
        </Text>
        <View style={styles.items} fixed>
          <View>
            <Text style={styles.category}>Level</Text>
            <Text style={styles.content}>{`Primary ${selectedLevel}`}</Text>
          </View>
          <View>
            <Text style={styles.category}>Class</Text>
            <Text style={styles.content}>{selectedClass}</Text>
          </View>
          <View>
            <Text style={styles.category}>Date</Text>
            <Text style={styles.content}>{date}</Text>
          </View>
        </View>
        {/* Table */}
        <View style={styles.table}>
          <View style={styles.tableRow} fixed>
            <View style={styles.tableColHead}>
              <Text style={styles.tableCellHead}>Student Name</Text>
            </View>
            <View style={styles.tableColDaysHead}>
              <Text style={styles.tableCellHead}>Total</Text>
            </View>
            {days.map(day => (
              <View style={styles.tableColDaysHead}>
                <Text style={styles.tableCellDay}>
                  {moment(day).format('ddd')}
                </Text>
                <Text style={styles.tableCellDay}>
                  {moment(day)
                    .subtract(weekId * 7, 'days')
                    .format('DD/MM')}
                </Text>
              </View>
            ))}
          </View>
          {data &&
            data.map(item => (
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellStu}>{item.Name}</Text>
                </View>
                <View style={styles.tableColDays}>
                  <Text style={styles.tableCell}>{item.Total}</Text>
                </View>
                {item.LoginStatus.map(status => (
                  <View style={styles.tableColDays}>
                    <Text style={styles.tableCell}>{icon(status)}</Text>
                  </View>
                ))}
              </View>
            ))}
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </View>
      </Page>
    </Document>
  );
};

export default LoginReportPDF;
