import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import groupIcon from 'assets/img/Group_icon.svg';
import groupLogo from 'assets/img/group-logo-white.svg';
import smallGrpIcon from 'assets/img/icon-small-group.svg';
// import reportIcon from 'assets/img/icon-report-group.svg';

import GroupPopoverMenu from 'components/ManageClass/GroupPopoverMenu';
import GroupDeleteModal from 'components/ManageClass/GroupDeleteModal';
import PopupModal from 'components/Shared/PopupModal';
import moment from 'moment';
import history from 'helpers/history';
import { fetchMyGroupRequest } from 'stores/createGroup/actions';
import { ReactComponent as Triangle } from './triangle.svg';
import {
  NoGroupList,
  GroupLists,
  GroupCard,
  StyledSortingBtnGroup,
  StyledSortingBtn
} from './GroupList.styles';

const GroupList = ({ list }) => {
  const { t } = useTranslation(['manage-class-group']);
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [selectedGroupID, setSelectedGroupID] = useState('');
  const [selectedGroupName, setSelectedGroupName] = useState('');
  const [selectSorting, setSelectSorting] = useState('name-asc'); // ['name-asc', 'name-desc','time-asc', 'time-desc']
  const showDeleteModal = () => setShowModal(true);

  const createSelectedList = val => {
    const finalObj = {};
    val.forEach(elem => {
      const keyName = elem.ClassID;
      if (finalObj[keyName] === undefined) {
        finalObj[keyName] = [
          {
            userId: elem.UserId,
            userName: elem.FullName,
            classname: elem.ClassName || ''
          }
        ];
      } else {
        finalObj[keyName].push({
          userId: elem.UserId,
          userName: elem.FullName,
          classname: elem.ClassName || ''
        });
      }
    });
    return finalObj;
  };

  const editGroup = user => {
    const Students = user.Students.map(elem => {
      return { userId: elem.UserId };
    });
    const selectedStudents = createSelectedList(user.Students);

    history.push({
      pathname: '/manage-class/create-group',
      state: {
        Students,
        selectedStudents,
        groupName: user.Name,
        id: user.ID
      }
    });
  };

  const goToCreateGroup = () => history.push('/manage-class/create-group');

  const deleteSuccess = () => {
    setShowModal(false);
    dispatch(fetchMyGroupRequest());
  };

  return (list && list.length) === 0 ? (
    <NoGroupFound goToCreateGroup={goToCreateGroup} t={t} />
  ) : (
    <GroupLists>
      <div className="group-list-header">
        <div />
        <div className="my-group-heading">
          <img src={groupLogo} alt="icon-group-logo" />
          <span>{t(`manage-class-group:header.myGroup`, 'My Groups')}</span>
        </div>
        <button
          type="submit"
          className="btn btn-lg btn-primary create-group-btn"
          onClick={goToCreateGroup}
        >
          + {t(`manage-class-group:header.createGroup`, 'Create New Group')}
        </button>
      </div>

      <div className="group-list-body">
        <div className="group-list-body-header">
          <div className="header-grp-name">
            <StyledSortingBtnGroup>
              <StyledSortingBtn
                onClick={() => setSelectSorting('name-desc')}
                active={selectSorting === 'name-desc'}
              >
                <Triangle />
              </StyledSortingBtn>
              <StyledSortingBtn
                className="up-btn"
                onClick={() => setSelectSorting('name-asc')}
                active={selectSorting === 'name-asc'}
              >
                <Triangle />
              </StyledSortingBtn>
            </StyledSortingBtnGroup>
            {t(`manage-class-group:table.grpName`, 'Group name')}
          </div>
          <div className="header-created-time">
            <StyledSortingBtnGroup>
              <StyledSortingBtn
                onClick={() => setSelectSorting('time-desc')}
                active={selectSorting === 'time-desc'}
              >
                <Triangle />
              </StyledSortingBtn>
              <StyledSortingBtn
                className="up-btn"
                onClick={() => setSelectSorting('time-asc')}
                active={selectSorting === 'time-asc'}
              >
                <Triangle />
              </StyledSortingBtn>
            </StyledSortingBtnGroup>
            {t(`manage-class-group:table.creationTime`, 'Creation Time')}
          </div>
          <div className="header-members">
            {t(`manage-class-group:table.members`, 'Members')}
          </div>
        </div>
        {(list || [])
          .toSorted((a, b) => {
            if (selectSorting === 'name-asc')
              return a.Name.localeCompare(b.Name);
            if (selectSorting === 'name-desc')
              return b.Name.localeCompare(a.Name);
            if (selectSorting === 'time-asc')
              return moment(a.CreateDate).isBefore(b.CreateDate) ? -1 : 1;
            if (selectSorting === 'time-desc')
              return moment(a.CreateDate).isBefore(b.CreateDate) ? 1 : -1;
            return 0;
          })
          .map(elem => (
            <GroupCard key={elem.ID}>
              <div className="f2 group-name">{elem.Name}</div>
              <div className="f1 group-date">
                {moment(elem.CreateDate).format('hh:mm a DD/MM/YYYY')}
              </div>
              <div className="f1 group-stu">
                <img src={smallGrpIcon} alt="students" />
                <span className="group-text-stu" tabIndex="1">
                  {elem.Students.length}
                  {t(`manage-class-group:table.students`, ' students')}
                  <div className="show-added-students">
                    <ShowList list={createSelectedList(elem.Students)} />
                  </div>
                </span>
              </div>
              <div className="f1 group-more">
                {/* <button className="report-gtp-btn">
                <img src={reportIcon} alt="icon-group-report" />
                Report
              </button> */}
                <GroupPopoverMenu
                  openDeleteModal={showDeleteModal}
                  setIdNameForDelete={() => {
                    setSelectedGroupID(elem.ID);
                    setSelectedGroupName(elem.Name);
                  }}
                  editGroup={() => editGroup(elem)}
                />
              </div>
            </GroupCard>
          ))}
        <PopupModal show={showModal} hide={() => setShowModal(false)}>
          <GroupDeleteModal
            hide={() => setShowModal(false)}
            deleteSuccess={deleteSuccess}
            groupId={selectedGroupID}
            groupName={selectedGroupName}
          />
        </PopupModal>
      </div>
    </GroupLists>
  );
};

export default GroupList;

const NoGroupFound = ({ goToCreateGroup, t }) => (
  <NoGroupList>
    <div>
      <img src={groupIcon} alt="create-group-icon" className="show-hide" />
    </div>
    <div className="no-group-text">
      <div className="first-line">
        {t(
          `manage-class-group:table.noGroupMsg`,
          `You haven't created any groups.`
        )}
      </div>
      <div className="second-line">
        {t(
          `manage-class-group:table.noGroupDesc`,
          'Click the button below to create new group.'
        )}
      </div>
    </div>
    <button
      type="submit"
      className="btn btn-lg btn-primary create-group-btn"
      onClick={goToCreateGroup}
    >
      {t(`manage-class-group:header.createGroup`, 'Create New Group')}
    </button>
  </NoGroupList>
);

const ShowList = ({ list }) => {
  return (
    <div className="scroll-list-stu">
      {Object.keys(list).map(val => (
        <div key={val} className="list-class-title">
          <span>
            {val && list[val] && list[val].length ? list[val][0].classname : ''}
          </span>
          {list[val].map(elem => (
            <div key={elem.userId}>{elem.userName}</div>
          ))}
        </div>
      ))}
    </div>
  );
};
