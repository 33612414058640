import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAssigmentType,
  setSettingTimeValue
} from 'stores/worksheet/actions';
import { assignmentTypes, timeSettingTypes } from 'constants/index';
import isNilOrEmpty from 'helpers/isNilOrEmpty';
import useClickOutside from 'helpers/useClickOutside';
import intervalTimeImg from 'assets/img/factual-fluency/interval-time.gif';
import totalTimeImg from 'assets/img/factual-fluency/total-time.png';
import noTimeImg from 'assets/img/factual-fluency/no-time.png';
import { range } from 'ramda';
import {
  SettingCard,
  WorksheetTimeStyled,
  CardTop,
  CardBottom,
  Overlay
} from './ChooseWorkSheetTimeType.styles';

const ChooseWorkSheetTimeType = () => {
  const { t } = useTranslation(['create-assign-question']);
  const dispatch = useDispatch();

  const { assignmentType, timeLimit } = useSelector(state => state.worksheet);

  const [minute, setMinute] = useState(
    // eslint-disable-next-line no-nested-ternary
    timeLimit ? (timeLimit / 60 > 1 ? timeLimit / 60 : 3) : 3
  );
  const [second, setSecond] = useState(timeLimit || 3);
  const [toggleInfo, setToggleInfo] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const clickRef = useRef();

  const currentSelected = document.getElementById('interval-options');
  const currentSecond = parseInt(
    currentSelected?.selectedOptions[0]?.value,
    10
  );

  useClickOutside(clickRef, () => {
    if (showInfo) {
      setShowInfo(false);
    }
  });

  const assignmentTypeID = isNilOrEmpty(assignmentType)
    ? assignmentTypes.interval
    : parseInt(assignmentType, 10);

  useEffect(() => {
    dispatch(setAssigmentType(assignmentTypeID));
  }, [assignmentTypeID, dispatch]);

  useEffect(() => {
    switch (assignmentTypeID) {
      case assignmentTypes.interval:
        dispatch(setSettingTimeValue(second));
        break;
      case assignmentTypes.total:
        dispatch(setSettingTimeValue(minute * 60));
        break;
      default:
        dispatch(setSettingTimeValue(0));
        break;
    }
    // eslint-disable-next-line
  }, [assignmentTypeID]);

  const handleOnChange = value => {
    const type = Object.keys(assignmentTypes).find(
      key => assignmentTypes[key] === value
    );

    if (type === 'interval') {
      setSecond(currentSecond);
    }
    dispatch(setAssigmentType(value));
  };

  const images = {
    intervalTime: intervalTimeImg,
    totalTime: totalTimeImg,
    noTime: noTimeImg
  };

  const interval = range(3, 11);

  const infoHandler = (e, type) => {
    e.stopPropagation();
    setShowInfo(!showInfo);
    setToggleInfo(type);
  };

  return (
    <WorksheetTimeStyled>
      {Object.keys(timeSettingTypes).map(type => {
        const checked = assignmentTypeID === timeSettingTypes[type].value;
        return (
          <SettingCard
            key={`type-${type}`}
            selected={checked}
            onClick={() => handleOnChange(timeSettingTypes[type].value)}
          >
            <CardTop selected={checked}>
              <div className="form-check">
                <input
                  type="radio"
                  id={`type-${type}`}
                  value={timeSettingTypes[type].value}
                  label={t(
                    `create-assign-question:timeSetting.${type}`,
                    timeSettingTypes[type].name
                  )}
                  checked={checked}
                  className="form-check-radio"
                />
                <span className="custom-radio" />
                <Form.Label className="types-label" htmlFor={`type-${type}`}>
                  {t(
                    `create-assign-question:timeSetting.${type}`,
                    timeSettingTypes[type].name
                  )}
                </Form.Label>
                {type !== 'noTime' && (
                  <button
                    className="info"
                    type="button"
                    onClick={e => infoHandler(e, type)}
                    id={type}
                  >
                    ?
                    {showInfo && toggleInfo === type && (
                      <Overlay ref={clickRef}>
                        <p>
                          {type === 'intervalTime'
                            ? `${t(
                                `create-assign-question:timeSetting.intervalTimeInfo`,
                                'You can set interval time between two questions'
                              )}`
                            : `${t(
                                `create-assign-question:timeSetting.totalTimeInfo`,
                                'You can set total time for this worksheet'
                              )}`}
                        </p>
                      </Overlay>
                    )}
                  </button>
                )}
              </div>

              {type === 'intervalTime' && (
                <Form.Control
                  size="sm"
                  as="select"
                  onChange={e =>
                    dispatch(setSettingTimeValue(parseInt(e.target.value, 10)))
                  }
                  defaultValue={second}
                  className="interval-options"
                  id="interval-options"
                >
                  {interval.map(time => (
                    <option
                      value={time}
                      key={`option-${time}`}
                      className="option-list"
                    >
                      {time}{' '}
                      {t('create-assign-question:timeSetting.Sec', 'Sec')}
                    </option>
                  ))}
                </Form.Control>
              )}

              {type === 'totalTime' && (
                <div className="total-time">
                  <Form.Control
                    className="total-time-input"
                    size="sm"
                    as="input"
                    onChange={e => {
                      setMinute(e.target.value);
                      dispatch(setSettingTimeValue(e.target.value * 60));
                    }}
                    value={minute}
                  />
                  <span>
                    {t('create-assign-question:timeSetting.Min', 'min')}
                  </span>
                </div>
              )}
            </CardTop>
            <CardBottom>
              <p>
                {t('create-assign-question:timeSetting.example', 'Example')}
              </p>
              <div className="form-image">
                {type === 'totalTime' && <div className="rect" />}
                <img
                  src={images[type]}
                  alt={`Choose Worksheet Time Type ${type}`}
                />
              </div>
            </CardBottom>
          </SettingCard>
        );
      })}
    </WorksheetTimeStyled>
  );
};

export default React.memo(ChooseWorkSheetTimeType);
