import React from 'react';
import { isEmpty, isNil } from 'ramda';
import { CurriculumCardContainer } from './CurriculumCard.styles';
import C1 from './icon-c1.svg';

const CurriculumCard = ({
  curriculumName,
  curriculumIcon,
  curriculum,
  setSelectedModalCurriculum,
  selectedModalCurriculum,
  currentLevel,
  setSelectedModalLevel
}) => {
  const level = selectCurr => {
    const checkLevelAvailable =
      selectCurr && selectCurr.Levels.find(item => item.ID === currentLevel.ID);
    if (!isEmpty(selectCurr.Levels)) {
      if (isNil(checkLevelAvailable)) {
        return selectCurr.Levels[0];
        // eslint-disable-next-line no-else-return
      } else {
        return currentLevel;
      }
      // eslint-disable-next-line no-else-return
    } else {
      return { ID: -1 };
    }
  };

  return (
    <CurriculumCardContainer>
      <div
        className={`card-content ${selectedModalCurriculum.CurriculumID ===
          curriculum.CurriculumID && 'selected'}`}
        onClick={() => {
          setSelectedModalCurriculum(curriculum);
          setSelectedModalLevel(level(curriculum));
        }}
      >
        <p>{curriculumName}</p>
        <img
          onError={e => {
            e.target.src = C1;
          }}
          src={curriculumIcon}
          alt="curriculum-icon"
        />
      </div>
    </CurriculumCardContainer>
  );
};

export default CurriculumCard;
