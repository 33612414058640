import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fetchClassesRequest } from 'stores/manage-class/actions';
import moment from 'moment';
import { fetchProfileDataRequest } from 'stores/profileSettings/actions';
// import LoginReportCard from './components/LoginReportCard';
import LoginReportCard from 'components/Dashboard/LoginReportCard';
import Header from 'components/Header/Header';
import AssignmentTrackingCard from './components/AssignmentTrackingCard';
import dashboardCards from './constants/dashboardCards';
import {
  BottomCard,
  Content,
  StyledCardRow,
  StyledInnerCardRow,
  StyledCard,
  StyledWelcomeText,
  MainContent
} from './DashboardV2.styled';

const DashboardV2 = () => {
  const { t } = useTranslation(['dashboard']);
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading, profileData } = useSelector(
    state => state.profileSettings
  );

  useEffect(() => {
    dispatch(fetchProfileDataRequest());
    dispatch(fetchClassesRequest({ year: moment().format('YYYY') }));
  }, [dispatch]);

  const redirectToPage = ({ url, urlType }) => {
    if (urlType === 'NEW_TAB') {
      return window.open(url, '_blank', 'noreferrer');
    }
    return history.push(url);
  };

  return (
    <Content>
      <Header />
      <MainContent>
        {isLoading && 'Loading...'}
        {!isLoading && (
          <StyledWelcomeText>
            <h1>{t('dashboard:greeting.hello', 'Hello')},</h1>
            <h2 className="user-name">{profileData.FullName}</h2>
            <h2 className="class-name">{profileData.SchoolName}</h2>
          </StyledWelcomeText>
        )}
        <hr className="dashed-line" />
        <StyledCardRow>
          {dashboardCards.map(dashboardCard => (
            <StyledCard
              key={dashboardCard.id}
              backgroundcolor={dashboardCard.backgroundColor}
              onClick={() => redirectToPage(dashboardCard)}
              style={{
                marginRight:
                  dashboardCards.length !== dashboardCard.id && '15px'
              }}
            >
              <StyledInnerCardRow title={dashboardCard.title}>
                <Card.Body>
                  <Card.Title className="title">
                    {t(
                      `dashboard:navigation.${dashboardCard.title}`,
                      dashboardCard.title
                    )}
                  </Card.Title>
                  <Card.Text className="subtitle">
                    {t(
                      `dashboard:navigation.${dashboardCard.subTitle}`,
                      dashboardCard.subTitle
                    )}
                  </Card.Text>
                </Card.Body>
                <Card.Img className="card-img" src={dashboardCard.icon} />
              </StyledInnerCardRow>
            </StyledCard>
          ))}
        </StyledCardRow>
        <Row>
          <Col md={5}>
            <BottomCard backgroundcolor="#FFFFFF" className="">
              <Card.Title className="graph-subtitle">
                {t('dashboard:loginReport.title', 'Student Login')}
              </Card.Title>
              <LoginReportCard />
            </BottomCard>
          </Col>
          <Col md={7}>
            <BottomCard backgroundcolor="#FFFFFF">
              <Card.Title className="graph-subtitle">
                {t(
                  'dashboard:assignmentTracking.title',
                  'Latest Assignment Tracking'
                )}
              </Card.Title>
              <AssignmentTrackingCard />
            </BottomCard>
          </Col>
        </Row>
      </MainContent>
    </Content>
  );
};

export default DashboardV2;
