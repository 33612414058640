import React from 'react';
import { Form } from 'react-bootstrap';

export default function PlainText(props) {
  const { label, value, children } = props;
  return (
    <div className={`form-group ${label ? 'row' : ''}`}>
      {label && (
        <Form.Label className="plain-text__label col-sm-4 col-form-label">
          {label}
        </Form.Label>
      )}
      <div className={`${label ? 'plain-text__value col-sm-8' : ''}`}>
        {value && <span className="form-control-plaintext">{value}</span>}
        {children && <div className="form-control-plaintext">{children}</div>}
      </div>
    </div>
  );
}
