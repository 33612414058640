import styled from 'styled-components';

const VideoOverlayContainer = styled.div`
  width: 225px;
  height: 127.5px;
  position: absolute;
  .video-thumbnail {
    position: absolute;
    top: 0;
    left: 0;
  }
  .play-video {
    background-color: #474747;
    opacity: 0;
    display: flex;
    width: 225px;
    height: 127.5px;
    align-items: center;
    justify-content: center;
    .play-icon {
      width: 50%;
      height: 50%;
    }
  }
  .play-video:hover {
    background-color: #f9caa6;
    opacity: 0.4;
    cursor: pointer;
  }
  .disabled-video {
    background-color: #474747;
    opacity: 0.9;
    width: 225px;
    height: 127.5px;
    display: flex;
    .text {
      color: white;
      padding: 20px;
    }
  }
  .disabled-video:hover {
    cursor: pointer;
  }
`;

export default VideoOverlayContainer;
