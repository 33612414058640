import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 0 30px;
  position: relative;
  top: 14.6rem;
`;
export const SkillList = styled.div`
  background: white;
  text-align: left;
  width: 20%;
  border: 1px solid #e9e9e9;
  margin-bottom: 30px;
`;
export const SkillItem = styled.div`
  padding: 21px 20px 21px 10px;
  color: #363636;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #e9e9e9;
  &:nth-child(even) {
    background-color: #fbfbfb;
  }
  &:last-child {
    border-bottom: none;
  }
  :hover:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 0px 2px #ff5b00 inset;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
