import {
  FETCH_CART_QUESTIONS_FAILED,
  FETCH_CART_QUESTIONS_REQUEST,
  FETCH_CART_QUESTIONS_SUCCESS,
  FETCH_QUESTIONS_FAILED,
  FETCH_QUESTIONS_REQUEST,
  FETCH_QUESTIONS_SUCCESS,
  RESET_SELECTED_QUESTION,
  SELECTED_QUESTION,
  SELECTING_QUESTION,
  SELECTING_QUESTIONS,
  RESET_SELECTED_QUESTIONS,
  SEARCHING_QUESTIONS,
  SEARCH_QUESTIONS_SUCCESS,
  SEARCH_QUESTIONS_FAILED,
  RESET_SEARCH_QUESTIONS,
  ADD_QUESTIONS_TO_CART,
  RESET_CART_QUESTIONS
} from './actionTypes';

const initialState = {
  cartQuestions: [],
  error: '',
  isLoading: false,
  questions: [],
  selectedQuestion: {},
  selectedQuestions: [],
  searchedQuestions: []
};

export default function questions(state = initialState, action) {
  switch (action.type) {
    case FETCH_CART_QUESTIONS_REQUEST:
    case FETCH_QUESTIONS_REQUEST:
    case SELECTING_QUESTION:
    case SEARCHING_QUESTIONS:
    case ADD_QUESTIONS_TO_CART:
    case RESET_CART_QUESTIONS:
      return {
        ...state,
        isLoading: true
      };

    case FETCH_CART_QUESTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
        cartQuestions: action.cartQuestions
      };
    case FETCH_QUESTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
        questions: action.questions
      };
    case SELECTED_QUESTION:
      return {
        ...state,
        isLoading: false,
        error: '',
        selectedQuestion: {
          ...state.selectedQuestion,
          [action.selectedQuestion.VariationId]: action.selectedQuestion
        }
      };
    case RESET_SELECTED_QUESTION:
      return {
        ...state,
        error: '',
        selectedQuestion: {}
      };
    case FETCH_CART_QUESTIONS_FAILED:
    case FETCH_QUESTIONS_FAILED:
    case SEARCH_QUESTIONS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case SELECTING_QUESTIONS:
      return {
        ...state,
        isLoading: false,
        selectedQuestions: action.selectedQuestions
      };
    case RESET_SELECTED_QUESTIONS:
      return {
        ...state,
        isLoading: false,
        error: '',
        selectedQuestions: []
      };
    case SEARCH_QUESTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
        searchedQuestions: action.questionsResult
      };
    case RESET_SEARCH_QUESTIONS:
      return {
        ...state,
        isLoading: false,
        error: '',
        searchedQuestions: []
      };
    default:
      return state;
  }
}
