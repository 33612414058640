import React, { createRef, useEffect, useState } from 'react';
import { isEmpty } from 'ramda';
import { useSelector } from 'react-redux';
import useWindows from 'helpers/useWindowSize';
import QuestionsView from 'components/Content/QuestionsView';
import SkillsList from 'components/Content/SkillsList';
import ContentWrap from 'components/Content/ContentWrap';
import QuestionFilter from 'components/Content/QuestionsFilter';
import SkillsResult from 'components/Content/SkillsResult';

const QuestionList = () => {
  const subbarRef = createRef();
  const { width } = useWindows();
  const [subbarHeight, setSubbarHeight] = useState(0);
  const { skills, selectedSkill, isLoading } = useSelector(
    state => state.skills
  );
  useEffect(() => {
    setSubbarHeight(subbarRef.current?.clientHeight);
  }, [subbarRef, width]);
  return (
    <>
      <QuestionFilter />
      <SkillsResult />
      <ContentWrap paddingTop={subbarHeight}>
        {isEmpty(selectedSkill) ? (
          <SkillsList isLoading={isLoading} skills={skills} />
        ) : (
          <QuestionsView />
        )}
      </ContentWrap>
    </>
  );
};

export default QuestionList;
