import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 100px;
  font-family: 'Linotte Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  }
`;

export const FactualFluencyBody = styled.div``;
export const FactualFluencySideBar = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  width: 270px;
  background-color: #4484d2;
  border-left: 0;
  top: 100px;
`;

export const ButtonSkip = styled.button`
  font-size: 36px;
  margin-right: 0;
  margin-left: auto;
  background-color: #094a9b;
  color: #fff;
  border: 0;
  display: block;
  padding: 10px 20px 5px 30px;
`;

export const ButtonPrev = styled.button`
  font-size: 36px;
  margin-right: 0;
  margin-left: auto;
  background-color: #094a9b;
  color: #fff;
  border: 0;
  display: block;
  position: fixed;
  left: 0;
  bottom: 80px;
  border-radius: 0 32px 32px 0;
  padding: 3px 30px 5px 20px;
  font-weight: 800;
`;

export const ButtonNext = styled.button`
  font-size: 36px;
  margin-right: 0;
  margin-left: auto;
  background-color: #094a9b;
  color: #fff;
  border: 0;
  display: block;
  padding: 3px 20px 5px 30px;
  position: fixed;
  right: 0;
  bottom: 80px;
  border-radius: 32px 0 0 32px;
  font-weight: 800;
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 1040;
`;

export const Countdown = styled.div`
  width: 484px;
  height: 484px;
  border-radius: 50%;
  background-color: #e06400;
  border: 40px solid #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -242px 0 0 -242px;
  z-index: 1050;
  font-size: 210px;
  text-align: center;
  color: #fff;
  line-height: 404px;
  justify-content: center;
  font-weight: 700;
`;
