import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { resetPwRequest } from 'stores/manage-class/actions';
import successIcon from 'assets/img/icon-manage-class-success-check.svg';
import { ResetPwContainer, Sample } from './ResetPwModalBody.styles';

export default function ResetPwModalBody({ hide, studentID }) {
  const { t } = useTranslation(['manage-class']);
  const dispatch = useDispatch();
  const { isLoading, resetPwResult, error } = useSelector(
    state => state.manageClasses
  );
  return (
    <ResetPwContainer>
      {resetPwResult === true ? (
        <>
          <span className="success-title">
            <img
              src={successIcon}
              alt="Success icon"
              style={{ marginRight: '0.5rem' }}
            />
            {t(`manage-class:resetModal.success`, 'Success!')}
          </span>
          <p className="desc">
            <Trans i18nKey="manage-class:resetModal.successMsg">
              You have successfully reset to password
              <strong>to the same as the student’s Login ID!</strong>
            </Trans>
          </p>
          <Sample>
            <p>
              <Trans i18nKey="manage-class:resetModal.sample1">
                e.g. Login ID: <strong>abc123</strong>
              </Trans>
            </p>
            <p>
              <Trans i18nKey="manage-class:resetModal.sample2">
                Reset Password: <strong>abc123</strong>
              </Trans>
            </p>
          </Sample>
          <Button
            variant="success-ok"
            onClick={() => {
              hide();
            }}
          >
            {t(`manage-class:studModal.ok`, 'OK')}
          </Button>
        </>
      ) : (
        <>
          <p className="reset-title">
            {t(`manage-class:resetModal.resetPswd`, 'Reset Password')}
          </p>
          <p>
            {t(
              `manage-class:resetModal.resetConfirm`,
              'Do you want to reset the password?'
            )}
          </p>
          {error && <span className="err">{error}</span>}
          <div className="selection-action-buttons">
            <Button
              variant="outline-cancel"
              onClick={() => {
                hide();
              }}
            >
              {t(`manage-class:resetModal.no`, 'No')}
            </Button>
            <Button
              variant="ok"
              onClick={() => {
                dispatch(
                  resetPwRequest({
                    studentID
                  })
                );
              }}
            >
              {t(`manage-class:resetModal.yes`, 'Yes')}
              {isLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="ml-2"
                />
              )}
            </Button>
          </div>
        </>
      )}
    </ResetPwContainer>
  );
}
