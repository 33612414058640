import styled from 'styled-components';

export const GroupsListPage = styled.div`
  padding-left: 205px;
  @media (max-width: 1024px) {
    padding-left: 66px;
  }
  @media (max-width: 640px) {
    padding-left: 0;
  }
  h5 {
    width: 100%;
    margin: 0.625rem auto 1.875rem auto;
    text-align: center;
  }
  .btn-private {
    padding-bottom: 3px;
    padding-top: 3px;
    margin-left: 15px;
    float: right;
    border: solid 1px rgba(114, 114, 114, 0.15);
    color: #ff5b00;
    &-create {
      float: right;
      margin-top: -55px;
    }
  }
  .list-group {
    .list-group-item {
      vertical-align: center;
      font-size: 18px;
      .list-group-initial {
        min-width: 2.25rem;
        display: inline-block;
        padding-top: 5px;
        padding-bottom: 5px;
        text-transform: uppercase;
        text-align: center;
        border-radius: 50%;
        margin-right: 12px;
        color: #fff;
        background-color: #ff5b00;
      }
    }
  }
`;
