import { call, put } from 'redux-saga/effects';
import { isNil } from 'ramda';
import {
  createFactualFluencyError,
  createFactualFluencyFailed,
  createFactualFluencySuccess,
  fetchFactualFluencyLeaderboardFailed,
  fetchFactualFluencyLeaderboardSuccess,
  fetchTopicSkillFailed,
  fetchTopicSkillSuccess,
  noTopicSkill
} from 'stores/factual-fluency/actions';
import FactualFluencyService from 'services/factual-fluency';
import isNilOrEmpty from 'helpers/isNilOrEmpty';

export function* fetchTopicSkill({ LevelID }) {
  const data = yield call(FactualFluencyService.fetchTopicSkill, LevelID);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      const topics = data.filter(item => !isNilOrEmpty(item.Skills));
      yield put(fetchTopicSkillSuccess(topics));
    } else {
      if (data.error === 'No Data Found') yield put(noTopicSkill());
    }
  } else {
    yield put(fetchTopicSkillFailed(data.error));
  }
}

export function* createFactualFluency({ obj }) {
  const data = yield call(FactualFluencyService.createFactualFluency, obj);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(createFactualFluencySuccess(data));
    } else {
      if (data.error) {
        yield put(createFactualFluencyError(data.error));
      }
    }
  } else {
    yield put(createFactualFluencyFailed(data.error));
  }
}

export function* fetchLeaderboard({ homeworkID }) {
  const data = yield call(FactualFluencyService.fetchLeaderboard, homeworkID);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchFactualFluencyLeaderboardSuccess(data));
    } else {
      if (data.error) {
        yield put(fetchFactualFluencyLeaderboardFailed(data.error));
      }
    }
  } else {
    yield put(createFactualFluencyFailed(data.error));
  }
}
