import { compareValues } from 'helpers/singleClassReport';
import {
  FETCH_TOPIC_SKILL_REQUEST,
  FETCH_TOPIC_SKILL_SUCCESS,
  FETCH_TOPIC_SKILL_FAILED,
  NO_TOPIC_SKILL,
  CREATE_FACTUAL_FLUENCY_RESET,
  CREATE_FACTUAL_FLUENCY_REQUEST,
  CREATE_FACTUAL_FLUENCY_SUCCESS,
  CREATE_FACTUAL_FLUENCY_FAILED,
  CREATE_FACTUAL_FLUENCY_ERROR,
  SET_CREATE_FACTUAL_FLUENCY_QUESTIONS,
  REMOVE_CREATE_FACTUAL_FLUENCY_QUESTION,
  FETCH_FACTUAL_FLUENCY_LEADERBOARD_SUCCESS,
  FETCH_FACTUAL_FLUENCY_LEADERBOARD_REQUEST,
  FACTUAL_FLUENCY_LEADERBOARD_RESET,
  FETCH_FACTUAL_FLUENCY_LEADERBOARD_FAILED
} from './actionTypes';

const initialState = {
  topicSkill: [],
  createFactualFluency: {},
  createFactualFluencyItems: [],
  leaderboard: [],
  isLoading: false,
  createFactualFluencyLoading: false,
  error: ''
};

export default function factualFluency(state = initialState, action) {
  switch (action.type) {
    case FETCH_TOPIC_SKILL_REQUEST:
    case FETCH_FACTUAL_FLUENCY_LEADERBOARD_REQUEST:
      return { ...state, error: '', isLoading: true };
    case CREATE_FACTUAL_FLUENCY_REQUEST:
      return { ...state, error: '', createFactualFluencyLoading: true };
    case FETCH_TOPIC_SKILL_FAILED:
    case FETCH_FACTUAL_FLUENCY_LEADERBOARD_FAILED:
    case CREATE_FACTUAL_FLUENCY_FAILED:
    case CREATE_FACTUAL_FLUENCY_ERROR:
      return {
        ...state,
        isLoading: false,
        createFactualFluencyLoading: false,
        error: action.error
      };
    case NO_TOPIC_SKILL:
      return { ...state, isLoading: false, topicSkill: [] };
    case FETCH_TOPIC_SKILL_SUCCESS:
      return {
        ...initialState,
        isLoading: false,
        topicSkill: action.TopicSkill.sort(compareValues('Order', 'asc')) || []
      };
    case FETCH_FACTUAL_FLUENCY_LEADERBOARD_SUCCESS:
      return {
        ...initialState,
        isLoading: false,
        leaderboard: action.leaderboard || []
      };
    case FACTUAL_FLUENCY_LEADERBOARD_RESET:
      return {
        ...initialState,
        leaderboard: []
      };
    case CREATE_FACTUAL_FLUENCY_RESET:
      return {
        ...initialState,
        createFactualFluency: {}
      };
    case CREATE_FACTUAL_FLUENCY_SUCCESS:
      return {
        ...initialState,
        createFactualFluencyLoading: false,
        createFactualFluency: action.data,
        createFactualFluencyItems: action.data.Questions
      };
    case SET_CREATE_FACTUAL_FLUENCY_QUESTIONS:
      return {
        ...state,
        createFactualFluencyItems: action.questions
      };
    case REMOVE_CREATE_FACTUAL_FLUENCY_QUESTION:
      const updatedQuestions = state.createFactualFluencyItems.filter(
        question => question.ID !== action.Id
      );
      return { ...state, createFactualFluencyItems: updatedQuestions };
    default:
      return state;
  }
}
