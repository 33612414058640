import styled from 'styled-components';

export const MediaBody = styled.div`
  flex-direction: column;
  p {
    span {
      font-weight: 600;
    }
    line-height: 1.4285;
    font-size: 18px;
    color: #000;
    font-family: Arial, sans-serif;
  }
  @media print {
    td {
      font-size: 18px;
      color: #000;
      font-family: Arial, sans-serif;
    }
  }
`;

export const McqOptionsList = styled.ul`
  list-style-type: circle;
`;
