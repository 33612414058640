import React, { useState } from 'react';

export default function DropdownFilter({ filters, onCheckFilter }) {
  const [show, setShow] = useState(false);
  return (
    <div className={`dropwdown dropdown-filter ${show ? 'show' : ''}`}>
      <button
        type="button"
        className="btn btn-outline-secondary btn-xs"
        data-toggle="dropdown"
        onClick={() => setShow(!show)}
      >
        + Add Filter
      </button>
      <div
        className={`dropdown-menu ${show ? 'show' : ''}`}
        style={{
          position: 'absolute',
          willChange: 'transform',
          top: '0px',
          left: '0px',
          transform: 'translate3d(-163px, -120px, 0px)'
        }}
      >
        {filters.map((filter, index) => {
          const checked = filter.isActive;
          const id = `customCheckFilter${index}`;
          const handleCheck = () => onCheckFilter(filter, index);
          return (
            <div key={String(index)} className="custom-control custom-checkbox">
              <input
                checked={checked}
                className="custom-control-input"
                data-filtergroup={index}
                disabled={index === 1}
                id={id}
                onChange={handleCheck}
                type="checkbox"
              />
              {/* eslint-disable-next-line */}
              <label className="custom-control-label" htmlFor={id}>
                {filter.title}
                <div className="text-muted">
                  {Object.keys(filter.options).join(', ')}
                </div>
              </label>
            </div>
          );
        })}
        <div className="text-right mt-3">
          <button
            type="button"
            className="btn btn-primary btn-md"
            data-dismiss="dropdown-filter"
            onClick={() => setShow(!show)}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
}
