// Helper
function* range(start, end, step) {
  while (start <= end) {
    yield start;
    start += step;
  }
}

const generateLevels = () => {
  return Array.from(range(1, 6, 1));
};

export default generateLevels;
