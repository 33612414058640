import apiClient from 'services/api';

export default SIOID => {
  return apiClient
    .get(`Variation/GetVariationWithQuestionsBySIOId/${SIOID}`)
    .then(result => {
      return result.data?.Result ?? [];
    })
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch variations failed' // eslint-disable-line
      };
    });
};
