import React from 'react';
import { isNil } from 'ramda';
import HTMLReactParser from 'html-react-parser';
import questionParser from 'helpers/questionParser';
import convertTagsToHTML from 'helpers/text/convertTagsToHTML';
import {
  MediaBody,
  McqOptionsList,
  TableModal
} from './QuestionContentPrint.styles';

export default function QuestionContentPrint({ question }) {
  // jsx renders
  const { title, mcqOptions } = questionParser(question);
  const renderOptions = mcqOptions.map(option =>
    HTMLReactParser(convertTagsToHTML(option, false), {
      replace: domNode => {
        if (domNode.name === 'img') {
          domNode.attribs.style = 'width: 68%';
          return domNode;
        }
        return domNode;
      }
    })
  );
  const renderQuestion = HTMLReactParser(convertTagsToHTML(title), {
    replace: domNode => {
      if (domNode.name === 'img' && !isNil(domNode.attribs?.url)) {
        return (
          <img src={domNode.attribs?.url} alt={domNode.attribs?.uniqueid} />
        );
      }
      if (domNode.name === 'helptext') {
        return (
          <small id="questionHelp" className="form-text text-muted">
            {domNode.children[0]?.data ?? ''}
          </small>
        );
      }
      if (domNode.name === 'table') {
        domNode.attribs.cellspacing = '0';
        domNode.attribs.cellpadding = '0';
        domNode.attribs.style = 'align-content: center;';
        return domNode;
      }
      if (domNode.name === 'td') {
        if (
          domNode.attribs.hasOwnProperty('style') &&
          !domNode.attribs.style.includes('border')
        ) {
          domNode.attribs.style = 'width: 20%';
        }
        return domNode;
      }
      return domNode;
    }
  });
  return (
    <div className="media">
      <MediaBody>
        <TableModal>{renderQuestion}</TableModal>
        {mcqOptions.length > 0 && (
          <McqOptionsList>
            {renderOptions.map(option => (
              <li key={option}>{option}</li>
            ))}
          </McqOptionsList>
        )}
      </MediaBody>
    </div>
  );
}
