import React from 'react';
import wrongAnsIcon from 'assets/img/icon-track-assignment-wrong-ans.svg';
import { StyledContainer } from './ScoreCount.styles';

const ScoreCount = ({ type, num }) => {
  return (
    <StyledContainer type={type} num={num}>
      {type === 'correct' ? (
        <span className="correct-answer" />
      ) : (
        <img src={wrongAnsIcon} alt="Wrong Answer" />
      )}
      {num}
    </StyledContainer>
  );
};

export default ScoreCount;
