import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './SchedulePreview.styles.scss';
import { Link } from 'react-router-dom';
import moment from 'moment';
import HeaderFull from 'components/CreateAndAssign/HeaderFull';
import { useSelector, useDispatch } from 'react-redux';
import CircleType from 'components/CreateAndAssign/CircleType';
import Border from 'components/Shared/Border';
import PlainText from 'components/Shared/PlainText';
import {
  assignmentTypes,
  assignReferenceType,
  assignmentVisibilities
} from 'constants/index';
import { scheduleWorksheet } from 'stores/worksheet/actions';
import { isNil } from 'ramda';
import PopoverItems from 'components/Shared/PopoverItems';
import hasProp from 'helpers/hasProp';
import InfoScheduledWorksheetModal from 'components/Alert/InfoScheduledWorksheetModal';
import InfoLinkScheduledModal from 'components/Alert/InfoLinkScheduledModal';
import history from 'helpers/history';

export default function SchedulePreview() {
  const { t } = useTranslation(['create-assign-saved']);
  const dispatch = useDispatch();
  const { isAdmin } = useSelector(state => state.login);
  const { selectedWorksheet, error, isLoading } = useSelector(
    state => state.worksheet
  );
  const AssignmentTypeID = isNil(
    selectedWorksheet.PresentationType.AssignmentTypeID
  )
    ? null
    : selectedWorksheet.PresentationType.AssignmentTypeID;

  const [showScheduled, setShowScheduled] = useState(false);
  const [showLink, setShowLink] = useState(false);

  const {
    startDate,
    endDate,
    releaseDate,
    assignmentVisibility,
    kokoCredit,
    assignedClass,
    assignedGroup,
    assignedStudent,
    assignedPrivateGroup,
    assignedAdminGroup,
    assignedLevel
  } = useSelector(state => state.schedule);

  const renderShowAssigne = items => {
    return items.map(item => <span key={item.ID}>{item.Name}</span>);
  };

  const renderAssigneeItem = items => {
    if (items.length > 5) {
      const itemsShow = items.slice(0, 5);
      const itemsPopOver = items.slice(5).map(item => {
        return item.Name;
      });

      return (
        <>
          {renderShowAssigne(itemsShow)}
          <PopoverItems items={itemsPopOver} />
        </>
      );
    }
    return renderShowAssigne(items);
  };

  const renderShowIndividual = items => {
    return items.map(item => (
      <span key={item.UserId}>
        {item.FullName} <small>({item.ClassName})</small>
      </span>
    ));
  };

  const renderAssigneeItemIndividual = items => {
    if (items.length > 3) {
      const itemsShow = items.slice(0, 3);
      const itemsPopOver = items.slice(3).map(item => {
        return `${item.FullName} (${item.ClassName})`;
      });

      return (
        <>
          {renderShowIndividual(itemsShow)}
          <PopoverItems items={itemsPopOver} />
        </>
      );
    }
    return renderShowIndividual(items);
  };

  const handleSchedule = () => {
    // compile data for assigning
    const assignedClassData = assignedClass.map(classData => ({
      referenceId: classData.ID,
      type: assignReferenceType.class
    }));
    const assignedGroupData = assignedGroup.map(groupData => ({
      referenceId: groupData.ID,
      type: assignReferenceType.group
    }));
    const assignedPrivateGroupData = assignedPrivateGroup.map(
      privateGroupData => ({
        referenceId: privateGroupData.ID,
        type: assignReferenceType.group
      })
    );
    const assignedStudentData = assignedStudent.map(studentData => ({
      referenceId: studentData.UserId,
      type: assignReferenceType.student
    }));
    let assignedAdminData = null;
    if (hasProp(assignedAdminGroup, 'ID')) {
      switch (assignedAdminGroup.ID) {
        case 'premium':
          assignedAdminData = {
            referenceId: parseInt(assignedLevel, 10),
            type: assignReferenceType.level,
            isPremium: 1
          };
          break;
        case 'retail':
          assignedAdminData = {
            referenceId: parseInt(assignedLevel, 10),
            type: assignReferenceType.level,
            isRetail: 1
          };
          break;
        case 'koobits':
          assignedAdminData = {
            referenceId: parseInt(assignedLevel, 10),
            type: assignReferenceType.level
          };
          break;
        default:
          assignedAdminData = null;
      }
    }
    const assignmentsArray =
      assignedAdminData !== null
        ? [assignedAdminData]
        : [
            ...assignedClassData,
            ...assignedGroupData,
            ...assignedStudentData,
            ...assignedPrivateGroupData
          ];

    const data = {
      assignmentVisibility,
      rewards: kokoCredit,
      startDate: moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ'),
      endDate: moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ'),
      assignments: assignmentsArray,
      homeWorkID: selectedWorksheet.ID
    };
    if (AssignmentTypeID === assignmentTypes.test) {
      data.resultDate = moment(releaseDate).format(
        'YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ'
      );
    }
    dispatch(scheduleWorksheet(data));
    setShowScheduled(true);
  };

  return (
    <>
      <InfoScheduledWorksheetModal
        isShow={showScheduled}
        onConfirm={() => {
          setShowScheduled(false);
          setShowLink(true);
        }}
        onClose={() => setShowScheduled(false)}
        isLoading={isLoading}
        error={error}
      />

      <InfoLinkScheduledModal
        isShow={showLink}
        onConfirm={() => {
          setShowLink(false);
          history.push('/create-and-assign/saved-worksheet');
        }}
        onClose={() => {
          setShowLink(false);
        }}
      />

      <div className="container schedule-content schedule-content-preview">
        <HeaderFull>
          <div className="d-flex flex-row align-items-center justify-content-center schedule-content-title">
            <CircleType
              typeId={selectedWorksheet.PresentationType.AssignmentTypeID}
            />
            <h1>{selectedWorksheet.Title}</h1>
          </div>
          <div className="schedule-content-nav-btn">
            <Link
              type="button"
              className="btn btn-outline-primary"
              to="/create-and-assign/saved-worksheet"
            >
              {t(`create-assign-saved:scheduleWo.cancel`, 'Cancel')}
            </Link>
            <button
              onClick={handleSchedule}
              type="button"
              className="btn btn-primary"
            >
              {t(`create-assign-saved:scheduleWo.schedule`, 'Schedule')}
            </button>
          </div>
        </HeaderFull>
        <Border />
        <div className="ml-4 pl-2 panel-with-icon">
          <div className="panel panel-schedule-time">
            <div className="panel-header h5">
              {t(`create-assign-saved:scheduleWo.schedTime`, 'Schedule Time')}
            </div>
            <div className="panel-body row">
              <div className="col-6">
                <PlainText
                  label={t(
                    `create-assign-saved:scheduleWo.startDate`,
                    'Start Date'
                  )}
                  value={moment(startDate).format('D/M/YYYY, ha')}
                />
                {selectedWorksheet.PresentationType.AssignmentTypeID ===
                  assignmentTypes.test && (
                  <PlainText
                    label={t(
                      `create-assign-saved:scheduleWo.resDate`,
                      'Result Release Date'
                    )}
                    value={moment(releaseDate).format('D/M/YYYY, ha')}
                  />
                )}
                <PlainText
                  label={t(
                    `create-assign-saved:scheduleWo.visibility`,
                    'Visibility'
                  )}
                  value={t(
                    `create-assign-saved:scheduleWo.${assignmentVisibilities[assignmentVisibility]}`,
                    assignmentVisibilities[assignmentVisibility]
                  )}
                />
              </div>
              <div className="col-6">
                <PlainText
                  label={t(
                    `create-assign-saved:scheduleWo.endDate`,
                    'End Date'
                  )}
                  value={moment(endDate).format('D/M/YYYY, ha')}
                />
              </div>
            </div>
          </div>
          <div className="panel panel-schedule-assign-to">
            <div className="panel-header h5">
              {t(`create-assign-saved:assignNow.assignTo`, 'Assign To')}
            </div>
            <div className="panel-body">
              {isAdmin && (
                <>
                  {hasProp(assignedAdminGroup, 'ID') && (
                    <PlainText label="Admin" value={assignedAdminGroup.Name} />
                  )}
                  {assignedLevel !== '' && (
                    <PlainText
                      label={t(`create-assign-saved:assignNow.level`, 'Level')}
                      value={assignedLevel}
                    />
                  )}
                  {assignedPrivateGroup.length > 0 && (
                    <PlainText
                      label={t(
                        `create-assign-saved:assignNow.pvtGroups`,
                        'Private Groups'
                      )}
                    >
                      {renderAssigneeItem(assignedPrivateGroup)}
                    </PlainText>
                  )}
                </>
              )}
              {assignedClass.length > 0 && (
                <PlainText
                  label={t(`create-assign-saved:assignNow.class`, 'Class')}
                  className="mr-n1"
                >
                  {renderAssigneeItem(assignedClass)}
                </PlainText>
              )}
              {assignedGroup.length > 0 && (
                <PlainText
                  label={t(`create-assign-saved:assignNow.group`, 'Groups')}
                >
                  {renderAssigneeItem(assignedGroup)}
                </PlainText>
              )}
              {assignedStudent.length > 0 && (
                <PlainText
                  label={t(
                    `create-assign-saved:assignNow.indvStud`,
                    'Individual Student'
                  )}
                >
                  {renderAssigneeItemIndividual(assignedStudent)}
                </PlainText>
              )}
            </div>
          </div>
          <div className="panel panel-schedule-rewards row">
            <div className="panel-header h5 col-sm-2">
              {t(`create-assign-saved:scheduleWo.rewards`, 'Rewards')}
            </div>
            <div className="panel-body col-sm-10 mt-n1">
              <PlainText value={`${kokoCredit} Koko`} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
