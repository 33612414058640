import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Clipboard from 'react-clipboard.js';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ButtonGroup,
  Button,
  ProgressBar,
  Table,
  Dropdown
} from 'react-bootstrap';
import {
  fetchSingleClassReportRequest,
  clearSingleClassReport
} from 'stores/assignments/actions';
import QuestionPagination from 'components/TrackAssignment/QuestionPagination';
import QuestionResultIcon from 'components/TrackAssignment/QuestionResultIcon';
import ScoreCount from 'components/TrackAssignment/ScoreCount';
import QuestionSummaryModal from 'components/TrackAssignment/QuestionSummaryModal';
import HomeworkQuestionsModal from 'components/TrackAssignment/HomeworkQuestionsModal';
import ScorePieChart from 'components/TrackAssignment/ScorePieChart';
import printIcon from 'assets/img/icon-track-assignment-print-active.svg';
import downloadIcon from 'assets/img/icon-track-assignment-download.svg';
import leaderboardIcon from 'assets/img/icon-track-assignment-leaderboard.svg';
import openHWIcon from 'assets/img/icon-track-assignment-open-hw.svg';
import {
  getClass,
  compareValues,
  parseQuestionResult
} from 'helpers/singleClassReport';
import StudentSummaryModal from 'components/TrackAssignment/StudentSummaryModal';
import Spinner from 'components/Shared/Spinner';
import { featureToggle, homeworkLink } from 'constants/index';
import PopupModal from 'components/ManageClass/PopupModal';
import DownloadCSVModalBody from 'components/TrackAssignment/DownloadCSVModalBody';
import {
  SingleClassReportPage,
  StyledSwitchContainer,
  StyledSwitch
} from './SingleClassReport.styles';

// HELPERS
const createStudentSummaryData = (
  studentID,
  singleClassReport,
  studentByScores
) => {
  const studentIndex = studentByScores.findIndex(
    student => student.ID === studentID
  );
  if (studentIndex !== -1) {
    const summaryData = {
      hwTitle: singleClassReport[0].HomeWorkTitle,
      studentScore: `${studentByScores[studentIndex].score}/${singleClassReport[0].TotalQuestion}`,
      selectedStudent: studentByScores[studentIndex].FullName,
      questions: studentByScores[studentIndex].answers
    };
    return summaryData;
  }
  return null;
};
// Constants
const TYPES = [
  {
    label: 'Chart',
    value: 'chart'
  },
  {
    label: 'Data',
    value: 'data'
  }
];
export default function SingleClassReport({ match }) {
  const { t } = useTranslation(['track-assignment-multi-class']);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    isSingleClassReportLoading,
    singleClassReport,
    assignmentSummaryHistory,
    selectedTeacher
  } = useSelector(state => state.assignments);
  const homeworkID = parseInt(match.params.homeworkID);
  const [type, setType] = useState(TYPES[0].value);
  const setInitialClassID = () => {
    switch (match.params.classID) {
      case 'view':
        if (singleClassReport.length > 0) {
          if (singleClassReport[0].TrackingIndividual !== null) {
            return 'all';
          }
          if (singleClassReport[0].TrackingGroup.length > 0) {
            return singleClassReport[0].TrackingGroup[0].ClassID;
          }
        }
        return null;
      case 'all':
        return 'all';
      default:
        return Number(match.params.classID);
    }
  };

  // Selected Class ID State
  const [classID, setClassID] = useState(setInitialClassID());
  const sortingOrderHashmap = {
    1: [
      'score',
      'desc',
      t(`track-assignment-multi-class:single.scoreHL`, 'Score ( High to Low )')
    ],
    2: [
      'score',
      'asc',
      t(`track-assignment-multi-class:single.scoreLH`, 'Score ( Low to High )')
    ],
    3: [
      'FullName',
      'asc',
      t(`track-assignment-multi-class:single.nameAZ`, 'Name ( A-Z )')
    ]
  };
  const [scoresOrder, setScoresOrder] = useState(3);
  // Question Pagination State
  const [questionsPage, setQuestionsPage] = useState(1);
  // Question Summary Modal States
  const [showQuestionSummary, setShowQuestionSummary] = useState(false);
  const [questionSummaryData, setQuestionSummaryData] = useState(null);
  // Student Summary Modal States
  const [showStudentSummary, setShowStudentSummary] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  // Questions Modal States (Triggered on click of Open HW button)
  const [showQuestionsModal, setShowQuestionsModal] = useState(false);
  const hideQuestionModal = () => {
    setShowQuestionsModal(false);
  };

  // Event handlers to hide modals
  const hideQuestionSummary = () => {
    setShowQuestionSummary(false);
  };
  const hideStudentSummary = () => {
    setShowStudentSummary(false);
  };

  let questions = null;
  let classDetails = null;
  let studentScores;
  if (singleClassReport.length > 0) {
    // Set questions
    if (singleClassReport[0].hasOwnProperty('Questions')) {
      questions = singleClassReport[0].Questions.sort(
        compareValues('DisplayOrder', 'asc')
      );
    }

    // Set classDetails
    if (classID === 'all') {
      if (singleClassReport[0].TrackingIndividual !== null) {
        classDetails = singleClassReport[0].TrackingIndividual;
      }
    } else if (singleClassReport[0].TrackingGroup.length > 0) {
      classDetails = getClass(singleClassReport[0].TrackingGroup, classID);
    }
    // Calculate scores
    if (classID === 'all') {
      if (singleClassReport[0].TrackingIndividual !== null) {
        studentScores = parseQuestionResult(
          singleClassReport[0].TrackingIndividual.QuestionResult,
          singleClassReport[0].Questions,
          singleClassReport[0].TrackingIndividual.Students
        );
      }
    } else if (singleClassReport[0].TrackingGroup.length > 0) {
      studentScores = parseQuestionResult(
        classDetails.QuestionResult,
        singleClassReport[0].Questions,
        classDetails.Students
      );
    }
  }

  // Popup States
  const [showDownloadCSVModal, setShowDownloadCSVModal] = useState(false);

  // Modal Event Handlers
  const hideDownloadCSVModal = () => {
    setShowDownloadCSVModal(false);
  };

  useEffect(() => {
    dispatch(
      fetchSingleClassReportRequest({
        homeworkID
      })
    );
    return () => {
      dispatch(clearSingleClassReport());
    };
  }, [dispatch, homeworkID]);

  const [classInfo, setClassInfo] = useState(null);
  // Set classDetails
  useEffect(() => {
    if (singleClassReport.length > 0) {
      // Set classDetails
      if (classID === 'all') {
        if (singleClassReport[0].TrackingIndividual !== null) {
          setClassInfo(singleClassReport[0].TrackingIndividual);
        }
      } else if (singleClassReport[0].TrackingGroup.length > 0) {
        setClassInfo(getClass(singleClassReport[0].TrackingGroup, classID));
      }
    }
  }, [classID, singleClassReport]);

  return (
    <>
      {isSingleClassReportLoading && <Spinner />}
      {!isSingleClassReportLoading &&
        singleClassReport.length > 0 &&
        studentScores !== null &&
        classID !== null &&
        classInfo !== null && (
          <SingleClassReportPage>
            <PopupModal
              show={showDownloadCSVModal}
              hide={hideDownloadCSVModal}
              size="sm"
            >
              <DownloadCSVModalBody
                homeworkID={homeworkID}
                hide={hideDownloadCSVModal}
              />
            </PopupModal>
            <HomeworkQuestionsModal
              show={showQuestionsModal}
              hide={hideQuestionModal}
              homeworkID={homeworkID}
              hwTitle={singleClassReport[0].HomeWorkTitle}
              isSingleClassReportPage
            />
            <div
              className="report-navigation"
              onClick={() => {
                if (assignmentSummaryHistory === 'others') {
                  history.push(`/track-assignment/others/${selectedTeacher}`);
                } else {
                  history.push('/track-assignment');
                }
              }}
            >
              {`< ${t(`track-assignment-multi-class:main.back`, `Back`)}`}
            </div>
            <div className="tab-container">
              <div className="report-tabs">
                {singleClassReport[0].TrackingGroup.length > 1 && (
                  <span
                    className="class-tab"
                    onClick={() => {
                      history.push(`/track-assignment/homework/${homeworkID}`);
                    }}
                  >
                    {t(`track-assignment-multi-class:main.all`, `All`)}
                  </span>
                )}
                {singleClassReport[0].TrackingIndividual !== null && (
                  <span
                    className={classID === 'all' ? 'active' : 'class-tab'}
                    onClick={() => {
                      setClassID('all');
                    }}
                  >
                    {t(
                      `track-assignment-multi-class:main.assignedStud`,
                      `Assigned Students`
                    )}
                  </span>
                )}
                {singleClassReport[0].TrackingGroup.length > 0 &&
                  singleClassReport[0].TrackingGroup.map(classGroup => (
                    <span
                      key={classGroup.ClassID}
                      className={
                        parseInt(classID) === classGroup.ClassID
                          ? 'active'
                          : 'class-tab'
                      }
                      onClick={() => {
                        setClassID(classGroup.ClassID);
                      }}
                    >
                      {classGroup.ClassName}
                    </span>
                  ))}
                <span className="placeholder" />
              </div>
              <span className="scroller-tab">
                <ButtonGroup aria-label="Basic example">
                  <Button
                    variant="light"
                    onClick={() => {
                      const div = document.getElementsByClassName(
                        'report-tabs'
                      );
                      div[0].scrollLeft -= 100;
                    }}
                  >
                    {'<'}
                  </Button>
                  <Button
                    variant="light"
                    onClick={() => {
                      const div = document.getElementsByClassName(
                        'report-tabs'
                      );
                      div[0].scrollLeft += 100;
                    }}
                  >
                    {'>'}
                  </Button>
                </ButtonGroup>
              </span>
            </div>

            <div className="report-homework-details">
              <div className="report-homework-details-info">
                <p>
                  <strong>{singleClassReport[0].HomeWorkTitle}</strong>
                </p>
                <span style={{ marginRight: '1rem' }}>
                  {t(
                    `track-assignment-multi-class:main.assignBy`,
                    'Assigned by: '
                  )}
                  {singleClassReport[0].TeacherName}
                </span>
                <span className="report-dates">{`${moment(
                  singleClassReport[0].StartDate
                ).format('DD/MM/YYYY')} - ${moment(
                  singleClassReport[0].Deadline
                ).format('DD/MM/YYYY')}`}</span>
                {classDetails && (
                  <div className="report-progress">
                    {' '}
                    <ProgressBar
                      variant="progress-bar-submission"
                      now={
                        (classDetails.TotalSubmission /
                          classDetails.TotalAssignee) *
                        100
                      }
                      style={{
                        width: '130px',
                        height: '6px',
                        margin: '0 1rem 0 0'
                      }}
                    />
                    <span>
                      {t(
                        `track-assignment-multi-class:single.submitted`,
                        'Submitted'
                      )}{' '}
                      {`${classDetails.TotalSubmission}/${classDetails.TotalAssignee}`}
                    </span>
                  </div>
                )}
                <div className="submission-details-url">
                  <span>
                    {t(`track-assignment-multi-class:main.url`, 'URL: ')}
                    <span className="url-text">{`${homeworkLink}${homeworkID}`}</span>
                  </span>
                  <Clipboard
                    data-clipboard-text={`${homeworkLink}${homeworkID}`}
                    className="copy-button"
                  >
                    {t(`track-assignment-multi-class:main.copy`, 'Copy')}
                  </Clipboard>
                </div>
              </div>
              <div className="report-actions">
                <span
                  onClick={() => {
                    const win = window.open(
                      `${process.env.REACT_APP_DOMAIN}${
                        process.env.REACT_APP_SUBDOMAIN
                      }${
                        process.env.REACT_APP_SUBDOMAIN === '/' ? '' : '/'
                      }print/${homeworkID}`,
                      '_blank'
                    );
                    if (win != null) {
                      win.focus();
                    }
                  }}
                >
                  <img src={printIcon} alt="Print" className="action-icon" />
                  {t(`track-assignment-multi-class:main.printHw`, 'Print HW')}
                </span>
                {featureToggle.reportActionButtons &&
                  singleClassReport[0].HomeWorkType === 13 && (
                    <span
                      onClick={() => {
                        history.push(`/leaderboard/${homeworkID}`);
                      }}
                    >
                      <img
                        src={leaderboardIcon}
                        alt="Leaderboard"
                        className="action-icon"
                      />
                      {t(
                        `track-assignment-multi-class:main.leaderboard`,
                        'Leaderboard'
                      )}
                    </span>
                  )}
                <span
                  onClick={() => {
                    setShowDownloadCSVModal(true);
                  }}
                >
                  <img
                    src={downloadIcon}
                    alt="Download"
                    className="action-icon"
                  />
                  {t(`track-assignment-multi-class:main.download`, 'Download')}
                </span>
                <span
                  onClick={() => {
                    setShowQuestionsModal(true);
                  }}
                >
                  <img src={openHWIcon} alt="Open HW" className="action-icon" />
                  {t(`track-assignment-multi-class:main.openHw`, 'Open HW')}
                </span>
              </div>
            </div>
            {questionSummaryData && (
              <QuestionSummaryModal
                size="sm"
                data={questionSummaryData}
                show={showQuestionSummary}
                hide={hideQuestionSummary}
              />
            )}
            <StudentSummaryModal
              data={createStudentSummaryData(
                selectedStudent,
                singleClassReport,
                studentScores.byStudent
              )}
              show={showStudentSummary}
              hide={hideStudentSummary}
              id={homeworkID}
            />
            <div className="report-scores">
              <div className="report-scores-header">
                <div className="select-sorting-order">
                  <span>
                    {t(`track-assignment-multi-class:single.sortBy`, 'Sort by')}
                  </span>
                  <Dropdown className="sorting-dropdown">
                    <Dropdown.Toggle variant="light" id="dropdown-sorting">
                      {sortingOrderHashmap[scoresOrder][2]}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={event => {
                          event.preventDefault();
                          setScoresOrder(1);
                        }}
                        className="sorting-dropdown-item"
                      >
                        {t(
                          `track-assignment-multi-class:single.scoreHL`,
                          'Score ( High to Low )'
                        )}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={event => {
                          event.preventDefault();
                          setScoresOrder(2);
                        }}
                        className="sorting-dropdown-item"
                      >
                        {t(
                          `track-assignment-multi-class:single.scoreLH`,
                          'Score ( Low to High )'
                        )}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={event => {
                          event.preventDefault();
                          setScoresOrder(3);
                        }}
                        className="sorting-dropdown-item"
                      >
                        {t(
                          `track-assignment-multi-class:single.nameAZ`,
                          'Name ( A-Z )'
                        )}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <StyledSwitchContainer>
                  {TYPES.map(item => (
                    <StyledSwitch
                      key={item.value}
                      active={item.value === type}
                      onClick={() => setType(item.value)}
                    >
                      {item.label}
                    </StyledSwitch>
                  ))}
                </StyledSwitchContainer>
              </div>
              <div className="score-table">
                <Table hover borderless className="score-table-left">
                  <thead>
                    <tr>
                      <th style={{ height: '75px' }}>
                        <span>
                          {t(
                            `track-assignment-multi-class:single.name`,
                            'Name'
                          )}
                        </span>
                      </th>
                      <th style={{ height: '75px' }}>
                        <span>
                          {t(
                            `track-assignment-multi-class:single.score`,
                            'Score'
                          )}
                        </span>
                      </th>
                      {questions &&
                        questions
                          .slice((questionsPage - 1) * 10, questionsPage * 10)
                          .map((question, index) => (
                            <th key={question.Id}>
                              <span
                                className={`header-cell-question ${classDetails.TotalSubmission >
                                  0 && 'has-submissions'} ${type}`}
                                onClick={() => {
                                  setQuestionSummaryData({
                                    question,
                                    answers:
                                      studentScores.byQuestion[
                                        (questionsPage - 1) * 10 + index + 1
                                      ]
                                  });
                                  setShowQuestionSummary(true);
                                }}
                              >
                                {type === TYPES[0].value ? (
                                  <ScorePieChart
                                    submissions={{
                                      correct: Object.values(
                                        studentScores.byQuestion[
                                          (questionsPage - 1) * 10 + index + 1
                                        ].correct
                                      ).reduce(
                                        (total, { num }) => total + num,
                                        0
                                      ),
                                      wrong: Object.values(
                                        studentScores.byQuestion[
                                          (questionsPage - 1) * 10 + index + 1
                                        ].wrong
                                      ).reduce(
                                        (total, { num }) => total + num,
                                        0
                                      ),
                                      notSubmitted:
                                        classDetails.TotalAssignee -
                                        classDetails.TotalSubmission
                                    }}
                                  />
                                ) : (
                                  <>
                                    <ScoreCount
                                      type="wrong"
                                      num={Object.values(
                                        studentScores.byQuestion[
                                          (questionsPage - 1) * 10 + index + 1
                                        ].wrong
                                      ).reduce(
                                        (total, { num }) => total + num,
                                        0
                                      )}
                                    />
                                    <ScoreCount
                                      type="correct"
                                      num={Object.values(
                                        studentScores.byQuestion[
                                          (questionsPage - 1) * 10 + index + 1
                                        ].correct
                                      ).reduce(
                                        (total, { num }) => total + num,
                                        0
                                      )}
                                    />
                                  </>
                                )}
                                <span>{`Q${question.DisplayOrder}`}</span>
                              </span>
                            </th>
                          ))}
                    </tr>
                  </thead>
                  <tbody>
                    {studentScores && (scoresOrder === 1 || scoresOrder === 2)
                      ? studentScores.byStudent
                          .filter(student => student.hasSubmitted === true)
                          .sort(
                            compareValues(
                              sortingOrderHashmap[scoresOrder][0],
                              sortingOrderHashmap[scoresOrder][1]
                            )
                          )
                          .map(student => (
                            <tr key={`left-${student.ID}`}>
                              <td
                                className="cell-student-name"
                                onClick={() => {
                                  setSelectedStudent(student.ID);
                                  setShowStudentSummary(true);
                                }}
                              >
                                <strong>
                                  {student.FullName.toUpperCase()}
                                </strong>
                              </td>
                              <td
                                className="cell-score"
                                style={{ height: '43px', width: '10rem' }}
                                onClick={() => {
                                  setSelectedStudent(student.ID);
                                  setShowStudentSummary(true);
                                }}
                              >
                                {student.hasSubmitted ? (
                                  <>
                                    <ProgressBar
                                      variant="progress-bar-score"
                                      now={
                                        (student.score /
                                          singleClassReport[0].TotalQuestion) *
                                        100
                                      }
                                      style={{
                                        width: '42px',
                                        height: '6px',
                                        margin: '0 0.5rem 0 0'
                                      }}
                                    />
                                    <strong style={{ marginRight: '0.5rem' }}>
                                      {student.score}
                                    </strong>
                                    {` / ${singleClassReport[0].TotalQuestion}`}
                                  </>
                                ) : (
                                  <ProgressBar
                                    className="progress-not-submitted"
                                    now={0}
                                    style={{
                                      width: '41px',
                                      height: '6px',
                                      margin: '0 0.5rem 0 0'
                                    }}
                                  />
                                )}
                              </td>
                              {student.questions.length > 0
                                ? student.questions
                                    .slice(
                                      (questionsPage - 1) * 10 + 1,
                                      questionsPage * 10 + 1
                                    )
                                    .map((result, index) => (
                                      <td
                                        key={`qn${index}-result-${student.ID}`}
                                        className="cell-answer"
                                      >
                                        <QuestionResultIcon result={result} />
                                      </td>
                                    ))
                                : questions
                                    .slice(
                                      (questionsPage - 1) * 10,
                                      questionsPage * 10
                                    )
                                    .map((question, index) => (
                                      <td
                                        key={`qn${index}-result-${student.ID}`}
                                        className="cell-answer"
                                      >
                                        <QuestionResultIcon result="no-attempt" />
                                      </td>
                                    ))}
                            </tr>
                          ))
                      : studentScores.byStudent
                          .sort(
                            compareValues(
                              sortingOrderHashmap[scoresOrder][0],
                              sortingOrderHashmap[scoresOrder][1]
                            )
                          )
                          .map(student => (
                            <tr key={`left-${student.ID}`}>
                              <td
                                onClick={() => {
                                  if (student.hasSubmitted === true) {
                                    setSelectedStudent(student.ID);
                                    setShowStudentSummary(true);
                                  } else {
                                    return null;
                                  }
                                }}
                              >
                                <strong>
                                  {student.FullName.toUpperCase()}
                                </strong>
                              </td>
                              <td
                                className="cell-score"
                                onClick={() => {
                                  if (student.hasSubmitted === true) {
                                    setSelectedStudent(student.ID);
                                    setShowStudentSummary(true);
                                  } else {
                                    return null;
                                  }
                                }}
                              >
                                {student.hasSubmitted ? (
                                  <>
                                    <ProgressBar
                                      variant="progress-bar-score"
                                      now={
                                        (student.score /
                                          singleClassReport[0].TotalQuestion) *
                                        100
                                      }
                                      style={{
                                        width: '41px',
                                        height: '6px',
                                        margin: '0 0.5rem 0 0'
                                      }}
                                    />
                                    <strong style={{ marginRight: '0.5rem' }}>
                                      {student.score}
                                    </strong>
                                    {` / ${singleClassReport[0].TotalQuestion}`}
                                  </>
                                ) : (
                                  <ProgressBar
                                    className="progress-not-submitted"
                                    now={0}
                                    style={{
                                      width: '41px',
                                      height: '6px',
                                      margin: '0 0.5rem 0 0'
                                    }}
                                  />
                                )}
                              </td>
                              {student.questions.length > 0
                                ? student.questions
                                    .slice(
                                      (questionsPage - 1) * 10 + 1,
                                      questionsPage * 10 + 1
                                    )
                                    .map((result, index) => (
                                      <td
                                        key={`qn${index}-result-${student.ID}`}
                                        className="cell-answer"
                                      >
                                        <QuestionResultIcon result={result} />
                                      </td>
                                    ))
                                : questions
                                    .slice(
                                      (questionsPage - 1) * 10,
                                      questionsPage * 10
                                    )
                                    .map((question, index) => (
                                      <td
                                        key={`qn${index}-result-${student.ID}`}
                                        className="cell-answer"
                                      >
                                        <QuestionResultIcon result="no-attempt" />
                                      </td>
                                    ))}
                            </tr>
                          ))}
                    {studentScores &&
                      (scoresOrder === 1 || scoresOrder === 2) &&
                      studentScores.byStudent
                        .filter(student => student.hasSubmitted === false)
                        .sort(
                          compareValues(
                            sortingOrderHashmap[3][0],
                            sortingOrderHashmap[3][1]
                          )
                        )
                        .map(student => (
                          <tr key={`left-${student.ID}`}>
                            <td
                              onClick={() => {
                                if (student.hasSubmitted === true) {
                                  setSelectedStudent(student.ID);
                                  setShowStudentSummary(true);
                                } else {
                                  return null;
                                }
                              }}
                            >
                              <strong>{student.FullName.toUpperCase()}</strong>
                            </td>
                            <td
                              className="cell-score"
                              onClick={() => {
                                if (student.hasSubmitted === true) {
                                  setSelectedStudent(student.ID);
                                  setShowStudentSummary(true);
                                } else {
                                  return null;
                                }
                              }}
                            >
                              {student.hasSubmitted ? (
                                <>
                                  <ProgressBar
                                    variant="progress-bar-score"
                                    now={
                                      (student.score /
                                        singleClassReport[0].TotalQuestion) *
                                      100
                                    }
                                    style={{
                                      width: '41px',
                                      height: '6px',
                                      margin: '0 0.5rem 0 0'
                                    }}
                                  />
                                  <strong style={{ marginRight: '0.5rem' }}>
                                    {student.score}
                                  </strong>
                                  {` / ${singleClassReport[0].TotalQuestion}`}
                                </>
                              ) : (
                                <ProgressBar
                                  className="progress-not-submitted"
                                  now={0}
                                  style={{
                                    width: '41px',
                                    height: '6px',
                                    margin: '0 0.5rem 0 0'
                                  }}
                                />
                              )}
                            </td>
                            {questions
                              .slice(
                                (questionsPage - 1) * 10,
                                questionsPage * 10
                              )
                              .map((question, index) => (
                                <td
                                  key={`qn${index}-result-${student.ID}`}
                                  className="cell-answer"
                                >
                                  <QuestionResultIcon result="no-attempt" />
                                </td>
                              ))}
                          </tr>
                        ))}
                  </tbody>
                </Table>
                {questions.length > 10 && (
                  <QuestionPagination
                    currentPage={questionsPage}
                    numOfPages={Math.ceil(questions.length / 10)}
                    setPage={setQuestionsPage}
                  />
                )}
              </div>
            </div>
          </SingleClassReportPage>
        )}
    </>
  );
}
