import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import ButtonStepPrev from 'components/Shared/ButtonStepPrev';

const WorksheetForm = props => {
  const { t } = useTranslation(['create-assign-question']);
  const { placeholder, value, isError, onChange, alertMsg } = props;
  const [placeholderTitle, setPlaceholderTitle] = useState(placeholder);
  const formik = useFormik({
    initialValues: {
      worksheetTitle: value
    }
  });

  const handleOnFocus = event => {
    formik.setFieldTouched(event.target.name, true);
    setPlaceholderTitle('');
  };

  const handleOnBlur = event => {
    formik.setFieldTouched(event.target.name, false);
    setPlaceholderTitle(event.target.value === '' ? placeholder : '');
  };

  const handleOnChange = e => {
    onChange(e.target.value);
  };

  return (
    <>
      {props.showBackBtn === false ? null : (
        <ButtonStepPrev
          title={t(`create-assign-question:editWo.back`, '< Back')}
        />
      )}
      <form>
        <div className="form-group question-cart-content__form-required">
          <input
            type="text"
            name="worksheetTitle"
            className={`form-control question-cart-content__field ${
              formik.touched.worksheetTitle && isError ? 'error' : ''
            }`}
            placeholder={placeholderTitle}
            value={value}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
          />
          {alertMsg && isError && (
            <p style={{ color: '#ff0000' }}>
              {t(
                `create-assign-question:editWo.nextStepVal`,
                '*Please add worksheet title before going to the next step.'
              )}
            </p>
          )}
        </div>
      </form>
    </>
  );
};
export default WorksheetForm;
