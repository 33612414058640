import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';

import { HOTS_CURR_ID, AGE_GROUP } from 'constants/hotsCurr';

import VideoOverlay from 'components/Content/VideoOverlay';
import NoVideoFound from 'components/Content/NoVideoFound';
import SkillsResult from 'components/Content/SkillsResult';
import { unSelectTopic } from 'stores/topics/actions';
import {
  ProblemsumsTrialContainer,
  VideoListContainer
} from './ProblemSumsTrial.styles';

const ProblemsumsTrial = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);
  const { selectedTopic } = useSelector(state => state.topics);
  const { isTrial } = useSelector(state => state.login);
  const { videos, selectedLevel } = useSelector(state => state.levels);
  const { selectedCurriculum } = useSelector(state => state.curriculums);
  const [isRun, setIsRun] = useState(false);
  const [videoData, setVideoData] = useState([]);

  const isHOTS = selectedCurriculum?.CurriculumID === HOTS_CURR_ID;

  useEffect(() => {
    dispatch(unSelectTopic());
  }, [dispatch]);

  useEffect(() => {
    if (!isRun) {
      setIsRun(true);
      return;
    }
    if (!isEmpty(selectedTopic)) {
      const tempVideo = [...videos].filter(
        vid => vid.TopicId === selectedTopic.ID
      );
      setVideoData(tempVideo);
    } else {
      setVideoData([...videos]);
    }
  }, [selectedTopic, videos, isRun]);

  const videoLists = videoData.map((video, index) => (
    <div style={{ width: '225px', marginRight: '2rem' }} key={video.skillId}>
      <div className="video" key={video.videoCode}>
        <VideoOverlay
          type={isTrial && index > 5 ? 'disabled-video' : 'play-video'}
          videoId={video.videoCode}
        />
        {/* <iframe
          title={video.videoCode}
          src={`https://www.youtube.com/embed/${video.videoCode}?autoplay=0&showinfo=0&controls=0`}
        /> */}
      </div>
      <div className="video-title" style={{ fontWeight: 'bold' }}>
        <span>
          Skill {video.skillId}- {video.TopicName}
        </span>
        <span className="video-desc">{video.SkillDescription}</span>
      </div>
    </div>
  ));

  if (isHOTS) {
    return (
      <ProblemsumsTrialContainer>
        <SkillsResult isVideo totalVideos={[]} />
        <div className="age-header">
          <span className="age-group">
            {t(`common:age`, 'Age')} {AGE_GROUP[selectedLevel.ID]} -{' '}
          </span>
          <span className="topic-name">
            {t(`common:allTopics`, 'All Topics')}
          </span>
        </div>
        <NoVideoFound />
      </ProblemsumsTrialContainer>
    );
  }

  return (
    <ProblemsumsTrialContainer>
      <SkillsResult isVideo totalVideos={videoData} />
      <div className="vids-header">
        <span className="level-id">P{selectedLevel.ID}-</span>
        <span className="topic-name">
          {isEmpty(selectedTopic)
            ? t(`common:allTopics`, 'All Topics')
            : selectedTopic.Name}
        </span>
      </div>
      {videoLists.length === 0 ? <NoVideoFound /> : null}
      {videoLists.length > 0 ? (
        <VideoListContainer>{videoLists}</VideoListContainer>
      ) : null}
    </ProblemsumsTrialContainer>
  );
};

export default ProblemsumsTrial;
