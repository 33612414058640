import apiClient from 'services/api';

const handleResponseSuccess = result => {
  if (result.data?.IsSuccessful === false) {
    return {
      error: result.data?.Message?.Message ?? 'Fetch data failed' // eslint-disable-line
    };
  }
  return result.data?.Result ?? [];
};

/**
 * Fetch class by Level ID
 */
const getClassByLevel = LevelId => {
  return apiClient
    .get(`Lookup/Classes/${LevelId}`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch class failed' // eslint-disable-line
      };
    });
};

const getStudentsByClass = ClassId => {
  return apiClient
    .get(`Lookup/Students/${ClassId}`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch students failed' // eslint-disable-line
      };
    });
};

const getStudentsByKeyword = (keyword, page, limit) => {
  const options = {
    params: {
      SrchName: keyword,
      pageindex: page || 0,
      pagesize: limit || 10
    }
  };
  return apiClient
    .get(`Lookup/SearchStudent`, options)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch students failed' // eslint-disable-line
      };
    });
};

const getMyGroups = () => {
  return apiClient
    .get(`lookup/GetMygroups/`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch group failed' // eslint-disable-line
      };
    });
};

export default {
  getClassByLevel,
  getStudentsByClass,
  getStudentsByKeyword,
  getMyGroups
};
