import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginRequest } from 'stores/login/actions';
import LoginButton from '../LoginButton/LoginButton';
import {
  PasswordContainer,
  LoginFormContainer,
  StyledErrorMessageContainer
} from './LoginForm.styles';

const Password = ({ password, handleChange }) => {
  const [type, setType] = useState('password');
  const togglePasswordHandler = e => {
    if (type === 'password') {
      setType('text');
    } else {
      setType('password');
    }
  };

  return (
    <PasswordContainer>
      <input
        type={type}
        name="password"
        id="password"
        placeholder="Enter your password"
        onChange={handleChange}
        value={password}
        required
      />
      <div className="show-password">
        <input
          type="checkbox"
          name="show-password"
          onClick={togglePasswordHandler}
        />
        <span>Show</span>
      </div>
    </PasswordContainer>
  );
};

const LoginErrors = ({ error, username }) => {
  const errorTypes = {
    'Invalid Password.': (
      <>
        Your Password is wrong.{' '}
        <a
          href={`${process.env.REACT_APP_KOOBITS_DOMAIN}/Recover?uid=${username}`}
        >
          Forgot password?
        </a>
      </>
    ),
    'Invalid Username.': (
      <>
        Your Username is invalid.{' '}
        <a href="https://support.koobits.com/hc/en-gb/articles/212117966-Forgot-Login-ID-">
          Check Login ID Format
        </a>
      </>
    ),
    'connection-issue': "Sorry, we can't connect to internet or our server.",
    "You don't have permission to access":
      'Sorry, your account does not have permission to access.',
    'account expired': (
      <>
        This teacher’s account has expired. You can contact{' '}
        <a href="https://www.koobits.com/contact">support</a> for assistance.
      </>
    )
  };

  return (
    <StyledErrorMessageContainer>
      {errorTypes[error] || error}
    </StyledErrorMessageContainer>
  );
};

const LoginForm = () => {
  const dispatch = useDispatch();
  const { isLoading, error } = useSelector(state => state.login);
  // STATES
  // Stores user-entered fields
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // Event handler for when user changes the value in input fields
  const handleChange = event => {
    const { value, name } = event.target;
    if (name === 'login-id') {
      setUsername(value);
    } else {
      setPassword(value);
    }
  };

  // Event handler for when form is submitted by clicking Login button
  const handleSubmit = event => {
    event.preventDefault();
    // Clear any errors from the previous login attempt
    dispatch(loginRequest(username, password));
  };

  return (
    <LoginFormContainer>
      <form className="login-form" onSubmit={handleSubmit}>
        <PasswordContainer>
          <input
            type="text"
            name="login-id"
            id="login-id"
            placeholder="Enter your Login ID"
            onChange={handleChange}
            value={username}
            required
          />
        </PasswordContainer>
        <Password password={password} handleChange={handleChange} />
        {error.length > 0 && <LoginErrors error={error} username={username} />}
        <span className="help-container">
          <a href="https://support.koobits.com/hc/en-gb?utm_source=kps&utm_medium=login&utm_campaign=help">
            Need Help?
          </a>
        </span>
        <LoginButton className="login-button" type="submit">
          {!isLoading ? 'Login' : 'Logging In...'}
        </LoginButton>
      </form>
    </LoginFormContainer>
  );
};

export default LoginForm;
