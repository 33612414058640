import {
  FETCH_VARIATIONS_FAILED,
  FETCH_VARIATIONS_REQUEST,
  FETCH_VARIATIONS_SUCCESS,
  FETCH_VARIATIONS_COMPLETE,
  RESET_SELECTED_VARIATION,
  SELECTED_VARIATION
} from './actionTypes';

const initialState = {
  variations: [],
  selectedVariation: {},
  isLoading: false,
  isTitleLoading: true,
  error: ''
};

export default function variations(state = initialState, action) {
  switch (action.type) {
    case FETCH_VARIATIONS_REQUEST:
      return { ...state, isLoading: true, variations: [] };
    case FETCH_VARIATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        variations: [...state.variations, ...action.variations],
        selectedVariation: state.selectedVariation
      };
    case FETCH_VARIATIONS_FAILED:
      return { ...state, isLoading: false, error: action.error };
    case FETCH_VARIATIONS_COMPLETE:
      return { ...state, isTitleLoading: action.value };
    case SELECTED_VARIATION:
      return { ...state, selectedVariation: action.selectedVariation };
    case RESET_SELECTED_VARIATION:
      return { ...state, selectedVariation: {} };
    default:
      return state;
  }
}
