import {
  FETCH_SYLLABUS_REQUEST,
  FETCH_SYLLABUS_SUCCESS,
  FETCH_SYLLABUS_FAILED,
  NO_SYLLABUS,
  PUSH_TO_CART_RESET,
  PUSH_TO_CART_REQUEST,
  PUSH_TO_CART_SUCCESS,
  PUSH_TO_CART_FAILED,
  CREATE_WORKSHEET_RESET,
  CREATE_WORKSHEET_REQUEST,
  CREATE_WORKSHEET_SUCCESS,
  CREATE_WORKSHEET_FAILED,
  CREATE_WORKSHEET_ERROR,
  SET_CREATE_HOMEWORK_QUESTIONS,
  REMOVE_CREATE_HOMEWORK_QUESTION
} from './actionTypes';

export const fetchSyllabusRequest = (CurriculumID, LevelID) => {
  return {
    type: FETCH_SYLLABUS_REQUEST,
    CurriculumID,
    LevelID
  };
};

export const fetchSyllabusSuccess = syllabus => {
  return { type: FETCH_SYLLABUS_SUCCESS, syllabus };
};

export const fetchSyllabusFailed = error => {
  return { type: FETCH_SYLLABUS_FAILED, error };
};

export const noSyllabus = () => {
  return { type: NO_SYLLABUS };
};

export const pushToCartReset = () => {
  return { type: PUSH_TO_CART_RESET };
};

export const pushToCartRequest = obj => {
  return {
    type: PUSH_TO_CART_REQUEST,
    obj
  };
};

export const pushToCartSuccess = data => {
  return { type: PUSH_TO_CART_SUCCESS, data };
};

export const pushToCartFailed = error => {
  return { type: PUSH_TO_CART_FAILED, error };
};

export const createWorkSheetReset = () => {
  return { type: CREATE_WORKSHEET_RESET };
};

export const createWorkSheetRequest = obj => {
  return {
    type: CREATE_WORKSHEET_REQUEST,
    obj
  };
};

export const createWorkSheetSuccess = data => {
  return { type: CREATE_WORKSHEET_SUCCESS, data };
};

export const createWorkSheetFailed = error => {
  return { type: CREATE_WORKSHEET_FAILED, error };
};

export const createWorkSheetError = error => {
  return { type: CREATE_WORKSHEET_ERROR, error };
};

export const setCreateHomeworkQuestions = questions => {
  return {
    type: SET_CREATE_HOMEWORK_QUESTIONS,
    questions
  };
};

export const removeCreateHomeworkQuestion = Id => {
  return { type: REMOVE_CREATE_HOMEWORK_QUESTION, Id };
};
