import styled from 'styled-components';
import DefaultBadge from 'assets/img/leaderboard/default-pos.svg';
import FirstBadge from 'assets/img/leaderboard/first-pos.svg';
import SecondBadge from 'assets/img/leaderboard/second-pos.svg';
import ThirdBadge from 'assets/img/leaderboard/third-pos.svg';

const allBadge = {
  default: {
    bgImg: DefaultBadge,
    badgeColor: '#ffffff',
    gradinet: null,
    borderCol: null
  },
  first: {
    bgImg: FirstBadge,
    badgeColor: '#CC7900',
    gradinet:
      'linear-gradient(to right, #FFDC5D, #FFFFFF, #FFC903, #FFC903, #FFC903, #FFC903, #FFC903, #FFC903, #FFCA07)',
    borderCol: '#fac500'
  },
  second: {
    bgImg: SecondBadge,
    badgeColor: '#849AB1',
    gradinet:
      'linear-gradient(to right, #dfe5eb, #ffffff, #e4e9eb, #e4e9eb, #e4e9eb, #e4e9eb, #e4e9eb, #e4e9eb, #deecf4)',
    borderCol: '#a6bdc6'
  },
  third: {
    bgImg: ThirdBadge,
    badgeColor: '#D17D1F',
    gradinet:
      'linear-gradient(to right, #fcd094, #f8f8f8,  #fddfbb, #fddfbb, #fddfbb, #fddfbb, #fddfbb, #fddfbb, #fddfbb, #ffc37b)',
    borderCol: '#e3af70'
  }
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  background: #ffffff;
  width: ${props => (props.challengeMode === 'hallOfFame' ? '100%' : '70%')};
  height: 100%;
  padding: 20px 30px;
  .list-headings {
    display: flex;
    width: 100%;
    padding: 0 5px 10px 35px;
  }
  .nearby-container {
    background-color: #f0fcff;
    border: 1px solid transparent;
    border-radius: 20px;
    margin: 5px 0 20px;
    display: flex;
    justify-content: center;
    .nearby {
      border: 1px solid #9adcf5;
      background-color: #dbf7ff;
      border-radius: 20px;
      color: #0081b1;
      font-size: 18px;
      padding: 5px 10px;
      width: 30%;
      text-align: center;
      cursor: pointer;
    }
  }
  .current-user {
    background-color: #fece18 !important;
  }
`;

export const Headings = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  color: #5ec0eb;
  flex: ${props => props.flex};
  font-weight: 700;
`;

export const Lists = styled.div`
  display: flex;
  margin-bottom: 10px;
  border: 1px solid black;
  align-items: center;
  padding: 3px 10px 3px 45px;
  border: 1px solid;
  border-radius: 30px;
  position: relative;
  font-size: 14px;
  cursor: pointer;
  :nth-of-type(odd) {
    background-color: #ffffff;
    border-color: #d4ecf5;
  }
  :nth-of-type(even) {
    background-color: #def6ff;
    border-color: #b9e6f7;
  }
  border-color: ${props =>
    props.currPos !== 'default'
      ? allBadge[props.currPos].borderCol
      : 'auto'}!important;
  background-image: ${props => allBadge[props.currPos].gradinet};
  .postions {
    color: ${props => allBadge[props.currPos].badgeColor};
    background: url(${props => allBadge[props.currPos].bgImg});
    position: absolute;
    left: ${props => (props.currPos === 'default' ? '-8px' : '-12px')};
    height: ${props => (props.currPos === 'default' ? '30px' : '40px')};
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    background-repeat: no-repeat;
    background-size: 50px 100%;
  }
  .list-name {
    flex: 2;
    display: flex;
  }
  .list-class {
    flex: 2;
    display: flex;
  }
  .list-points {
    flex: 1;
    display: flex;
    font-size: 17px;
    font-weight: 700;

    .total-score {
      font-weight: normal;
    }
  }
  .list-achivement {
    flex: 1;
    display: flex;
  }
`;

export const ClassList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
  border: 1px solid black;
  padding: 3px 0px;
  font-size: 16px;
  border: 1px solid #fac500;
  border-radius: 30px;
  user-select: none;
  cursor: pointer;
  background-image: linear-gradient(
    to right,
    #ffdc5d,
    #ffffff,
    #ffc903,
    #ffc903,
    #ffc903,
    #ffc903,
    #ffca07
  );
  span {
    font-size: 18px;
  }
  .total-participants,
  .total-details {
    font-size: 14px;
  }
  .total-participants {
    span {
      font-weight: bold;
    }
  }
`;
