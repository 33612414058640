import apiClient from 'services/api';
import moment from 'moment';
import handleLogout from 'helpers/logout';

export const setUserDefaultLevel = LevelID => {
  return apiClient
    .post(`User/SetDefaultLevel/${LevelID}`)
    .then(result => {
      return result.data?.Result ?? {};
    })
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Set user default level failed' // eslint-disable-line
      };
    });
};

export default () => {
  return apiClient
    .get(`User/GetUserDefaultLevel`)
    .then(result => {
      if (result?.data?.Result) {
        const { IsAccountExpired, ExpiryDate } = result.data.Result;
        if (IsAccountExpired || moment().isAfter(moment(ExpiryDate))) {
          handleLogout();
          throw new Error('account expired');
        }
      }
      return result.data?.Result ?? {};
    })
    .catch(err => {
      if (err.message) {
        return { error: err.message };
      }
      return {
        error: err?.response?.data?.message ?? 'Fetch user default level failed' // eslint-disable-line
      };
    });
};
