import {
  FETCH_VARIATIONS_FAILED,
  FETCH_VARIATIONS_REQUEST,
  FETCH_VARIATIONS_SUCCESS,
  FETCH_VARIATIONS_COMPLETE,
  RESET_SELECTED_VARIATION,
  SELECTED_VARIATION,
  FETCH_SINGLE_VARIATION
} from './actionTypes';

export const fetchVariationsRequest = () => {
  return { type: FETCH_VARIATIONS_REQUEST };
};

export const fetchVariationsSuccess = variations => {
  return { type: FETCH_VARIATIONS_SUCCESS, variations };
};

export const fetchVariationsFailed = error => {
  return { type: FETCH_VARIATIONS_FAILED, error };
};

export const fetchVariationsComplete = value => {
  return { type: FETCH_VARIATIONS_COMPLETE, value };
};

export const selectVariation = selectedVariation => {
  return { type: SELECTED_VARIATION, selectedVariation };
};

export const resetSelectedVariation = () => {
  return { type: RESET_SELECTED_VARIATION };
};
export const fetchSingleVariation = skill => {
  return { type: FETCH_SINGLE_VARIATION, skill };
};
