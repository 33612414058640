export default [
  {
    icon: 'icon-browse',
    name: 'Content',
    nameKey: 'content',
    to: '/browse-content',
    externalLink: false
  },
  {
    icon: 'icon-create',
    name: 'Assign',
    nameKey: 'assign',
    to: '/create-and-assign',
    externalLink: false
  },
  {
    icon: 'icon-track-assignment',
    name: 'Track',
    nameKey: 'track',
    to: '/track-assignment',
    externalLink: false
  },
  {
    icon: 'icon-report',
    name: 'Report',
    nameKey: 'report',
    to: '/report',
    externalLink: false
  },
  {
    icon: 'icon-manage-class',
    name: 'Class',
    nameKey: 'class',
    to: '/manage-class',
    externalLink: false
  },
  {
    icon: 'icon-help',
    name: 'Help',
    nameKey: 'help',
    to:
      'https://support.koobits.com/hc/en-gb/categories/201303246-For-Teachers',
    externalLink: true
  }
];
