import React, { useCallback } from 'react';
import SchoolPlanIcon from 'assets/img/icon-manage-class-student-list-plan-school.svg';
import StandardPlanIcon from 'assets/img/icon-manage-class-student-list-plan-standard.svg';
import PremiumPlanIcon from 'assets/img/icon-manage-class-student-list-plan-premium.svg';
import avatarPlaceholder from 'assets/img/avatar.png';
import Spinner from 'components/Shared/Spinner';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import GameSettingStudentTable from './GameSettingStudentList.styles';

const getPlanIcon = planID => {
  switch (planID) {
    case 0:
      return SchoolPlanIcon;
    case 2:
      return StandardPlanIcon;
    case 3:
      return PremiumPlanIcon;
    case 5:
      return PremiumPlanIcon;
    case 6:
      return PremiumPlanIcon;
    default:
      return null;
  }
};

const GameSettingStudentList = ({
  students,
  allowedStudentIDs,
  setAllowedStudentIDs,
  isLoading
}) => {
  const { t } = useTranslation(['manage-class']);

  const handleOnChange = useCallback(
    event => {
      const userID = parseInt(event.target.value, 10);
      if (event.target.checked) {
        setAllowedStudentIDs([...allowedStudentIDs, userID]);
      } else {
        setAllowedStudentIDs(
          allowedStudentIDs.filter(studentID => studentID !== userID)
        );
      }
    },
    [allowedStudentIDs, setAllowedStudentIDs]
  );
  return (
    <GameSettingStudentTable>
      {isLoading && <Spinner position="inherit" gridColumn="1/17" />}
      {!isLoading && students === 0 && (
        <span
          style={{
            gridColumn: '1/17',
            textAlign: 'center',
            marginTop: '1rem',
            height: '70vh'
          }}
        >
          No students
        </span>
      )}
      {!isLoading && students && allowedStudentIDs && (
        <table className="table table-striped table-hover">
          <thead className="table-borderless">
            <tr className="table-head">
              <th scope="col">{t(`manage-class:studList.plan`, 'Plan')}</th>
              <th scope="col">
                {t(`manage-class:studList.studName`, 'Student Name')}
              </th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody className="table-bordered">
            {students &&
              students.map(item => (
                <tr className="table-row" key={item.UserId}>
                  <td className="plan">
                    {getPlanIcon(item.PlanId) !== null && (
                      <img src={getPlanIcon(item.PlanId)} alt="Plan Icon" />
                    )}
                    {item.PlanName}
                  </td>
                  <td className="table-row--student">
                    <img
                      onError={e => {
                        e.target.src = avatarPlaceholder;
                      }}
                      src={item.ProfilePictureUrl}
                      alt="profile"
                    />
                    {item.Name}
                  </td>
                  <td>
                    <div className="container-checkbox">
                      <input
                        value={item.UserId}
                        id={`check-${item.UserId}`}
                        type="checkbox"
                        className="student-check-box individual"
                        name="check"
                        checked={allowedStudentIDs.includes(item.UserId)}
                        onChange={handleOnChange}
                      />
                      <Form.Label htmlFor={`check-${item.UserId}`}>
                        {t(`manage-class:gameSettings.allow`, 'Allow access')}
                      </Form.Label>
                      <span className="checkmark" />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </GameSettingStudentTable>
  );
};

export default React.memo(GameSettingStudentList);
