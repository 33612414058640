import styled from 'styled-components';

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.9375rem;
  font-weight: 600;
  color: #1c1b1b;
  padding: 2rem 1rem;
  input {
    border: solid 1px #cecece;
  }
  .next-prev-page {
    margin-right: 1rem;
    .btn-outline-page-primary {
      background: #fff;
      border: solid 1px #cecece;
      font-weight: 600;
      margin-left: 1rem;
    }
    .btn-outline-page-primary.selected {
      color: #fff;
      background: #ff5b00;
    }
    .btn-outline-page-primary.selected:hover {
      color: #fff;
    }
    .btn-outline-page-primary:hover {
      color: #ff5b00;
    }
    .btn-outline-page-primary.disabled,
    .btn-outline-page-primary:disabled {
      color: #d8d8d8;
    }
  }
`;
