import React, { useCallback, useEffect } from 'react';
import FactualFluencyHeader from 'components/CreateAndAssign/FactualFluency/Preview/FactualFluencyHeader';
import FactualFluencyQuestion from 'components/CreateAndAssign/FactualFluency/Preview/FactualFluencyQuestion';

import {
  Backdrop,
  ButtonPrev,
  ButtonNext,
  Container,
  Countdown,
  FactualFluencyBody
} from './FactualFluencyPreview.styles';
import { useState } from 'react';
import { getTimeType, secondsToTime } from 'helpers/timesParser';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const FactualFluencyPreview = ({ onMute, mute }) => {
  const history = useHistory();
  const { assignmentType, timeLimit } = useSelector(state => state.worksheet);

  const { createFactualFluencyItems } = useSelector(
    state => state.factualFluency
  );

  const [isStart, setIsStart] = useState(true);
  const [intervalActive, setIntervalActive] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const [time, setTime] = useState(secondsToTime(timeLimit));
  const [seconds, setSeconds] = useState(timeLimit);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [inputValue, setInputValue] = useState('');

  const timeType = getTimeType(assignmentType);

  const resetInterval = useCallback(() => {
    setIntervalActive(false);
    setSeconds(timeLimit);
    setTime(secondsToTime(timeLimit));
    setTimeout(function() {
      setIntervalActive(true);
    }, 500);
    // eslint-disable-next-line
  }, []);

  const onChange = useCallback(e => {
    setInputValue(e.target.value);
  }, []);

  const onSkip = useCallback(() => {
    if (currentQuestion < createFactualFluencyItems.length - 1) {
      setInputValue('');
      setCurrentQuestion(currentQuestion + 1);

      if (timeType.value === 5) {
        resetInterval();
      }
    } else {
      history.push('/factual-fluency/result');
    }
    // eslint-disable-next-line
  }, [currentQuestion, timeType]);

  const onPrev = useCallback(() => {
    if (currentQuestion > 0) {
      setInputValue('');
      setCurrentQuestion(currentQuestion - 1);
      if (timeType.value === 5) {
        resetInterval();
      }
    }
    // eslint-disable-next-line
  }, [currentQuestion, timeType]);

  const onSubmitAnswer = useCallback(
    e => {
      if (e.key === 'Enter') {
        if (currentQuestion < createFactualFluencyItems.length - 1) {
          setInputValue('');
          setCurrentQuestion(prev => prev + 1);
          if (timeType.value === 5) {
            resetInterval();
          }
        } else {
          history.push('/factual-fluency/result');
        }
      }
    },
    // eslint-disable-next-line
    [inputValue, timeType]
  );

  useEffect(() => {
    let interval;
    let timeout;

    switch (timeType.value) {
      case 4:
        if (isStart) {
          interval = setInterval(() => {
            setSeconds(seconds - 1);
            setTime(secondsToTime(seconds - 1));
          }, 1000);
          if (seconds === 0) {
            clearInterval(interval);
          }
        }
        break;
      case 5:
        if (isStart) {
          timeout = setTimeout(function() {
            setIntervalActive(true);
          }, 500);
        }
        interval = setInterval(() => {
          setSeconds(seconds - 1);
          setTime(secondsToTime(seconds - 1));
        }, 1000);
        if (seconds <= 0) {
          if (currentQuestion < createFactualFluencyItems.length - 1) {
            setInputValue('');
            setCurrentQuestion(currentQuestion + 1);
            resetInterval();
          } else {
            clearInterval(interval);
            clearTimeout(timeout);
            history.push('/factual-fluency/result');
          }
        }
        break;
      default:
        break;
    }

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, [seconds, timeType, isStart, currentQuestion, createFactualFluencyItems]);

  useEffect(() => {
    let interval;
    setIsStart(false);
    switch (timeType.value) {
      case 4:
        interval = setInterval(() => {
          setCountdown(countdown - 1);
        }, 1000);
        if (countdown === 0) {
          setIsStart(true);
          clearInterval(interval);
        }
        break;
      default:
        setIsStart(true);
        clearInterval(interval);
        break;
    }

    return () => clearInterval(interval);
  }, [countdown, timeType]);

  return (
    <Container>
      {!isStart && (
        <>
          <Backdrop />
          <Countdown>{countdown}</Countdown>
        </>
      )}

      <FactualFluencyHeader
        timeType={timeType}
        time={time}
        intervalActive={intervalActive}
        limit={timeLimit}
        onMute={onMute}
        mute={mute}
      />
      <FactualFluencyBody>
        <FactualFluencyQuestion
          questionNumber={currentQuestion + 1}
          question={createFactualFluencyItems[currentQuestion]}
          value={inputValue}
          onChange={onChange}
          onSubmitAnswer={onSubmitAnswer}
        />
      </FactualFluencyBody>
      {currentQuestion > 0 && (
        <ButtonPrev className="btn" onClick={onPrev}>
          <i className="icon-koo-prev" /> Prev
        </ButtonPrev>
      )}
      <ButtonNext className="btn" onClick={onSkip}>
        <i className="icon-koo-skip" /> Next
      </ButtonNext>
    </Container>
  );
};

export default React.memo(FactualFluencyPreview);
