import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { compareValues } from 'helpers/singleClassReport';
import { proficiencyColors } from 'constants/index';
import calculatePercentageString from 'helpers/calculatePercentageString';

import {
  fetchSkillHighScoreDetailReportRequest,
  fetchHighScoreSkillReportRequest
} from 'stores/report/actions';

import SkillTopbar from 'components/Report/SkillReportDetail/SkillTopbar';
import SkillDetailContent from 'components/Report/SkillReportDetail/SkillDetailContent';
import NoReport from 'components/Report/NoReport';
import Spinner from 'components/Shared/Spinner';

import { isNil } from 'ramda';
import SkillDetailPage from './SkillProficiencyReport.styles';

const SkillHighScoreReport = () => {
  const { t } = useTranslation(['proficiency-report']);
  const dispatch = useDispatch();
  const history = useHistory();
  const { topicID, skillID } = useParams();
  const {
    selectedClass,
    reportLevel,
    selectedSyllabus,
    skillDetailReport,
    highScoreSkill
  } = useSelector(state => state.reports);
  const pageLoading = highScoreSkill.isLoading || skillDetailReport?.isLoading;

  useEffect(() => {
    dispatch(
      fetchSkillHighScoreDetailReportRequest({
        classID: selectedClass.ID,
        reportLevel: reportLevel.ID,
        curriculumID: selectedSyllabus.CurriculumID,
        topicID,
        skillID
      })
    );
    dispatch(
      fetchHighScoreSkillReportRequest({
        classID: selectedClass.ID,
        reportLevel: reportLevel.ID,
        curriculumID: selectedSyllabus.CurriculumID,
        topicID
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, skillID]);

  const parseHighScoreProgressBar = data => {
    const progressBarInput = [
      {
        percentage: '',
        color: '#FFC219',
        borderColor: '#D2A905'
      },
      {
        percentage: '',
        color: '#FFEAAF',
        borderColor: '#E5D582'
      },
      {
        percentage: '',
        color: '#FFEAAF',
        borderColor: '#E5D582'
      },
      {
        percentage: '',
        color: '#FFEAAF',
        borderColor: '#E5D582'
      },
      {
        percentage: '',
        color: proficiencyColors.incomplete.color,
        borderColor: proficiencyColors.incomplete.borderColor
      }
    ];
    const masteryIndex = {
      A: 0,
      B: 1,
      C: 2,
      D: 3,
      Incomplete: 4
    };
    // eslint-disable-next-line no-restricted-syntax
    for (const mastery of Object.keys(data?.MasteryLevels)) {
      if (data?.MasteryLevels[mastery] > 0) {
        // Calculate percentage string
        const percentageString = calculatePercentageString(
          data?.MasteryLevels[mastery],
          data?.TotalNoOfUsers
        );
        progressBarInput[masteryIndex[mastery]].percentage = percentageString;
      } else {
        progressBarInput[masteryIndex[mastery]].percentage = '0%';
      }
    }
    return progressBarInput.filter(mastery => mastery.percentage !== '0%');
  };

  const skillData = highScoreSkill?.data?.find(
    skill => skill.SkillId === parseInt(skillID, 10)
  );

  const parseHighScoreSkill = [];
  for (let i = 0; i <= 4; i += 1) {
    const MasteryStatus = {
      0: 'D',
      1: 'C',
      2: 'B',
      3: 'A',
      4: 'Incomplete'
    };
    const findItem = skillDetailReport?.data?.findIndex(
      item => item.MasteryLevelName === MasteryStatus[i]
    );

    if (findItem !== -1) {
      parseHighScoreSkill.push(
        skillDetailReport?.data && skillDetailReport?.data[findItem]
      );
    } else {
      parseHighScoreSkill.push({
        Counts: 0,
        MasteryLevelName: MasteryStatus[i],
        Data: []
      });
    }
  }

  return (
    <SkillDetailPage>
      {pageLoading && <Spinner />}
      {!pageLoading && !isNil(skillDetailReport?.error) && (
        <NoReport
          message={t(
            `proficiency-report:noReports.skillDesc`,
            'Select another skill to show reports.'
          )}
        />
      )}
      {!pageLoading && skillData && (
        <SkillTopbar
          type="high-score"
          item={skillData}
          progressBar={parseHighScoreProgressBar(skillData)}
          skillList={highScoreSkill.data}
          back={() =>
            history.push('/report/class-report/high-score-report', {
              type: 'skill'
            })
          }
        />
      )}
      <div className="content-detail">
        {!pageLoading &&
          skillDetailReport?.data &&
          parseHighScoreSkill
            .sort(compareValues('MasteryLevelName'))
            .map(data => (
              <SkillDetailContent
                key={data.MasteryLevelName}
                data={data}
                reportType="high-score"
                isLast={data.MasteryLevelName === 'Incomplete'}
                level={data.MasteryLevelName}
              />
            ))}
      </div>
    </SkillDetailPage>
  );
};

export default SkillHighScoreReport;
