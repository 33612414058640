import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setStep } from 'stores/utils/step/actions';
import Stepper from './Stepper';

// eslint-disable-next-line
const StepperWizard = props => {
  const dispatch = useDispatch();

  const { activeStep } = useSelector(state => state.step);
  const { children, onSelect, className, backButton } = props;

  let componentChildrens;
  const defaultOnSelect = step => {
    dispatch(setStep(step));
  };

  const handleOnClickStepper = onSelect || defaultOnSelect;
  const steps = [];

  React.Children.map(children, child => {
    steps.push({
      title: child.props.title
    });
    if (activeStep === parseInt(child.props.step, 10)) {
      componentChildrens = child;
    }
  });

  return (
    <>
      <Stepper
        className={className}
        backButton={backButton}
        key="stepper"
        steps={steps}
        activeStep={activeStep}
        onSelect={handleOnClickStepper}
        showNumber
      />
      <div key="stepper-content">{componentChildrens}</div>
    </>
  );
};

export default StepperWizard;
