import React from 'react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'components/CreateAndAssign/DateTimePicker.styles.scss';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import calendarIcon from 'assets/img/icon-calendar-white.svg';

import { DatePickerStyle } from './DatePicker.styles.js';

const config = {
  sundayFirst: true
};

const dateFormat = date => {
  return date.format('DD MMM YYYY, h:mm A');
};

export default function DatePicker({ date, applyCallback, minDate }) {
  return (
    <DateRangePicker
      showDropdowns
      singleDatePicker
      startDate={date}
      local={config}
      onApply={applyCallback}
      minDate={minDate || new Date()}
      onHideCalendar={applyCallback}
      timePicker
      onEvent={applyCallback}
    >
      <DatePickerStyle>
        <span>{dateFormat(date)}</span>
        <img src={calendarIcon} alt="calendar icon" />
      </DatePickerStyle>
    </DateRangePicker>
  );
}
