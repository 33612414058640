import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { find, findIndex, propEq } from 'ramda';
import { selectQuestions } from 'stores/questions/actions';
import { questionStatus } from 'constants/index';

export default function SearchResult() {
  const { t } = useTranslation(['create-assign-import-qn']);
  // const { keyword } = props;
  const { isLoading, searchedQuestions, selectedQuestions } = useSelector(
    state => state.questions
  );

  const validSearchedQuestions = searchedQuestions.filter(
    question => questionStatus[question.Status] === 'Valid'
  );

  const dispatch = useDispatch();

  const checkChecked = id => {
    return findIndex(propEq('ID', parseInt(id, 10)))(selectedQuestions) !== -1;
  };
  const onChange = event => {
    const { value } = event.target;
    // Use new variable to avoid mutating state
    let updatedData;

    if (!checkChecked(value)) {
      const newData = find(propEq('ID', parseInt(value, 10)))(
        searchedQuestions
      );

      updatedData = [...selectedQuestions, newData];
    } else {
      const index = findIndex(propEq('ID', parseInt(value, 10)))(
        selectedQuestions
      );
      updatedData = [
        ...selectedQuestions.slice(0, index),
        ...selectedQuestions.slice(index + 1)
      ];
    }

    dispatch(selectQuestions(updatedData));
  };

  const isAllQuestionsSelected =
    validSearchedQuestions.length > 0
      ? validSearchedQuestions
          .map(question => question.ID)
          .every(
            v =>
              selectedQuestions.map(selected => selected.ID).indexOf(v) !== -1
          )
      : false;

  const toggleCheckAll = event => {
    let updatedData;
    if (event.target.checked) {
      updatedData = [...selectedQuestions, ...validSearchedQuestions];
    } else {
      const questionIds = searchedQuestions.map(question => question.ID);
      updatedData = selectedQuestions.filter(
        question => questionIds.indexOf(question.ID) === -1
      );
    }
    dispatch(selectQuestions(updatedData));
  };

  return (
    <div className={`content-data ${isLoading ? 'on-loading' : ''}`}>
      {isLoading && (
        <Spinner animation="border">
          <span className="sr-only">
            {t('create-assign-import-qn:result.loading', 'Loading...')}
          </span>
        </Spinner>
      )}
      {searchedQuestions.length > 0 ? (
        <>
          <div className="table-responsive table-bordered table-striped">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">
                    {t('create-assign-import-qn:result.id', 'Question ID')}
                  </th>
                  <th scope="col">
                    {t('create-assign-import-qn:result.status', 'Status')}
                  </th>
                  <th scope="col">
                    <Form.Check
                      checked={isAllQuestionsSelected}
                      type="checkbox"
                      onChange={toggleCheckAll}
                      label={t(
                        'create-assign-import-qn:result.addAll',
                        'Add all?'
                      )}
                      bsPrefix="form-check-visible search-questionid__input--check"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {searchedQuestions.map(question => (
                  <tr key={question.ID}>
                    <td>{question.ID}</td>
                    <td>{questionStatus[question.Status]}</td>
                    <td>
                      {questionStatus[question.Status] === 'Valid' && (
                        <Form.Check
                          checked={checkChecked(question.ID)}
                          onChange={onChange}
                          type="checkbox"
                          value={question.ID}
                          bsPrefix="form-check-visible"
                          className="search-questionid__input--check"
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: '40vh' }}
        >
          <h4>
            {t('create-assign-import-qn:result.notFound', 'Question Not found')}
          </h4>
        </div>
      )}
    </div>
  );
}
