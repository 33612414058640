import { call, put, select } from 'redux-saga/effects';
import { isNil } from 'ramda';
import LookupService from 'services/lookup';
import {
  fetchClassSuccess,
  fetchClassFailed,
  fetchStudentsSuccess,
  fetchStudentsFailed,
  fetchMyGroupsSuccess,
  fetchMyGroupsFailed
} from 'stores/utils/lookup/actions';
import { setSelectedClass } from 'stores/report/actions';

export function* fetchClassByLevel({ LevelId }) {
  const data = yield call(LookupService.getClassByLevel, LevelId);
  let { selectedClass, selectedClassLevel } = yield select(
    state => state.reports
  );
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchClassSuccess(data));
      const filteredClasses = data.filter(
        classObject => classObject.Type === 1
      );
      if (isNil(selectedClass) || selectedClass.LevelId !== selectedClassLevel)
        yield put(setSelectedClass(filteredClasses[0]));
    } else {
      yield put(fetchClassFailed(data.error));
    }
  }
}

export function* fetchStudentsByClass({ ClassId }) {
  const data = yield call(LookupService.getStudentsByClass, ClassId);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchStudentsSuccess(data));
    } else {
      yield put(fetchStudentsFailed(data.error));
    }
  }
}

export function* fetchStudentsByKeyword({ keyword, page, limit }) {
  const data = yield call(
    LookupService.getStudentsByKeyword,
    keyword,
    page,
    limit
  );
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchStudentsSuccess(data));
    } else {
      yield put(fetchStudentsFailed(data.error));
    }
  }
}

export function* fetchMyGroups() {
  const data = yield call(LookupService.getMyGroups);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchMyGroupsSuccess(data));
    } else {
      yield put(fetchMyGroupsFailed(data.error));
    }
  }
}
