import React from 'react';

export default function DifficultyChillis(props) {
  const { value } = props;
  const Chillis = [];
  const filteredValue = value <= 0 ? 3 : value;

  for (let index = 1; index <= 9; index += 2) {
    Chillis.push(
      <i
        key={index}
        className={
          index <= filteredValue ? 'icon-chilli active' : 'icon-chilli'
        }
      />
    );
  }
  return <span className="justify-content-center">{Chillis}</span>;
}
