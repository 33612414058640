import styled from 'styled-components';

const SkillsResultContainer = styled.div`
  .subbar-inner {
    position: relative;
    top: 48px;
    z-index: 10;
  }
`;

export default SkillsResultContainer;
