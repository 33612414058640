import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMyGroupRequest } from 'stores/createGroup/actions';

import GroupList from './GroupList';
import Spinner from 'components/Shared/Spinner';
import { ShowGroupWrapper } from './ShowGroup.styles';

const ShowGroup = () => {
  const dispatch = useDispatch();
  const { allGroups, isLoading } = useSelector(state => state.createGroups);

  useEffect(() => {
    dispatch(fetchMyGroupRequest());
    // eslint-disable-next-line
  }, []);

  return (
    <ShowGroupWrapper>
      {isLoading && <Spinner />}
      {!isLoading && <GroupList list={allGroups} />}
    </ShowGroupWrapper>
  );
};

export default ShowGroup;
