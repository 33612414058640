import React from 'react';
import { Trans } from 'react-i18next';

import { Container } from './ValidationMessage.styles';

export default function ValidationMessage() {
  return (
    <Container>
      <Trans i18nKey="create-assign-question:validation.max50">
        Please select max <strong>50</strong> questions to proceed.
      </Trans>
    </Container>
  );
}
