const sortClasses = classList => {
  const sortedClasses = [];
  for (const classObject of classList) {
    const hasLevel = sortedClasses.findIndex(
      item => item.level === classObject.LevelId
    );
    if (hasLevel === -1) {
      sortedClasses.push({
        level: classObject.LevelId,
        classes: [classObject]
      });
    } else {
      sortedClasses[hasLevel].classes.push(classObject);
    }
  }
  return sortedClasses;
};

export default sortClasses;
