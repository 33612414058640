import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import iconRight from 'assets/img/icon-right-purple.svg';
import { clone, sum } from 'ramda';
import {
  TopicWrap,
  ArrowImage,
  TopicItemContainer,
  SkillItem
} from './TopicItem.style';
import SelectNumberQuestions from '../SelectNumberQuestions';

const TopicItem = ({
  topic,
  index,
  counts,
  setCounts,
  allOpen,
  setAllOpen
}) => {
  const { t } = useTranslation(['create-assign-factual-fluency.json']);
  const [showSkill, setShowSkill] = useState(false);
  useEffect(() => {
    if (allOpen) {
      setShowSkill(true);
    }
  }, [allOpen]);
  const onChangeHandler = useCallback(
    (val, difficultyLevel, indexSkill) => {
      if (/^\d*\.?\d*$/.test(val)) {
        const tempCounts = clone(counts);
        const objVal = tempCounts[index];
        if (val === '') val = 0;
        if (val <= 10) {
          objVal.skills[indexSkill].numberOfQuestion = Number(val);
          objVal.total = sum(
            objVal.skills.map(item => {
              return item.numberOfQuestion;
            })
          );
          setCounts(tempCounts);
        }
      }
    },
    [index, counts, setCounts]
  );

  const onControlsHandler = useCallback(
    (type, difficultyLevel, indexSkill) => {
      const tempCounts = clone(counts);
      const objVal = tempCounts[index];
      if (type === 'inc') {
        objVal.skills[indexSkill].numberOfQuestion =
          objVal.skills[indexSkill].numberOfQuestion + 1;
      } else if (objVal.skills[indexSkill].numberOfQuestion !== 0) {
        objVal.skills[indexSkill].numberOfQuestion =
          objVal.skills[indexSkill].numberOfQuestion - 1;
      }
      if (objVal.skills[indexSkill].numberOfQuestion <= 10) {
        objVal.total = sum(
          objVal.skills.map(item => {
            return item.numberOfQuestion;
          })
        );
        setCounts(tempCounts);
      }
    },
    [index, counts, setCounts]
  );

  const onPress = () => {
    setShowSkill(prev => {
      setAllOpen(!prev);
      return !prev;
    });
  };

  return (
    <TopicWrap key={String(topic.Id)}>
      <TopicItemContainer onClick={onPress}>
        <div className="title-wrap">
          <button className="arrow-button">
            <ArrowImage className={showSkill ? 'open' : ''} src={iconRight} />
          </button>

          <div className="title-topic">{topic.Name}</div>
        </div>
        <div className="question-wrap">
          <span className={`total-qn ${counts[index].total === 0 && 'empty'}`}>
            {counts[index].total}
          </span>
        </div>
      </TopicItemContainer>
      <div className={`skill-wrap ${showSkill ? 'open' : 'hide'}`}>
        {topic.Skills.map((Skill, i) => (
          <SkillItem key={String(Skill.Id)}>
            <div className="title-wrap">
              <div className="subtitle-skill">{`${t(
                'create-assign-factual-fluency:main.skill',
                'Skill'
              )} ${i + 1}`}</div>
              <div className="title-skill">{Skill.Name}</div>
            </div>
            <SelectNumberQuestions
              type="skill"
              count={counts[index].skills[i].numberOfQuestion}
              changeCount={onChangeHandler}
              changeControl={onControlsHandler}
              color="#48D292"
              index={i}
              label=""
            />
          </SkillItem>
        ))}
      </div>
    </TopicWrap>
  );
};
export default React.memo(TopicItem);
