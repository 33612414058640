import React from 'react';

const Overlay = props => {
  const { isVisible, toggleVisible } = props;
  return (
    <div
      className={isVisible ? 'content-overlay' : ''}
      onClick={toggleVisible}
      onKeyPress={toggleVisible}
      role="button"
      tabIndex={0}
    />
  );
};

export default Overlay;
