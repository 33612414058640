import {
  FETCH_LEVELS_REQUEST,
  FETCH_LEVELS_SUCCESS,
  FETCH_LEVELS_FAILED,
  FETCH_VIDEOS_REQUEST,
  FETCH_VIDEOS_SUCCESS,
  FETCH_VIDEOS_FAILED,
  SELECTED_LEVEL
} from './actionTypes';

export const fetchLevelsRequest = () => {
  return { type: FETCH_LEVELS_REQUEST };
};

export const fetchLevelsSuccess = levels => {
  return { type: FETCH_LEVELS_SUCCESS, levels };
};

export const fetchLevelsFailed = error => {
  return { type: FETCH_LEVELS_FAILED, error };
};

export const selectLevel = selectedLevel => {
  return { type: SELECTED_LEVEL, selectedLevel };
};

export const setVideosRequest = selectedLevel => {
  return { type: FETCH_VIDEOS_REQUEST, selectedLevel };
};

export const setVideosSuccess = data => {
  return { type: FETCH_VIDEOS_SUCCESS, data };
};

export const setVideosFailed = error => {
  return { type: FETCH_VIDEOS_FAILED, error };
};
