import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

const StyledModal = styled(Modal)`
  .modal-dialog {
    display: flex;
    align-items: center;
  }
  .modal-content {
    background-color: transparent;
    align-items: center;
    width: ${({ width }) => width || '100%'};
  }
  .modal-custom {
    min-width: 30%;
  }
  .modal-custom-large {
    min-width: 60%;
  }
`;

export default StyledModal;
