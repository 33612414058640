import React from 'react';
import { useTranslation } from 'react-i18next';
import { isNil } from 'ramda';
import { useSelector } from 'react-redux';
import { featureToggle } from 'constants/index';
import SavedWorksheetRow from './SavedWorksheetRow';
import './SavedWorksheetList.styles.scss';

export default function SavedWorksheetList() {
  const { t } = useTranslation(['create-assign-saved']);
  const { worksheets } = useSelector(state => state.worksheet);

  const dataWorksheets = !isNil(worksheets.List) ? worksheets.List : [];

  return (
    <div className="content-data">
      <div className="saved-worksheet__content-header">
        <div className="saved-worksheet-header saved-worksheet-header--wtype">
          <p>{t(`create-assign-saved:main.type`, 'Type')}</p>
        </div>
        <div className="saved-worksheet-header saved-worksheet-header--wtitle">
          <p>{t(`create-assign-saved:main.woTitle`, 'Worksheet Title')}</p>
        </div>
        <div className="saved-worksheet-header saved-worksheet-header--wdate">
          <p>{t(`create-assign-saved:main.lastMod`, 'Last Modified')}</p>
        </div>
        {featureToggle.previewButton && (
          <div className="saved-worksheet-header saved-worksheet-header--wpreview">
            <p>{t(`create-assign-saved:main.type`, 'Preview')}</p>
          </div>
        )}
      </div>
      <div>
        {dataWorksheets.map(worksheet => (
          <SavedWorksheetRow key={worksheet.ID} worksheet={worksheet} />
        ))}
      </div>
    </div>
  );
}
