import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import emptyCartImage from 'assets/img/empty-cart.svg';

/**
 * @todo adding classNames and styles
 */
export default function EmptyCartQuestion() {
  const { t } = useTranslation(['create-assign-question']);
  return (
    <div className="d-flex flex-column justify-content-center align-items-center page-empty-cart">
      <img
        src={emptyCartImage}
        alt="Empty Card"
        className="page-empty-cart-image"
      />
      <h1 className="text-center">
        <b>
          {' '}
          {t(
            `create-assign-question:main.cartEmpty`,
            'Your question cart is empty.'
          )}
        </b>
      </h1>
      <h2 className="text-center">
        {t(`create-assign-question:main.cartMsg1`, 'You can click the ')}
        <b>{t(`create-assign-question:main.cartMsg2`, 'Browse Content ')}</b>
        {t(`create-assign-question:main.cartMsg3`, 'at the top bar to ')}
        <b>{t(`create-assign-question:main.cartMsg4`, 'pick questions.')}</b>
      </h2>
      <Link
        type="button"
        className="btn btn-lg btn-primary page-empty-cart-btn"
        to="/browse-content"
      >
        {t(`create-assign-question:main.browseContent`, 'Browse Content')}
      </Link>
    </div>
  );
}
