import React from 'react';
import { useTranslation } from 'react-i18next';

export default function QuestionHeader(props) {
  const { t } = useTranslation(['create-assign-question']);
  // const { order, score } = props;
  const { order } = props;
  return (
    <div className="d-flex justify-content-between question-header">
      <div className="question-header-title">
        {t(`create-assign-question:assign.question`, 'Question')}
        <span>{order}</span>
      </div>
      {/* <div className="question-header-meta">
        Score Weightage <span>{score}</span> marks
      </div> */}
    </div>
  );
}
