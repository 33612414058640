// select
export const SET_START_END_DATE = '@SCHEDULE/SET_START_END_DATE';
export const SET_RELEASE_DATE = '@SCHEDULE/SET_RELEASE_DATE';
export const SET_TIMEZONE = '@SCHEDULE/SET_TIMEZONE';
export const SET_VISIBILITY = '@SCHEDULE/SET_VISIBILITY';
export const SET_KOKO_CREDIT = '@SCHEDULE/SET_KOKO_CREDIT';

export const ASSIGN_CLASS = '@SCHEDULE/ASSIGN_CLASS';
export const ASSIGN_GROUP = '@SCHEDULE/ASSIGN_GROUP';
export const ASSIGN_STUDENT = '@SCHEDULE/ASSIGN_STUDENT';
export const ASSIGN_PRIVATE_GROUP = '@SCHEDULE/ASSIGN_PRIVATE_GROUP';
export const ASSIGN_ADMIN_GROUP = '@SCHEDULE/ASSIGN_ADMIN_GROUP';
export const ASSIGN_LEVEL = '@SCHEDULE/ASSIGN_LEVEL';
export const RESET_SCHEDULE = '@SCHEDULE/RESET_SCHEDULE';
export const RESET_ASSIGNMENT = '@SCHEDULE/RESET_ASSIGNMENT';
