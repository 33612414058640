import {
  FETCH_TOPIC_SKILL_REQUEST,
  FETCH_TOPIC_SKILL_SUCCESS,
  FETCH_TOPIC_SKILL_FAILED,
  NO_TOPIC_SKILL,
  CREATE_FACTUAL_FLUENCY_RESET,
  CREATE_FACTUAL_FLUENCY_REQUEST,
  CREATE_FACTUAL_FLUENCY_SUCCESS,
  CREATE_FACTUAL_FLUENCY_FAILED,
  CREATE_FACTUAL_FLUENCY_ERROR,
  SET_CREATE_FACTUAL_FLUENCY_QUESTIONS,
  REMOVE_CREATE_FACTUAL_FLUENCY_QUESTION,
  FETCH_FACTUAL_FLUENCY_LEADERBOARD_REQUEST,
  FETCH_FACTUAL_FLUENCY_LEADERBOARD_SUCCESS,
  FETCH_FACTUAL_FLUENCY_LEADERBOARD_FAILED,
  FACTUAL_FLUENCY_LEADERBOARD_RESET
} from './actionTypes';

export const fetchTopicSkillRequest = LevelID => {
  return {
    type: FETCH_TOPIC_SKILL_REQUEST,
    LevelID
  };
};

export const fetchTopicSkillSuccess = TopicSkill => {
  return { type: FETCH_TOPIC_SKILL_SUCCESS, TopicSkill };
};

export const fetchTopicSkillFailed = error => {
  return { type: FETCH_TOPIC_SKILL_FAILED, error };
};

export const noTopicSkill = () => {
  return { type: NO_TOPIC_SKILL };
};

export const createFactualFluencyReset = () => {
  return { type: CREATE_FACTUAL_FLUENCY_RESET };
};

export const createFactualFluencyRequest = obj => {
  return {
    type: CREATE_FACTUAL_FLUENCY_REQUEST,
    obj
  };
};

export const createFactualFluencySuccess = data => {
  return { type: CREATE_FACTUAL_FLUENCY_SUCCESS, data };
};

export const createFactualFluencyFailed = error => {
  return { type: CREATE_FACTUAL_FLUENCY_FAILED, error };
};

export const createFactualFluencyError = error => {
  return { type: CREATE_FACTUAL_FLUENCY_ERROR, error };
};

export const setCreateFactualFluencyQuestions = questions => {
  return {
    type: SET_CREATE_FACTUAL_FLUENCY_QUESTIONS,
    questions
  };
};

export const removeCreateFactualFluencyQuestion = Id => {
  return { type: REMOVE_CREATE_FACTUAL_FLUENCY_QUESTION, Id };
};

export const fetchFactualFluencyLeaderboardRequest = homeworkID => {
  return {
    type: FETCH_FACTUAL_FLUENCY_LEADERBOARD_REQUEST,
    homeworkID
  };
};

export const fetchFactualFluencyLeaderboardSuccess = leaderboard => {
  return {
    type: FETCH_FACTUAL_FLUENCY_LEADERBOARD_SUCCESS,
    leaderboard
  };
};

export const fetchFactualFluencyLeaderboardFailed = error => {
  return {
    type: FETCH_FACTUAL_FLUENCY_LEADERBOARD_FAILED,
    error
  };
};

export const factualFluencyLeaderboardReset = () => {
  return { type: FACTUAL_FLUENCY_LEADERBOARD_RESET };
};
