import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { isNil } from 'ramda';
import HTMLReactParser from 'html-react-parser';
import questionParser from 'helpers/questionParser';
import convertTagsToHTML from 'helpers/text/convertTagsToHTML';
import Collapse from 'components/Shared/Collapse';
import DifficultyChillis from 'components/Level/DifficultyChillis';
import wrongAnsIcon from 'assets/img/icon-track-assignment-wrong-ans.svg';
import './StudentSummaryModal.styles.scss';
import {
  McqOptionsList,
  TableModal
} from 'components/Question/QuestionContent.styles';
import { parseQnAnswer } from 'helpers/singleClassReport';
import MarkRightPopover from './MarkRightPopover';

const TickIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="19"
      viewBox="0 0 30.432 24.152"
    >
      <path
        id="Path_1863"
        data-name="Path 1863"
        d="M6481,333.5l9.031,8.5,14.344-17"
        transform="translate(-6477.466 -321.477)"
        fill="none"
        stroke="#55C92B"
        strokeLinecap="round"
        strokeWidth="5"
      />
    </svg>
  );
};

const Test = ({ data, qnNumber, hideModal, hwID }) => {
  const {
    isCorrect,
    questionDifficulty,
    questionPayload,
    studentAnswer,
    submissionId
  } = data;

  // States
  const [questionTitle, setQuestionTitle] = useState(null);
  const [questionSolution, setQuestionSolution] = useState(null);
  const [questionAnswerParsed, setQuestionAnswerParsed] = useState(null);
  const [questionMcqOptions, setQuestionMCQOptions] = useState([]);

  // Effects
  useEffect(() => {
    if (!isNil(questionPayload)) {
      const { title, solution, mcqOptions, questionAnswer } = questionParser({
        Payload: questionPayload
      });
      const renderOptions = mcqOptions.map(option =>
        HTMLReactParser(convertTagsToHTML(option, false), {
          replace: domNode => {
            if (domNode.name === 'img') {
              domNode.attribs.style = 'width: 68%';
              return domNode;
            }
            return domNode;
          }
        })
      );
      setQuestionMCQOptions(renderOptions);
      setQuestionTitle(
        HTMLReactParser(convertTagsToHTML(title), {
          replace: domNode => {
            if (domNode.name === 'img' && !isNil(domNode.attribs?.url)) {
              return (
                <img
                  src={domNode.attribs?.url}
                  alt={domNode.attribs?.uniqueid}
                />
              );
            }
            if (domNode.name === 'helptext') {
              return (
                <small id="questionHelp" className="form-text text-muted">
                  {domNode.children[0]?.data ?? ''}
                </small>
              );
            }
            if (domNode.name === 'input') {
              return <input type="text" />;
            }
            return domNode;
          }
        })
      );

      setQuestionSolution(HTMLReactParser(convertTagsToHTML(solution)));
      setQuestionAnswerParsed(parseQnAnswer(questionAnswer));
    }
  }, [questionPayload]);

  return (
    <>
      <div className="question-item-body">
        <div className="media">
          <div className="media-body">
            <div className="qs-inner">
              <div className="qs-attr">
                <div className="row">
                  <div className="col-md">
                    <div className="d-flex">
                      <span>Question {qnNumber}</span>
                      <span>
                        Difficulty:{' '}
                        <DifficultyChillis
                          value={questionDifficulty.Max ?? 0}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="qs-text">
                <TableModal>{questionTitle}</TableModal>
                {questionMcqOptions.length > 0 && (
                  <McqOptionsList>
                    {questionMcqOptions.map(option => (
                      <li key={option}>{option}</li>
                    ))}
                  </McqOptionsList>
                )}
              </div>
            </div>
            <div className="qs-student-answer">
              <p>Student's Answer:</p>
              <div className="answer-correction">
                <div className="student-answer-result">
                  <span style={{ marginRight: '1rem' }}>{studentAnswer}</span>
                  {isCorrect ? (
                    <TickIcon />
                  ) : (
                    <img
                      src={wrongAnsIcon}
                      alt="Wrong Answer"
                      className="student-answer-wrong"
                    />
                  )}
                </div>
                {!isCorrect && (
                  <MarkRightPopover
                    submissionId={submissionId}
                    hide={hideModal}
                    homeworkID={hwID}
                  />
                )}
              </div>
            </div>
            <div className="qs-solution">
              <Collapse
                collapsed={false}
                hideText="Hide Solution"
                showText="Show Solution"
              >
                {questionAnswerParsed !== null &&
                  questionAnswerParsed.length > 0 && (
                    <>
                      <p>
                        <strong>Answer:</strong>
                      </p>
                      <p>{questionAnswerParsed}</p>
                    </>
                  )}
                <p>
                  <strong>Solution:</strong>
                </p>
                {questionSolution}
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function StudentSummaryModal({ show, hide, data, id }) {
  return (
    <Modal show={show} onHide={() => hide()} className="question-summary">
      {data && (
        <div className="student-summary-modal">
          <div className="question-item-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => hide()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h3>{data.hwTitle}</h3>
            <p className="text-muted">{data.selectedStudent}</p>
            <p>Score: {data.studentScore}</p>
          </div>
          {data.questions &&
            data.questions
              .slice(1)
              .map((question, index) => (
                <Test
                  key={`qn-${index}`}
                  data={question}
                  qnNumber={index + 1}
                  hideModal={hide}
                  hwID={id}
                />
              ))}
        </div>
      )}
    </Modal>
  );
}
