import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectCurriculum } from 'stores/curriculums/actions';
import { selectLevel } from 'stores/levels/actions';
import { subjectTypes } from 'constants/index';
import history from 'helpers/history';
import {
  MultipleCurriculumModalBodyContainer,
  CurriculumNav,
  Curriculums,
  Pagination
} from './MultipleCurriculumModalBody.styles';
import CurriculumCard from './CurriculumCard';

const MultipleCurriculumModalBody = ({
  hide,
  curriculums,
  selectedCurriculum,
  selectedLevel
}) => {
  const { t } = useTranslation(['browse-content']);
  const dispatch = useDispatch();
  const uniqueSubjectIDs = [
    ...new Set(curriculums.map(curriculum => curriculum.SubjectID))
  ];
  // Component States
  const [selectedModalCurriculum, setSelectedModalCurriculum] = useState(
    selectedCurriculum
  );
  const [selectedSubjectID, setSubjectID] = useState(uniqueSubjectIDs[0]);
  const [selectedModalLevel, setSelectedModalLevel] = useState(selectedLevel);
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(
    curriculums.filter(curriculum => curriculum.SubjectID === selectedSubjectID)
      .length / 5
  );

  return (
    <MultipleCurriculumModalBodyContainer>
      <CurriculumNav>
        <>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            {uniqueSubjectIDs.map(subjectID => (
              <a
                key={subjectID}
                className={`nav-item nav-link ${
                  selectedSubjectID === subjectID ? 'active' : ''
                }`}
                data-toggle="tab"
                href="#nav-home"
                role="tab"
                aria-controls="nav-home"
                aria-selected={
                  selectedSubjectID === subjectID ? 'true' : 'false'
                }
                onClick={() => {
                  setSubjectID(subjectID);
                }}
              >
                {t(
                  `browse-content:curriculumSection.${subjectTypes[subjectID]}`,
                  subjectTypes[subjectID]
                )}
              </a>
            ))}
          </div>
        </>
        <button
          type="button"
          className="close-btn"
          onClick={() => {
            hide();
          }}
        >
          X
        </button>
      </CurriculumNav>
      <div className="info-text">
        <p>
          {t(
            `browse-content:curriculumSection.choose`,
            'Please choose the curriculum you want to browse'
          )}
        </p>
        <button
          type="button"
          className="confirm-btn"
          data-cy="confirm"
          onClick={() => {
            dispatch(selectCurriculum(selectedModalCurriculum));
            dispatch(selectLevel(selectedModalLevel));
            hide();
            history.push('/browse-content');
          }}
        >
          {t(`browse-content:curriculumSection.confirm`, 'Confirm')}
        </button>
      </div>
      <Curriculums>
        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-math"
            role="tabpanel"
            aria-labelledby="nav-math-tab"
          >
            {curriculums
              .filter(curriculum => curriculum.SubjectID === selectedSubjectID)
              .slice((currentPage - 1) * 5, (currentPage - 1) * 5 + 5)
              .map(item => (
                <CurriculumCard
                  key={`${item.ID}-${item.CurriculumID}`}
                  curriculumName={item.curriculumName}
                  curriculumIcon={item.CurriculumIconUrl}
                  curriculum={item}
                  setSelectedModalCurriculum={setSelectedModalCurriculum}
                  selectedModalCurriculum={selectedModalCurriculum}
                  setSelectedModalLevel={setSelectedModalLevel}
                  selectedModalLevel={selectedModalLevel}
                  currentLevel={selectedLevel}
                />
              ))}
          </div>
        </div>
      </Curriculums>
      <Pagination>
        {currentPage > 1 && (
          <button
            type="button"
            onClick={() => {
              setCurrentPage(currentPage - 1);
            }}
          >
            {'<'}
          </button>
        )}
        {currentPage < maxPage && (
          <button
            type="button"
            onClick={() => {
              setCurrentPage(currentPage + 1);
            }}
          >
            {'>'}
          </button>
        )}
      </Pagination>
    </MultipleCurriculumModalBodyContainer>
  );
};

export default MultipleCurriculumModalBody;
