export const FETCH_MY_GROUPS_REQUEST = 'FETCH_MY_GROUPS_REQUEST';
export const FETCH_MY_GROUPS_SUCCESS = 'FETCH_MY_GROUPS_SUCCESS';
export const FETCH_MY_GROUPS_FAILED = 'FETCH_MY_GROUPS_FAILED';
export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILED = 'DELETE_GROUP_FAILED';
export const DELETE_GROUP_RESET = 'DELETE_GROUP_RESET';
export const CREATE_MY_GROUP_RESET = 'CREATE_MY_GROUP_RESET';
export const CREATE_MY_GROUP_REQUEST = 'CREATE_MY_GROUP_REQUEST';
export const CREATE_MY_GROUP_SUCCESS = 'CREATE_MY_GROUP_SUCCESS';
export const CREATE_MY_GROUP_FAILED = 'CREATE_MY_GROUP_FAILED';
export const UPDATE_GROUP_RESET = 'UPDATE_GROUP_RESET';
export const UPDATE_GROUP_REQUEST = 'UPDATE_GROUP_REQUEST';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILED = 'UPDATE_GROUP_FAILED';
export const GET_CLASS_BY_LEVEL_REQUEST = 'GET_CLASS_BY_LEVEL_REQUEST';
export const GET_CLASS_BY_LEVEL_SUCCESS = 'GET_CLASS_BY_LEVEL_SUCCESS';
export const GET_CLASS_BY_LEVEL_FAILED = 'GET_CLASS_BY_LEVEL_FAILED';
export const GET_STUDENT_BY_CLASS_RESET = 'GET_STUDENT_BY_CLASS_RESET';
export const GET_STUDENT_BY_CLASS_REQUEST = 'GET_STUDENT_BY_CLASS_REQUEST';
export const GET_STUDENT_BY_CLASS_SUCCESS = 'GET_STUDENT_BY_CLASS_SUCCESS';
export const GET_STUDENT_BY_CLASS_FAILED = 'GET_STUDENT_BY_CLASS_FAILED';
