import AStar from 'assets/img/icon-report-high-score-a.svg';
import BStar from 'assets/img/icon-report-high-score-b.svg';
import CStar from 'assets/img/icon-report-high-score-c.svg';
import DStar from 'assets/img/icon-report-high-score-d.svg';
import IncompleteStar from 'assets/img/icon-report-high-score-incomplete.svg';

export const homeworkLink =
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_NETLIFY === 'true'
    ? 'https://student-portal.netlify.app/assignment/start/'
    : 'https://student.koobits.com/assignment/start/';

export const worksheetStatus = {
  draft: 1,
  publish: 3
};

export const assignmentTypes = {
  homework: 1,
  mocktest: 2,
  test: 3,
  total: 4,
  interval: 5,
  notime: 6
};

export const assignmentTypeNames = {
  homework: 'homework',
  mocktest: 'mockTest',
  test: 'test'
};

export const assignmentTypeColors = {
  homework: '#49C8AA',
  mocktest: '#FFD016',
  test: '#FF6E1D'
};

export const assignmentTypeInitials = {
  homework: 'H',
  mocktest: 'M',
  test: 'T'
};

export const presentationTypes = {
  standard: 1,
  kooQuiz: 2
};

export const classType = {
  class: 1,
  group: 3
};

export const assignReferenceType = {
  class: 5,
  group: 5, // same as class
  student: 6,
  level: 4
};

export const questionStatus = {
  1: 'Valid',
  2: 'Invalid',
  3: 'Already Exists In Users Cart'
};

export const assignmentVisibilities = {
  1: 'dayAdvnc',
  2: 'onSameDay',
  3: 'hourAdvnc',
  4: 'minAdvnc'
};

// export const assignmentVisibilities = {
//   1: '1 day in advance',
//   2: 'on the same day',
//   3: '1 hour in advance',
//   4: '30 min in advance'
// };

export const LevelColors = {
  1: '#FFCB51',
  2: '#C1E141',
  3: '#86DFD3',
  4: '#DBACF6',
  5: '#FFB388',
  6: '#F398BA',
  7: '#FFCB51',
  8: '#C1E141',
  9: '#86DFD3',
  10: '#DBACF6',
  11: '#FFB388',
  12: '#F398BA'
};

export const LevelHoverColors = {
  1: '#ffe5a8',
  2: '#e0f0a0',
  3: '#c3efe9',
  4: '#edd6fb',
  5: '#ffd9c4',
  6: '#f9ccdd',
  7: '#ffe5a8',
  8: '#e0f0a0',
  9: '#c3efe9',
  10: '#edd6fb',
  11: '#ffd9c4',
  12: '#f9ccdd'
};

export const proficiencyColors = {
  mastery: {
    color: '#FBDA38',
    borderColor: '#CBAA0A',
    tableOddColor: '#FFFCD5',
    tableEvenColor: '#FFFDE4',
    tableBorderColor: '#D1CEA9'
  },
  competent: {
    color: '#A9E9A4',
    borderColor: '#58BE51',
    tableOddColor: '#EEFBE9',
    tableEvenColor: '#F8FFF4',
    tableBorderColor: '#C4CEC3'
  },
  developing: {
    color: '#7DD3FF',
    borderColor: '#299AD4',
    tableOddColor: '#FFFAEB',
    tableEvenColor: '#FFFCF2',
    tableBorderColor: '#FFFCF2'
  },
  beginning: {
    color: '#FF8282',
    borderColor: '#B93A3A',
    tableOddColor: '#FDF2F2',
    tableEvenColor: '#FFFAFA',
    tableBorderColor: '#E0D0D0'
  },
  incomplete: {
    color: '#EFEFEF',
    borderColor: '#B3CCD6',
    tableOddColor: '#F4F4F4',
    tableEvenColor: '#FAFAFA',
    tableBorderColor: '#CCCCCC'
  }
};

export const masteryColors = {
  A: {
    tableOddColor: '#FFFCD9',
    tableEvenColor: '#FFFDE6',
    tableBorderColor: '#D1CEA9'
  },
  B: {
    tableOddColor: '#F0F6FA',
    tableEvenColor: '#F7F9FA',
    tableBorderColor: '#C5D3E2'
  },
  C: {
    tableOddColor: '#FFFAEB',
    tableEvenColor: '#FFFCF2',
    tableBorderColor: '#E0D0D0'
  },
  D: {
    tableOddColor: '#FFFDF7',
    tableEvenColor: '#FFFFFD',
    tableBorderColor: '#EBE0D0'
  },
  Incomplete: {
    tableOddColor: '#F4F4F4',
    tableEvenColor: '#FAFAFA',
    tableBorderColor: '#CCCCCC'
  }
};

// For Proficiency Report
const negativeOne = -1;
export const ProficiencyStatus = {
  1: 'beginning',
  2: 'developing',
  3: 'competent',
  4: 'mastery',
  0: 'incomplete'
};

export const proficiencyScoreRange = {
  mastery: '80-100',
  competent: '60-79',
  beginning: '0-59',
  incomplete: 'Question < 10'
};

// Grade for High Score Report
export const MasteryLevel = {
  0: 'D',
  1: 'C',
  2: 'B',
  3: 'A',
  [negativeOne]: 'incomplete'
};

export const MasteryLevelRange = {
  A: {
    icon: AStar,
    noQn: '9-10'
  },
  B: {
    icon: BStar,
    noQn: '6-8'
  },
  C: {
    icon: CStar,
    noQn: '4-5'
  },
  D: {
    icon: DStar,
    noQn: '1-3'
  },
  Incomplete: {
    icon: IncompleteStar,
    noQn: null
  }
};

// Subject IDs representing Math, Science etc.
// TODO: Update when subject ids for other subjects like Science is available
export const subjectTypes = {
  1: 'Math'
};

// Login Report Status
export const LoginStatus = {
  0: 'Not log in',
  1: 'Logged in',
  2: 'In progress'
};

// Submission Status
export const SubmissionStatus = {
  1: 'Correct',
  2: 'Wrong',
  3: 'Unknown',
  4: 'Skipped'
};

export const homeworkTypes = {
  3: {
    bgColor: '#49C8AA',
    logo: 'H'
  },
  6: {
    bgColor: '#49C8AA',
    logo: 'H'
  },
  11: {
    bgColor: '#FFC043',
    logo: 'M'
  },
  12: {
    bgColor: '#FF7121',
    logo: 'T'
  },
  13: {
    bgColor: '#fff',
    logo: 'T'
  }
};

// Feature toggle for some features not ready for Teacher Portal demo
export const featureToggle = {
  // NavbarNav.js
  languageDropdown: true,
  // NavbarCollapse.js
  notificationBell: false,
  // SidebarCreateAssign.js
  topicRevision: false,
  // QuestionCardView.js
  videoPlayButton: false,
  // ClassReport-Sidebar.js, SidebarReport.js
  reportSidebarOptions: false,
  // SidebarManageClass.js
  myGroups: false,
  // ActionButtons.js
  classListActions: false,
  // AssignmentCard.js
  assignAgainButton: false,
  popoverMenu: false,
  // SavedWorksheetList.js, SavedWorksheetRow.js
  previewButton: false,
  // SavedWorksheetRowSubMenu.js
  printButton: true,
  // MultiClassReport.js, SingleClassReport.js
  reportActionButtons: true,
  // TopBar.js
  reportPrintButton: false
};

export const questionTypeIDs = {
  1: 'mcq',
  2: 'fillInTheBlanks',
  3: 'longAnswer'
};

export const timeSettingTypes = {
  intervalTime: {
    name: 'Interval Time',
    value: 5
  },
  totalTime: {
    name: 'Total Time',
    value: 4
  },
  noTime: {
    name: 'Do not time me',
    value: 6
  }
};
