import styled from 'styled-components';

export const ProblemsumsTrialContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* margin: 80px 60px; */
  flex-wrap: wrap;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  .logo {
    margin-bottom: 30px;
    text-align: center;
  }
  .level {
    .active {
      background-color: #545454;
      color: white;
      border-color: #545454;
    }
  }
  .topics {
    .active {
      background-color: #545454;
      color: white;
      border-color: #545454;
    }
  }
  .btn-group {
    margin-right: 20px;
    margin-bottom: 10px;
    background-color: #ededed;
    border: 1px solid #adb5bd;
    padding: 5px 12px;
  }
  .vids-header {
    margin-top: 25px;
    padding: 30px 25px 5px;
    font-size: 16px;
    .level-id {
      font-weight: 700;
      margin-right: 3px;
    }
  }
  .age-header {
    font-size: 1rem;
    color: #000000;
    background-color: #ffffff;
    max-width: fit-content;
    margin-left: 1rem;
    margin-top: 4rem;
    padding: 10px 25px;
    font-weight: 600;
  }
`;

export const VideoListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* margin-top: 50px; */
  padding: 10px 30px;
  overflow-y: scroll;
  .video {
    width: 225px;
    height: 127.5px;
    margin-right: 20px;
    margin-bottom: 10px;
    color: #444;
    iframe {
      border: none;
      width: 100%;
      height: 100%;
    }
  }
  .video-title {
    font-weight: bold;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    .video-desc {
      margin-top: 5px;
      font-size: 14px;
      font-weight: 500;
    }
  }
`;
