import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';

export default function Collapse({ collapsed, hideText, showText, children }) {
  const [show, setShow] = useState(collapsed);
  const toggleShow = () => setShow(!show);
  const showStyles = `collapse${show ? ' show' : ''}`;
  return (
    <div className="inner">
      <div className="text-right">
        <Button onClick={toggleShow} variant="link">
          {show ? hideText : showText}
        </Button>
      </div>
      <div id="qssolution1236" className={showStyles}>
        {children}
      </div>
    </div>
  );
}
