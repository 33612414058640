import styled from 'styled-components';

export const ResetPwContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #060606;
  font-size: 0.875rem;
  gap: 1.8rem;
  p {
    margin-bottom: 0;
  }
  .success-title {
    font-size: 1.125rem;
    color: #4dae30;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -2.5rem;
  }
  .desc {
    width: 90%;
    padding: 0 2.5rem;
  }
  .btn-success-ok {
    width: 127px;
    color: #fffdfc;
    background: #5ed46a;
    margin-bottom: 1rem;
  }
  .reset-title {
    color: #ff5b00;
    font-size: 1.125rem;
  }
  .err {
    color: #ff5b00;
  }
  .selection-action-buttons {
    display: flex;
    flex-direction: row;
    border-top: solid 1px rgba(112, 112, 112, 0.22);
    justify-content: space-evenly;
    padding-top: 1rem;
    width: 100%;
    margin-top: 1rem;
    .btn-outline-cancel {
      color: #ff5b00;
      border: solid 1px #d5d5d5;
      width: 127px;
      &:hover {
        border-color: #ff5b00;
        background: #fff7f3;
      }
    }
    .btn-ok {
      width: 127px;
      color: #fffdfc;
      background: #ff5b00;
    }
  }
`;

export const Sample = styled.div`
  border: 1px solid #d1d1d1;
  display: flex;
  padding: 0.8rem;
  gap: 2rem;
`;
