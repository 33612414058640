import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { fetchClassRequest } from 'stores/utils/lookup/actions';
import CheckboxClassGroup from './CheckboxClassGroup';

export default function AssignClass() {
  const { t } = useTranslation(['create-assign-question']);
  const dispatch = useDispatch();

  const { levels, selectedLevel: globalSelectedLevel } = useSelector(
    state => state.levels
  );

  const [selectedLevel, setSelectedLevel] = useState(globalSelectedLevel.ID);

  const onChangeLevel = event => {
    setSelectedLevel(event.target.value);
  };

  useEffect(() => {
    dispatch(fetchClassRequest(selectedLevel));
  }, [dispatch, selectedLevel]); // @see {@link https://github.com/facebook/create-react-app/issues/6880#issuecomment-488158024 having dispatch in useEffect dependencies}

  return (
    <div>
      <p>
        {t(
          `create-assign-question:assignModal.classAssign`,
          'Please choose classes you want to assign'
        )}
      </p>
      <Form.Group controlId="options-level">
        <Form.Control
          as="select"
          name="level"
          onChange={onChangeLevel}
          value={selectedLevel}
        >
          {levels.map(level => (
            <option key={level.ID} value={level.ID}>
              {level.Name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      {/* <Form.Group controlId="options-class-group">
        <Form.Control
          as="select"
          name="classGroup"
          onChange={onChangeClassGroup}
          value={type}
        >
          <option value="class">Class</option>
          <option value="group">Group</option>
        </Form.Control>
      </Form.Group> */}
      <CheckboxClassGroup type="class" />
    </div>
  );
}
