import apiClient from 'services/api';

const handleResponseSuccess = result => {
  if (result.data?.IsSuccessful === false) {
    return {
      error: result.data?.Message?.Message ?? 'API request failed' // eslint-disable-line
    };
  }
  return result.data?.Result ?? [];
};

const postUserPreferences = params => {
  return apiClient
    .post(`UserPreferences/UpdatePreferences`, params)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Update user preferences failed' // eslint-disable-line
      };
    });
};

export default {
  postUserPreferences
};
