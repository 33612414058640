import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  EditPanelStyle,
  MenuSection,
  MenuUp,
  MenuEnd,
  MenuDown,
  MenuDownEnd
} from './EditPanel.styles';
import iconMenu from 'assets/img/icon-menu-list.svg';
import iconMenuActive from 'assets/img/icon-menu-list-active.svg';
import iconUp from 'assets/img/icon-menu-up.svg';
import iconUpActive from 'assets/img/icon-menu-up-active.svg';
import iconEnd from 'assets/img/icon-menu-up-end.svg';
import iconEndActive from 'assets/img/icon-menu-up-end-active.svg';
import iconDown from 'assets/img/icon-menu-down.svg';
import iconDownActive from 'assets/img/icon-menu-down-active.svg';
import iconDownEnd from 'assets/img/icon-menu-down-end.svg';
import iconDownEndActive from 'assets/img/icon-menu-down-end-active.svg';
import {
  setSelectedQuestions,
  setEditSavedWorksheetQuestions
} from 'stores/worksheet/actions';

import { setCreateHomeworkQuestions } from 'stores/topical-worksheet/actions';
import { setCreateFactualFluencyQuestions } from 'stores/factual-fluency/actions';

const EditPanel = props => {
  const { t } = useTranslation(['create-assign-question']);
  const {
    cardIndex,
    isEditSavedWorksheet,
    isCreateHomework,
    isCreateFactualFluency
  } = props;

  const { selectedQuestions, editSavedWorksheetQuestions } = useSelector(
    state => state.worksheet
  );
  const { createWorksheetItems } = useSelector(state => state.topicalWorksheet);
  const { createFactualFluencyItems } = useSelector(
    state => state.factualFluency
  );

  // Copy into new array, but @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_syntax#Copy_an_array potential hiccups}
  let updatedSelectedQuestions = [];
  if (isEditSavedWorksheet)
    updatedSelectedQuestions = [...editSavedWorksheetQuestions];
  else if (isCreateHomework)
    updatedSelectedQuestions = [...createWorksheetItems];
  else if (isCreateFactualFluency)
    updatedSelectedQuestions = [...createFactualFluencyItems];
  else updatedSelectedQuestions = [...selectedQuestions];

  const dispatch = useDispatch();

  const moveUp = () => {
    const firstIndex = 0;
    const move = cardIndex - 1;
    if (move >= firstIndex) {
      const tmp = updatedSelectedQuestions[cardIndex];
      updatedSelectedQuestions[cardIndex] = updatedSelectedQuestions[move];
      updatedSelectedQuestions[move] = tmp;
      if (isEditSavedWorksheet) {
        dispatch(setEditSavedWorksheetQuestions(updatedSelectedQuestions));
      } else if (isCreateHomework) {
        dispatch(setCreateHomeworkQuestions(updatedSelectedQuestions));
      } else if (isCreateFactualFluency) {
        dispatch(setCreateFactualFluencyQuestions(updatedSelectedQuestions));
      } else {
        dispatch(setSelectedQuestions(updatedSelectedQuestions));
      }
    }
  };

  const moveDown = () => {
    const lastIndex = updatedSelectedQuestions.length - 1;
    const move = cardIndex + 1;
    if (move <= lastIndex) {
      const tmp = updatedSelectedQuestions[cardIndex];
      updatedSelectedQuestions[cardIndex] = updatedSelectedQuestions[move];
      updatedSelectedQuestions[move] = tmp;
      if (isEditSavedWorksheet) {
        dispatch(setEditSavedWorksheetQuestions(updatedSelectedQuestions));
      } else if (isCreateHomework) {
        dispatch(setCreateHomeworkQuestions(updatedSelectedQuestions));
      } else if (isCreateFactualFluency) {
        dispatch(setCreateFactualFluencyQuestions(updatedSelectedQuestions));
      } else {
        dispatch(setSelectedQuestions(updatedSelectedQuestions));
      }
    }
  };

  const moveFirst = () => {
    const firstIndex = 0;
    if (cardIndex !== firstIndex) {
      updatedSelectedQuestions.unshift(
        updatedSelectedQuestions.splice(cardIndex, 1)[0]
      );
      if (isEditSavedWorksheet) {
        dispatch(setEditSavedWorksheetQuestions(updatedSelectedQuestions));
      } else if (isCreateHomework) {
        dispatch(setCreateHomeworkQuestions(updatedSelectedQuestions));
      } else if (isCreateFactualFluency) {
        dispatch(setCreateFactualFluencyQuestions(updatedSelectedQuestions));
      } else {
        dispatch(setSelectedQuestions(updatedSelectedQuestions));
      }
    }
  };

  const moveLast = () => {
    const lastIndex = updatedSelectedQuestions.length - 1;
    if (cardIndex !== lastIndex) {
      updatedSelectedQuestions.push(
        updatedSelectedQuestions.splice(cardIndex, 1)[0]
      );
      if (isEditSavedWorksheet) {
        dispatch(setEditSavedWorksheetQuestions(updatedSelectedQuestions));
      } else if (isCreateHomework) {
        dispatch(setCreateHomeworkQuestions(updatedSelectedQuestions));
      } else if (isCreateFactualFluency) {
        dispatch(setCreateFactualFluencyQuestions(updatedSelectedQuestions));
      } else {
        dispatch(setSelectedQuestions(updatedSelectedQuestions));
      }
    }
  };

  return (
    // <div className="question-panel question-action position-absolute flex-column right-0 top-3">
    <EditPanelStyle>
      <MenuSection
        type="button"
        className="question-action__item"
        content={t(`create-assign-question:editWo.addSec`, 'Add Section')}
        icon={iconMenu}
        activeIcon={iconMenuActive}
      />
      <MenuEnd
        type="button"
        onClick={moveFirst}
        className="question-action__item"
        content={t(`create-assign-question:editWo.moveFirst`, 'Move to 1st')}
        icon={iconEnd}
        activeIcon={iconEndActive}
      />
      <MenuUp
        type="button"
        onClick={moveUp}
        className="question-action__item"
        content={t(`create-assign-question:editWo.bringFwd`, 'Bring Forward')}
        icon={iconUp}
        activeIcon={iconUpActive}
      />
      <MenuDown
        type="button"
        onClick={moveDown}
        className="question-action__item"
        content={t(`create-assign-question:editWo.sendBwd`, 'Send Backward')}
        icon={iconDown}
        activeIcon={iconDownActive}
      />
      <MenuDownEnd
        type="button"
        onClick={moveLast}
        className="question-action__item"
        content={t(`create-assign-question:editWo.moveLast`, 'Move to Last')}
        icon={iconDownEnd}
        activeIcon={iconDownEndActive}
      />
    </EditPanelStyle>
    // </div>
  );
};

export default EditPanel;
