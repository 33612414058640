import React, { useState } from 'react';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectQuestion } from 'stores/questions/actions';
import PopupModal from 'components/Shared/PopupModal';
import TrialAccountModalBody from 'components/TrialAccount/TrialAccountModalBody';

export default function SimilarQuestions({
  align,
  button,
  onSelectedQuestion,
  questions,
  variation
}) {
  const { t } = useTranslation(['browse-content']);
  const [hoverButton, setHoverButton] = useState(false);
  const [hoverMenu, setHoverMenu] = useState(false);
  const show = hoverButton || hoverMenu;
  const dispatch = useDispatch();
  const { selectedQuestion, cartQuestions } = useSelector(
    state => state.questions
  );
  const { isTrial } = useSelector(state => state.login);

  // modal state
  const [showTrialAccount, setShowTrialAccount] = useState(false);
  const hideTrialAccount = () => {
    setShowTrialAccount(false);
  };

  // onClick handler for change value button
  const changeValueButton = () => {
    if (isTrial) {
      setShowTrialAccount(true);
      setHoverButton(!hoverButton);
    } else {
      setHoverButton(!hoverButton);
    }
  };

  const isSelectedQuestion = question =>
    selectedQuestion[variation?.ID]?.Id === question.Id ||
    (isNil(selectedQuestion[variation?.ID]) && questions[0].Id === question.Id); // UI, first item as default selected item

  const isAssignedQuestion = question =>
    cartQuestions.findIndex(q => q.QuestionId === question.Id) !== -1;

  const getQuestionClassNames = question => {
    if (isAssignedQuestion(question)) {
      return 'active';
    }

    if (isSelectedQuestion(question)) {
      return 'selected';
    }

    return '';
  };

  let dropdownClassNames = `dropdown-menu`;
  if (align === 'left') {
    dropdownClassNames = `${dropdownClassNames} dropdown-menu-left`;
  }
  if (align === 'right') {
    dropdownClassNames = `${dropdownClassNames} dropdown-menu-right`;
  }

  return (
    <>
      <PopupModal show={showTrialAccount} hide={hideTrialAccount}>
        <TrialAccountModalBody hide={hideTrialAccount} type="trial-account" />
      </PopupModal>
      <span
        className={`dropdown dropdown-lg dropdown-hover ${
          button ? 'd-block mt-3' : ''
        } ${show ? 'show' : ''}`}
      >
        <a
          href="#noredirect"
          className={`${
            button ? 'btn btn-outline-primary btn-block fz14 ' : ''
          }dropdown-toggle`}
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded={show}
          onClick={changeValueButton}
          data-cy="change-value"
        >
          <i className={`${button ? 'icon-refresh-orange' : 'icon-refresh'}`} />{' '}
          {t(`browse-content:questionCard.changeVal`, 'Change Value')}
        </a>
        <div
          onMouseEnter={() => setHoverMenu(true)}
          onMouseLeave={() => setTimeout(() => setHoverMenu(false), 300)}
          className={`${dropdownClassNames}${show ? ' show' : ''}`}
          data-cy="changevaluetooltip"
        >
          <ul className="list-qn">
            {questions.map((question, index) => (
              <li
                key={String(index)}
                className={getQuestionClassNames(question)}
              >
                <a
                  data-cy={`anchor${index}`}
                  href="#noredirect"
                  onClick={e => {
                    e.preventDefault();
                    dispatch(selectQuestion(question));
                    if (!isNil(onSelectedQuestion)) {
                      onSelectedQuestion(index);
                    }
                  }}
                >
                  Qn<span>{index + 1}</span>
                </a>
              </li>
            ))}
          </ul>
          <div>
            <i className="square-orange" />{' '}
            {t(
              `browse-content:questionCard.changeVal`,
              'Question has been assigned'
            )}
          </div>
        </div>
      </span>
    </>
  );
}
