import styled from 'styled-components';

export const DownloadCSVModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.875rem;
  margin-top: -50px;
  .success-title {
    font-size: 1.125rem;
    color: #000000;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .btn-success-ok {
    width: 127px;
    color: #fffdfc;
    background: #5ed46a;
    margin-bottom: 1rem;
  }
  .title {
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    color: #ff5b00;
    font-size: 1.25rem;
    .action-icon {
      margin-right: 1rem;
    }
  }
  .content-section {
    color: #000000;
    text-align: center;
    p {
      font-size: 16px;
    }
    .class-list {
      text-align: left;
      margin-left: 20px;
      margin-bottom: 10px;
      .form-check-input {
        margin-left: 0px;
      }
      .form-check-label {
        margin: 0px 30px;
      }
    }
  }
  .btn-section {
    display: flex;
    width: 500px;
    justify-content: space-evenly;
    margin-top: 20px;
    padding: 20px;
    border-top: solid 1px rgba(112, 112, 112, 0.22);
    .cancel-btn {
      color: #ff5b00;
      border: solid 1px #d5d5d5;
      width: 127px;
      background: #ffffff;
      &:hover {
        border-color: #ff5b00;
        background: #fff7f3;
      }
    }
    .download-btn {
      width: 127px;
      color: #fffdfc;
      background: #ff5b00;
      &:hover {
        background: #f25008;
      }
    }
    .download-btn:disabled {
      border: none;
    }
  }
`;
