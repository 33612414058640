import styled from 'styled-components';

export const StyledContainer = styled.div`
  margin-bottom: 2rem;
`;

export const StyledLink = styled.div`
  margin-top: 1rem;
  text-align: right;

  .more-link {
    color: #ff5b00;
    font-size: 16px;
    text-decoration: underline;
  }
`;

export const StyledCard = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 1rem;
  border: solid 1px #dfdfdf;
  color: #1e1e1e;
  padding: 0.5rem 1rem;
  box-shadow: 0 1px 3px 0px rgb(0 0 0 / 16%);
  .track-row {
    display: flex;
    align-items: center;
  }
  .hw-link {
    font-family: 'Muli', sans-serif;
    color: #000000;
    font-weight: 600;
    font-size: 15px;
    word-break: break-all;
  }
  .hw-type-icon {
    width: 31px;
    height: 31px;
    border-radius: 50%;
    padding: 0.5rem;
    font-family: Helvetica, sans-serif;
    font-size: 19px;
    font-weight: 100;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 0.7rem;

    img {
      width: 31px;
      height: 31px;
    }
  }
  .hw-assign-date {
    font-size: 12px;
    color: #8a8a8a;
    margin-right: 2rem;
  }
  .open-track-btn {
    color: #ff5b00;
    background-color: #ffffff;
    padding: 0.3rem 1.5rem;
    text-decoration: none;

    :hover {
      opacity: 0.8;
      text-decoration: underline;
    }
  }
`;
