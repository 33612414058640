import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedQuestions } from 'stores/worksheet/actions';
import { propEq, find, findIndex } from 'ramda';
import { nextStep } from 'stores/utils/step/actions';
import { Modal, Col } from 'react-bootstrap';
import PopupModal from 'components/Shared/PopupModal';
import iconTrash from 'assets/img/icons8-trash.svg';
import QuestionLoader from 'components/Loader/QuestionLoader';
import WorksheetQuestion from 'components/CreateAndAssign/QuestionCart/WorksheetQuestion';
import WorksheetQuestionModal from 'components/CreateAndAssign/QuestionCart/WorksheetQuestionModal';
import RemoveQuestionModal from 'components/CreateAndAssign/QuestionCart/RemoveQuestionModal';
import HeaderBox from 'components/CreateAndAssign/HeaderBox';
import NavigationWizard from 'components/CreateAndAssign/NavigationWizard';
import CheckAllCard from 'components/CreateAndAssign/CheckAllCard';
import ValidationMessage from 'components/CreateAndAssign/ValidationMessage';

export default function WorksheetQuestions() {
  const dispatch = useDispatch();
  const { cartQuestions, isLoading } = useSelector(state => state.questions);
  const { selectedQuestions } = useSelector(state => state.worksheet);

  // Boolean variable to monitor whether all questions are selected
  // Variable name prefixed with `is` so it is more meaningful
  // @see {@link https://www.samanthaming.com/tidbits/34-better-boolean-variable-names/}

  const isAllQuestionsSelected = cartQuestions
    .map(question => question.ID)
    .every(
      v => selectedQuestions.map(selected => selected.ID).indexOf(v) !== -1
    );

  const [show, setShow] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [showSolution, setShowSolution] = useState(false);
  const handleShowSolution = () => setShowSolution(true);
  const handleHideSolution = () => setShowSolution(false);

  const [questionPopup, setQuestionPopup] = useState({});
  const handleClose = () => {
    handleHideSolution();
    setShow(false);
  };

  const handleShow = async question => {
    setQuestionPopup(question);
    setShow(true);
  };

  const showConfirmation = () => {
    setConfirmation(true);
  };

  const closeConfirmation = () => {
    setConfirmation(false);
  };

  const findIn = Id => {
    const found = findIndex(propEq('ID', Id))(selectedQuestions);
    if (found !== -1) {
      return true;
    }
    return false;
  };

  const handleAllChecked = event => {
    const data = event.target.checked ? cartQuestions : [];
    dispatch(setSelectedQuestions(data));
  };

  const handleChecked = event => {
    const Id = parseInt(event.target.value, 10);
    const index = findIndex(propEq('ID', Id))(selectedQuestions);

    // Use new variable to avoid mutating state
    let updatedSelectedQuestions;

    if (event.target.checked) {
      const question = find(propEq('ID', Id))(cartQuestions);
      updatedSelectedQuestions = [...selectedQuestions, question];
    } else if (index !== -1) {
      updatedSelectedQuestions = [
        ...selectedQuestions.slice(0, index),
        ...selectedQuestions.slice(index + 1)
      ];
    }
    dispatch(setSelectedQuestions(updatedSelectedQuestions));
  };

  const handleNextStep = () => {
    if (selectedQuestions.length > 0) {
      dispatch(nextStep());
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} className="modal-qs">
        <WorksheetQuestionModal
          key={String(questionPopup.Id)}
          selectedQuestion={questionPopup}
          onCloseModal={handleClose}
          showSolution={showSolution}
        />
      </Modal>
      <PopupModal
        show={confirmation}
        hide={closeConfirmation}
        className="modal-qs"
      >
        <RemoveQuestionModal hide={closeConfirmation} type="remove-all" />
      </PopupModal>
      <HeaderBox>
        <Col md="auto">
          <CheckAllCard
            checked={isAllQuestionsSelected}
            onChange={handleAllChecked}
          />
        </Col>
        <Col md="auto" style={{ display: 'flex' }}>
          {selectedQuestions.length > 50 && <ValidationMessage />}
          <button
            type="button"
            className="btn remove-question-all-btn"
            onClick={showConfirmation}
            style={{ paddingRight: '0px', marginLeft: 0 }}
          >
            <img
              alt="Trash"
              src={iconTrash}
              className="trash-icon-all"
              title="Remove All"
            />
          </button>
          <NavigationWizard
            showtotalQuestions
            totalQuestions={selectedQuestions.length}
            onClick={handleNextStep}
          />
        </Col>
      </HeaderBox>
      <div className="box-white-wrap question-cart-list">
        <div className="row">
          {isLoading ? (
            <QuestionLoader />
          ) : (
            cartQuestions.map(question => {
              return (
                <WorksheetQuestion
                  isSelectable
                  isChecked={findIn(question.ID)}
                  onChange={handleChecked}
                  key={String(question.ID)}
                  selectedQuestion={question}
                  onShowModal={() => handleShow(question)}
                  onShowSolution={handleShowSolution}
                />
              );
            })
          )}
        </div>
      </div>
    </>
  );
}
