import styled from 'styled-components';

export const LoginButton = styled.div`
  width: 100%;
  .custom-button,
  .login-button {
    background: #ffae0c;
    color: #fff;
    font-size: calc(20 / 1366 * 100vw);
    font-family: 'Linotte Semibold', -apple-system, BlinkMacSystemFont,
      'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    border: none;
    width: 100%;
    padding: 0.5rem 2rem;
    border-radius: 2rem;
    -webkit-box-shadow: 0px 9px 0px 0px #e39f16;
    -moz-box-shadow: 0px 9px 0px 0px #e39f16;
    box-shadow: 0px 9px 0px 0px #e39f16;
    margin-bottom: 1rem;
    cursor: pointer;
    @media only screen and (max-width: 1024px) {
      font-size: calc(20 / 1024 * 100vw);
    }
  }

  .custom-button:hover,
  .login-button:hover {
    background: #ff9e0c;
    box-shadow: 0px 9px 0px 0px #e39116;
    -webkit-box-shadow: 0px 9px 0px 0px #e39116;
    -moz-box-shadow: 0px 9px 0px 0px #e39116;
  }

  @media only screen and (max-width: 480px) {
    .custom-button,
    .login-button {
      width: 90%;
      font-size: 24px;
      position: relative;
      top: -4rem;
    }
    .login-button {
      position: static;
      align-self: center;
    }
  }
`;
