import styled from 'styled-components';

export const ReportPage = styled.div`
  padding-left: 195px;
  text-align: center;
  .error {
    width: 100%;
    text-align: center;
    margin-top: 2rem;
  }
  @media (max-width: 1024px) {
    padding-left: 55px;
  }
  @media (max-width: 639px) {
    padding-left: 0px;
  }
`;
export const LoadingContainer = styled.div`
  position: relative;
  top: 250px;
  width: ${({ width }) => width || '100%'};
`;
