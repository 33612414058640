import { call, put } from 'redux-saga/effects';
import { isNil } from 'ramda';
import {
  fetchUserCurriculumsRequest,
  fetchUserCurriculumsSuccess,
  fetchUserCurriculumsFailed
} from 'stores/curriculums/actions';
import { setSyllabus } from 'stores/report/actions';
import GetCurriculumForCurrentUser from 'services/curriculums';
import { compareValues } from 'helpers/singleClassReport';

// Helper to set default curriculum
const setDefaultCurriculum = curriculumsArray => {
  const sortedCurriculums = curriculumsArray.sort(
    compareValues('CurriculumName', 'asc')
  );
  const feature6DefaultCurriculum = sortedCurriculums.find(
    curr => curr.Feature === 6
  );
  if (feature6DefaultCurriculum) {
    return feature6DefaultCurriculum;
  }
  const defaultCurriculumIndex = sortedCurriculums.findIndex(
    curr => curr.IsDefault === true
  );
  if (defaultCurriculumIndex !== -1) {
    return sortedCurriculums[defaultCurriculumIndex];
  }
  return sortedCurriculums[0];
};

/**
 * The saga to get user curriculum
 */
function* getUserCurriculums() {
  yield put(fetchUserCurriculumsRequest());
  const data = yield call(GetCurriculumForCurrentUser);
  if (!isNil(data)) {
    if (!isNil(data.error)) {
      // if the API return error message
      yield put(fetchUserCurriculumsFailed(data.error));
    } else {
      yield put(fetchUserCurriculumsSuccess(data));
      yield put(setSyllabus(setDefaultCurriculum(data)));
    }
  }
}

export default getUserCurriculums;
