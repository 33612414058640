import { call, put, select } from 'redux-saga/effects';
import { isNil, pluck } from 'ramda';
import privateGroupService from 'services/privateGroup';
import {
  fetchPrivateGroupsSuccess,
  fetchPrivateGroupsFailed,
  fetchPrivateGroupSuccess,
  fetchPrivateGroupFailed
} from 'stores/privateGroup/actions';
import { fetchStudentsSuccess } from 'stores/utils/lookup/actions';

/**
 * The saga for add question to the Cart
 */
export function* savePrivateGroup({ name }) {
  const { selectedStudents } = yield select(state => state.privateGroup);

  const kids = pluck('UserId', selectedStudents);

  const params = {
    // id: !isNil(selectedPrivateGroup.ID) ? selectedPrivateGroup.ID : 0,
    name,
    kids
  };

  const data = yield call(privateGroupService.savePrivateGroup, params);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchPrivateGroupSuccess(data));
      yield put(fetchStudentsSuccess([]));
    } else {
      yield put(fetchPrivateGroupFailed(data.error));
    }
  }
}

/**
 * The saga for fetch private group
 */
export function* fetchPrivateGroups({ groupName }) {
  const data = yield call(privateGroupService.getPrivateGroups, groupName);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchPrivateGroupsSuccess(data));
    } else {
      yield put(fetchPrivateGroupsFailed(data.error));
    }
  }
}
