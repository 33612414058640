import React from 'react';
import { CPContainer, RealTimeContainer } from './CPSection.styles';
import Mascot from './mascot.svg';

const CPErrorFallback = () => {
  return (
    <CPContainer>
      <RealTimeContainer>
        <p className="title">Latest CP Submitted</p>
      </RealTimeContainer>
      <img className="mascot" src={Mascot} alt="Welcome to KooBits" />
    </CPContainer>
  );
};

export default CPErrorFallback;
