import React from 'react';
import ReactSvgPieChart from 'react-svg-piechart';

export default function ScorePieChart({ submissions }) {
  const data = [];
  if (submissions.correct > 0) {
    data.push({
      title: 'Correct',
      value: submissions.correct,
      color: '#55C92B'
    });
  }
  if (submissions.wrong > 0) {
    data.push({
      title: 'Wrong',
      value: submissions.wrong,
      color: '#FF5E5E'
    });
  }
  if (submissions.notSubmitted > 0) {
    data.push({
      title: 'Not Submitted',
      value: submissions.notSubmitted,
      color: '#dedede'
    });
  }
  // Check if data array has only 1 item
  if (data.length === 1) {
    data.push(data[0]);
  }
  return (
    <div style={{ width: '24px' }}>
      <ReactSvgPieChart
        data={data}
        startAngle={270}
        strokeWidth={0}
        viewBoxSize={1}
      />
    </div>
  );
}
