import { call, put, select } from 'redux-saga/effects';
import { isNil, reject, merge } from 'ramda';
import WorksheetService from 'services/worksheet';
import CartService from 'services/cart';
import {
  fetchWorksheetsSuccess,
  fetchWorksheetsFailed,
  selectWorksheetSuccess,
  selectWorksheetFailed,
  setSelectedQuestions,
  fetchAllWorksheetsSuccess,
  fetchAllWorksheetsFailed,
  setEditSavedWorksheetQuestions,
  deleteWorksheetSuccess,
  deleteWorksheetFailed
} from 'stores/worksheet/actions';
import { fetchCartQuestionsSuccess } from 'stores/questions/actions';
import {
  setKokoCredit,
  setStartEndDate,
  setReleaseDate
} from 'stores/worksheet/schedule/actions';
import {
  setKokoCredit as setAssignKokoCredit,
  setStartEndDate as setAssignStartEndDate,
  setReleaseDate as setAssignReleaseDate
} from 'stores/worksheet/assign/actions';
import hasProp from 'helpers/hasProp';
import moment from 'moment-timezone';
import { assignmentTypes } from 'constants/index';

/**
 * The saga for add question to the Cart
 */
export function* saveWorksheet({ worksheet }) {
  const {
    selectedWorksheet,
    selectedQuestions,
    editSavedWorksheetQuestions
  } = yield select(state => state.worksheet);
  const { createWorksheetItems } = yield select(
    state => state.topicalWorksheet
  );

  const { createFactualFluencyItems } = yield select(
    state => state.factualFluency
  );

  const {
    NumberOfKokoCredits,
    Title,
    Instructions,
    WorksheetStatus,
    AssignmentTypeID,
    PresentationTypeID,
    TimeLimit,
    isEditSavedWorksheet,
    isCreateHomework,
    isCreateFactualFluency
  } = worksheet;

  let Questions;
  if (isEditSavedWorksheet === true) {
    Questions = editSavedWorksheetQuestions.map(question => {
      return {
        ID: question.ID,
        TopicId: question.TopicId
      };
    });
  } else if (isCreateHomework === true) {
    Questions = createWorksheetItems.map(question => {
      return {
        ID: question.ID,
        TopicId: question.TopicId
      };
    });
  } else if (isCreateFactualFluency === true) {
    Questions = createFactualFluencyItems.map(question => {
      return {
        ID: question.ID,
        TopicId: question.TopicId
      };
    });
  } else {
    Questions = selectedQuestions.map(question => {
      return {
        ID: question.ID,
        TopicId: question.TopicId
      };
    });
  }

  let defaultParams = {};

  if (!isNil(selectedWorksheet.ID)) {
    defaultParams = {
      ID: selectedWorksheet.ID,
      Questions,
      NumberOfKokoCredits: selectedWorksheet.NumberOfKokoCredits,
      Title: selectedWorksheet.Title,
      Instructions: selectedWorksheet.Instructions,
      WorksheetStatus: selectedWorksheet.WorksheetStatus,
      PresentationType: {
        AssignmentTypeID: selectedWorksheet.AssignmentTypeID,
        PresentationTypeID: selectedWorksheet.PresentationTypeID,
        PresentationParameters: selectedWorksheet.PresentationParameters,
        TimeLimit: selectedWorksheet.TimeLimit
      }
    };
  }

  let NewParams = {
    Questions,
    NumberOfKokoCredits,
    Title,
    Instructions,
    WorksheetStatus
  };

  let NewPresentationType = {
    AssignmentTypeID,
    PresentationTypeID,
    TimeLimit
  };

  NewPresentationType = reject(isNil())(NewPresentationType);
  NewParams.PresentationType = NewPresentationType;
  NewParams = reject(isNil())(NewParams);

  const Params = merge(defaultParams, NewParams);

  const isEdit = hasProp(Params, 'ID');

  const data = yield call(WorksheetService.saveWorksheet, Params, isEdit);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(selectWorksheetSuccess(data));
    } else {
      yield put(selectWorksheetFailed(data.error));
    }
  }
}

/**
 * The saga to assign worksheet to classes / groups / students
 */
export function* assignWorksheet({ data, isPublish }) {
  // First save the worksheet (in blocking request) before assigning

  if (isPublish) {
    const saveParams = {
      Title: data.Title,
      Instructions: data.Title,
      WorksheetStatus: data.WorksheetStatus,
      PresentationTypeID: data.PresentationTypeID,
      AssignmentTypeID: data.AssignmentTypeID,
      TimeLimit: data.TimeLimit,
      NumberOfKokoCredits: data.NumberOfKokoCredits,
      isCreateHomework: data.isCreateHomework,
      isCreateFactualFluency: data.isCreateFactualFluency,
      isEditSavedWorksheet: data.isEditSavedWorksheet
    };
    // Save worksheet
    yield call(saveWorksheet, { worksheet: saveParams });
  }

  const { selectedWorksheet } = yield select(state => state.worksheet);
  if (hasProp(selectedWorksheet, 'ID')) {
    const assignParams = {
      startDate: data.startDate,
      endDate: data.endDate,
      assignments: data.assignments,
      homeWorkID: selectedWorksheet.ID,
      resultDate: data.resultDate
    };
    const response = yield call(
      WorksheetService.assignWorksheet,
      assignParams,
      isPublish
    );

    if (!isNil(response)) {
      if (isNil(response.error)) {
        // Backend duplicates the worksheet but returns a different ID
        // Replace the ID here for generation of worksheet sharing URL
        const worksheet = response.model;
        worksheet.ID = response.DuplicatedHWID;
        yield put(selectWorksheetSuccess(worksheet));
      } else {
        yield put(selectWorksheetFailed(data.error));
      }
    }
  } else {
    const saveWorkSheetError = yield select(state => state.worksheet.error);
    yield put(
      selectWorksheetFailed(saveWorkSheetError ?? 'Assign Worksheet Failed')
    );
  }
}

/**
 * The saga for fetch all
 */
export function* fetchAllWorksheets() {
  const data = yield call(WorksheetService.getMyWorksheets);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchAllWorksheetsSuccess(data));
    } else {
      yield put(fetchAllWorksheetsFailed(data.error));
    }
  }
}

/**
 * The saga for fetch worksheet pagination
 */
export function* fetchWorksheets({ page, limit }) {
  const data = yield call(WorksheetService.getSavedWorksheets, page, limit);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchWorksheetsSuccess(data));
    } else {
      yield put(fetchWorksheetsFailed(data.error));
    }
  }
}

/**
 * The saga for select question
 */
// assignmentType
// 1 - Schedule
// 2 - Assign Now
export default function* getWorksheetDetail({
  selectedWorksheet,
  withCart,
  savedWorksheet,
  assignmentType
}) {
  const data = yield call(
    WorksheetService.getMyWorksheetById,
    selectedWorksheet.ID
  );
  if (!isNil(data)) {
    if (isNil(data.error)) {
      if (assignmentType === 1) {
        const kokoCredits = !isNil(data.NumberOfKokoCredits)
          ? data.NumberOfKokoCredits
          : 5;
        yield put(setKokoCredit(kokoCredits));
        yield put(
          setStartEndDate(
            moment()
              .startOf('day')
              .format('YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ'),
            moment()
              .add(3, 'day')
              .set({ hour: 23, minute: 59 })
              .format('YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ')
          )
        );
        const releaseDate =
          data.PresentationType.AssignmentTypeID === assignmentTypes.test
            ? moment()
                .add(4, 'day')
                .startOf('day')
                .format('YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ')
            : '';
        yield put(setReleaseDate(releaseDate));
      } else if (assignmentType === 2) {
        const kokoCredits = !isNil(data.NumberOfKokoCredits)
          ? data.NumberOfKokoCredits
          : 5;
        yield put(setAssignKokoCredit(kokoCredits));
        yield put(
          setAssignStartEndDate(
            moment()
              .startOf('day')
              .format('YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ'),
            moment()
              .add(3, 'day')
              .set({ hour: 23, minute: 59 })
              .format('YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ')
          )
        );
        const releaseDate =
          data.PresentationType.AssignmentTypeID === assignmentTypes.test
            ? moment()
                .add(4, 'day')
                .startOf('day')
                .format('YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ')
            : '';
        yield put(setAssignReleaseDate(releaseDate));
      }
      if (withCart) {
        const QuestionIds = data.Questions.map(question => {
          return question.ID;
        });
        yield call(CartService.addQuestionsToCart, QuestionIds);
        yield put(fetchCartQuestionsSuccess(data.Questions));
        yield put(setSelectedQuestions(data.Questions));
      }
      if (savedWorksheet) {
        yield put(setEditSavedWorksheetQuestions(data.Questions));
      }
      yield put(selectWorksheetSuccess(data));
    } else {
      yield put(selectWorksheetFailed(data.error));
    }
  }
}

/**
 * The saga to assign worksheet to classes / groups / students
 */
export function* scheduledWorksheet({ data }) {
  const { selectedWorksheet } = yield select(state => state.worksheet);

  const scheduleParams = {
    assignmentVisibility: data.assignmentVisibility,
    rewards: data.rewards,
    startDate: data.startDate,
    endDate: data.endDate,
    assignments: data.assignments,
    homeWorkID: data.homeWorkID,
    resultDate: data.resultDate
  };
  const response = yield call(
    WorksheetService.scheduledWorksheet,
    scheduleParams
  );

  if (!isNil(response)) {
    if (isNil(response.error)) {
      // Backend duplicates the worksheet but returns a different ID
      // Replace the ID here for generation of worksheet sharing URL
      selectedWorksheet.ID = response.DuplicatedHWID;
      yield put(selectWorksheetSuccess(selectedWorksheet));
    } else {
      yield put(selectWorksheetFailed(data.error));
    }
  }
}

export function* deleteWorksheet({ worksheetId }) {
  const data = yield call(WorksheetService.deleteWorksheet, worksheetId);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(deleteWorksheetSuccess());
    } else {
      yield put(deleteWorksheetFailed());
    }
  }
}

export function* getWorksheet({ worksheetId }) {
  const data = yield call(WorksheetService.getMyWorksheetById, worksheetId);
  if (!isNil(data)) {
    yield put(selectWorksheetSuccess(data));
  } else {
    yield put(selectWorksheetFailed(data.error));
  }
}
