import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { find, findIndex, propEq } from 'ramda';
import { selectStudents } from 'stores/privateGroup/actions';
import { fetchStudentsSearchRequest } from 'stores/utils/lookup/actions';
import { StudentsListContainer } from './StudentsList.styles';

export default function StudentList(props) {
  const { t } = useTranslation(['manage-class-private-group']);
  const { keyword } = props;
  const { isLoading, students } = useSelector(state => state.lookup);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);

  const { selectedStudents } = useSelector(state => state.privateGroup);
  const checkChecked = id => {
    return (
      findIndex(propEq('UserId', parseInt(id, 10)))(selectedStudents) !== -1
    );
  };
  const onChange = event => {
    const { value } = event.target;
    // Use new variable to avoid mutating state
    let updatedData;

    if (!checkChecked(value)) {
      const newData = find(propEq('UserId', parseInt(value, 10)))(students);

      updatedData = [...selectedStudents, newData];
    } else {
      const index = findIndex(propEq('UserId', parseInt(value, 10)))(
        selectedStudents
      );
      updatedData = [
        ...selectedStudents.slice(0, index),
        ...selectedStudents.slice(index + 1)
      ];
    }

    dispatch(selectStudents(updatedData));
  };

  const isAllQuestionsSelected = students
    .map(student => student.UserId)
    .every(
      v => selectedStudents.map(selected => selected.UserId).indexOf(v) !== -1
    );

  const toggleCheckAll = event => {
    let updatedData;
    if (event.target.checked) {
      updatedData = [...selectedStudents, ...students];
    } else {
      const studentIds = students.map(student => student.UserId);
      updatedData = selectedStudents.filter(
        student => studentIds.indexOf(student.UserId) === -1
      );
    }
    dispatch(selectStudents(updatedData));
  };

  // Reset page number when search term changes
  useEffect(() => {
    setPage(0);
  }, [keyword]);

  const onPrev = () => {
    const prev = page - 1;
    dispatch(fetchStudentsSearchRequest(keyword, prev));
    setPage(prev);
  };

  const onNext = () => {
    const next = page + 1;
    dispatch(fetchStudentsSearchRequest(keyword, next));
    setPage(next);
  };
  const dontHaveData = page === 0 && students.length === 0;
  return (
    <StudentsListContainer>
      {isLoading && (
        <Spinner animation="border">
          <span className="sr-only">
            {t('manage-class-private-group:add.loading', 'Loading...')}
          </span>
        </Spinner>
      )}
      {students.length > 0 ? (
        <>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">
                    {t(
                      'manage-class-private-group:add.table.username',
                      'Username'
                    )}
                  </th>
                  <th scope="col">
                    {t('manage-class-private-group:add.table.name', 'Name')}
                  </th>
                  <th scope="col">
                    {t('manage-class-private-group:add.table.school', 'School')}
                  </th>
                  <th scope="col">
                    {t('manage-class-private-group:add.table.level', 'Level')}
                  </th>
                  <th scope="col">
                    {t('manage-class-private-group:add.table.class', 'Class')}
                  </th>
                  <th scope="col">
                    <Form.Check
                      checked={isAllQuestionsSelected}
                      type="checkbox"
                      onChange={toggleCheckAll}
                      label={t(
                        'manage-class-private-group:add.table.addAll',
                        'Add all?'
                      )}
                      bsPrefix="form-check-visible"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {students.map(student => (
                  <tr key={student.UserId}>
                    <td>{student.UserName}</td>
                    <td>{student.DisplayName}</td>
                    <td>{student.SchoolName}</td>
                    <td>{student.LevelId}</td>
                    <td>{student.ClassName}</td>
                    <td>
                      <Form.Check
                        checked={checkChecked(student.UserId)}
                        onChange={onChange}
                        type="checkbox"
                        value={student.UserId}
                        bsPrefix="form-check-visible"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center p-5">
          {dontHaveData && keyword === '' ? (
            <h4>
              {t(
                'manage-class-private-group:add.studentSearch',
                'Please do a Students Search'
              )}
            </h4>
          ) : (
            <h4>
              {t(
                'manage-class-private-group:add.notFound',
                'Student Not found'
              )}
            </h4>
          )}
        </div>
      )}
      {!dontHaveData && (
        <nav aria-label="Page navigation example">
          <ul className="pagination table-pagination">
            <li className="page-item pagination-prev">
              <button
                type="button"
                className="page-link"
                disabled={page === 0}
                onClick={onPrev}
              >
                {t('manage-class-private-group:add.prev', 'Previous')}
              </button>
            </li>
            <li className="page-item pagination-next">
              <button type="button" className="page-link" onClick={onNext}>
                {t('manage-class-private-group:add.next', 'Next')}
              </button>
            </li>
          </ul>
        </nav>
      )}
    </StudentsListContainer>
  );
}
