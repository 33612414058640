import styled from 'styled-components';

export const ActionButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  position: fixed;
  margin-top: 85px;
  z-index: 99;
  background-color: white;
  right: 50px;
  left: 245px;
  @media only screen and (max-width: 1024px) {
    right: 80px;
    left: 130px;
  }
  .buttons {
    grid-column: 1/17;
    margin: 10px 10px;
    @media only screen and (max-width: 768px) {
      margin: 8px 5px;
    }
    @media only screen and (max-width: 587px) {
      margin: 5px 0px;
    }
    button {
      font-size: 11px;
      img {
        margin-right: 6px;
        @media only screen and (max-width: 768px) {
          margin-right: 5px;
          width: 15px;
          height: 15px;
        }
        @media only screen and (max-width: 768px) {
          margin-right: 3px;
          width: 10px;
          height: 10px;
        }
      }
      &:hover {
        color: #e84b05;
      }
      @media only screen and (max-width: 768px) {
        font-size: 8px;
        margin-right: 3px;
      }
    }
  }
`;
