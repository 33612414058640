import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import sortClasses from 'helpers/parseClassList';
import { compareValues } from 'helpers/singleClassReport';
import ClassCard from './ClassCard';
import { ClassCollection } from './ClassCardCollection.styles';

const ClassCardCollection = ({ showModal, setSelectedClass, filterLevel }) => {
  const { t } = useTranslation(['manage-class']);
  const { classes } = useSelector(state => state.manageClasses);

  const sortData = classes.hasOwnProperty('ClassList')
    ? sortClasses(classes.ClassList).sort(compareValues('level', 'asc'))
    : [];

  const filteredData =
    filterLevel === parseInt(filterLevel, 10)
      ? sortData.filter(levelObject => levelObject.level === filterLevel)
      : sortData;

  const cardList = filteredData.map(item => (
    <>
      <ClassCollection>
        <div className="class-list__level">
          <h5>
            {t('manage-class:main.primary', 'Primary')} {item.level}
          </h5>
        </div>
        <div className="class-list__container">
          {item.classes.map(singleCard => (
            <ClassCard
              showModal={showModal}
              card={singleCard}
              setSelectedClass={setSelectedClass}
            />
          ))}
        </div>
      </ClassCollection>
    </>
  ));

  return <div>{cardList}</div>;
};

export default ClassCardCollection;
