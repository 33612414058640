import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { toggleReport } from 'stores/report/actions';
import { ToggleReportStyle, ReportButton } from './ToggleReport.style';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const ToggleReport = ({ studentID }) => {
  const { t } = useTranslation(['proficiency-report']);
  const { activeToggle } = useSelector(state => state.reports);
  const dispatch = useDispatch();
  const history = useHistory();

  const onToggle = val => {
    dispatch(toggleReport(val));
    if (val === 'high') {
      history.push(`/report/class-report/high-score-report/${studentID}`);
    } else {
      history.push(`/report/proficiency-report/${studentID}`);
    }
  };

  return (
    <ToggleReportStyle>
      <ReportButton
        proficiency
        active={activeToggle === 'proficiency'}
        onClick={() => onToggle('proficiency')}
      >
        {t(
          `proficiency-report:sidebar.proficiencyReport`,
          'Proficiency Report'
        )}
      </ReportButton>
      <ReportButton
        high
        active={activeToggle === 'high'}
        onClick={() => onToggle('high')}
      >
        {t(`proficiency-report:sidebar.highScoreReport`, 'High Score Report')}
      </ReportButton>
    </ToggleReportStyle>
  );
};

export default memo(ToggleReport);
