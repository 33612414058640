import styled from 'styled-components';

export const EditPanelStyle = styled.div`
  right: -44px;
  top: 0;
  text-align: center;
  flex-direction: column !important;
  position: absolute !important;
  .question-action__item {
    display: block;
    width: 39px;
    height: 36px;
    padding: 11px;
    background-color: #ffffff;
    color: #545454;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 2px;
    background-repeat: no-repeat;
    background-size: 17px auto;
    background-position: center center;
    position: relative;

    &:hover {
      background-color: #fff6ee;
      cursor: pointer;

      &::after {
        font-family: 'Muli', sans-serif;
        font-size: 13px;
        line-height: 16px;
        display: block;
        position: absolute;
        top: 5px;
        background-color: #6b6b6b;
        border-radius: 4px;
        padding: 5px;
        color: #fff;
        white-space: nowrap;
        right: 45px;
      }
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;

export const MenuSection = styled.button`
  padding-left: 0;
  padding-right: 0;
  background-size: 30px auto!important;
  background-image: ${props => `url(${props.icon})`};

  &:hover {
    background-image: ${props => `url(${props.activeIcon})`};

    &:after {
      content: "${props => props.content}";
    }
  }
`;

export const MenuUp = styled.button`
  background-image: ${props => `url(${props.icon})`};

    &:hover {
        background-image: ${props => `url(${props.activeIcon})`};

    &:after {
        content: "${props => props.content}";
    }
    }
`;

export const MenuEnd = styled.button`
  background-image: ${props => `url(${props.icon})`};

  &:hover {
    background-image: ${props => `url(${props.activeIcon})`};

    &::after {
      content: "${props => props.content}";
    }
  }
`;

export const MenuDown = styled.button`
  background-image: ${props => `url(${props.icon})`};

  &:hover {
    background-image: ${props => `url(${props.activeIcon})`};

    &::after {
      content: "${props => props.content}";
    }
  }
`;

export const MenuDownEnd = styled.button`
  background-image: ${props => `url(${props.icon})`};

  &:hover {
    background-image: ${props => `url(${props.activeIcon})`};

    &::after {
      content: "${props => props.content}";
    }
  }
`;
