import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { homeworkLink } from 'constants/index';
import AlertModal from './AlertModal';

const InfoLinkScheduledModal = props => {
  const { t } = useTranslation(['create-assign-question']);
  const { isShow, onConfirm, onClose } = props;
  const { selectedWorksheet } = useSelector(state => state.worksheet);
  const buttons = (
    <>
      <button type="button" className="btn btn-primary" onClick={onConfirm}>
        {t(`create-assign-question:saveAssignModal.ok`, 'OK')}
      </button>
    </>
  );
  return (
    <AlertModal
      title={t(
        `create-assign-question:saveAssignModal.hwSceduled`,
        'Homework Scheduled Succesfully!'
      )}
      isShow={isShow}
      handleClose={onClose}
      buttons={buttons}
    >
      <div className="custom-popups__assign-final">
        <p>
          {t(
            `create-assign-question:saveAssignModal.reimndMsg`,
            'You can remind your student to complete this worksheet by sharing the link via email, forum, LMS, or simply instruct the students to login to KooBits and look for it at the homework section.'
          )}
        </p>
        <input
          type="text"
          className="custom-popups__input"
          value={`${homeworkLink}${selectedWorksheet.ID}`}
          readOnly
        />
        <p>
          {t(
            `create-assign-question:saveAssignModal.impNote`,
            'important note:'
          )}
          <br />
          {t(
            `create-assign-question:saveAssignModal.authorizeStud`,
            'only authorized students will be able to access the home work link.'
          )}
        </p>
      </div>
    </AlertModal>
  );
};

export default InfoLinkScheduledModal;
