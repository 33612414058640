import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import SidebarCreateAssign from 'components/Sidebar/SidebarCreateAssign';
import Sidebar from 'components/Sidebar/Sidebar';
import QuestionCart from 'pages/CreateAndAssign/QuestionCart';
import SavedWorksheet from 'pages/CreateAndAssign/SavedWorksheet';
import TopicalWorksheet from 'pages/CreateAndAssign/TopicalWorksheet/TopicalWorksheet';
import FactualFluency from 'pages/CreateAndAssign/FactualFluency/FactualFluency';
import Overlay from 'components/Content/Overlay';
import { useDispatch } from 'react-redux';
import { startStep } from 'stores/utils/step/actions';
import ImportQuestionList from 'pages/CreateAndAssign/ImportQuestionList';
import Header from 'components/Header/Header';
import ScheduleView from './Schedule/ScheduleView';
import AssignView from './Assign/AssignView';
import EditView from './Edit/EditView';
import CreateHomework from './CreateHomework/CreateHomework';
import CreateFactualFluency from './CreateFactualFluency/CreateFactualFluency';

export default function CreateAndAssign() {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const toggleNavigation = () => setExpanded(!expanded);
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(startStep());
  }, [dispatch, pathname]);

  const getContentClassName = () =>
    `content content-sidebar-small${
      pathname === '/create-and-assign'
        ? ' content--centered content__inner-question-cart'
        : ''
    }`;

  return (
    <>
      <Header />
      <main>
        <Sidebar
          expanded={expanded}
          toggleNavigation={toggleNavigation}
          className="sidebar--auto"
        >
          <SidebarCreateAssign />
        </Sidebar>
        <div className={getContentClassName()}>
          <Overlay isVisible={expanded} toggleVisible={toggleNavigation} />
          <Switch>
            <Route
              exact
              path="/create-and-assign"
              component={() => <QuestionCart />}
            />
            <Route
              path="/create-and-assign/topic-revision"
              component={() => <span>Topic Revision</span>}
            />
            <Route
              exact
              path="/create-and-assign/saved-worksheet"
              component={() => <SavedWorksheet />}
            />
            <Route
              path="/create-and-assign/import-questions"
              component={() => <ImportQuestionList />}
            />
            <Route
              path="/create-and-assign/saved-worksheet/schedule"
              component={() => <ScheduleView />}
            />
            <Route
              path="/create-and-assign/saved-worksheet/assign-now"
              component={() => <AssignView />}
            />
            <Route
              path="/create-and-assign/saved-worksheet/edit"
              component={() => <EditView />}
            />
            <Route
              exact
              path="/create-and-assign/topical-worksheet"
              component={() => <TopicalWorksheet />}
            />
            <Route
              path="/create-and-assign/topical-worksheet/create"
              component={() => <CreateHomework />}
            />
            <Route
              exact
              path="/create-and-assign/factual-fluency"
              component={() => <FactualFluency />}
            />
            <Route
              exact
              path="/create-and-assign/factual-fluency/:method"
              component={props => <CreateFactualFluency {...props} />}
            />
          </Switch>
        </div>
      </main>
    </>
  );
}
