import React from 'react';
import { useSelector } from 'react-redux';
import HeaderBox from 'components/CreateAndAssign/HeaderBox';
import WorksheetAssignTitle from 'components/CreateAndAssign/QuestionCart/WorksheetAssignTitle';
import NewBorder from 'components/Shared/NewBorder';
import QuestionHeader from 'components/Question/QuestionHeader';
import QuestionContent from 'components/Question/QuestionContent';
import AssignSidebar from 'components/CreateAndAssign/AssignSidebar';
// import printImage from 'assets/img/print-icon.svg';

export default function WorksheetAssign() {
  const { selectedQuestions, title } = useSelector(state => state.worksheet);

  return (
    <>
      <div className="assign-page">
        <HeaderBox>
          <WorksheetAssignTitle
            title={title}
            totalQuestions={selectedQuestions.length}
          />
          {/* <button
            type="button"
            className="assign-print-btn btn btn-outline-secondary"
            onClick={() => window.print()}
          >
            <img alt="" src={printImage} />
            Print
          </button> */}
        </HeaderBox>
        <div className="box-white p-3 assign-content">
          {/* <InfoSection
            title="Instruction"
            description="Follow All Instuction Carefully. Answer All Question."
          /> */}
          {/* <Border />
          <InfoSection
            title="Section A"
            description="Question From 1 to 5 carry to marks each."
          /> */}
          <NewBorder />
          {selectedQuestions.map((question, key) => {
            const order = key + 1;
            return (
              <div key={`question-${order}`}>
                <QuestionHeader order={order} score={2} />
                <NewBorder styles={{ marginBottom: '20px' }} />
                <QuestionContent question={question.CartQuestion} />
                <NewBorder />
              </div>
            );
          })}
        </div>
      </div>
      <AssignSidebar />
    </>
  );
}
