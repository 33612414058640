import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import SavedWorksheetList from 'components/CreateAndAssign/SavedWorksheet/SavedWorksheetList';
import './SavedWorksheet.styles.scss';
import savedWorksheetIcon from 'assets/img/icon-saved-worksheet-grey.svg';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'ramda';
import {
  fetchWorksheetsRequest,
  deleteWorksheetReset
  // resetSelectedWorksheet
} from 'stores/worksheet/actions';
import EmptySavedWorksheet from 'components/CreateAndAssign/EmptySavedWorksheet';
import Pagination from 'components/Shared/Pagination';
import Spinner from 'components/Shared/Spinner';

export default function SavedWorksheet() {
  const { t } = useTranslation(['create-assign-saved']);
  const {
    isLoading,
    worksheets,
    deleteWorkSheet,
    worksheetSearchPage
  } = useSelector(state => state.worksheet);
  // Variables and setup
  const initialPage = worksheetSearchPage || 1;
  const perPage = 5;
  const dispatch = useDispatch();
  const [page, setPage] = useState(initialPage);

  // Search page logic
  const [searchPage, setSearchPage] = useState(initialPage);
  const [debouncedSearchPage] = useDebounce(searchPage, 500);
  const totalWorksheets = !isNil(worksheets.TotalCount)
    ? worksheets.TotalCount
    : 0;
  const maxPage = Math.ceil(totalWorksheets / perPage);
  const onSearchPageChange = pageNumber => {
    if (pageNumber <= maxPage) {
      setSearchPage(pageNumber);
    } else {
      setSearchPage(page);
    }
  };

  const totalData = !isNil(worksheets.List) ? worksheets.List.length : 0;

  useEffect(() => {
    if (debouncedSearchPage) {
      dispatch(fetchWorksheetsRequest(debouncedSearchPage, perPage));
      setPage(parseInt(debouncedSearchPage, 10));
    }
  }, [debouncedSearchPage, dispatch]);

  useEffect(() => {
    if (deleteWorkSheet) {
      let pageNo = debouncedSearchPage;
      if (totalData === 1 && debouncedSearchPage !== 1) {
        pageNo -= 1;
        setSearchPage(pageNo);
      }
      dispatch(fetchWorksheetsRequest(pageNo, perPage));
      setPage(parseInt(pageNo, 10));
      dispatch(deleteWorksheetReset());
    }
  }, [debouncedSearchPage, deleteWorkSheet, dispatch, totalData]);

  const isInitialLoading = isLoading && isNil(worksheets.List);
  if (isInitialLoading) {
    return (
      <div className="content__inner content__inner-create-assign content__inner-saved-worksheet">
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Spinner />
        </div>
      </div>
    );
  }

  // Callback for page change
  const onPageChange = number => {
    // dispatch(fetchWorksheetsRequest(number, perPage));
    setPage(number);
    setSearchPage(number);
  };

  // Callback for search page change

  return totalData > 0 ? (
    <div className="content__inner content__inner-create-assign content__inner-saved-worksheet">
      <div className="row saved-worksheet__header">
        <div className="col-sm-12">
          <img
            src={savedWorksheetIcon}
            alt="icon-list"
            className="saved-worksheet__icon"
          />
          <h5>{t(`create-assign-saved:main.savedWo`, 'Saved Worksheet')}</h5>
        </div>
      </div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center p-5">
          <Spinner animation="border">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <SavedWorksheetList />
          <Pagination
            maxPage={maxPage}
            onPageChange={onPageChange}
            onSearchPageChange={onSearchPageChange}
            page={page}
            searchPage={searchPage}
          />
        </>
      )}
    </div>
  ) : (
    <EmptySavedWorksheet />
  );
}
