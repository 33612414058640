import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { isNil } from 'ramda';
import convertTagsToHTML from 'helpers/text/convertTagsToHTML';
import HTMLReactParser from 'html-react-parser';
import questionParser from 'helpers/questionParser';
import { McqOptionsList } from 'components/Question/QuestionContent.styles';
import { ModalStyled, StudentAnswers } from './QuestionSummaryModal.styles';

export default function QuestionSummaryModal({ size, show, hide, data }) {
  const [questionTitle, setQuestionTitle] = useState(null);
  const [mcqOptions, setMCQOptions] = useState([]);
  const { question, answers } = data;
  useEffect(() => {
    if (!isNil(question.Payload)) {
      const { title, mcqOptions } = questionParser(question);
      const renderOptions = mcqOptions.map(option =>
        HTMLReactParser(convertTagsToHTML(option, false), {
          replace: domNode => {
            if (domNode.name === 'img') {
              domNode.attribs.style = 'width: 68%';
              return domNode;
            }
            return domNode;
          }
        })
      );
      setMCQOptions(renderOptions);
      setQuestionTitle(
        HTMLReactParser(convertTagsToHTML(title), {
          replace: domNode => {
            if (domNode.name === 'img' && !isNil(domNode.attribs?.url)) {
              return (
                <img
                  src={domNode.attribs?.url}
                  alt={domNode.attribs?.uniqueid}
                />
              );
            }
            if (domNode.name === 'helptext') {
              return (
                <small id="questionHelp" className="form-text text-muted">
                  {domNode.children[0]?.data ?? ''}
                </small>
              );
            }
            if (domNode.type === 'text') {
              if (domNode.prev?.name === 'input') {
                return <span>{domNode.data}</span>;
              }
            }
            if (domNode.name === 'input') {
              return (
                <span
                  style={{
                    width: '5rem',
                    border: 'solid 1px #cecece',
                    height: '1rem',
                    display: 'inline-block'
                  }}
                />
              );
            }
            return domNode;
          }
        })
      );
    }
  }, [question]);
  return (
    <ModalStyled
      size={size}
      show={show}
      onHide={() => hide()}
      className="question-summary"
    >
      <Modal.Header closeButton>
        <Modal.Title>{`Question ${question.DisplayOrder}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="question-content">
          {questionTitle}
          {mcqOptions.length > 0 && (
            <McqOptionsList>
              {mcqOptions.map(option => (
                <li key={option}>{option}</li>
              ))}
            </McqOptionsList>
          )}
        </div>
        <StudentAnswers>
          <div
            className={
              (Object.keys(answers.correct).length > 0 ||
                Object.keys(answers.wrong).length > 0) &&
              'answer'
            }
          >
            {Object.keys(answers.correct).length > 0 ? (
              <div className="correct-box">
                <p className="title">Correct Answer:</p>
                {Object.keys(answers.correct).map(key => (
                  <>
                    <p className="name">{`${key} (${answers.correct[key].num} student)`}</p>
                    <p>{answers.correct[key].students.join(', ')}</p>
                  </>
                ))}
              </div>
            ) : null}
            {Object.keys(answers.wrong).length > 0 ? (
              <div className="wrong-box">
                <p className="title">Wrong Answer:</p>
                {Object.keys(answers.wrong).map(key => (
                  <>
                    <p className="name">{`${key} (${answers.wrong[key].num} student)`}</p>
                    <p>{answers.wrong[key].students.join(', ')}</p>
                  </>
                ))}
              </div>
            ) : null}
          </div>
        </StudentAnswers>
      </Modal.Body>
    </ModalStyled>
  );
}
