export const FETCH_SYLLABUS_REQUEST = 'FETCH_SYLLABUS_REQUEST';
export const FETCH_SYLLABUS_SUCCESS = 'FETCH_SYLLABUS_SUCCESS';
export const FETCH_SYLLABUS_FAILED = 'FETCH_SYLLABUS_FAILED';
export const NO_SYLLABUS = 'NO_SYLLABUS';
export const PUSH_TO_CART_RESET = 'PUSH_TO_CART_RESET';
export const PUSH_TO_CART_REQUEST = 'PUSH_TO_CART_REQUEST';
export const PUSH_TO_CART_SUCCESS = 'PUSH_TO_CART_SUCCESS';
export const PUSH_TO_CART_FAILED = 'PUSH_TO_CART_FAILED';
export const CREATE_WORKSHEET_RESET = 'CREATE_WORKSHEET_RESET';
export const CREATE_WORKSHEET_REQUEST = 'CREATE_WORKSHEET_REQUEST';
export const CREATE_WORKSHEET_SUCCESS = 'CREATE_WORKSHEET_SUCCESS';
export const CREATE_WORKSHEET_FAILED = 'CREATE_WORKSHEET_FAILED';
export const CREATE_WORKSHEET_ERROR = 'CREATE_WORKSHEET_ERROR';
export const SET_CREATE_HOMEWORK_QUESTIONS = 'SET_CREATE_HOMEWORK_QUESTIONS';
export const REMOVE_CREATE_HOMEWORK_QUESTION =
  'REMOVE_CREATE_HOMEWORK_QUESTION';
