import styled from 'styled-components';

export const EditClassIconSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  .edit-classicon-selector {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 380px;
    height: 250px;
    margin-bottom: 1rem;

    .class-icon-container {
      width: 74px;
      height: 74px;
      background: #f9f9f9;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: solid 1px #dbdbdb;
      cursor: pointer;
      &:hover {
        background: #fff3df;
      }
      .class-icon {
        width: 51.3px;
        height: 51.3px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #ffcb51;
        border-radius: 50%;
      }
    }
    .selected {
      border: solid 1px #ff5b00;
      background: #fff3df;
    }
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  width: 360px;
  justify-content: center;
  margin-bottom: 20px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #dbdbdb;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    background: #ffffff;
    color: #ff5b00;
    &:hover {
      background: #fff7f3;
      border-color: #ff5b00;
    }
  }
`;
