import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import showArrow from 'assets/img/icon-report-collapse-show.svg';
import studentReport from 'assets/img/icon-report-student-report.svg';
import cardReportIcon from 'assets/img/icon-report-sidebar-card.svg';
import insightsIcon from 'assets/img/icon-report-sidebar-insight.svg';
import { SidebarContainer } from './AllReports.styles';

export default function StudentReportSidebar() {
  const { pathname } = useLocation();
  const isActivePath = path => path === pathname;
  return (
    <>
      <SidebarContainer>
        <div className="accordion" id="student-report">
          <div>
            <button
              type="button"
              className="btn"
              data-toggle="collapse"
              data-target="#studentReport"
            >
              <span className="report-category-icon">
                <img src={studentReport} alt="icon-list" />
              </span>
              <span className="report-category">Student Report</span>
            </button>
            <img src={showArrow} alt="show" className="arrow" />
            <div
              className="collapse show"
              id="studentReport"
              data-parent="#student-report"
            >
              <li className="list-group-item">
                <Link
                  className={`nav-link${
                    isActivePath('/student-report') ? ' nav-link-active' : ''
                  }`}
                  to="/student-report"
                >
                  <img
                    src={cardReportIcon}
                    alt="icon-list"
                    className="sidebar-icon"
                  />
                  <span>Report Card</span>
                </Link>
              </li>
              <li className="list-group-item">
                <Link
                  className={`nav-link${
                    isActivePath('/report/student-report/insights')
                      ? ' nav-link-active'
                      : ''
                  }`}
                  to="/report/student-report/insights"
                >
                  <img
                    src={insightsIcon}
                    alt="icon-list"
                    className="sidebar-icon"
                  />
                  <span>Insights</span>
                </Link>
              </li>
            </div>
          </div>
        </div>
      </SidebarContainer>
    </>
  );
}
