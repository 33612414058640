import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import Header from 'components/Header/Header';
import Sidebar from 'components/Sidebar/Sidebar';
import SidebarReport from 'components/Sidebar/SidebarReport/SidebarReport';
import Overlay from 'components/Content/Overlay';
import ProficiencyReport from './ProficiencyReport';
import HighScoreReport from './HighScoreReport';
import StudentHighScoreReport from './StudentHighScoreReport';
import StudentProficiencyReport from './StudentProficiencyReport';
import LoginReport from './LoginReport';
import TeacherMonthlyReport from './TeacherMonthlyReport';
import SpecificMonthlyReport from './SpecificMonthlyReport';
import SkillProficiencyReport from './SkillProficiencyReport';
import SkillHighScoreReport from './SkillHighScoreReport';

export default function Report() {
  const [expanded, setExpanded] = useState(false);
  const toggleNavigation = () => setExpanded(!expanded);

  return (
    <>
      <Header />
      <main className="report">
        <Sidebar
          expanded={expanded}
          toggleNavigation={toggleNavigation}
          className="sidebar--auto"
        >
          <SidebarReport />
        </Sidebar>
        <div className="report-content">
          <Overlay isVisible={expanded} toggleVisible={toggleNavigation} />
          <Switch>
            <Route exact path="/report" component={ProficiencyReport} />
            <Route
              exact
              path="/report/proficiency-report/skill/:topicID/:skillID"
              component={SkillProficiencyReport}
            />
            <Route
              exact
              path="/report/proficiency-report/:studentID"
              component={StudentProficiencyReport}
            />
            <Route
              exact
              path="/report/class-report/high-score-report"
              component={HighScoreReport}
            />
            <Route
              exact
              path="/report/high-score-report/skill/:topicID/:skillID"
              component={SkillHighScoreReport}
            />
            <Route
              path="/report/class-report/high-score-report/:studentID"
              component={StudentHighScoreReport}
            />
            <Route
              path="/report/class-report/login-report"
              component={LoginReport}
            />
            <Route
              path="/report/school-report/monthly-activity"
              component={TeacherMonthlyReport}
            />
            <Route
              path="/report/teacherreport/monthlyreport"
              component={SpecificMonthlyReport}
            />
          </Switch>
        </div>
      </main>
    </>
  );
}
