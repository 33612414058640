import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { clone, flatten, isEmpty, sum } from 'ramda';
import {
  fetchTopicSkillRequest,
  createFactualFluencyRequest
} from 'stores/factual-fluency/actions';
import { resetSelectedWorksheet } from 'stores/worksheet/actions';
// Temporary deactivate cause currently we only have content for P1
// import { selectLevel } from 'stores/levels/actions';
// import DropdownMenu from 'components/Shared/DropdownMenu';
import topicalWorksheetWhiteIcon from 'assets/img/icon-topical-worksheet-white.svg';
import AlertModal from 'components/Alert/AlertModal';

import NavigationWizard from 'components/CreateAndAssign/NavigationWizard';
import TopicList from 'components/CreateAndAssign/FactualFluency/TopicList';
import ValidationMessage from 'components/CreateAndAssign/ValidationMessage';
import NoTopicSkill from './NoTopicSkill';
import { FactualFluencyContainer } from './FactualFluency.styles.js';

const sortedArray = arrVal => {
  const val = arrVal.map(item => {
    return {
      topicId: item.Id,
      total: 0,
      skills: item.Skills.map(skill => {
        return { topicId: item.Id, skillId: skill.Id, numberOfQuestion: 0 };
      })
    };
  });
  return val;
};

const FactualFluency = () => {
  const { t } = useTranslation(['create-assign-factual-fluency']);
  const dispatch = useDispatch();
  const history = useHistory();
  // Temporary deactivate cause currently we only have content for P1
  // const { levels, selectedLevel } = useSelector(state => state.levels);

  const {
    topicSkill,
    createFactualFluency,
    isLoading,
    createFactualFluencyLoading,
    error
  } = useSelector(state => state.factualFluency);

  // eslint-disable-next-line no-unused-vars
  const [levelId, setLevelId] = useState(1);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowMsg, setIsShowMsg] = useState('');

  const onModalClose = () => {
    setIsShowMsg('');
    setIsShowModal(false);
  };

  const [counts, setCounts] = useState([]);
  const totalQn = sum(
    counts.map(count => {
      return count.total;
    })
  );

  const getTopics = useCallback(
    levelId => {
      dispatch(fetchTopicSkillRequest(levelId));
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      dispatch(resetSelectedWorksheet());
    };
  }, [dispatch]);

  useEffect(() => {
    getTopics(levelId);
    // eslint-disable-next-line
  }, [levelId]);

  useEffect(() => {
    let tempCounts = clone(counts);
    tempCounts = sortedArray(topicSkill);
    setCounts(tempCounts);
    // eslint-disable-next-line
  }, [topicSkill]);

  useEffect(() => {
    if (!isEmpty(createFactualFluency)) {
      history.push('/create-and-assign/factual-fluency/create');
    }
  }, [history, createFactualFluency]);

  useEffect(() => {
    if (!isEmpty(error)) {
      setIsShowModal(true);
      setIsShowMsg(error);
    }
  }, [error]);

  // Temporary deactivate cause currently we only have content for P1
  // const setLevel = useCallback(
  //   id => {
  //     const level = levels.find(lvl => lvl.ID === id);
  //     setLevelId(id);
  //     dispatch(selectLevel(level));
  //   },
  //   [dispatch, levels]
  // );

  const save = useCallback(() => {
    if (totalQn > 0) {
      const obj = flatten(
        counts.map(count => {
          return count.skills.filter(skill => skill.numberOfQuestion > 0);
        })
      );
      dispatch(createFactualFluencyRequest(obj));
    }
  }, [totalQn, counts, dispatch]);

  return (
    <FactualFluencyContainer>
      <div className="factual-fluency-header">
        <div className="factual-fluency-title">
          <img src={topicalWorksheetWhiteIcon} alt="topical-icon" />
          <span>
            {t(
              `create-assign-factual-fluency:main.factualFluency`,
              'Factual Fluency Practice'
            )}
          </span>
        </div>

        <div className="factual-fluency-action">
          {/* Temporary deactivate cause currently we only have content for P1 */}
          {/* <div className="factual-fluency-dropdown">
            <div className="tw-levels">
              <span>
                {t(`create-assign-factual-fluency:main.level`, 'Level')}
              </span>
              <DropdownMenu
                selectedValue={`Primary ${levelId}`}
                valueKey={null}
                values={levels.map(level => level.ID)}
                setValue={setLevel}
                valueTemplate={value => `P${value}`}
              />
            </div>
          </div> */}
          {totalQn > 50 && <ValidationMessage />}
          <NavigationWizard
            onClick={() => save()}
            showtotalQuestions
            totalQuestions={totalQn}
          />
        </div>
      </div>

      <div className="factual-fluency-body">
        {isLoading || createFactualFluencyLoading ? (
          <Spinner className="topical-loader" animation="border" />
        ) : topicSkill.length === 0 || counts.length === 0 ? (
          <NoTopicSkill />
        ) : (
          <TopicList
            topics={topicSkill}
            counts={counts}
            setCounts={setCounts}
          />
        )}
        <AlertModal
          title={t(`create-assign-factual-fluency:delWo.error`, 'Error')}
          isShow={isShowModal}
          handleClose={onModalClose}
          buttons={
            <button
              type="button"
              className="btn btn-primary"
              onClick={onModalClose}
            >
              {t(`create-assign-factual-fluency:delWo.close`, 'Close')}
            </button>
          }
        >
          <p>{isShowMsg}</p>
        </AlertModal>
      </div>
    </FactualFluencyContainer>
  );
};

export default React.memo(FactualFluency);
