import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setStep } from 'stores/utils/step/actions';
import { Link } from 'react-router-dom';
import StepperWizard from 'components/Shared/StepperWizard';
import EditSavedWorksheet from 'pages/CreateAndAssign/Edit/EditSavedWorksheet';
import AssignSavedWorksheet from 'pages/CreateAndAssign/Edit/AssignSavedWorksheet';
import isNilOrEmpty from 'helpers/isNilOrEmpty';
import WorksheetPreviewNew from '../QuestionCart/WorksheetPreviewNew';
import { EditViewContainer } from './EditView.styles';

const EditView = () => {
  const { t } = useTranslation(['create-assign-question']);
  const dispatch = useDispatch();
  const { activeStep } = useSelector(state => state.step);
  const { title, editSavedWorksheetQuestions, presentationType } = useSelector(
    state => state.worksheet
  );
  const BackButton = (
    <Link
      to="/create-and-assign/saved-worksheet"
      className="btn btn-link question-cart-content__back"
    >
      &lt; {t(`create-assign-saved:scheduleWo.backList`, 'Back To List')}
    </Link>
  );
  const onSelect = step => {
    let valid = false;
    switch (step) {
      case 2:
        valid =
          title.trim().length > 0 &&
          editSavedWorksheetQuestions.length > 0 &&
          editSavedWorksheetQuestions.length <= 50;
        break;
      case 3:
        valid =
          title.trim().length > 0 &&
          editSavedWorksheetQuestions.length > 0 &&
          !isNilOrEmpty(presentationType);
        break;
      default:
        valid = true;
        break;
    }
    if (valid) {
      dispatch(setStep(step));
    }
  };
  return (
    <EditViewContainer activeStep={activeStep}>
      <StepperWizard onSelect={onSelect} backButton={BackButton}>
        <EditSavedWorksheet
          step="1"
          title={t(`create-assign-question:stepper.editWo`, 'Edit Worksheet')}
        />
        <WorksheetPreviewNew
          step="2"
          title={t(`create-assign-question:stepper.preview`, 'Preview')}
        />
        <AssignSavedWorksheet
          step="3"
          title={t(`create-assign-question:stepper.assign`, 'Assign')}
        />
      </StepperWizard>
    </EditViewContainer>
  );
};

export default EditView;
