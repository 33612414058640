import React from 'react';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import calendarIcon from 'assets/img/icon-calendar-white.svg';
import './DateTimePicker.styles.scss';

export default function DateTimeRangePicker(props) {
  const { startDate, endDate, applyCallback } = props;

  const config = {
    sundayFirst: true
  };

  const value = `${startDate.format('DD MMM YYYY, h:mm A')} - ${endDate.format(
    'DD MMM YYYY, h:mm A'
  )}`;

  return (
    <DateTimeRangeContainer
      leftMode
      noMobileMode
      ranges={{}}
      start={startDate}
      end={endDate}
      local={config}
      applyCallback={applyCallback}
      autoApply
    >
      <div className="track-assignment-picker">
        <span>{value}</span>
        <img src={calendarIcon} alt="calendar icon" />
      </div>
    </DateTimeRangeContainer>
  );
}
