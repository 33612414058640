import styled from 'styled-components';

export const DisplayClassPage = styled.div`
  .error {
    width: 100%;
    text-align: center;
    margin-top: 20rem;
  }
  #dropdown-custom {
    width: 160px;
    margin-left: 10px;
    border: 1px solid #c5c5c5;
    background-color: #ffffff;
    @media only screen and (max-width: 870px) {
      width: 120px;
      margin-top: 5px;
      font-size: 14px;
    }
    @media only screen and (max-width: 768px) {
      width: 120px;
      margin-top: 5px;
    }
  }
  #search {
    background-image: ${props => `url(${props.searchIcon})`};
    background-repeat: no-repeat;
    text-indent: 20px;
    background-position: left;
    background-position-x: 10px;
    @media only screen and (max-width: 870px) {
      margin-top: 5px;
      font-size: 14px;
    }
  }
  #search:focus {
    background-image: none;
    text-indent: 0px;
  }
  .btn {
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
  display: flex;
  flex-direction: column;
  padding-left: 195px;
  z-index: 100;
  @media (max-width: 1024px) {
    padding: 0;
  }

  .top-bar {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    position: fixed;
    right: 0px;
    left: 195px;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #818181;
    padding: 10px 0px;
    font-family: 'Muli';
    margin-bottom: 10px;
    z-index: 10;
    @media only screen and (max-width: 1024px) {
      left: 55px;
    }
    .calendar-year {
      grid-column: 2/5;
      display: flex;
      font-size: 16px;
      p {
        margin-top: 8px;
      }
    }
    .school {
      grid-column: 7/11;
      display: flex;
      img {
        height: 42px;
        width: 42px;
      }
      p {
        font-size: 20px;
        color: #545454;
        font-weight: 500;
        line-height: 25px;
        margin-top: 8px;
        margin-left: 10px;
        @media only screen and (max-width: 1024px) {
          margin-top: 0px;
          text-align: center;
        }
        @media only screen and (max-width: 1142px) {
          font-size: 16px;
        }
        @media only screen and (max-width: 1024px) {
          font-size: 20px;
          margin-top: 8px;
        }
        @media only screen and (max-width: 916px) {
          font-size: 16px;
        }
      }
    }
    .level {
      grid-column: 13/16;
      display: flex;
      p {
        margin-top: 8px;
      }
    }
  }
  .search-bar {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    padding: 40px 0px 10px 0px;
    margin-top: 60px;
    position: fixed;
    background-color: #f1f1f1;
    z-index: 9;
    .search-type-dropdown {
      grid-column: 6/7;
    }
    .search {
      grid-column: 7/12;
      .form-control:focus {
        border-color: #ffa200;
      }
    }
  }
  .text-information {
    display: flex;
    justify-content: center;
    margin-top: 145px;
    padding: 10px;
    text-align: center;
    width: calc(100vw - 195px);
    position: fixed;
    background-color: #f1f1f1;
    z-index: 8;
    span {
      color: #ff5b00;
    }
    @media (max-width: 1024px) {
      width: calc(100vw - 56x);
    }
  }
  .class-card {
    margin-top: 160px;
  }
`;
