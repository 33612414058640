import React from 'react';
import { Button } from 'react-bootstrap';
import { QuestionPaginationContainer } from './QuestionPagination.styles';

export default function QuestionPagination({
  currentPage,
  numOfPages,
  setPage
}) {
  return (
    <QuestionPaginationContainer currentPage={currentPage}>
      <span className="question-pagination-page">{currentPage}</span>
      <div className="btn-container">
        {currentPage > 1 && (
          <Button
            className="prev-button"
            onClick={() => {
              setPage(currentPage - 1);
            }}
          >
            <span>{'<'}</span>
          </Button>
        )}
        {currentPage < numOfPages && (
          <Button
            className="next-button"
            onClick={() => {
              setPage(currentPage + 1);
            }}
          >
            <span>{'>'}</span>
          </Button>
        )}
      </div>
    </QuestionPaginationContainer>
  );
}
