import styled from 'styled-components';

const ModalContainerStyle = styled.div`
  background-color: white;
  padding-bottom: 20px;
  .top-section {
    padding: 20px 50px;
  }
  .close-btn {
    border: none;
    background-color: transparent;
    color: #616161;
    font-size: 16px;
    position: absolute;
    right: 35px;
    top: 25px;
  }
  .title {
    font-size: 16px;
    color: #ff5b00;
    text-align: center;
  }
  .text {
    text-align: center;
    color: #000000;
    font-size: 14px;
    line-height: 18px;
    margin: 10px;
  }
  hr {
    margin-top: 0px;
  }
  .btn-section {
    display: flex;
    justify-content: space-evenly;
    .cancel {
      border: 1px solid #d5d5d5;
      border-radius: 2px;
      color: #ff5b00;
      font-size: 16px;
      background-color: #ffffff;
      width: 110px;
      &:hover {
        border-color: #ff5b00;
        background: #fff7f3;
      }
    }
    .remove {
      border: 1px solid #ff3d3d;
      border-radius: 2px;
      color: #ffffff;
      font-size: 16px;
      background-color: #ff5b00;
      width: 110px;
    }
  }
`;

export default ModalContainerStyle;
