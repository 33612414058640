import React from 'react';
import { isEmpty, isNil } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import shortenLevel from 'helpers/text/shortenLevel';
import { HOTS_CURR_ID, AGE_GROUP } from 'constants/hotsCurr';

import { selectLevel } from 'stores/levels/actions';
import { setShowTrialPopup } from 'stores/login/actions';

const SidebarLevels = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const levels = useSelector(state => state.levels.levels ?? []);
  const { isTrialExpired, isTrial } = useSelector(state => state.login);
  const selectedLevel = useSelector(state => state.levels.selectedLevel ?? {});
  const { selectedCurriculum } = useSelector(state => state.curriculums);
  const currLevels =
    selectedCurriculum && selectedCurriculum.Levels
      ? selectedCurriculum.Levels
      : [];

  const isHOTS = selectedCurriculum?.CurriculumID === HOTS_CURR_ID;

  const onSelectLevel = e => {
    const checkLevelAvailable =
      isEmpty(selectedCurriculum.Levels) &&
      selectedCurriculum.Levels.findIndex(item => item.ID === selectedLevel.ID);

    const level =
      checkLevelAvailable !== -1
        ? currLevels.find(lvl => lvl.Name === e.target.value)
        : selectedCurriculum.Levels[0];

    if (isTrialExpired && isTrial) {
      dispatch(setShowTrialPopup(true));
    } else if (!isEmpty(currLevels)) {
      history.push('/browse-content');
      dispatch(selectLevel(level));
    }
  };
  return (
    <div className="sidebar-level">
      <ul className="d-none d-lg-block">
        {currLevels.map((item, index) => (
          <li
            key={String(index)}
            className={selectedLevel.ID === item.ID ? 'active' : ''}
          >
            <a
              href="#noredirect"
              onClick={e => {
                e.preventDefault();
                if (isTrialExpired && isTrial) {
                  dispatch(setShowTrialPopup(true));
                } else {
                  if (selectedLevel.ID !== item.ID)
                    history.push('/browse-content');
                  dispatch(selectLevel(item));
                }
              }}
            >
              {isHOTS ? `Age ${AGE_GROUP[item.ID]}` : shortenLevel(item.Name)}
            </a>
          </li>
        ))}
      </ul>
      <div className="row align-items-center d-flex d-lg-none">
        <div className="col-auto col-sidebar-label">
          {/* eslint-disable-next-line */}
          <label>Level</label>
        </div>
        <div className="col">
          <select
            className="form-control"
            onChange={onSelectLevel}
            onBlur={onSelectLevel}
            value={
              !isNil(selectedLevel) ? selectedLevel.Name : currLevels[0].Name
            }
          >
            {currLevels.map((item, index) => (
              <option key={String(index)}>
                {isHOTS ? `Age ${AGE_GROUP[item.ID]}` : item.Name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default SidebarLevels;
