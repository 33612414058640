import styled from 'styled-components';

export const NoGroupList = styled.div`
  text-align: center;
  align-self: center;
  .no-group-text {
    margin: 10px 0;
  }
  .first-line {
    font-weight: 700;
    font-size: 16px;
  }
`;

export const GroupLists = styled.div`
  width: 100%;
  padding: 20px 80px 20px 40px;
  .group-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .my-group-heading {
      margin-left: 100px;
      display: flex;
      align-items: flex-start;
      span {
        font-size: 19px;
        color: #545454;
        margin-left: 10px;
      }
    }
  }
  .group-list-body {
    margin-top: 20px;
    .header-grp-name {
      width: 39%;
      display: flex;
      align-items: center;
    }
    .header-members {
      justify-content: center;
    }
    .header-created-time,
    .header-members {
      text-align: center;
      width: 20%;
      display: flex;
      align-items: center;
    }

    .f1 {
      flex: 1;
    }
    .f2 {
      flex: 2;
    }
    .group-list-body-header {
      display: flex;
      margin-bottom: 4px;
    }
    .group-list-body-container {
    }
  }
`;

export const GroupCard = styled.div`
  display: flex;
  border: 1px solid rgb(200, 200, 200);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 8px;
  background-color: #ffffff;
  margin-bottom: 15px;
  color: #1e1e1e;
  border-radius: 4px;
  .group-name {
    font-size: 18px;
    font-weight: 600;
    font-size: 17px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px;
    border-right: 1px solid rgb(200, 200, 200);
  }
  .group-date,
  .group-stu,
  .group-more {
    display: flex;
    align-items: center;
    border-right: 1px solid rgb(200, 200, 200);
    padding: 5px 10px;
    justify-content: center;
  }
  .group-stu {
    text-decoration: underline;
    cursor: pointer;
    img {
      margin-right: 5px;
    }
  }
  .group-more {
    border-right: none;
    display: flex;
    justify-content: space-around;
  }
  .report-gtp-btn {
    background-color: transparent;
    color: #ff5b00;
    padding: 5px 25px 4px 6px;
    box-shadow: none !important;
    border: 1px solid rgb(200, 200, 200);
    font-size: 16px;
    img {
      margin-right: 5px;
    }
  }
  .group-text-stu {
    position: relative;
  }
  .show-added-students {
    position: absolute;
    max-height: 200px;
    width: 230px;
    padding: 10px;
    background-color: #707070;
    color: #ffffff;
    z-index: 9;
    top: 30px;
    left: -100%;
    transition: opacity 0.3s;
    visibility: hidden;
    opacity: 0;
    .scroll-list-stu {
      height: 100%;
      max-height: 160px;
      overflow: auto;
      ::-webkit-scrollbar {
        width: 8px !important;
        overscroll-behavior-y: none;
      }
      ::-webkit-scrollbar-thumb {
        background: #949494 !important;
      }
    }
    .list-class-title {
      span {
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 15px;
      }
      margin-bottom: 20px;
    }
  }
  .show-added-students::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #707070 transparent;
  }
  .group-text-stu:focus .show-added-students {
    visibility: visible;
    opacity: 1;
  }
`;
export const StyledSortingBtnGroup = styled.div`
  display: flex;
  margin-right: 10px;
`;
export const StyledSortingBtn = styled.div`
  cursor: pointer;
  padding: 9px 13px 7px;
  background: ${({ active }) => (active ? '#9A9A9A' : 'white')};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e9e9e9;
  border-right: none;
  path {
    fill: ${({ active }) => (active ? 'white' : '#DBDBDB')};
  }
  &.up-btn {
    border-left: 1px solid #e9e9e9;
    svg {
      transform: rotate(180deg) translateY(2px);
    }
  }
`;
