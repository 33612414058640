import {
  ADD_QUESTION_TO_CART,
  DELETE_QUESTION_FROM_CART,
  FETCH_CART_QUESTIONS_FAILED,
  FETCH_CART_QUESTIONS_REQUEST,
  FETCH_CART_QUESTIONS_SUCCESS,
  FETCH_QUESTIONS_FAILED,
  FETCH_QUESTIONS_REQUEST,
  FETCH_QUESTIONS_SUCCESS,
  RESET_SELECTED_QUESTION,
  SELECTED_QUESTION,
  SELECTING_QUESTION,
  RESET_SELECTED_QUESTIONS,
  SELECTING_QUESTIONS,
  SEARCHING_QUESTIONS,
  SEARCH_QUESTIONS_SUCCESS,
  SEARCH_QUESTIONS_FAILED,
  RESET_SEARCH_QUESTIONS,
  ADD_QUESTIONS_TO_CART,
  RESET_CART_QUESTIONS
} from './actionTypes';

export const addQuestion = question => {
  return { type: ADD_QUESTION_TO_CART, question };
};

export const deleteQuestion = id => {
  return { type: DELETE_QUESTION_FROM_CART, id };
};

export const resetCartQuestions = () => {
  return { type: RESET_CART_QUESTIONS };
};

export const fetchCartQuestionsRequest = () => {
  return { type: FETCH_CART_QUESTIONS_REQUEST };
};

export const fetchCartQuestionsSuccess = cartQuestions => {
  return { type: FETCH_CART_QUESTIONS_SUCCESS, cartQuestions };
};

export const fetchCartQuestionsFailed = error => {
  return { type: FETCH_CART_QUESTIONS_FAILED, error };
};

export const fetchQuestionsRequest = () => {
  return { type: FETCH_QUESTIONS_REQUEST };
};

export const fetchQuestionsSuccess = questions => {
  return { type: FETCH_QUESTIONS_SUCCESS, questions };
};

export const fetchQuestionsFailed = error => {
  return { type: FETCH_QUESTIONS_FAILED, error };
};

export const selectQuestion = selectedQuestion => {
  return { type: SELECTING_QUESTION, selectedQuestion };
};

export const selectQuestionSuccess = selectedQuestion => {
  return { type: SELECTED_QUESTION, selectedQuestion };
};

export const resetSelectedQuestion = () => {
  return { type: RESET_SELECTED_QUESTION };
};

//
export const selectQuestions = selectedQuestions => {
  return { type: SELECTING_QUESTIONS, selectedQuestions };
};

export const resetSelectedQuestions = () => {
  return { type: RESET_SELECTED_QUESTIONS };
};

export const searchingQuestions = searchIDs => {
  return { type: SEARCHING_QUESTIONS, searchIDs };
};

export const fetchSearchQuestionSuccess = questionsResult => {
  return { type: SEARCH_QUESTIONS_SUCCESS, questionsResult };
};

export const fetchSearchQuestionFailed = error => {
  return { type: SEARCH_QUESTIONS_FAILED, error };
};

export const resetSearchQuestions = () => {
  return { type: RESET_SEARCH_QUESTIONS };
};

export const addQuestions = isReset => {
  return { type: ADD_QUESTIONS_TO_CART, isReset };
};
