import React, { useEffect, useState } from 'react';
import Mascot from './mascot.svg';
import { CPContainer, RealTimeContainer } from './CPSection.styles';

// Reference to jquery
const { $ } = window;

// Helpers
// const compareArray = (arr1, arr2) => {
//   // Check if first item is the same
//   return JSON.stringify(arr1[0]) === JSON.stringify(arr2[0]);
// };

// Component
const CPSection = () => {
  // Component States
  const [connectionStatus, setConnectionStatus] = useState(false);
  const [submissionsData, setSubmissionsData] = useState([]);
  const [batchedData, setBatchedData] = useState([]);

  // UseEffects
  // Fetch latest submission
  useEffect(() => {
    $.connection.hub.url = process.env.REACT_APP_SIGNALR_ENDPOINT;
    $.connection.hub.logging = true;
    const psHub = $.connection.ProblemSumsBroadcast;
    // Connect to SignalR endpoint
    $.connection.hub
      .start()
      .done(() => {
        // console.log('Connected');
        setConnectionStatus(true);
        psHub.server
          .getLatestSubmissions()
          .done(data => {
            // console.log(JSON.parse(data));
            if (psHub.client.prevState.length === 0) {
              setSubmissionsData(JSON.parse(data));
              setBatchedData(JSON.parse(data));
            }
          })
          .fail(err => {
            console.log(err);
          });
      })
      .fail(err => {
        setConnectionStatus(false);
        console.log(err);
      });
    return () => $.connection.hub.stop();
  }, []);
  useEffect(() => {
    const psHub = $.connection.ProblemSumsBroadcast;
    if (submissionsData.length > 0) {
      psHub.client.prevState = [...submissionsData];
      // console.log('Set prevState', psHub.client.prevState);
    } else {
      psHub.client.prevState = [];
    }
  }, [submissionsData]);

  useEffect(() => {
    const psHub = $.connection.ProblemSumsBroadcast;
    psHub.client.broadCastLatestCP = data => {
      // console.log('Received broadcast', JSON.parse(data));
      const newData = [...psHub.client.prevState];
      // console.log('Function', newData);
      newData.unshift(JSON.parse(data));
      setSubmissionsData(newData.slice(0, 6));
      if (psHub.client.prevState.length === 3) {
        setBatchedData(newData.slice(0, 6));
      }
    };
  }, []);

  // Mock broadcast update
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     console.log('Broadcast update');
  //     setSubmissionsData([
  //       {
  //         FullName: 'Penared******************',
  //         School: 'Sacred Heart Academy Pasig',
  //         SchoolCode: '1509934585',
  //         Points: 2,
  //         SDate: '11:31, 2020-Jul-04',
  //         Flag: '/Content/images/flag/169.png',
  //         CountryId: '169'
  //       }
  //     ]);
  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, []);

  // Update data in batches every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      const { prevState } = $.connection.ProblemSumsBroadcast.client;
      // console.log('Interval batch');
      if (batchedData.length > 0) {
        setBatchedData(prevState);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [batchedData.length]);
  return (
    <CPContainer>
      <RealTimeContainer>
        <p className="title">Latest CP Submitted</p>
        <table className="table">
          <thead className="table-title">
            <tr>
              <td>Name</td>
              <td>School</td>
              <td>Latest CP</td>
              <td>Submission Time</td>
              <td />
            </tr>
          </thead>
          <tbody className="table-body">
            {connectionStatus &&
              batchedData.slice(0, 4).map((item, index) => (
                <tr className="border_bottom" key={`${item.FullName}-${index}`}>
                  <td className="student-name">{item.FullName}</td>
                  <td className="school">
                    <img
                      onError={e => {
                        e.target.style.display = 'none';
                      }}
                      src={`https://static.koobits.com/eduresources/schlogo/${item.SchoolCode}.png`}
                      alt="school-logo"
                    />
                    <span>{item.School}</span>
                  </td>
                  <td className="latest-CP">{item.Points}</td>
                  <td className="time">{item.SDate}</td>
                  <td className="flag">
                    <img
                      onError={e => {
                        e.target.style.display = 'none';
                      }}
                      src={`https://problemsums.koobits.com/Content/images/flag/${item.CountryId}.png`}
                      alt="flag"
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </RealTimeContainer>
      <img className="mascot" src={Mascot} alt="Welcome to KooBits" />
    </CPContainer>
  );
};

export default CPSection;
