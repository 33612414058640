import {
  FETCH_GAME_SETTING_CLASS_REQUEST,
  FETCH_GAME_SETTING_CLASS_SUCCESS,
  FETCH_GAME_SETTING_CLASS_FAILED,
  FETCH_GAME_SETTING_STUDENT_REQUEST,
  FETCH_GAME_SETTING_STUDENT_SUCCESS,
  FETCH_GAME_SETTING_STUDENT_FAILED,
  SAVE_GAME_SETTING_REQUEST,
  SAVE_GAME_SETTING_SUCCESS,
  SAVE_GAME_SETTING_FAILED,
  RESET_ERROR
} from './actionTypes';

const initialState = {
  isLoading: false,
  classes: [],
  students: [],
  saveSettingResult: false,
  error: null
};

export default function gameSetting(state = initialState, action) {
  switch (action.type) {
    case FETCH_GAME_SETTING_CLASS_REQUEST:
      return {
        ...state,
        isLoading: true,
        classes: [],
        error: null
      };
    case FETCH_GAME_SETTING_CLASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        classes: action.classes,
        error: null
      };
    case FETCH_GAME_SETTING_CLASS_FAILED:
      return {
        ...state,
        isLoading: false,
        classes: [],
        error: action.error
      };
    case FETCH_GAME_SETTING_STUDENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        students: [],
        error: null
      };
    case FETCH_GAME_SETTING_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        students: action.students,
        error: null
      };
    case FETCH_GAME_SETTING_STUDENT_FAILED:
      return {
        ...state,
        isLoading: false,
        students: [],
        error: action.error
      };
    case SAVE_GAME_SETTING_REQUEST:
      return {
        ...state,
        isLoading: true,
        saveSettingResult: false,
        error: null
      };
    case SAVE_GAME_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        saveSettingResult: true,
        error: null
      };
    case SAVE_GAME_SETTING_FAILED:
      return {
        ...state,
        isLoading: false,
        saveSettingResult: false,
        error: action.error
      };
    case RESET_ERROR:
      return {
        ...state,
        saveSettingResult: false,
        error: null
      };
    default:
      return state;
  }
}
