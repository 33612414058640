import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner, Dropdown } from 'react-bootstrap';
import { changeTitleDateRequest } from 'stores/assignments/actions';

import DateTimeRangePicker from 'components/TrackAssignment/DateTimeRangePicker';

import moment from 'moment-timezone';
import { assignmentVisibilities } from 'constants/index';
import successIcon from 'assets/img/icon-manage-class-success-check.svg';
import tickIcon from 'assets/img/icon-tick.svg';
import DatePicker from './DatePicker';
import { ChangeTitleDateContainer } from './ChangeTitleDateModal.styles';

export default function ChangeTitleDateModal({ hide, data }) {
  const { t } = useTranslation(['track-assignment-assigned-me']);
  const { changeTitleIsLoading, changeTitleDate } = useSelector(
    state => state.assignments
  );
  const dispatch = useDispatch();

  const [Title, setTitle] = useState(data.HomeWorkTitle);
  const [StartDate, setStartDate] = useState(setDate(data.StartDate));
  const [Deadline, setDeadline] = useState(setDate(data.Deadline));
  const [ResultDate, setResultDate] = useState(setDate(data.ResultDate, true));
  const [Visibility, setVisibility] = useState(data.AssignmentVisibilty);

  function setDate(date, deadLine) {
    if (deadLine) date = compareDate(date, data.Deadline);
    return moment.tz(date, 'Asia/singapore');
  }

  function compareDate(resDate, deadLineDate) {
    const result = new Date(resDate);
    const deadline = new Date(deadLineDate);
    if (deadline.getTime() > result.getTime()) {
      return deadLineDate;
    }
    return resDate;
  }

  const applyCallback = (startDateValue, endDateValue) => {
    setStartDate(startDateValue);
    setDeadline(endDateValue);
    setResultDate(compareDate(ResultDate, endDateValue));
  };

  const applyCallbackRelease = (e, picker) => {
    setResultDate(picker.startDate);
  };

  const update = () => {
    const obj = {
      HomeworkId: data.HomeWorkId,
      StartDate: StartDate.format('YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ'),
      Deadline: Deadline.format('YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ'),
      Title
    };
    if (data.HomeWorkStatus === 6) obj.Visibility = Visibility;
    if (data.HomeWorkType === 12)
      obj.ResultDate = ResultDate.format('YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ');
    dispatch(changeTitleDateRequest(obj));
  };

  return (
    <ChangeTitleDateContainer>
      {changeTitleDate === true ? (
        <>
          <span className="success-title">
            <img
              src={successIcon}
              alt="Success icon"
              style={{ marginRight: '0.5rem' }}
            />
            {t(`track-assignment-assigned-me:editAssign.success`, 'Success!')}
          </span>
          <span style={{ margin: '2rem 0' }}>
            {t(
              `track-assignment-assigned-me:editAssign.changesSave`,
              'Changes have been saved sucessfully!'
            )}
          </span>
          <button className="success-btn" type="button" onClick={hide}>
            {t(`track-assignment-assigned-me:editAssign.ok`, 'OK')}
          </button>
        </>
      ) : (
        <>
          <button className="close" type="button" onClick={hide}>
            X
          </button>
          <div className="title">
            {t(
              `track-assignment-assigned-me:editAssign.editAssignment`,
              'Edit Assignment'
            )}
          </div>

          <div className="form-container">
            <div className="form-row">
              <div className="row-title">
                {t(
                  `track-assignment-assigned-me:editAssign.hWTitle`,
                  'Homework Title'
                )}
              </div>
              <input
                type="text"
                value={Title}
                className="hw-form-inp"
                onChange={e => setTitle(e.target.value)}
                required
              />
            </div>

            <div className="form-row">
              <div className="row-title">
                {t(`track-assignment-assigned-me:editAssign.date`, 'Date')}
              </div>
              <DateTimeRangePicker
                startDate={StartDate}
                endDate={Deadline}
                applyCallback={applyCallback}
              />
            </div>

            {data.HomeWorkStatus === 6 && (
              <div className="form-row">
                <div className="row-title">
                  {t(
                    `track-assignment-assigned-me:editAssign.showWorksheet`,
                    'Show the worksheet in students’ account'
                  )}
                </div>
                <Dropdown>
                  <Dropdown.Toggle variant="light" className="release-dropdown">
                    {assignmentVisibilities[Visibility]}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {Object.keys(assignmentVisibilities).map(key => (
                      <Dropdown.Item
                        value={key}
                        key={key}
                        onClick={() => setVisibility(key)}
                      >
                        <div className="custom-dropdown">
                          <span
                            style={
                              Visibility === key
                                ? { color: '#FF5B00' }
                                : { color: '#000000' }
                            }
                          >
                            {assignmentVisibilities[key]}
                          </span>
                          {Visibility === key && (
                            <img src={tickIcon} alt="tick-icon" />
                          )}
                        </div>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

            {data.HomeWorkType === 12 && (
              <div className="form-row">
                <div className="row-title">
                  {t(
                    `track-assignment-assigned-me:editAssign.resRelease`,
                    'Result Release Date'
                  )}
                </div>
                <DatePicker
                  date={ResultDate}
                  minDate={Deadline}
                  applyCallback={applyCallbackRelease}
                />
              </div>
            )}
          </div>

          <Buttons
            hide={hide}
            update={update}
            isLoading={changeTitleIsLoading}
            disableBtn={Title === ''}
            t={t}
          />
        </>
      )}
    </ChangeTitleDateContainer>
  );
}

function Buttons({ hide, update, isLoading, disableBtn, t }) {
  return (
    <div className="btn-group">
      <button className="no-btn" type="button" onClick={hide}>
        {t(`track-assignment-assigned-me:editAssign.cancel`, 'Cancel')}
      </button>
      <button
        disabled={disableBtn}
        className="yes-btn"
        type="button"
        onClick={update}
      >
        {t(`track-assignment-assigned-me:editAssign.confirm`, 'Confirm')}
        {isLoading && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="ml-2"
          />
        )}
      </button>
    </div>
  );
}
