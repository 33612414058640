import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import shortenLevel from 'helpers/text/shortenLevel';
import { selectLevel } from 'stores/levels/actions';
import Radio from 'components/Level/Radio';
import Header from 'components/Header/Header';

const ChooseLevel = () => {
  const { t } = useTranslation(['choose-level']);
  const history = useHistory();
  const dispatch = useDispatch();
  const levels = useSelector(state => state.levels.levels ?? []);
  const handleSubmit = event => {
    event.preventDefault();
    history.push('/browse-content');
  };
  return (
    <>
      <Header />
      <main>
        <section className="select-level">
          <div className="container">
            <h1>
              {t(
                `choose-level:main.chooseMsg`,
                'Please choose the level you want to browse'
              )}
            </h1>
            <form onSubmit={handleSubmit}>
              <ul className="list-level">
                {levels.map((item, index) => (
                  <Radio
                    key={String(index)}
                    onChange={() => dispatch(selectLevel(item))}
                    id={String(index)}
                    value={shortenLevel(item.Name)}
                  />
                ))}
              </ul>
              <button
                type="submit"
                className="btn btn-primary btn-lg btn-confirm"
              >
                {t(`choose-level:main.confirm`, 'Confirm')}
              </button>
            </form>
          </div>
        </section>
      </main>
    </>
  );
};

export default ChooseLevel;
