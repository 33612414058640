import React from 'react';
import { useSelector } from 'react-redux';
import HeaderBox from 'components/CreateAndAssign/HeaderBox';
import WorksheetAssignTitle from 'components/CreateAndAssign/QuestionCart/WorksheetAssignTitle';
import NewBorder from 'components/Shared/NewBorder';
import QuestionHeader from 'components/Question/QuestionHeader';
import QuestionContent from 'components/Question/QuestionContent';
import AssignSidebar from 'components/CreateAndAssign/AssignSidebar';
import { AssignContent } from './AssignSavedWorksheet.styles';

const AssignSavedWorksheet = () => {
  const { title } = useSelector(state => state.worksheet);

  const { createWorksheetItems } = useSelector(state => state.topicalWorksheet);
  return (
    <>
      <AssignContent>
        <HeaderBox>
          <WorksheetAssignTitle
            title={title}
            totalQuestions={createWorksheetItems.length}
          />
        </HeaderBox>
        <div className="box-white p-3 assign-content">
          {/* <InfoSection
            title="Instruction"
            description="Follow All Instuction Carefully. Answer All Question."
          />*/}
          <NewBorder />
          {createWorksheetItems.map((question, key) => {
            const order = key + 1;
            return (
              <div key={`question-${order}`}>
                <QuestionHeader order={order} score={2} />
                <NewBorder styles={{ marginBottom: '20px' }} />
                <QuestionContent question={question.CartQuestion} />
                <NewBorder />
              </div>
            );
          })}
        </div>
      </AssignContent>
      <AssignSidebar isCreateHomework />
    </>
  );
};

export default AssignSavedWorksheet;
