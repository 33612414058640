import React from 'react';
import StarIcon from 'assets/img/icon-report-skill-star.svg';

const HighScoreStars = ({ numOfStars }) => {
  const generateStars = num => {
    const stars = [];
    if (num <= 0) {
      return null;
    }
    for (let i = 1; i <= numOfStars; i++) {
      stars.push(<img src={StarIcon} alt="star" />);
    }
    return stars;
  };
  return <span>{generateStars(numOfStars)}</span>;
};

export default HighScoreStars;
