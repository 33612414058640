import styled from 'styled-components';

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.9375rem;
  font-weight: 600;
  color: #1c1b1b;
  padding: 2rem 1rem;
  @media only screen and (max-width: 768px) {
    font-size: 10px;
  }
  input {
    border: solid 1px #cecece;
  }
  .next-prev-page {
    margin-right: 1rem;
    @media only screen and (max-width: 768px) {
      margin-right: 5px;
    }
    .btn-outline-page-primary {
      background: #fff;
      border: solid 1px #cecece;
      font-weight: 600;
      margin-left: 1rem;
      @media only screen and (max-width: 768px) {
        font-size: 10px;
        margin-left: 5px;
      }
    }
    .btn-outline-page-primary.selected {
        color: #fff;
        background: #ff5b00;
        @media only screen and (max-width: 768px) {
          font-size: 10px;
        }
      }
      .btn-outline-page-primary:hover {
        color: #ff5b00;
      }
      .btn-outline-page-primary.disabled,
      .btn-outline-page-primary:disabled {
        color: #d8d8d8;
      }
`;
