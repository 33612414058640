import { isNil } from 'ramda';
import React, { useEffect, useCallback, useState } from 'react';
import { Bar, PolarArea } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MonthlyReportChartStyle } from './MonthlyReportChart.styles';

const MonthlyReportChart = props => {
  const { isLoading, classes } = useSelector(state => state.manageClasses);
  const { data, reportName } = props;
  const [barChart, setBarChart] = useState(null);
  const [polarChart, setPolarChart] = useState(null);
  const [horizontalChart, setHorizontalChart] = useState([]);
  const [tiltedChart, setTiltedChart] = useState([]);

  const { t } = useTranslation(['monthly-report']);
  const monthName = t(
    `monthly-report:month.${reportName.ReportName.slice(0, 3)}`,
    reportName.ReportName.slice(0, 3)
  );
  const handleClick = useCallback(
    (index, levelIndex) => () => {
      document
        .getElementById(`refPrimary_${index}_${levelIndex}`)
        .scrollIntoView();
    },
    []
  );

  const bar = useCallback(() => {
    const totalLoginData =
      data &&
      data.totalPercentLoginByMonthReportData.data.map(d => d.totallogindata);
    const loginPercentageData =
      data &&
      data.totalPercentLoginByMonthReportData.data.map(
        d => d.loginpercentageData
      );
    const chartData = {
      labels: [
        t('monthly-report:longMonths.January', 'January'),
        t('monthly-report:longMonths.February', 'February'),
        t('monthly-report:longMonths.March', 'March'),
        t('monthly-report:longMonths.April', 'April'),
        t('monthly-report:longMonths.May', 'May'),
        t('monthly-report:longMonths.June', 'June'),
        t('monthly-report:longMonths.July', 'July'),
        t('monthly-report:longMonths.August', 'August'),
        t('monthly-report:longMonths.September', 'September'),
        t('monthly-report:longMonths.October', 'October'),
        t('monthly-report:longMonths.November', 'November'),
        t('monthly-report:longMonths.December', 'December')
      ],
      datasets: [
        {
          type: 'line',
          label: t(
            'monthly-report:SpecificMonthlyReport.loginPercentage',
            'Login Percentage (%)'
          ),
          data: loginPercentageData,
          borderWidth: 2,
          borderColor: '#0D97E5',
          fill: true,
          yAxisID: 'yAxisLine',
          lineTension: 0.5
        },
        {
          type: 'bar',
          label: t(
            'monthly-report:SpecificMonthlyReport.totalLogin',
            'Total Login'
          ),
          data: totalLoginData,
          backgroundColor: '#ff6384',
          yAxisID: 'yAxisBar'
        }
      ]
    };
    setBarChart(chartData);
  }, [data, t]);

  const polar = useCallback(() => {
    const percentage =
      data &&
      data.loginComparisionByLevelReportData.data.map(d => d.percentage);
    const chartData = {
      labels: ['P1 (%)', 'P2 (%)', 'P3 (%)', 'P4 (%)', 'P5 (%)', 'P6 (%)'],
      datasets: [
        {
          data: percentage,
          label: 'Login % By Level',
          backgroundColor: [
            'rgba(255,136,193,0.5)',
            'rgba(255,203,158,0.5)',
            'rgba(255,232,169,0.5)',
            'rgba(127,227,223,0.5)',
            'rgba(127,205,245,0.5)',
            'rgba(219,127,255,0.5)'
          ],
          borderWidth: 1
        }
      ]
    };
    setPolarChart(chartData);
  }, [data]);

  const horizontalBar = useCallback(() => {
    const temp = data && data.loginComparisionByLevelAndClassReportData.data;
    const percentage = temp.map(e =>
      !isNil(e.classes) ? e.classes.map(e => e.percentage) : []
    );
    const label = temp.map(e =>
      !isNil(e.classes) ? e.classes.map(e => e.className) : []
    );
    const chartData = [];
    temp.forEach((e, i) => {
      chartData[i] = {
        labels: label[i],
        datasets: [
          {
            data: percentage[i],
            label: t(
              'monthly-report:SpecificMonthlyReport.loginPercentage',
              'Login Percentage (%)'
            ),
            backgroundColor: 'rgba(255,136,193,0.5)',
            borderWidth: 1,
            yAxisID: 'yAxisBar'
          }
        ]
      };
    });
    setHorizontalChart(chartData);
  }, [data, t]);

  const tiltedBar = useCallback(() => {
    const temp =
      data && data.averageHomeworkQuestionsPerStudentPerclassReportData.data;
    const homeworkQuestionData = temp.map(e =>
      !isNil(e.classes) ? e.classes.map(e => e.homeworkQuestionData) : []
    );
    const SDLQuestionData = temp.map(e =>
      !isNil(e.classes) ? e.classes.map(e => e.sdlQuestionData) : []
    );
    const label = temp.map(e =>
      !isNil(e.classes) ? e.classes.map(e => e.className) : []
    );
    const chartData = [];
    temp.forEach((e, i) => {
      chartData[i] = {
        labels: label[i],
        datasets: [
          {
            data: homeworkQuestionData[i],
            label: t(
              'monthly-report:SpecificMonthlyReport.averageHw',
              'Average homework questions per student per class per month'
            ),
            backgroundColor: 'rgba(255,136,193,0.5)',
            borderWidth: 1
          },
          {
            data: SDLQuestionData[i],
            label: t(
              'monthly-report:SpecificMonthlyReport.averageSelf',
              'Average self-directed learning questions per student'
            ),
            backgroundColor: 'rgba(127,205,245,0.5)',
            borderWidth: 1
          }
        ]
      };
    });
    setTiltedChart(chartData);
  }, [data, t]);

  useEffect(() => {
    if (data) {
      bar();
      polar();
      horizontalBar();
      tiltedBar();
    }
  }, [data, bar, polar, horizontalBar, tiltedBar]);

  return (
    <MonthlyReportChartStyle>
      <p className="koobitsTitle">
        {!isLoading && classes !== null && (classes.Name || '')}
      </p>
      <p className="monthlyText">
        {t(
          `monthly-report:SpecificMonthlyReport.MonthlyReportTitle`,
          {
            month: monthName,
            year: reportName.Year
          },
          `Monthly Report - ${monthName} ${reportName.Year}`
        )}
      </p>
      <div className="reportContainer">
        {data &&
          Object.entries(data).map((e, index) => {
            if (index > 1) {
              return (
                <div className="renderReport" key={e[1].reportName}>
                  <p className="number">{index - 1}</p>
                  <div>
                    <p className="reportName">
                      {t(
                        `monthly-report:SpecificMonthlyReport.${e[1].reportName}`,
                        `${e[1].reportName}`
                      )}
                    </p>
                    <div className="renderLevel">
                      {!isNil(e[1].data) &&
                        e[1].data.map((level, levelIndex) => {
                          if (index > 3) {
                            return (
                              <button
                                className="levelButton"
                                type="button"
                                key={level.levelName}
                                onClick={handleClick(index, levelIndex)}
                              >
                                <p className="levelText">
                                  {t(
                                    `monthly-report:SpecificMonthlyReport.${level.levelName}`,
                                    `${level.levelName}`
                                  )}
                                </p>
                              </button>
                            );
                          }
                          return null;
                        })}
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}
      </div>
      <div className="chartReportContainer">
        {data &&
          Object.entries(data).map((e, index) => {
            if (index > 1) {
              return (
                <div key={`chart-${e[1].reportName}`}>
                  <div className="reportNameChart">
                    <p className="number">{index - 1}</p>
                    <p className="reportName">
                      {t(
                        `monthly-report:SpecificMonthlyReport.${e[1].reportName}`,
                        `${e[1].reportName}`
                      )}
                    </p>
                  </div>
                  <div>
                    {index - 2 === 0 ? (
                      <>
                        <Bar
                          data={barChart}
                          options={{
                            responsive: true,
                            plugins: {
                              title: {
                                display: true,
                                text: t(
                                  'monthly-report:SpecificMonthlyReport.loginData',
                                  'Login Data'
                                )
                              }
                            },
                            tooltips: {
                              mode: 'index',
                              intersect: true
                            },
                            scales: {
                              yAxisLine: {
                                position: 'right',
                                grid: {
                                  drawOnChartArea: false
                                },
                                suggestedMin: 0,
                                suggestedMax: 100
                              }
                            }
                          }}
                        />
                        <hr />
                      </>
                    ) : null}
                    {index - 2 === 1 ? (
                      <>
                        <div style={{ width: '75%', margin: '0 auto' }}>
                          <PolarArea
                            data={polarChart}
                            options={{
                              responsive: true,
                              plugins: {
                                legend: {
                                  position: 'right'
                                },
                                title: {
                                  display: true,
                                  text: t(
                                    'monthly-report:SpecificMonthlyReport.login',
                                    'Login %'
                                  )
                                }
                              },
                              scale: {
                                ticks: {
                                  beginAtZero: true,
                                  stepSize: 10
                                },
                                reverse: false
                              },
                              animation: {
                                animateRotate: false,
                                animateScale: true
                              }
                            }}
                          />
                        </div>
                        <hr />
                      </>
                    ) : null}
                    {e[1].data.map((d, levelIndex) => {
                      if (index - 2 === 2) {
                        return (
                          <>
                            <div
                              id={`refPrimary_${index}_${levelIndex}`}
                              className="chart"
                              key={d.levelName}
                            >
                              <p className="levelChartName">
                                {' '}
                                {t(
                                  `monthly-report:SpecificMonthlyReport.${d.levelName}`,
                                  `${d.levelName}`
                                )}
                              </p>

                              <Bar
                                id={`horizontalBarChart-${levelIndex}`}
                                data={horizontalChart[levelIndex]}
                                options={{
                                  indexAxis: 'y',
                                  plugins: {
                                    legend: {
                                      position: 'right',
                                      align: 'start',
                                      labels: {
                                        padding: 35
                                      }
                                    },
                                    title: {
                                      display: true,
                                      text: t(
                                        'monthly-report:SpecificMonthlyReport.loginPercentage',
                                        'Login Percentage (%)'
                                      )
                                    }
                                  },
                                  scales: {
                                    yAxisBar: {
                                      ticks: {
                                        autoSkip: false
                                      }
                                    }
                                  }
                                }}
                              />
                            </div>
                            <hr />
                          </>
                        );
                      }
                      if (index - 2 === 3) {
                        return (
                          <>
                            <div
                              id={`refPrimary_${index}_${levelIndex}`}
                              className="chart"
                              key={d.levelName}
                            >
                              <p className="levelChartName">
                                {' '}
                                {t(
                                  `monthly-report:SpecificMonthlyReport.${d.levelName}`,
                                  `${d.levelName}`
                                )}
                              </p>
                              <Bar
                                id={`tiltedBarChart-${levelIndex}`}
                                data={tiltedChart[levelIndex]}
                                options={{
                                  plugins: {
                                    title: {
                                      display: true,
                                      text: t(
                                        'monthly-report:SpecificMonthlyReport.qnSubmissions',
                                        { level: index + 1 },
                                        `Question submissions - P${index + 1}`
                                      )
                                    }
                                  },
                                  scales: {
                                    xAxes: [
                                      {
                                        ticks: {
                                          maxRotation: 50,
                                          minRotation: 50
                                        }
                                      }
                                    ]
                                  }
                                }}
                              />
                            </div>
                            <hr />
                          </>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
              );
            }
            return null;
          })}
      </div>
    </MonthlyReportChartStyle>
  );
};
export default MonthlyReportChart;
