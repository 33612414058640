import { call, put } from 'redux-saga/effects';
import { isNil } from 'ramda';
import profileSettingsService from 'services/profileSettings';
import {
  fetchProfileDataSuccess,
  fetchProfileDataFailed,
  fetchAvatarsSuccess,
  fetchAvatarsFailed,
  updateProfileSuccess,
  updateProfileFailed
} from 'stores/profileSettings/actions';

/**
 * The saga for add question to the Cart
 */
export function* updateProfileData({ params, updateProfile }) {
  const data = yield call(
    profileSettingsService.updateProfile,
    params,
    updateProfile
  );
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(updateProfileSuccess(data));
    } else {
      yield put(updateProfileFailed(data.error));
    }
  }
}

/**
 * The saga for fetch profile data
 */
export function* fetchProfileData() {
  const data = yield call(profileSettingsService.getProfileData);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchProfileDataSuccess(data));
    } else {
      yield put(fetchProfileDataFailed(data.error));
    }
  }
}

/**
 * The saga for fetch avatars
 */
export function* fetchAvatars() {
  const data = yield call(profileSettingsService.getAvatars);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchAvatarsSuccess(data));
    } else {
      yield put(fetchAvatarsFailed(data.error));
    }
  }
}
