import React from 'react';
import { sort } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { selectTopic } from 'stores/topics/actions';
import TopicsLoader from 'components/Loader/TopicsLoader';
import SimpleBar from 'simplebar-react';
import { setShowTrialPopup } from 'stores/login/actions';
import SidebarMultipleCurriculums from './SidebarMultipleCurriculum/SidebarMultipleCurriculum';
import 'simplebar/dist/simplebar.min.css';

const SidebarTopics = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { topics, isLoading } = useSelector(state => state.topics);
  const { isTrialExpired, isTrial } = useSelector(state => state.login);
  const { selectedCurriculum } = useSelector(state => state.curriculums);
  const currLevels =
    selectedCurriculum && selectedCurriculum.Levels
      ? selectedCurriculum.Levels
      : [];
  const sortedTopics = sort((a, b) => a.Display - b.Display, topics);
  const selectedTopic = useSelector(state => state.topics.selectedTopic ?? {});
  const onSelectTopic = topic => {
    window.scrollTo(0, 0);
    if (isTrialExpired && isTrial) {
      dispatch(setShowTrialPopup(true));
    } else {
      dispatch(selectTopic(topic));
    }
  };

  return (
    <div
      className={`sidebar-topic ${currLevels.length === 0 &&
        'sidebar-nolevel'}`}
    >
      <div className="sidebar-topic-header">
        <SidebarMultipleCurriculums />
      </div>
      <SimpleBar className="sidebar-topic-body" style={{ maxHeight: '90%' }}>
        <ul className="nav flex-column">
          {isLoading ? (
            <TopicsLoader />
          ) : (
            sortedTopics.map((item, index) => (
              <li
                key={String(index)}
                className={`nav-item ${
                  selectedTopic.ID === item.ID ? 'active' : ''
                }`}
              >
                <a
                  className="nav-link"
                  href="#noredirect"
                  onClick={e => {
                    e.preventDefault();
                    onSelectTopic(item);
                    if (
                      location.pathname !== '/browse-content/problemsums-trial'
                    )
                      history.push('/browse-content/question-list');
                  }}
                >
                  {item.Name}
                </a>
              </li>
            ))
          )}
        </ul>
      </SimpleBar>
    </div>
  );
};

export default SidebarTopics;
