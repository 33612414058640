import styled from 'styled-components';
import { Nav } from 'react-bootstrap';
import { PaginationContainer } from 'components/ManageClass/EditClassIconSelector.styles';

export const MultipleCurriculumModalBodyContainer = styled.div`
  background-color: white;
  padding-bottom: 15px;
  .info-text {
    display: flex;
    justify-content: space-evenly;
    margin: 15px 80px;
    p {
      font-size: 21px;
      font-weight: 600;
      color: #e84b05;
      margin-right: 30px;
    }
    button {
      width: 113px;
      height: 40px;
      font-size: 16px;
      border: none;
      color: #fffdfc;
      background: #ff5b00;
      padding: 10px;
      border-radius: 3px;
    }
    button:hover {
      background-color: #ef4b01;
    }
  }
`;

export const CurriculumNav = styled(Nav)`
  background-color: #f5f5f5;
  border-bottom: 1px solid #dee2e6;
  .nav {
    width: 95%;
    justify-content: center;
    background-color: transparent;
  }
  .nav-tabs {
    border-bottom: none;
  }
  .nav-link {
    border: none;
  }
  .nav-link.active {
    color: #101010;
    border-bottom: 3px solid #ffc400;
    background-color: transparent;
  }
  a {
    font-size: 18px;
    font-weight: 600;
  }
  .close-btn {
    border: none;
    background-color: transparent;
    color: #616161;
    font-size: 16px;
  }
`;

export const Curriculums = styled.div`
  .tab-pane {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 25px;
  }
`;

export const Pagination = styled(PaginationContainer)`
  width: 100%;
`;
