import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import GradeAIcon from 'assets/img/icon-report-high-score-a.svg';
import GradeBIcon from 'assets/img/icon-report-high-score-b.svg';
import GradeCIcon from 'assets/img/icon-report-high-score-c.svg';
import GradeDIcon from 'assets/img/icon-report-high-score-d.svg';
import { ReactComponent as TriangleIcon } from 'assets/img/report/icon-triangle.svg';
import HighScoreIncompleteIcon from 'assets/img/icon-report-high-score-incomplete.svg';
import { proficiencyColors } from 'constants/index';
import calculatePercentageString from 'helpers/calculatePercentageString';
import { toggleReport } from 'stores/report/actions';
import ProgressBar from './ProgressBar';
import {
  StudentTable,
  ProficiencySkills,
  SortButtonContainer,
  SortButton
} from './SkillTableReport.styles';

const progressbarNumber = data => {
  const progressBarInput = [
    {
      percentage: '',
      color: proficiencyColors.mastery.color,
      borderColor: proficiencyColors.mastery.borderColor
    },
    {
      percentage: '',
      color: proficiencyColors.competent.color,
      borderColor: proficiencyColors.competent.borderColor
    },
    {
      percentage: '',
      color: proficiencyColors.beginning.color,
      borderColor: proficiencyColors.beginning.borderColor
    },
    {
      percentage: '',
      color: proficiencyColors.incomplete.color,
      borderColor: proficiencyColors.incomplete.borderColor
    }
  ];
  const proficiencyIndex = {
    Incomplete: 3,
    NeedImprove: 2,
    Passed: 1,
    Mastered: 0
  };
  for (const proficiency of Object.keys(data.CompetencyDetails)) {
    if (data.CompetencyDetails[proficiency] > 0) {
      // Calculate percentage string
      const percentageString = calculatePercentageString(
        data.CompetencyDetails[proficiency],
        data.TotalNoOfStudents
      );
      progressBarInput[
        proficiencyIndex[proficiency]
      ].percentage = percentageString;
    } else {
      progressBarInput[proficiencyIndex[proficiency]].percentage = '0%';
    }
  }
  return progressBarInput.filter(
    proficiency => proficiency.percentage !== '0%'
  );
};

const parseHighScoreProgressBar = data => {
  const progressBarInput = [
    {
      percentage: '',
      color: '#FBDA38',
      borderColor: '#BE9E00'
    },
    {
      percentage: '',
      color: '#D9ECFF',
      borderColor: '#ADBED0'
    },
    {
      percentage: '',
      color: '#EDD592',
      borderColor: '#D5AC6A'
    },
    {
      percentage: '',
      color: '#FFF4D5',
      borderColor: '#DDC6A3'
    },
    {
      percentage: '',
      color: proficiencyColors.incomplete.color,
      borderColor: proficiencyColors.incomplete.borderColor
    }
  ];
  const masteryIndex = {
    A: 0,
    B: 1,
    C: 2,
    D: 3,
    Incomplete: 4
  };
  for (const mastery of Object.keys(data.MasteryLevels)) {
    if (data.MasteryLevels[mastery] > 0) {
      // Calculate percentage string
      const percentageString = calculatePercentageString(
        data.MasteryLevels[mastery],
        data.TotalNoOfUsers
      );
      progressBarInput[masteryIndex[mastery]].percentage = percentageString;
    } else {
      progressBarInput[masteryIndex[mastery]].percentage = '0%';
    }
  }
  return progressBarInput.filter(mastery => mastery.percentage !== '0%');
};
const PROFICIENCY_THEAD = [
  {
    type: 'mastery',
    title: 'mastery',
    upperCaseTitle: 'Mastery',
    content: 'proficiency',
    upperCaseContent: 'Proficiency',
    columnName: 'Mastered'
  },
  {
    type: 'competent',
    title: 'competent',
    upperCaseTitle: 'Competent',
    content: 'proficiency',
    upperCaseContent: 'Proficiency',
    columnName: 'Passed'
  },
  {
    type: 'beginning',
    title: 'beginning',
    upperCaseTitle: 'Beginning',
    content: 'proficiency',
    upperCaseContent: 'Proficiency',
    columnName: 'NeedImprove'
  },
  {
    type: 'incomplete',
    title: 'incomplete',
    upperCaseTitle: 'Incomplete',
    content: 'question',
    upperCaseContent: 'Question',
    columnName: 'Incomplete'
  }
];
const HIGH_SCORE_THEAD = [
  {
    gradeType: 'A',
    icon: GradeAIcon,
    fraction: '9-10'
  },
  {
    gradeType: 'B',
    icon: GradeBIcon,
    fraction: '6-8'
  },
  {
    gradeType: 'C',
    icon: GradeCIcon,
    fraction: '4-5'
  },
  {
    gradeType: 'D',
    icon: GradeDIcon,
    fraction: '1-3'
  },
  {
    gradeType: 'Incomplete',
    icon: HighScoreIncompleteIcon
  }
];
export default function SkillTableReport({
  type,
  data,
  isLoading,
  sortClick,
  sortDetail
}) {
  const { t } = useTranslation(['proficiency-report']);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleClick = item => {
    if (type === 'proficiency') {
      history.push(
        `/report/proficiency-report/skill/${item.TopicId}/${item.SkillId}`
      );
      dispatch(toggleReport('proficiency'));
    } else if (type === 'highscore') {
      history.push(
        `/report/high-score-report/skill/${item.TopicId}/${item.SkillId}`
      );
      dispatch(toggleReport('high'));
    }
  };
  return (
    <>
      <StudentTable type={type}>
        {!isLoading && (
          <table className="table table-responsive-sm">
            <thead className="table-head">
              <tr>
                {type === 'proficiency' && (
                  <>
                    <th colSpan="3" />
                    {PROFICIENCY_THEAD.map(
                      ({ type, title, upperCaseTitle, columnName }) => (
                        <th scope="col" key={type} className="proficiency-th">
                          <ProficiencySkills proficiency={type}>
                            <div className="skill-title">
                              <div className="icon" />
                              <span>
                                {t(
                                  `proficiency-report:legend.${title}`,
                                  upperCaseTitle
                                )}
                              </span>
                            </div>
                          </ProficiencySkills>
                          <SortButtonContainer>
                            <SortButton
                              active={
                                sortDetail?.type === columnName &&
                                sortDetail?.sortType === 'desc'
                              }
                              onClick={() =>
                                sortClick({
                                  type: columnName,
                                  sortType: 'desc'
                                })
                              }
                            >
                              <TriangleIcon />
                            </SortButton>
                            <SortButton
                              type="asc"
                              active={
                                sortDetail?.type === columnName &&
                                sortDetail?.sortType === 'asc'
                              }
                              onClick={() =>
                                sortClick({ type: columnName, sortType: 'asc' })
                              }
                            >
                              <TriangleIcon />
                            </SortButton>
                          </SortButtonContainer>
                        </th>
                      )
                    )}
                  </>
                )}
                {type === 'highscore' && (
                  <>
                    <th scope="col" colSpan={3} />
                    {HIGH_SCORE_THEAD.map(({ gradeType, icon }) => {
                      return (
                        <th scope="col" key={gradeType}>
                          <span className="ratings-category">
                            <span className="grade">
                              <img src={icon} alt={`Grade ${gradeType}`} />
                            </span>
                          </span>
                          <SortButtonContainer>
                            <SortButton
                              active={
                                sortDetail?.type === gradeType &&
                                sortDetail?.sortType === 'desc'
                              }
                              onClick={() =>
                                sortClick({
                                  type: gradeType,
                                  sortType: 'desc'
                                })
                              }
                            >
                              <TriangleIcon />
                            </SortButton>
                            <SortButton
                              type="asc"
                              active={
                                sortDetail?.type === gradeType &&
                                sortDetail?.sortType === 'asc'
                              }
                              onClick={() =>
                                sortClick({ type: gradeType, sortType: 'asc' })
                              }
                            >
                              <TriangleIcon />
                            </SortButton>
                          </SortButtonContainer>
                        </th>
                      );
                    })}
                  </>
                )}
              </tr>
            </thead>
            <tbody className="table-bordered ">
              {type === 'proficiency' && (
                <>
                  {data &&
                    data.map(item => (
                      <tr className="skill-row" key={item.SkillId}>
                        <td
                          className="skill-id"
                          onClick={() => handleClick(item)}
                        >
                          {t(
                            `proficiency-report:studentReportSide.skillNumber`,
                            { number: item.Display },
                            `Skill ${item.Display}`
                          )}
                        </td>
                        <td
                          className="skill"
                          onClick={() => handleClick(item)}
                          title={item.SkillName}
                        >
                          {item.SkillName}
                        </td>
                        <td className="progressbar">
                          <ProgressBar
                            height="16px"
                            visualParts={progressbarNumber(item)}
                          />
                          <p>
                            <strong>{item.CompetencyDetails.Mastered}</strong>{' '}
                            {`/${item.TotalNoOfStudents} ${
                              item.TotalNoOfStudents >= 2
                                ? t(
                                    `proficiency-report:classReport.students`,
                                    'students'
                                  )
                                : t(
                                    `proficiency-report:classReport.student`,
                                    'student'
                                  )
                            } ${t(
                              `proficiency-report:classReport.mastery`,
                              'Mastery'
                            )}`}
                          </p>
                        </td>
                        <td className="mastery custom-td">
                          <strong>{item.CompetencyDetails.Mastered}</strong>
                        </td>
                        <td className="competent custom-td">
                          <strong>{item.CompetencyDetails.Passed}</strong>
                        </td>
                        <td className="beginning custom-td">
                          <strong>{item.CompetencyDetails.NeedImprove}</strong>
                        </td>
                        <td className="incomplete custom-td">
                          <strong>{item.CompetencyDetails.Incomplete}</strong>
                        </td>
                      </tr>
                    ))}
                </>
              )}
              {type === 'highscore' && (
                <>
                  {data &&
                    data.map(item => (
                      <tr className="skill-row" key={item.SkillId}>
                        <td
                          className="skill-id"
                          onClick={() => handleClick(item)}
                        >
                          {t(
                            `proficiency-report:studentReportSide.skillNumber`,
                            { number: item.Display },
                            `Skill ${item.Display}`
                          )}
                        </td>
                        <td
                          className="skill"
                          onClick={() => handleClick(item)}
                          title={item.SkillName}
                        >
                          {item.SkillName}
                        </td>
                        <td className="progressbar">
                          <ProgressBar
                            height="16px"
                            visualParts={parseHighScoreProgressBar(item)}
                          />
                          <p>
                            <strong>{item.MasteryLevels.A}</strong>{' '}
                            {`/${item.TotalNoOfUsers} ${
                              item.TotalNoOfUsers >= 2
                                ? t(
                                    `proficiency-report:classReport.students`,
                                    'students'
                                  )
                                : t(
                                    `proficiency-report:classReport.student`,
                                    'student'
                                  )
                            } ${t(`high-score-report:legend.AStar`, 'A Star')}`}
                          </p>
                        </td>
                        <td className="custom-td">
                          <strong>{item.MasteryLevels.A}</strong>{' '}
                          {t(`proficiency-report:classReport.stu`, 'stu')}
                        </td>
                        <td className="custom-td">
                          <strong>{item.MasteryLevels.B}</strong>{' '}
                          {t(`proficiency-report:classReport.stu`, 'stu')}
                        </td>
                        <td className="custom-td">
                          <strong>{item.MasteryLevels.C}</strong>{' '}
                          {t(`proficiency-report:classReport.stu`, 'stu')}
                        </td>
                        <td className="custom-td">
                          <strong>{item.MasteryLevels.D}</strong>{' '}
                          {t(`proficiency-report:classReport.stu`, 'stu')}
                        </td>
                        <td className="custom-td">
                          <strong>{item.MasteryLevels.Incomplete}</strong>{' '}
                          {t(`proficiency-report:classReport.stu`, 'stu')}
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </tbody>
          </table>
        )}
      </StudentTable>
    </>
  );
}
