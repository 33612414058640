import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import noVideo from 'assets/img/no-videos-found.svg';
import { NoVideoStyle } from './NoVideo.styles';

const NoVideoFound = () => {
  const { t } = useTranslation(['browse-content']);
  return (
    <NoVideoStyle>
      <img src={noVideo} alt="no-video-found" />
      <div className="no-vid-title">
        {t(
          `browse-content:main.noVideoTitle`,
          'No video tutorials found for this topic'
        )}
      </div>
      <div>
        {t(
          `browse-content:main.noVideoDesc`,
          'Browse other topics to watch more video tutorials.'
        )}
      </div>
    </NoVideoStyle>
  );
};

export default memo(NoVideoFound);
