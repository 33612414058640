import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import report from 'assets/img/icon-manage-class-report.svg';
import edit from 'assets/img/icon-manage-class-edit.svg';
import student from 'assets/img/icon-manage-class-student.svg';
import { LevelColors } from 'constants/index';
import { CardContainer, CardTopSection } from './ClassCard.styles';

export default function ClassCard({ showModal, card, setSelectedClass }) {
  const history = useHistory();
  return (
    <CardContainer className="class-list__container__card" level={card.LevelId}>
      <CardTopSection>
        <div className="class-list__container__card__top">
          <div
            className="class-list__container__card__top__img"
            onClick={() => {
              history.push(`/manage-class/${card.ID}`);
            }}
            style={{
              background: LevelColors[card.LevelId],
              cursor: 'pointer'
            }}
          >
            {card.IconUrl !== 'No Path found' ? (
              <img src={card.IconUrl} alt="class-icon" />
            ) : (
              <h1>{card.Name.slice(0, 1).toUpperCase()}</h1>
            )}
          </div>
        </div>
        <div className="class-list__container__card__name">
          <p>{card.Name}</p>
        </div>
      </CardTopSection>
      <div className="class-list__container__card__button card-footer">
        <img
          src={report}
          alt="report"
          className="class-list__container__card__button__icon"
        />
        <img
          src={edit}
          alt="report"
          className="class-list__container__card__button__icon"
          onClick={() => {
            setSelectedClass(card);
            showModal(true);
          }}
          style={{
            cursor: 'pointer'
          }}
        />
        <Link to={`/manage-class/${card.ID}`}>
          <img
            src={student}
            alt="report"
            className="class-list__container__card__button__icon"
          />
        </Link>
      </div>
    </CardContainer>
  );
}

// rgba(255, 203, 81, 1);
// rgba(255, 230, 146, 0.74);
