import apiClient from 'services/api';

const handleResponseObjectSuccess = result => {
  if (result.data?.IsSuccessful === false) {
    return {
      error: result.data?.Message?.Message ?? 'Fetch question failed' // eslint-disable-line
    };
  }
  return result.data?.Result ?? {};
};

const handleResponseSuccess = result => {
  if (result.data?.IsSuccessful === false) {
    return {
      error: result.data?.Message?.Message ?? 'Fetch questions failed' // eslint-disable-line
    };
  }
  return result.data?.Result ?? [];
};

/**
 * Fetch question by ID
 */
export const fetchQuestionByID = questionID => {
  const options = {
    params: {
      includeAnswer: true,
      includeSolution: true
    }
  };
  return apiClient
    .get(`Question/GetById/${questionID}`, options)
    .then(handleResponseObjectSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch question failed' // eslint-disable-line
      };
    });
};

/**
 * Fetch questions by skill id
 */
export default SIOID => {
  const options = {
    params: {
      includeAnswer: true,
      includeSolution: true
    }
  };
  return apiClient
    .get(`NewQuestion/GetAllBySkillId/${SIOID}`, options)
    .then(result => {
      return result.data?.Result ?? [];
    })
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Questions fetch failed' // eslint-disable-line
      };
    });
};

/**
 * Search question by ID
 */
export const searchQuestionByIDs = questionIDs => {
  const options = {
    params: {
      includeAnswer: true,
      includeSolution: true
    }
  };
  return apiClient
    .get(`Question/ValidateQuestions/${questionIDs}`, options)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Search question failed' // eslint-disable-line
      };
    });
};
