import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { fetchStudentDetailsRequest } from 'stores/manage-class/actions';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { compareValues } from 'helpers/singleClassReport';
import SchoolPlanIcon from 'assets/img/icon-manage-class-student-list-plan-school.svg';
import StandardPlanIcon from 'assets/img/icon-manage-class-student-list-plan-standard.svg';
import PremiumPlanIcon from 'assets/img/icon-manage-class-student-list-plan-premium.svg';
import LinkedIcon from 'assets/img/icon-manage-class-linked-active.svg';
import avatarPlaceholder from 'assets/img/avatar.png';
import Spinner from 'components/Shared/Spinner';
import { StudentDetailsModalContainer } from './StudentDetailsModalBody.styles';

// Helper to parse parent/s details
const parseParents = data => {
  const parentTypes = {
    0: 'Unlinked',
    1: 'Father',
    2: 'Mother',
    3: 'Others'
  };
  const result = [];
  // Check for respective parent details
  for (const parent of data) {
    if (
      parent.ParentName !== null ||
      parent.ParentEmail !== null ||
      parent.PhoneNumber !== null
    ) {
      result.push({
        type:
          parent.RelationshipType !== 3 && parent.RelationshipType !== 0
            ? parentTypes[parent.RelationshipType]
            : '',
        order: parent.RelationshipType === 0 ? 4 : parent.RelationshipType,
        name: parent.ParentName,
        email: parent.ParentEmail,
        phone: parent.PhoneNumber,
        hasLinked: parent.RelationshipType !== 0
      });
    }
  }
  return result.sort(compareValues('order', 'asc'));
};

const getPlanIcon = planID => {
  switch (planID) {
    case 0:
      return SchoolPlanIcon;
    case 2:
      return StandardPlanIcon;
    case 3:
      return PremiumPlanIcon;
    case 5:
      return PremiumPlanIcon;
    case 6:
      return PremiumPlanIcon;
    default:
      return null;
  }
};

export default function StudentDetailsModalBody({ hide, studentID }) {
  const { t } = useTranslation(['manage-class']);
  const dispatch = useDispatch();
  const { studentDetails, isStudentDetailsLoading } = useSelector(
    state => state.manageClasses
  );
  let parents;
  if (
    studentDetails.hasOwnProperty('Parents') &&
    studentDetails.Parents.length
  ) {
    parents = parseParents(studentDetails.Parents);
  } else {
    parents = [];
  }
  useEffect(() => {
    dispatch(
      fetchStudentDetailsRequest({
        studentID
      })
    );
  }, [dispatch, studentID]);
  return (
    <StudentDetailsModalContainer>
      {isStudentDetailsLoading && <Spinner top="0px" />}
      {!isStudentDetailsLoading && studentDetails.hasOwnProperty('UserId') && (
        <>
          <div className="student-details-section">
            <div className="student-details-name">
              <img
                onError={e => {
                  e.target.src = avatarPlaceholder;
                }}
                src={studentDetails.ProfileImage}
                alt="Student Profile"
                width="40px"
                height="40px"
                style={{
                  borderRadius: '50%'
                }}
              />
              <span>
                <strong>{studentDetails.DisplayName}</strong>
              </span>
            </div>
            <div className="student-details-row">
              <div className="student-details-field">
                <span className="placeholder-title group-1">
                  {t(`manage-class:searchResult.loginId`, 'Login ID')}
                </span>
                <span className="field-group-1">{studentDetails.UserName}</span>
              </div>
              <div className="student-details-field">
                <span className="placeholder-title group-2">
                  {t(`manage-class:searchResult.password`, 'Password')}
                </span>
                <span className="field-group-2">
                  {studentDetails.password === null
                    ? 'N.A'
                    : studentDetails.password}
                </span>
              </div>
              <div className="student-details-field">
                <span className="placeholder-title group-3">
                  {t(
                    `manage-class:studModal.accActiveDate`,
                    'Account Activation Date'
                  )}
                </span>
                <span>
                  {studentDetails.ActivationDate === null
                    ? 'N.A'
                    : moment(studentDetails.ActivationDate).format(
                        'DD/MM/YYYY'
                      )}
                </span>
              </div>
            </div>
            <div
              className="student-details-row"
              style={{ marginBottom: '0.5rem' }}
            >
              <div className="student-details-field">
                <span className="placeholder-title group-1">
                  {t(`manage-class:studModal.class`, 'Class')}
                </span>
                <span className="field-group-1">
                  {studentDetails.ClassName}
                </span>
              </div>
              <div className="student-details-field">
                <span className="placeholder-title group-2">
                  {t(`manage-class:studModal.subPlan`, 'Subscription Plan')}
                </span>
                <span className="field-group-2">
                  {getPlanIcon(studentDetails.PlanId) !== null && (
                    <img
                      src={getPlanIcon(studentDetails.PlanId)}
                      alt="Plan Icon"
                    />
                  )}
                  {studentDetails.PlanName}
                </span>
              </div>
              {studentDetails.ValidStartDate !== null &&
              studentDetails.ValidEndDate !== null ? (
                <div className="student-details-field">
                  <span className="placeholder-title group-3">
                    {t(`manage-class:studModal.validPeriod`, 'Valid Period')}
                  </span>
                  <span>{`${moment(studentDetails.ValidStartDate).format(
                    'DD/MM/YYYY'
                  )} - ${moment(studentDetails.ValidEndDate).format(
                    'DD/MM/YYYY'
                  )}`}</span>
                </div>
              ) : (
                <div className="student-details-field">
                  <span className="placeholder-title group-3">
                    {t(`manage-class:studModal.validPeriod`, 'Valid Period')}
                  </span>

                  <span>N.A</span>
                </div>
              )}
            </div>
          </div>
          {parents !== null &&
            parents.map(parent => (
              <div className="parent-section" key={parent.id}>
                <div className="parent-details-row">
                  <div>
                    <span className="placeholder-title">
                      {parent.name === null ? (
                        <span>N.A</span>
                      ) : (
                        <span>
                          {parent.type} {t(`manage-class:search.name`, 'Name')}
                        </span>
                      )}
                    </span>
                    <span>{parent.name}</span>
                  </div>
                </div>
                <div className="parent-details-row">
                  <div className="parent-details-field">
                    <span className="placeholder-title">PH</span>
                    {parent.phone === null || parent.phone.length === 0 ? (
                      <span>N.A</span>
                    ) : (
                      <span>{parent.phone}</span>
                    )}
                  </div>
                  <div className="parent-details-field">
                    <span className="placeholder-title">
                      {t(`manage-class:studModal.email`, 'Email')}
                    </span>
                    {parent.email === null ? (
                      <span>N.A</span>
                    ) : (
                      <span className="parent-detail-email">
                        {parent.email}
                      </span>
                    )}
                  </div>
                  {parent.hasLinked && (
                    <div className="parent-details-field">
                      <img src={LinkedIcon} alt="Linked Icon" />
                      <span style={{ marginLeft: '0.5rem', width: '153px' }}>
                        {t(
                          `manage-class:studModal.linkedParent`,
                          'Linked with Parent APP'
                        )}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          <div className="selection-action-buttons">
            <Button
              variant="ok"
              onClick={() => {
                hide();
              }}
            >
              {t(`manage-class:studModal.ok`, 'OK')}
            </Button>
          </div>
        </>
      )}
    </StudentDetailsModalContainer>
  );
}
