import {
  FETCH_CLASSES_REQUEST,
  FETCH_CLASSES_SUCCESS,
  FETCH_CLASSES_FAILED,
  FETCH_STUDENTS_DATA_REQUEST,
  FETCH_STUDENTS_CLASS_SUCCESS,
  FETCH_STUDENTS_FAILED,
  FETCH_STUDENT_DETAILS_REQUEST,
  FETCH_STUDENT_DETAILS_SUCCESS,
  FETCH_STUDENT_DETAILS_FAILED,
  EDIT_CLASS_REQUEST,
  EDIT_CLASS_SUCCESS,
  EDIT_CLASS_FAILED,
  CHANGE_CLASS_REQUEST,
  CHANGE_CLASS_SUCCESS,
  CHANGE_CLASS_FAILED,
  SEARCH_STUDENT_REQUEST,
  SEARCH_STUDENT_SUCCESS,
  SEARCH_STUDENT_FAILED,
  SET_SEARCH_PAGES,
  SET_STUDENT_PAGES,
  RESET_PW_REQUEST,
  RESET_PW_SUCCESS,
  RESET_PW_FAILED,
  FETCH_ICONS_REQUEST,
  FETCH_ICONS_SUCCESS,
  FETCH_ICONS_FAILED,
  RESET_STUDENTS,
  RESET_SEARCH_RESULTS,
  RESET_PW_RESULT,
  RESET_CHANGE_CLASS_RESULT,
  RESET_EDIT_CLASS_RESULT,
  RESET_ERROR
} from './actionTypes';

const initialState = {
  isLoading: false,
  isIconsLoading: false,
  isStudentDetailsLoading: false,
  classes: null,
  students: [],
  searchResults: [],
  maxStudentPages: 1,
  maxSearchPages: 1,
  studentDetails: {},
  resetPwResult: null,
  changeClassResult: null,
  editClassResult: null,
  icons: [],
  iconsLevel: null
};

export default function manageClasses(state = initialState, action) {
  switch (action.type) {
    case FETCH_CLASSES_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_CLASSES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        classes: action.classes
      };
    case FETCH_CLASSES_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case FETCH_STUDENTS_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_STUDENTS_CLASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        students: action.students
      };
    case FETCH_STUDENTS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case FETCH_STUDENT_DETAILS_REQUEST:
      return {
        ...state,
        isStudentDetailsLoading: true
      };
    case FETCH_STUDENT_DETAILS_SUCCESS:
      return {
        ...state,
        isStudentDetailsLoading: false,
        studentDetails: action.details
      };
    case FETCH_STUDENT_DETAILS_FAILED:
      return {
        ...state,
        isStudentDetailsLoading: false,
        error: action.error
      };
    case EDIT_CLASS_REQUEST:
      return {
        ...state,
        isLoading: true,
        editClassResult: false
      };
    case EDIT_CLASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        editClassResult: true
      };
    case EDIT_CLASS_FAILED:
      return {
        ...state,
        isLoading: false,
        editClassResult: false,
        error: action.error
      };
    case CHANGE_CLASS_REQUEST:
      return {
        ...state,
        isLoading: true,
        changeClassResult: false
      };
    case CHANGE_CLASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        changeClassResult: true
      };
    case CHANGE_CLASS_FAILED:
      return {
        ...state,
        isLoading: false,
        changeClassResult: false,
        error: action.error
      };
    case SEARCH_STUDENT_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case SEARCH_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        searchResults: action.results
      };
    case SEARCH_STUDENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };

    case RESET_PW_REQUEST:
      return {
        ...state,
        isLoading: true,
        resetPwResult: false
      };
    case RESET_PW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        resetPwResult: true
      };
    case RESET_PW_FAILED:
      return {
        ...state,
        isLoading: false,
        resetPwResult: false,
        error: action.error
      };
    case FETCH_ICONS_REQUEST:
      return {
        ...state,
        isIconsLoading: true
      };
    case FETCH_ICONS_SUCCESS:
      return {
        ...state,
        isIconsLoading: false,
        icons: action.result.icons,
        iconsLevel: action.result.level
      };
    case FETCH_ICONS_FAILED:
      return {
        ...state,
        isIconsLoading: false,
        error: action.error
      };
    case RESET_STUDENTS:
      return {
        ...state,
        students: []
      };
    case RESET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: []
      };
    case SET_STUDENT_PAGES:
      return {
        ...state,
        maxStudentPages: action.pageTotal
      };
    case SET_SEARCH_PAGES:
      return {
        ...state,
        maxSearchPages: action.pageTotal
      };
    case RESET_PW_RESULT:
      return {
        ...state,
        resetPwResult: null
      };
    case RESET_EDIT_CLASS_RESULT:
      return {
        ...state,
        editClassResult: null
      };
    case RESET_CHANGE_CLASS_RESULT:
      return {
        ...state,
        changeClassResult: null
      };
    case RESET_ERROR:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
}
