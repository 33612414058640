export default (str, showImage = true) => {
  let htmlText = str;
  // "Content" tag >> to "div" tag
  htmlText = htmlText.replace(/(<\/?)Content([^>]*>)/gi, '$1div$2');

  // removing "CDATA" opening and closing tags
  htmlText = htmlText.replace(/<!\[CDATA\[|\]\]>/g, '');

  // converting "/>" tag-ending to a ">" tag-ending in order to avoid detecting "/" as a divide sign
  htmlText = htmlText.replace(/\/>/g, '>');

  // converting xml-img to html-img
  htmlText = htmlText.replace(
    /(<img[^<>]*)\surl=([^<>]*>)/g,
    `$1 style="max-width:100%;height:auto;${
      !showImage ? 'display:none;' : '' // To hide image, when we want to move the position
    }" src=$2`
  );

  // converting "helptext" tags to "div" tags with class "help-text"
  while (htmlText.match(/<helptext>(((?!helptext>).)+)<\/helptext>/g))
    htmlText = htmlText.replace(
      /<helptext>(((?!helptext>).)+)<\/helptext>/g,
      '<span style="color:#008bbe" class="help-text">$1</span>'
    );

  // html parts for fractions
  const fractionHtmlStart =
    '<span style="display:inline-block;margin:0 0.25em" class="fraction"><span style="display:block;border-bottom:1px solid black;padding:0.25em" class="numerator">';
  const fractionHtmlMiddle =
    '</span><span style="display:block;padding:0.25em" class="denominator">';
  const fractionHtmlEnd = '</span></span>';

  // converting "fraction" tags to html code with horizontal line
  while (
    htmlText.match(
      /\[fraction\](((?!fracline\]).)+)\/(((?!fracline\]).)+)\[\/fraction\]/g
    )
  )
    htmlText = htmlText.replace(
      /\[fraction\](((?!fracline\]).)+)\/(((?!fracline\]).)+)\[\/fraction\]/g,
      '[fraction]$1[fracline]$3[/fraction]'
    );
  while (
    htmlText.match(
      /\[fraction\](((?!fraction\]).)*)&(((?!fraction\]).)*)\[fracline\]/g
    )
  )
    htmlText = htmlText.replace(
      /\[fraction\](((?!fraction\]).)*)&(((?!fraction\]).)*)\[fracline\]/g,
      '$1[fraction]$3[fracline]'
    );
  while (
    htmlText.match(
      /\[fraction\](((?!fraction\]).)*)\[fracline\](((?!fraction\]).)*)\[\/fraction\]/g
    )
  )
    htmlText = htmlText.replace(
      /\[fraction\](((?!fraction\]).)*)\[fracline\](((?!fraction\]).)*)\[\/fraction\]/g,
      `${fractionHtmlStart}$1${fractionHtmlMiddle}$3${fractionHtmlEnd}`
    );
  // removing unused "fraction" tags
  htmlText = htmlText.replace(/\[fraction\]|\[fracline\]|\[\/fraction\]/g, '');

  // EQN
  htmlText = htmlText.replace(/EQN\(/g, '[eqntag]');
  while (htmlText.match(/\(([^)(]*)\)/g))
    htmlText = htmlText.replace(/\(([^)(]*)\)/g, '&#40;$1&#41;');
  htmlText = htmlText.replace(
    /\[eqntag\](((?!fraction\]).)*)\[fracline\](((?!fraction\]).)*)\)/g,
    `${fractionHtmlStart}$1${fractionHtmlMiddle}$3${fractionHtmlEnd}`
  );
  htmlText = htmlText.replace(
    /\[eqntag\](((?!fraction\]).)*)\/(((?!fraction\]).)*)\)/g,
    `${fractionHtmlStart}$1${fractionHtmlMiddle}$3${fractionHtmlEnd}`
  );
  // removing unused "EQN" tags
  htmlText = htmlText.replace(/\[eqntag\](((?!eqntag\]).)*)\)/g, '$1');

  // replacing simple tags
  htmlText = htmlText.replace(/\n/g, '<br/>');
  htmlText = htmlText.replace(/\[angle\]/g, '&ang;');
  htmlText = htmlText.replace(/\[div\]/g, '&#247;');
  htmlText = htmlText.replace(/\[deg\]/g, '&deg;');
  htmlText = htmlText.replace(/\[quote\]/g, '&#34;');
  htmlText = htmlText.replace(/\[cent\]/g, '&cent;');
  htmlText = htmlText.replace(/\[arrow\]/g, '&#8594;');
  htmlText = htmlText.replace(/\[triangle\]/g, '&utri;');
  htmlText = htmlText.replace(/\[approx\]/g, '&#8764;');
  htmlText = htmlText.replace(/\[square\]/g, '<sup>2</sup>');
  htmlText = htmlText.replace(/\[cube\]/g, '<sup>3</sup>');
  htmlText = htmlText.replace(/\[power4\]/g, '<sup>4</sup>');
  htmlText = htmlText.replace(/\[power5\]/g, '<sup>5</sup>');

  // converting "blank#" tag
  htmlText = htmlText.replace(/\[blank\]/g, '&#95;&#95;');
  htmlText = htmlText.replace(/\[blank1\]/g, '&#95;&#95;');
  while (htmlText.match(/\[blank(\d+)\]/)) {
    htmlText = htmlText.replace(/\[blank(\d+)\]/, (match, numOfUnders) => {
      let currentNumOfUnders = parseInt(numOfUnders); // eslint-disable-line
      if (currentNumOfUnders <= 0) return '';
      currentNumOfUnders--; // eslint-disable-line
      return `&#95;[blank${currentNumOfUnders}]`;
    });
  }

  return htmlText;
};
