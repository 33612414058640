import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  fetchAssignmentsRequest,
  fetchTeacherAssignmentsRequest,
  fetchTeachersRequest,
  setAssignmentSummaryHistory,
  setSelectedTeacher,
  resetDeleteHwResult,
  changeTitleDateReset
} from 'stores/assignments/actions';
import AssignmentCard from 'components/TrackAssignment/AssignmentCard';
import Pagination from 'components/TrackAssignment/Pagination';
import EmptyAssignment from 'components/TrackAssignment/EmptyAssignment';
import assignmentIcon from 'assets/img/icon-track-assignment-summary.svg';
import Spinner from 'components/Shared/Spinner';
import DropdownMenu from 'components/Shared/DropdownMenu';
import PopupModal from 'components/Shared/PopupModal';
import CustomModal from 'components/TrackAssignment/CustomModal';
import DeleteModalBody from 'components/TrackAssignment/AssignmentCardDeleteModalBody';
import ChangeTitleDateModal from 'components/TrackAssignment/ChangeTitleDateModal';

import { AssignmentSummaryPage } from './AssignmentSummary.styles';

export default function AssignmentSummary({ isOthers, match }) {
  const { t } = useTranslation(['track-assignment-assigned-me']);
  const teacherID = isOthers ? parseInt(match.params.teacherID) : null;
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux States
  const {
    assignments,
    isLoading,
    years,
    teachers,
    deleteHwResult,
    latestYearWithAssignments,
    changeTitleDate
  } = useSelector(state => state.assignments);
  // States
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [selectedHomeworkID, setSelectedHomeworkID] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showChangeTitleModal, setShowChangeTitleModal] = useState(false);
  const [changeData, setChangeData] = useState({});
  const hideDeleteModal = () => {
    if (deleteHwResult) {
      dispatch(resetDeleteHwResult());
      dispatch(
        fetchAssignmentsRequest({
          date: selectedYear !== null ? `${selectedYear}` : null,
          pageSize: 20,
          pageIndex: currentPage
        })
      );
    }
    setShowDeleteModal(false);
  };
  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const openChangeTitleModal = data => {
    setShowChangeTitleModal(true);
    setChangeData(data);
  };

  const closeChangeTitleModal = () => {
    if (changeTitleDate) {
      dispatch(changeTitleDateReset());
      dispatch(
        fetchAssignmentsRequest({
          date: selectedYear !== null ? `${selectedYear}` : null,
          pageSize: 20,
          pageIndex: currentPage
        })
      );
    }
    setShowChangeTitleModal(false);
  };

  // Dropdown item click handler
  const handleSelectedTeacher = teacher => {
    history.replace(`/track-assignment/others/${teacher.ID}`);
  };
  // Fetch list of teachers
  useEffect(() => {
    if (isOthers === true) {
      dispatch(fetchTeachersRequest());
    }
  }, [dispatch, isOthers]);
  // Fetch assignment data
  useEffect(() => {
    if (isOthers === true) {
      dispatch(setAssignmentSummaryHistory('others'));
      dispatch(setSelectedTeacher(teacherID));
      dispatch(
        fetchTeacherAssignmentsRequest({
          teacherID,
          pageSize: 20,
          pageIndex: currentPage,
          year: selectedYear
        })
      );
    } else {
      dispatch(setAssignmentSummaryHistory('me'));
      dispatch(
        fetchAssignmentsRequest({
          date: selectedYear !== null ? `${selectedYear}` : null,
          pageSize: 20,
          pageIndex: currentPage
        })
      );
    }
  }, [currentPage, dispatch, isOthers, selectedYear, teacherID]);

  return (
    <AssignmentSummaryPage isOthers={isOthers}>
      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          <div className="summary-header">
            <span className="title">
              <img
                src={assignmentIcon}
                alt="Track Assignment icon"
                className="title-icon"
              />
              {t(
                `track-assignment-assigned-me:main.trackAssign`,
                'Track Assignment'
              )}
            </span>
            <span className="year-selector">
              {isOthers && (
                <>
                  <div className="dropdown-container">
                    {t(
                      `track-assignment-assigned-me:main.assignBy`,
                      'Assigned by:'
                    )}
                    <DropdownMenu
                      selectedValue={
                        teachers.length > 0 &&
                        teachers.find(teacher => teacher.ID === teacherID)
                          .FullName
                      }
                      values={teachers}
                      valueKey="FullName"
                      setValue={handleSelectedTeacher}
                    />
                  </div>
                  <div className="dropdown-container">
                    {t(
                      `track-assignment-assigned-me:main.assignByMeIn`,
                      'Assigned by me in:'
                    )}
                    <DropdownMenu
                      selectedValue={
                        selectedYear !== null
                          ? selectedYear
                          : latestYearWithAssignments !== null
                          ? latestYearWithAssignments
                          : null
                      }
                      values={years}
                      valueKey={null}
                      setValue={setSelectedYear}
                    />
                  </div>
                </>
              )}
              {!isOthers && (
                <div className="dropdown-container">
                  {t(
                    `track-assignment-assigned-me:main.assignByMeIn`,
                    'Assigned by me in:'
                  )}
                  <DropdownMenu
                    selectedValue={
                      selectedYear !== null
                        ? selectedYear
                        : latestYearWithAssignments !== null
                        ? latestYearWithAssignments
                        : null
                    }
                    values={years}
                    valueKey={null}
                    setValue={setSelectedYear}
                  />
                </div>
              )}
            </span>
          </div>
          <PopupModal show={showDeleteModal} hide={hideDeleteModal}>
            <DeleteModalBody
              hide={hideDeleteModal}
              homeworkID={selectedHomeworkID}
            />
          </PopupModal>
          {showChangeTitleModal && (
            <CustomModal hide={closeChangeTitleModal}>
              <ChangeTitleDateModal
                hide={closeChangeTitleModal}
                data={changeData}
              />
            </CustomModal>
          )}
          {assignments.length > 0 ? (
            <>
              {selectedYear !== null
                ? assignments
                    .slice(0, 20)
                    .map(assignment => (
                      <AssignmentCard
                        key={assignment.HomeWorkId}
                        homeworkID={assignment.HomeWorkId}
                        homeworkName={assignment.HomeWorkTitle}
                        homeworkType={assignment.HomeWorkType}
                        homeworkStatus={assignment.HomeWorkStatus}
                        visibleDate={assignment.VisibleDate}
                        homeworkQnCount={assignment.TotalQuestion}
                        classes={assignment.TrackingGroup}
                        individuals={assignment.TrackingIndividual}
                        assignedDate={assignment.AssignedDate}
                        startDate={assignment.StartDate}
                        deadline={assignment.Deadline}
                        isOthers={isOthers}
                        openDeleteModal={openDeleteModal}
                        setSelectedHomeworkID={setSelectedHomeworkID}
                        assignment={assignment}
                        openChangeTitleModal={openChangeTitleModal}
                      />
                    ))
                : assignments
                    .filter(
                      assignment =>
                        moment(assignment.AssignedDate).year() ===
                        moment(assignments[0].AssignedDate).year()
                    )
                    .slice(0, 20)
                    .map(assignment => (
                      <AssignmentCard
                        key={assignment.HomeWorkId}
                        homeworkID={assignment.HomeWorkId}
                        homeworkName={assignment.HomeWorkTitle}
                        homeworkType={assignment.HomeWorkType}
                        homeworkStatus={assignment.HomeWorkStatus}
                        visibleDate={assignment.VisibleDate}
                        homeworkQnCount={assignment.TotalQuestion}
                        classes={assignment.TrackingGroup}
                        individuals={assignment.TrackingIndividual}
                        assignedDate={assignment.AssignedDate}
                        startDate={assignment.StartDate}
                        deadline={assignment.Deadline}
                        isOthers={isOthers}
                        openDeleteModal={openDeleteModal}
                        setSelectedHomeworkID={setSelectedHomeworkID}
                        assignment={assignment}
                        openChangeTitleModal={openChangeTitleModal}
                      />
                    ))}
              <Pagination currentPage={currentPage} setPage={setCurrentPage} />
            </>
          ) : (
            <EmptyAssignment />
          )}
        </>
      )}
    </AssignmentSummaryPage>
  );
}
