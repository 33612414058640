import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 60px);
  overflow: hidden;
  padding-left: 195px;
  @media (max-width: 1024px) {
    padding-left: 3.5rem;
  }
`;

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1065px;
  background-color: #fff;
  padding: 15px 1px 0 1px;
  margin: 60px auto 0;
  font-family: 'Muli', sans-serif;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.07);
  color: rgba(52, 52, 52, 0.8);
  div {
    display: flex;
    flex: 1;
  }

  .back-button-wrap {
    padding-left: 12px;
  }

  .title-wrap {
    align-items: center;
    .title-container {
      display: flex;
      flex-direction: column;
      flex: 2;
      .title {
        justify-content: center;
        align-items: center;
        h5 {
          margin-bottom: 0;
        }
        img {
          margin-right: 10px;
          height: 31px;
          width: 31px;
        }
      }
      .desc {
        text-align: center;
        font-size: 1rem;
        margin: 1.5rem 0 1.5rem;
        text-indent: 1.8rem;
      }
    }
  }
`;

export const ContentBox = styled.div`
  max-width: 1065px;
  border-top: 1px solid rgba(112, 112, 112, 0.21);
  margin-left: auto;
  margin-right: auto;
  height: 80%;
`;
