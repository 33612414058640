export const FETCH_CLASS_FAILED = 'FETCH_CLASS_FAILED';
export const FETCH_CLASS_REQUEST = 'FETCH_CLASS_REQUEST';
export const FETCH_CLASS_SUCCESS = 'FETCH_CLASS_SUCCESS';

export const FETCH_STUDENTS_FAILED = 'FETCH_STUDENTS_FAILED';
export const FETCH_STUDENTS_REQUEST = 'FETCH_STUDENTS_REQUEST';
export const FETCH_STUDENTS_SEARCH_REQUEST = 'FETCH_STUDENTS_SEARCH_REQUEST';
export const FETCH_STUDENTS_SUCCESS = 'FETCH_STUDENTS_SUCCESS';

export const FETCH_MY_GROUP_REQUEST = 'FETCH_MY_GROUP_REQUEST';
export const FETCH_MY_GROUP_SUCCESS = 'FETCH_MY_GROUP_SUCCESS';
export const FETCH_MY_GROUP_FAILED = 'FETCH_MY_GROUP_FAILED';
