import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Avatar from 'assets/img/avatar.png';
import GradeAIcon from 'assets/img/icon-report-high-score-a.svg';
import GradeBIcon from 'assets/img/icon-report-high-score-b.svg';
import GradeCIcon from 'assets/img/icon-report-high-score-c.svg';
import GradeDIcon from 'assets/img/icon-report-high-score-d.svg';
import { ReactComponent as TriangleIcon } from 'assets/img/report/icon-triangle.svg';
import HighScoreIncompleteIcon from 'assets/img/icon-report-high-score-incomplete.svg';
import { proficiencyColors } from 'constants/index';
import calculatePercentageString from 'helpers/calculatePercentageString';
import { toggleReport } from 'stores/report/actions';
import ProgressBar from './ProgressBar';
import {
  StudentTable,
  GradeACell,
  ProficiencySkills,
  SortButtonContainer,
  SortButton
} from './StudentTableReport.styles';

export const progressbarNumber = data => {
  const progressBarInput = [
    {
      percentage: '',
      color: proficiencyColors.mastery.color,
      borderColor: proficiencyColors.mastery.borderColor
    },
    {
      percentage: '',
      color: proficiencyColors.competent.color,
      borderColor: proficiencyColors.competent.borderColor
    },
    {
      percentage: '',
      color: proficiencyColors.beginning.color,
      borderColor: proficiencyColors.beginning.borderColor
    },
    {
      percentage: '',
      color: proficiencyColors.incomplete.color,
      borderColor: proficiencyColors.incomplete.borderColor
    }
  ];
  const proficiencyIndex = {
    Incomplete: 3,
    NeedImprove: 2,
    Passed: 1,
    Mastered: 0
  };

  for (const proficiency of Object.keys(data.CompetencyDetails)) {
    if (data.CompetencyDetails[proficiency] > 0) {
      // Calculate percentage string
      const percentageString = calculatePercentageString(
        data.CompetencyDetails[proficiency],
        data.TotalNoOfSkills
      );
      progressBarInput[
        proficiencyIndex[proficiency]
      ].percentage = percentageString;
    } else {
      progressBarInput[proficiencyIndex[proficiency]].percentage = '0%';
    }
  }
  return progressBarInput.filter(
    proficiency => proficiency.percentage !== '0%'
  );
};

const parseHighScoreProgressBar = data => {
  const progressBarInput = [
    {
      percentage: '',
      color: '#FBDA38',
      borderColor: '#BE9E00'
    },
    {
      percentage: '',
      color: '#D9ECFF',
      borderColor: '#ADBED0'
    },
    {
      percentage: '',
      color: '#EDD592',
      borderColor: '#D5AC6A'
    },
    {
      percentage: '',
      color: '#FFF4D5',
      borderColor: '#DDC6A3'
    },
    {
      percentage: '',
      color: proficiencyColors.incomplete.color,
      borderColor: proficiencyColors.incomplete.borderColor
    }
  ];
  const masteryIndex = {
    A: 0,
    B: 1,
    C: 2,
    D: 3,
    Incomplete: 4
  };
  for (const mastery of Object.keys(data.MasteryLevels)) {
    if (data.MasteryLevels[mastery] > 0) {
      // Calculate percentage string
      const percentageString = calculatePercentageString(
        data.MasteryLevels[mastery],
        data.TotalNoOfSkills
      );
      progressBarInput[masteryIndex[mastery]].percentage = percentageString;
    } else {
      progressBarInput[masteryIndex[mastery]].percentage = '0%';
    }
  }
  return progressBarInput.filter(mastery => mastery.percentage !== '0%');
};
const PROFICIENCY_THEAD = [
  {
    proficiencyType: 'mastery',
    number: `80-100`,
    title: 'mastery',
    upperCaseTitle: 'Mastery',
    content: 'proficiency',
    upperCaseContent: 'Proficiency',
    columnName: 'Mastered'
  },
  {
    proficiencyType: 'competent',
    number: `60-79`,
    title: 'competent',
    upperCaseTitle: 'Competent',
    content: 'proficiency',
    upperCaseContent: 'Proficiency',
    columnName: 'Passed'
  },
  {
    proficiencyType: 'beginning',
    number: `0-59`,
    title: 'beginning',
    upperCaseTitle: 'Beginning',
    content: 'proficiency',
    upperCaseContent: 'Proficiency',
    columnName: 'NeedImprove'
  },
  {
    proficiencyType: 'incomplete',
    number: `< 10`,
    title: 'incomplete',
    upperCaseTitle: 'Incomplete',
    content: 'question',
    upperCaseContent: 'Question',
    columnName: 'Incomplete'
  }
];
const HIGH_SCORE_THEAD = [
  {
    gradeType: 'A',
    icon: GradeAIcon,
    fraction: '9-10'
  },
  {
    gradeType: 'B',
    icon: GradeBIcon,
    fraction: '6-8'
  },
  {
    gradeType: 'C',
    icon: GradeCIcon,
    fraction: '4-5'
  },
  {
    gradeType: 'D',
    icon: GradeDIcon,
    fraction: '1-3'
  },
  {
    gradeType: 'Incomplete',
    icon: HighScoreIncompleteIcon
  }
];
export default function StudentTableReport({
  type,
  data,
  isLoading,
  sortClick,
  sortDetail
}) {
  const { t } = useTranslation(['proficiency-report']);
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <>
      <StudentTable>
        {!isLoading && (
          <table className="table table-responsive-sm">
            <thead className="table-head">
              <tr>
                {type === 'proficiency' && (
                  <>
                    <th colSpan="2" />
                    {PROFICIENCY_THEAD.map(
                      ({
                        proficiencyType,
                        number,
                        title,
                        content,
                        upperCaseTitle,
                        upperCaseContent,
                        columnName
                      }) => (
                        <th scope="col" key={proficiencyType}>
                          <ProficiencySkills proficiency={proficiencyType}>
                            <div className="skill-title">
                              <div className="icon" />
                              <span>
                                {t(
                                  `proficiency-report:legend.${title}`,
                                  upperCaseTitle
                                )}
                              </span>
                            </div>
                            <span className="prof-desc">
                              {t(
                                `proficiency-report:legend.${content}`,
                                upperCaseContent
                              )}{' '}
                              <b>{number}</b>
                            </span>
                          </ProficiencySkills>
                          <SortButtonContainer>
                            <SortButton
                              active={
                                sortDetail?.type === columnName &&
                                sortDetail?.sortType === 'desc'
                              }
                              onClick={() =>
                                sortClick({
                                  type: columnName,
                                  sortType: 'desc'
                                })
                              }
                            >
                              <TriangleIcon />
                            </SortButton>
                            <SortButton
                              type="asc"
                              active={
                                sortDetail?.type === columnName &&
                                sortDetail?.sortType === 'asc'
                              }
                              onClick={() =>
                                sortClick({ type: columnName, sortType: 'asc' })
                              }
                            >
                              <TriangleIcon />
                            </SortButton>
                          </SortButtonContainer>
                        </th>
                      )
                    )}
                  </>
                )}
                {type === 'highscore' && (
                  <>
                    <th scope="col" colSpan={2} />
                    {HIGH_SCORE_THEAD.map(({ gradeType, icon, fraction }) => {
                      return (
                        <th scope="col" key={gradeType}>
                          <span className="ratings-category">
                            <span className="grade">
                              <img src={icon} alt={`Grade ${gradeType}`} />
                              <span className="ratings-text">
                                {fraction ? (
                                  <>
                                    {fraction}
                                    <span className="sub-text">
                                      {' '}
                                      / 10
                                      {t(
                                        `proficiency-report:classReport.qnPerSkill`,
                                        ' Qn per skill'
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  <strong>
                                    {t(
                                      `proficiency-report:classReport.toBeComplete`,
                                      'To be completed'
                                    )}
                                  </strong>
                                )}
                              </span>
                            </span>
                          </span>
                          <SortButtonContainer>
                            <SortButton
                              active={
                                sortDetail?.type === gradeType &&
                                sortDetail?.sortType === 'desc'
                              }
                              onClick={() =>
                                sortClick({
                                  type: gradeType,
                                  sortType: 'desc'
                                })
                              }
                            >
                              <TriangleIcon />
                            </SortButton>
                            <SortButton
                              type="asc"
                              active={
                                sortDetail?.type === gradeType &&
                                sortDetail?.sortType === 'asc'
                              }
                              onClick={() =>
                                sortClick({ type: gradeType, sortType: 'asc' })
                              }
                            >
                              <TriangleIcon />
                            </SortButton>
                          </SortButtonContainer>
                        </th>
                      );
                    })}
                  </>
                )}
              </tr>
            </thead>
            <tbody className="table-bordered ">
              {type === 'proficiency' && (
                <>
                  {data &&
                    data.map(item => (
                      <tr className="student-row" key={item.UserId}>
                        <td
                          className="student-name"
                          onClick={() => {
                            history.push(
                              `/report/proficiency-report/${item.UserId}`
                            );
                            dispatch(toggleReport('proficiency'));
                          }}
                        >
                          <img
                            onError={e => {
                              e.target.src = Avatar;
                            }}
                            src={item.ProfilePictureUrl}
                            alt="profile"
                          />
                          <strong>{item.Fullname}</strong>
                        </td>
                        <td
                          className="progressbar"
                          onClick={() => {
                            history.push(
                              `/report/proficiency-report/${item.UserId}`
                            );
                            dispatch(toggleReport('proficiency'));
                          }}
                        >
                          <ProgressBar
                            height="16px"
                            visualParts={progressbarNumber(item)}
                          />
                          <p>
                            <strong>{item.CompetencyDetails.Mastered}</strong>{' '}
                            {`${t(
                              `proficiency-report:classReport.outOf`,
                              ' out of '
                            )} ${item.TotalNoOfSkills} ${
                              item.TotalNoOfSkills >= 2
                                ? t(
                                    `proficiency-report:classReport.skills`,
                                    'skills'
                                  )
                                : t(
                                    `proficiency-report:classReport.skill`,
                                    'skill'
                                  )
                            } ${t(
                              `proficiency-report:classReport.mastery`,
                              'mastery'
                            )}`}
                          </p>
                        </td>
                        <td className="mastery custom-td">
                          <strong>{item.CompetencyDetails.Mastered}</strong>
                          {item.CompetencyDetails.Mastered >= 2
                            ? t(
                                `proficiency-report:classReport.skills`,
                                'skills'
                              )
                            : t(
                                `proficiency-report:classReport.skill`,
                                'skill'
                              )}
                        </td>
                        <td className="competent custom-td">
                          <strong>{item.CompetencyDetails.Passed}</strong>
                          {item.CompetencyDetails.Passed >= 2
                            ? t(
                                `proficiency-report:classReport.skills`,
                                'skills'
                              )
                            : t(
                                `proficiency-report:classReport.skill`,
                                'skill'
                              )}
                        </td>
                        <td className="beginning custom-td">
                          <strong>{item.CompetencyDetails.NeedImprove}</strong>
                          {item.CompetencyDetails.NeedImprove >= 2
                            ? t(
                                `proficiency-report:classReport.skills`,
                                'skills'
                              )
                            : t(
                                `proficiency-report:classReport.skill`,
                                'skill'
                              )}
                        </td>
                        <td className="incomplete custom-td">
                          <strong>{item.CompetencyDetails.Incomplete}</strong>
                          {item.CompetencyDetails.Incomplete >= 2
                            ? t(
                                `proficiency-report:classReport.skills`,
                                'skills'
                              )
                            : t(
                                `proficiency-report:classReport.skill`,
                                'skill'
                              )}
                        </td>
                      </tr>
                    ))}
                </>
              )}
              {type === 'highscore' && (
                <>
                  {data &&
                    data.map(item => (
                      <tr className="student-row" key={item.UserId}>
                        <td
                          className="student-name"
                          onClick={() => {
                            history.push(
                              `/report/class-report/high-score-report/${item.UserId}`
                            );
                            dispatch(toggleReport('high'));
                          }}
                        >
                          <img
                            onError={e => {
                              e.target.src = Avatar;
                            }}
                            src={item.ProfilePictureUrl}
                            alt="profile"
                          />
                          <strong>{item.Fullname}</strong>
                        </td>
                        <td
                          className="progressbar"
                          onClick={() => {
                            history.push(
                              `/report/class-report/high-score-report/${item.UserId}`
                            );
                            dispatch(toggleReport('high'));
                          }}
                        >
                          <GradeACell>
                            {item.MasteryLevels.A > 0 ? (
                              <img src={GradeAIcon} alt="Grade A" />
                            ) : (
                              <img
                                src={HighScoreIncompleteIcon}
                                alt="No skills with Grade A"
                              />
                            )}
                            <div className="progress-part">
                              <p>
                                <strong>{item.MasteryLevels.A}</strong>
                                {` ${t(
                                  `proficiency-report:classReport.skills`,
                                  'skills'
                                )} ${t(
                                  `proficiency-report:classReport.outOf`,
                                  ' out of '
                                )}${item.TotalNoOfSkills} ${t(
                                  `proficiency-report:classReport.skills`,
                                  'skills'
                                )}`}
                              </p>
                              <ProgressBar
                                height="9px"
                                visualParts={parseHighScoreProgressBar(item)}
                              />
                            </div>
                          </GradeACell>
                        </td>
                        <td className="custom-td">
                          <strong>{item.MasteryLevels.A}</strong>
                          {item.MasteryLevels.A >= 2
                            ? t(
                                `proficiency-report:classReport.skills`,
                                'skills'
                              )
                            : t(
                                `proficiency-report:classReport.skill`,
                                'skill'
                              )}
                        </td>
                        <td className="custom-td">
                          <strong>{item.MasteryLevels.B}</strong>
                          {item.MasteryLevels.B >= 2
                            ? t(
                                `proficiency-report:classReport.skills`,
                                'skills'
                              )
                            : t(
                                `proficiency-report:classReport.skill`,
                                'skill'
                              )}
                        </td>
                        <td className="custom-td">
                          <strong>{item.MasteryLevels.C}</strong>
                          {item.MasteryLevels.C >= 2
                            ? t(
                                `proficiency-report:classReport.skills`,
                                'skills'
                              )
                            : t(
                                `proficiency-report:classReport.skill`,
                                'skill'
                              )}
                        </td>
                        <td className="custom-td">
                          <strong>{item.MasteryLevels.D}</strong>
                          {item.MasteryLevels.D >= 2
                            ? t(
                                `proficiency-report:classReport.skills`,
                                'skills'
                              )
                            : t(
                                `proficiency-report:classReport.skill`,
                                'skill'
                              )}
                        </td>
                        <td className="custom-td">
                          <strong>{item.MasteryLevels.Incomplete}</strong>
                          {item.MasteryLevels.Incomplete >= 2
                            ? t(
                                `proficiency-report:classReport.skills`,
                                'skills'
                              )
                            : t(
                                `proficiency-report:classReport.skill`,
                                'skill'
                              )}
                        </td>
                      </tr>
                    ))}
                </>
              )}
            </tbody>
          </table>
        )}
      </StudentTable>
    </>
  );
}
