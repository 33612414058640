import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  deleteGroupRequest,
  resetDeleteRequest
} from 'stores/createGroup/actions';
import successIcon from 'assets/img/icon-manage-class-success-check.svg';
import { Spinner } from 'react-bootstrap';
import GroupDeleteModalContnainer from './GroupDeleteModal.styles';

const GroupDeleteModal = ({ groupName, hide, groupId, deleteSuccess }) => {
  const { t } = useTranslation(['manage-class-group']);
  const dispatch = useDispatch();
  const { isDelLoading, deleteGroup, error } = useSelector(
    state => state.createGroups
  );

  const deleteGroupSuccess = () => {
    dispatch(resetDeleteRequest());
    deleteSuccess();
  };

  return (
    <GroupDeleteModalContnainer>
      {deleteGroup === true ? (
        <>
          <button
            className="close-btn"
            type="button"
            onClick={deleteGroupSuccess}
          >
            X
          </button>
          <span className="success-title">
            <img
              src={successIcon}
              alt="Success icon"
              style={{ marginRight: '0.5rem' }}
            />
            {t(`manage-class-group:deleteModal.success`, 'Success!')}
          </span>
          <span style={{ margin: '2rem 0' }}>
            "{groupName}"
            {t(
              `manage-class-group:deleteModal.delConfirmMsg`,
              ' has been deleted sucessfully!'
            )}
          </span>
          <button
            className="success-btn"
            type="button"
            onClick={deleteGroupSuccess}
          >
            {t(`manage-class-group:deleteModal.ok`, 'OK')}
          </button>
        </>
      ) : (
        <>
          <button className="close-btn" type="button" onClick={hide}>
            X
          </button>
          <div className="title">
            {t(`manage-class-group:deleteModal.delGroup`, 'Delete Group')}
          </div>
          <p className="text">
            {t(
              `manage-class-group:deleteModal.delConfirm`,
              'Are you sure you want to delete '
            )}
            "{groupName}"?
            <div style={{ textAlign: 'center' }}>
              {t(
                `manage-class-group:deleteModal.actionUndone`,
                'This action cannot be undone.'
              )}
            </div>
          </p>
          {error && <span style={{ color: '#ff5b00' }}>{error}</span>}
          <div className="btn-group">
            <button
              className="yes-btn"
              type="button"
              onClick={() => {
                dispatch(deleteGroupRequest(groupId));
              }}
            >
              {t(`manage-class-group:deleteModal.yes`, 'Yes')}
            </button>
            <button className="no-btn" type="button" onClick={hide}>
              {t(`manage-class-group:deleteModal.no`, 'No')}
              {isDelLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="ml-2"
                />
              )}
            </button>
          </div>
        </>
      )}
    </GroupDeleteModalContnainer>
  );
};

export default GroupDeleteModal;
