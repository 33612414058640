import styled from 'styled-components';

export const Container = styled.div`
  background: white;
  border-radius: 4px;
  height: 350px;
  .dropdown-container {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    .dropdown-custom {
      background: #f2f2f2;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .dropdown-label {
      width: 80px;
      padding-right: 0.5rem;
      text-align: right;
      white-space: nowrap;
    }
    .level-dropdown {
      margin: 0 1rem 0 0;
    }
    .week {
      width: 85%;
      > button {
        width: 100%;
        text-align: left;
      }
      .dropdown-menu {
        width: 100%;
      }
    }
    .class {
      width: 100%;
      > button {
        width: 100%;
        text-align: left;
      }
      .dropdown-menu {
        width: 100%;
      }
    }
  }
`;

export const ClassAttendance = styled.div`
  .indicator {
    margin-left: 1.6rem;
  }
  .num-logged-in {
    margin-left: 0.5rem;
    font-size: 1.875rem;
    font-weight: 700;
    color: black;
  }
  .class-size {
    font-size: 1rem;
    color: #9a9a9a;
    margin-right: 1rem;
  }
  .logged-in-date {
    font-size: 0.75rem;
    color: #8a8a8a;
  }
  .recharts-bar-rectangles {
    cursor: pointer;
  }
`;

export const BarContainer = styled.div`
  padding: 1rem;
  background: yellow;
`;
