import React from 'react';
import noSyllabus from 'assets/img/icon-no-syllabus.svg';
import { NoSyllabusContainer } from './TopicalWorksheet.styles';

export default function NoSyllabus() {
  return (
    <NoSyllabusContainer>
      <img className="no-syll-img" src={noSyllabus} alt="no-syllabus" />
      <div className="title">No topics found</div>
      <div className="suggestion">Select another syllabus to show topics.</div>
    </NoSyllabusContainer>
  );
}
