import React from 'react';

export default function Radio(props) {
  const { onChange, id, value } = props;
  return (
    <li>
      <input
        defaultValue={value}
        id={id}
        name="level"
        onChange={onChange}
        type="radio"
      />
      {/* eslint-disable-next-line */}
      <label htmlFor={id}>{value}</label>
    </li>
  );
}
