import React, { useEffect, useRef } from 'react';
import QuestionContentPrint from 'components/Print/QuestionContentPrint';
import SolutionContentPrint from 'components/Print/SolutionContentPrint';
import isNilOrEmpty from 'helpers/isNilOrEmpty';
import { useReactToPrint } from 'react-to-print';
import { Button } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import { getWorksheet } from 'stores/worksheet/actions';
import {
  ContainerPaper,
  ContainerPrint,
  QuestionsList,
  SolutionsList,
  Container
} from './WorksheetPrint.styles';

class WorksheetPrintComponent extends React.PureComponent {
  render() {
    const { selectedWorksheet, containerRef } = this.props;

    return (
      !isNilOrEmpty(selectedWorksheet) && (
        <ContainerPrint ref={containerRef}>
          <ContainerPaper>
            <div className="worksheet-title">
              <div className="left-title">
                <p>{selectedWorksheet.Title}</p>
              </div>
              <div className="right-title">
                <p>
                  Name:
                  <span className="inputWrapper">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </p>
                <p>
                  Class:
                  <span className="inputWrapper">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </p>
              </div>
            </div>
            <QuestionsList>
              {selectedWorksheet.Questions.map((question, key) => {
                const order = key + 1;
                return (
                  <>
                    <div
                      className="question-item-body"
                      key={`question-${order}`}
                    >
                      <p className="header-text">Question {order}</p>
                      <QuestionContentPrint question={question.CartQuestion} />
                    </div>
                    <div className="question-break" />
                  </>
                );
              })}
            </QuestionsList>
            <div className="page-break" />
            <SolutionsList>
              <p className="solution-header">Answers and Solutions</p>
              {selectedWorksheet.Questions.map((question, key) => {
                const order = key + 1;
                return (
                  <>
                    <div
                      className="solution-item-body"
                      key={`solution-${order}`}
                    >
                      <p className="header-text">Question {order}</p>
                      <SolutionContentPrint question={question.CartQuestion} />
                    </div>
                    <div className="question-break" />
                  </>
                );
              })}
            </SolutionsList>
          </ContainerPaper>
        </ContainerPrint>
      )
    );
  }
}

const WorksheetPrint = ({ match }) => {
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const homeworkID = parseInt(match.params.homeworkID, 10);
  const { selectedWorksheet } = useSelector(state => state.worksheet);

  useEffect(() => {
    dispatch(getWorksheet(homeworkID));
  }, [dispatch, homeworkID]);

  const handlePrint = useReactToPrint({
    content: () => containerRef.current
  });

  console.log(selectedWorksheet, 'a');
  return (
    <>
      <Container>
        <div className="top">
          <Button variant="primary" onClick={() => handlePrint()}>
            Print Worksheet
          </Button>
        </div>
      </Container>
      {selectedWorksheet && (
        <WorksheetPrintComponent
          containerRef={containerRef}
          selectedWorksheet={selectedWorksheet}
        />
      )}
    </>
  );
};

export default React.memo(WorksheetPrint);
