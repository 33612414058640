import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'ramda';

import { ProficiencyStatus, MasteryLevelRange } from 'constants/index';

import { ReactComponent as TriangleIcon } from 'assets/img/report/icon-triangle.svg';

import {
  Content,
  TableTitle,
  TableContent,
  ProficiencySkills,
  MasterySkills,
  SortButtonContainer,
  SortButton
} from './SkillDetailContent.styles';

const ProficiencyLegend = ({ data }) => {
  const { t } = useTranslation(['proficiency-report']);
  const profScore = {
    mastery: '80-100',
    competent: '60-79',
    beginning: '0-59',
    incomplete: '< 10'
  };
  return (
    <ProficiencySkills proficiency={ProficiencyStatus[data.CompetencyLevel]}>
      <div className="icon" />
      <p className="prof-name">
        {t(
          `proficiency-report:legend.${
            ProficiencyStatus[data.CompetencyLevel]
          }`,
          ProficiencyStatus[data.CompetencyLevel]
        )}
      </p>
      <p className="prof-descricption">
        {ProficiencyStatus[data.CompetencyLevel] === 'incomplete'
          ? t(`proficiency-report:legend.question`, 'Question')
          : t(`proficiency-report:legend.proficiency`, 'Proficiency')}
        <span>{profScore[ProficiencyStatus[data.CompetencyLevel]]}</span>
      </p>
    </ProficiencySkills>
  );
};

const MasteryLegend = ({ data }) => {
  const { t } = useTranslation(['high-score-report']);
  return (
    <MasterySkills>
      <span>
        <img src={MasteryLevelRange[data.MasteryLevelName]?.icon} alt="icon" />
      </span>
      {data.MasteryLevelName !== 'Incomplete' ? (
        <p className="skill-detail">
          <span>{MasteryLevelRange[data.MasteryLevelName]?.noQn}</span> / 10
          {t(`high-score-report:perSkill`, 'Qn per skill')}
        </p>
      ) : (
        <p className="skill-detail">
          {t(`high-score-report:beCompleted`, 'To be completed')}
        </p>
      )}
    </MasterySkills>
  );
};

const SkillDetailContent = ({
  data,
  reportType,
  isLast,
  isSorting,
  descSortHandler,
  descActive,
  ascSortHandler,
  ascActive,
  level
}) => {
  const { t } = useTranslation(['proficiency-report']);
  return (
    <Content isLast={isLast}>
      <TableTitle>
        <p className="student-count">{data.Counts}</p>
        <p className="student-detail">Students</p>
        {reportType === 'proficiency' && <ProficiencyLegend data={data} />}
        {reportType === 'high-score' && <MasteryLegend data={data} />}
      </TableTitle>
      {!isEmpty(data.Data) && (
        <>
          {isSorting &&
            ProficiencyStatus[data.CompetencyLevel] !== 'incomplete' && (
              <SortButtonContainer>
                <SortButton active={descActive} onClick={descSortHandler}>
                  <TriangleIcon />
                </SortButton>
                <SortButton
                  type="asc"
                  active={ascActive}
                  onClick={ascSortHandler}
                >
                  <TriangleIcon />
                </SortButton>
              </SortButtonContainer>
            )}
          {isSorting &&
            ProficiencyStatus[data.CompetencyLevel] === 'incomplete' && (
              <p className="incomplete-desc">
                {t(
                  `proficiency-report:skillReports.lessQn`,
                  'Completed less than 10 questions, so the proficiency is grey.'
                )}
              </p>
            )}

          <TableContent level={level} sortBtn={isSorting} report={reportType}>
            <tbody>
              {data.Data.map(student => (
                <tr key={student.UserId}>
                  <td>
                    <li>{student.FullName}</li>
                  </td>
                  <td className="percentage">
                    {reportType === 'proficiency' && (
                      <>
                        {isNil(student.Proficiency)
                          ? '0%'
                          : `${(student.Proficiency * 100).toFixed()}%`}
                      </>
                    )}
                    {reportType === 'high-score' && (
                      <>
                        <img src={MasteryLevelRange[level]?.icon} alt={level} />
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </TableContent>
        </>
      )}
    </Content>
  );
};

export default SkillDetailContent;
