import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import noReport from 'assets/img/no-reports.svg';
import { NoReportStyle } from './NoReport.styles';

const NoReport = ({ detail, message }) => {
  const { t } = useTranslation(['proficiency-report']);
  return (
    <NoReportStyle detail={detail}>
      <img src={noReport} alt="no-report-found" />
      <div className="no-report-title">
        {t(`proficiency-report:noReports.title`, 'No reports found')}
      </div>
      {!detail && !message && (
        <div>
          {t(
            `proficiency-report:noReports.desc`,
            'Select another Class/Group to show reports.'
          )}
        </div>
      )}
      {message && <div>{message}</div>}
    </NoReportStyle>
  );
};

export default memo(NoReport);
