import 'assets/icons/icon.css';
import React, { useCallback } from 'react';
import {
  ButtonHeader,
  LeftNav,
  RightNav,
  TopBarFixed
} from './FactualFluencyHeader.style';
import FactualFluencyTimer from './FactualFluencyTimer';

const FactualFluencyHeader = ({
  timeType,
  time,
  intervalActive,
  limit,
  mute,
  onMute
}) => {
  const onClose = useCallback(() => {
    window.open('', '_self');
    window.close();
  }, []);

  return (
    <TopBarFixed>
      <LeftNav>
        <FactualFluencyTimer
          timeType={timeType}
          time={time}
          intervalActive={intervalActive}
          limit={limit}
        />
      </LeftNav>
      <RightNav>
        <ButtonHeader
          className={`btn ${!mute ? 'active' : ''}`}
          onClick={onMute}
        >
          <i className="icon-koo-volume" />
        </ButtonHeader>
        <ButtonHeader className="btn" onClick={onClose}>
          <i className="icon-koo-close2" />
        </ButtonHeader>
      </RightNav>
    </TopBarFixed>
  );
};

export default React.memo(FactualFluencyHeader);
