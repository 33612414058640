const sortStudentsByClass = students => {
  const sortedStudents = [];
  for (const student of students) {
    const hasClass = sortedStudents.findIndex(
      item => item.classID === student.ClassID
    );
    if (hasClass === -1) {
      sortedStudents.push({
        classID: student.ClassID,
        students: [student]
      });
    } else {
      sortedStudents[hasClass].students.push(student);
    }
  }
  return sortedStudents;
};

export default sortStudentsByClass;
