import styled from 'styled-components';

export const QuestionResultContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .correct-answer {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #55c92b;
  }

  .no-attempt {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: solid 1.5px #dedede;
  }

  .wrong-answer {
    width: 8px;
    height: 8px;
  }
`;
