import styled from 'styled-components';

export const NoVideoStyle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #555555;
  margin-top: 150px;
  .no-vid-title {
    font-weight: bold;
    margin: 40px 0 10px;
  }
`;
