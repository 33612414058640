export const FETCH_CLASS_PROFICIENCY_REPORT_REQUEST =
  'FETCH_CLASS_PROFICIENCY_REPORT_REQUEST';
export const FETCH_CLASS_PROFICIENCY_REPORT_SUCCESS =
  'FETCH_CLASS_PROFICIENCY_REPORT_SUCCESS';
export const FETCH_CLASS_PROFICIENCY_REPORT_FAILED =
  'FETCH_CLASS_PROFICIENCY_REPORT_FAILED';
export const FETCH_CLASS_HIGHSCORE_REPORT_REQUEST =
  'FETCH_CLASS_HIGHSCORE_REPORT_REQUEST';
export const FETCH_CLASS_HIGHSCORE_REPORT_SUCCESS =
  'FETCH_CLASS_HIGHSCORE_REPORT_SUCCESS';
export const FETCH_CLASS_HIGHSCORE_REPORT_FAILED =
  'FETCH_CLASS_HIGHSCORE_REPORT_FAILED';
export const FETCH_STUDENT_PROFICIENCY_REPORT_REQUEST =
  'FETCH_STUDENT_PROFICIENCY_REPORT_REQUEST';
export const FETCH_STUDENT_PROFICIENCY_REPORT_SUCCESS =
  'FETCH_STUDENT_PROFICIENCY_REPORT_SUCCESS';
export const FETCH_STUDENT_PROFICIENCY_REPORT_FAILED =
  'FETCH_STUDENT_PROFICIENCY_REPORT_FAILED';
export const FETCH_SAMPLE_QN_REQUEST = 'FETCH_SAMPLE_QN_REQUEST';
export const FETCH_SAMPLE_QN_SUCCESS = 'FETCH_SAMPLE_QN_SUCCESS';
export const FETCH_SAMPLE_QN_FAILED = 'FETCH_SAMPLE_QN_FAILED';
export const SET_SELECTED_CLASS = 'SET_SELECTED_CLASS';
export const SET_SORT_ORDER = 'SET_SORT_ORDER';
export const SET_REPORT_LEVEL = 'SET_REPORT_LEVEL';
export const SET_SYLLABUS = 'SET_SYLLABUS';
export const SET_CLASS_LEVEL = 'SET_CLASS_LEVEL';
export const FETCH_WEEK_ITEMS_REQUEST = 'FETCH_WEEK_ITEMS_REQUEST';
export const FETCH_WEEK_ITEMS_SUCCESS = 'FETCH_WEEK_ITEMS_SUCCESS';
export const FETCH_WEEK_ITEMS_FAILED = 'FETCH_WEEK_ITEMS_FAILED';
export const FETCH_LOGIN_REPORT_REQUEST = 'FETCH_LOGIN_REPORT_REQUEST';
export const FETCH_LOGIN_REPORT_SUCCESS = 'FETCH_LOGIN_REPORT_SUCCESS';
export const FETCH_LOGIN_REPORT_FAILED = 'FETCH_LOGIN_REPORT_FAILED';
export const TOGGLE_REPORTS = 'TOGGLE_REPORTS';
export const FETCH_TEACHER_REPORT_MONTH_REQUEST =
  'FETCH_TEACHER_REPORT_MONTH_REQUEST';
export const FETCH_TEACHER_REPORT_MONTH_SUCCESS =
  'FETCH_TEACHER_REPORT_MONTH_SUCCESS';
export const FETCH_TEACHER_REPORT_MONTH_FAILED =
  'FETCH_TEACHER_REPORT_MONTH_FAILED';
export const FETCH_TEACHER_REPORT_SPECIFIC_MONTH_REQUEST =
  'FETCH_TEACHER_REPORT_SPECIFIC_MONTH_REQUEST';
export const FETCH_TEACHER_REPORT_SPECIFIC_MONTH_SUCCESS =
  'FETCH_TEACHER_REPORT_SPECIFIC_MONTH_SUCCESS';
export const FETCH_TEACHER_REPORT_SPECIFIC_MONTH_FAILED =
  'FETCH_TEACHER_REPORT_SPECIFIC_MONTH_FAILED';

export const FETCH_REPORT_SYLLABUS_REQUEST = 'FETCH_REPORT_SYLLABUS_REQUEST';
export const FETCH_REPORT_SYLLABUS_SUCCESS = 'FETCH_REPORT_SYLLABUS_SUCCESS';
export const FETCH_REPORT_SYLLABUS_FAILED = 'FETCH_REPORT_SYLLABUS_FAILED';

export const FETCH_REPORT_PROFICIENCY_SKILL_REQUEST =
  'FETCH_REPORT_PROFICIENCY_SKILL_REQUEST';
export const FETCH_REPORT_PROFICIENCY_SKILL_SUCCESS =
  'FETCH_REPORT_PROFICIENCY_SKILL_SUCCESS';
export const FETCH_REPORT_PROFICIENCY_SKILL_FAILED =
  'FETCH_REPORT_PROFICIENCY_SKILL_FAILED';
export const CLEAN_REPORT_PROFICIENCY_SKILL = 'CLEAN_REPORT_PROFICIENCY_SKILL';

export const FETCH_REPORT_HIGHSCORE_SKILL_REQUEST =
  'FETCH_REPORT_HIGHSCORE_SKILL_REQUEST';
export const FETCH_REPORT_HIGHSCORE_SKILL_SUCCESS =
  'FETCH_REPORT_HIGHSCORE_SKILL_SUCCESS';
export const FETCH_REPORT_HIGHSCORE_SKILL_FAILED =
  'FETCH_REPORT_HIGHSCORE_SKILL_FAILED';

export const FETCH_SKILL_PROFICIENCY_DETAIL_REPORT_REQUEST =
  'FETCH_SKILL_PROFICIENCY_DETAIL_REPORT_REQUEST';
export const FETCH_SKILL_PROFICIENCY_DETAIL_REPORT_SUCCESS =
  'FETCH_SKILL_PROFICIENCY_DETAIL_REPORT_SUCCESS';
export const FETCH_SKILL_PROFICIENCY_DETAIL_REPORT_FAILED =
  'FETCH_SKILL_PROFICIENCY_DETAIL_REPORT_FAILED';

export const FETCH_SKILL_HIGHSCORE_DETAIL_REPORT_REQUEST =
  'FETCH_SKILL_HIGHSCORE_DETAIL_REPORT_REQUEST';
export const FETCH_SKILL_HIGHSCORE_DETAIL_REPORT_SUCCESS =
  'FETCH_SKILL_HIGHSCORE_DETAIL_REPORT_SUCCESS';
export const FETCH_SKILL_HIGHSCORE_DETAIL_REPORT_FAILED =
  'FETCH_SKILL_HIGHSCORE_DETAIL_REPORT_FAILED';
export const CLEAN_REPORT_HIGHSCORE_SKILL = 'CLEAN_REPORT_HIGHSCORE_SKILL';
