import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PopupModal from 'components/Shared/PopupModal';
import MultipleCurriculumModalBody from 'components/MultipleCurriculum/MultipleCurriculumModalBody';
import { ButtonContainer, ChangeBtn } from './SidebarMultipleCurriculum.styles';

const SidebarMultipleCurriculums = () => {
  const { t } = useTranslation(['browse-content']);
  const { selectedCurriculum, curriculums } = useSelector(
    state => state.curriculums
  );
  const selectedLevel = useSelector(state => state.levels.selectedLevel ?? {});

  // popup modal states
  const [
    showMultipleCurriculumModal,
    setShowMultipleCurriculumModal
  ] = useState(false);

  // handlers to hide popup modal
  const hideMultipleCurriculumModal = () => {
    setShowMultipleCurriculumModal(false);
  };
  return (
    <div className="row align-items-center">
      <PopupModal
        show={showMultipleCurriculumModal}
        hide={hideMultipleCurriculumModal}
      >
        <MultipleCurriculumModalBody
          hide={hideMultipleCurriculumModal}
          curriculums={curriculums}
          selectedCurriculum={selectedCurriculum}
          selectedLevel={selectedLevel}
        />
      </PopupModal>
      <ButtonContainer>
        <div className="curr-name">
          <div className="label">
            {t(`browse-content:curriculumSection.curriculum`, 'Curriculum')}
          </div>
          <p className="name">{selectedCurriculum.curriculumName ?? ''}</p>
        </div>
        <ChangeBtn onClick={() => setShowMultipleCurriculumModal(true)}>
          {t(`browse-content:curriculumSection.change`, 'Change')}
        </ChangeBtn>
      </ButtonContainer>
    </div>
  );
};

export default SidebarMultipleCurriculums;
