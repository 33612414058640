import { call, put, select } from 'redux-saga/effects';
import { isNil } from 'ramda';
import fetchSkillsApi from 'services/skills';
import {
  fetchSkillsRequest,
  fetchSkillsSuccess,
  fetchSkillsFailed,
  resetSelectedSkill
} from 'stores/skills/actions';

/**
 * The startup saga is the place to define behavior to execute when the application starts.
 */
export default function* skills() {
  yield put(fetchSkillsRequest());
  const topic = yield select(state => state.topics.selectedTopic);
  const data = yield call(fetchSkillsApi, topic.ID);
  if (!isNil(data)) {
    if (!isNil(data.error)) {
      // if the API return error message
      yield put(fetchSkillsFailed(data.error));
    } else {
      yield put(fetchSkillsSuccess(data));
    }
  }
}

export function* refreshSkills() {
  yield put(resetSelectedSkill());
  yield call(skills);
}
