export default (questions, filters) => {
  const questionType = filters.find(f => f.title === 'Question Type');
  return questions.filter(question => {
    if (
      questionType?.options['MCQ'] === questionType?.options['Short Answer']
    ) {
      return true;
    }

    if (questionType?.options['MCQ']) {
      return question.QuestionType === 1;
    }

    if (questionType?.options['Short Answer']) {
      return question.QuestionType === 2;
    }

    return true;
  });
};
