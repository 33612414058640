import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeClassRequest } from 'stores/manage-class/actions';
import { Button, Dropdown, Spinner } from 'react-bootstrap';
import sortClasses from 'helpers/parseClassList';
import successIcon from 'assets/img/icon-manage-class-success-check.svg';
import { ChangeClassModalContainer } from './ChangeClassModalBody.styles';

export default function ChangeClassModalBody({
  hide,
  selectedStudentIDs,
  currentLevel,
  currentClassID
}) {
  const dispatch = useDispatch();
  const { classes, changeClassResult, error, isLoading } = useSelector(
    state => state.manageClasses
  );
  const sortedClasses = Object.prototype.hasOwnProperty.call(
    classes,
    'ClassList'
  )
    ? sortClasses(classes.ClassList)
    : [];
  // TODO: filter out the current class of selected student
  const [selectedLevel, setSelectedLevel] = useState(currentLevel);
  const selectedLevelIndex = sortedClasses.findIndex(
    item => item.level === selectedLevel
  );
  const [selectedClass, setSelectedClass] = useState(
    sortedClasses[selectedLevelIndex].classes.filter(
      classObject => classObject.ID !== currentClassID
    )[0]
  );
  return (
    <ChangeClassModalContainer>
      {changeClassResult === true ? (
        <>
          <span className="success-title">
            <img
              src={successIcon}
              alt="Success icon"
              style={{ marginRight: '0.5rem' }}
            />
            Success!
          </span>
          <span style={{ margin: '2rem 0' }}>
            {`The selected student(s) have been moved to Class ${selectedClass.Name}.`}
          </span>
          <Button
            variant="success-ok"
            onClick={() => {
              hide();
            }}
          >
            OK
          </Button>
        </>
      ) : (
        <>
          <p style={{ color: '#FF5B00', fontSize: '1.125rem' }}>
            Move to another class
          </p>
          <p>The student(s) you selected will move to</p>
          <div className="change-class-selector">
            <span>Level</span>
            <Dropdown className="other-assignments-dropdown">
              <Dropdown.Toggle variant="light" id="dropdown-change-class">
                {sortedClasses.length ? `P${selectedLevel}` : 'Select level'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {sortedClasses.map(item => (
                  <Dropdown.Item
                    key={`${item.level}`}
                    onClick={() => {
                      setSelectedLevel(item.level);
                      const newLevelIndex = sortedClasses.findIndex(
                        sortedObject => sortedObject.level === item.level
                      );
                      setSelectedClass(sortedClasses[newLevelIndex].classes[0]);
                    }}
                  >{`P${item.level}`}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="change-class-selector">
            <span>Class</span>
            <Dropdown className="other-assignments-dropdown">
              <Dropdown.Toggle variant="light" id="dropdown-change-class">
                {selectedClass.Name}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {sortedClasses[selectedLevelIndex].classes
                  .filter(classObject => classObject.ID !== currentClassID)
                  .map(item => (
                    <Dropdown.Item
                      key={item.ID}
                      onClick={() => {
                        setSelectedClass(item);
                      }}
                    >
                      {item.Name}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {error && <span style={{ color: '#ff5b00' }}>{error}</span>}
          <div className="selection-action-buttons">
            <Button
              variant="outline-cancel"
              onClick={() => {
                hide();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="ok"
              onClick={() => {
                dispatch(
                  changeClassRequest({
                    StudentIDs: selectedStudentIDs,
                    classID: selectedClass.ID
                  })
                );
              }}
            >
              OK
              {isLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="ml-2"
                />
              )}
            </Button>
          </div>
        </>
      )}
    </ChangeClassModalContainer>
  );
}
