import axios from 'axios';
import Config from 'config';
import refreshTokenInterceptor from 'services/refreshToken';

/**
 * Interceptor to automatically set access token to axios instance.
 */
const requestInterceptor = initialConfig => {
  const config = { ...initialConfig };
  // Don't intercept for queries to non backend servers.
  if (config.baseURL !== Config.API_URL) {
    return config;
  }

  const accessToken = localStorage.getItem('access_token');

  if (accessToken) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`
    };
  }

  return config;
};

/**
 * Interceptor for handling request errors.
 */
const requestErrorInterceptor = error => {
  // Request shouldn't be errorneous, capture in Sentry for future diagnosis.
  // console.log('%cAPI ERROR', 'color: #E35F5F;font-weight: bold;', error);
  return Promise.reject(error);
};

/**
 * Interceptor for logging request.
 */
const requestLoggerInterceptor = request => {
  // console.log('%cAPI REQUEST', 'color: #17B5E2;font-weight: bold;', request);
  return request;
};

/**
 * Interceptor for logging response.
 */
const responseLoggerInterceptor = response => {
  // console.log('%cAPI RESPONSE', 'color: #42AD3E;font-weight: bold;', response);
  return response;
};

const axiosInstance = axios.create({
  baseURL: Config.API_URL
});

axiosInstance.interceptors.request.use(
  requestInterceptor,
  requestErrorInterceptor
);

if (process.env.NODE_ENV !== 'production') {
  axiosInstance.interceptors.request.use(requestLoggerInterceptor);
  axiosInstance.interceptors.response.use(responseLoggerInterceptor);
}

refreshTokenInterceptor(axiosInstance);

export default axiosInstance;
