import styled from 'styled-components';

export const CreateGroupStudentsWrapper = styled.div`
  width: 35%;
  border-right: 1px solid #c4c4c4;
  height: calc(100vh - 160px);
  overflow: auto;
  background-color: #fbfbfb;
  .crt-grp-classname {
    margin: 20px 10px 5px;
    font-weight: 700;
    font-size: 17px;
    color: #1e1e1e;
  }
  #search-student {
    background-image: ${props => `url(${props.searchIcon})`};
    background-repeat: no-repeat;
    text-indent: 20px;
    background-position: left;
    background-position-x: 10px;
    background-color: #ffffff;
    @media only screen and (max-width: 870px) {
      margin-top: 5px;
      font-size: 14px;
    }
  }
  #search-student:focus {
    border-color: #ffa200;
  }
  #search-student:focus {
    background-image: none;
    text-indent: 0px;
  }
`;

export const GroupStudentsContainer = styled.div`
  margin-top: 10px;
  cursor: pointer;
  .no-student {
    font-size: 16px;
    color: #545454;
    text-align: center;
  }
  .active-student {
    background-color: #ffc400;
  }
  .group-students-lists {
    display: flex;
    padding: 5px 20px;
    align-items: center;
    margin-bottom: 5px;
    .check-container {
      position: relative;
      bottom: 10px;
      padding-left: 35px;
      cursor: pointer;
      user-select: none;
    }
    input {
      height: 23px;
      width: 25px;
      position: absolute;
      opacity: 0;
      cursor: pointer;
      left: 1px;
    }
    .group-checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: transparent;
      border: 2px solid #707070;
    }
    input:checked ~ .group-checkmark {
      background-color: #ef7836;
    }
    .group-checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }
    input:checked ~ .group-checkmark:after {
      display: block;
    }
    .group-checkmark:after {
      left: 7px;
      top: 1px;
      width: 9px;
      height: 16px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(25deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      border: 2px solid #fff;
      margin: 0 10px;
    }
    .grp-img-name {
      color: #1e1e1e;
      font-size: 15px;
    }
  }
`;
