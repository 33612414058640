import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import classReport from 'assets/img/icon-report-class-report.svg';
import showArrow from 'assets/img/icon-report-collapse-show.svg';
import proficiencyIcon from 'assets/img/icon-report-sidebar-proficiency.svg';
import highScoreIcon from 'assets/img/icon-report-sidebar-high-score.svg';
import loginIcon from 'assets/img/icon-report-sidebar-login.svg';
import activeIcon from 'assets/img/icon-report-sidebar-active.svg';
import milestoneIcon from 'assets/img/icon-report-sidebar-milestone.svg';
import insightsIcon from 'assets/img/icon-report-sidebar-insight.svg';
import { featureToggle } from 'constants/index';
import { SidebarContainer } from './AllReports.styles';

export default function ClassReportSidebar() {
  const { t } = useTranslation(['proficiency-report']);
  const { pathname } = useLocation();
  const isActivePath = path =>
    path === '/report'
      ? pathname === path || pathname.includes('/proficiency-report')
      : pathname.includes(path);
  return (
    <>
      <SidebarContainer>
        <div className="accordion" id="class-report">
          <button
            type="button"
            className="btn"
            data-toggle="collapse"
            data-target="#classReport"
          >
            <span className="report-category-icon">
              <img src={classReport} alt="icon-list" />
            </span>
            <span className="report-category">
              {t(`proficiency-report:sidebar.classReport`, 'Class Report')}
            </span>
          </button>
          <img src={showArrow} alt="show" className="arrow" />
          <div
            className="collapse show"
            id="classReport"
            data-parent="#class-report"
          >
            <li className="list-group-item">
              <Link
                className={`nav-link${
                  isActivePath('/report') ? ' nav-link-active' : ''
                }`}
                to="/report"
              >
                <img
                  src={proficiencyIcon}
                  alt="icon-list"
                  className="sidebar-icon"
                />
                <span>
                  {t(
                    `proficiency-report:sidebar.proficiencyReport`,
                    'Proficiency Report'
                  )}
                </span>
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                className={`nav-link${
                  isActivePath('high-score-report') ? ' nav-link-active' : ''
                }`}
                to="/report/class-report/high-score-report"
              >
                <img
                  src={highScoreIcon}
                  alt="icon-list"
                  className="sidebar-icon"
                />
                <span>
                  {t(
                    `proficiency-report:sidebar.highScoreReport`,
                    'High Score Report'
                  )}
                </span>
              </Link>
            </li>
            <li className="list-group-item">
              <Link
                className={`nav-link${
                  isActivePath('/report/class-report/login-report')
                    ? ' nav-link-active'
                    : ''
                }`}
                to="/report/class-report/login-report"
              >
                <img src={loginIcon} alt="icon-list" className="sidebar-icon" />
                <span>
                  {t(`proficiency-report:sidebar.loginReport`, 'Login Report')}
                </span>
              </Link>
            </li>
            {featureToggle.reportSidebarOptions && (
              <>
                <li className="list-group-item">
                  <Link
                    className={`nav-link${
                      isActivePath('/report/class-report/active-report')
                        ? ' nav-link-active'
                        : ''
                    }`}
                    to="/report/class-report/active-report"
                  >
                    <img
                      src={activeIcon}
                      alt="icon-list"
                      className="sidebar-icon"
                    />
                    <span>
                      {t(
                        `proficiency-report:sidebar.activeReport`,
                        'Active Report'
                      )}
                    </span>
                  </Link>
                </li>
                <li className="list-group-item">
                  <Link
                    className={`nav-link${
                      isActivePath('/report/class-report/milestone')
                        ? ' nav-link-active'
                        : ''
                    }`}
                    to="/report/class-report/milestone"
                  >
                    <img
                      src={milestoneIcon}
                      alt="icon-list"
                      className="sidebar-icon"
                    />
                    <span>
                      {t(`proficiency-report:sidebar.milestone`, 'Milestone')}
                    </span>
                  </Link>
                </li>
                <li className="list-group-item">
                  <Link
                    className={`nav-link${
                      isActivePath('/report/class-report/insights')
                        ? ' nav-link-active'
                        : ''
                    }`}
                    to="/report/class-report/insights"
                  >
                    <img
                      src={insightsIcon}
                      alt="icon-list"
                      className="sidebar-icon"
                    />
                    <span>
                      {t(`proficiency-report:sidebar.insights`, 'Insights')}
                    </span>
                  </Link>
                </li>
              </>
            )}
          </div>
        </div>
      </SidebarContainer>
    </>
  );
}
