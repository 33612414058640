import { call, put } from 'redux-saga/effects';
import { isNil } from 'ramda';
import userPreferencesService from 'services/userPreferences';
import {
  setUserPreferencesSuccess,
  setUserPreferencesFailed
} from 'stores/userPreferences/actions';

export default function* setUserPreferences({ params }) {
  const rawBody = {
    Id: params.Id,
    Value: params.Value
  };
  const data = yield call(userPreferencesService.postUserPreferences, rawBody);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      localStorage.setItem('current_locale', params.Value);
      yield put(setUserPreferencesSuccess(data));
    }
  } else {
    yield put(setUserPreferencesFailed(data.error));
  }
}
