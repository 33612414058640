import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { isNil } from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import HTMLReactParser from 'html-react-parser';
import questionParser from 'helpers/questionParser';
import convertTagsToHTML from 'helpers/text/convertTagsToHTML';
import Collapse from 'components/Shared/Collapse';
import DifficultyChillis from 'components/Level/DifficultyChillis';
import {
  McqOptionsList,
  TableModal
} from 'components/Question/QuestionContent.styles';
import Spinner from 'components/Shared/Spinner';
import {
  fetchSingleClassReportRequest,
  clearSingleClassReport
} from 'stores/assignments/actions';
import { parseQnAnswer } from 'helpers/singleClassReport';

const QuestionContent = ({ data, qnNumber }) => {
  const { t } = useTranslation(['track-assignment-assigned-me']);
  const { Payload, Difficulty } = data;
  // States
  const [questionTitle, setQuestionTitle] = useState(null);
  const [questionSolution, setQuestionSolution] = useState(null);
  const [questionAnswerParsed, setQuestionAnswerParsed] = useState(null);
  const [mcqOptionsParsed, setMCQOptionsParsed] = useState([]);
  // Effects
  useEffect(() => {
    if (!isNil(Payload)) {
      const { title, solution, mcqOptions, questionAnswer } = questionParser({
        Payload
      });
      const renderOptions = mcqOptions.map(option =>
        HTMLReactParser(convertTagsToHTML(option, false), {
          replace: domNode => {
            if (domNode.name === 'img') {
              domNode.attribs.style = 'width: 68%';
              return domNode;
            }
            return domNode;
          }
        })
      );
      setMCQOptionsParsed(renderOptions);
      setQuestionTitle(
        HTMLReactParser(convertTagsToHTML(title), {
          replace: domNode => {
            if (domNode.name === 'img' && !isNil(domNode.attribs?.url)) {
              return (
                <img
                  src={domNode.attribs?.url}
                  alt={domNode.attribs?.uniqueid}
                />
              );
            }
            if (domNode.name === 'helptext') {
              return (
                <small id="questionHelp" className="form-text text-muted">
                  {domNode.children[0]?.data ?? ''}
                </small>
              );
            }
            if (domNode.name === 'input') {
              return <input type="text" />;
            }
            return domNode;
          }
        })
      );

      setQuestionSolution(HTMLReactParser(convertTagsToHTML(solution)));
      setQuestionAnswerParsed(parseQnAnswer(questionAnswer));
    }
  }, [Payload]);
  return (
    <div className="question-item-body">
      <div className="media">
        <div className="media-body">
          <div className="qs-inner">
            <div className="qs-attr">
              <div className="row">
                <div className="col-md">
                  <div className="d-flex">
                    <span>
                      {t(
                        'track-assignment-assigned-me:openHwModal.qn',
                        'Question'
                      )}{' '}
                      {qnNumber}
                    </span>
                    <span>
                      {t(
                        'track-assignment-assigned-me:openHwModal.difficulty',
                        'Difficulty:'
                      )}{' '}
                      <DifficultyChillis value={Difficulty.Max ?? 0} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="qs-text">
              <TableModal>{questionTitle}</TableModal>
              {mcqOptionsParsed.length > 0 && (
                <McqOptionsList>
                  {mcqOptionsParsed.map(option => (
                    <li key={option}>{option}</li>
                  ))}
                </McqOptionsList>
              )}
            </div>
          </div>
          <div className="qs-solution">
            <Collapse
              collapsed={false}
              hideText={t(
                'track-assignment-assigned-me:openHwModal.hideSol',
                'Hide Solution'
              )}
              showText={t(
                'track-assignment-assigned-me:openHwModal.showSol',
                'Show Solution'
              )}
            >
              {questionAnswerParsed !== null &&
                questionAnswerParsed.length > 0 && (
                  <>
                    <p>
                      <strong>
                        {t(
                          'track-assignment-assigned-me:openHwModal.answer',
                          'Answer:'
                        )}
                      </strong>
                    </p>
                    <p>{questionAnswerParsed}</p>
                  </>
                )}
              <p>
                <strong>
                  {t(
                    'track-assignment-assigned-me:openHwModal.solution',
                    'Solution:'
                  )}
                </strong>
              </p>
              {questionSolution}
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
};

const HomeworkQuestionsModal = ({
  show,
  hide,
  homeworkID,
  hwTitle,
  isSingleClassReportPage
}) => {
  const dispatch = useDispatch();
  const { singleClassReport, isSingleClassReportLoading } = useSelector(
    state => state.assignments
  );

  useEffect(() => {
    if (show && !isSingleClassReportPage) {
      dispatch(
        fetchSingleClassReportRequest({
          homeworkID
        })
      );
    }
    return () => {
      if (!isSingleClassReportPage) {
        dispatch(clearSingleClassReport());
      }
    };
  }, [dispatch, homeworkID, isSingleClassReportPage, show]);
  return (
    <Modal show={show} onHide={() => hide()} className="question-summary">
      <div className="student-summary-modal">
        <div className="question-item-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => hide()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h3>{hwTitle}</h3>
        </div>
        {isSingleClassReportLoading && (
          <div className="question-item-body" style={{ height: '50vh' }}>
            <Spinner />
          </div>
        )}
        {!isSingleClassReportLoading &&
          singleClassReport.length > 0 &&
          singleClassReport[0].Questions.length > 0 &&
          singleClassReport[0].Questions.map((question, index) => (
            <QuestionContent
              key={`qn-${question.Id}`}
              data={question}
              qnNumber={index + 1}
            />
          ))}
      </div>
    </Modal>
  );
};

export default HomeworkQuestionsModal;
