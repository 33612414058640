import styled from 'styled-components';

export const CreateFactualFluencyWorksheetContainer = styled.div`
  margin-top: 55px;
  padding-left: 195px;
  @media (max-width: 1024px) {
    padding-left: 56px;
    .question-cart-content {
      margin-left: 0;
      margin-top: 60px;
    }
    .check-add-score {
      left: 100px;
      ~ .custom-checkbox {
        left: 100px;
        @media (max-width: 991px) {
          left: 120px;
        }
        @media (max-width: 639px) {
          left: 40px;
        }
      }
      ~ label {
        left: 130px;
        @media (max-width: 991px) {
          left: 150px;
        }
        @media (max-width: 639px) {
          left: 70px;
        }
      }
    }
    .box-white-wrap {
      .question-card.create-assign {
        padding-left: 10px;
      }
    }
  }
`;
