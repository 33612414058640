import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import SidebarTrackAssignment from 'components/Sidebar/SidebarTrackAssignment';
import Sidebar from 'components/Sidebar/Sidebar';
import Overlay from 'components/Content/Overlay';
import Header from 'components/Header/Header';
import AssignmentSummary from './AssignmentSummary';
import OtherAssignments from './OtherAssignments';
import MultiClassReport from './MultiClassReport';
import SingleClassReport from './SingleClassReport';
import { TrackAssignmentPage } from './TrackAssignment.styles';

export default function TrackAssignment() {
  const [expanded, setExpanded] = useState(false);
  const toggleNavigation = () => setExpanded(!expanded);

  return (
    <>
      <Header />
      <TrackAssignmentPage>
        <Sidebar
          expanded={expanded}
          toggleNavigation={toggleNavigation}
          className="sidebar--auto"
        >
          <SidebarTrackAssignment />
        </Sidebar>
        <div className="assignment-content">
          <Overlay isVisible={expanded} toggleVisible={toggleNavigation} />
          <Switch>
            <Route
              exact
              path="/track-assignment/homework/:homeworkID"
              component={props => <MultiClassReport {...props} />}
            />
            <Route
              exact
              path="/track-assignment/homework/:homeworkID/:classID"
              component={props => <SingleClassReport {...props} />}
            />
            <Route
              exact
              path="/track-assignment"
              component={() => <AssignmentSummary isOthers={false} />}
            />
            <Route
              path="/track-assignment/others/:teacherID"
              component={props => <AssignmentSummary isOthers {...props} />}
            />
            <Route
              exact
              path="/track-assignment/others"
              component={() => <OtherAssignments />}
            />
          </Switch>
        </div>
      </TrackAssignmentPage>
    </>
  );
}
