import React from 'react';
import { Modal } from 'react-bootstrap';

const AlertModal = props => {
  const { isShow, handleClose, title, message, children, buttons } = props;

  return (
    <Modal show={isShow} onHide={handleClose} className="custom-popups">
      <Modal.Body>
        <h4>{title}</h4>
        {message && (
          <div className="align-items-center justify-content-center">
            {message}
          </div>
        )}
        <div className="align-items-center justify-content-center">
          {children}
        </div>
        {buttons && <div className="justify-content-center">{buttons}</div>}
      </Modal.Body>
    </Modal>
  );
};

export default AlertModal;
