import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { deleteAssignedHwRequest } from 'stores/assignments/actions';
import successIcon from 'assets/img/icon-manage-class-success-check.svg';
import { Spinner } from 'react-bootstrap';
import DeleteModalBodyContnainer from './AssignmentCardDeleteModalBody.styles';

const DeleteModalBody = ({ hide, homeworkID }) => {
  const dispatch = useDispatch();
  // Redux states
  const { isLoading, deleteHwResult, error } = useSelector(
    state => state.assignments
  );

  return (
    <DeleteModalBodyContnainer>
      {deleteHwResult === true ? (
        <>
          <span className="success-title">
            <img
              src={successIcon}
              alt="Success icon"
              style={{ marginRight: '0.5rem' }}
            />
            Success!
          </span>
          <span style={{ margin: '2rem 0' }}>
            Homework is successfully deleted.
          </span>
          <button
            className="success-btn"
            type="button"
            onClick={() => {
              hide();
            }}
          >
            OK
          </button>
        </>
      ) : (
        <>
          <button
            className="close-btn"
            type="button"
            onClick={() => {
              hide();
            }}
          >
            X
          </button>
          <div className="title">Delete Assignment</div>
          <p className="text">
            Are you sure you want to delete this assignment?
          </p>
          {error && <span style={{ color: '#ff5b00' }}>{error}</span>}
          <div className="btn-group">
            <button
              className="no-btn"
              type="button"
              onClick={() => {
                hide();
              }}
            >
              No
            </button>
            <button
              className="yes-btn"
              type="button"
              onClick={() => {
                dispatch(
                  deleteAssignedHwRequest({
                    homeworkID
                  })
                );
              }}
            >
              Yes
              {isLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="ml-2"
                />
              )}
            </button>
          </div>
        </>
      )}
    </DeleteModalBodyContnainer>
  );
};

export default DeleteModalBody;
