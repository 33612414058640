import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import StepperWizard from 'components/Shared/StepperWizard';
import { Link } from 'react-router-dom';
import { setStep } from 'stores/utils/step/actions';
import { useDispatch, useSelector } from 'react-redux';
import hasProp from 'helpers/hasProp';
import AssignTime from './AssignTime';
import AssignClass from './AssignClass';
import AssignPreview from './AssignPreview';
import { resetSelectedWorksheet } from 'stores/worksheet/actions';
import { resetAssign } from 'stores/worksheet/assign/actions';

/**
 * @todo when the UI for empty cart questions done, use cartQuestions from reducer or other resources
 * @note client still not confirm whether cartQuestions is the data we will be use
 */

export default function AssignView() {
  const { t } = useTranslation(['create-assign-saved']);
  const dispatch = useDispatch();
  const {
    assignedClass,
    assignedGroup,
    assignedStudent,
    assignedPrivateGroup,
    assignedAdminGroup,
    assignedLevel
  } = useSelector(state => state.assign);
  const hasAssignees =
    assignedClass.length > 0 ||
    assignedGroup.length > 0 ||
    assignedStudent.length > 0 ||
    assignedPrivateGroup.length > 0 ||
    (hasProp(assignedAdminGroup, 'ID') && assignedLevel !== '');
  const BackButton = (
    <Link
      to="/create-and-assign/saved-worksheet"
      className="btn btn-link question-cart-content__back"
    >
      &lt; {t(`create-assign-saved:scheduleWo.backList`, 'Back To List')}
    </Link>
  );

  useEffect(() => {
    return () => {
      dispatch(resetSelectedWorksheet());
      dispatch(resetAssign());
    };
  }, [dispatch]);

  const onSelect = step => {
    let valid = false;
    switch (step) {
      case 3:
        if (hasAssignees) {
          valid = true;
        }
        break;
      default:
        valid = true;
        break;
    }
    if (valid) {
      dispatch(setStep(step));
    }
  };

  return (
    <div className="content content__inner content__inner-create-assign">
      <StepperWizard
        backButton={BackButton}
        onSelect={onSelect}
        className="stepper-schedule stepper-default"
      >
        <AssignTime
          step="1"
          title={t(`create-assign-saved:assignNow.selTime`, 'Select Time')}
        />
        <AssignClass
          step="2"
          title={t(`create-assign-saved:assignNow.chooseClass`, 'Choose Class')}
        />
        <AssignPreview
          step="3"
          title={t(
            `create-assign-saved:assignNow.schedPrev`,
            'Preview Assigment'
          )}
        />
      </StepperWizard>
    </div>
  );
}
