import styled from 'styled-components';
import PopupModal from 'components/Shared/PopupModal';

export const CreateGroupWrapper = styled.div`
  padding-left: 190px;
  min-height: calc(100vh - 100px);
  @media (max-width: 1024px) {
    padding-left: 66px;
  }
`;

export const CreateGroupHeaderStyle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #707070;
  padding: 30px;
  height: 100px;
  width: 100%;
  background-color: #ffffff;
  .back-list {
    font-size: 16px;
    flex: 2;
    span {
      cursor: pointer;
    }
  }
  .grp-name-container {
    flex: 6;
    span {
      font-size: 17px;
      font-weight: 700;
    }
    input {
      width: 360px;
      height: 40px;
      padding: 0 1.5rem;
      border: solid 1px rgba(112, 112, 112, 0.36);
      color: #202020;
      font-weight: 600;
      margin: 0 10px;
    }
    button {
      padding: 2px;
      height: 40px;
      min-width: 140px;
      border-radius: 3px;
    }
    .show-err {
      color: red;
      width: 50%;
      text-align: center;
    }
  }
`;

export const CreateGroupBody = styled.div`
  display: flex;
  padding-left: 5px;
  min-height: calc(100vh - 160px);
`;
export const StyledCreateSuccessModal = styled(PopupModal)`
  text-align: center;
  .modal-body {
    max-width: 500px;
    padding: 0 1rem;
    background: white;
    width: 100%;
  }
  h5 {
    color: #ff5b00;
    font-size: 20px;
    margin: 36px 0;
    font-weight: 400;
  }
  .btn-primary {
    padding: 8px 40px;
    margin-bottom: 50px;
    font-size: 18px;
  }
`;
