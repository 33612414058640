import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Spinner from 'components/Shared/Spinner';
import { Container, Headings, Lists } from './StudentList.styles';
import { milisecondToString } from 'helpers/timesParser';

const StudentList = ({ data, isTopFifty, challengeMode, isLoading }) => {
  const { t } = useTranslation(['leaderboard']);
  const { userID } = useSelector(state => state.login);
  const [list, setList] = useState(data);
  const showLabel = () => {
    return t('leaderboard:leaderboardRanking.class', 'Class');
  };
  const labels = [
    {
      name: t('leaderboard:leaderboardRanking.name', 'Name'),
      flex: 2
    },
    {
      name: showLabel(),
      flex: 2
    },
    {
      name: t('leaderboard:leaderboardRanking.score', 'Score'),
      flex: 1
    },
    {
      name: t('leaderboard:leaderboardRanking.totalTime', 'Total Time'),
      flex: 1
    }
  ];

  const showPosition = pos => {
    if (isTopFifty && pos < 4) {
      if (pos === 1) return 'first';
      if (pos === 2) return 'second';
      if (pos === 3) return 'third';
    }
    return 'default';
  };

  useEffect(() => {
    if (data && !isTopFifty) {
      let tempList = [...list];
      tempList = data.slice(2);
      setList(tempList);
    } else {
      setList(data);
    }
    // eslint-disable-next-line
  }, [data]);

  const cpValue = item => {
    return (
      <>
        {item.CurrentScore}/
        <span className="total-score">{item.TotalScore}</span>
      </>
    );
  };

  return (
    <Container challengeMode={challengeMode}>
      <div className="list-headings">
        {labels.map((item, index) => (
          <Headings key={index} flex={item.flex}>
            {item.name}
          </Headings>
        ))}
      </div>
      {isLoading && <Spinner />}
      {!isLoading &&
        list.map((item, i) => (
          <React.Fragment key={item.UserId}>
            <Lists
              className={item.UserId === userID && 'current-user'}
              currPos={showPosition(i + 1)}
            >
              {isTopFifty && <div className="postions">{i + 1}</div>}
              <div className="list-name">{item.UserName}</div>
              <div className="list-class">{item.ClassName}</div>
              <div className="list-points">{cpValue(item)}</div>
              <div className="list-achivement">
                {milisecondToString(item.TimeTaken)}
              </div>
            </Lists>
          </React.Fragment>
        ))}
    </Container>
  );
};

export default React.memo(StudentList);
