import { call, put } from 'redux-saga/effects';
import { isNil } from 'ramda';
import GameAccessSettingService from 'services/gameAccessSetting';
import {
  fetchGameSettingClassFailed,
  fetchGameSettingClassSuccess,
  fetchGameSettingStudentFailed,
  fetchGameSettingStudentSuccess,
  saveGameSettingFailed,
  saveGameSettingSuccess
} from 'stores/gameSetting/actions';

export function* fetchGameSettingClass() {
  const data = yield call(GameAccessSettingService.fetchGameSettingClass);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchGameSettingClassSuccess(data));
    } else if (data.error) {
      yield put(fetchGameSettingClassFailed(data.error));
    }
  } else {
    yield put(fetchGameSettingClassFailed(data.error));
  }
}

export function* fetchGameSettingStudentByGroup({ GroupID }) {
  const data = yield call(
    GameAccessSettingService.fetchGameSettingStudentByGroup,
    GroupID
  );
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchGameSettingStudentSuccess(data));
    } else if (data.error) {
      yield put(fetchGameSettingStudentFailed(data.error));
    }
  } else {
    yield put(fetchGameSettingStudentFailed(data.error));
  }
}

export function* saveGameSetting({ params }) {
  const result = yield call(GameAccessSettingService.saveGameSetting, params);
  if (!isNil(result)) {
    if (isNil(result.error)) {
      yield put(saveGameSettingSuccess());
    } else {
      yield put(saveGameSettingFailed(result.error));
    }
  } else {
    yield put(saveGameSettingFailed(result.error));
  }
}
