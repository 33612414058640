import { timeSettingTypes } from 'constants/index';
import { find, propEq } from 'ramda';

export const secondsToTime = secs => {
  const hours = Math.floor(secs / (60 * 60));

  const divisor_for_minutes = secs % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  const obj = {
    h: hours,
    m: minutes,
    s: seconds
  };
  return obj;
};

export const renderTimes = (times, translation) => {
  const names = {
    h: 'H',
    m: 'Min',
    s: 'Sec'
  };
  const res = Object.keys(times)
    .map(time => {
      let parsedTime;
      if (times[time] !== 0) {
        parsedTime = `${times[time]} ${names[time]}`;
      }
      return parsedTime;
    })
    .filter(v => v !== undefined)
    .join(' ');
  return res;
};

export const getTimeType = assigmentId => {
  const timeType = find(propEq('value', parseInt(assigmentId, 10)))(
    Object.values(timeSettingTypes)
  );

  return timeType;
};

export const milisecondToString = timeMs => {
  const timeSecond = timeMs / 1000;
  const minute = Math.floor(timeSecond / 60);
  const second = Math.floor(timeSecond - minute * 60);
  const minutePadded = `${minute}`.padStart(2, '0');
  const secondPadded = `${second}`.padStart(2, '0');
  return `${minutePadded} min ${secondPadded} sec`;
};
