import qs from 'qs';
import apiClient from 'services/api';

export default (username, password) => {
  const options = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  };
  return apiClient
    .post(
      process.env.REACT_APP_TOKEN_ENDPOINT,
      qs.stringify({
        grant_type: 'password',
        username,
        password,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        scope: process.env.REACT_APP_CLIENT_SCOPE
      }),
      options
    )
    .then(result => {
      return result.data;
    })
    .catch(err => {
      return {
        error: err?.response?.data?.error_description ?? 'Login failed' // eslint-disable-line
      };
    });
};
