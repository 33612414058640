import React from 'react';

/**
 * @todo adding classNames and styles
 */
const HeaderFull = props => {
  const { children } = props;
  return (
    <div className="d-flex justify-content-between align-items-center">
      {children}
    </div>
  );
};
export default HeaderFull;
