import {
  FETCH_SYLLABUS_REQUEST,
  FETCH_SYLLABUS_SUCCESS,
  FETCH_SYLLABUS_FAILED
} from './actionTypes';

const initialState = {
  syllabus: {},
  isLoading: false,
  error: ''
};

export default function syllabus(state = initialState, action) {
  switch (action.type) {
    case FETCH_SYLLABUS_REQUEST:
      return { ...state, isLoading: true };
    case FETCH_SYLLABUS_SUCCESS:
      return {
        ...initialState,
        syllabus: action.syllabus
      };
    case FETCH_SYLLABUS_FAILED:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
}
