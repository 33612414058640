import styled from 'styled-components';

export const CPContainer = styled.div`
  background: white;
  grid-column: 1 / span 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -53px;
  font-family: 'Linotte Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;

  @media only screen and (max-width: 1024px) {
    grid-column: 1 / span 5;
  }

  @media only screen and (max-width: 480px) {
    grid-column: 1 / span 10;
    justify-content: center;
    padding: 0 1rem 1rem;
  }

  .mascot {
    position: absolute;
    width: 60%;
    bottom: 0;
    @media only screen and (max-width: 1024px) {
      width: 50%;
    }
  }
`;

export const RealTimeContainer = styled.div`
  width: 90%;
  margin-top: 25px;
  font-size: 16px;
  @media only screen and (min-width: 1920px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 1024px) {
    font-size: 12px;
    padding: 2rem 1.5rem;
  }
  .title {
    font-size: 22px;
    color: #7486ff;
    margin-bottom: 1rem;
    font-family: 'Linotte Semibold', -apple-system, BlinkMacSystemFont,
      'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
  }
  .student-name {
    width: 28%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media only screen and (max-width: 1024px) {
      width: 18%;
    }
  }
  .school {
    display: flex;
    width: 21.5vw;
    @media only screen and (max-width: 1024px) {
      width: auto;
    }
    img {
      width: 21px;
      height: 21px;
    }
    span {
      margin-left: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }
  }
  .latest-CP {
    text-align: center;
    width: 12%;
    @media only screen and (max-width: 1024px) {
      width: 17%;
    }
  }
  .time {
    width: 19%;
    @media only screen and (max-width: 1024px) {
      width: 30%;
    }
  }
  .flag {
    width: 5%;
    img {
      width: 23px;
      height: 23px;
      @media only screen and (max-width: 1024px) {
        width: 18px;
        height: 18px;
      }
    }
  }

  table td {
    padding: 0.3rem;
    color: #707070;
  }
  .table-title {
    color: #7e7e7e;
    font-family: 'Linotte Semibold', -apple-system, BlinkMacSystemFont,
      'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    tr {
      border-top: hidden;
      border-bottom: hidden;
    }
    @media only screen and (max-width: 1366px) {
      font-size: 14px;
    }
  }
  .table-body {
    td {
      border-top: none;
      font-family: 'Linotte Light', -apple-system, BlinkMacSystemFont,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
      font-size: 0.8125rem;
      @media (min-width: 1920px) {
        font-size: 1rem;
      }
    }
    tr {
      border-bottom: 1px solid #858dff;
      width: 100%;
    }
  }
`;
