import styled from 'styled-components';

export const LoginReportContainer = styled.div`
  padding-left: 195px;
  @media (max-width: 1024px) {
    padding-left: 56px;
  }
  @media (max-width: 639px) {
    padding-left: 0px;
  }
  .top-bar {
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
    position: sticky;
    right: 0;
    left: 195px;
    z-index: 100;
    top: 53px;
    @media (max-width: 1024px) {
      left: 56px;
    }
    @media (max-width: 639px) {
      left: 0px;
    }
    .title {
      text-align: center;
      margin: 25px 0px 10px;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #202020;
    }
    .top-bar-content {
      display: flex;
      justify-content: space-between;
      padding: 0px 40px;
      margin-bottom: 5px;
      align-items: baseline;
      @media (max-width: 1038px) {
        padding: 0px 20px;
      }
      .dropdown-section {
        display: flex;
        .dropdown-label {
          margin-top: 5px;
          @media (max-width: 1038px) {
            margin-top: 8px;
          }
        }
        .dropdown-custom {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 160px;
          margin-left: 10px;
          border: 1px solid rgba(112, 112, 112, 0.48);
          background-color: #ffffff;
          font-size: 14px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          @media (max-width: 882px) {
            font-size: 12px;
            width: 120px;
          }
          @media (max-width: 744px) {
            font-size: 10px;
            width: 110px;
          }
        }
        .level {
          width: 75px;
        }
        .class {
          width: 190px;
        }
      }
      .weekly-date-section {
        display: flex;
        margin-right: 200px;
        @media (max-width: 1375px) {
          margin-right: 300px;
        }
        @media (max-width: 1300px) {
          margin-right: 100px;
        }
        @media (max-width: 1000px) {
          margin-right: 0;
        }
        .nav-btn {
          border: none;
          background: none;
          margin: 0px 5px;
          &:hover {
            color: #ff5b00;
          }
        }
        .nav-btn:disabled {
          color: #d8d8d8;
        }
        .week,
        .date {
          margin-block-start: 1em;
          margin-block-end: 1em;
          border: 0.5px solid #c4c4c4;
          border-radius: 2px;
          padding: 8px 15px;
        }
        .week {
          border-bottom-right-radius: 0px;
          border-top-right-radius: 0px;
          font-size: 16px;
          color: #545454;
          @media (max-width: 882px) {
            font-size: 12px;
          }
          @media (max-width: 744px) {
            font-size: 10px;
          }
        }
        .date {
          border-left: none;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          font-weight: 600;
          font-size: 16px;
          color: #ff5b00;
          @media (max-width: 882px) {
            font-size: 12px;
          }
          @media (max-width: 744px) {
            font-size: 10px;
          }
        }
      }
      .action-button {
        button {
          padding: 10px;
          background: none;
          border: 0.5px solid #c4c4c4;
          box-sizing: border-box;
          border-radius: 2px;
          color: #545454;
          &:hover {
            color: #ff5b00;
          }
          @media (max-width: 882px) {
            font-size: 12px;
          }
          @media (max-width: 744px) {
            font-size: 10px;
          }
        }
        span {
          margin-right: 10px;
          color: #545454;
          &:hover {
            color: #ff5b00;
          }
          img {
            @media (max-width: 882px) {
              width: 18px;
            }
            @media (max-width: 744px) {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
`;

export const SeparationBar = styled.div`
  height: 30px;
  width: 100%;
  position: sticky;
  z-index: 99;
  background: #f1f1f1;
  top: 186px;
  box-shadow: inset 0px 0px -3px 6px rgba(0, 0, 0, 0.161);
  @media (max-width: 882px) {
    top: 172px;
  }
  @media (max-width: 768px) {
    top: 186px;
  }
`;
