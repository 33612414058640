import styled from "styled-components";

export const EmptyAssignmentContainer = styled.div`
  color: #46535e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  h1 {
    font-size: 0.9rem;
    line-height: 2;
  }
  a {
    font-size: 1rem;
    line-height: 1.5;
    padding: 0.3em 4em;
    border-radius: 4px;
  }
  img {
    margin: 1rem 0;
    margin-left: 1rem;
  }

  .btn-primary {
    background-color: #ff5b00;
    border-color: #ff5b00;
  }
`;
