import React from 'react';

const SkillItem = props => {
  const {
    skill: { Display, AliasSkillName }
  } = props;
  return (
    <span>
      {`Skill ${Display} `}
      <span className="skill-name">{`- ${AliasSkillName}`}</span>
    </span>
  );
};

export default SkillItem;
