import { call, put } from 'redux-saga/effects';
import { isNil } from 'ramda';
import ReportsService from 'services/reports';
import SyllabusApi from 'services/syllabus';
import {
  fetchClassProficiencyReportSuccess,
  fetchClassProficiencyReportFailed,
  fetchClassHighScoreReportSuccess,
  fetchClassHighScoreReportFailed,
  fetchStudentProficiencyReportSuccess,
  fetchStudentProficiencyReportFailed,
  fetchSampleQnSuccess,
  fetchSampleQnFailed,
  fetchWeekItemsSuccess,
  fetchWeekItemsFailed,
  fetchLoginReportSuccess,
  fetchLoginReportFailed,
  fetchTeacherReportMonthSuccess,
  fetchTeacherReportMonthFailed,
  fetchTeacherReportSpecificMonthSuccess,
  fetchTeacherReportSpecificMonthFailed,
  fetchSyllabusSuccess,
  fetchSyllabusFailed,
  fetchProficiencySkillReportSuccess,
  fetchProficiencySkillReportFailed,
  fetchHighScoreSkillReportSuccess,
  fetchHighScoreSkillReportFailed,
  fetchSkillProficiencyDetailReportSuccess,
  fetchSkillProficiencyDetailReportFailed,
  fetchSkillHighScoreDetailReportSuccess,
  fetchSkillHighScoreDetailReportFailed
} from 'stores/report/actions';

// Saga for fetching class proficiency report
export function* fetchClassProficiencyReport({ params }) {
  const queryParams = {
    classID: params.classID,
    reportLevel: params.reportLevel,
    syllabusID: params.syllabusID
  };
  const data = yield call(
    ReportsService.fetchClassProficiencyReport,
    queryParams
  );
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchClassProficiencyReportSuccess(data));
    } else {
      yield put(fetchClassProficiencyReportFailed(data.error));
    }
  } else {
    yield put(fetchClassProficiencyReportFailed(data.error));
  }
}

// Saga for fetching class highscore report
export function* fetchClassHighScoreReport({ params }) {
  const queryParams = {
    classID: params.classID,
    reportLevel: params.reportLevel,
    curriculumID: params.curriculumID
  };
  const data = yield call(
    ReportsService.fetchClassHighScoreReport,
    queryParams
  );
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchClassHighScoreReportSuccess(data));
    } else {
      yield put(fetchClassHighScoreReportFailed(data.error));
    }
  } else {
    yield put(fetchClassHighScoreReportFailed(data.error));
  }
}

// Saga for fetching student proficiency/highscore report
export function* fetchStudentProficiencyReport({ params }) {
  const queryParams = {
    studentID: params.studentID,
    classID: params.classID,
    reportLevel: params.reportLevel,
    syllabusID: params.syllabusID
  };
  const data = yield call(
    ReportsService.fetchStudentProficiencyReport,
    queryParams
  );
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchStudentProficiencyReportSuccess(data));
    } else {
      yield put(fetchStudentProficiencyReportFailed(data.error));
    }
  } else {
    yield put(fetchStudentProficiencyReportFailed(data.error));
  }
}
// Saga for fetching student proficiency/highscore report
export function* fetchProficiencySkillReport({ params }) {
  const queryParams = {
    classID: params.classID,
    reportLevel: params.reportLevel,
    curriculumID: params.curriculumID,
    topicID: params.topicID
  };
  const data = yield call(
    ReportsService.fetchProficiencySkillReport,
    queryParams
  );
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchProficiencySkillReportSuccess(data));
    } else {
      yield put(fetchProficiencySkillReportFailed(data.error));
    }
  } else {
    yield put(fetchProficiencySkillReportFailed(data.error));
  }
}

// Saga for fetching student proficiency/highscore report
export function* fetchHighScoreSkillReport({ params }) {
  const queryParams = {
    classID: params.classID,
    reportLevel: params.reportLevel,
    curriculumID: params.curriculumID,
    topicID: params.topicID
  };
  const data = yield call(
    ReportsService.fetchHighScoreSkillReport,
    queryParams
  );
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchHighScoreSkillReportSuccess(data));
    } else {
      yield put(fetchHighScoreSkillReportFailed(data.error));
    }
  } else {
    yield put(fetchHighScoreSkillReportFailed(data.error));
  }
}

// Saga for fetching sample qn
export function* fetchSampleQn({ params }) {
  const queryParams = {
    qnID: params.qnID
  };
  const data = yield call(ReportsService.fetchSampleQn, queryParams);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchSampleQnSuccess(data));
    } else {
      yield put(fetchSampleQnFailed(data.error));
    }
  } else {
    yield put(fetchSampleQnFailed(data.error));
  }
}

// Saga for fetching login report
export function* fetchLoginReport({ params }) {
  const queryParams = { classID: params.classID, weekID: params.weekID };
  const data = yield call(ReportsService.fetchLoginReport, queryParams);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchLoginReportSuccess(data));
    } else {
      yield put(fetchLoginReportFailed(data.error));
    }
  } else {
    yield put(fetchLoginReportFailed(data.error));
  }
}

// Saga for fetching week items
export function* fetchWeekItems({ params }) {
  const queryParams = { numberOfPreviousWeek: params.numberOfPreviousWeek };
  const data = yield call(ReportsService.fetchWeekItems, queryParams);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchWeekItemsSuccess(data));
    } else {
      yield put(fetchWeekItemsFailed(data.error));
    }
  } else {
    yield put(fetchWeekItemsFailed(data.error));
  }
}

// Saga for fetching teacher month report
export function* fetchTeacherMonthReport({ params }) {
  const queryParams = { year: params };
  const data = yield call(ReportsService.fetchTeacherMonthReport, queryParams);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchTeacherReportMonthSuccess(data));
    } else {
      yield put(fetchTeacherReportMonthFailed(data.error));
    }
  } else {
    yield put(fetchTeacherReportMonthFailed(data.error));
  }
}

// Saga for fetching teacher specific month report
export function* fetchTeacherSpecificMonthReport({ params }) {
  const queryParams = { year: params.year, month: params.month };
  const data = yield call(
    ReportsService.fetchTeacherSpecificMonthReport,
    queryParams
  );
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchTeacherReportSpecificMonthSuccess(data));
    } else {
      yield put(fetchTeacherReportSpecificMonthFailed(data.error));
    }
  } else {
    yield put(fetchTeacherReportSpecificMonthFailed(data.error));
  }
}
// Saga for fetching Syllabus report
export function* fetchReportSyllabus({ params }) {
  const data = yield call(SyllabusApi, params.CurriculumID, params.LevelID);
  if (!isNil(data)) {
    if (!isNil(data.error)) {
      yield put(fetchSyllabusFailed(data.error));
    } else {
      yield put(fetchSyllabusSuccess(data.Topics));
    }
  }
}

// Saga for fetching skill proficiency detail report
export function* fetchSkillProficiencyDetailReport({ params }) {
  const queryParams = {
    classID: params.classID,
    reportLevel: params.reportLevel,
    curriculumID: params.curriculumID,
    topicID: params.topicID,
    skillID: params.skillID
  };
  const data = yield call(
    ReportsService.fetchSkillProficiencyDetailReport,
    queryParams
  );
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchSkillProficiencyDetailReportSuccess(data));
    } else {
      yield put(fetchSkillProficiencyDetailReportFailed(data.error));
    }
  } else {
    yield put(fetchSkillProficiencyDetailReportFailed(data.error));
  }
}

// Saga for fetching skill high score detail report
export function* fetchSkillHighScoreDetailReport({ params }) {
  const queryParams = {
    classID: params.classID,
    reportLevel: params.reportLevel,
    curriculumID: params.curriculumID,
    topicID: params.topicID,
    skillID: params.skillID
  };
  const data = yield call(
    ReportsService.fetchSkillHighScoreDetailReport,
    queryParams
  );
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchSkillHighScoreDetailReportSuccess(data));
    } else {
      yield put(fetchSkillHighScoreDetailReportFailed(data.error));
    }
  } else {
    yield put(fetchSkillHighScoreDetailReportFailed(data.error));
  }
}
