import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  getStudentByClassRequest,
  createMyGroupRequest,
  createMyGroupReset,
  updateGroupRequest,
  updateGroupReset,
  getStudentByClassReset
} from 'stores/createGroup/actions';

import { isEmpty, clone } from 'ramda';
import history from 'helpers/history';
import { useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import CreateGroupHeader from './CreateGroupHeader';
import CreateGroupLevels from './CreateGroupLevels';
import CreateGroupStudents from './CreateGroupStudents';
import CreateGroupLists from './CreateGroupLists';

import {
  CreateGroupWrapper,
  CreateGroupBody,
  StyledCreateSuccessModal
} from './CreateGroup.styles';

const CreateGroup = () => {
  const { t } = useTranslation(['manage-class-group']);
  const dispatch = useDispatch();
  const location = useLocation();

  const getSelectedStudents =
    location.state && location.state.Students ? location.state.Students : []; // get student while editing group
  const getSelectedList =
    location.state && location.state.selectedStudents
      ? location.state.selectedStudents
      : {}; // get selected list while editing group
  const name =
    location.state && location.state.groupName ? location.state.groupName : ''; // get groupname while editing group
  const groupID = location.state && location.state.id ? location.state.id : ''; // get group-id while editing group

  const { getStudents, error, createGroup, updateGroup } = useSelector(
    state => state.createGroups
  );

  const [grpName, setGrpName] = useState(name);
  const [Students, setStudents] = useState(getSelectedStudents);
  const [selectedList, setSelectedList] = useState(getSelectedList);
  const [classSelected, setClassSelected] = useState('');
  const [classIdSelected, setClassIdSelected] = useState('');
  const [showErr, setShowErr] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  useEffect(() => {
    dispatch(getStudentByClassReset());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isEmpty(error)) {
      setShowErr(error);
    }
  }, [error]);

  useEffect(() => {
    if (!isEmpty(createGroup)) {
      resetStates('create');
    }
    // eslint-disable-next-line
  }, [createGroup]);

  useEffect(() => {
    if (!isEmpty(updateGroup)) {
      resetStates('update');
    }
    // eslint-disable-next-line
  }, [updateGroup]);

  const resetStates = actionType => {
    if (actionType === 'create') {
      dispatch(createMyGroupReset());
      setShowSuccess(true);
      return;
    }
    dispatch(updateGroupReset());

    history.push('/manage-class/show');
  };

  const changeNameHandler = val => setGrpName(val);

  const callCreateGroup = async e => {
    e.preventDefault();
    if (Students.length === 0) {
      setShowErr('Please select at least one student.');
      return;
    }
    setShowErr('');
    const reqObj = {
      Name: grpName,
      Students
    };
    if (groupID) {
      reqObj.ID = groupID;
      dispatch(updateGroupRequest(reqObj));
      return;
    }
    dispatch(createMyGroupRequest(reqObj));
  };

  const setClassAndId = (name, id) => {
    setClassSelected(name);
    setClassIdSelected(id);
  };

  const callStudents = id => dispatch(getStudentByClassRequest(id));

  const changeStudents = val => setStudents(val);

  const changeSelectedList = value => {
    // for showing list in right panel(Name list of group) when selecting a student
    let tempList = { ...selectedList };
    tempList = value;
    setSelectedList(tempList);
  };

  const deleteSelectedStudent = userId => {
    const stuIndex = Students.findIndex(elem => elem.userId === userId);
    const tempStudents = [...Students];
    tempStudents.splice(stuIndex, 1);
    setStudents(tempStudents);

    const tempList = clone(selectedList);
    Object.keys(tempList).forEach(elem => {
      const listIndex = tempList[elem].findIndex(key => key.userId === userId);
      if (listIndex !== -1) tempList[elem].splice(listIndex, 1);
    });
    setSelectedList(tempList);
  };
  const hidePopup = () => {
    setShowSuccess(false);
    history.push('/manage-class/show');
  };
  return (
    <>
      <StyledCreateSuccessModal show={showSuccess} hide={hidePopup}>
        <h5>
          {t(
            'manage-class-group:successModal.title',
            'Group Created Successfully!'
          )}
        </h5>
        <Button onClick={hidePopup}>
          {t('manage-class-group:successModal.btn', 'See My Groups')}
        </Button>
      </StyledCreateSuccessModal>
      <CreateGroupWrapper>
        <CreateGroupHeader
          Name={grpName}
          changeNameHandler={changeNameHandler}
          callCreateGroup={callCreateGroup}
          showErr={showErr}
          buttonName={
            groupID
              ? t(`manage-class-group:createGroup.save`, 'Save')
              : t(`manage-class-group:createGroup.create`, 'Create')
          }
        />
        <CreateGroupBody>
          <CreateGroupLevels
            selectClass={setClassAndId}
            callStudents={callStudents}
          />
          <CreateGroupStudents
            classSelected={classSelected}
            classIdSelected={classIdSelected}
            getStudents={getStudents}
            selectedList={selectedList}
            changeStudents={changeStudents}
            changeSelectedList={changeSelectedList}
            Students={Students}
          />
          <CreateGroupLists
            grpName={grpName}
            selectedList={selectedList}
            Students={Students}
            deleteStudent={deleteSelectedStudent}
          />
        </CreateGroupBody>
      </CreateGroupWrapper>
    </>
  );
};

export default CreateGroup;
