import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  assignPrivateGroup,
  resetAssignment
} from 'stores/worksheet/assign/actions';
import { findIndex, find, propEq } from 'ramda';

export default function CheckboxPrivateGroup(props) {
  const { t } = useTranslation(['create-assign-question']);
  const { groups } = props;
  const dispatch = useDispatch();
  const { assignedPrivateGroup } = useSelector(state => state.assign);

  const checkChecked = id => {
    return (
      findIndex(propEq('ID', parseInt(id, 10)))(assignedPrivateGroup) !== -1
    );
  };

  const onChange = event => {
    const { value } = event.target;
    // Use new variable to avoid mutating state
    let updatedData;

    if (!checkChecked(value)) {
      const newData = find(propEq('ID', parseInt(value, 10)))(groups);

      updatedData = [...assignedPrivateGroup, newData];
    } else {
      const index = findIndex(propEq('ID', parseInt(value, 10)))(
        assignedPrivateGroup
      );
      updatedData = [
        ...assignedPrivateGroup.slice(0, index),
        ...assignedPrivateGroup.slice(index + 1)
      ];
    }

    dispatch(assignPrivateGroup(updatedData));
    dispatch(resetAssignment('admin'));
  };

  return groups.length !== 0 ? (
    <>
      {groups.map(group => {
        return (
          <Form.Group key={group.ID} className="row" controlId={group.ID}>
            <Form.Check
              className="checkbox-class"
              checked={checkChecked(group.ID)}
              onChange={onChange}
              type="checkbox"
              label={group.Name}
              value={group.ID}
            />
          </Form.Group>
        );
      })}
    </>
  ) : (
    <p>
      {t(
        'create-assign-question:assignModal.noFound',
        'No Private groups found'
      )}
    </p>
  );
}
