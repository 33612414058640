import { SET_FILTERS, RESET_FILTERS } from './actionTypes';

const initialState = {
  filters: [
    {
      title: 'Assessment Objectives',
      isActive: false,
      options: {
        A01: false,
        A02: false,
        A03: false
      }
    },
    {
      title: 'Question Pools',
      isActive: false,
      options: {
        'Problem Sums': false,
        'Mechanical Sums': false,
        'High-Ability': false
      }
    },
    {
      title: 'Question Type',
      isActive: false,
      options: {
        MCQ: false,
        'Short Answer': false
      }
    }
  ],
  isLoading: false,
  error: ''
};

export default function filters(state = initialState, action) {
  switch (action.type) {
    case SET_FILTERS:
      return { ...state, filters: action.filters };
    case RESET_FILTERS:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
