import React from 'react';
import { Link } from 'react-router-dom';
import emptyWorksheetImage from 'assets/img/empty-worksheet.svg';
import arrowImage from 'assets/img/empty-worksheet-navigator.svg';
import './EmptySavedWorksheet.styles.scss';

export default function EmptySavedWorksheet() {
  return (
    <div className="content__inner content__inner-create-assign content__inner-saved-worksheet">
      <div className="navigation-arrow navigation-arrow--saved-worksheet">
        <img alt="nav-arrow" src={arrowImage} />
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center page-empty-cart">
        <img
          src={emptyWorksheetImage}
          alt="Empty Card"
          className="page-empty-cart-image"
        />
        <h1 className="text-center">
          <b> Your haven&lsquo;t saved any worksheet.</b>
        </h1>
        <h2 className="text-center">
          You can check the <b>Question Cart</b> to create the worksheet.
        </h2>
        <Link
          type="button"
          className="btn btn-lg btn-primary page-empty-cart-btn"
          to="/create-and-assign"
        >
          Create worksheet
        </Link>
      </div>
    </div>
  );
}
