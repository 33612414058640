import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Overlay, Tooltip } from 'react-bootstrap';
import './PopoverItems.styles.scss';

function PopoverItems(Props) {
  const { t } = useTranslation(['create-assign-saved']);
  const { items, placement } = Props;
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <button
        type="button"
        className="btn btn-link"
        ref={target}
        onClick={() => setShow(!show)}
      >
        {t(`create-assign-saved:assignNow.showMore`, 'Show more')}
      </button>
      <Overlay target={target.current} show={show} placement={placement}>
        <Tooltip id="popover-items">
          <div className="popover-tooltip">
            {items.map((item, index) => {
              return <div key={String(index)}>{item}</div>;
            })}
          </div>
        </Tooltip>
      </Overlay>
    </>
  );
}

PopoverItems.defaultProps = {
  placement: 'top'
};

export default PopoverItems;
