import React from 'react';
import { useDispatch } from 'react-redux';
import { nextStep } from 'stores/utils/step/actions';

function ButtonStepNext(props) {
  const dispatch = useDispatch();
  const { title, onClick, disabled } = props;

  const defaultOnClick = () => dispatch(nextStep());
  const handlePrevStep = onClick || defaultOnClick;

  return (
    <button
      type="button"
      onClick={handlePrevStep}
      className="btn btn-primary btn-next-qn question-cart-content__right-btn"
      disabled={disabled}
    >
      {title}
    </button>
  );
}

export default ButtonStepNext;
