import { combineReducers } from 'redux';
import rootSaga from 'sagas';
import configureStore from 'stores/createStore';
import curriculums from 'stores/curriculums/reducer';
import filters from 'stores/filters/reducer';
import levels from 'stores/levels/reducer';
import login from 'stores/login/reducer';
import { LOGIN_RESET } from 'stores/login/actionTypes';
import questions from 'stores/questions/reducer';
import skills from 'stores/skills/reducer';
import syllabus from 'stores/syllabus/reducer';
import topics from 'stores/topics/reducer';
import variations from 'stores/variations/reducer';
import step from 'stores/utils/step/reducer';
import worksheet from 'stores/worksheet/reducer';
import lookup from 'stores/utils/lookup/reducer';
import privateGroup from 'stores/privateGroup/reducer';
import schedule from 'stores/worksheet/schedule/reducer';
import assign from 'stores/worksheet/assign/reducer';
import assignments from 'stores/assignments/reducer';
import manageClasses from 'stores/manage-class/reducer';
import reports from 'stores/report/reducer';
import createGroups from 'stores/createGroup/reducer';
import profileSettings from 'stores/profileSettings/reducer';
import topicalWorksheet from 'stores/topical-worksheet/reducer';
import factualFluency from 'stores/factual-fluency/reducer';
import gameSetting from 'stores/gameSetting/reducer';
import userPreferences from './userPreferences/reducer';

export default () => {
  const appReducer = combineReducers({
    curriculums,
    filters,
    levels,
    login,
    lookup,
    questions,
    skills,
    syllabus,
    topics,
    variations,
    step,
    worksheet,
    privateGroup,
    schedule,
    assign,
    assignments,
    manageClasses,
    reports,
    createGroups,
    profileSettings,
    topicalWorksheet,
    factualFluency,
    userPreferences,
    gameSetting
  });

  const rootReducer = (state, action) => {
    /**
     * To purge your persist store https://stackoverflow.com/a/35641992/6366525
     */
    if (action.type === LOGIN_RESET) {
      state = undefined; /* eslint-disable-line */
    }

    return appReducer(state, action);
  };

  return configureStore(rootReducer, rootSaga);
};
