import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { NoAssignmentSVG } from 'assets/img/dashboardv2';
import { EmptyAssignmentContainer } from './EmptyAssignment.styles';

const EmptyAssignment = () => {
  const { t } = useTranslation(['track-assignment-assigned-me']);
  return (
    <EmptyAssignmentContainer>
      <img src={NoAssignmentSVG} alt="Empty Assignment" />
      <h1 className="text-center">
        <b>
          {t(
            `track-assignment-assigned-me:emptyState.emptyMsg`,
            `You haven't assigned any homework yet.`
          )}
        </b>
      </h1>
      <Link type="button" className="btn btn-primary" to="/browse-content">
        {t(
          `track-assignment-assigned-me:emptyState.browseContent`,
          'Create Assignment'
        )}
      </Link>
    </EmptyAssignmentContainer>
  );
};

export default EmptyAssignment;
