import React, { useMemo } from 'react';
import { isEmpty, isNil } from 'ramda';
import HTMLReactParser from 'html-react-parser';
import questionParser from 'helpers/questionParser';
import convertTagsToHTML from 'helpers/text/convertTagsToHTML';
import { questionTypeIDs } from 'constants/index';
import { MediaBody } from './SolutionContentPrint.styles';

export default function SolutionContentPrint({ question }) {
  // jsx renders
  const { solution, questionAnswer, correctMCQAnswers } = questionParser(
    question
  );

  const type = questionTypeIDs[question.QuestionType];

  const answer = !isEmpty(questionAnswer)
    ? Object.entries(questionAnswer.map(e => e.$))
    : '-';

  const renderSolutions = HTMLReactParser(convertTagsToHTML(solution, false), {
    replace: domNode => {
      return domNode;
    }
  });

  const renderAnswer = useMemo(() => {
    if (type === 'mcq') {
      return (
        <>
          <p>
            <span>Answer:</span>
          </p>
          {!isEmpty(correctMCQAnswers) &&
            correctMCQAnswers.map(correctAnswer => {
              return HTMLReactParser(correctAnswer, {
                replace: domNode => {
                  if (domNode.name === 'img' && !isNil(domNode.attribs?.url)) {
                    return (
                      <img
                        src={domNode.attribs?.url}
                        alt={domNode.attribs?.uniqueid}
                      />
                    );
                  }

                  if (domNode.type === 'text') {
                    return <p key={domNode.data}>{domNode.data}</p>;
                  }

                  return domNode;
                }
              });
            })}
        </>
      );
    }

    return (
      <>
        <p>
          <span>Answer:</span>
          {!isEmpty(answer) && answer === '-' ? '-' : null}
        </p>
        {!isEmpty(answer) &&
          answer !== '-' &&
          answer.map(e => {
            return <p key={e}>{e[1].Value}</p>;
          })}
      </>
    );
  }, [answer, correctMCQAnswers, type]);

  return (
    <MediaBody>
      {renderAnswer}
      <p>
        <span>Solution:</span>
      </p>
      <p>{renderSolutions}</p>
    </MediaBody>
  );
}
