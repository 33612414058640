export default (items, keyValue, keyLabel) => {
  const options = [];
  items.forEach(item => {
    options.push({
      value: item[keyValue],
      label: item[keyLabel]
    });
  });
  return options;
};
