import React from 'react';
import ContentLoader from 'react-content-loader';

export default function QuestionLoader() {
  return (
    <>
      {[...Array(2)].map((x, index) => (
        <div className="col-xl-6" key={String(index)}>
          <div className="box-white">
            <div className="box-white-header">
              <div className="row align-items-center">
                <div className="col-md">
                  <ContentLoader height="20">
                    <rect x="0" y="6" rx="4" ry="4" width="80" height="9" />
                    <rect x="120" y="6" rx="4" ry="4" width="120" height="9" />
                    <rect x="285" y="6" rx="4" ry="4" width="80" height="9" />
                    <rect x="375" y="0" rx="4" ry="4" width="25" height="20" />
                  </ContentLoader>
                </div>
              </div>
            </div>
            <div className="box-white-body">
              <div className="media">
                <div className="media-body">
                  <ContentLoader height="160">
                    <rect x="0" y="6" rx="4" ry="4" width="220" height="9" />
                    <rect x="0" y="25" rx="4" ry="4" width="260" height="9" />
                    <rect x="0" y="45" rx="4" ry="4" width="140" height="9" />
                    <rect x="0" y="100" rx="2" ry="2" width="160" height="25" />
                    <rect x="0" y="140" rx="4" ry="4" width="80" height="9" />
                    <rect x="290" y="4" rx="4" ry="4" width="110" height="60" />
                  </ContentLoader>
                </div>
              </div>
            </div>
            <div className="box-white-footer">
              <ContentLoader height="25">
                <rect x="0" y="7" rx="4" ry="4" width="40" height="9" />
                <rect x="56" y="7" rx="4" ry="4" width="60" height="9" />
                <rect x="132" y="7" rx="4" ry="4" width="100" height="9" />
                <rect x="320" y="0" rx="4" ry="4" width="80" height="25" />
              </ContentLoader>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
