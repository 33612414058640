import React from 'react';
import update from 'immutability-helper';
import { useDispatch, useSelector } from 'react-redux';
import slugify from 'helpers/text/slugify';
import { setFilters } from 'stores/filters/actions';
import Checkbox from 'components/Shared/Checkbox';
import DropdownFilter from 'components/Content/DropdownFilter';

export default function QuestionFilter() {
  const dispatch = useDispatch();
  const filters = useSelector(state => state.filters.filters);
  const onCheckFilter = (filter, index) => {
    const newFilters = update(filters, {
      [index]: {
        isActive: { $set: !filter.isActive }
      }
    });
    dispatch(setFilters(newFilters));
  };

  const checkActive = title => {
    return filters?.find(f => f.title === title)?.isActive ? 'active' : '';
  };

  const onCheckOption = (key, filter, index) => {
    const newFilters = update(filters, {
      [index]: {
        options: {
          [key]: {
            $set: !filter.options[key]
          }
        }
      }
    });
    dispatch(setFilters(newFilters));
  };

  return (
    <>
      {filters[1].isActive && (
        <div
          className="subbar-inner active d-block d-lg-none"
          data-filtergroup={0}
        >
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ height: 41 }}
          >
            <div className="subbar-label">Questions Pools</div>
            <div className="row col-sm-auto justify-content-end">
              <DropdownFilter filters={filters} onCheckFilter={onCheckFilter} />
            </div>
          </div>
        </div>
      )}
      <div
        className={`subbar-inner ${checkActive(filters[1].title)}`}
        data-filtergroup={1}
      >
        <div className="row align-items-center">
          <div className="col-md-auto d-none d-lg-block">
            <div className="subbar-label">Questions Pools</div>
          </div>
          <div className="col-md">
            <div className="row align-items-center">
              <div className="col-md">
                <div className="d-flex justify-content-between d-md-block">
                  {Object.keys(filters[1]?.options)?.map(
                    (filterOption, index) => (
                      <Checkbox
                        checked={filters[1]?.options[filterOption]}
                        id={slugify(filterOption)}
                        key={String(index)}
                        onChange={() =>
                          onCheckOption(filterOption, filters[1], 1)
                        }
                        value={filterOption}
                      />
                    )
                  )}
                </div>
              </div>
              <div className="col-md-auto d-none d-lg-block">
                <DropdownFilter
                  filters={filters}
                  onCheckFilter={onCheckFilter}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`subbar-inner ${checkActive(filters[0].title)}`}
        data-filtergroup={2}
      >
        <div className="row align-items-center">
          <div className="col-sm-auto">
            <div className="subbar-label pt-2 py-sm-0">
              Assessment Objectives
              <button
                type="button"
                className="btn btn-icon ml-3"
                data-dismiss="filter"
                onClick={() => onCheckFilter(filters[0], 0)}
              >
                <i className="icon-cross" />
              </button>
            </div>
          </div>
          <div className="col-sm">
            <div className="row align-items-center">
              <div className="col-md">
                <div className="d-block">
                  {Object.keys(filters[0]?.options)?.map(
                    (filterOption, index) => (
                      <Checkbox
                        checked={filters[0]?.options[filterOption]}
                        id={slugify(filterOption)}
                        key={String(index)}
                        onChange={() =>
                          onCheckOption(filterOption, filters[0], 0)
                        }
                        value={filterOption}
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`subbar-inner ${checkActive(filters[2].title)}`}
        data-filtergroup={3}
      >
        <div className="row align-items-center">
          <div className="col-sm-auto">
            <div className="subbar-label pt-2 py-sm-0">
              Question Type
              <button
                type="button"
                className="btn btn-icon ml-3"
                data-dismiss="filter"
                onClick={() => onCheckFilter(filters[2], 2)}
              >
                <i className="icon-cross" />
              </button>
            </div>
          </div>
          <div className="col-sm">
            <div className="row align-items-center">
              <div className="col-md">
                <div className="d-block">
                  {Object.keys(filters[2]?.options)?.map(
                    (filterOption, index) => (
                      <Checkbox
                        checked={filters[2]?.options[filterOption]}
                        id={slugify(filterOption)}
                        key={String(index)}
                        onChange={() =>
                          onCheckOption(filterOption, filters[2], 2)
                        }
                        value={filterOption}
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
