import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'ramda';
import { resetSelectedSkill } from 'stores/skills/actions';
import SkillsResultContainer from './SkillsResult.styles';

export default function SkillsResult({ isVideo, totalVideos }) {
  const { t } = useTranslation(['browse-content']);
  const dispatch = useDispatch();
  const { skills, selectedSkill } = useSelector(state => state.skills);
  const onSeeSkillDescription = e => {
    e.preventDefault();
    dispatch(resetSelectedSkill());
  };

  const data = isVideo ? totalVideos : skills;
  return (
    <SkillsResultContainer>
      <div className="subbar-inner info">
        <div className="row align-items-center">
          <div className="col-md-auto">
            <div className="subbar-label">
              Total{' '}
              <span className="color-orange" id="skillNumber">
                {data?.length ?? 'Empty'} {isVideo ? 'videos' : 'skills'}
              </span>
            </div>
          </div>
          <div className="col-md">
            {!isEmpty(selectedSkill) && (
              <a href="#noredirect" onClick={onSeeSkillDescription}>
                {t(`browse-content:main.skillDesc`, 'See Skill Description')}
              </a>
            )}
          </div>
        </div>
      </div>
    </SkillsResultContainer>
  );
}
