import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setAssigmentType } from 'stores/worksheet/actions';
import {
  assignmentTypeNames,
  assignmentTypes,
  assignmentTypeColors,
  assignmentTypeInitials
} from 'constants/index';
import isNilOrEmpty from 'helpers/isNilOrEmpty';
import worksheetType from 'assets/img/worksheet-type.svg';
import { WorksheetTypeStyled } from './ChooseWorkSheetType.styles';

export default function ChooseWorkSheetType() {
  const { t } = useTranslation(['create-assign-question']);
  const dispatch = useDispatch();
  const { assignmentType } = useSelector(state => state.worksheet);

  const assignmentTypeID = isNilOrEmpty(assignmentType)
    ? assignmentTypes.homework
    : parseInt(assignmentType, 10);

  useEffect(() => {
    dispatch(setAssigmentType(assignmentTypeID));
  }, [assignmentTypeID, dispatch]);
  const handleOnChange = e => {
    dispatch(setAssigmentType(e.target.value));
  };

  return (
    <WorksheetTypeStyled>
      <div className="worksheet-type-options">
        {Object.keys(assignmentTypeNames).map(type => (
          <div key={`type-${type}`} className="worksheet-type-choice">
            <div
              className="worksheet-type-icon"
              style={{ backgroundColor: `${assignmentTypeColors[type]}` }}
            >
              {assignmentTypeInitials[type]}
            </div>
            <div className="form-check">
              <input
                type="radio"
                id={`type-${type}`}
                value={assignmentTypes[type]}
                label={t(
                  `create-assign-question:preview.${assignmentTypeNames[type]}`,
                  assignmentTypeNames[type]
                )}
                onChange={handleOnChange}
                checked={assignmentTypeID === assignmentTypes[type]}
                className="form-check-radio"
              />{' '}
              <span className="custom-radio" />
              <Form.Label className="types-label" htmlFor={`type-${type}`}>
                {t(
                  `create-assign-question:preview.${assignmentTypeNames[type]}`,
                  assignmentTypeNames[type]
                )}
              </Form.Label>
            </div>
          </div>
        ))}
      </div>
      <div className="worksheet-type-info">
        <img src={worksheetType} alt="Choose Worksheet Type" />
      </div>
    </WorksheetTypeStyled>
  );
}
