import styled from 'styled-components';

export const SidebarContainer = styled.div`
  .accordion {
    border-bottom: 1px solid rgba(94, 50, 80, 0.38);
    .report-category {
      color: #fff0b2;
      font-size: 16px;
      font-weight: 600;
    }
    .report-category-icon {
      margin-right: 10px;
    }
  }
  .sidebar-icon {
    margin-right: 10px;
    visibility:hidden;
    @media (max-width: 1024px) {
        visibility:visible;
      }
  }
  .list-group-item a {
    padding: 5px 0px 5px 15px;
  }
    .arrow {
      margin-left: 0px;
    }
  }
`;
