import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownMenu from 'components/Shared/DropdownMenu';
import { compareValues } from 'helpers/singleClassReport';
import sortClasses from 'helpers/parseClassList';
import { useSelector } from 'react-redux';
import temp from '../../../assets/img/icon-right-arrow.svg';
import { CreateGroupLevelWrapper } from './CreateGroupLevels.styles';

let sortData = null;
const CreateGroupLevels = ({ selectClass, callStudents }) => {
  const { t } = useTranslation(['manage-class-group']);
  const { levels } = useSelector(state => state.levels);
  const { classes } = useSelector(state => state.manageClasses);
  const [filterLevel, setFilterLevel] = useState();
  const [classId, setClassId] = useState('');
  const [classData, setClassData] = useState([]);
  const LevelMenuOption = value => `P${value}`;

  useEffect(() => {
    sortData =
      classes && classes.hasOwnProperty('ClassList')
        ? sortClasses(classes.ClassList).sort(compareValues('level', 'asc'))
        : [];

    setClassData(sortData && sortData[0].classes);
    setFilterLevel(sortData[0].level);
  }, [classes]);

  const changeLevel = e => {
    setFilterLevel(e);
    let tempClass = [...classData];
    tempClass = sortData.find(elem => elem.level === e)
      ? sortData.find(elem => elem.level === e).classes
      : [];
    setClassData(tempClass);
  };

  const showStudents = val => {
    setClassId(val);
    callStudents(val);
  };

  return (
    <CreateGroupLevelWrapper>
      <div className="group-select-level">
        <DropdownMenu
          selectedValue={`${t(
            'manage-class-group:createGroup.primary',
            'Primary'
          )} ${filterLevel}`}
          valueKey={null}
          values={levels.map(level => level.ID)}
          setValue={changeLevel}
          valueTemplate={LevelMenuOption}
        />
      </div>
      <div className="group-select-class">
        {classData.map(obj => (
          <div
            key={obj.ID}
            style={
              classId === obj.ID
                ? { backgroundColor: '#FFC400' }
                : { backgroundColor: 'transparent' }
            }
            onClick={() => {
              showStudents(obj.ID);
              selectClass(obj.Name, obj.ID);
            }}
          >
            {obj.Name}
            {classId === obj.ID && (
              <img className="select-class-img" src={temp} alt="tem" />
            )}
          </div>
        ))}
      </div>
    </CreateGroupLevelWrapper>
  );
};

export default CreateGroupLevels;
