import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { editClassRequest } from 'stores/manage-class/actions';
import { LevelColors } from 'constants/index';
import EditClassIconSelector from 'components/ManageClass/EditClassIconSelector';
import { EditClassModalBodyContainer } from './EditClassModalBody.styles';

export default function EditClassModalBody({
  hide,
  selectedClass,
  selectedYear
}) {
  const { t } = useTranslation(['manage-class']);
  const dispatch = useDispatch();
  const { isLoading, editClassResult, error, icons } = useSelector(
    state => state.manageClasses
  );

  const [className, setClassName] = useState(selectedClass.Name);
  const [selectedIconID, setSelectedIconID] = useState(selectedClass.IconId);
  const currentIconIndex = icons.findIndex(icon => icon.Id === selectedIconID);

  useEffect(() => {
    if (editClassResult === true) {
      hide();
    }
  }, [editClassResult, hide]);
  return (
    <EditClassModalBodyContainer>
      <div className="edit-class-field" style={{ marginBottom: '1rem' }}>
        <span className="field-title">
          {t(`manage-class:editGroup.year`, 'Year')}
        </span>
        <p style={{ color: '#707070' }}>{selectedYear}</p>
      </div>
      <div className="edit-class-field" style={{ alignItems: 'center' }}>
        <span className="field-title">
          {t(`manage-class:editGroup.className`, 'Class name')}
        </span>
        <input
          type="text"
          value={className}
          placeholder="Enter a class name"
          onChange={e => {
            setClassName(e.target.value);
          }}
        />
      </div>

      <span
        className="selected-class-icon"
        style={{
          background: LevelColors[selectedClass.LevelId],
          color: '#fff'
        }}
      >
        {currentIconIndex !== -1 ? (
          <img src={icons[currentIconIndex].IconUrl} alt="Current Class Icon" />
        ) : (
          <h1>{selectedClass.Name.slice(0, 1).toUpperCase()}</h1>
        )}
      </span>

      <div className="edit-class-field" style={{ height: '200px' }}>
        <span className="field-title">
          {t(`manage-class:editGroup.classIcon`, 'Class icon')}
        </span>
        <EditClassIconSelector
          level={selectedClass.LevelId}
          selectedIconID={selectedIconID}
          setSelectedIconID={setSelectedIconID}
        />
      </div>

      {error && <span style={{ color: '#ff5b00' }}>{error}</span>}
      <div className="selection-action-buttons">
        <Button
          variant="outline-cancel"
          onClick={() => {
            hide();
          }}
        >
          {t(`manage-class:editGroup.cancel`, 'Cancel')}
        </Button>
        <Button
          variant="ok"
          onClick={() => {
            dispatch(
              editClassRequest({
                classID: selectedClass.ID,
                className,
                iconID: selectedIconID
              })
            );
          }}
        >
          {t(`manage-class:editGroup.save`, 'Save')}
          {isLoading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="ml-2"
            />
          )}
        </Button>
      </div>
    </EditClassModalBodyContainer>
  );
}
