import styled from 'styled-components';

export const AssignContent = styled.div`
  margin-top: 55px;
  padding-left: 205px;
  padding-right: 283px;
  @media (max-width: 1024px) {
    padding-left: 56px;
  }
  @media screen and (max-width: 1199px) {
    padding-right: 220px;
  }
  @media screen and (max-width: 991.98px) {
    padding-right: 200px;
  }
  @media screen and (max-width: 575.98px) {
    padding-right: 180px;
  }
`;
