import React from 'react';

export default function Checkbox(props) {
  const { checked, disabled, onChange, id, value } = props;
  return (
    <div className="custom-control custom-checkbox custom-control-inline">
      <input
        className="custom-control-input"
        checked={checked}
        disabled={disabled}
        id={id}
        onChange={onChange}
        type="checkbox"
      />
      {/* eslint-disable-next-line */}
      <label className="custom-control-label" htmlFor={id}>
        {value}
      </label>
    </div>
  );
}
