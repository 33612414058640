import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { resetCartQuestions } from 'stores/questions/actions';

import ModalContainerStyle from './RemoveQuestionModal.styles';

const RemoveQuestionModal = ({ hide, type, remove }) => {
  const { t } = useTranslation(['create-assign-question']);
  const dispatch = useDispatch();
  const handleRemoveAll = () => {
    dispatch(resetCartQuestions());
    hide();
  };
  return (
    <ModalContainerStyle>
      <div className="top-section">
        <button className="close-btn" onClick={() => hide()}>
          X
        </button>
        <div className="title">
          {type === 'remove-question'
            ? t(`create-assign-question:main.removeQn`, 'Remove Question')
            : t(`create-assign-question:main.removeAll`, 'Remove All')}
        </div>
        <div className="text">
          {type === 'remove-question' ? (
            t(
              `create-assign-question:main.removeMsg`,
              'Are you sure you want to remove this question?'
            )
          ) : (
            <div>
              {t(
                `create-assign-question:main.removeAllMsg`,
                'Are you sure you want to remove all the questions? '
              )}
              <br />
              {t(
                `create-assign-question:main.removeAllDesc`,
                'Your question cart will be empty.'
              )}
            </div>
          )}
        </div>
      </div>
      <hr />
      <div className="btn-section">
        <Button className="cancel" onClick={() => hide()}>
          {t(`create-assign-question:resetModal.no`, 'No')}
        </Button>
        <Button
          className="remove"
          onClick={type === 'remove-question' ? remove : handleRemoveAll}
        >
          {t(`create-assign-question:resetModal.yes`, 'Yes')}
        </Button>
      </div>
    </ModalContainerStyle>
  );
};

export default RemoveQuestionModal;
