import styled from 'styled-components';
import { proficiencyColors } from 'constants/index';

export const StudentReportPage = styled.div`
  padding-left: 195px;
  @media (max-width: 1024px) {
    padding-left: 56px;
  }
  @media (max-width: 639px) {
    padding-left: 0;
  }
  .error {
    width: 100%;
    text-align: center;
    margin-top: 2rem;
  }
`;

export const Header = styled.div`
  background: white;
  position: fixed;
  width: calc(100vw - 195px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  box-shadow: 0 3px 6px rgba(129, 129, 129, 0.16);
  z-index: 10;
  .back-button {
    color: #545454;
    cursor: pointer;
    flex: 1.5;
    /* flex-basis: 45%; */
    &:hover {
      color: #ff5b00;
    }
  }
  .student-details {
    font-size: 1rem;
    color: #363636;
    flex: 1;
  }

  .skill-detail {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 1024px) {
    width: calc(100vw - 56px);
  }
  @media (max-width: 639px) {
    width: 100vw;
  }
`;

export const Content = styled.div`
  background: #fbfbfb;
  width: ${props =>
    props.showSkillDetails ? 'calc(100vw - 555px)' : 'calc(100vw - 195px)'};
  position: relative;
  top: 4rem;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  @media (max-width: 1024px) {
    width: ${props =>
      props.showSkillDetails ? 'calc(100vw - 385px)' : 'calc(100vw - 56px)'};
  }
  @media (max-width: 639px) {
    width: ${props =>
      props.showSkillDetails ? 'calc(100vw - 329px)' : '100vw'};
  }
  .section-container {
    font-size: 0.75rem;
    padding: 1.5rem 3rem 1rem 3rem;
    border-bottom: solid 1px #e9e9e9;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    .report-level-dropdown {
      margin-right: 1rem;
      margin-bottom: 0.5rem;
      span {
        width: 120px;
      }
    }
    .report-level-dropdown,
    .syllabus-dropdown {
      display: flex;
    }
    @media screen and (max-width: 895px) {
      padding: 1rem 0 1rem 0.5rem;
    }
  }
  .skills-section {
    display: flex;
    margin: 10px 0;
    @media (max-width: 1366px) {
      margin-top: 11px;
    }
  }
  .legend-section {
    display: flex;
    align-items: center;
    font-family: 'Muli', 'sans-serif';
    margin-bottom: 10px;
    .ratings-category {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 12px;
      margin-right: 20px;
      img {
        width: 13px;
        margin-right: 3px;
      }
      .sub-text {
        color: #afacac;
        font-size: 11px;
        margin-left: 3px;
        font-weight: normal;
      }
    }
  }

  .dropdown-section {
    display: flex;
    margin-left: ${props =>
      props.showSkillDetails
        ? props.type === 'proficiency'
          ? '0px'
          : '15px'
        : '0px'};
    margin-top: ${props => (props.showSkillDetails ? '15px' : '0px')};
    @media (max-width: 1024px) {
      margin-top: 15px;
    }
  }
  .dropdown-item:active {
    color: #212529;
    background-color: transparent;
  }
  .dropdown-label {
    margin-top: 10px;
  }
  .dropdown-select-syllabus {
    width: 300px;
    margin-left: 10px;
    border: 1px solid rgba(112, 112, 112, 0.48);
    background-color: #ffffff;
    font-size: 14px;
    text-align: left;
    span {
      display: inline-block;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 93%;
    }
    :after {
      bottom: 4px;
    }
    @media (max-width: 1024px) {
      font-size: 12px;
      width: 280px;
    }
  }
  .dropdown-custom {
    width: 75px;
    margin-left: 10px;
    border: 1px solid rgba(112, 112, 112, 0.48);
    background-color: #ffffff;
    font-size: 14px;
    text-align: left;
    @media (max-width: 1024px) {
      font-size: 12px;
    }
  }
  .dropdown-toggle::after {
    position: relative;
    left: 10px;
    @media (max-width: 744px) {
      left: 3px;
      width: 6px;
      vertical-align: 1px;
    }
  }
`;

export const ProficiencySkills = styled.div`
  display: flex;
  margin-right: 15px;
  .icon {
    width: 7px;
    height: 7px;
    margin-top: 5px;
    background: ${props => proficiencyColors[props.proficiency].color};
    border-radius: 50%;
    border: solid 1px
      ${props => proficiencyColors[props.proficiency].borderColor};
  }
  span {
    font-size: 12px;
    color: #363636;
    margin-left: 5px;
  }
  .prof-descricption {
    font-size: 10px;
    display: flex;
    align-items: flex-end;
    color: #727272;
    margin-right: 15px;
  }
  @media screen and (max-width: 895px) {
    margin-right: 0 !important;
  }
`;
