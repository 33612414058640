import styled from 'styled-components';

export const MonthlyReportStyle = styled.div`
  background-color: #ffffff;
  display: grid;
  grid-template-columns: repeat(6, 120px);
  grid-column-gap: 71px;
  grid-row-gap: 51px;
  padding: 47px 114px;
  justify-content: center;
  margin: 100px 38px 50px 38px;
  @media (max-width: 1450px) {
    grid-template-columns: repeat(4, 120px);
    grid-column-gap: 50px;
    padding: 47px 80px;
  }
  @media (max-width: 850px) {
    grid-template-columns: repeat(3, 120px);
    grid-column-gap: 50px;
    padding: 47px 60px;
  }
  .monthlyContainer {
    align-self: flex-start;
    border: none;
    background-color: transparent;
  }
  .reportName {
    font-size: 12px;
  }
  .monthlyImage {
    width: 78px;
    height: 78px;
    margin-bottom: 17px;
  }
  .available {
    text-align: center;
    margin-top: -15px;
    color: #f79a41;
    font-size: 12px;
  }
`;
