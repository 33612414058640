import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { ErrorBoundary } from 'react-error-boundary';
import CPSectionFallback from 'components/Login/CPSection/CPSectionFallback';
import CPSection from '../components/Login/CPSection/CPSection';
import LoginSection from '../components/Login/LoginSection/LoginSection';
import { LoginPage } from './LoginNew.styles';

export default function LoginNew() {
  const isMobile = useMediaQuery({
    query: '(max-width: 480px)'
  });

  return (
    <LoginPage>
      {isMobile ? (
        <LoginSection isMobile={isMobile} />
      ) : (
        <>
          <ErrorBoundary FallbackComponent={CPSectionFallback}>
            <CPSection isMobile={isMobile} />
          </ErrorBoundary>
          <LoginSection isMobile={isMobile} />
        </>
      )}
    </LoginPage>
  );
}
