import styled from 'styled-components';
import Tick from 'assets/img/locale/icon-tick-red.svg';

export const StyledLanguageModal = styled.div`
  background-color: #ffffff;
  min-width: 177px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  font-family: 'Nunito';
  border: 1px solid rgb(112, 112, 112, 0.15);
  .styled-button {
    border-radius: 23px;
    padding: 0.1rem 1.5rem;
    margin: 1rem;
  }
`;

export const Item = styled.div`
  width: 100%;
  text-align: center;
  color: #2b2b2b;
  font-size: 16px;
  border-bottom: 1px solid rgb(112, 112, 112, 0.15);
  padding: 1rem 1rem 1rem 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600;

  .tick {
    width: 20px;
    height: 15px;
    background: ${({ isSelected }) => (isSelected ? `url(${Tick})` : 'none')};
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 0.5rem;
  }
`;
