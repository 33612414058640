import {
  FETCH_MY_GROUPS_REQUEST,
  FETCH_MY_GROUPS_SUCCESS,
  FETCH_MY_GROUPS_FAILED,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILED,
  DELETE_GROUP_RESET,
  CREATE_MY_GROUP_RESET,
  CREATE_MY_GROUP_REQUEST,
  CREATE_MY_GROUP_SUCCESS,
  CREATE_MY_GROUP_FAILED,
  UPDATE_GROUP_RESET,
  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAILED,
  GET_CLASS_BY_LEVEL_REQUEST,
  GET_CLASS_BY_LEVEL_SUCCESS,
  GET_CLASS_BY_LEVEL_FAILED,
  GET_STUDENT_BY_CLASS_RESET,
  GET_STUDENT_BY_CLASS_REQUEST,
  GET_STUDENT_BY_CLASS_SUCCESS,
  GET_STUDENT_BY_CLASS_FAILED
} from './actionTypes';

const initialState = {
  isLoading: false,
  error: '',
  allGroups: [],
  updateGroup: {},
  deleteGroup: false,
  createGroup: {},
  getClasses: {},
  getStudents: [],
  isDelLoading: false
};

export default function createGroups(state = initialState, action) {
  switch (action.type) {
    case FETCH_MY_GROUPS_REQUEST:
      return {
        ...state,
        error: '',
        isLoading: true
      };
    case FETCH_MY_GROUPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allGroups: action.data
      };
    case FETCH_MY_GROUPS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case DELETE_GROUP_REQUEST:
      return {
        ...state,
        error: '',
        isDelLoading: true,
        deleteGroup: false
      };
    case DELETE_GROUP_SUCCESS:
      return {
        ...state,
        error: '',
        isDelLoading: false,
        deleteGroup: true
      };
    case DELETE_GROUP_FAILED:
      return {
        ...state,
        isDelLoading: false,
        error: action.error,
        deleteGroup: false
      };
    case DELETE_GROUP_RESET:
      return {
        ...state,
        deleteGroup: false
      };
    case CREATE_MY_GROUP_RESET:
      return {
        ...state,
        error: '',
        createGroup: {}
      };
    case CREATE_MY_GROUP_REQUEST:
      return {
        ...state,
        error: '',
        isLoading: true,
        createGroup: {}
      };
    case CREATE_MY_GROUP_SUCCESS:
      return {
        ...state,
        error: '',
        isLoading: false,
        createGroup: action.data
      };
    case CREATE_MY_GROUP_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case UPDATE_GROUP_RESET:
      return {
        ...state,
        updateGroup: {}
      };
    case UPDATE_GROUP_REQUEST:
      return {
        ...state,
        error: '',
        isLoading: true,
        updateGroup: {}
      };
    case UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        error: '',
        isLoading: false,
        updateGroup: action.data
      };
    case UPDATE_GROUP_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case GET_CLASS_BY_LEVEL_REQUEST:
      return {
        ...state,
        error: '',
        isLoading: true,
        getClasses: {}
      };
    case GET_CLASS_BY_LEVEL_SUCCESS:
      return {
        ...state,
        error: '',
        isLoading: false,
        getClasses: action.data
      };
    case GET_STUDENT_BY_CLASS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case GET_STUDENT_BY_CLASS_RESET:
      return {
        ...state,
        error: '',
        isLoading: false,
        getStudents: []
      };
    case GET_STUDENT_BY_CLASS_REQUEST:
      return {
        ...state,
        error: '',
        isLoading: true
        // getStudents: []
      };
    case GET_STUDENT_BY_CLASS_SUCCESS:
      return {
        ...state,
        error: '',
        isLoading: false,
        getStudents: action.data
      };
    case GET_CLASS_BY_LEVEL_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    default:
      return state;
  }
}
