import styled from 'styled-components';

export const ClassCollection = styled.div`
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  border-bottom: 1px solid #d9d9d9;
  font-family: 'Muli';
  margin-top: 50px;
  .class-list__level {
    grid-column: 3/17;
    color: #1a1a1a;
  }
  .class-list__container {
    grid-column: 3/17;
    display: flex;
    flex-wrap: wrap;
    &__card {
      width: 171px;
      min-height: 140px;
      background-color: white;
      border-radius: 10px;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
      margin-bottom: 40px;
      margin-top: 10px;
      margin-right: 100px;
      outline-style: solid;
      outline: #ffe692 solid 5px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      @media only screen and (max-width: 1024px) {
        margin-right: 90px;
      }

      &__top {
        display: flex;
        justify-content: center;
        &__img {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 82px;
          height: 82px;
          border-radius: 50%;
          margin: 20px;
          color: #fff;
        }
      }
      &__name {
        display: flex;
        justify-content: center;
        color: #1a1a1a;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
      }
      .card-footer {
        background-color: transparent;
        padding: 10px 5px;
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }
      &__button {
        img {
          color: red;
          margin: 0px 10px;
        }
      }
    }
  }
`;
