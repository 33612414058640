import { call, put, select } from 'redux-saga/effects';
import { isNil } from 'ramda';
import fetchVariationsApi from 'services/variations';
import {
  fetchVariationsRequest,
  fetchVariationsSuccess,
  fetchVariationsFailed,
  fetchVariationsComplete,
  fetchSingleVariation,
  resetSelectedVariation
} from 'stores/variations/actions';

/**
 * The startup saga is the place to define behavior to execute when the application starts.
 */
export default function* getAllVariations(selectedSkillId) {
  const { skills } = yield select(state => state.skills);
  // const variations = [];
  // eslint-disable-next-line
  for (let index = 0; index < skills.length; index++) {
    const variations = [];
    const skill = skills[index];
    if (selectedSkillId !== skill.SIOID) {
      const variationsPerSkill = yield call(fetchVariationsApi, skill.SIOID);
      if (!isNil(variationsPerSkill)) {
        if (isNil(variationsPerSkill.error)) {
          yield variations.push(...variationsPerSkill);
          yield put(fetchVariationsSuccess(variations));
        } else {
          yield put(fetchVariationsFailed(variationsPerSkill.error));
        }
      }
    }
  }
  yield put(fetchVariationsComplete(true));
}

export function* refreshVariations() {
  yield put(resetSelectedVariation());
  yield call(getAllVariations);
}

export function* callGetVariations({ skill }) {
  const variationsPerSkill = yield call(fetchVariationsApi, skill.skill.SIOID);
  const variations = [];
  if (!isNil(variationsPerSkill)) {
    if (isNil(variationsPerSkill.error)) {
      yield variations.push(...variationsPerSkill);
      yield put(fetchVariationsSuccess(variations));
      yield put(fetchVariationsComplete(false));
    } else {
      yield put(fetchVariationsFailed(variationsPerSkill.error));
    }
  }
}

export function* callSelectedSkill({ selectedSkill }) {
  yield put(resetSelectedVariation());
  yield put(fetchVariationsRequest());
  yield put(fetchSingleVariation({ skill: selectedSkill }));
}
