import { call, put, select } from 'redux-saga/effects';
import { isNil, isEmpty } from 'ramda';
import getUserCurriculums from 'sagas/curriculums';
import fetchSyllabusApi from 'services/syllabus';
import {
  // fetchSyllabusRequest,
  fetchSyllabusSuccess,
  fetchSyllabusFailed
} from 'stores/syllabus/actions';
import { setVideosRequest } from 'stores/levels/actions';
import { fetchTopicsSuccess, resetSelectedTopic } from 'stores/topics/actions';

/**
 * The startup saga is the place to define behavior to execute when the application starts.
 */
export default function* syllabus() {
  // yield put(fetchSyllabusRequest());
  let { selectedCurriculum } = yield select(state => state.curriculums);
  const { selectedLevel } = yield select(state => state.levels);
  const { selectedTopic } = yield select(state => state.topics);
  // If there's no selected curriculum, fetch user's curriculums first and
  // select the first from the results
  if (!selectedCurriculum.CurriculumID) {
    yield call(getUserCurriculums);
    ({ selectedCurriculum } = yield select(state => state.curriculums));
  }
  const checkLevelAvailable =
    !isEmpty(selectedCurriculum?.Levels) &&
    selectedCurriculum.Levels.find(item => item.ID === selectedLevel.ID);
  // eslint-disable-next-line no-nested-ternary
  const levelId = isEmpty(selectedCurriculum?.Levels)
    ? -1
    : !isNil(checkLevelAvailable)
    ? selectedLevel.ID
    : selectedCurriculum.Levels[0].ID;
  const data = yield call(
    fetchSyllabusApi,
    selectedCurriculum.CurriculumID,
    levelId
  );
  if (!isNil(data)) {
    if (!isNil(data.error)) {
      // if the API return error message
      yield put(fetchSyllabusFailed(data.error));
    } else {
      yield put(
        setVideosRequest(selectedLevel, selectedCurriculum.CurriculumID)
      );
      yield put(fetchSyllabusSuccess(data));
      // set topics from syllabus and reset selectedTopic if the level change
      const topics = data.Topics ?? [];
      yield put(fetchTopicsSuccess(topics));
      // if selectedTopic isn't in the new topics, reset selected topic
      const topicIndex = topics.findIndex(
        topic => topic.ID === selectedTopic?.ID
      );
      if (topicIndex === -1) {
        yield put(resetSelectedTopic());
      }
    }
  }
}
