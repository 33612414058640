import styled, { css } from 'styled-components';
import { MultiClassReportPage } from './MultiClassReport.styles';

export const SingleClassReportPage = styled(MultiClassReportPage)`
  .report-progress {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
    .bg-progress-bar-submission {
      background-color: #ff5b00;
    }
  }

  .submission-details-url {
    display: flex;
    flex-direction: row;
    align-items: center;
    .url-text {
      color: #ff5b00;
    }
    .copy-button {
      border: solid 1px #d8d8d8;
      background: #fff;
      padding: 0.2rem 1rem;
      color: #ff5b00;
      margin-left: 1rem;
      border-radius: 2px;

      &:hover {
        border: solid 1px #ff5b00;
        background: #fff7f3;
      }
    }
  }

  .report-scores {
    min-height: 350px;
    margin-top: 1rem;
    .report-scores-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .select-sorting-order {
      padding-left: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      .sorting-dropdown {
        margin-left: 1rem;
      }
      #dropdown-sorting {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border: solid 1px #707070;
        width: 190px;
        font-size: 12px;
      }
      .sorting-dropdown-item {
        width: 190px;
        font-size: 12px;
      }
    }
    .bg-progress-bar-score {
      background-color: #55c92b;
    }
    .progress {
      background-color: #f9625c;
    }

    .progress-not-submitted.progress {
      background-color: #dedede;
    }
    .cell-score {
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow-x: auto;
      white-space: nowrap;
    }
    .header-cell-question {
      display: flex;
      flex-direction: column;
      color: #9a9a9a;
      font-weight: 600;
      font-size: 14px;
      background: #f7f7f7;
      align-items: center;
      border-radius: 8px;
      height: 70px;
      width: 40px;
      padding: 6px 0;
      cursor: pointer;
      margin: 0 auto;
      &.chart {
        justify-content: space-between;
        padding: 10px 0 6px;
      }
    }
  }

  .score-table {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .cell-answer {
      height: 43px;
    }
    .score-table-left {
      align-self: flex-end;
      .cell-student-name {
        overflow-x: hidden;
        white-space: nowrap;
      }
    }
    .score-table-right {
      flex-grow: 0;
    }
  }
`;
export const StyledSwitchContainer = styled.div`
  background: #dddddd;
  color: #484848;
  padding: 3px 4px;
  border-radius: 4px;
  display: flex;
  margin-right: 1rem;
`;
export const StyledSwitch = styled.div`
  min-width: 70px;
  min-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px 0 0 4px;
  background: white;
  border: 1px solid #cbcbcb;
  /* border-right: none; */
  font-size: 12px;
  :last-child {
    border-radius: 0 4px 4px 0;
    border-left: ${({ active }) => (active ? '1px solid #ffac86' : 'none')};
  }
  :first-child {
    border-right: ${({ active }) => (active ? '1px solid #ffac86' : 'none')};
  }
  @media (max-width: 1223px) {
    min-width: 100px;
  }
  ${({ active }) =>
    active &&
    css`
      background: #fff7f2;
      border: 1px solid #ffac86;
      color: #ff5b00;
    `}
`;
