import {
  SET_START_END_DATE,
  SET_RELEASE_DATE,
  SET_TIMEZONE,
  SET_VISIBILITY,
  SET_KOKO_CREDIT,
  ASSIGN_CLASS,
  ASSIGN_GROUP,
  ASSIGN_STUDENT,
  ASSIGN_PRIVATE_GROUP,
  ASSIGN_ADMIN_GROUP,
  ASSIGN_LEVEL,
  RESET_SCHEDULE,
  RESET_ASSIGNMENT
} from './actionTypes';

export const setStartEndDate = (startDate, endDate) => {
  return { type: SET_START_END_DATE, startDate, endDate };
};

export const setReleaseDate = releaseDate => {
  return { type: SET_RELEASE_DATE, releaseDate };
};

export const setTimeZone = timezone => {
  return { type: SET_TIMEZONE, timezone };
};

export const setAssignmentVisibility = assignmentVisibility => {
  return { type: SET_VISIBILITY, assignmentVisibility };
};

export const setKokoCredit = kokoCredit => {
  return { type: SET_KOKO_CREDIT, kokoCredit };
};

export const assignClass = classes => {
  return { type: ASSIGN_CLASS, classes };
};

export const assignGroup = groups => {
  return { type: ASSIGN_GROUP, groups };
};

export const assignStudent = students => {
  return { type: ASSIGN_STUDENT, students };
};

export const assignPrivateGroup = privateGroups => {
  return { type: ASSIGN_PRIVATE_GROUP, privateGroups };
};

export const assignAdminGroup = adminGroups => {
  return { type: ASSIGN_ADMIN_GROUP, adminGroups };
};

export const assignLevel = level => {
  return { type: ASSIGN_LEVEL, level };
};

export const resetSchedule = () => {
  return { type: RESET_SCHEDULE };
};

export const resetAssignment = assignmentType => {
  return {
    type: RESET_ASSIGNMENT,
    assignmentType
  };
};
