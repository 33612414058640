import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { nextStep } from 'stores/utils/step/actions';
import HeaderFull from 'components/CreateAndAssign/HeaderFull';
import CircleType from 'components/CreateAndAssign/CircleType';
import Border from 'components/Shared/Border';
import AssignRadio from 'components/CreateAndAssign/Schedule/AssignRadio';
import AssignResult from 'components/CreateAndAssign/Schedule/AssignResult';
import { Col } from 'react-bootstrap';
import hasProp from 'helpers/hasProp';
import './ScheduleClass.styles.scss';

export default function ScheduleClass() {
  const { t } = useTranslation(['create-assign-saved']);
  const dispatch = useDispatch();
  const { selectedWorksheet } = useSelector(state => state.worksheet);

  const {
    assignedClass,
    assignedGroup,
    assignedStudent,
    assignedPrivateGroup,
    assignedAdminGroup,
    assignedLevel
  } = useSelector(state => state.schedule);

  const hasAssignees =
    assignedClass.length > 0 ||
    assignedGroup.length > 0 ||
    assignedStudent.length > 0 ||
    assignedPrivateGroup.length > 0 ||
    (hasProp(assignedAdminGroup, 'ID') && assignedLevel !== '');

  const handleNext = () => {
    if (hasAssignees) {
      dispatch(nextStep());
    }
  };

  return (
    <div className="container schedule-content schedule-content-class">
      <HeaderFull>
        <div className="d-flex flex-row align-items-center justify-content-center schedule-content-title">
          <CircleType
            typeId={selectedWorksheet.PresentationType.AssignmentTypeID}
          />
          <h1>{selectedWorksheet.Title}</h1>
        </div>
        <div className="schedule-content-nav-btn">
          <Link
            type="button"
            className="btn btn-outline-primary"
            to="/create-and-assign/saved-worksheet"
          >
            {t(`create-assign-saved:scheduleWo.cancel`, 'Cancel')}
          </Link>
          <button
            onClick={handleNext}
            type="button"
            className="btn btn-primary"
          >
            {t(`create-assign-saved:scheduleWo.next`, 'Next')}
          </button>
        </div>
      </HeaderFull>
      <Border />
      <div className="d-flex assign-modal">
        <Col md={9} className="assign-modal-form assign-private">
          <AssignRadio />
        </Col>
        <Col md={3} className="assign-modal-info assign-class">
          <AssignResult
            assignedClass={assignedClass}
            assignedGroup={assignedGroup}
            assignedStudent={assignedStudent}
            assignedPrivateGroup={assignedPrivateGroup}
            assignedAdminGroup={assignedAdminGroup}
            assignedLevel={assignedLevel}
            hasAssignees={hasAssignees}
          />
        </Col>
      </div>
    </div>
  );
}
