import apiClient from 'services/api';

export const fetchLevelsApi = () => {
  return apiClient
    .get(`Lookup/Levels`)
    .then(result => {
      return result.data?.Result ?? [];
    })
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Levels fetch failed' // eslint-disable-line
      };
    });
};

export const getLevelVideos = (CurriculumID, LevelID) => {
  return apiClient
    .get(`video/GetVideo/${CurriculumID}/${LevelID}`)
    .then(result => {
      return result.data?.Result ?? [];
    })
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Levels videos fetch failed' // eslint-disable-line
      };
    });
};

export default {
  fetchLevelsApi,
  getLevelVideos
};
