export const FETCH_ASSIGNMENTS_REQUEST = 'FETCH_ASSIGNMENTS_REQUEST';
export const FETCH_ASSIGNMENTS_SUCCESS = 'FETCH_ASSIGNMENTS_SUCCESS';
export const FETCH_ASSIGNMENTS_FAILED = 'FETCH_ASSIGNMENTS_FAILED';
export const SET_YEARS = 'SET_YEARS';
export const SET_NUMBER_OF_PAGES = 'SET_NUMBER_OF_PAGES';
export const FETCH_TEACHERS_REQUEST = 'FETCH_TEACHERS_REQUEST';
export const FETCH_TEACHERS_SUCCESS = 'FETCH_TEACHERS_SUCCESS';
export const FETCH_TEACHERS_FAILED = 'FETCH_TEACHERS_FAILED';
export const FETCH_TEACHER_ASSIGNMENTS_REQUEST =
  'FETCH_TEACHER_ASSIGNMENTS_REQUEST';
export const FETCH_TEACHER_ASSIGNMENTS_SUCCESS =
  'FETCH_TEACHER_ASSIGNMENTS_SUCCESS';
export const FETCH_TEACHER_ASSIGNMENTS_FAILED =
  'FETCH_TEACHER_ASSIGNMENTS_FAILED';
export const FETCH_MULTI_CLASS_REPORT_REQUEST =
  'FETCH_MULTI_CLASS_REPORT_REQUEST';
export const FETCH_MULTI_CLASS_REPORT_SUCCESS =
  'FETCH_MULTI_CLASS_REPORT_SUCCESS';
export const FETCH_MULTI_CLASS_REPORT_FAILED =
  'FETCH_MULTI_CLASS_REPORT_FAILED';
export const FETCH_SINGLE_CLASS_REPORT_REQUEST =
  'FETCH_SINGLE_CLASS_REPORT_REQUEST';
export const FETCH_SINGLE_CLASS_REPORT_SUCCESS =
  'FETCH_SINGLE_CLASS_REPORT_SUCCESS';
export const FETCH_SINGLE_CLASS_REPORT_FAILED =
  'FETCH_SINGLE_CLASS_REPORT_FAILED';
export const FETCH_CSV_CLASS_REPORT_REQUEST = 'FETCH_CSV_CLASS_REPORT_REQUEST';
export const FETCH_CSV_CLASS_REPORT_SUCCESS = 'FETCH_CSV_CLASS_REPORT_SUCCESS';
export const FETCH_CSV_CLASS_REPORT_FAILED = 'FETCH_CSV_CLASS_REPORT_FAILED';
export const SET_LATEST_YEAR = 'SET_LATEST_YEAR';
export const SET_ASSIGNMENT_SUMMARY_HISTORY = 'SET_ASSIGNMENT_SUMMARY_HISTORY';
export const SET_SELECTED_TEACHER = 'SET_SELECTED_TEACHER';
export const CLEAR_ASSIGNMENTS = 'CLEAR_ASSIGNMENTS';
export const CLEAR_SINGLE_CLASS_REPORT = 'CLEAR_SINGLE_CLASS_REPORT';
export const CLEAR_MULTI_CLASS_REPORT = 'CLEAR_MULTI_CLASS_REPORT';
export const DELETE_ASSIGNED_HW_REQUEST = 'DELETE_ASSIGNED_HW_REQUEST';
export const DELETE_ASSIGNED_HW_SUCCESS = 'DELETE_ASSIGNED_HW_SUCCESS';
export const DELETE_ASSIGNED_HW_FAILED = 'DELETE_ASSIGNED_HW_FAILED';
export const RESET_ERROR = 'RESET_ERROR';
export const RESET_DELETE_HW_RESULT = 'RESET_DELETE_HW_RESULT';
export const CHANGE_TITLE_DATE_REQUEST = 'CHANGE_TITLE_DATE_REQUEST';
export const CHANGE_TITLE_DATE_SUCCESS = 'CHANGE_TITLE_DATE_SUCCESS';
export const CHANGE_TITLE_DATE_FAILED = 'CHANGE_TITLE_DATE_FAILED';
export const CHANGE_TITLE_DATE_RESET = 'CHANGE_TITLE_DATE_RESET';
export const MARK_ANSWER_RIGHT_REQUEST = 'MARK_ANSWER_RIGHT_REQUEST';
export const MARK_ANSWER_RIGHT_SUCCESS = 'MARK_ANSWER_RIGHT_SUCCESS';
export const MARK_ANSWER_RIGHT_FAILED = 'MARK_ANSWER_RIGHT_FAILED';
