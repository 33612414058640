import styled from 'styled-components';

export const OtherAssignmentsPage = styled.div`
  padding-top: 5rem;
  color: #46535e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 1.5625rem;
    line-height: 1;
  }

  h2 {
    font-size: 1.25rem;
    line-height: 1;
    margin-bottom: 1em;
  }

  &-btn {
    font-size: 1.375rem;
    line-height: 1.5;
    padding: 0.375em 3.5em;
    border-radius: 4px;
  }

  img {
    margin-bottom: 2rem;
  }

  .no-assignments-error {
    margin-top: 1rem;
    font-size: 1rem;
    color: #ff5b00;
  }

  .btn-show-button {
    width: 270px;
    &:disabled {
      background: #d8d8d8;
      border: 1px solid #d8d8d8;
      cursor: not-allowed;
    }
  }

  @media (max-width: 968px) {
    padding: 0 2rem;
  }
`;
