import styled from 'styled-components';

export const TrackAssignmentPage = styled.main`
  .assignment-content {
    padding-right: 2rem;
    position: absolute;
    top: 50px;
    left: 240px;
    width: calc(100vw - 240px);
  }

  @media (max-width: 1024px) {
    .assignment-content {
      padding-right: 2rem;
      position: absolute;
      top: 50px;
      left: 80px;
      width: calc(100vw - 80px);
    }
  }
`;
