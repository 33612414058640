import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  assignWorksheet,
  saveWorksheet,
  resetWorksheet,
  setSelectedQuestions
} from 'stores/worksheet/actions';
import { resetCartQuestions } from 'stores/questions/actions';
import { groupBy, isNil } from 'ramda';
import { Modal } from 'react-bootstrap';
import {
  assignReferenceType,
  worksheetStatus,
  assignmentTypes
} from 'constants/index';
import DateTimeRangePicker from 'components/CreateAndAssign/DateTimeRangePicker';
import DateTimePicker from 'components/CreateAndAssign/DateTimePicker';
import SidebarRight from 'components/Sidebar/SidebarRight';
import ConfirmModal from 'components/Alert/ConfirmModal';
import InfoSavedWorksheetModal from 'components/Alert/InfoSavedWorksheetModal';
import InfoAssignedWorksheetModal from 'components/Alert/InfoAssignedWorksheetModal';
import InfoLinkWorksheetModal from 'components/Alert/InfoLinkWorksheetModal';
import CartResetModal from 'components/Alert/CartResetModal';
import history from 'helpers/history';
import hasProp from 'helpers/hasProp';
import AssignModal from './AssignModal';
import { AssignSidebarStyle } from './AssignSidebar.styles';
/**
 * @todo adding classNames and styles
 */
export default function AssignSidebar({
  isEditSavedWorksheet,
  isCreateHomework,
  isCreateFactualFluency
}) {
  const { t } = useTranslation(['create-assign-question']);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  // message alert
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [showAssign, setShowAssign] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [showResetCart, setShowResetCart] = useState(false);

  const {
    title,
    presentationType,
    assignmentType,
    assignedClass,
    assignedGroup,
    assignedStudent,
    assignedPrivateGroup,
    assignedAdminGroup,
    assignedLevel,
    timeLimit,
    selectedWorksheet,
    isLoading,
    error
  } = useSelector(state => state.worksheet);
  const { cartQuestions } = useSelector(state => state.questions);

  const NumberOfKokoCredits = isNil(selectedWorksheet.NumberOfKokoCredits)
    ? 5
    : selectedWorksheet.NumberOfKokoCredits;

  const [tempAssignedClass, setTempAssignedClass] = useState([]);
  const [tempAssignedGroup, setTempAssignedGroup] = useState([]);
  const [tempAssignedStudent, setTempAssignedStudent] = useState([]);
  const [tempAssignedPrivateGroup, setTempAssignedPrivateGroup] = useState([]);
  const [tempAssignedAdminGroup, setTempAssignedAdminGroup] = useState({});
  const [tempAssignedLevel, setTempAssignedLevel] = useState('');

  const hasAssignees =
    tempAssignedClass.length > 0 ||
    tempAssignedGroup.length > 0 ||
    tempAssignedStudent.length > 0 ||
    tempAssignedPrivateGroup.length > 0 ||
    (hasProp(tempAssignedAdminGroup, 'ID') && assignedLevel !== '');

  // Save Worksheet Event Handler
  const onClickSave = () => {
    const params = {
      Title: title,
      Instructions: title,
      WorksheetStatus: worksheetStatus.publish,
      PresentationTypeID: presentationType,
      AssignmentTypeID: assignmentType,
      NumberOfKokoCredits,
      TimeLimit: timeLimit,
      isEditSavedWorksheet: false,
      isCreateHomework: false,
      isCreateFactualFluency: false
    };
    if (isCreateHomework) params.isCreateHomework = true;

    if (isCreateFactualFluency) params.isCreateFactualFluency = true;

    if (isEditSavedWorksheet) params.isEditSavedWorksheet = true;

    dispatch(saveWorksheet(params));
    setShowSave(true);
  };

  const showModal = event => {
    event.preventDefault();
    setShow(true);
  };

  // Save & Assign Event Handler
  const handleSaveAndAssign = event => {
    event.preventDefault();
    if (hasAssignees) {
      setShowConfirm(true);
    } else {
      setShow(true);
    }
  };

  const hideModal = () => {
    setShow(false);
  };

  const renderAssigneeItem = items => {
    return items.map(item => (
      <p key={item.ID}>
        <a href="#assign" onClick={showModal}>
          {item.Name}
        </a>
      </p>
    ));
  };

  const [startDate, setStartDate] = useState(moment().startOf('day'));
  const [endDate, setEndDate] = useState(
    moment()
      .add(3, 'day')
      .set({ hour: 23, minute: 59 })
  );
  const [releaseDate, setReleaseDate] = useState(
    moment()
      .add(4, 'day')
      .startOf('day')
  );

  const applyCallback = (startDateValue, endDateValue) => {
    setStartDate(startDateValue);
    setEndDate(endDateValue);
  };

  const applyCallbackRelease = (e, picker) => {
    setReleaseDate(picker.startDate);
  };

  const groupedStudents = groupBy(
    student => student.ClassName,
    tempAssignedStudent
  );

  const saveAssignedWorksheet = () => {
    // compile data for assigning
    const assignedClassData = assignedClass.map(classData => ({
      referenceId: classData.ID,
      type: assignReferenceType.class
    }));
    const assignedGroupData = assignedGroup.map(groupData => ({
      referenceId: groupData.ID,
      type: assignReferenceType.group
    }));
    const assignedPrivateGroupData = assignedPrivateGroup.map(
      privateGroupData => ({
        referenceId: privateGroupData.ID,
        type: assignReferenceType.group
      })
    );
    const assignedStudentData = assignedStudent.map(studentData => ({
      referenceId: studentData.UserId,
      type: assignReferenceType.student
    }));
    let assignedAdminData = null;
    if (hasProp(assignedAdminGroup, 'ID')) {
      switch (assignedAdminGroup.ID) {
        case 'premium':
          assignedAdminData = {
            referenceId: parseInt(assignedLevel, 10),
            type: assignReferenceType.level,
            isPremium: 1
          };
          break;
        case 'retail':
          assignedAdminData = {
            referenceId: parseInt(assignedLevel, 10),
            type: assignReferenceType.level,
            isRetail: 1
          };
          break;
        case 'koobits':
          assignedAdminData = {
            referenceId: parseInt(assignedLevel, 10),
            type: assignReferenceType.level
          };
          break;
        default:
          assignedAdminData = null;
      }
    }
    const assignmentsArray =
      assignedAdminData !== null
        ? [assignedAdminData]
        : [
            ...assignedClassData,
            ...assignedGroupData,
            ...assignedStudentData,
            ...assignedPrivateGroupData
          ];

    const data = {
      // data needed for saving worksheet
      Title: title,
      Instructions: title,
      WorksheetStatus: worksheetStatus.publish,
      PresentationTypeID: presentationType,
      AssignmentTypeID: assignmentType,
      TimeLimit: timeLimit,
      NumberOfKokoCredits,
      // data needed for assignment
      startDate: startDate.format('YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ'),
      endDate: endDate.format('YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ'),
      assignments: assignmentsArray,
      isCreateHomework: isCreateHomework || false,
      isEditSavedWorksheet: isEditSavedWorksheet || false,
      isCreateFactualFluency: isCreateFactualFluency || false
    };
    if (assignmentType === assignmentTypes.test) {
      data.resultDate = releaseDate.format('YYYY-MM-DDTHH:mm:ss.SSSSSSSZZ');
    }
    const isPublish = true;
    dispatch(assignWorksheet(data, isPublish));
    setShowConfirm(false);
    setShowAssign(true);
  };

  const onConfirm = () => {
    setShow(false);
    const newAssignedClass = [...assignedClass];
    setTempAssignedClass(newAssignedClass);

    const newAssignedGroup = [...assignedGroup];
    setTempAssignedGroup(newAssignedGroup);

    const newAssignedStudent = [...assignedStudent];
    setTempAssignedStudent(newAssignedStudent);

    const newAssignedPrivateGroup = [...assignedPrivateGroup];
    setTempAssignedPrivateGroup(newAssignedPrivateGroup);

    const newAssignedAdminGroup = assignedAdminGroup;
    setTempAssignedAdminGroup(newAssignedAdminGroup);
    const newAssignedLevel = assignedLevel;
    setTempAssignedLevel(newAssignedLevel);
  };

  // button modal

  return (
    <>
      <Modal show={show} onHide={hideModal}>
        <AssignModal onConfirm={onConfirm} />
      </Modal>

      <ConfirmModal
        isShow={showConfirm}
        onConfirm={saveAssignedWorksheet}
        onClose={() => setShowConfirm(false)}
        title={t(
          `create-assign-question:saveAssignModal.assignHw`,
          'Assign Homework Now'
        )}
        loading={isLoading}
      >
        <p>
          {t(
            `create-assign-question:saveAssignModal.assignMsg`,
            'Homework will be assigned to the classes/ students that you have selected.'
          )}
        </p>
        <p>
          {t(
            `create-assign-question:saveAssignModal.proceed`,
            'Do you want to proceed?'
          )}
        </p>
      </ConfirmModal>

      <InfoSavedWorksheetModal
        isShow={showSave}
        onConfirm={() => {
          setShowSave(false);
          setShow(true);
        }}
        isLoading={isLoading}
        onClose={() => {
          setShowSave(false);
          if (
            isCreateHomework ||
            isEditSavedWorksheet ||
            isCreateFactualFluency
          ) {
            dispatch(resetWorksheet());
            history.push('/create-and-assign/saved-worksheet');
          } else {
            setShowResetCart(true);
          }
        }}
        onCloseError={() => setShowSave(false)}
        error={error}
      />

      <InfoAssignedWorksheetModal
        isShow={showAssign}
        onConfirm={() => {
          setShowAssign(false);
          setShowLink(true);
        }}
        onClose={() => setShowAssign(false)}
        error={error}
        isLoading={isLoading}
      />

      <InfoLinkWorksheetModal
        isShow={showLink}
        onConfirm={() => {
          setShowLink(false);
          if (
            isCreateHomework ||
            isEditSavedWorksheet ||
            isCreateFactualFluency
          ) {
            dispatch(resetWorksheet());
            history.push('/create-and-assign/saved-worksheet');
          } else {
            setShowResetCart(true);
          }
        }}
        onClose={() => {
          setShowLink(false);
          if (
            isCreateHomework ||
            isEditSavedWorksheet ||
            isCreateFactualFluency
          ) {
            dispatch(resetWorksheet());
            history.push('/create-and-assign/saved-worksheet');
          } else {
            setShowResetCart(true);
          }
        }}
      />
      <CartResetModal
        isShow={showResetCart}
        onConfirm={() => {
          dispatch(resetCartQuestions());
          dispatch(resetWorksheet());
          history.push('/create-and-assign/saved-worksheet');
          setShowResetCart(false);
        }}
        onClose={() => {
          dispatch(resetWorksheet());
          dispatch(setSelectedQuestions(cartQuestions));
          setShowResetCart(false);
          history.push('/create-and-assign/saved-worksheet');
        }}
      />
      <SidebarRight className="save-assign__sidebar">
        <h4 className="save-assign__title">
          {' '}
          {t(
            `create-assign-question:assign.assignSettings`,
            'Assignment Settings'
          )}
        </h4>
        <div className="save-assign__content-action">
          <AssignSidebarStyle className="save-assign__content-action-item save-assign_sidebar-align">
            <i className="save-assign__content-action-icon icon-class-group" />
            <div className="save-assign__content-action-caption">
              <p>{t(`create-assign-question:assign.assignTo`, 'Assign to')}</p>
              {!hasAssignees && (
                <a href="#assign" onClick={showModal}>
                  {t(
                    `create-assign-question:assign.clickChoose`,
                    'Click here to choose'
                  )}
                </a>
              )}
              {tempAssignedClass.length > 0 && (
                <div className="class-list">
                  <h5>
                    {t(`create-assign-question:assignModal.class`, 'Class')}
                  </h5>
                  {renderAssigneeItem(tempAssignedClass)}
                </div>
              )}
              {tempAssignedGroup.length > 0 && (
                <div className="group-list">
                  <h5>
                    {t(`create-assign-question:assignModal.group`, 'Group')}
                  </h5>
                  {renderAssigneeItem(tempAssignedGroup)}
                </div>
              )}
              {tempAssignedPrivateGroup.length > 0 && (
                <div className="group-list">
                  <h5>
                    {t(
                      `create-assign-question:assignModal.pvtGroup`,
                      'Private Group'
                    )}
                  </h5>
                  {renderAssigneeItem(tempAssignedPrivateGroup)}
                </div>
              )}
              {hasProp(tempAssignedAdminGroup, 'ID') && (
                <div className="group-list">
                  <h5>
                    {t(
                      `create-assign-question:assignModal.adminGroup`,
                      'Admin Group'
                    )}
                  </h5>
                  <p>
                    <a href="#assign" onClick={showModal}>
                      {tempAssignedAdminGroup.Name}
                    </a>
                  </p>
                </div>
              )}
              {tempAssignedLevel !== '' && (
                <div className="group-list">
                  <h5>
                    {t(`create-assign-question:assignModal.level`, 'Level')}
                  </h5>
                  <p>
                    <a href="#assign" onClick={showModal}>
                      {`Primary ${tempAssignedLevel}`}
                    </a>
                  </p>
                </div>
              )}
              {tempAssignedStudent.length > 0 && (
                <div className="group-list">
                  <h5>
                    {t(
                      `create-assign-question:assignModal.indvStud`,
                      'Individual Students'
                    )}
                  </h5>
                  {Object.entries(groupedStudents).map(
                    ([studentClass, classOfStudents]) => {
                      return (
                        <React.Fragment key={studentClass}>
                          <p className="text-muted mt-2">{studentClass}</p>
                          {classOfStudents.map(singleStudent => (
                            <div key={singleStudent.UserId}>
                              <a href="#assign" onClick={showModal}>
                                {singleStudent.FullName}
                              </a>
                            </div>
                          ))}
                        </React.Fragment>
                      );
                    }
                  )}
                </div>
              )}
            </div>
          </AssignSidebarStyle>
          <div className="save-assign__content-action-item">
            <i className="save-assign__content-action-icon icon-class-start" />
            <div className="save-assign__content-action-caption">
              <p>
                {t(`create-assign-question:assign.startEnd`, 'Start / End')}
              </p>
              <DateTimeRangePicker
                startDate={startDate}
                endDate={endDate}
                applyCallback={applyCallback}
              />
            </div>
          </div>
          {assignmentType === assignmentTypes.test && (
            <div className="save-assign__content-action-item">
              <i className="save-assign__content-action-icon icon-class-start" />
              <div className="save-assign__content-action-caption">
                <p>
                  {t(
                    `create-assign-question:assignModal.releaseDate`,
                    'Release Date'
                  )}
                </p>
                <DateTimePicker
                  selectedDate={releaseDate}
                  applyCallback={applyCallbackRelease}
                />
              </div>
            </div>
          )}
          {/* <div className="save-assign__content-action-item">
            <i className="icon-class-reward save-assign__content-action-icon" />
            <div className="save-assign__content-action-caption">
              <p>Rewards</p>
              <div className="save-assign__content-action-rewards">
                <select
                  onBlur={onChangeKoko}
                  onChange={onChangeKoko}
                  defaultValue={NumberOfKokoCredits}
                  name="kokoCredit"
                >
                  {kokoCredits.map(koko => {
                    return (
                      <option value={koko} key={koko}>
                        {koko}
                      </option>
                    );
                  })}
                </select>
                <p>Koko</p>
              </div>
            </div>
          </div> */}
        </div>
        <div className="save-assign__content-submit">
          <button
            type="button"
            onClick={onClickSave}
            className="btn btn-save-only"
          >
            {t(`create-assign-question:assign.saveOnly`, 'Save Only')}
          </button>
          <button
            type="button"
            onClick={handleSaveAndAssign}
            className="btn btn-save-assign"
          >
            {t(`create-assign-question:assign.saveAssign`, 'Save & Assign')}
          </button>
        </div>
      </SidebarRight>
    </>
  );
}
