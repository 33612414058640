import styled, { css } from 'styled-components';

export const NoReportStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 30px;
  position: relative;
  top: 13rem;
  color: #555555;
  font-size: 18px;
  img {
    width: 200px;
  }
  .no-report-title {
    font-weight: 900;
    margin: 30px 0 15px;
  }
  ${({ detail }) => {
    if (detail) {
      return css`
        width: calc(80% - 2rem);
      `;
    }
  }}
`;
