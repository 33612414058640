import { call, put, select } from 'redux-saga/effects';
import { isNil, find, propEq } from 'ramda';
import { fetchQuestionByID, searchQuestionByIDs } from 'services/questions';
import CartService from 'services/cart';
import {
  fetchCartQuestionsFailed,
  fetchCartQuestionsSuccess,
  resetSelectedQuestion,
  selectQuestionSuccess,
  fetchSearchQuestionSuccess,
  fetchSearchQuestionFailed,
  selectQuestions,
  resetCartQuestions
} from 'stores/questions/actions';
import { setSelectedQuestions } from 'stores/worksheet/actions';
import history from 'helpers/history';

/**
 * The saga for add question to the Cart
 */
export function* addQuestionToCart({ question }) {
  const { selectedTopic } = yield select(state => state.topics);
  const { selectedSkill } = yield select(state => state.skills);
  const { cartQuestions } = yield select(state => state.questions);
  const { selectedQuestions } = yield select(state => state.worksheet);
  const temporaryCartQuestion = {
    QuestionId: question.Id,
    SkillId: selectedSkill.ID,
    TopicId: selectedTopic.ID,
    TopicName: selectedTopic.Name,
    SkillName: selectedSkill.AliasSkillName,
    CartQuestion: question // the data not exactly same, it's because backend inconsistency
  };
  /**
   * Add temporary cart question before the addQuestionToCart service done,
   * Reason: if the cartQuestions length the time will be long enough and look laggy and buggy
   */
  yield put(
    fetchCartQuestionsSuccess([temporaryCartQuestion, ...cartQuestions])
  );
  const data = yield call(
    CartService.addQuestionToCart,
    question.Id,
    selectedTopic.ID,
    cartQuestions.length ?? 0
  );
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchCartQuestionsSuccess(data));

      const newData = find(propEq('ID', parseInt(question.Id, 10)))(data);
      const updatedData = [...selectedQuestions, newData];
      yield put(setSelectedQuestions(updatedData));
    } else {
      yield put(fetchCartQuestionsFailed(data.error));
      // if error remove the temporary cart question
      yield put(
        fetchCartQuestionsSuccess(
          cartQuestions.filter(
            cq => cq.QuestionId !== temporaryCartQuestion.QuestionId
          )
        )
      );
    }
  }
}

/**
 * The saga for add question to the Cart
 */
export function* removeQuestionFromCart({ id }) {
  const { cartQuestions } = yield select(state => state.questions);

  /**
   * Delete cart question from state before the removeQuestionFromCart service done,
   * Reason: if the cartQuestions length the time will be long enough and look laggy and buggy
   */
  yield put(
    fetchCartQuestionsSuccess(cartQuestions.filter(cq => cq.QuestionId !== id))
  );
  const data = yield call(CartService.removeQuestionFromCart, id);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchCartQuestionsSuccess(data));
    } else {
      yield put(fetchCartQuestionsFailed(data.error));
      // if failed use the previous cartQuestions, no cart question deleted
      yield put(fetchCartQuestionsSuccess(cartQuestions));
    }
  }
}

/**
 * The saga for fetch cart questions, useful when load user cart questions when the app render
 */
export function* fetchCartQuestions() {
  const data = yield call(CartService.getMyCart);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchCartQuestionsSuccess(data));
      yield put(setSelectedQuestions(data));
    } else {
      yield put(fetchCartQuestionsFailed(data.error));
    }
  }
}

/**
 * The saga for select question
 */
export default function* getQuestionDetail({ selectedQuestion }) {
  if (isNil(selectedQuestion.Payload)) {
    const data = yield call(fetchQuestionByID, selectedQuestion.Id);
    if (!isNil(data)) {
      if (isNil(data.error)) {
        yield put(selectQuestionSuccess(data));
      }
    }
  } else {
    yield put(selectQuestionSuccess(selectedQuestion));
  }
}

export function* refreshQuestions() {
  yield put(resetSelectedQuestion());
}

export function* fetchSearchQuestions({ searchIDs }) {
  yield put(selectQuestions([]));
  const data = yield call(searchQuestionByIDs, searchIDs);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchSearchQuestionSuccess(data));
    } else {
      yield put(fetchSearchQuestionFailed(data.error));
    }
  }
}

export function* resetCart() {
  const data = yield call(CartService.resetCart);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchCartQuestionsSuccess(data));
      yield put(setSelectedQuestions(data));
    } else {
      yield put(fetchCartQuestionsFailed(data.error));
    }
  }
}

export function* addQuestionsToCart({ isReset }) {
  if (isReset) {
    yield put(resetCartQuestions());
  }
  const { selectedQuestions } = yield select(state => state.questions);

  const QuestionIds = selectedQuestions.map(question => {
    return question.ID;
  });

  const data = yield call(CartService.addQuestionsToCart, QuestionIds);
  if (!isNil(data)) {
    if (isNil(data.error)) {
      yield put(fetchCartQuestionsSuccess(data));
      yield put(setSelectedQuestions(data));
      yield put(selectQuestions([]));
      yield put(fetchSearchQuestionSuccess([]));
      yield call([history, history.push], '/create-and-assign');
    } else {
      yield put(fetchCartQuestionsFailed(data.error));
    }
  }
}
