import apiClient from 'services/api';

const handleResponseSuccess = result => {
  if (result.data?.IsSuccessful === false) {
    return {
      error: result.data?.Message?.Message ?? 'Fetch cart questions failed' // eslint-disable-line
    };
  }
  return result.data?.Result?.Items ?? [];
};

/**
 * Add a question into Cart, I put the all data into the params
 * is because they are only 3 data, it is clearer then only single params object
 * @param {string} ID
 * @param {string} topicId
 * @param {number} order, I still don't know what is the order for
 */
const addQuestionToCart = (ID, topicId, order) => {
  return apiClient
    .post(`Cart/AddItem`, {
      ID,
      topicId,
      order
    })
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Add question to cart failed' // eslint-disable-line
      };
    });
};

/**
 * Remove a question from Cart
 * @param {string} questionId
 */
const removeQuestionFromCart = questionId => {
  return apiClient
    .delete(`Cart/RemoveItem/${questionId}`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error:
          err?.response?.data?.message ?? 'Remove question from cart failed' // eslint-disable-line
      };
    });
};

/**
 * Fetch all questions in the Cart
 */
const getMyCart = () => {
  return apiClient
    .get(`Cart/GetMyCart`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch cart questions failed' // eslint-disable-line
      };
    });
};

/**
 * Reset cart question
 */
const resetCart = () => {
  return apiClient
    .delete(`Cart/RemoveAllItems`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Reset cart failed' // eslint-disable-line
      };
    });
};

/**
 * add cart questions
 */
const addQuestionsToCart = QuestionIDs => {
  return apiClient
    .post(`Cart/AddItems`, QuestionIDs)
    .then(result => {
      if (result.data?.IsSuccessful === false) {
        return {
          error: result.data?.Message?.Message ?? 'Fetch cart questions failed' // eslint-disable-line
        };
      }
      return result.data?.Result?.CartModel?.Items ?? [];
    })
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Add questions to cart failed' // eslint-disable-line
      };
    });
};

export default {
  addQuestionToCart,
  getMyCart,
  removeQuestionFromCart,
  resetCart,
  addQuestionsToCart
};
