import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import StudentTable from 'components/ManageClass/StudentTable';
import { LevelColors } from 'constants/index';
import sortClasses from 'helpers/parseClassList';
import ActionButtons from 'components/ManageClass/ActionButtons';
import Pagination from 'components/ManageClass/Pagination';
import DropdownMenu from 'components/Shared/DropdownMenu';
import { StudentListPage } from './StudentList.styles.js';

export default function StudentList({ match }) {
  const { t } = useTranslation(['manage-class']);
  const classID = parseInt(match.params.classID, 10);
  const { classes, students, isLoading } = useSelector(
    state => state.manageClasses
  );
  const currentClass = classes.hasOwnProperty('ClassList')
    ? classes.ClassList.find(classObject => classObject.ID === classID)
    : null;
  const sameLevelClasses = Object.prototype.hasOwnProperty.call(
    classes,
    'ClassList'
  )
    ? sortClasses(classes.ClassList).find(
        classObject => classObject.level === currentClass.LevelId
      ).classes
    : [];
  const history = useHistory();
  // Stores selected student IDs as checked in the table
  const [selectedStudentIDs, setSelectedStudentIDs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // Event handler for dropdown menu item
  const handleSelectedClass = classObject => {
    history.push(`/manage-class/${classObject.ID}`);
  };

  return (
    <StudentListPage>
      <div className="top-bar">
        <div
          className="navigation-back"
          onClick={() => {
            history.push('/manage-class');
          }}
        >
          {t(`manage-class:studList.back`, '< Back')}
        </div>
        <div className="dropdown" style={{ zIndex: '100' }}>
          {currentClass.IconUrl !== 'No Path found' ? (
            <img
              src={currentClass.IconUrl}
              alt="class-icon"
              width="35px"
              height="35px"
            />
          ) : (
            <div
              style={{
                width: '35px',
                height: '35px',
                background: LevelColors[currentClass.LevelId],
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white'
              }}
            >
              <strong>{currentClass.Name.slice(0, 1).toUpperCase()}</strong>
            </div>
          )}

          <p>{t(`manage-class:studModal.class`, 'Class')}</p>
          <DropdownMenu
            selectedValue={currentClass.Name}
            values={sameLevelClasses}
            valueKey="Name"
            setValue={handleSelectedClass}
            width="200px"
          />
          {students.TotalCount ? (
            <div className="total-stud">
              <div>{t(`manage-class:studList.total`, 'Total:')}</div>
              <div className="total-count">
                {students.TotalCount +
                  (students.TotalCount === 1
                    ? t(`manage-class:studList.student`, ' student')
                    : t(`manage-class:studList.students`, ' students'))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <ActionButtons
        selectedStudentIDs={selectedStudentIDs}
        setSelectedStudentIDs={setSelectedStudentIDs}
        classID={classID}
        currentLevel={currentClass.LevelId}
      />
      <StudentTable
        selectedStudentIDs={selectedStudentIDs}
        setSelectedStudentIDs={setSelectedStudentIDs}
        classID={classID}
        currentPage={currentPage}
        setPage={setCurrentPage}
      />
      {!isLoading && students.hasOwnProperty('List') && (
        <Pagination currentPage={currentPage} setPage={setCurrentPage} />
      )}
    </StudentListPage>
  );
}
