import styled from 'styled-components';

export const ToggleReportStyle = styled.div``;

export const ReportButton = styled.button`
  background: #ffffff;
  border: 0.5px solid #c4c4c4;
  color: #c4c4c4;
  padding: 8px 15px;
  border-radius: ${props =>
    props.proficiency ? '2px 0 0 2px' : '0 2px 2px 0'};
  border-width: ${props => props.high && '0.5px 0.5px 0.5px 0'};

  ${({ active }) =>
    active &&
    `
    background: #FFF3EC;
    color: #FF5B00;
  `}
`;
