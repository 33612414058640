import styled from 'styled-components';

export const WorksheetTimeStyled = styled.div`
  display: flex;
  background: #f7f7f7;
  justify-content: center;
  gap: 1.5rem;
  padding: 2rem 3rem;
`;

export const SettingCard = styled.div`
  border-radius: 14px;
  background: #ffffff;
  height: 300px;
  width: 294px;
  background: ${({ selected }) => selected && '#FFFBF2'};
  border: ${({ selected }) => (selected ? ' 2px solid #FF5C00' : 'none')};
  cursor: pointer;
  &:hover {
    background: #fffaf0;
    .form-check .custom-radio {
      border-color: #ff5c02;
    }
  }
  @media (max-width: 1160px) {
    width: 250px;
  }
`;

export const CardTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  border-bottom: 1px solid
    ${({ selected }) => (selected ? '#FFC7A3' : '#e0e0e0')};
  gap: 0.5rem;
  height: 112px;
  background: ${({ selected }) => selected && '#FFF2D5'};
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  .interval-options {
    width: 143px;
  }
  .total-time {
    display: flex;
    align-items: center;
    gap: 10px;
    .total-time-input {
      width: 85px;
      text-align: center;
    }
  }

  .form-check {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 8px;
    color: #000000;
    .types-label {
      margin-bottom: 0.1rem;
      font-weight: 700;
      font-size: 16px;
      @media screen and (max-width: 562px) {
        font-size: 12px;
      }
    }
    .info {
      font-family: 'Linotte Light';
      font-size: 13px;
      border: 1px solid #c7c7c7;
      background: #fff;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    select {
      margin-left: 5px;
      width: auto;
    }
  }
  //hide the browser's default radio
  .form-check {
    input[type='radio'] {
      opacity: 0;
      cursor: pointer;
    }
  }

  //create a custom radio
  .form-check .custom-radio {
    position: absolute;
    left: 3px;
    height: 20px;
    width: 20px;
    border-radius: 50px;
    border: 1px solid #b1b1b1;
    background-color: white;
    pointer-events: none;
  }
  //when radio is checked
  .form-check input:checked ~ .custom-radio {
    background-color: none;
    border: 1px solid #ff5c02;
  }
  //create the indicator (hidden when not checked)
  .form-check .custom-radio:after {
    content: '';
    position: absolute;
    display: none;
  }
  //show the checkmark when checked
  .form-check input:checked ~ .custom-radio:after {
    display: block;
  }
  //style the indicator
  .form-check .custom-radio:after {
    margin: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background-color: #ff5c02;
  }
`;

export const CardBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 0.8rem 1.5rem 0;
  p {
    margin-bottom: 0;
    font-size: 12px;
  }
  .form-image {
    .rect {
      border: 1px solid #ff0000;
      border-radius: 4px;
      position: absolute;
      height: 25px;
      width: 55px;
      margin-top: -5px;
      margin-left: -3px;
    }
    img {
      max-width: 245px;
      @media (max-width: 1160px) {
        max-width: 200px;
      }
    }
  }
`;

export const Overlay = styled.div`
  position: absolute;
  background: #ffffff;
  border: 1px solid #d8d1d1;
  border-radius: 3.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  padding: 0.5rem;
  bottom: 2.5rem;
  min-width: 168px;
  &:after,
  :before {
    content: '';
    position: absolute;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    top: 100%;
    left: 50%;
    margin-left: -0.5rem;
  }
  &:before {
    border-top: 0.5rem solid #d8d1d1;
  }
  &:after {
    border-top: 0.5rem solid white;
    margin-top: -2px;
    z-index: 1;
  }
`;
