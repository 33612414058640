import apiClient from 'services/api';

// `Curriculum/GetCurriculumForCurrentUser`
const GetCurriculumForCurrentUser = () => {
  return apiClient
    .get('MultiCurriculum/GetSelfPracticeCurriculums')
    .then(result => {
      return result.data?.Result ?? [];
    })
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch user curriculum failed' // eslint-disable-line
      };
    });
};

export default GetCurriculumForCurrentUser;
