export const FETCH_CLASSES_REQUEST = 'FETCH_CLASSES_REQUEST';
export const FETCH_CLASSES_SUCCESS = 'FETCH_CLASSES_SUCCESS';
export const FETCH_CLASSES_FAILED = 'FETCH_CLASSES_FAILED';
export const FETCH_STUDENTS_DATA_REQUEST = 'FETCH_STUDENTS_DATA_REQUEST';
export const FETCH_STUDENTS_CLASS_SUCCESS = 'FETCH_STUDENTS_CLASS_SUCCESS';
export const FETCH_STUDENTS_FAILED = 'FETCH_STUDENTS_FAILED';
export const FETCH_STUDENT_DETAILS_REQUEST = 'FETCH_STUDENT_DETAILS_REQUEST';
export const FETCH_STUDENT_DETAILS_SUCCESS = 'FETCH_STUDENT_DETAILS_SUCCESS';
export const FETCH_STUDENT_DETAILS_FAILED = 'FETCH_STUDENT_DETAILS_FAILED';
export const EDIT_CLASS_REQUEST = 'EDIT_CLASS_REQUEST';
export const EDIT_CLASS_SUCCESS = 'EDIT_CLASS_SUCCESS';
export const EDIT_CLASS_FAILED = 'EDIT_CLASS_FAILED';
export const CHANGE_CLASS_REQUEST = 'CHANGE_CLASS_REQUEST';
export const CHANGE_CLASS_SUCCESS = 'CHANGE_CLASS_SUCCESS';
export const CHANGE_CLASS_FAILED = 'CHANGE_CLASS_FAILED';
export const RESET_PW_REQUEST = 'RESET_PW_REQUEST';
export const RESET_PW_SUCCESS = 'RESET_PW_SUCCESS';
export const RESET_PW_FAILED = 'RESET_PW_FAILED';
export const RESET_STUDENTS = 'RESET_STUDENTS';
export const RESET_SEARCH_RESULTS = 'RESET_SEARCH_RESULTS';
export const SET_STUDENT_PAGES = 'SET_STUDENT_PAGES';
export const SET_SEARCH_PAGES = 'SET_SEARCH_PAGES';
export const SEARCH_STUDENT_REQUEST = 'SEARCH_STUDENT_REQUEST';
export const SEARCH_STUDENT_SUCCESS = 'SEARCH_STUDENT_SUCCESS';
export const SEARCH_STUDENT_FAILED = 'SEARCH_STUDENT_FAILED';
export const RESET_PW_RESULT = 'RESET_PW_RESULT';
export const RESET_ERROR = 'RESET_ERROR';
export const RESET_CHANGE_CLASS_RESULT = 'RESET_CHANGE_CLASS_RESULT';
export const FETCH_ICONS_REQUEST = 'FETCH_ICONS_REQUEST';
export const FETCH_ICONS_SUCCESS = 'FETCH_ICONS_SUCCESS';
export const FETCH_ICONS_FAILED = 'FETCH_ICONS_FAILED';
export const RESET_EDIT_CLASS_RESULT = 'RESET_EDIT_CLASS_RESULT';
