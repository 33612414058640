import {
  FETCH_USER_CURRICULUMS_REQUEST,
  FETCH_USER_CURRICULUMS_SUCCESS,
  FETCH_USER_CURRICULUMS_FAILED,
  SELECTED_CURRICULUM
} from './actionTypes';

// Fetch curriculums for current user
export const fetchUserCurriculumsRequest = () => {
  return { type: FETCH_USER_CURRICULUMS_REQUEST };
};

export const fetchUserCurriculumsSuccess = curriculums => {
  return { type: FETCH_USER_CURRICULUMS_SUCCESS, curriculums };
};

export const fetchUserCurriculumsFailed = error => {
  return { type: FETCH_USER_CURRICULUMS_FAILED, error };
};

export const selectCurriculum = selectedCurriculum => {
  return { type: SELECTED_CURRICULUM, selectedCurriculum };
};
