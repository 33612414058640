import React, { useState, useEffect } from 'react';
import { ProgressBarContainer, ProgressSection } from './ProgressBar.styles';

const ProgressBar = ({ backgroundColor = '#e5e5e5', height, visualParts }) => {
  const [widths, setWidths] = useState(
    visualParts.map(item => {
      return item.percentage;
    })
  );

  // Update state on visualParts props change
  useEffect(() => {
    setWidths(
      visualParts.map(item => {
        return item.percentage;
      })
    );
  }, [visualParts]);

  return (
    <>
      <ProgressBarContainer height={height}>
        {visualParts.length === widths.length && (
          <div className="progress-visual-full" style={{ backgroundColor }}>
            {visualParts.map((item, index) => {
              return (
                <ProgressSection
                  index={index}
                  isLastIndex={index === visualParts.length - 1}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${item.percentage}-${item.color}-${index}`}
                  width={widths[index]}
                  item={item}
                  singleWidth={visualParts.length === 1}
                  className="visualpart"
                />
              );
            })}
          </div>
        )}
      </ProgressBarContainer>
    </>
  );
};

export default ProgressBar;
