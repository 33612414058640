import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNil } from 'ramda';
import { fetchClassRequest } from 'stores/utils/lookup/actions';
import { setReportLevel } from 'stores/report/actions';
import { fetchUserCurriculumsRequest } from 'stores/curriculums/actions';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import TopBar from 'components/Report/TopBar';
import generateLevels from 'helpers/reportLevels';
import HighScoreReportByStudent from './HighScoreReportByStudent';
import HighScoreReportBySkills from './HighScoreReportBySkills';
import {
  StyledSwitchContainer,
  StyledSwitch
} from './ProficiencyReport.styles';
import { ReportPage } from './ReportPage.styles';
const TYPES = [
  {
    label: 'By Students',
    value: 'student'
  },
  {
    label: 'By Skills',
    value: 'skill'
  }
];
const HighScoreReport = ({ history }) => {
  const dispatch = useDispatch();
  // Redux States
  const {
    selectedClass,
    sortOrder,
    reportLevel,
    selectedSyllabus,
    selectedClassLevel
  } = useSelector(state => state.reports);
  const { classes } = useSelector(state => state.lookup);
  const { curriculums, error } = useSelector(state => state.curriculums);
  const isLeveless = isEmpty(selectedSyllabus?.Levels);
  const [type, setType] = useState(
    history.location.state?.type || TYPES[0].value
  );

  const reportLevels = generateLevels();
  // Filter out only classes of type 1
  const filteredClasses =
    classes.length > 0
      ? classes.filter(classObject => classObject.Type === 1)
      : [];

  // UseEffect
  useEffect(() => {
    dispatch(fetchClassRequest(selectedClassLevel));
  }, [dispatch, selectedClassLevel]);
  useEffect(() => {
    if (error !== '') {
      dispatch(fetchUserCurriculumsRequest());
    }
  }, [classes, classes.length, dispatch, error, selectedClass]);
  useDeepCompareEffectNoCheck(() => {
    if (selectedSyllabus && isNil(reportLevel) && !isLeveless) {
      const firstLevel = selectedSyllabus.Levels[0];
      dispatch(setReportLevel(firstLevel));
    }
  }, [reportLevel, selectedSyllabus, isLeveless]);
  return (
    reportLevel && (
      <ReportPage>
        <TopBar
          type="highscore"
          classes={filteredClasses}
          selectedClass={selectedClass}
          classLevels={reportLevels}
          reportLevels={
            !isEmpty(selectedSyllabus.Levels) ? selectedSyllabus.Levels : []
          }
          selectedLevel={reportLevel}
          curriculums={curriculums}
          selectedSyllabus={selectedSyllabus}
          sortOrder={sortOrder}
          selectedClassLevel={selectedClassLevel}
          detailType={type}
        />
        <StyledSwitchContainer>
          {TYPES.map(item => (
            <StyledSwitch
              key={item.value}
              active={item.value === type}
              onClick={() => setType(item.value)}
            >
              {item.label}
            </StyledSwitch>
          ))}
        </StyledSwitchContainer>
        <HighScoreReportByStudent visible={type === TYPES[0].value} />
        <HighScoreReportBySkills visible={type === TYPES[1].value} />
      </ReportPage>
    )
  );
};

export default HighScoreReport;
