import { Card } from 'react-bootstrap';
import styled, { css } from 'styled-components';

export const Content = styled.div`
  padding-left: 4%;
  padding-right: 4%;

  .dashed-line {
    border-top: 1px dashed #cccccc;
  }
`;

export const MainContent = styled.div`
  margin-top: 2rem;
`;

export const StyledWelcomeText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  h1 {
    color: #1e1e1e;
    font-style: italic;
    font-family: 'Ichlthni';
    font-size: 38px;
  }
  h2 {
    padding-left: 10px;
    padding-bottom: 4px;
    color: #1e1e1e;
    font-size: 24px;
    font-family: 'Muli';
  }

  .user-name {
    font-weight: 900;
  }

  .class-name {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 6px;
  }
  @media (max-width: 1366px) {
    h1 {
      font-size: 28px;
    }
    .user-name {
      font-size: 18px;
    }
    .class-name {
      font-size: 14px;
    }
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: start;

    .user-name,
    .class-name {
      padding: 0px;
      margin: 0px;
    }
  }
`;

export const StyledCardRow = styled.div`
  display: flex;
  justify-content: row;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledCard = styled(Card)`
  width: 100%;
  display: flex;
  background-color: ${props => props.backgroundcolor};
  min-height: 100px;
  padding: 24px 30px;
  border-radius: 8px;
  box-shadow: 2px 2px #d8d8d8;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
  @media (max-width: 1366px) {
    padding: 32px 20px;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 1rem;
    margin-right: 0px;
  }
`;

export const StyledInnerCardRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .title {
    margin-top: -10px;
    font-size: 24px;
    font-family: 'Muli', sans-serif;
    font-weight: 700;
    color: #000000;
  }

  .subtitle {
    margin-top: -5px;
    margin-left: 2px;
    color: #000000;
    font-size: 18px;
  }
  .card-body {
    padding: 1.25rem 1.25rem 1.25rem 0;
  }
  .card-img {
    width: 80px;
  }
  @media (max-width: 1366px) {
    .title {
      font-size: 18px;
    }
    .subtitle {
      font-size: 14px;
    }
    .card-img {
      width: 60px;
    }
  }
  ${({ title }) =>
    title === 'Play' &&
    css`
      .card-img {
        width: 70px;
      }
      @media (max-width: 1366px) {
        .card-img {
          width: 50px;
        }
      }
    `}
`;

export const BottomCard = styled(Card)`
  background-color: ${props => props.backgroundcolor};
  min-height: 100px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  box-shadow: 2px 2px #d8d8d8;
  margin-top: 2rem;
  margin-bottom: 2rem;

  .graph-subtitle {
    font-size: 16px;
    font-family: 'Muli', sans-serif;
    font-weight: 700;
    color: #000000;
    margin-top: 0.5rem;
  }

  @media (max-width: 767px) {
    margin-top: 1rem;
  }
`;
