import styled from 'styled-components';
import HeaderBg from 'assets/img/leaderboard/leaderboard-bg.png';

export const Topbar = styled.div`
  height: 390px;
  background-image: url(${HeaderBg});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  padding: 0 20px;
  position: relative;
  .button-back {
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0, 74, 137, 0.61);
    color: #ffffff;
    border-radius: 2rem;
    border: none;
    padding: 0.3rem 1.2rem;
    font-weight: 600;
    cursor: pointer;
    font-size: 1rem;
    img {
      margin-right: 0.3rem;
    }
  }
  .badge-img {
    margin-left: 5%;
  }
  .middle-content {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .scoring-period {
    color: #ffffff;
    font-size: 26px;
    display: flex;
    margin-top: 24px;
    font-weight: 700;
    span {
      font-size: 16px;
      margin-right: 5px;
    }
  }
  .rules {
    position: absolute;
    right: -3%;
    bottom: 40px;
    cursor: pointer;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const TitleHeading = styled.div`
  font-size: 1.6rem;
  width: 40%;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
`;

export const TitleMeta = styled.div`
  display: flex;
  width: 25%;
  justify-content: space-between;
  color: #ffffff;
`;
