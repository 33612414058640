import questionParser from 'helpers/questionParser';
import moment from 'moment';
import { isNil } from 'ramda';
import { questionTypeIDs } from 'constants/index';

// HELPERS
// Gets a specific class' details from fetchSingleClassReport API response
export const getClass = (group, targetClassID) => {
  return group.find(classObject => classObject.ClassID === targetClassID);
};

export const parseQnAnswer = questionAnswer => {
  let answerString;
  if (questionAnswer.length > 0 && typeof questionAnswer !== 'string') {
    answerString = questionAnswer.map(ans => ans.$.Value).join(', ');
  } else {
    answerString = questionAnswer;
  }
  return answerString;
};

// Parse QuestionResult payload to return a answer string delimited by |
export const parseQuestionResultToString = (questionResult, questionType) => {
  const result = questionParser(questionResult);
  const { questionAnswer, correctMCQOptions } = result;

  let answerString;
  if (questionType === 'mcq') {
    answerString = correctMCQOptions
      .map(option => `Option ${option}`)
      .join(', ');
  } else {
    try {
      if (questionAnswer.length > 0 && typeof questionAnswer !== 'string') {
        answerString = questionAnswer.map(ans => ans.$.Value).join(', ');
      } else {
        answerString = questionAnswer;
      }
    } catch (error) {
      console.log(error);
    }
  }

  return answerString;
};

// Parses SubmissionKeyValuePairs property in QuestionResult object to return a answer string delimited by |
export const parseSubmittedAnswersToString = (answers, questionType) => {
  let answerString;
  if (questionType === 'mcq') {
    answerString = answers
      .map(ans => {
        if (!isNil(ans.Key)) {
          return `Option ${ans.Key}`;
        }
        return '';
      })
      .filter(ans => ans.length > 0)
      .join(', ');
  } else {
    answerString = answers
      .map(ans => {
        if (!isNil(ans.Value)) {
          return ans.Value;
        }
        return '';
      })
      .filter(ans => ans.length > 0)
      .join(', ');
  }
  if (answerString.length === 0) {
    return 'No answer submitted';
  }
  return answerString;
};

// Sorts an array of objects by given key
export function compareValues(key, order = 'asc') {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
}

// Parse QuestionResult array from fetchSingleClassReport API response into format for CSV report
export const parseCSVReport = data => {
  let report = [];
  const {
    Questions,
    HomeWorkTitle,
    HomeWorkId,
    TrackingGroup,
    TrackingIndividual
  } = data[0];
  const maxScore = Questions.length;
  // Check if there are classes assigned for this homework
  if (TrackingGroup.length > 0) {
    // Loops through each class
    for (const assignedClass of TrackingGroup) {
      const className = assignedClass.ClassName;
      const classID = assignedClass.ClassID;
      // Stores a hashmap of studentID to index position in students
      const studentHashmap = {};
      const students = [...assignedClass.Students];
      // Setup students array
      for (let i = 0; i < students.length; i++) {
        students[i].type = 'class';
        students[i].class = className;
        students[i].classID = classID;
        students[i].studentName = students[i].FullName;
        students[i].homeworkTitle = HomeWorkTitle;
        students[i].homeworkID = HomeWorkId;
        students[i].score = '-';
        students[i].maxScore = maxScore;
        students[i].submissionDate = '-';
        // Update studentHashmap
        studentHashmap[students[i].ID] = i;
      }
      // Loops through QuestionResult array
      for (const result of assignedClass.QuestionResult) {
        const studentIndex = studentHashmap[result.SubmittedBy];
        // If question was answered correctly
        if (result.SubmissionResult === 1) {
          // Update student score
          if (students[studentIndex].score === '-') {
            students[studentIndex].score = 1;
          } else {
            students[studentIndex].score += 1;
          }
          // Update submission date
          if (students[studentIndex].submissionDate === '-') {
            students[studentIndex].submissionDate = moment(
              result.SubmissionDate
            ).format('DD/MM/YYYY HH:mm');
          } else {
            const currentDate = moment(
              students[studentIndex].submissionDate,
              'DD/MM/YYYY HH:mm'
            ).unix();
            const newDate = moment(result.SubmissionDate).unix();
            if (newDate > currentDate) {
              students[studentIndex].submissionDate = moment(
                result.SubmissionDate
              ).format('DD/MM/YYYY HH:mm');
            }
          }
        }
        // Question result !== correct
        else {
          // Update student score
          if (students[studentIndex].score === '-') {
            students[studentIndex].score = 0;
          }
          // Update submission date
          if (students[studentIndex].submissionDate === '-') {
            students[studentIndex].submissionDate = moment(
              result.SubmissionDate
            ).format('DD/MM/YYYY HH:mm');
          } else {
            const currentDate = moment(
              students[studentIndex].submissionDate,
              'DD/MM/YYYY HH:mm'
            ).unix();
            const newDate = moment(result.SubmissionDate).unix();
            if (newDate > currentDate) {
              students[studentIndex].submissionDate = moment(
                result.SubmissionDate
              ).format('DD/MM/YYYY HH:mm');
            }
          }
        }
      }
      report = [...report, ...students];
    }
  }
  // Check if there are individual students assigned for this homework
  if (TrackingIndividual !== null) {
    // Stores a hashmap of studentID to index position in students
    const studentHashmap = {};
    const students = [...TrackingIndividual.Students];
    // Setup students array
    for (let i = 0; i < students.length; i++) {
      students[i].type = 'individual';
      students[i].class = '-';
      students[i].studentName = students[i].FullName;
      students[i].homeworkTitle = HomeWorkTitle;
      students[i].homeworkID = HomeWorkId;
      students[i].score = '-';
      students[i].maxScore = maxScore;
      students[i].submissionDate = '-';
      // Update studentHashmap
      studentHashmap[students[i].ID] = i;
    }
    // Loops through QuestionResult array
    for (const result of TrackingIndividual.QuestionResult) {
      const studentIndex = studentHashmap[result.SubmittedBy];
      // If question was answered correctly
      if (result.SubmissionResult === 1) {
        // Update student score
        if (students[studentIndex].score === '-') {
          students[studentIndex].score = 1;
        } else {
          students[studentIndex].score += 1;
        }
        // Update submission date
        if (students[studentIndex].submissionDate === '-') {
          students[studentIndex].submissionDate = moment(
            result.SubmissionDate
          ).format('DD/MM/YYYY HH:mm');
        } else {
          const currentDate = moment(
            students[studentIndex].submissionDate,
            'DD/MM/YYYY HH:mm'
          ).unix();
          const newDate = moment(result.SubmissionDate).unix();
          if (newDate > currentDate) {
            students[studentIndex].submissionDate = moment(
              result.SubmissionDate
            ).format('DD/MM/YYYY HH:mm');
          }
        }
      }
      // Question result !== correct
      else {
        // Update student score
        if (students[studentIndex].score === '-') {
          students[studentIndex].score = 0;
        }
        // Update submission date
        if (students[studentIndex].submissionDate === '-') {
          students[studentIndex].submissionDate = moment(
            result.SubmissionDate
          ).format('DD/MM/YYYY HH:mm');
        } else {
          const currentDate = moment(
            students[studentIndex].submissionDate,
            'DD/MM/YYYY HH:mm'
          ).unix();
          const newDate = moment(result.SubmissionDate).unix();
          if (newDate > currentDate) {
            students[studentIndex].submissionDate = moment(
              result.SubmissionDate
            ).format('DD/MM/YYYY HH:mm');
          }
        }
      }
    }
    report = [...report, ...students];
  }
  return report;
};

// Parse QuestionResult array from fetchSingleClassReport API response into an array of scores by student
export const parseQuestionResult = (questionResult, questions, students) => {
  const questionsHashmap = {};
  const scoresByQuestion = [];
  // Setup scoresByQuestion array
  let questionIndex = 1;
  for (const question of questions) {
    questionsHashmap[question.Id] = questionIndex;
    scoresByQuestion[questionIndex] = {
      correct: {},
      wrong: {}
    };
    questionIndex += 1;
  }

  const scoresByStudent = students;
  // Stores a hashmap of studentID to index position in scoresByStudent
  const studentHashmap = {};
  // Add properties to student objects
  for (let i = 0; i < scoresByStudent.length; i++) {
    scoresByStudent[i].score = 0;
    scoresByStudent[i].hasSubmitted = false;
    scoresByStudent[i].questions = [];
    scoresByStudent[i].answers = [];
    // Update studentHashmap
    studentHashmap[scoresByStudent[i].ID] = i;
  }
  // Loops through array of question results
  for (const result of questionResult) {
    if (studentHashmap.hasOwnProperty(result.SubmittedBy)) {
      scoresByStudent[studentHashmap[result.SubmittedBy]].hasSubmitted = true;
      // Check if question was answered correctly
      if (result.SubmissionResult === 1) {
        scoresByStudent[studentHashmap[result.SubmittedBy]].score += 1;
        scoresByStudent[studentHashmap[result.SubmittedBy]].questions[
          questionsHashmap[result.Id]
        ] = true;
        // Parse the answer from payload, questionAnswer is an array of objects
        // The answer can be found in the key $
        const wrongAnsString = parseSubmittedAnswersToString(
          result.SubmissionKeyValuePairs,
          questionTypeIDs[result.QuestionType]
        );

        // Add to answers array
        scoresByStudent[studentHashmap[result.SubmittedBy]].answers[
          questionsHashmap[result.Id]
        ] = {
          isCorrect: true,
          questionPayload: result.Payload,
          questionDifficulty: result.Difficulty,
          studentAnswer: wrongAnsString,
          submissionId: result.SubmissionID
        };
        // Check if answer already exists in correct hashmap
        if (
          scoresByQuestion[questionsHashmap[result.Id]].correct.hasOwnProperty(
            wrongAnsString
          )
        ) {
          scoresByQuestion[questionsHashmap[result.Id]].correct[
            wrongAnsString
          ].num += 1;
          scoresByQuestion[questionsHashmap[result.Id]].correct[
            wrongAnsString
          ].students.push(
            scoresByStudent[studentHashmap[result.SubmittedBy]].FullName
          );
        } else {
          scoresByQuestion[questionsHashmap[result.Id]].correct[
            wrongAnsString
          ] = {
            num: 1,
            students: [
              scoresByStudent[studentHashmap[result.SubmittedBy]].FullName
            ]
          };
        }
      } else if (
        result.SubmissionResult === 2 ||
        result.SubmissionResult === 3
      ) {
        scoresByStudent[studentHashmap[result.SubmittedBy]].questions[
          questionsHashmap[result.Id]
        ] = false;
        const ansString = parseQuestionResultToString(
          result,
          questionTypeIDs[result.QuestionType]
        );

        // Add correct answer to scoresByQuestion
        if (
          !scoresByQuestion[questionsHashmap[result.Id]].correct.hasOwnProperty(
            ansString
          )
        ) {
          scoresByQuestion[questionsHashmap[result.Id]].correct[ansString] = {
            num: 0,
            students: []
          };
        }
        const wrongAnsString = parseSubmittedAnswersToString(
          result.SubmissionKeyValuePairs,
          questionTypeIDs[result.QuestionType]
        );
        // Add to answers array
        scoresByStudent[studentHashmap[result.SubmittedBy]].answers[
          questionsHashmap[result.Id]
        ] = {
          isCorrect: false,
          questionPayload: result.Payload,
          questionDifficulty: result.Difficulty,
          studentAnswer: wrongAnsString,
          submissionId: result.SubmissionID
        };
        if (
          scoresByQuestion[questionsHashmap[result.Id]].wrong.hasOwnProperty(
            wrongAnsString
          )
        ) {
          scoresByQuestion[questionsHashmap[result.Id]].wrong[
            wrongAnsString
          ].num += 1;
          scoresByQuestion[questionsHashmap[result.Id]].wrong[
            wrongAnsString
          ].students.push(
            scoresByStudent[studentHashmap[result.SubmittedBy]].FullName
          );
        } else {
          scoresByQuestion[questionsHashmap[result.Id]].wrong[
            wrongAnsString
          ] = {
            num: 1,
            students: [
              scoresByStudent[studentHashmap[result.SubmittedBy]].FullName
            ]
          };
        }
      }
    }
  }
  return {
    byQuestion: scoresByQuestion,
    byStudent: scoresByStudent
  };
};
