import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetChangeClassResult,
  resetError
} from 'stores/manage-class/actions';
import RemoveIcon from 'assets/img/icon-manage-class-remove.svg';
import MailIcon from 'assets/img/icon-manage-class-mail.svg';
import ChangeClassIcon from 'assets/img/icon-manage-class-change-class.svg';
import Notification from 'assets/img/icon-manage-class-notification.svg';
import { featureToggle } from 'constants/index';
import PopupModal from './PopupModal';
import ChangeClassModalBody from './ChangeClassModalBody';
import { ActionButtonsContainer } from './ActionButtons.styles';

export default function ActionButtons({
  selectedStudentIDs,
  classID,
  currentLevel
}) {
  const { t } = useTranslation(['manage-class']);
  const dispatch = useDispatch();
  const { isSchoolAdmin } = useSelector(state => state.login);
  // Modal States
  const [showChangeClassModal, setShowChangeClassModal] = useState(false);

  const openChangeClassModal = () => {
    dispatch(resetError());
    dispatch(resetChangeClassResult());
    setShowChangeClassModal(true);
  };
  // Event handlers to hide popup modals
  const hideChangeClassModal = () => {
    setShowChangeClassModal(false);
  };

  return (
    <ActionButtonsContainer>
      <div className="buttons">
        <PopupModal
          show={showChangeClassModal}
          hide={hideChangeClassModal}
          size="sm"
        >
          <ChangeClassModalBody
            hide={hideChangeClassModal}
            selectedStudentIDs={selectedStudentIDs}
            currentLevel={currentLevel}
            currentClassID={classID}
          />
        </PopupModal>
        {featureToggle.classListActions && (
          <>
            <button className="btn" type="button">
              <img src={RemoveIcon} alt="remove" />
              <span>{t(`manage-class:actions.remove`, 'Remove')}</span>
            </button>
            <button className="btn" type="button">
              <img src={MailIcon} alt="email-parent" />
              <span>
                {t(`manage-class:actions.emailParent`, 'Email Parent')}
              </span>
            </button>
          </>
        )}
        {isSchoolAdmin && (
          <button
            className="btn"
            type="button"
            onClick={() => {
              openChangeClassModal();
            }}
            disabled={!(selectedStudentIDs.length > 0)}
          >
            <img src={ChangeClassIcon} alt="change-class" />
            <span>{t(`manage-class:actions.changeClass`, 'Change Class')}</span>
          </button>
        )}
        {featureToggle.classListActions && (
          <button className="btn" type="button">
            <img src={Notification} alt="send-notification" />
            <span>
              {t(`manage-class:actions.sendNotif`, 'Send Notification')}
            </span>
          </button>
        )}
      </div>
    </ActionButtonsContainer>
  );
}
