import styled from 'styled-components';

export const AssignSidebarStyle = styled.div`
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #f4f4f4;
    border-radius: 15px;
  }
  ::-webkit-scrollbar-thumb {
    border: 2px solid rgba(0, 0, 0, 0);
  }
`;
