import React from 'react';
import { useTranslation } from 'react-i18next';
import noSyllabus from 'assets/img/icon-no-syllabus.svg';
import { NoTopicsContainer } from './FactualFluency.styles';

export default function NoTopicSkill() {
  const { t } = useTranslation(['create-assign-factual-fluency']);
  return (
    <NoTopicsContainer>
      <img className="no-topic-img" src={noSyllabus} alt="no-topics-skill" />
      <div className="title">
        {t('create-assign-factual-fluency:empty.notFound', 'No topics found')}
      </div>
      <div className="suggestion">
        {t(
          'create-assign-factual-fluency:empty.suggest',
          'Select another level to show topics.'
        )}
      </div>
    </NoTopicsContainer>
  );
}
