import React from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import ContentWrapContainer from './ContentWrap.styles';

export default function ContentWrap({ paddingTop, children }) {
  return (
    <ContentWrapContainer>
      <div className="content-wrap" style={{ paddingTop }}>
        <SimpleBar className="content-wrap-scrollpane">{children}</SimpleBar>
      </div>
    </ContentWrapContainer>
  );
}
