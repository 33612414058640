import styled from 'styled-components';

export const MonthlyActivity = styled.div`
  padding-left: 195px;
  @media (max-width: 1024px) {
    padding-left: 56px;
  }
  @media (max-width: 639px) {
    padding-left: 0px;
  }
  .top-bar {
    display: flex;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
    position: fixed;
    right: 0;
    left: 195px;
    z-index: 100;
    justify-content: center;
    top: 53px;
    @media (max-width: 1024px) {
      left: 56px;
    }
    @media (max-width: 639px) {
      left: 0px;
    }
    .title {
      margin: 20px 30px 20px 0px;
      font-size: 20px;
    }
    .dropdown-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      .dropdown-label {
        margin-top: 15px;
        font-size: 13px;
        font-weight: 600;
      }
      .dropdown-custom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 75px;
        margin-left: 10px;
        border: 1px solid rgba(112, 112, 112, 0.48);
        background-color: #ffffff;
        font-size: 14px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 882px) {
          font-size: 12px;
          width: 120px;
        }
        @media (max-width: 744px) {
          font-size: 10px;
          width: 110px;
        }
      }
    }
  }
`;
