import apiClient from 'services/api';

const handleResponseSuccess = result => {
  if (result.data?.IsSuccessful === false) {
    return {
      error: result.data?.Message?.Message ?? 'Fetch data failed' // eslint-disable-line
    };
  }
  return result.data?.Result ?? [];
};

/**
 * Fetch all groups
 */
const fetchGroupData = () => {
  return apiClient
    .get(`ManageTeacherGroup/GetAll`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch Groups data failed' // eslint-disable-line
      };
    });
};

/**
 * Create group
 */
const createNewGroup = params => {
  return apiClient
    .post(`ManageTeacherGroup/AddGroup`, params)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Create group failed' // eslint-disable-line
      };
    });
};

/**
 * Update group
 */
const updateGroup = params => {
  return apiClient
    .put(`ManageTeacherGroup/UpdateGroup`, params)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Update Group failed' // eslint-disable-line
      };
    });
};

/**
 * Delete group
 */
const deleteGroupById = id => {
  return apiClient
    .put(`ManageTeacherGroup/DeleteGroup/${id}`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Delete Groups data failed' // eslint-disable-line
      };
    });
};

/**
 * NU Get classes
 */
const getClassByLevel = params => {
  return apiClient
    .get(`Lookup/Classes/${params}`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Get class failed' // eslint-disable-line
      };
    });
};

/**
 * Get students
 */
const getStudentsByClass = params => {
  return apiClient
    .get(`lookup/SearchStudentWithDetails?classId=${params}&EnablePaging=false`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Get student failed' // eslint-disable-line
      };
    });
};

export default {
  fetchGroupData,
  updateGroup,
  deleteGroupById,
  createNewGroup,
  getClassByLevel,
  getStudentsByClass
};
