import styled from 'styled-components';

export const StudentListPage = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: calc(100vw - 280px);
  margin-left: 245px;
  margin-right: 35px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  @media (max-width: 1024px) {
    width: calc(100vw - 106px);
    margin-left: 81px;
    margin-right: 25px;
  }
  .top-bar {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    padding: 20px 0px;
    border-bottom: solid 1px rgba(112, 112, 112, 0.11);
    position: fixed;
    z-index: 100;
    background-color: white;
    width: calc(100vw - 280px);
    @media only screen and (max-width: 1024px) {
      left: 81px;
      width: calc(100vw - 106px);
    }
    .navigation-back {
      grid-column: 1/5;
      @media only screen and (max-width: 768px) {
        font-size: 10px;
      }
      cursor: pointer;
      padding-left: 20px;
      margin: auto;
      margin-left: 0px;
      &:hover {
        color: #ff5b00;
      }
    }
    .dropdown {
      grid-column: 6/12;
      display: flex;
      @media only screen and (max-width: 768px) {
        grid-column: 6/13;
      }
      .dropdown-item {
        min-width: 200px;
      }
      #dropdown-custom {
        width: 200px;
        margin-left: 10px;
        border: 1px solid #c5c5c5;
        background-color: #ffffff;
        @media only screen and (max-width: 1132px) {
          width: 125px;
          margin-top: 3px;
          font-size: 14px;
        }
        @media only screen and (max-width: 768px) {
          width: 120px;
          margin-top: 5px;
          font-size: 10px;
        }
      }
      img {
        margin-right: 10px;
        @media only screen and (max-width: 1132px) {
          margin-right: 2px;
        }
        @media only screen and (max-width: 768px) {
          height: 25px;
          width: 25px;
          margin-right: 5px;
          margin-top: 5px;
        }
      }
      p {
        font-size: 16px;
        margin-top: 8px;
        margin-left: 5px;
        @media only screen and (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
    .total-stud {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
    .total-count {
      color: #ff5b00;
    }
  }
`;
