import styled from 'styled-components';

export const ChangeTitleDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 550px;
  background: white;
  padding: 10px;
  align-items: center;
  position: relative;
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    border: none;
    font-size: 18px;
    background: none;
  }
  .title {
    color: #000000;
    font-size: 18px;
    margin: 10px;
  }
  .text {
    color: #060606;
    margin: 10px 10px 30px 10px;
    font-size: 16px;
  }
  .btn-group {
    margin: 10px;
    button {
      width: 180px;
      padding: 8px;
      border-radius: 2px;
      font-size: 16px;
      margin: 0px 20px;
    }
    .no-btn {
      background: white;
      color: #ff5b00;
      border: 1px solid #d5d5d5;
      &:hover {
        background: #fff7f3;
        color: #f25008;
        border-color: #f25008;
      }
    }
    .yes-btn {
      background: #ff5b00;
      color: white;
      border: 1px solid #ff3d3d;
      &:hover {
        background: #f25008;
      }
      :disabled {
        cursor: not-allowed;
      }
    }
  }
  .success-title {
    font-size: 18px;
  }
  .success-btn {
    width: 180px;
    padding: 8px;
    border-radius: 2px;
    font-size: 16px;
    margin: 0px 20px;
    background: #5ed46a;
    color: white;
    border: 1px solid #29c16a;
  }
  .form-container {
    width: 100%;
    padding: 20px;
    .row-title {
      color: #727272;
    }
    .form-row {
      flex-direction: column;
      margin-bottom: 15px;
    }
    .hw-form-inp {
      background: #ffffff;
      border: 0.5px solid #c4c4c4;
      border-radius: 2px;
      height: 30px;
      padding: 0 8px;
    }
    .dropdown-menu.show {
      width: 100%;
    }
    .dropdown-item.active,
    .dropdown-item:active {
      background: transparent;
    }
    .release-dropdown {
      color: #202020;
      padding: 3px 10px;
      font-size: 15px;
      width: 100%;
      background-color: #ffffff;
      border: 1px solid rgb(197, 197, 197) !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .custom-dropdown {
      display: flex;
      justify-content: space-between;
    }
  }
`;
