import {
  SET_USER_PREFERENCES_REQUEST,
  SET_USER_PREFERENCES_SUCCESS,
  SET_USER_PREFERENCES_FAILED
} from './actionTypes';

const initialState = {
  isUpdating: false,
  updatePreferences: null,
  updateError: null
};

export default function userPreferences(state = initialState, action) {
  switch (action.type) {
    case SET_USER_PREFERENCES_REQUEST:
      return {
        ...state,
        isUpdating: true
      };
    case SET_USER_PREFERENCES_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        updatePreferences: action.data
      };
    case SET_USER_PREFERENCES_FAILED:
      return {
        ...state,
        isUpdating: false,
        updatePreferences: action.error
      };
    default:
      return state;
  }
}
