import apiClient from 'services/api';

const handleResponseSuccess = result => {
  if (result.data?.IsSuccessful === false) {
    return {
      error: result.data?.Message?.Message ?? 'Fetch worksheet questions failed' // eslint-disable-line
    };
  }
  return result.data?.Result ?? [];
};

// Fetch list of latest assignments of logged in user
const fetchAssignments = params => {
  const { date, pageSize, pageIndex } = params;
  return apiClient
    .get(
      `AssignmentTracker/GetMyAssignment/${date}?pageSize=${pageSize}&pageIndex=${pageIndex}`
    )
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch assignments failed' // eslint-disable-line
      };
    });
};

// Fetch list of assignments specified by teacherID
const fetchTeacherAssignments = params => {
  const { teacherID, pageSize, pageIndex, year } = params;
  return apiClient
    .get(
      `AssignmentTracker/GetAssignmentByUserId/${teacherID}/${year}?pageSize=${pageSize}&pageIndex=${pageIndex}`
    )
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch assignments failed' // eslint-disable-line
      };
    });
};

const fetchTeachers = () => {
  return apiClient
    .get(`user/GetAllTeachers`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch teachers failed' // eslint-disable-line
      };
    });
};

const checkTeacherAssignments = params => {
  const { teacherID, year } = params;
  return apiClient
    .get(
      `AssignmentTracker/GetAssignmentByUserId/${teacherID}/${year}?pageSize=1&pageIndex=1`
    )
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error:
          err?.response?.data?.message ?? 'Fetch assignments for teacher failed' // eslint-disable-line
      };
    });
};

const fetchMultiClassReport = params => {
  const { homeworkID } = params;
  return apiClient
    .get(`AssignmentTracker/GetMyBlockChartDataByHomeworkId/${homeworkID}`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch multi-class report failed' // eslint-disable-line
      };
    });
};

const fetchSingleClassReport = params => {
  const { homeworkID } = params;
  return apiClient
    .get(`AssignmentTracker/GetChartStatistic/${homeworkID}`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch multi-class report failed' // eslint-disable-line
      };
    });
};

const deleteAssignedHw = params => {
  const { homeworkID } = params;
  return apiClient
    .delete(`AssignmentTracker/DeleteAssignedHomework/${homeworkID}`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Delete assigned homework failed' // eslint-disable-line
      };
    });
};

const changeTitleDate = params => {
  return apiClient
    .put(`AssignmentTracker/UpdateAssignment`, params)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Change title & date failed' // eslint-disable-line
      };
    });
};

const markAnswerRight = params => {
  const { submissionId } = params;
  return apiClient
    .post(`submission/MarkSubmissionRight/${submissionId}`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Failed to mark answer right' // eslint-disable-line
      };
    });
};

export default {
  fetchAssignments,
  fetchTeacherAssignments,
  fetchTeachers,
  checkTeacherAssignments,
  fetchMultiClassReport,
  fetchSingleClassReport,
  deleteAssignedHw,
  changeTitleDate,
  markAnswerRight
};
