import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import showArrow from 'assets/img/icon-report-collapse-show.svg';
import schoolReport from 'assets/img/icon-report-school-report.svg';
import milestoneIcon from 'assets/img/icon-report-sidebar-milestone.svg';
import monthlyIcon from 'assets/img/icon-report-sidebar-monthly.svg';
import yearlyIcon from 'assets/img/icon-report-sidebar-yearly.svg';
import progressIcon from 'assets/img/icon-report-sidebar-progress.svg';
import insightsIcon from 'assets/img/icon-report-sidebar-insight.svg';
import { featureToggle } from 'constants/index';
import { useTranslation } from 'react-i18next';
import { SidebarContainer } from './AllReports.styles';

export default function SchoolReportSidebar() {
  const { pathname } = useLocation();
  const isActivePath = path => path === pathname;
  const { t } = useTranslation(['monthly-report']);
  return (
    <>
      <SidebarContainer>
        <div className="accordion" id="school-report">
          <div>
            <button
              type="button"
              className="btn"
              data-toggle="collapse"
              data-target="#schoolReport"
            >
              <span className="report-category-icon">
                <img src={schoolReport} alt="icon-list" />
              </span>
              <span className="report-category">
                {t(`monthly-report:sidebar.schollReport`, 'School Report')}
              </span>
            </button>
            <img src={showArrow} alt="show" className="arrow" />
            <div
              className="collapse show"
              id="schoolReport"
              data-parent="#school-report"
            >
              {featureToggle.reportSidebarOptions && (
                <li className="list-group-item">
                  <Link
                    className={`nav-link${
                      isActivePath('/school-report') ? ' nav-link-active' : ''
                    }`}
                    to="/school-report"
                  >
                    <img
                      src={milestoneIcon}
                      alt="icon-list"
                      className="sidebar-icon"
                    />
                    <span>
                      {t(`monthly-report:sidebar.milestone`, 'Milestone')}
                    </span>
                  </Link>
                </li>
              )}
              <li className="list-group-item">
                <Link
                  className={`nav-link${
                    isActivePath('/report/school-report/monthly-activity')
                      ? ' nav-link-active'
                      : ''
                  }`}
                  to="/report/school-report/monthly-activity"
                >
                  <img
                    src={monthlyIcon}
                    alt="icon-list"
                    className="sidebar-icon"
                  />
                  <span>
                    {t(
                      `monthly-report:sidebar.monthlyActivity`,
                      'Monthly Activity'
                    )}
                  </span>
                </Link>
              </li>
              {featureToggle.reportSidebarOptions && (
                <>
                  <li className="list-group-item">
                    <Link
                      className={`nav-link${
                        isActivePath('/report/school-report/yearly-activity')
                          ? ' nav-link-active'
                          : ''
                      }`}
                      to="/report/school-report/yearly-activity"
                    >
                      <img
                        src={yearlyIcon}
                        alt="icon-list"
                        className="sidebar-icon"
                      />
                      <span>
                        {t(
                          `monthly-report:sidebar.yearlyActivity`,
                          'Yearly Activity'
                        )}
                      </span>
                    </Link>
                  </li>
                  <li className="list-group-item">
                    <Link
                      className={`nav-link${
                        isActivePath('/report/school-report/learning-progress')
                          ? ' nav-link-active'
                          : ''
                      }`}
                      to="/report/school-report/learning-progress"
                    >
                      <img
                        src={progressIcon}
                        alt="icon-list"
                        className="sidebar-icon"
                      />
                      <span>
                        {t(
                          `monthly-report:sidebar.learningProgress`,
                          'Learning Progress'
                        )}
                      </span>
                    </Link>
                  </li>
                  <li className="list-group-item">
                    <Link
                      className={`nav-link${
                        isActivePath('/report/school-report/insights')
                          ? ' nav-link-active'
                          : ''
                      }`}
                      to="/report/school-report/insights"
                    >
                      <img
                        src={insightsIcon}
                        alt="icon-list"
                        className="sidebar-icon"
                      />
                      <span>
                        {t(`monthly-report:sidebar.insights`, 'Insights')}
                      </span>
                    </Link>
                  </li>
                </>
              )}
            </div>
          </div>
        </div>
      </SidebarContainer>
    </>
  );
}
