import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { Form, Col, Row } from 'react-bootstrap';
import { searchingQuestions, addQuestions } from 'stores/questions/actions';
import SearchResult from 'components/Search/SearchResult';

export default function ImportQuestionList() {
  const { t, i18n } = useTranslation(['create-assign-import-qn']);
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAdmin } = useSelector(state => state.login);
  const { selectedQuestions } = useSelector(state => state.questions);

  const [searchIds, setSearchIds] = useState('');
  const [emptyCart, setEmptyCart] = useState(0);
  const [debouncedSearchTerm] = useDebounce(searchIds, 500);
  const isEnglish = i18n.language === 'en';
  const handleChange = event => {
    const re = /^[0-9\s\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      setSearchIds(event.target.value);
    }
  };

  const handelSubmit = () => {
    dispatch(addQuestions(emptyCart));
    setSearchIds('');
  };

  useEffect(() => {
    if (isAdmin !== true) {
      history.push('/create-and-assign');
    }
  }, [history, isAdmin]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      const questionIds = debouncedSearchTerm
        .split(/\s+/)
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        })
        .join(',');
      dispatch(searchingQuestions(questionIds));
    }
  }, [dispatch, debouncedSearchTerm]);

  return (
    <div className="box-white-wrap import-question-list">
      <div className="row">
        <div className="col-12 mb-4 position-relative">
          <div className="box-white h-100 d-flex flex-column">
            <div className="box-white-body h-100">
              <Form.Group as={Row}>
                <Col sm="12">
                  <Form.Control
                    as="textarea"
                    rows="10"
                    value={searchIds}
                    onChange={handleChange}
                    placeholder={t(
                      'create-assign-import-qn:placeholder',
                      'Search question by ID'
                    )}
                    className="import-question-list__input--text"
                  />
                </Col>
              </Form.Group>
              <SearchResult keyword={searchIds} />
              <div className="d-flex bd-highlight">
                <div className="bd-highlight">
                  <Form.Check
                    checked={emptyCart}
                    type="checkbox"
                    onChange={() => setEmptyCart(!emptyCart)}
                    label={t('create-assign-import-qn:emptyCart', 'Empty Cart')}
                    bsPrefix="form-check-visible import-question-list__input--check"
                  />
                </div>
                <div className="bd-highlight import-question-list__label--info">
                  {t(
                    'create-assign-import-qn:qnList',
                    {
                      qn: selectedQuestions.length
                    },
                    `Selected ${selectedQuestions.length} Question`
                  )}
                  {isEnglish && selectedQuestions.length > 1 ? 's' : ''}
                </div>
                <button
                  type="button"
                  className="btn btn-primary bd-highlight flex-grow-1 import-question-list__btn--add"
                  onClick={handelSubmit}
                  disabled={selectedQuestions.length === 0}
                >
                  {t(
                    'create-assign-import-qn:btn',
                    'Add Valid Question To Cart'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
