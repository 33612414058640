import React, { useCallback, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setPresentationType } from 'stores/worksheet/actions';
import NavigationWizard from 'components/CreateAndAssign/NavigationWizard';
import { presentationTypes } from 'constants/index';
import { nextStep } from 'stores/utils/step/actions';
import isNilOrEmpty from 'helpers/isNilOrEmpty';
import ButtonStepPrev from 'components/Shared/ButtonStepPrev';
import ChooseWorkSheetTimeType from 'components/CreateAndAssign/FactualFluency/ChooseWorkSheetTimeType';
import iconFactualFluency from 'assets/img/icon-factual-fluency.png';
import { Container, HeaderBox, ContentBox } from './WorksheetTimer.styles';

export default function WorksheetTimer() {
  const { t } = useTranslation(['create-assign-question']);
  const dispatch = useDispatch();
  const { presentationType } = useSelector(state => state.worksheet);

  const presentationTypeID = isNilOrEmpty(presentationType)
    ? presentationTypes.kooQuiz
    : parseInt(presentationType, 10);

  useEffect(() => {
    dispatch(setPresentationType(presentationTypeID));
  }, [presentationTypeID, dispatch]);

  const handleOnNext = useCallback(() => {
    dispatch(nextStep());
  }, [dispatch]);

  return (
    <Container className="timer-setting">
      <HeaderBox>
        <div className="back-button-wrap">
          <ButtonStepPrev
            title={t(`create-assign-question:editWo.back`, '< Back')}
          />
        </div>
        <div className="title-wrap">
          <div className="spacer" />
          <div className="title-container">
            <div className="title">
              <img src={iconFactualFluency} alt="iconFactualFluency" />
              <h5>
                {t(
                  `create-assign-question:timeSetting.chooseTimeType`,
                  'Factual Fluency Presentation Setting'
                )}
              </h5>
            </div>
            <p className="desc">
              <Trans i18nKey="create-assign-question:timeSetting.desc">
                Choose one <strong>time setting</strong> for your worksheet
              </Trans>
            </p>
          </div>
          <NavigationWizard onClick={handleOnNext} />
        </div>
      </HeaderBox>
      <ContentBox>
        <ChooseWorkSheetTimeType />
      </ContentBox>
    </Container>
  );
}
