import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Spinner from 'components/Shared/Spinner';
import ErrorIcon from 'assets/img/icon-error.svg';
import AlertModal from './AlertModal';

const InfoScheduledWorksheetModal = props => {
  const { t } = useTranslation(['create-assign-question']);
  const history = useHistory();
  const { isShow, onConfirm, onClose, isLoading, error } = props;
  const buttons = (
    <>
      {error === null && (
        <button type="button" className="btn btn-primary" onClick={onConfirm}>
          {t(`create-assign-question:main.next`, 'Next')}
        </button>
      )}
    </>
  );

  const errorTitle = (
    <p style={{ textAlign: 'center', color: '#000000' }}>
      <span>
        <img
          src={ErrorIcon}
          alt="error"
          style={{
            width: '40px',
            marginRight: '20px',
            marginBottom: '5px'
          }}
        />
        {t(`create-assign-question:saveWoModal.error`, 'Error')}
      </span>
    </p>
  );
  return (
    <AlertModal
      title={
        isLoading
          ? t(
              `create-assign-question:saveAssignModal.assigningWo`,
              'Assigning Worksheet'
            )
          : error === null
          ? t(
              `create-assign-question:saveAssignModal.hwSceduled`,
              'Homework Scheduled Succesfully!'
            )
          : errorTitle
      }
      isShow={isShow}
      handleClose={onClose}
      buttons={buttons}
    >
      <div className="custom-popups__assign-success">
        {isLoading && <Spinner />}
        {!isLoading && error === null && (
          <>
            <p>
              {t(
                `create-assign-question:saveAssignModal.hwAssignedMsg`,
                'Homework Scheduled Succesfully!This Worksheet has been scheduled to the classes / student that you have selected'
              )}
            </p>
            <p>
              {t(
                `create-assign-question:saveAssignModal.trackAssign`,
                'Track Assignment'
              )}
            </p>
            <ul>
              <li className="custom-popups-list">
                {t(
                  `create-assign-question:saveAssignModal.trackStuActions`,
                  'Track student assignment actions'
                )}
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => history.push('/track-assignment')}
                >
                  <i className="icon-track-assignment" />
                  {t(
                    `create-assign-question:saveAssignModal.trackAssign`,
                    'Track Assignment'
                  )}
                </button>
              </li>
              <li className="custom-popups-list">
                {t(
                  `create-assign-question:saveAssignModal.editReWo`,
                  'To edit or re-use your worksheet, go to'
                )}
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() =>
                    history.push('/create-and-assign/saved-worksheet')
                  }
                >
                  <i className="icon-saved-worksheet-grey" />
                  {t(
                    `create-assign-question:saveWoModal.savedWo`,
                    'Saved Worksheet'
                  )}
                </button>
              </li>
            </ul>
          </>
        )}
        {!isLoading && error !== null && (
          <p
            style={{
              textAlign: 'center',
              marginTop: '15px',
              color: '#000000',
              marginBottom: '20px',
              fontWeight: 'normal'
            }}
          >
            {t(
              `create-assign-question:saveAssignModal.failedSchedule`,
              'Failed to schedule worksheet.'
            )}{' '}
            <br />{' '}
            {t(
              `create-assign-question:saveAssignModal.tryAgain`,
              'Please try again.'
            )}
          </p>
        )}
      </div>
    </AlertModal>
  );
};

export default InfoScheduledWorksheetModal;
