import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { isEmpty, isNil } from 'ramda';
import { Dropdown, Spinner, Button } from 'react-bootstrap';

import { HOTS_CURR_ID, AGE_GROUP } from 'constants/hotsCurr';
import reportServices from 'services/reports';
import {
  setSelectedClass,
  setReportLevel,
  setSyllabus,
  setClassLevel
} from 'stores/report/actions';

import PopupModal from 'components/Shared/PopupModal';

import CloseIcon from 'assets/img/icon-closed.svg';
import downloadCSVIcon from 'assets/img/report/download-csv.svg';
import successIcon from 'assets/img/icon-manage-class-success-check.svg';
import errIcon from 'assets/img/icon-error.svg';
import {
  TopBarContainer,
  DownloadCSV,
  DownloadCSVModalBody,
  DownloadCSVSuccessBody
} from './TopBar.styles';

export default function TopBar({
  type,
  classes,
  selectedClass,
  reportLevels,
  selectedLevel,
  curriculums,
  selectedSyllabus,
  selectedClassLevel,
  classLevels,
  detailType
}) {
  const { t } = useTranslation(['proficiency-report', 'common']);
  const [openDownloadCsvModal, setOpenDownloadCsvModal] = useState(false);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);
  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const [err, setErr] = useState(null);
  const dispatch = useDispatch();
  // For scroll behavior
  const isLeveless = isEmpty(selectedSyllabus.Levels);
  const selectedClassName =
    selectedClass && selectedClass.Name ? selectedClass.Name : '-';
  const getLevel = syll => {
    const isSelectedLeveless = isEmpty(syll.Levels);
    const checkLevelAvailable =
      syll && syll.Levels.find(item => item.ID === selectedLevel);
    if (!isSelectedLeveless) {
      if (isNil(checkLevelAvailable)) {
        return syll.Levels[0];
        // eslint-disable-next-line no-else-return
      } else {
        return { ID: selectedLevel };
      }
      // eslint-disable-next-line no-else-return
    } else {
      return { ID: -1 };
    }
  };
  const changeItemLevel = level => {
    dispatch(setClassLevel(level));
    // dispatch(setReportLevel(level));
  };
  const handleDownloadCsv = async () => {
    setDownloadIsLoading(true);
    setErr(null);
    const rawBody = {
      classID: selectedClass.ID,
      reportLevel: selectedLevel.ID,
      curriculumID: selectedSyllabus.CurriculumID,
      fileName: `${type} report.csv`
    };
    try {
      if (type === 'proficiency') {
        await reportServices.fetchDownloadProficiencyReport(rawBody);
      }
      if (type === 'highscore') {
        await reportServices.fetchDownloadHighScoreReport(rawBody);
      }
      setDownloadSuccess(true);
    } catch (error) {
      setErr(error.message);
    }
    setDownloadIsLoading(false);
  };

  const isHOTS = selectedSyllabus?.CurriculumID === HOTS_CURR_ID;

  return (
    <>
      <TopBarContainer>
        <div className="title-container">
          <p className="title">
            {type === 'proficiency'
              ? t(
                  `proficiency-report:header.stuProReport`,
                  'Student Proficiency Report'
                )
              : t(
                  `proficiency-report:sidebar.highScoreReport`,
                  'High Score Report'
                )}
          </p>
          <p className="subtitle">
            {t(`proficiency-report:header.upTo`, 'up to ')}
            <span>{moment().format('DD/MM/YYYY HH:mm')}</span>
          </p>
        </div>
        <div className="dropdown-container">
          <div className="dropdown-container-group">
            <div className="class-group-dropdown">
              <p className="dropdown-label">
                {t(`proficiency-report:header.classGroup`, 'Class/Group from')}
              </p>
              <Dropdown>
                <Dropdown.Toggle
                  variant="light"
                  className="dropdown-custom level"
                >
                  {`P${selectedClassLevel}`}
                </Dropdown.Toggle>
                <Dropdown.Menu flip={false}>
                  {classLevels &&
                    classLevels.map(level => (
                      <Dropdown.Item
                        key={level}
                        onClick={() => {
                          const findLevel = reportLevels.find(
                            le => le.ID === level
                          );
                          if (findLevel) {
                            dispatch(setReportLevel(findLevel));
                          }
                          changeItemLevel(level);
                        }}
                      >{`P${level}`}</Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  variant="light"
                  className="dropdown-custom class-group"
                >
                  {selectedClassName}
                </Dropdown.Toggle>
                <Dropdown.Menu flip={false}>
                  {classes.map(item => (
                    <Dropdown.Item
                      key={item.ID}
                      onClick={() => {
                        dispatch(setSelectedClass(item));
                      }}
                    >
                      {item && item.Name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="report-level-dropdown">
              {!isLeveless && (
                <>
                  <p className="dropdown-label">
                    {t(
                      `proficiency-report:header.reportLevel`,
                      'Report Level from'
                    )}
                  </p>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="light"
                      className="dropdown-custom level"
                    >
                      {isHOTS
                        ? `${t(`common:age`, 'Age')} ${
                            AGE_GROUP[selectedLevel.ID]
                          }`
                        : `P${selectedLevel.ID} ${t(
                            `proficiency-report:header.data`,
                            'Data'
                          )}`}
                    </Dropdown.Toggle>
                    <Dropdown.Menu flip={false}>
                      {reportLevels.map(level => (
                        <Dropdown.Item
                          key={level.ID}
                          onClick={() => {
                            dispatch(setReportLevel(level));
                          }}
                        >
                          {isHOTS
                            ? `${t(`common:age`, 'Age')} ${AGE_GROUP[level.ID]}`
                            : `P${level.ID} ${t(
                                `proficiency-report:header.data`,
                                'Data'
                              )}`}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}
              {isLeveless && (
                <p className="dropdown-label">
                  {t(`proficiency-report:header.syllabus`, 'Syllabus')}
                </p>
              )}
              <Dropdown>
                <Dropdown.Toggle
                  variant="light"
                  className="dropdown-custom syllabus"
                >
                  {selectedSyllabus.curriculumName}
                </Dropdown.Toggle>
                <Dropdown.Menu flip={false}>
                  {curriculums.map(syl => (
                    <Dropdown.Item
                      key={syl.CurriculumID}
                      onClick={() => {
                        dispatch(setSyllabus(syl));
                        dispatch(setReportLevel(getLevel(syl)));
                      }}
                    >
                      {syl.curriculumName}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {detailType === 'skill' && (
            <DownloadCSV
              onClick={() => {
                if (downloadSuccess) setDownloadSuccess(false);
                setOpenDownloadCsvModal(true);
              }}
            >
              <img src={downloadCSVIcon} alt="downloadCSVIcon" />
              <span>{t(`common:download`, 'Download')}</span>
            </DownloadCSV>
          )}
        </div>
      </TopBarContainer>
      {detailType === 'skill' && (
        <PopupModal
          show={openDownloadCsvModal}
          hide={() => setOpenDownloadCsvModal(false)}
        >
          {downloadSuccess || err ? (
            <DownloadCSVSuccessBody fail={Boolean(err)}>
              <span className="success-title">
                <img
                  src={err ? errIcon : successIcon}
                  alt={`${err ? 'Error' : 'Success'} icon`}
                  style={{ marginRight: '0.5rem' }}
                />
                {err
                  ? t('common:error', 'Error!')
                  : t('common:success', 'Success!')}
              </span>
              <span className="success-description">
                {err ||
                  t(
                    'proficiency-report:downloadCSVModal.succesMsg',
                    'Report downloaded successfully!'
                  )}
              </span>
              <Button
                variant="success-ok"
                onClick={() => {
                  setOpenDownloadCsvModal(false);
                }}
              >
                {t('common:ok', 'OK')}
              </Button>
            </DownloadCSVSuccessBody>
          ) : (
            <DownloadCSVModalBody>
              <img
                src={CloseIcon}
                alt="close"
                className="close"
                onClick={() => setOpenDownloadCsvModal(false)}
              />
              <div className="title">
                <img src={downloadCSVIcon} alt="downloadCSVIcon" />
                <span>
                  {t(
                    `proficiency-report:downloadCSVModal.title`,
                    'Download CSV'
                  )}
                </span>
              </div>
              <div className="modal-content">
                <p>
                  {t(
                    `proficiency-report:downloadCSVModal.content`,
                    'Do you want to download the raw data from'
                  )}
                </p>
                <p>
                  P{selectedClassLevel} {selectedClassName}{' '}
                  {!isLeveless &&
                    `- P${selectedLevel.ID} ${t(
                      `proficiency-report:header.data`,
                      'Data'
                    )} - ${selectedSyllabus.curriculumName}`}
                </p>
              </div>
              <hr />
              <div className="action">
                <button
                  type="button"
                  className="btn btn-cancel"
                  onClick={() => setOpenDownloadCsvModal(false)}
                >
                  {t('common:cancel', 'Cancel')}
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-download"
                  onClick={handleDownloadCsv}
                  disabled={downloadIsLoading}
                >
                  {downloadIsLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    t('common:download', 'Download')
                  )}
                </button>
              </div>
            </DownloadCSVModalBody>
          )}
        </PopupModal>
      )}
    </>
  );
}
