import React from 'react';

const Stepper = props => {
  const {
    steps,
    showNumber,
    activeStep,
    onSelect,
    className,
    backButton
  } = props;
  const lastIndexOfSteps = steps.length - 1;
  const handleChangeClass = index => {
    if (activeStep === index + 1) {
      return 'stepper-item-inner-active';
    }
    if (index + 1 < activeStep) {
      return 'stepper-item-inner-completed';
    }
    return 'stepper-item-inner-future';
  };
  return (
    <div
      className={`content__stepper-block ${
        steps.length === activeStep
          ? 'content__stepper-block--last stepper-container--last'
          : ''
      } ${className || ''}`}
    >
      {backButton}
      <div className="stepper-container" role="tablist">
        {steps.map((step, index) => {
          return (
            <React.Fragment key={`step-${step.title.replace(' ', '-')}`}>
              <div
                className={
                  index + 1 < activeStep
                    ? 'stepper-item-complete'
                    : 'stepper-item'
                }
              >
                <div
                  onClick={onSelect.bind(null, index + 1)}
                  onKeyPress={onSelect.bind(null, index + 1)}
                  className="stepper-item-outer"
                  role="tab"
                  tabIndex={0}
                >
                  <span
                    className={`stepper-item-inner ${handleChangeClass(index)}`}
                  >
                    {' '}
                    {showNumber && index + 1}{' '}
                  </span>
                  <span
                    className={`stepper-title ${
                      activeStep === index + 1 ? 'stepper-title-active' : ''
                    }`}
                  >
                    {' '}
                    {step.title}{' '}
                  </span>
                </div>
              </div>
              {lastIndexOfSteps === index ? (
                ''
              ) : (
                <div className="stepper-item-outer" />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
export default Stepper;
