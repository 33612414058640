import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const SpecificMonthlyReportStyle = styled.div`
  padding-left: 195px;
  @media (max-width: 1024px) {
    padding-left: 56px;
  }
  @media (max-width: 639px) {
    padding-left: 0px;
  }
  .top-bar {
    display: flex;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
    position: fixed;
    right: 0;
    left: 195px;
    z-index: 100;
    justify-content: space-between;
    top: 53px;
    padding: 10px 100px 10px 50px;
    @media (max-width: 1024px) {
      left: 56px;
    }
    @media (max-width: 639px) {
      left: 0px;
    }
    .backButtonWrapper {
      align-self: center;
    }
    .headerRight {
      display: flex;
    }
    .dropdown-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      .dropdown-label {
        margin-top: 15px;
        font-size: 16pxpx;
        font-weight: 700;
      }
      .dropdown-custom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100px;
        margin-left: 10px;
        border: 1px solid rgba(112, 112, 112, 0.48);
        background-color: #ffffff;
        font-size: 14px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 882px) {
          font-size: 12px;
          width: 120px;
        }
        @media (max-width: 744px) {
          font-size: 10px;
          width: 110px;
        }
      }
    }
    .back-button {
      color: #545454;
      cursor: pointer;
      flex: 1.5;
      /* flex-basis: 45%; */
      &:hover {
        color: #ff5b00;
      }
      border: none;
      background-color: transparent;
    }
    .action-button {
      button {
        padding: 10px;
        margin-left: 30px;
        background: none;
        border: 1px solid rgba(112, 112, 112, 0.48);
        box-sizing: border-box;
        border-radius: 1px;
        color: #545454;
        &:hover {
          color: #ff5b00;
        }
        @media (max-width: 882px) {
          font-size: 12px;
        }
        @media (max-width: 744px) {
          font-size: 10px;
        }
      }
      .printIcon {
        margin-right: 10px;
        color: #545454;
        img {
          @media (max-width: 882px) {
            width: 18px;
          }
          @media (max-width: 744px) {
            font-size: 10px;
          }
        }
      }
    }
  }
`;
