import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const ModalStyled = styled(Modal)`
  .modal-dialog {
    min-width: 50%;
  }
  .question-content {
    color: #008bbe;
    font-weight: bold;
    padding: 1rem;
  }
  .modal-body {
    padding: 0;
  }
`;

export const StudentAnswers = styled.div`
  .answer {
    border-top: solid 1px #dbe0d9;
    margin-top: 1rem;
  }
  .correct-box,
  .wrong-box {
    padding: 0.5rem 1rem;
    .title {
      font-weight: 600;
      margin-bottom: 0.3rem;
    }
    .name {
      font-weight: bold;
      color: #363636;
    }
    p {
      margin-bottom: 0px;
      line-height: 20px;
      color: #363636;
    }
  }
  .correct-box {
    background-color: #f5fdf2;
    .title {
      color: #55c92b;
    }
  }
  .wrong-box {
    background-color: #fff6f5;
    .title {
      color: #d5463b;
    }
  }
`;
