import styled from 'styled-components';

export const DatePickerStyle = styled.div`
  background: #ffffff;
  border: 0.5px solid #c4c4c4;
  border-radius: 2px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 10px;
  color: #202020;
  cursor: pointer;
  span {
    font-size: 15px;
  }
`;
