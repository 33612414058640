import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSampleQnRequest } from 'stores/report/actions';
import GradeAIcon from 'assets/img/icon-report-high-score-a.svg';
import GradeBIcon from 'assets/img/icon-report-high-score-b.svg';
import GradeCIcon from 'assets/img/icon-report-high-score-c.svg';
import GradeDIcon from 'assets/img/icon-report-high-score-d.svg';
import IncompleteIcon from 'assets/img/icon-report-high-score-incomplete.svg';
import { isNil } from 'ramda';
import HTMLReactParser from 'html-react-parser';
import { ProficiencyStatus, MasteryLevel } from 'constants/index';
import questionParser from 'helpers/questionParser';
import convertTagsToHTML from 'helpers/text/convertTagsToHTML';
import HighScoreStars from './HighScoreStars';
import {
  SkillDetailsContainer,
  SkillDetailsHeader,
  SkillBadge,
  QuestionContainer,
  SolutionContainer
} from './SkillDetails.styles.js';

const SkillDetailsQuestion = ({ data }) => {
  const { t } = useTranslation(['proficiency-report']);
  // States
  const [questionTitle, setQuestionTitle] = useState(null);
  const [questionSolution, setQuestionSolution] = useState(null);

  // Effects
  useEffect(() => {
    if (!isNil(data.Payload)) {
      const { title, solution } = questionParser({
        Payload: data.Payload
      });
      setQuestionTitle(
        HTMLReactParser(convertTagsToHTML(title), {
          replace: domNode => {
            if (domNode.name === 'img' && !isNil(domNode.attribs?.url)) {
              return (
                <img
                  src={domNode.attribs?.url}
                  alt={domNode.attribs?.uniqueid}
                />
              );
            }
            if (domNode.name === 'helptext') {
              return (
                <small id="questionHelp" className="form-text text-muted">
                  {domNode.children[0]?.data ?? ''}
                </small>
              );
            }
            if (domNode.name === 'input') {
              return <input type="text" />;
            }
            return domNode;
          }
        })
      );

      setQuestionSolution(HTMLReactParser(convertTagsToHTML(solution)));
    }
  }, [data.Payload]);
  return (
    <>
      <QuestionContainer>
        <p className="question-title">
          {t(
            `proficiency-report:studentReportSide.sampleQs`,
            'Sample Question'
          )}{' '}
          ( ID: {data.Id} )
        </p>
        <p className="question-content">{questionTitle}</p>
      </QuestionContainer>
      <SolutionContainer>
        <p className="solution-title">
          {t(`proficiency-report:studentReportSide.solution`, 'Solution')}
        </p>
        <p className="solution-content">{questionSolution}</p>
      </SolutionContainer>
    </>
  );
};

export default function SkillDetails({
  type,
  selectedSkill,
  selectedSkillTopic,
  selectedSkillNumber
}) {
  const { t } = useTranslation(['proficiency-report']);
  const GradeIconMapping = {
    D: GradeDIcon,
    C: GradeCIcon,
    B: GradeBIcon,
    A: GradeAIcon,
    incomplete: IncompleteIcon
  };
  const dispatch = useDispatch();
  const { isLoading, sampleQn } = useSelector(state => state.reports);
  // useEffect
  useEffect(() => {
    dispatch(
      fetchSampleQnRequest({
        qnID: selectedSkill.SampleQuestionID
      })
    );
  }, [dispatch, selectedSkill.SampleQuestionID]);
  return (
    <SkillDetailsContainer>
      <SkillDetailsHeader>
        <p className="skill-name">
          <strong>{selectedSkillTopic}</strong>
        </p>
        <div style={{ marginBottom: '0.5rem' }}>
          <span className="skill-number">
            {t(`proficiency-report:studentReportSide.skill`, 'Skill')}{' '}
            {selectedSkillNumber}
          </span>
          {type === 'highscore' && (
            <HighScoreStars numOfStars={selectedSkill.MasteryLevel} />
          )}
          {type === 'proficiency' ? (
            <SkillBadge
              proficiency={ProficiencyStatus[selectedSkill.ProficiencyStatus]}
            >
              {t(
                `proficiency-report:legend.${
                  ProficiencyStatus[selectedSkill.ProficiencyStatus]
                }`,
                `${ProficiencyStatus[selectedSkill.ProficiencyStatus]
                  .charAt(0)
                  .toUpperCase() +
                  ProficiencyStatus[selectedSkill.ProficiencyStatus].slice(1)}`
              )}
            </SkillBadge>
          ) : null}
        </div>
        <p className="skill-description">{selectedSkill.SkillName}</p>
        <div style={{ marginBottom: '0.5rem' }}>
          {type === 'highscore' && (
            <img
              src={GradeIconMapping[MasteryLevel[selectedSkill.MasteryLevel]]}
              alt={MasteryLevel[selectedSkill.MasteryLevel]}
            />
          )}
          {type === 'proficiency' && (
            <>
              <span className="student-percentage">
                <strong>{`${Math.round(
                  selectedSkill.Proficiency * 100
                )}%`}</strong>
              </span>
              <span className="class-avg-title">
                {t(
                  `proficiency-report:studentReportSide.classAvg`,
                  'Class Avg'
                )}
              </span>
              <span className="class-avg-percentage">
                <strong>
                  {`${Math.round(selectedSkill.ClassAverage * 100)}%`}
                </strong>
              </span>
            </>
          )}
        </div>
      </SkillDetailsHeader>
      {!isLoading && sampleQn && <SkillDetailsQuestion data={sampleQn} />}
    </SkillDetailsContainer>
  );
}
