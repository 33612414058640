import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAvatarsRequest } from 'stores/profileSettings/actions';
import defaultIcon from 'assets/img/defaulticon.svg';
import cameraIcon from 'assets/img/profile-camera.svg';
import Avatar from 'assets/img/avatar.png';
import ProfileImageModal from './ProfileImageModal';
import { ProfileImageStyle } from './ProfileImage.style';

export default function ProfileImage({
  profileImage,
  profileAvatarId,
  setProfileAvatarId,
  removeDisableButton
}) {
  const dispatch = useDispatch();
  const { avatars } = useSelector(state => state.profileSettings);
  const [showModal, setShowModal] = useState(false);
  const [mainImage, setMainImage] = useState(profileImage);

  useEffect(() => {
    dispatch(fetchAvatarsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (profileImage !== undefined) setMainImage(profileImage);
  }, [profileImage]);

  const openModal = () => setShowModal(true);

  const closeModal = () => setShowModal(false);

  const setImageAndId = (img, id) => {
    removeDisableButton();
    setMainImage(img);
    setProfileAvatarId(id);
  };

  return (
    <ProfileImageStyle>
      <img
        src={mainImage || defaultIcon}
        alt="default-icon"
        className="profile-img"
        onError={e => {
          e.target.src = Avatar;
        }}
      />
      <span className="camera-border" onClick={openModal}>
        <img src={cameraIcon} alt="camera-icon" />
      </span>
      {showModal && (
        <ProfileImageModal
          show={showModal}
          handleClose={closeModal}
          avatarId={profileAvatarId}
          saveImage={setImageAndId}
          resultImages={avatars}
        />
      )}
    </ProfileImageStyle>
  );
}
