import {
  FETCH_SYLLABUS_REQUEST,
  FETCH_SYLLABUS_SUCCESS,
  FETCH_SYLLABUS_FAILED
} from './actionTypes';

export const fetchSyllabusRequest = () => {
  return { type: FETCH_SYLLABUS_REQUEST };
};

export const fetchSyllabusSuccess = syllabus => {
  return { type: FETCH_SYLLABUS_SUCCESS, syllabus };
};

export const fetchSyllabusFailed = error => {
  return { type: FETCH_SYLLABUS_FAILED, error };
};
