const { default: styled } = require('styled-components');

export const ContainerPrint = styled.div`
  width: 210mm;
  height: 297mm;
  font-size: 12px;
  font-family: 'Verdana', Arial, Helvetica, sans-serif;
  color: #222;
  margin: 0 auto;
  @media all {
    .page-break {
      display: none;
    }
    .question-break {
      display: none;
    }
  }
  @media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media print {
    .page-break {
      margin-top: 0px;
      display: block;
      page-break-after: always;
    }
  }
  @page {
    margin: 20mm 0mm;
    size: 210mm 290mm;
  }
`;

export const ContainerPaper = styled.div`
  color: #000;
  @media print {
    padding: 0px auto;
  }
  .worksheet-title {
    margin: 0 0 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .left-title {
      width: 50%;
      p {
        text-align: left;
        font-size: 30px;
        margin-bottom: 10px;
        margin-top: -10px;
      }
    }
    .right-title {
      width: 50%;
      text-align: right;
      p {
        span {
          border-bottom: 1px solid black;
        }
      }
    }
    .inputWrapper {
      margin-left: 5px;
    }
  }

  .header-text {
    padding: 15px 0;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 15px;
  }

  @media print {
    .question-break {
      margin-bottom: 10mm;
      display: block;
      page-break-inside: auto;
    }
  }
`;

export const QuestionsList = styled.div`
  .question-item-body {
    margin-bottom: 50px;
  }

  @media print {
    .question-item-body {
      margin-top: 10mm;
      page-break-inside: avoid;
    }
  }
`;

export const SolutionsList = styled.div`
  padding-top: 200px;
  .solution-header {
    font-size: 40px;
    text-align: center;
    margin-bottom: 30px;
  }

  .solution-item-body {
    margin-bottom: 10px;
  }

  @media print {
    padding-top: 0;
    .solution-item-body {
      page-break-inside: avoid;
    }
  }
`;

export const Container = styled.div`
  .top {
    display: flex;
    width: 100%;
    padding: 0 2rem;
    margin-bottom: 2rem;
    justify-content: flex-end;
  }
`;
