import React, { useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Sidebar from 'components/Sidebar/Sidebar';
import SidebarManageClass from 'components/Sidebar/SidebarManageClass';
import Overlay from 'components/Content/Overlay';
import Header from 'components/Header/Header';
import GroupAdd from './GroupAdd';
import GroupsList from './GroupsList';
import DisplayClass from './DisplayClass';
import StudentList from './StudentList';
import ShowGroup from './ShowGroup/ShowGroup';
import CreateGroup from './CreateGroup/CreateGroup';
import GameSetting from './GameSetting';

export default function ManageClass() {
  const [expanded, setExpanded] = useState(false);
  const toggleNavigation = () => setExpanded(!expanded);
  const { pathname } = useLocation();
  const getContentClassName = () =>
    `content ${
      pathname === '/manage-class/add'
        ? ' content-sidebar-small'
        : ' content-sidebar-none'
    }`;

  return (
    <>
      <Header />
      <main>
        <Sidebar
          expanded={expanded}
          toggleNavigation={toggleNavigation}
          className="sidebar--auto"
        >
          <SidebarManageClass />
        </Sidebar>
        <div className={getContentClassName()}>
          <Overlay isVisible={expanded} toggleVisible={toggleNavigation} />
          <Switch>
            <Route exact path="/manage-class" component={DisplayClass} />
            <Route exact path="/manage-class/show" component={ShowGroup} />
            <Route
              exact
              path="/manage-class/create-group"
              component={CreateGroup}
            />
            <Route exact path="/manage-class/add" component={GroupAdd} />
            <Route
              exact
              path="/manage-class/privategroups"
              component={GroupsList}
            />
            <Route path="/manage-class/game-setting" component={GameSetting} />
            <Route path="/manage-class/:classID" component={StudentList} />
          </Switch>
        </div>
      </main>
    </>
  );
}
