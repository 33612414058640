import React from 'react';
import questionParser from 'helpers/questionParser';
import convertTagsToHTML from 'helpers/text/convertTagsToHTML';
import HTMLReactParser from 'html-react-parser';
import {
  QuestionAnswer,
  QuestionNumber,
  QuestionText,
  QuestionWrap
} from './FactualFluencyQuestion.style';

const FactualFluencyQuestion = ({
  questionNumber,
  question,
  onChange,
  onSubmitAnswer,
  value
}) => {
  const { title } = questionParser(question.CartQuestion);

  const renderQuestion = HTMLReactParser(convertTagsToHTML(title, false), {
    replace: domNode => {
      if (domNode.name === 'p') {
        return (
          <QuestionText className="question">
            {domNode.children[0]?.data.replace('__', '') ?? ''}
          </QuestionText>
        );
      }
      if (domNode.name === 'input') {
        return (
          <QuestionAnswer className="question">
            <input
              type="text"
              className="form-control"
              onChange={onChange}
              onKeyPress={onSubmitAnswer}
              value={value}
            />
          </QuestionAnswer>
        );
      }
      return domNode;
    }
  }).filter(el => el.type !== 'br');
  return (
    <QuestionWrap>
      <QuestionNumber className="question">{`Q${questionNumber}:`}</QuestionNumber>
      {renderQuestion}
    </QuestionWrap>
  );
};

export default React.memo(FactualFluencyQuestion);
