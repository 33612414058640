export const HOTS_CURR_ID = 92;

export const AGE_GROUP = {
  1: '6-9',
  2: '',
  3: '8-11',
  4: '',
  5: '10-13',
  6: ''
};
