import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * @todo adding classNames and styles
 */
const CheckAllCard = props => {
  const { t } = useTranslation(['create-assign-question']);
  const { checked, onChange } = props;
  return (
    <>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="select-all"
          checked={checked}
          onChange={onChange}
        />
        <span className="custom-checkbox" />
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label className="form-check-label" htmlFor="select-all">
          {t(`create-assign-question:main.selectAll`, 'Select All')}
        </label>
      </div>
    </>
  );
};
export default CheckAllCard;
