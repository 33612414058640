import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Overlay, Tooltip } from 'react-bootstrap';
import {
  markAnswerRightRequest,
  fetchSingleClassReportRequest
} from 'stores/assignments/actions';
import './MarkRightPopover.scss';

function MarkRightPopover({ submissionId, homeworkID }) {
  const dispatch = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const target = useRef(null);

  const confirmHandler = () => {
    dispatch(markAnswerRightRequest({ submissionId }));
    setShowConfirmation(false);
    dispatch(
      fetchSingleClassReportRequest({
        homeworkID
      })
    );
  };

  return (
    <div className="mark-right">
      <button
        className="btn btn-lg mark-right-btn"
        type="button"
        ref={target}
        onClick={() => setShowConfirmation(true)}
      >
        Mark Right
      </button>
      <Overlay target={target.current} show={showConfirmation} placement="left">
        <Tooltip id="popover-items">
          <div className="popup-text">
            <span onClick={() => setShowConfirmation(false)}>X</span>
            <p>Are you sure to mark this answer correct?</p>
          </div>
          <div className="btn-container">
            <button
              className="btn btn-primary confirm-right-btn"
              type="button"
              onClick={confirmHandler}
            >
              Confirm
            </button>
          </div>
        </Tooltip>
      </Overlay>
    </div>
  );
}

export default MarkRightPopover;
