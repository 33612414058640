import {
  FETCH_SYLLABUS_REQUEST,
  FETCH_SYLLABUS_SUCCESS,
  FETCH_SYLLABUS_FAILED,
  NO_SYLLABUS,
  PUSH_TO_CART_RESET,
  PUSH_TO_CART_REQUEST,
  PUSH_TO_CART_SUCCESS,
  PUSH_TO_CART_FAILED,
  CREATE_WORKSHEET_RESET,
  CREATE_WORKSHEET_REQUEST,
  CREATE_WORKSHEET_SUCCESS,
  CREATE_WORKSHEET_FAILED,
  CREATE_WORKSHEET_ERROR,
  SET_CREATE_HOMEWORK_QUESTIONS,
  REMOVE_CREATE_HOMEWORK_QUESTION
} from './actionTypes';

const initialState = {
  syllabus: [],
  pushCart: {},
  createWorksheet: {},
  createWorksheetItems: [],
  isLoading: false,
  pushCartLoading: false,
  createWorksheetLoading: false,
  error: ''
};

export default function topicalWorksheet(state = initialState, action) {
  switch (action.type) {
    case FETCH_SYLLABUS_REQUEST:
      return { ...state, error: '', isLoading: true };
    case PUSH_TO_CART_REQUEST:
      return { ...state, error: '', pushCartLoading: true };
    case CREATE_WORKSHEET_REQUEST:
      return { ...state, error: '', createWorksheetLoading: true };
    case FETCH_SYLLABUS_FAILED:
    case PUSH_TO_CART_FAILED:
    case CREATE_WORKSHEET_FAILED:
    case CREATE_WORKSHEET_ERROR:
      return {
        ...state,
        isLoading: false,
        pushCartLoading: false,
        createWorksheetLoading: false,
        error: action.error
      };
    case NO_SYLLABUS:
      return { ...state, isLoading: false, syllabus: [] };
    case FETCH_SYLLABUS_SUCCESS:
      return {
        ...initialState,
        isLoading: false,
        syllabus: action.syllabus.Topics || []
      };
    case PUSH_TO_CART_RESET:
      return {
        ...initialState,
        pushCart: {}
      };
    case PUSH_TO_CART_SUCCESS:
      return {
        ...initialState,
        pushCartLoading: false,
        pushCart: action.data
      };
    case CREATE_WORKSHEET_RESET:
      return {
        ...initialState,
        createWorksheet: {}
      };
    case CREATE_WORKSHEET_SUCCESS:
      return {
        ...initialState,
        createWorksheetLoading: false,
        createWorksheet: action.data,
        createWorksheetItems: action.data.Items
      };
    case SET_CREATE_HOMEWORK_QUESTIONS:
      return {
        ...state,
        createWorksheetItems: action.questions
      };
    case REMOVE_CREATE_HOMEWORK_QUESTION:
      const updatedQuestions = state.createWorksheetItems.filter(
        question => question.ID !== action.Id
      );
      return { ...state, createWorksheetItems: updatedQuestions };
    default:
      return state;
  }
}
