import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { resetCartQuestions } from 'stores/questions/actions';
import AlertModal from './AlertModal';

const CartResetModal = props => {
  const { t } = useTranslation(['create-assign-question']);
  const dispatch = useDispatch();
  const { isShow, onConfirm, onClose } = props;
  const resetCart = () => {
    onConfirm();
    dispatch(resetCartQuestions());
  };

  const buttons = (
    <>
      <button type="button" className="btn btn-primary" onClick={resetCart}>
        {t(`create-assign-question:resetModal.yes`, 'Yes')}
      </button>
      <button type="button" className="btn btn-outline-light" onClick={onClose}>
        {t(`create-assign-question:resetModal.no`, 'No')}
      </button>
    </>
  );
  return (
    <AlertModal
      title={t(`create-assign-question:resetModal.resetCart`, 'Reset Cart')}
      isShow={isShow}
      handleClose={onClose}
      buttons={buttons}
    >
      <p>
        {t(
          `create-assign-question:resetModal.selectReset`,
          'Do you want to reset the cart?'
        )}
      </p>
    </AlertModal>
  );
};

export default CartResetModal;
