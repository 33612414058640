import styled from 'styled-components';

export const PasswordContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  @media only screen and (max-width: 480px) {
    width: 89%;
  }
  .show-password {
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
    @media only screen and (max-width: 480px) {
      margin: 0.5rem;
    }
    @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
      margin-left: 1.1rem;
    }
    span {
      margin-top: 0.2rem;
      margin-left: 1.5rem;
      font-size: 15px;
      color: #4852b5;
      opacity: 1;
      font-family: 'Linotte Regular', -apple-system, BlinkMacSystemFont,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
      @media only screen and (min-width: 1920px) {
        font-size: 20px;
      }
    }
  }
  input[type='checkbox'] {
    width: 12px;
    height: 12px;
    position: absolute;
    z-index: 1;
    margin-top: 0.3rem;
  }
  .show-password input {
    cursor: pointer;
    /* Increase size of checkbox */
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    transform: scale(2);
  }
  #password {
    background: #fff;
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 2rem;
    font-size: 1rem;
    color: #4852b5;
    width: calc(100% - 3rem);
    margin-bottom: 1rem;
    @media only screen and (min-width: 1920px) {
      padding: 0.8rem 1.5rem;
      font-size: 1.2rem;
    }
    @media only screen and (max-width: 1366px) {
      padding: 0.5rem 1.5rem;
    }
    @media only screen and (max-width: 480px) {
      padding: 0.5rem 1.5rem;
      font-size: 18px;
      margin-bottom: 1rem;
    }
  }
  #password::placeholder {
    color: #8b96c5;
    font-family: 'Linotte Regular', -apple-system, BlinkMacSystemFont,
      'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
  }
`;

export const LoginFormContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.22);
  width: 70%;
  border-radius: 1rem;
  padding: 2rem 2rem;
  @media only screen and (max-width: 480px) {
    background-color: rgba(255, 255, 255, 0);
    width: 100%;
    padding: 1.5rem 2rem;
  }
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    padding: 1.5rem 0rem;
  }
  .login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #login-id {
    background: #fff;
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 2rem;
    font-size: 1rem;
    color: #4852b5;
    width: 100%;
    margin-bottom: 1rem;
    @media only screen and (min-width: 1920px) {
      font-size: 1.2rem;
      padding: 0.8rem 1.5rem;
    }
    @media only screen and (max-width: 480px) {
      width: 75%;
      padding: 0.5rem 1.5rem;
      font-size: 18px;
      margin-bottom: 1rem;
    }
    @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
      width: 100%;
    }
  }
  #login-id::placeholder {
    color: #8b96c5;
    font-family: 'Linotte Regular', -apple-system, BlinkMacSystemFont,
      'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
  }
  .help-container {
    text-align: left;
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 0.3rem;
    font-size: 15px;
    a {
      color: #4852b5;
      text-decoration: underline;
      font-family: 'Linotte Regular', -apple-system, BlinkMacSystemFont,
        'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    }
    @media only screen and (min-width: 1920px) {
      font-size: 1.2rem;
    }
    @media only screen and (max-width: 480px) {
      padding-left: 4rem;
    }
    @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
      padding-left: 2rem;
    }
  }
`;
export const StyledErrorMessageContainer = styled.div`
  color: #ff2e35;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
  text-align: left;
  a {
    color: #ff2e35;
    text-decoration: underline;
  }
`;
