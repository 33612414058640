import {
  SET_STEP,
  START_STEP,
  PREV_STEP,
  NEXT_STEP,
  FINISH_STEP
} from './actionTypes';

const initialState = {
  activeStep: 1,
  startStep: 1,
  lastStep: 1
};

export default function step(state = initialState, action) {
  const prevStep = state.activeStep - 1;
  const nextStep = state.activeStep + 1;
  switch (action.type) {
    case SET_STEP:
      return { ...state, activeStep: action.step };
    case START_STEP:
      return { ...state, activeStep: state.startStep };
    case PREV_STEP:
      return {
        ...state,
        activeStep: prevStep !== 0 ? prevStep : state.startStep
      };
    case NEXT_STEP:
      return { ...state, activeStep: nextStep };
    case FINISH_STEP:
      return { ...state, activeStep: state.lastStep };
    default:
      return state;
  }
}
