import {
  SET_USER_PREFERENCES_REQUEST,
  SET_USER_PREFERENCES_SUCCESS,
  SET_USER_PREFERENCES_FAILED
} from './actionTypes';

export const setUserPreferencesRequest = params => {
  return {
    type: SET_USER_PREFERENCES_REQUEST,
    params
  };
};

export const setUserPreferencesSuccess = data => {
  return {
    type: SET_USER_PREFERENCES_SUCCESS,
    data
  };
};

export const setUserPreferencesFailed = error => {
  return {
    type: SET_USER_PREFERENCES_FAILED,
    error
  };
};
