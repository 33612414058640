import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NoReport from 'components/Report/NoReport';
import Spinner from 'components/Shared/Spinner';
import { ReactComponent as SkillSelectIcon } from 'assets/img/report/icon-skill-select.svg';
import {
  fetchSyllabusRequest,
  fetchHighScoreSkillReportRequest,
  cleanHighScoreSkillReport
} from 'stores/report/actions';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { isEmpty, isNil } from 'ramda';
import SkillTableReport from 'components/Report/SkillTableReport';
import cloneDeep from 'lodash.clonedeep';
import { LoadingContainer } from './ReportPage.styles';
import {
  Container,
  SkillList,
  SkillItem
} from './ProficiencyReportBySkills.styles';

const HighScoreReportBySkills = ({ visible }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['proficiency-report']);
  const [sortDetail, setSortDetail] = useState(null);
  const [selectedSkill, setSelectedSkill] = useState(null);

  const {
    selectedClass,
    reportLevel,
    selectedSyllabus,
    syllabus,
    highScoreSkill
  } = useSelector(state => state.reports);
  const isLevelLess = isEmpty(selectedSyllabus?.Levels);
  useDeepCompareEffect(() => {
    if (selectedClass && selectedSyllabus && !isNil(reportLevel)) {
      dispatch(
        fetchSyllabusRequest({
          CurriculumID: selectedSyllabus.CurriculumID,
          LevelID: isLevelLess ? -1 : reportLevel.ID
        })
      );
    }
  }, [dispatch, reportLevel, selectedClass, selectedSyllabus]);
  useEffect(() => {
    dispatch(cleanHighScoreSkillReport());
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (syllabus.data?.length) {
      handleSkillClick(syllabus.data[0], true);
    }
    // eslint-disable-next-line
  }, [syllabus.data]);
  const sortedData = useCallback(() => {
    if (sortDetail) {
      const { type, sortType } = sortDetail;
      const hasData = highScoreSkill.data.some(
        datum => datum.MasteryLevels[type] > 0
      );
      if (hasData) {
        const result = cloneDeep(highScoreSkill.data).sort((a, b) => {
          const c = a.MasteryLevels[type] - b.MasteryLevels[type] > 0 ? 1 : -1;
          return sortType === 'asc' ? c : c * -1;
        });
        return result;
      }
      return highScoreSkill.data;
    }
    return highScoreSkill.data;
  }, [highScoreSkill.data, sortDetail]);

  const handleSkillClick = (skill, isInitial) => {
    if (selectedSkill === skill.ID && !isInitial) {
      return;
    }
    setSelectedSkill(skill.ID);
    setSortDetail(null);
    const rawBody = {
      classID: selectedClass.ID,
      reportLevel: reportLevel.ID,
      curriculumID: selectedSyllabus.CurriculumID,
      topicID: skill.ID
    };
    dispatch(fetchHighScoreSkillReportRequest(rawBody));
  };
  const handleSortClick = ({ type, sortType }) => {
    setSortDetail(prev => {
      if (prev) {
        return prev.type === type && prev.sortType === sortType
          ? null
          : { type, sortType };
      }
      return { type, sortType };
    });
  };
  const renderTable = () => {
    if (highScoreSkill.isLoading) {
      return (
        <LoadingContainer width="73%">
          <Spinner styles={{ marginTop: '0' }} />
        </LoadingContainer>
      );
    }
    if (!highScoreSkill.data || highScoreSkill.data.length === 0) {
      return (
        <NoReport
          detail
          message={t(
            `proficiency-report:noReports.skillDesc`,
            'Select another skill to show reports.'
          )}
        />
      );
    }
    return (
      <SkillTableReport
        type="highscore"
        data={sortedData()}
        isLoading={highScoreSkill.isLoading}
        sortClick={handleSortClick}
        sortDetail={sortDetail}
      />
    );
  };
  const renderResult = () => {
    if (syllabus.isLoading) {
      return (
        <LoadingContainer>
          <Spinner styles={{ marginTop: '0' }} />
        </LoadingContainer>
      );
    }
    if (isNil(syllabus.data) || isEmpty(syllabus.data)) {
      return <NoReport />;
    }
    return (
      <Container>
        <SkillList>
          {syllabus.data.map(skill => (
            <SkillItem
              key={skill.ID}
              active={selectedSkill === skill.ID}
              onClick={() => handleSkillClick(skill)}
            >
              {skill.Name}
              {selectedSkill === skill.ID && <SkillSelectIcon />}
            </SkillItem>
          ))}
        </SkillList>
        {renderTable()}
      </Container>
    );
  };
  return visible ? renderResult() : null;
};

export default HighScoreReportBySkills;
