import apiClient from 'services/api';

const handleResponseSuccess = result => {
  if (result.data?.IsSuccessful === false) {
    return {
      error: result.data?.Message?.Message ?? 'Fetch data failed' // eslint-disable-line
    };
  }
  return result.data?.Result ?? [];
};

const savePrivateGroup = params => {
  return apiClient
    .post(`Group/Save`, params)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Save Private Group failed' // eslint-disable-line
      };
    });
};

/**
 * Fetch all worksheet
 */
const getPrivateGroups = keyword => {
  const options = {
    params: {
      SrchName: keyword
    }
  };
  return apiClient
    .get(`Group/Search`, options)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch Private Groups failed' // eslint-disable-line
      };
    });
};

export default {
  savePrivateGroup,
  getPrivateGroups
};
