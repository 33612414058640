import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import emptyAssignmentImage from 'assets/img/empty-assignment.svg';
import { EmptyAssignmentContainer } from './EmptyAssignment.styles';

export default function EmptyAssignment() {
  const { t } = useTranslation(['track-assignment-assigned-me']);
  return (
    <EmptyAssignmentContainer>
      <img src={emptyAssignmentImage} alt="Empty Assignment" />
      <h1 className="text-center">
        <b>
          {t(
            `track-assignment-assigned-me:emptyState.emptyMsg`,
            `You haven't assigned any homework yet.`
          )}
        </b>
      </h1>
      <h2 className="text-center">
        {t(
          `track-assignment-assigned-me:emptyState.emptyDesc1`,
          'You can click the '
        )}
        <b>
          {t(
            `track-assignment-assigned-me:emptyState.emptyDesc2`,
            'Create & Assign '
          )}
        </b>
        {t(
          `track-assignment-assigned-me:emptyState.emptyDesc3`,
          'at the top bar, and track assignment here.'
        )}
      </h2>
      <Link
        type="button"
        className="btn btn-lg btn-primary page-empty-cart-btn"
        to="/browse-content"
      >
        {t(
          `track-assignment-assigned-me:emptyState.browseContent`,
          'Browse Content'
        )}
      </Link>
    </EmptyAssignmentContainer>
  );
}
