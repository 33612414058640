import React from 'react';
import TrialIcon from 'assets/img/icon-trial-account.png';
import ExpiredIcon from 'assets/img/icon-trial-account-expired.png';
import { TrialModalBodyContainer } from './TrialAccountModalBody.styles';

const TrialAccountModalBody = ({ hide, type }) => {
  return (
    <TrialModalBodyContainer>
      <div className="shape-top" />
      <div className="shape-bottom" />
      <img
        src={
          type === 'trial-account' || type === 'trial-question'
            ? TrialIcon
            : ExpiredIcon
        }
        alt="icon"
      />
      <h3>
        {type === 'trial-account' ||
        type === 'trial-question' ||
        type === 'trial-video' ? (
          <strong>You are using a trial account</strong>
        ) : (
          <strong>Your KooBits free trial has expired</strong>
        )}
      </h3>
      <p>
        {type === 'trial-account' && (
          <>
            In the Koobits <strong>School Trial</strong> account, some content
            and features are locked.
            <br />
            They will be made available to you once your account is upgraded to
            a full account.
          </>
        )}
        {type === 'trial-question' && (
          <>
            In the Koobits <strong>School Trial</strong> account, you can add{' '}
            <strong>up to 5 questions</strong> to the question cart. The
            limitation will be lifted once your account is upgraded to a full
            account.
          </>
        )}
        {type === 'trial-video' && (
          <>
            In the Koobits <strong>School Trial</strong> account, you can play{' '}
            <strong>up to 5 videos</strong>. The limitation will be lifted once
            your account is upgraded to a full account.
          </>
        )}
        {type === 'trial-expired' && (
          <>
            Your KooBits School Trial account has expired.
            <br />
            Please contact our team to upgrade to a full account.
            <br />
            Email: <span>support@koobits.com</span>
          </>
        )}
      </p>
      <button
        type="button"
        onClick={() => {
          hide();
        }}
      >
        Got it
      </button>
    </TrialModalBodyContainer>
  );
};

export default TrialAccountModalBody;
