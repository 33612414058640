import styled from 'styled-components';

export const MediaBody = styled.div`
  p {
    font-family: 'Muli', sans-serif;
    font-size: 0.875rem;
    line-height: 1.4285;
    color: rgba(52, 52, 52, 0.8);
  }
`;

export const McqOptionsList = styled.ul`
  list-style-type: circle;
`;

export const TableModal = styled.div`
  table {
    width: 140px;
    margin-left: initial;
  }
  table tbody tr td {
    text-align: center;
  }
  table tbody tr td hr {
    margin-top: 0;
    margin-bottom: 3px;
    border-color: darkgray;
  }
  table tbody tr td input {
    max-width: 25px !important;
    height: 35px;
    text-align: center;
  }
`;
