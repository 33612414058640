import styled from 'styled-components';

export const TopicWrap = styled.div`
  .skill-wrap {
    &.open {
      display: block;
    }
    &.hide {
      display: none;
    }
  }
`;

export const ArrowImage = styled.img`
  height: 15px;
  margin-right: 20px;
  transition-duration: 0.3s;
  &.open {
    transform: rotate(90deg);
    transition-duration: 0.3s;
  }
`;

export const TopicItemContainer = styled.div`
  background: #fdfbf6;
  border-bottom: solid 1px #dfdfdf;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  cursor: pointer;
  .title-wrap {
    display: flex;
    align-items: center;
    .arrow-button {
      border: none;
      background-color: transparent;
      width: 25px;
    }
    .title-topic {
      font-size: 1.2rem;
      font-weight: bolder;
    }
  }

  .question-wrap {
    display: flex;
    align-items: center;
    .total-qn {
      font-size: 1.5rem;
      color: #ff5b00;
      font-weight: bold;
      font-family: 'Muli', sans-serif;
      &.empty {
        color: #cececc;
      }
    }
  }
`;

export const SkillItem = styled.div`
  background: #fcfcfc;
  border-bottom: solid 1px #dfdfdf;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  .title-wrap {
    margin-left: 30px;
    display: flex;
    align-items: center;
    .subtitle-skill {
      color: #afafaf;
      font-size: 1rem;
      font-weight: bolder;
      margin-right: 20px;
    }
    .title-skill {
      color: #4a4a4a;
      font-size: 1rem;
      font-weight: bolder;
    }
  }
`;
