import {
  CubeSVG,
  QuestionSVG,
  VideoSVG,
  WorksheetSVG,
  FactualFluencyPNG
} from 'assets/img/dashboardv2';

const dashboardCards = [
  {
    id: 1,
    title: 'Browse',
    subTitle: 'Questions',
    backgroundColor: '#FFFBEA',
    icon: QuestionSVG,
    url: `/browse-content`,
    urlType: 'REDIRECT'
  },
  {
    id: 2,
    title: 'Watch',
    subTitle: 'Video Tutorials',
    backgroundColor: '#FFF9EF',
    icon: VideoSVG,
    url: `/browse-content/problemsums-trial`,
    urlType: 'REDIRECT'
  },
  {
    id: 3,
    title: 'Assign',
    subTitle: 'Topical worksheet',
    backgroundColor: '#FFF9EF',
    icon: WorksheetSVG,
    url: `/create-and-assign/topical-worksheet`,
    urlType: 'REDIRECT'
  },
  {
    id: 4,
    title: 'Play',
    subTitle: 'IVM',
    backgroundColor: '#FEFFF5',
    icon: CubeSVG,
    url: `https://ivm.koobits.com/dashboardv5/index.html`,
    urlType: 'NEW_TAB'
  },
  {
    id: 5,
    title: 'Practice',
    subTitle: 'Factual Fluency',
    backgroundColor: '#FFFDF7',
    icon: FactualFluencyPNG,
    url: `/create-and-assign/factual-fluency`,
    urlType: 'REDIRECT'
  }
];

export default dashboardCards;
