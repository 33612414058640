import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Avatar from 'assets/img/avatar.png';
import LoginIcon from 'assets/img/icon-login-report-active.svg';
import NotLoginIcon from 'assets/img/icon-login-report-not-login.svg';
import NotLoginYetIcon from 'assets/img/icon-login-report-not-active.svg';
import EmptyResult from 'assets/img/empty-search-results.svg';
import { LoginReportTableStyle } from './LoginReportTable.styles';

const LoginReportTable = ({ data, weekId, weekItems }) => {
  // login report status icon
  const { t } = useTranslation(['login-report']);
  const Login = <img src={LoginIcon} alt="login-icon" />;
  const NotLogin = <img src={NotLoginIcon} alt="not-login-icon" />;
  const NotLoginYet = <img src={NotLoginYetIcon} alt="not-login-yet-icon" />;
  const icon = day => {
    if (day === 1) {
      return Login;
    }
    if (day === 0) {
      return NotLogin;
    }
    if (day === 2) {
      return NotLoginYet;
    }
  };
  // Set days of week
  const endDate = moment(
    weekItems[weekId].Display.split(' - ')[1],
    'DD MMM YYYY'
  );
  const days = [endDate];
  for (let i = 1; i <= 6; i++) {
    const date = moment(
      weekItems[weekId].Display.split(' - ')[1],
      'DD MMM YYYY'
    ).subtract(i, 'days');
    days.unshift(date);
  }
  return (
    <LoginReportTableStyle>
      {data && data.length === 0 ? (
        <div className="empty-result">
          <img src={EmptyResult} alt="empty" />
        </div>
      ) : (
        <table className="table table-bordered table-responsive-md">
          <thead>
            <tr>
              <th scope="col" className="student">
                {t(`login-report:report.studName`, 'Student Name')}
              </th>
              <th scope="col" className="total">
                {t(`login-report:report.total`, 'Total')}
              </th>
              {days &&
                days.map((day, index) => (
                  <th scope="col" className="date" key={index}>
                    {t(
                      `login-report:report.${day.format('ddd')}`,
                      day.format('ddd')
                    )}{' '}
                    {day.format('D')}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((item, index) => (
                <tr key={index}>
                  <td className="student-name">
                    <img
                      onError={e => {
                        e.target.src = Avatar;
                      }}
                      src={item.ProfileImage}
                      alt="avatar"
                    />
                    <span>{item.Name}</span>
                  </td>
                  <td className="total-number">{item.Total}</td>
                  {item.LoginStatus.map((status, index) => (
                    <td key={index}>{icon(status)}</td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </LoginReportTableStyle>
  );
};

export default LoginReportTable;
