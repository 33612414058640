import React, { useCallback, useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import useSound from 'use-sound';
import FactualFluencyPreview from './FactualFluencyPreview';
import FactualFluencyReady from './FactualFluencyReady';
import bgAudio from 'assets/audio/bg-music.mp3';
import FactualFluencyResult from './FactualFluencyResult';

const FactualFluencyGame = () => {
  const [mute, setMute] = useState(true);
  const { pathname } = useLocation();

  const onMute = useCallback(() => {
    setMute(!mute);
  }, [mute]);

  const [play, { stop }] = useSound(bgAudio, { interrupt: true });

  useEffect(() => {
    if (mute) {
      stop();
    } else {
      play();
    }
    return () => {
      stop();
    };
    // eslint-disable-next-line
  }, [mute]);

  useEffect(() => {
    if (
      pathname === '/factual-fluency/ready' ||
      pathname === '/factual-fluency/result'
    ) {
      setMute(true);
    } else {
      setMute(false);
    }
  }, [pathname]);

  return (
    <Switch>
      <Route
        exact
        path="/factual-fluency/ready"
        component={props => <FactualFluencyReady {...props} onMute={onMute} />}
      />

      <Route
        exact
        path="/factual-fluency/preview"
        component={props => (
          <FactualFluencyPreview {...props} onMute={onMute} mute={mute} />
        )}
      />
      <Route
        exact
        path="/factual-fluency/result"
        component={props => <FactualFluencyResult {...props} />}
      />
    </Switch>
  );
};

export default React.memo(FactualFluencyGame);
