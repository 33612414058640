import apiClient from 'services/api';

const handleResponseSuccess = result => {
  if (result.data?.IsSuccessful === false) {
    return {
      error: result.data?.Message?.Message ?? 'API request failed' // eslint-disable-line
    };
  }
  return result.data?.Result ?? [];
};

// Fetch list of classes
const fetchClasses = params => {
  const { year } = params;
  // update api endpoint
  return apiClient
    .get(`ManageClass/GetAllClassesByYear/${year}`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch students failed' // eslint-disable-line
      };
    });
};

// TODO: Fetch list of students
const fetchStudents = params => {
  const { classID, pageNum } = params;
  const pageSize = 20;
  return apiClient
    .get(
      `lookup/SearchStudentWithDetails?ClassId=${classID}&pageSize=${pageSize}&pageIndex=${pageNum}`
    )
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch students failed' // eslint-disable-line
      };
    });
};

// Search students by ID or name
const searchStudents = params => {
  const { pageNum, SchoolId, SrchName, studentId } = params;
  const pageSize = 20;
  let apiUrl;
  if (SrchName !== null) {
    apiUrl = `lookup/SearchStudentWithDetails?pageSize=${pageSize}&pageIndex=${pageNum}&SchoolId=${SchoolId}&SrchName=${SrchName}`;
  } else if (studentId !== null) {
    apiUrl = `lookup/SearchStudentWithDetails?pageSize=${pageSize}&pageIndex=${pageNum}&SchoolId=${SchoolId}&Studentid=${studentId}`;
  }
  return apiClient
    .get(apiUrl)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Search students failed' // eslint-disable-line
      };
    });
};

// TODO: Fetch student details
const fetchStudentDetails = params => {
  const { studentID } = params;
  return apiClient
    .get(`lookup/GetStudentDetailsByStudentId/${studentID}`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch student details failed' // eslint-disable-line
      };
    });
};

// TODO: Post request for Edit Class
const editClass = params => {
  const body = {
    Name: params.className
  };
  let isUpdateIcon;
  if (params.iconID !== 0) {
    body.IconId = params.iconID;
    isUpdateIcon = true;
  } else {
    isUpdateIcon = false;
  }
  const options = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return apiClient
    .put(
      `ManageClass/EditClass/${params.classID}?UpdateIcon=${isUpdateIcon}`,
      body,
      options
    )
    .then(handleResponseSuccess)
    .catch(err => {
      // TODO: update error response format
      return {
        error: err?.response?.data?.error_description ?? 'Edit class failed' // eslint-disable-line
      };
    });
};

// TODO: Post request for Change Class
const changeClass = params => {
  const body = {
    StudentIds: params.StudentIDs,
    ClassId: params.classID
  };
  const options = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  return apiClient
    .post(`Student/MoveClass`, body, options)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.error_description ?? 'Change class failed' // eslint-disable-line
      };
    });
};
// TODO: Post request for Reset Password
const resetPassword = params => {
  return apiClient
    .put(`Student/ResetPassword/${params.studentID}`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.error_description ?? 'Reset password failed' // eslint-disable-line
      };
    });
};

const fetchIcons = params => {
  return apiClient
    .get(`ManageClass/GetClassIcons/${params.level}`)
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.error_description ?? 'Fetch icons failed' // eslint-disable-line
      };
    });
};

export default {
  fetchClasses,
  fetchStudents,
  searchStudents,
  fetchStudentDetails,
  editClass,
  changeClass,
  resetPassword,
  fetchIcons
};
