import apiClient from 'services/api';

const handleResponseSuccess = result => {
  if (result.data?.IsSuccessful === false) {
    return {
      error: result.data?.Message?.Message ?? 'API request failed' // eslint-disable-line
    };
  }
  return result.data?.Result ?? [];
};

const fetchSyllabus = (CurriculumID, LevelID) => {
  return apiClient
    .get(
      `Curriculum/GetSyllabusByCurriculumIDandLevelID/${CurriculumID}/${LevelID}`
    )
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Fetch syllabus failed' // eslint-disable-line
      };
    });
};

const pushToCart = obj => {
  return apiClient
    .post(
      `Cart/AddRandomQuestionsToCart/${obj.curriculumId}/${obj.topicId}/${obj.levelId}/${obj.easy}/${obj.medium}/${obj.difficult}`
    )
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Push to cart failed' // eslint-disable-line
      };
    });
};

const createWorkSheet = obj => {
  return apiClient
    .get(
      `Cart/GetRandomQuestions/${obj.curriculumId}/${obj.topicId}/${obj.levelId}/${obj.easy}/${obj.medium}/${obj.difficult}`
    )
    .then(handleResponseSuccess)
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Create worksheet failed' // eslint-disable-line
      };
    });
};

export default {
  fetchSyllabus,
  pushToCart,
  createWorkSheet
};
