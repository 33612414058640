import { isEmpty } from 'ramda';
import { compareValues } from 'helpers/singleClassReport';
import {
  FETCH_USER_CURRICULUMS_REQUEST,
  FETCH_USER_CURRICULUMS_SUCCESS,
  FETCH_USER_CURRICULUMS_FAILED,
  SELECTED_CURRICULUM
} from './actionTypes';

const initialState = {
  curriculums: [],
  selectedCurriculum: {},
  isLoading: false,
  error: ''
};

// Helper to set default curriculum
const sortCurriculums = curriculumsArray => {
  const sortedCurriculums = curriculumsArray.sort(
    compareValues('CurriculumName', 'asc')
  );
  const defaultCurriculumIndex = sortedCurriculums.findIndex(
    curr => curr.IsDefault === true
  );
  if (defaultCurriculumIndex !== -1) {
    const tempCurriculum = sortedCurriculums[defaultCurriculumIndex];
    // Move default curriculum to start of array
    sortedCurriculums[defaultCurriculumIndex] = sortedCurriculums[0];
    sortedCurriculums[0] = tempCurriculum;
  }
  return sortedCurriculums;
};

const setDefaultCurriculum = curriculumsArray => {
  const sortedCurriculums = curriculumsArray.sort(
    compareValues('CurriculumName', 'asc')
  );
  const defaultCurriculumIndex = sortedCurriculums.findIndex(
    curr => curr.IsDefault === true
  );
  if (defaultCurriculumIndex !== -1) {
    return sortedCurriculums[defaultCurriculumIndex];
  }
  return sortedCurriculums[0];
};

export default function curriculums(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_CURRICULUMS_REQUEST:
      return { ...state, isLoading: true };
    case FETCH_USER_CURRICULUMS_SUCCESS:
      return {
        ...state,
        curriculums: sortCurriculums(action.curriculums),
        selectedCurriculum: isEmpty(state.selectedCurriculum)
          ? setDefaultCurriculum(action.curriculums)
          : state.selectedCurriculum
      };
    case FETCH_USER_CURRICULUMS_FAILED:
      return { ...state, isLoading: false, error: action.error };
    case SELECTED_CURRICULUM:
      return { ...state, selectedCurriculum: action.selectedCurriculum };
    default:
      return state;
  }
}
