import React, { useState, useEffect } from 'react';
import { isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import questionParser from 'helpers/questionParser';
import convertTagsToHTML from 'helpers/text/convertTagsToHTML';
import Collapse from 'components/Shared/Collapse';
import DifficultyChillis from 'components/Level/DifficultyChillis';
import {
  McqOptionsList,
  TableModal
} from 'components/Question/QuestionContent.styles';

export default function WorksheetQuestionModal({
  selectedQuestion,
  onCloseModal,
  showSolution
}) {
  const { t } = useTranslation(['browse-content']);
  const question = selectedQuestion.CartQuestion;
  //  State
  const [questionTitle, setQuestionTitle] = useState(null);
  const [questionSolution, setQuestionSolution] = useState(null);
  const [mcqOptions, setMCQOptions] = useState([]);

  //  Effects
  useEffect(() => {
    if (!isNil(question.Payload)) {
      const { title, solution, mcqOptions } = questionParser(question);
      const renderOptions = mcqOptions.map(option =>
        HTMLReactParser(convertTagsToHTML(option, false), {
          replace: domNode => {
            if (domNode.name === 'img') {
              domNode.attribs.style = 'width: 68%';
              return domNode;
            }
            return domNode;
          }
        })
      );
      setMCQOptions(renderOptions);
      setQuestionTitle(
        HTMLReactParser(convertTagsToHTML(title), {
          replace: domNode => {
            if (domNode.name === 'img' && !isNil(domNode.attribs?.url)) {
              return (
                <img
                  src={domNode.attribs?.url}
                  alt={domNode.attribs?.uniqueid}
                />
              );
            }
            if (domNode.name === 'helptext') {
              return (
                <small id="questionHelp" className="form-text text-muted">
                  {domNode.children[0]?.data ?? ''}
                </small>
              );
            }
            if (domNode.name === 'input') {
              return <input type="text" />;
            }
            return domNode;
          }
        })
      );

      setQuestionSolution(HTMLReactParser(convertTagsToHTML(solution)));
    }
  }, [question]);

  return (
    <div className="question-slick">
      <div className="question-item">
        <div className="question-item-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onCloseModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h3>{selectedQuestion.TopicName}</h3>
          <p className="text-muted">{selectedQuestion.SkillName}</p>
        </div>

        <div className="question-item-body">
          <div className="media">
            <div className="media-body">
              <div className="qs-inner">
                <div className="qs-attr">
                  <div className="row">
                    <div className="col-md">
                      <div className="d-flex">
                        <span>
                          {t(
                            `browse-content:questionCard.questionId`,
                            'Question ID'
                          )}
                          : {selectedQuestion.QuestionId}
                        </span>
                        <span>
                          {t(
                            `browse-content:questionCard.difficulty`,
                            'Difficulty'
                          )}
                          <DifficultyChillis
                            value={question.Difficulty?.Max ?? 0}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="qs-text">
                  <TableModal>{questionTitle}</TableModal>
                  {mcqOptions.length > 0 && (
                    <McqOptionsList>
                      {mcqOptions.map(option => (
                        <li key={option}>{option}</li>
                      ))}
                    </McqOptionsList>
                  )}
                </div>
              </div>
              <div className="qs-solution">
                <Collapse
                  collapsed={showSolution}
                  hideText={t(
                    `browse-content:questionModal.hideSol`,
                    'Hide Solution'
                  )}
                  showText={t(
                    `browse-content:questionModal.showSol`,
                    'Show Solution'
                  )}
                >
                  {questionSolution}
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
