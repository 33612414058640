import apiClient from 'services/api';

const getStudentToken = () => {
  return apiClient
    .get(`User/SwitchToken`)
    .then(result => {
      console.log(result);
      if (result.data?.IsSuccessful === false) {
        return {
          error: result.data?.Message?.Message ?? 'Get student token failed'
        };
      }
      return result.data?.Result ?? {};
    })
    .catch(err => {
      return {
        error: err?.response?.data?.message ?? 'Get student token failed' // eslint-disable-line
      };
    });
};

export default getStudentToken;
