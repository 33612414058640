import React, { useState } from 'react';
import { isNil, findIndex, propEq } from 'ramda';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import IconPlay from 'assets/img/icon-play.svg';
import { addQuestion, deleteQuestion } from 'stores/questions/actions';
import SimilarQuestions from 'components/Content/SimilarQuestions';
import DifficultyChillis from 'components/Level/DifficultyChillis';
import { setSelectedQuestions } from 'stores/worksheet/actions';
import QuestionContent from 'components/Question/QuestionContent';
import PopupModal from 'components/Shared/PopupModal';
import TrialAccountModalBody from 'components/TrialAccount/TrialAccountModalBody';
import { featureToggle } from 'constants/index';
// import IconPlay2 from 'assets/img/Icon Video.svg';

export default function QuestionCardView({
  question,
  questions,
  onShowModal,
  onShowSolution
}) {
  const { t } = useTranslation(['browse-content']);
  const dispatch = useDispatch();
  // Redux state selector
  const { cartQuestions } = useSelector(state => state.questions);
  const { selectedQuestions } = useSelector(state => state.worksheet);
  const { variations } = useSelector(state => state.variations);
  const { isTrial } = useSelector(state => state.login);
  // Variable definitions
  const variation = variations.find(v => v.ID === question.VariationId);
  const isAdded = !isNil(cartQuestions.find(item => item.ID === question.Id));
  const btnClassNames = `btn ${
    isAdded ? 'btn-outline-primary' : 'btn-primary'
  } btn-md`;
  // Modal state
  const [showTrialAccount, setShowTrialAccount] = useState(false);
  const hideTrialAccount = () => {
    setShowTrialAccount(false);
  };

  // Function or actions
  const questionSelector = () => {
    // Create new temp variable and use JS array slice / filter instead of splice
    // so we don't mutate original `selectedQuestions` variable
    // @see {@link https://stackoverflow.com/a/40737615} and {@link https://daveceddia.com/why-not-modify-react-state-directly/}
    let updatedSelectedQuestions;
    if (isAdded) {
      dispatch(deleteQuestion(question.Id));
      const index = findIndex(propEq('ID', question.Id))(selectedQuestions);
      if (index !== -1) {
        updatedSelectedQuestions = [
          ...selectedQuestions.slice(0, index),
          ...selectedQuestions.slice(index + 1)
        ];
        dispatch(setSelectedQuestions(updatedSelectedQuestions));
      }
    } else {
      dispatch(addQuestion(question));
    }
  };

  const toggleQuestionSelector = () => {
    if (isTrial) {
      if (cartQuestions.length + 1 > 5) {
        setShowTrialAccount(true);
      } else {
        questionSelector();
      }
    } else {
      questionSelector();
    }
  };

  return (
    <>
      <PopupModal show={showTrialAccount} hide={hideTrialAccount}>
        <TrialAccountModalBody hide={hideTrialAccount} type="trial-question" />
      </PopupModal>
      <div className="col-xl-6 mb-4 question-card">
        <div
          className="box-white cursor h-100 d-flex flex-column"
          data-toggle="modal"
          onClickCapture={e => {
            /**
             * Prevent modal to show when others input/button clicked clicked
             * Using e.target.dataset.toggle === "modal", not really working
             */
            if (['DIV', 'P'].includes(e.target.tagName)) {
              onShowModal();
            }
          }}
        >
          <div className="box-white-header">
            <div className="row align-items-center">
              <div className="col-md">
                <span className="question-id">
                  {t(`browse-content:questionCard.questionId`, 'Question ID')} :{' '}
                  {question.Id}
                </span>
                <span className="question-difficulty">
                  <span className="difficulty-label">
                    {t(`browse-content:questionCard.difficulty`, 'Difficulty')}:
                  </span>
                  <DifficultyChillis value={question.Difficulty?.Max ?? 0} />
                </span>
              </div>
              <div className="col-md-auto right-side">
                {variation?.Name}
                {featureToggle.videoPlayButton && (
                  <a href="#noredirect" className="btn btn-primary btn-play">
                    <img src={IconPlay} alt="icon-play" />
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="box-white-body h-100">
            <QuestionContent
              question={question}
              onShowModal={onShowModal}
              onShowSolution={onShowSolution}
            />
          </div>
          <div className="box-white-footer">
            <div className="row align-items-center">
              <div className="col-sm">
                {/* <span>
                <a href="#noredirect" data-toggle="love" className="active">
                  <i className="icon-heart" /> 9.2K
                </a>
              </span>
              <span>
                <a href="#noredirect" data-toggle="flag">
                  <i className="icon-flag" /> Flag
                </a>
              </span> */}
                <SimilarQuestions
                  align="left"
                  questions={questions}
                  variation={variation}
                />
              </div>
              <div className="col-sm-auto">
                <button
                  onClick={toggleQuestionSelector}
                  type="button"
                  className={btnClassNames}
                >
                  {isAdded
                    ? t(`browse-content:questionCard.unselect`, 'Unselect')
                    : t(`browse-content:questionCard.select`, 'Select')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
