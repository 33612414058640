import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setUserPreferencesRequest } from 'stores/userPreferences/actions';
import { defaultLang } from 'helpers/localizationNames';
import { StyledLanguageModal, Item } from './LanguageModal.styles';

const LanguageModal = ({ setModal }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(['navigation']);
  const { language } = useSelector(state => state.login);
  const [selectedLang, setSelectedLang] = useState(null);

  const changeLanguage = code => {
    i18n.changeLanguage(code);
    dispatch(setUserPreferencesRequest({ Id: 3, Value: code }));
    setModal(false);
  };

  useEffect(() => {
    setSelectedLang(i18n.language);
  }, [i18n.language]);

  return (
    <StyledLanguageModal>
      {language &&
        language.map(lang => (
          <Item
            key={lang.Id}
            isSelected={
              selectedLang ===
              defaultLang(
                lang.TwoLetterIsolanguageName,
                lang.LocaleName,
                lang.NativeName
              ).code
            }
            onClick={() => {
              setSelectedLang(
                defaultLang(
                  lang.TwoLetterIsolanguageName,
                  lang.LocaleName,
                  lang.NativeName
                ).code
              );
            }}
          >
            <div className="tick" />
            <div>
              {
                defaultLang(
                  lang.TwoLetterIsolanguageName,
                  lang.LocaleName,
                  lang.NativeName
                ).name
              }
            </div>
          </Item>
        ))}
      <button
        type="button"
        className="btn btn-primary styled-button"
        onClick={() => changeLanguage(selectedLang)}
      >
        {t('navigation:menu.confirm', 'Confirm')}
      </button>
    </StyledLanguageModal>
  );
};

export default LanguageModal;
