import styled from 'styled-components';

export const MultiClassReportPage = styled.div`
  background: #fff;
  .report-navigation {
    padding: 1rem 1rem;
    cursor: pointer;
    width: 100px;
    &:hover {
      color: #ff5b00;
    }
  }
  .tab-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .scroller-tab {
      text-align: center;
      color: rgba(84, 84, 84, 0.43);
      background: #f5f5f5;
      border-bottom: solid 1px rgba(112, 112, 112, 0.26);
      border-top: solid 1px rgba(112, 112, 112, 0.26);
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      flex-grow: 1;
      border-right: none;
      height: 53px;
      padding: 0.5rem 0.5rem;
    }
  }
  .report-tabs {
    display: flex;
    flex-direction: row;
    border-top: solid 1px rgba(112, 112, 112, 0.26);
    overflow-x: auto;
    .class-tab {
      cursor: pointer;
      flex-shrink: 0;
      &:hover {
        color: #ff5b00;
        background: #fff7f3;
      }
    }
    span {
      padding: 1rem 2rem;
      min-width: 6rem;
      text-align: center;
      color: rgba(84, 84, 84, 0.43);
      background: #f5f5f5;
      border-right: solid 1px rgba(112, 112, 112, 0.26);
      border-bottom: solid 1px rgba(112, 112, 112, 0.26);
    }
    .active {
      color: #ff5b00;
      border-bottom: none;
      background: #fff;
      flex-shrink: 0;
    }
    .placeholder {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 0 0.5rem;
      flex-grow: 1;
      border-right: none;
    }
  }
  .report-homework-details {
    display: flex;
    flex-direction: row;
    padding: 1rem 1rem;
    border-bottom: solid 1px rgba(112, 112, 112, 0.22);
    .report-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      flex-basis: 40%;
      span {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        margin-right: 1rem;
        font-size: 11px;
        .action-icon {
          margin-bottom: 0.3rem;
        }
      }
    }
    .report-homework-details-info {
      flex-basis: 60%;
      .report-dates {
        color: rgba(30, 30, 30, 0.46);
      }
    }
    p {
      font-size: 22px;
      color: #1e1e1e;
    }
  }
  .report-submission-details {
    padding: 1rem 1rem;
    .submission-details-url {
      display: flex;
      flex-direction: row;
      align-items: center;
      .url-text {
        color: #ff5b00;
      }
      .copy-button {
        border: solid 1px #d8d8d8;
        background: #fff;
        padding: 0.2rem 1rem;
        color: #ff5b00;
        margin-left: 1rem;
        border-radius: 2px;

        &:hover {
          border: solid 1px #ff5b00;
          background: #fff7f3;
        }
      }
    }
  }
  .report-submission-chart {
    padding-bottom: 1rem;
    .chart-empty-text {
      position: relative;
      bottom: 18rem;
      left: 24rem;
      width: 18rem;
      text-align: center;
    }
  }
`;
