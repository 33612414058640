import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateGroupListsWrapper } from './CreateGroupLists.styles';

const CreateGroupLists = ({
  grpName,
  Students,
  selectedList,
  deleteStudent
}) => {
  const { t } = useTranslation(['manage-class-group']);
  return (
    <CreateGroupListsWrapper>
      {Students.length === 0 ? (
        <div className="no-student-container">
          <div className="no-studnet-selected">
            {t(
              `manage-class-group:createGroup.noSelected`,
              'No student selected'
            )}
          </div>
          <div>
            {t(
              `manage-class-group:createGroup.selectMsg`,
              'Select students from the list to add to this group'
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="group-list-title">
            {grpName ||
              t(
                `manage-class-group:createGroup.nameList`,
                'Name list of the group'
              )}
          </div>
          <div className="group-list-container">
            {Object.keys(selectedList).map(val => (
              <div key={val} className="list-class-title">
                {val && selectedList[val] && selectedList[val].length
                  ? selectedList[val][0].classname
                  : ''}
                {selectedList[val].map(elem => (
                  <div key={elem.userId} className="list-class-students">
                    {elem.userName}
                    <div
                      className="delete-student"
                      onClick={() => deleteStudent(elem.userId)}
                    >
                      x
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </CreateGroupListsWrapper>
  );
};

export default memo(CreateGroupLists);
