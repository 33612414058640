import React from 'react';
import LoginForm from '../LoginForm/LoginForm';
import {
  LoginSectionContainer,
  LoginHeader,
  SignupContainer
} from './LoginSection.styles';
// Import assets
import { ReactComponent as Icon } from './homeIcon.svg';
import { ReactComponent as KoobitsLogo } from './Koobits Logo.svg';
import { ReactComponent as Stars } from './stars.svg';
import { ReactComponent as Mascot } from './mascot-mobile.svg';
import Partners from './icon-login-partners.svg';
import SchoolSignup from './icon-login-school-signup.svg';
import App from './icon-login-parent-app.svg';

import shootingStar from './Shooting Star.png';

const LoginSection = ({ isMobile }) => {
  return (
    <LoginSectionContainer>
      <div className="gradient-bg" />
      <div className="login-header">
        <LoginHeader>
          <div className="links">
            <a href="https://www.koobits.com/franchise/centre">
              <span className="link-text">Partners</span>
              <span className="link-icon">
                <img src={Partners} alt="partners-icon" />
              </span>
            </a>
            <span>|</span>
            <a href="https://products.koobits.com/problemsums/school/">
              <span className="link-text">School Sign-up</span>
              <span className="link-icon">
                <img src={SchoolSignup} alt="school-signup-icon" />
              </span>
            </a>
            <span>|</span>
            <a href="https://www.koobits.com/parent/app">
              <span className="link-text">Download Parent App</span>
              <span className="link-icon">
                <img src={App} alt="parents-app-icon" />
              </span>
            </a>
          </div>
          <div className="home-page">
            <Icon className="home-icon" />
            <span className="home-text">Homepage</span>
          </div>
        </LoginHeader>
      </div>
      <div className="brandname-container">
        <KoobitsLogo className="brandname-logo" alt="KooBits" />
        <span className="brandname-divider">|</span>
        <div className="brandname-tagline">
          <span className="brandname-tagline-text">Love Learning</span>
          <img src={shootingStar} alt="" className="brandname-tagline-star" />
        </div>
      </div>
      <LoginForm />
      <SignupContainer>
        If you don't have account, you can{' '}
        <span>
          <a href="https://products.koobits.com/problemsums/">Sign up</a>
        </span>
      </SignupContainer>
      {isMobile ? <Mascot className="mascot" /> : <Stars className="stars" />}
      <footer>KooBits Learning Pte Ltd</footer>
    </LoginSectionContainer>
  );
};

export default LoginSection;
