import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { PaginationContainer } from './Pagination.styles';

export default function Pagination({ currentPage, setPage }) {
  const { t } = useTranslation(['create-assign-saved']);
  const { maxStudentPages } = useSelector(state => state.manageClasses);
  const [debouncedCallback] = useDebouncedCallback(value => {
    setPage(value);
  }, 2000);
  return (
    <PaginationContainer>
      <div className="next-prev-page">
        <Button
          variant="outline-page-primary"
          disabled={currentPage <= 1}
          onClick={() => setPage(currentPage - 1)}
        >
          &lt; {t(`create-assign-saved:pagination.prev`, 'Prev')}
        </Button>
        {currentPage === 1 && (
          <>
            <Button variant="outline-page-primary selected">
              {currentPage}
            </Button>
            {maxStudentPages >= 2 && (
              <Button
                variant="outline-page-primary"
                onClick={() => setPage(currentPage + 1)}
              >
                {currentPage + 1}
              </Button>
            )}
            {maxStudentPages >= 3 && (
              <Button
                variant="outline-page-primary"
                onClick={() => setPage(currentPage + 2)}
              >
                {currentPage + 2}
              </Button>
            )}
          </>
        )}
        {currentPage > 1 && currentPage < maxStudentPages && (
          <>
            <Button
              variant="outline-page-primary"
              onClick={() => setPage(currentPage - 1)}
            >
              {currentPage - 1}
            </Button>
            <Button variant="outline-page-primary selected">
              {currentPage}
            </Button>
            <Button
              variant="outline-page-primary"
              onClick={() => setPage(currentPage + 1)}
            >
              {currentPage + 1}
            </Button>
          </>
        )}
        {currentPage === 2 && currentPage === maxStudentPages && (
          <>
            <Button
              variant="outline-page-primary"
              onClick={() => setPage(currentPage - 1)}
            >
              {currentPage - 1}
            </Button>
            <Button variant="outline-page-primary selected">
              {currentPage}
            </Button>
          </>
        )}
        {currentPage > 2 && currentPage === maxStudentPages && (
          <>
            <Button
              variant="outline-page-primary"
              onClick={() => setPage(currentPage - 2)}
            >
              {currentPage - 2}
            </Button>
            <Button
              variant="outline-page-primary"
              onClick={() => setPage(currentPage - 1)}
            >
              {currentPage - 1}
            </Button>
            <Button variant="outline-page-primary selected">
              {currentPage}
            </Button>
          </>
        )}
        <Button
          variant="outline-page-primary"
          disabled={currentPage >= maxStudentPages}
          onClick={() => setPage(currentPage + 1)}
        >
          {t(`create-assign-saved:pagination.next`, 'Next')} &gt;
        </Button>
      </div>
      <span>
        {t(`create-assign-saved:pagination.page`, 'Page')}{' '}
        <input
          name="page"
          type="number"
          placeholder={currentPage}
          min={1}
          max={maxStudentPages}
          onChange={e => {
            e.preventDefault();
            if (
              e.target.value !== currentPage &&
              e.target.value >= 1 &&
              e.target.value <= maxStudentPages
            ) {
              debouncedCallback(parseInt(e.target.value));
            }
          }}
        />{' '}
        {t(`create-assign-saved:pagination.of`, 'of')} {maxStudentPages}
      </span>
    </PaginationContainer>
  );
}
