import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { StyledDropdown } from './DropdownMenu.styles';

const DropdownMenu = ({
  selectedValue,
  valueKey,
  values,
  setValue,
  width,
  valueTemplate,
  children
}) => {
  return (
    <StyledDropdown width={width}>
      <Dropdown.Toggle variant="light" id="dropdown-custom">
        {selectedValue}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {children}
        {values.length > 0 &&
          values.map(value => (
            <Dropdown.Item
              key={valueKey !== null ? value.ID : value}
              onClick={event => {
                event.preventDefault();
                setValue(value);
              }}
              className="dropdown-item-custom"
            >
              {valueTemplate !== undefined
                ? valueKey !== null
                  ? valueTemplate(value[valueKey])
                  : valueTemplate(value)
                : valueKey !== null
                ? value[valueKey]
                : value}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </StyledDropdown>
  );
};

export default DropdownMenu;
