import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { deleteQuestion } from 'stores/questions/actions';
import {
  removeSelectedQuestion,
  removeSelectedSavedWorksheetQuestion
} from 'stores/worksheet/actions';

import { removeCreateHomeworkQuestion } from 'stores/topical-worksheet/actions';

import DifficultyChillis from 'components/Level/DifficultyChillis';
import iconTrash from 'assets/img/icons8-trash.svg';
import KebabIcon from 'assets/img/icon-question-card-kebab.svg';
import KebabActiveIcon from 'assets/img/icon-question-card-kebab-active.svg';
import QuestionContent from 'components/Question/QuestionContent';
import EditPanel from 'components/Question/EditPanel';
import PopupModal from 'components/Shared/PopupModal';
import RemoveQuestionModal from 'components/CreateAndAssign/QuestionCart/RemoveQuestionModal';
import { removeCreateFactualFluencyQuestion } from 'stores/factual-fluency/actions';
import { QuestionNumber, QuestionBox } from './WorksheetQuestion.styles';

export default function WorksheetQuestion({
  selectedQuestion,
  onShowModal,
  onShowSolution,
  isSelectable,
  isChecked,
  onChange,
  isShowEditPanel,
  selectedCard,
  onClick,
  cardIndex,
  isEditSavedWorksheet,
  isCreateHomework,
  isCreateFactualFluency
}) {
  const { t } = useTranslation(['browse-content']);
  const question = selectedQuestion.CartQuestion;
  const active = selectedCard === selectedQuestion.ID;

  const dispatch = useDispatch();
  const removeQuestionSelector = () => {
    if (isEditSavedWorksheet) {
      dispatch(removeSelectedSavedWorksheetQuestion(selectedQuestion.ID));
    } else if (isCreateHomework) {
      dispatch(removeCreateHomeworkQuestion(selectedQuestion.ID));
    } else if (isCreateFactualFluency) {
      dispatch(removeCreateFactualFluencyQuestion(selectedQuestion.ID));
    } else {
      dispatch(deleteQuestion(selectedQuestion.ID));
      dispatch(removeSelectedQuestion(selectedQuestion.ID));
    }
  };
  const [confirmation, setConfirmation] = useState(false);
  const showConfirmation = () => {
    setConfirmation(true);
  };

  const closeConfirmation = () => {
    setConfirmation(false);
  };
  return (
    <>
      <PopupModal
        show={confirmation}
        hide={closeConfirmation}
        className="modal-qs"
      >
        <RemoveQuestionModal
          hide={closeConfirmation}
          remove={removeQuestionSelector}
          type="remove-question"
        />
      </PopupModal>
      <div
        className="col-12 mb-4 question-card create-assign position-relative"
        onClickCapture={onClick}
      >
        <QuestionBox
          className={`box-white h-100 d-flex flex-column ${
            active ? 'active' : ''
          }`}
          data-toggle="modal"
        >
          <div className="box-white-header">
            <div className="row align-items-center">
              {!isSelectable && (
                <QuestionNumber
                  className="question-number"
                  active={active}
                >{`Qn${cardIndex + 1}.`}</QuestionNumber>
              )}
              <div className="col-md">
                {isSelectable && (
                  <>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={selectedQuestion.ID}
                      checked={isChecked}
                      onChange={onChange}
                    />
                    <span className="custom-checkbox" />
                  </>
                )}
                <h5 className="mb-0 question-title">
                  {selectedQuestion.TopicName}
                </h5>
                <p className="text-muted">{selectedQuestion.SkillName}</p>
              </div>
              <div className="col-md-auto right-side">
                <span className="question-id">
                  {t(`browse-content:questionCard.id`, 'ID :')}{' '}
                  {selectedQuestion.ID}
                </span>
                <span className="question-difficulty">
                  <span className="difficulty-label">
                    {' '}
                    {t(`browse-content:questionCard.difficulty`, 'Difficulty:')}
                  </span>{' '}
                  <DifficultyChillis value={question.Difficulty?.Max ?? 0} />
                </span>
                {!isSelectable && (
                  <span
                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                    onClick={onClick}
                  >
                    <img
                      src={active ? KebabActiveIcon : KebabIcon}
                      alt="kebab-icon"
                    />
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="box-white-body h-100">
            <QuestionContent
              question={question}
              onShowModal={onShowModal}
              onShowSolution={onShowSolution}
              isFactualFluencyView={isCreateFactualFluency}
            />
          </div>
          <div className="box-white-footer">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                onClick={showConfirmation}
                className="btn btn-outline-secondary remove-question-btn"
              >
                <img alt="Trash" src={iconTrash} className="trash-icon" />
                {t(`browse-content:questionCard.remove`, 'Remove')}
              </button>
            </div>
          </div>
        </QuestionBox>
        {active && isShowEditPanel && (
          <EditPanel
            isEditSavedWorksheet={isEditSavedWorksheet}
            isCreateHomework={isCreateHomework}
            isCreateFactualFluency={isCreateFactualFluency}
            cardIndex={cardIndex}
          />
        )}
      </div>
    </>
  );
}
