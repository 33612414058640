import styled from 'styled-components';

export const TrialModalBodyContainer = styled.div`
  max-width: 600px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  font-family: Century Gothic;
  border-radius: 10px;
  border: 2px solid #dfe9e8;
  h3 {
    margin: 30px 0px;
    text-align: center;
  }
  p {
    font-size: 15px;
    line-height: 20px;
    padding: 15px 30px;
    span {
      text-decoration: underline;
    }
  }
  img {
    width: 104px;
    height: 94px;
  }
  button {
    background-color: #01a89e;
    border: none;
    border-radius: 2px;
    color: white;
    padding: 10px;
    width: 150px;
    font-size: 24px;
    font-weight: bold;
    margin-top: 30px;
    z-index: 10;
  }
  .shape-top {
    position: absolute;
    right: 19px;
    top: 19px;
    height: 0;
    width: 0;
    border-bottom: 200px solid #f9fafc;
    border-right: 200px solid transparent;
    transform: rotate(180deg);
  }
  .shape-bottom {
    position: absolute;
    left: 19px;
    bottom: 19px;
    height: 0;
    width: 0;
    border-bottom: 150px solid #f9fafc;
    border-right: 250px solid transparent;
  }
`;
