import styled from 'styled-components';
import bgFactualFluency from 'assets/img/factual-fluency/bg-blue_hover.jpeg';

export const Container = styled.div`
  font-family: 'Linotte Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;

  position: relative;
  margin-top: -53px;
  background: #0066b8 url(${bgFactualFluency});
  color: #fff;
  height: 100vh;

  .title-text {
    font-size: 60px;
    font-weight: 700;
    margin-bottom: 80px;

  .btn-close-rt {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: transparent;
  }
`;

export const CenterMiddle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);

  .title {
    font-family: 'Varela Round', sans-serif;
    font-size: 60px;
    font-weight: 700;
    margin-bottom: 80px;
  }
`;

export const InfoGroup = styled.div`
  max-width: 720px;
  margin: 0 auto 30px;
  &::after {
    display: block;
    clear: both;
    content: '';
  }
`;

export const InfoGroupBtn = styled.div`
  margin: 70px auto 0;
  max-width: 770px;

  .btn-xlg {
    font-size: 50px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 70px;
    border-radius: 40px;
  }
`;

export const InfoLabel = styled.div`
  float: left;
  line-height: 50px;
  padding: 10px 20px;
  background-color: #0e8bff;
  color: #fff;
  width: 270px;
  text-align: center;
  border-radius: 10px 0 0 10px;
  font-size: 30px;
`;

export const InfoDesc = styled.div`
  float: left;
  line-height: 50px;
  padding: 10px 20px;
  width: 450px;
  background-color: #fff;
  color: #212529;
  border-radius: 0 10px 10px 0;
  padding-left: 45px;
  padding-right: 45px;
  font-size: 36px;
`;

export const CloseButton = styled.button`
  position: absolute;
  pointer-events: all;
  top: 20px;
  right: 20px;
  z-index: 100;
`;
