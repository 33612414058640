import {
  FETCH_TOPICS_REQUEST,
  FETCH_TOPICS_SUCCESS,
  FETCH_TOPICS_FAILED,
  SELECTED_TOPIC,
  RESET_SELECTED_TOPIC,
  UNSELECT_TOPIC
} from './actionTypes';

const initialState = {
  topics: [],
  selectedTopic: {},
  showSideBar: false,
  isLoading: false,
  error: ''
};

export default function topics(state = initialState, action) {
  switch (action.type) {
    case FETCH_TOPICS_REQUEST:
      return { ...state, isLoading: true };
    case FETCH_TOPICS_SUCCESS:
      return {
        ...initialState,
        topics: action.topics,
        selectedTopic: state.selectedTopic
      };
    case FETCH_TOPICS_FAILED:
      return { ...state, isLoading: false, error: action.error };
    case SELECTED_TOPIC:
      return {
        ...state,
        selectedTopic: action.selectedTopic,
        showSideBar: true
      };
    case RESET_SELECTED_TOPIC:
      return { ...state, selectedTopic: {}, showSideBar: false };
    case UNSELECT_TOPIC:
      return { ...state, selectedTopic: {}, showSideBar: true };
    default:
      return state;
  }
}
