import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { setStep } from 'stores/utils/step/actions';
import StepperWizard from 'components/Shared/StepperWizard';
import isNilOrEmpty from 'helpers/isNilOrEmpty';
import { isEmpty } from 'ramda';
import { setCreateFactualFluencyQuestions } from 'stores/factual-fluency/actions';
import CreateFactualFluencyWorksheet from './CreateFactualFluencyWorksheet';
import AssignSavedFactualFluency from './AssignSavedFactualFluency';
import WorksheetTimer from './WorksheetTimer';
import { CreateFactualFluencyContainer } from './CreateFactualFluency.styles';

const CreateFactualFluency = ({ match }) => {
  const { method } = match.params;

  const { t } = useTranslation(['create-assign-question']);
  const dispatch = useDispatch();
  const history = useHistory();
  const { activeStep } = useSelector(state => state.step);
  const { title, assignmentType, editSavedWorksheetQuestions } = useSelector(
    state => state.worksheet
  );
  const { createFactualFluencyItems } = useSelector(
    state => state.factualFluency
  );

  // deactivate to prevent step 3 page crash when change the language
  // useEffect(() => {
  //   return () => {
  //     dispatch(resetSelectedWorksheet());
  //   };
  // }, [dispatch]);

  useEffect(() => {
    isEmpty(createFactualFluencyItems) &&
      method === 'create' &&
      history.push('/create-and-assign/factual-fluency');
  }, [createFactualFluencyItems, history, method]);

  useEffect(() => {
    if (method === 'edit') {
      dispatch(setCreateFactualFluencyQuestions(editSavedWorksheetQuestions));
    }
    // eslint-disable-next-line
  }, [method, editSavedWorksheetQuestions]);
  const BackButton = (
    <Link
      to="/create-and-assign/saved-worksheet"
      className="btn btn-link question-cart-content__back"
    >
      &lt; {t(`create-assign-saved:scheduleWo.backList`, 'Back To List')}
    </Link>
  );
  const onSelect = useCallback(
    step => {
      let valid = true;
      switch (step) {
        case 2:
          valid = !!title.trim() && createFactualFluencyItems.length > 0;
          break;
        case 3:
          valid =
            !!title.trim() &&
            createFactualFluencyItems.length > 0 &&
            !isNilOrEmpty(assignmentType);
          break;
        default:
          valid = false;
          break;
      }
      if (valid) {
        dispatch(setStep(step));
      }
    },
    [title, createFactualFluencyItems, assignmentType, dispatch]
  );

  return (
    <CreateFactualFluencyContainer activeStep={activeStep}>
      <StepperWizard
        onSelect={onSelect}
        backButton={method === 'edit' && BackButton}
      >
        <CreateFactualFluencyWorksheet
          method={method}
          step="1"
          title={t(`create-assign-question:stepper.editWo`, 'Edit Worksheet')}
        />
        <WorksheetTimer
          method={method}
          step="2"
          title={t(
            `create-assign-question:stepper.presentation`,
            'Presentation'
          )}
        />
        <AssignSavedFactualFluency
          method={method}
          step="3"
          title={t(`create-assign-question:stepper.assign`, 'Assign')}
        />
      </StepperWizard>
    </CreateFactualFluencyContainer>
  );
};

export default React.memo(CreateFactualFluency);
