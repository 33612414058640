import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { isNil } from 'ramda';
import qs from 'qs';
import apiClient from 'services/api';
import handleLogout from 'helpers/logout';

// Function that will be called to refresh authorization
export const refreshTokenApi = failedRequest => {
  const options = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  };
  return apiClient
    .post(
      process.env.REACT_APP_TOKEN_ENDPOINT,
      qs.stringify({
        grant_type: 'refresh_token',
        refresh_token: localStorage.getItem('refresh_token'),
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        scope: process.env.REACT_APP_CLIENT_SCOPE
      }),
      options
    )
    .then(tokenRefreshResponse => {
      // eslint-disable-next-line
      if (isNil(tokenRefreshResponse?.data?.access_token)) {
        // reject refresh when no access_token available, safe
        return Promise.reject(failedRequest);
      }
      // update access_token & refresh_token in localStorage
      localStorage.setItem(
        'access_token',
        tokenRefreshResponse.data.access_token
      );
      // Api does not return refresh token in response
      if (!isNil(tokenRefreshResponse.data.refresh_token)) {
        localStorage.setItem(
          'refresh_token',
          tokenRefreshResponse.data.refresh_token
        );
      }

      if (!isNil(failedRequest)) {
        // use updated access_token to refresh the request
        failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.data.access_token}`; // eslint-disable-line
      }
      return Promise.resolve(tokenRefreshResponse?.data);
    })
    .catch(error => {
      handleLogout();
      return error?.response?.data ?? { error: 'Failed to refresh the tokens' };
    });
};

// Instantiate the interceptor (you can chain it as it returns the axios instance)
export default axiosInstance =>
  createAuthRefreshInterceptor(axiosInstance, refreshTokenApi);
