import generateLevels from 'helpers/reportLevels';
import * as actionTypes from './actionTypes';

const reportLevels = generateLevels();

const initialState = {
  isLoading: false,
  classProficiencyReport: null,
  classHighScoreReport: null,
  selectedClass: null,
  selectedClassLevel: reportLevels[0],
  studentProficiencyReport: null,
  qnIsLoading: false,
  sampleQn: null,
  reportError: null,
  sortOrder: 1,
  reportLevel: null,
  selectedSyllabus: null,
  loginReport: null,
  weekItems: null,
  teacherMonthReport: null,
  teacherSpecificMonthReport: null,
  activeToggle: 'proficiency',
  syllabus: {
    isLoading: false,
    error: null,
    data: null
  },
  proficiencySkill: {
    isLoading: false,
    error: null,
    data: null
  },
  highScoreSkill: {
    isLoading: false,
    error: null,
    data: null
  },
  skillDetailReport: {
    isLoading: false,
    error: null,
    data: []
  }
};

export default function reports(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_CLASS_PROFICIENCY_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
        reportError: null
      };
    case actionTypes.FETCH_CLASS_PROFICIENCY_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        classProficiencyReport: action.report
      };
    case actionTypes.FETCH_CLASS_PROFICIENCY_REPORT_FAILED:
      return {
        ...state,
        isLoading: false,
        reportError: action.error,
        classProficiencyReport: null
      };
    case actionTypes.FETCH_CLASS_HIGHSCORE_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
        reportError: null
      };
    case actionTypes.FETCH_CLASS_HIGHSCORE_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        classHighScoreReport: action.report
      };
    case actionTypes.FETCH_CLASS_HIGHSCORE_REPORT_FAILED:
      return {
        ...state,
        isLoading: false,
        reportError: action.error,
        classHighScoreReport: null
      };
    case actionTypes.FETCH_STUDENT_PROFICIENCY_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
        reportError: null
      };
    case actionTypes.FETCH_STUDENT_PROFICIENCY_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        studentProficiencyReport: action.report
      };
    case actionTypes.FETCH_STUDENT_PROFICIENCY_REPORT_FAILED:
      return {
        ...state,
        isLoading: false,
        reportError: action.error,
        studentProficiencyReport: null
      };
    case actionTypes.FETCH_SAMPLE_QN_REQUEST:
      return {
        ...state,
        qnIsLoading: true,
        reportError: null
      };
    case actionTypes.FETCH_SAMPLE_QN_SUCCESS:
      return {
        ...state,
        qnIsLoading: false,
        sampleQn: action.question
      };
    case actionTypes.FETCH_SAMPLE_QN_FAILED:
      return {
        ...state,
        qnIsLoading: false,
        reportError: action.error,
        sampleQn: null
      };
    case actionTypes.SET_SELECTED_CLASS:
      return {
        ...state,
        selectedClass: action.classObject
      };
    case actionTypes.SET_SORT_ORDER:
      return {
        ...state,
        sortOrder: action.sortOrder
      };
    case actionTypes.SET_REPORT_LEVEL:
      return {
        ...state,
        reportLevel: action.reportLevel
      };
    case actionTypes.SET_SYLLABUS:
      return {
        ...state,
        selectedSyllabus: action.syllabus
      };
    case actionTypes.SET_CLASS_LEVEL:
      return {
        ...state,
        selectedClassLevel: action.level
      };
    case actionTypes.FETCH_LOGIN_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
        reportError: null
      };
    case actionTypes.FETCH_LOGIN_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loginReport: action.report
      };
    case actionTypes.FETCH_LOGIN_REPORT_FAILED:
      return {
        ...state,
        isLoading: false,
        reportError: action.error,
        loginReport: null
      };
    case actionTypes.FETCH_WEEK_ITEMS_REQUEST:
      return {
        ...state,
        isLoading: true,
        reportError: null
      };
    case actionTypes.FETCH_WEEK_ITEMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        weekItems: action.weeks
      };
    case actionTypes.FETCH_WEEK_ITEMS_FAILED:
      return {
        ...state,
        isLoading: false,
        reportError: action.error,
        weekItems: null
      };
    case actionTypes.TOGGLE_REPORTS:
      return {
        ...state,
        activeToggle: action.val
      };
    case actionTypes.FETCH_TEACHER_REPORT_MONTH_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.FETCH_TEACHER_REPORT_MONTH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        teacherMonthReport: action.report
      };
    case actionTypes.FETCH_TEACHER_REPORT_MONTH_FAILED:
      return {
        ...state,
        isLoading: false,
        reportError: action.error,
        teacherMonthReport: null
      };
    case actionTypes.FETCH_TEACHER_REPORT_SPECIFIC_MONTH_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.FETCH_TEACHER_REPORT_SPECIFIC_MONTH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        teacherSpecificMonthReport: action.report
      };
    case actionTypes.FETCH_TEACHER_REPORT_SPECIFIC_MONTH_FAILED:
      return {
        ...state,
        isLoading: false,
        reportError: action.error,
        teacherSpecificMonthReport: null
      };
    case actionTypes.FETCH_REPORT_SYLLABUS_REQUEST: {
      return {
        ...state,
        syllabus: {
          isLoading: true,
          error: null,
          data: null
        }
      };
    }
    case actionTypes.FETCH_REPORT_SYLLABUS_SUCCESS: {
      return {
        ...state,
        syllabus: {
          ...state.syllabus,
          isLoading: false,
          data: action.data.sort((a, b) => a.Display - b.Display)
        }
      };
    }
    case actionTypes.FETCH_REPORT_SYLLABUS_FAILED: {
      return {
        ...state,
        syllabus: {
          ...state.syllabus,
          isLoading: false,
          error: action.error
        }
      };
    }
    case actionTypes.FETCH_REPORT_PROFICIENCY_SKILL_REQUEST: {
      return {
        ...state,
        proficiencySkill: {
          isLoading: true,
          error: null,
          data: null
        }
      };
    }
    case actionTypes.FETCH_REPORT_PROFICIENCY_SKILL_SUCCESS: {
      return {
        ...state,
        proficiencySkill: {
          ...state.proficiencySkill,
          isLoading: false,
          data: action.data.sort((a, b) => a.Display - b.Display)
        }
      };
    }
    case actionTypes.FETCH_REPORT_PROFICIENCY_SKILL_FAILED: {
      return {
        ...state,
        proficiencySkill: {
          ...state.proficiencySkill,
          isLoading: false,
          error: action.error
        }
      };
    }
    case actionTypes.CLEAN_REPORT_PROFICIENCY_SKILL: {
      return {
        ...state,
        proficiencySkill: initialState.proficiencySkill
      };
    }
    case actionTypes.FETCH_REPORT_HIGHSCORE_SKILL_REQUEST: {
      return {
        ...state,
        highScoreSkill: {
          isLoading: true,
          error: null,
          data: null
        }
      };
    }
    case actionTypes.FETCH_REPORT_HIGHSCORE_SKILL_SUCCESS: {
      return {
        ...state,
        highScoreSkill: {
          ...state.highScoreSkill,
          isLoading: false,
          data: action.data.sort((a, b) => a.Display - b.Display)
        }
      };
    }
    case actionTypes.FETCH_REPORT_HIGHSCORE_SKILL_FAILED: {
      return {
        ...state,
        highScoreSkill: {
          ...state.highScoreSkill,
          isLoading: false,
          error: action.error
        }
      };
    }
    case actionTypes.FETCH_SKILL_PROFICIENCY_DETAIL_REPORT_REQUEST:
      return {
        ...state,
        skillDetailReport: {
          isLoading: true,
          error: null,
          data: null
        }
      };
    case actionTypes.FETCH_SKILL_PROFICIENCY_DETAIL_REPORT_SUCCESS:
      return {
        ...state,
        skillDetailReport: {
          isLoading: false,
          error: null,
          data: action.report
        }
      };
    case actionTypes.FETCH_SKILL_PROFICIENCY_DETAIL_REPORT_FAILED:
      return {
        ...state,
        skillDetailReport: {
          isLoading: false,
          error: action.error,
          data: null
        }
      };
    case actionTypes.FETCH_SKILL_HIGHSCORE_DETAIL_REPORT_REQUEST:
      return {
        ...state,
        skillDetailReport: {
          isLoading: true,
          error: null,
          data: null
        }
      };
    case actionTypes.FETCH_SKILL_HIGHSCORE_DETAIL_REPORT_SUCCESS:
      return {
        ...state,
        skillDetailReport: {
          isLoading: false,
          error: null,
          data: action.report
        }
      };
    case actionTypes.FETCH_SKILL_HIGHSCORE_DETAIL_REPORT_FAILED:
      return {
        ...state,
        skillDetailReport: {
          isLoading: false,
          error: action.error,
          data: null
        }
      };
    case actionTypes.CLEAN_REPORT_HIGHSCORE_SKILL: {
      return {
        ...state,
        highScoreSkill: initialState.highScoreSkill
      };
    }
    default:
      return state;
  }
}
