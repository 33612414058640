import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { isNil } from 'ramda';
import HTMLReactParser from 'html-react-parser';
import questionParser from 'helpers/questionParser';
import convertTagsToHTML from 'helpers/text/convertTagsToHTML';
import { McqOptionsList } from 'components/Question/QuestionContent.styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchSingleClassReportRequest,
  clearSingleClassReport
} from 'stores/assignments/actions';
import Spinner from 'components/Shared/Spinner';
import { PrintAssignmentPage } from './PrintAssignment.styles';

const QuestionContent = ({ data, qnNumber }) => {
  const { Payload } = data;
  // States
  const [questionTitle, setQuestionTitle] = useState(null);

  const [mcqOptionsParsed, setMCQOptionsParsed] = useState([]);
  // Effects
  useEffect(() => {
    if (!isNil(Payload)) {
      const { title, mcqOptions } = questionParser({
        Payload
      });
      const renderOptions = mcqOptions.map(option =>
        HTMLReactParser(convertTagsToHTML(option, false), {
          replace: domNode => {
            if (domNode.name === 'img') {
              domNode.attribs.style = 'width: 68%';
              return domNode;
            }
            return domNode;
          }
        })
      );
      setMCQOptionsParsed(renderOptions);
      setQuestionTitle(
        HTMLReactParser(convertTagsToHTML(title), {
          replace: domNode => {
            if (domNode.name === 'img' && !isNil(domNode.attribs?.url)) {
              return (
                <img
                  src={domNode.attribs?.url}
                  alt={domNode.attribs?.uniqueid}
                />
              );
            }
            if (domNode.name === 'helptext') {
              return (
                <small id="questionHelp" className="form-text text-muted">
                  {domNode.children[0]?.data ?? ''}
                </small>
              );
            }
            if (domNode.name === 'input') {
              return <input type="text" />;
            }
            return domNode;
          }
        })
      );
    }
  }, [Payload]);
  return (
    <div className="question-item-body">
      <div className="media">
        <div className="media-body">
          <div className="qs-inner">
            <div className="qs-attr">
              <div className="row">
                <div className="col-md">
                  <div className="d-flex">
                    <span>Question {qnNumber}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="qs-text">
              {questionTitle}
              {mcqOptionsParsed.length > 0 && (
                <McqOptionsList>
                  {mcqOptionsParsed.map(option => (
                    <li key={option}>{option}</li>
                  ))}
                </McqOptionsList>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

class PrintAssignmentContent extends React.Component {
  render() {
    return (
      <div>
        <div
          className="header"
          style={{
            margin: '0.5rem 2rem'
          }}
        >
          <h2>{this.props.title}</h2>
          <div className="header-right">
            <p>Name: ________________</p>
            <p>Class: ________________</p>
          </div>
        </div>
        <div
          className="questions"
          style={{
            margin: '0 1rem 0 1rem'
          }}
        >
          {this.props.questions.length > 0 &&
            this.props.questions.map((question, index) => (
              <>
                <QuestionContent
                  key={`qn-${question.Id}`}
                  data={question}
                  qnNumber={index + 1}
                />
                <div className="page-break" />
              </>
            ))}
        </div>
      </div>
    );
  }
}

const PrintAssignment = ({ match }) => {
  const homeworkID = parseInt(match.params.homeworkID, 10);
  const dispatch = useDispatch();
  const { singleClassReport, isSingleClassReportLoading } = useSelector(
    state => state.assignments
  );

  useEffect(() => {
    dispatch(
      fetchSingleClassReportRequest({
        homeworkID
      })
    );
    return () => {
      dispatch(clearSingleClassReport());
    };
  }, [dispatch, homeworkID]);
  return (
    <PrintAssignmentPage>
      <div className="top">
        <Button
          variant="primary"
          className="print-btn"
          onClick={() => {
            window.print();
          }}
        >
          Print Assignment
        </Button>
      </div>
      {isSingleClassReportLoading && <Spinner />}
      {!isSingleClassReportLoading && singleClassReport.length > 0 && (
        <PrintAssignmentContent
          title={singleClassReport[0].HomeWorkTitle}
          questions={singleClassReport[0].Questions}
        />
      )}
    </PrintAssignmentPage>
  );
};

export default PrintAssignment;
