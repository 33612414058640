import React from 'react';
import {
  CountdownNumber,
  ProgressTime,
  ProgressWrap,
  TimerCountdown,
  TimerWrap
} from './FactualFluencyTimer.style';

const FactualFluencyTimer = ({ timeType, time, intervalActive, limit }) => {
  const renderTime = (timeType, time) => {
    switch (timeType.value) {
      case 4:
        return (
          <>
            {timeType.name}
            <TimerCountdown>
              {time.m} : {time.s < 10 ? `0${time.s}` : time.s}
            </TimerCountdown>
          </>
        );
      case 5:
        return (
          <ProgressWrap>
            <ProgressTime>
              <div className="progress">
                <div
                  className={`progress-bar ${intervalActive ? 'active' : ''}`}
                  style={
                    intervalActive
                      ? {
                          transition: `width ${limit}s ease-in-out`
                        }
                      : { width: '101%' }
                  }
                ></div>
              </div>
            </ProgressTime>
            <CountdownNumber>{time.s}</CountdownNumber>
          </ProgressWrap>
        );
      default:
        break;
    }
  };
  return <TimerWrap>{renderTime(timeType, time)}</TimerWrap>;
};

export default React.memo(FactualFluencyTimer);
