import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { nextStep } from 'stores/utils/step/actions';
import { MonthlyReportStyle } from './MonthlyReport.styles';

const images = {
  ava_1: require('assets/img/report/report_icon_1.png'),
  ava_2: require('assets/img/report/report_icon_2.png'),
  ava_3: require('assets/img/report/report_icon_3.png'),
  ava_4: require('assets/img/report/report_icon_4.png'),
  ava_5: require('assets/img/report/report_icon_5.png'),
  ava_6: require('assets/img/report/report_icon_6.png'),
  ava_7: require('assets/img/report/report_icon_7.png'),
  ava_8: require('assets/img/report/report_icon_8.png'),
  ava_9: require('assets/img/report/report_icon_9.png'),
  ava_10: require('assets/img/report/report_icon_10.png'),
  ava_11: require('assets/img/report/report_icon_11.png'),
  ava_12: require('assets/img/report/report_icon_12.png'),
  no_1: require('assets/img/report/report_icon_no_1.png'),
  no_2: require('assets/img/report/report_icon_no_2.png'),
  no_3: require('assets/img/report/report_icon_no_3.png'),
  no_4: require('assets/img/report/report_icon_no_4.png'),
  no_5: require('assets/img/report/report_icon_no_5.png'),
  no_6: require('assets/img/report/report_icon_no_6.png'),
  no_7: require('assets/img/report/report_icon_no_7.png'),
  no_8: require('assets/img/report/report_icon_no_8.png'),
  no_9: require('assets/img/report/report_icon_no_9.png'),
  no_10: require('assets/img/report/report_icon_no_10.png'),
  no_11: require('assets/img/report/report_icon_no_11.png'),
  no_12: require('assets/img/report/report_icon_no_12.png')
};
const MONTHLY = {
  1: { name: 'Jan' },
  2: { name: 'Feb' },
  3: { name: 'Mar' },
  4: { name: 'Apr' },
  5: { name: 'May' },
  6: { name: 'Jun' },
  7: { name: 'Jul' },
  8: { name: 'Aug' },
  9: { name: 'Sep' },
  10: { name: 'Oct' },
  11: { name: 'Nov' },
  12: { name: 'Dec' }
};

const MonthlyReport = props => {
  const { data } = props;
  const { t } = useTranslation(['monthly-report']);
  const history = useHistory();
  const dispatch = useDispatch();

  const handlePress = useCallback(
    e => () => {
      dispatch(nextStep());
      history.push(
        `/report/teacherreport/monthlyreport?year=${e.Year}&month=${e.Month}`
      );
    },
    [dispatch, history]
  );
  return (
    <MonthlyReportStyle>
      {data &&
        data.map((e, index) => {
          return (
            <button
              disabled={!e.IsAvailable}
              type="button"
              key={e.ReportName}
              className="monthlyContainer"
              onClick={handlePress(e)}
            >
              {e.Month === index + 1 && (
                <img
                  className="monthlyImage"
                  src={
                    images[e.IsAvailable ? `ava_${e.Month}` : `no_${e.Month}`]
                  }
                  alt="monthImage"
                />
              )}
              <p className="reportName">
                {t(
                  `monthly-report:report.titleReport`,
                  {
                    month: t(`monthly-report:month.${MONTHLY[e.Month].name}`)
                  },
                  `${MONTHLY[e.Month].name} Report`
                )}
              </p>
              {e.IsAvailable && (
                <p className="available">
                  {t(`monthly-report:report.Available`, 'Available')}
                </p>
              )}
            </button>
          );
        })}
    </MonthlyReportStyle>
  );
};
export default MonthlyReport;
